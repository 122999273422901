import React, { useState, useEffect } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { useHistory } from 'react-router-dom';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux"
import './styles.css';
import { getTaskLogs } from '../../../functions/api';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { baseUrl } from '../../../config'
import moment from 'moment';

// Import Images
import emptyImage from '../../../images/empty-image.jpg';
import logo from '../../../images/default_logo.jpg';
import { getSpecificUser, convertTimeStampToTime, getParticularTaskPreviousSignatures, getAllTaskFlags } from '../../../functions/api';

const EducatorTaskDetail = (props) => {

  const history = useHistory();
  const store = useSelector(state => state.auth)
  const [userRole, setUserRole] = useState(1)

  const [taskNotes, setTaskNotes] = useState("")
  const [signatureImage, setSignatureImage] = useState("")
  const [taskTitle, setTaskTitle] = useState("")
  const [taskLink, setTaskLink] = useState("")
  const [taskLinkTitle, setTaskLinkTitle] = useState("")
  const [embedVideo, setEmbedVideo] = useState("")
  const regex = /(<([^>]+)>)/ig;
  const [videoUrl, setVideoUrl] = useState("")
  const [videoId, setVideoId] = useState("")
  const [vimeoId, setVimeoId] = useState("")
  const [createdBy, setCreatedBy] = useState("")
  const [assignedTo, setAssignedTo] = useState('')
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [taskStatus, setTaskStatus] = useState("")
  const [incompleteReason, setIncompleteReason] = useState("")
  const [taskLogs, setTaskLogs] = useState([])
  const [addImageName, setAddImageName] = useState("");
  const [addImagePath, setAddImagePath] = useState("");
  const [signaturesArray, setSignaturesArray] = useState('')
  const [displayLoader, setDisplayLoader] = useState(true)

  async function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  }

  async function getVimeoId(url) {
    var url = url;
    var id = url.substring(url.lastIndexOf('/') + 1);
    return id;
  }

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  async function getInitialData() {

    setUserRole(store.userRole)
    let task_id = props.match.params.id
    if (task_id !== '') {
      try {
        //getTaskLogs
        let task_logs = await getTaskLogs(props.match.params.id)
        if (task_logs.status === 200) {
          if (task_logs.data.data.length > 0) {
            setTaskLogs(task_logs.data.data);
          }
        }

        let responseData = await NodeDetails(task_id)
        console.log("responseData", responseData);
        if (responseData && responseData.status === 200) {
          if (responseData.data.data.title) {
            let taskTitle = responseData.data.data.title.length > 0 ? responseData.data.data.title[0].value : ""
            setTaskTitle(taskTitle)
          }
          if (responseData.data.data.field_task_notes) {
            let taskNotes = responseData.data.data.field_task_notes.length > 0 ? responseData.data.data.field_task_notes[0].value.replace(regex, '') : ""
            setTaskNotes(taskNotes)
          }

          if (responseData.data.data.field_links !== undefined && responseData.data.data.field_links !== '') {
            let taskLink = responseData.data.data.field_links[0] ? responseData.data.data.field_links[0].uri : ''
            let taskLinkTitle = responseData.data.data.field_links.length > 0 ? responseData.data.data.field_links[0].title : ''
            setTaskLink(taskLink)
            setTaskLinkTitle(taskLinkTitle)
          }
          if (responseData.data.data.field_date !== undefined && responseData.data.data.field_date !== '') {
            let newDate = responseData.data.data.field_date[0] ? responseData.data.data.field_date[0].value : ''
            setDate(newDate)
          }

          if (responseData.data.data.field_time !== undefined && responseData.data.data.field_time !== '') {
            let newTime = responseData.data.data.field_time[0] ? responseData.data.data.field_time[0].value : ''
            let convertedTime = await convertTimeStampToTime(newTime)
            setTime(convertedTime)
          }

          if (responseData.data.data.uid !== undefined && responseData.data.data.uid !== '') {
            let uid = responseData.data.data.uid[0] ? responseData.data.data.uid[0].target_id : ''
            let res = await getSpecificUser(uid, window.localStorage.getItem('auth_token'));
            if (res.status === 200) {
              if (res.data.data.name !== undefined && res.data.data.name !== "") {
                let name = res.data.data.name[0] ? res.data.data.name[0].value : '';
                setCreatedBy(name);
              }
            }
          }
          if (responseData.data.data.field_assign_to !== undefined && responseData.data.data.field_assign_to !== '') {
            let uid = responseData.data.data.field_assign_to[0] ? responseData.data.data.field_assign_to[0].target_id : ''
            let res = await getSpecificUser(uid, window.localStorage.getItem('auth_token'));
            if (res.status === 200) {
              let eduUser = {}
              console.log('name', res.data.data.uid[0].value);
              if (res.data.data.field_first_name !== undefined && res.data.data.field_first_name !== "") {
                let firstName = res.data.data.field_first_name[0] ? res.data.data.field_first_name[0].value : '';
                let lastName = res.data.data.field_last_name[0] ? res.data.data.field_last_name[0].value : '';
                let fullName = firstName + lastName;
                let id = res.data.data.uid[0].value;
                eduUser = { id, fullName };
                setAssignedTo({ id, fullName });
              }
              // if (res.data.data.name !== undefined && res.data.data.name !== "") {
              //     let name = res.data.data.name[0] ? res.data.data.name[0].value : '';
              //     setAssignedTo(fullName);
              // }
            }
          }
          if (responseData.data.data.field_status) {
            let taskStatus = responseData.data.data.field_status.length > 0 ? responseData.data.data.field_status[0].value : ""
            setTaskStatus(taskStatus)
          }

          if (responseData.data.data.field_signature !== "" && responseData.data.data.field_signature !== undefined) {
            let signatureImage = responseData.data.data.field_signature[0] ? responseData.data.data.field_signature[0].url : null
            setSignatureImage(signatureImage)
          }

          if (responseData.data.data.field_incomplete_reason !== "" && responseData.data.data.field_incomplete_reason !== undefined) {
            let incompleteReason = responseData.data.data.field_incomplete_reason[0] ? responseData.data.data.field_incomplete_reason[0].value : null
            setIncompleteReason(incompleteReason)
          }

          if (responseData.data.data.field_image !== undefined && responseData.data.data.field_image !== '') {
            let uploadedImage = responseData.data.data.field_image[0] ? responseData.data.data.field_image[0].url : null
            setAddImagePath(uploadedImage)
          }

          if (responseData.data.data.field_embed_video !== undefined && responseData.data.data.field_embed_video !== '') {
            if (responseData.data.data.field_embed_video[0] && responseData.data.data.field_embed_video[0].value) {

              let regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
              let match = responseData.data.data.field_embed_video[0].value.match(regExp);
              //if youtube
              if (match && match[1].length == 11) {
                let videoId = await getId(responseData.data.data.field_embed_video[0].value)
                setVideoId(videoId)
                // if vimeo
              } else {
                let vimeoId = await getVimeoId(responseData.data.data.field_embed_video[0].value)
                setVimeoId(vimeoId)
              }
            } else {
              let videoUrl = logo
              setVideoUrl(videoUrl)
            }
          }
          setDisplayLoader(false)

          // let getResponse = await getAllTaskFlags()
          // if(getResponse.status === 200)
          // {
          //     if(getResponse.data !== undefined && getResponse.data.data.length > 0)
          //     {
          //        let filterData = getResponse.data.data.filter(data=>data.nid===task_id)
          //        if(filterData.length>0)
          //        {
          //         let _status = filterData[0].field_complete_task === "True" ? "Completed" : "Not Completed"
          //         setTaskStatus(_status)
          //        }

          //     }
          // }   


        }
        else {
          setDisplayLoader(false)
        }

      }
      catch (error) {
        setDisplayLoader(false)
      }
    }



  }

  useEffect(() => {
    getInitialData()
    getAllSigns()

  }, [store.userRole]);

  const getAllSigns = async () => {
    let signResponse = await getParticularTaskPreviousSignatures(props.match.params.id)
    if (signResponse.status === 200) {
      if (signResponse.data !== undefined && signResponse.data.data.length > 0) {
        setSignaturesArray(signResponse.data.data)
      }
    }
  }
  if (displayLoader) {
    return <LoaderComponent displayLoader={displayLoader} />
  }

  return (
    <div>
      <LoaderComponent displayLoader={displayLoader} />
      {
        userRole === 1 || userRole === 3 ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <div className="main-wrapper mtb-70">
        <div className="container">
          {taskTitle !== "" ?
            <div className="dashboard-wrap">
              <div className="common-header-row common-header-with-large-text">
                <h4>{taskTitle}</h4>

                {userRole == 2 ?
                  <Link className="bg-green action-btn-style" to={`/edit-task/${props.match.params.id}`}>
                    Edit
                  </Link> : null
                }
                <a href="javscript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="sidebar">
                    <div className="time-date-row">
                      <div className="date-block">
                        <text><span className="material-icons">date_range</span>Date</text>
                        <p>{moment(date).format('DD-MM-YYYY')}</p>
                      </div>
                    </div>

                    {userRole === 1 ? null : (
                      <div className="list-block meeting-list">
                        <h5 className="title18">Assigned To</h5>
                        <ul>
                          <li>
                            <Link to={`/educators-profile/${assignedTo.id}`} >
                              {assignedTo.fullName}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                    {userRole === 1 ? null : (
                      <div className="list-block meeting-list">
                        <h5 className="title18">Created By</h5>
                        <ul>
                          <li>{createdBy}</li>
                        </ul>
                      </div>
                    )}
                    {userRole === 1 ? null : (
                      <div className="list-block meeting-list">
                        <h5 className="title18">Status</h5>
                        <ul>
                          <li>{taskStatus}</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content-block-right">

                    <div className="meeting-notes">
                      <h5 className="title18"><span className="material-icons">description</span>Task Notes</h5>
                      <div className="material-textfield">
                        <textarea maxlength="200" placeholder="" rows="6" readOnly>{taskNotes}</textarea>
                      </div>
                      {/* <p>{taskNotes}</p> */}
                    </div>

                    {
                      signatureImage !== undefined && signatureImage !== '' && signatureImage !== null ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons signature">signature</span>Signature</h5>
                          {signatureImage !== undefined && signatureImage !== '' ? <img height="100" width="100" src={signatureImage} /> : null}
                        </div> : null
                    }
                    {
                      incompleteReason !== undefined && incompleteReason !== '' && incompleteReason !== null ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">incomplete_circle</span>Incomplete Reason</h5>
                          {incompleteReason !== undefined && incompleteReason !== '' ? <p>{incompleteReason}</p> : null}
                        </div> : null
                    }
                    {/* <>
                                        { 
                                            taskLogs.length > 0 ?
                                            <div className="meeting-notes">
                                            <h5 className="title18"><span className="material-icons">comment_bank</span>Task Logs</h5>
                                            <ul>
                                            {
                                                taskLogs.length > 0 ? taskLogs.map((log, index) => (
                                                    <li key={index}>{log.description}</li>
                                                )) : null    
                                            }
                                            </ul>
                                        </div> : null
                                        }
                                        </> */}
                  </div>
                </div>
              </div>
              {
                signaturesArray.length > 0 ?
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Profile</TableCell>
                          <TableCell>Username</TableCell>
                          <TableCell>Task Status</TableCell>
                          <TableCell>Signature</TableCell>
                          <TableCell>Reason To Incomplete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          signaturesArray.map((data) => {
                            let imageFullUrl = baseUrl + data.field_signature
                            let profileUrl = baseUrl + data.field_profile_picture
                            return (
                              <TableRow className="mdc-form-field">
                                <TableCell className="custom-title-link"> <figure className="user-media"><img src={profileUrl} /></figure></TableCell>
                                <TableCell className="custom-title-link">{data.field_first_name + data.field_last_name}</TableCell>
                                <TableCell className="custom-title-link">{data.field_complete_task === "True" ? "Completed" : "Pending"}</TableCell>
                                <TableCell className="custom-title-link"><figure className="user-media"><img src={signatureImage} /></figure></TableCell>
                                <TableCell className="custom-title-link">{data.field_reason ? data.field_reason : ""}</TableCell>
                              </TableRow>
                            )
                          })
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>
                  :
                  null
              }
            </div>
            : <div className="common-header-row common-header-with-large-text">
              <h4>Task Not Found</h4>
              <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default EducatorTaskDetail;