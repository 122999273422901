import React from 'react';
import { useEffect, useState } from 'react';
import { deleteNode, archiveNotice } from '../../functions/api';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

//Import Images
import { PaginatedList } from 'react-paginated-list';

const CommonNoticeBoardListComponent = (props) => {
  const [noticesList, setNoticesList] = useState([]);
  const [userRole, setUserRole] = useState("")

  useEffect(() => {
    getInitialData();
  }, [props])

  async function getInitialData() {
    let role = window.localStorage.getItem('user_role');
    setUserRole(role)

    if (props.data !== undefined && props.data.length > 0) {
      let noticesListArray = []
      props.data.map(async (data) => {
        let singleNotice = {
          userName: '',
          userProfile: '',
          noticeTitle: '',
          noticeBody: '',
          noticeImage: '',
          videoLink: '',
          noticeId: '',
          createdDate: '',
          updated: ''
        }

        if (data.uid !== "" && data.uid !== undefined) {
          let userId = data.uid ? data.uid : ""
          if (userId !== "") {
            singleNotice['userName'] = userId
          }
        }

        if (data.field_last_updated_on !== "" && data.field_last_updated_on !== undefined) {
          singleNotice['updated'] = data.field_last_updated_on ? data.field_last_updated_on : ''
        }

        if (data.title !== "" && data.title !== undefined) {
          singleNotice['noticeTitle'] = data.title ? data.title : ''
        }

        if (data.nid !== "" && data.nid !== undefined) {
          singleNotice['noticeId'] = data.nid ? data.nid : ''
        }

        if (data.created !== "" && data.created !== undefined) {
          singleNotice['createdDate'] = data.created ? data.created : ''
        }

        if (data.body !== "" && data.body !== undefined) {
          const regex = /(<([^>]+)>)/ig; // remove html tags
          let bodyText = data.body ? data.body.replace(regex, '') : ""
          singleNotice['noticeBody'] = bodyText
        }

        if (data.field_media_image !== "" && data.field_media_image !== undefined) {
          let imagePath = data.field_media_image ? data.field_media_image : null
          singleNotice['noticeImage'] = imagePath
        }

        noticesListArray.push(singleNotice)
        setNoticesList([...noticesListArray])
      });
    }
  }

  async function deleteItem(item) {
    if (noticesList.length > 0) {
      const data = noticesList.filter(i => i.noticeId !== item)
      await setNoticesList([...data])
    }
  }
  //Archive Notice
  async function archiveNoticeById(noticeId) {
    let response = await archiveNotice(noticeId)
    if (response.status === 200) {
      deleteItem(noticeId);
      toast.success('Notice has been Archived Successfully!', {
        position: 'top-center'
      });
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  async function deleteNotice(noticeId) {

    let response = await deleteNode(noticeId);
    if (response.status === 204) {
      deleteItem(noticeId);
      toast.success('Notice has been deleted successfully!', {
        position: 'top-center'
      });
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteNotice(noticeId) {
    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteNotice(noticeId)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  return (
    <>
      <ToastContainer />
      <PaginatedList
        list={noticesList}
        itemsPerPage={10}
        renderList={(list) => (
          <div className="row notice_list" >
            {
              list.length > 0 ? list.map((data, index) => {
                return (
                  <div key={index} className="col-md-12" >
                    <div className="card-block-without-border">
                      <div className="card-header">
                        <Link to={`/notice-detail-page/${data.noticeId}`}>
                          <h3 dangerouslySetInnerHTML={{ __html: data.noticeTitle }}></h3>
                        </Link>
                        {userRole === 'head_coordinator' ?
                          <div className="row">
                            <Link to={`/edit-notice/${data.noticeId}`}>
                              <p> <b>Edit |{' '}</b></p>
                            </Link>
                            <p onClick={() => { confirmDeleteNotice(data.noticeId) }} style={{ color: "#0951a1", cursor: 'pointer' }} >
                              <b>Delete |{' '} </b>
                            </p>
                            {props.noticeType !== 'archived' ?
                              <p onClick={() => { archiveNoticeById(data.noticeId) }} style={{ color: "#0951a1", cursor: 'pointer' }}>
                                <b>Archive</b>
                              </p>
                              :
                              <p style={{ color: "#0951a1", cursor: 'pointer' }}>
                                <b>Archived</b>
                              </p>
                            }
                          </div>
                          : ""
                        }
                      </div>
                    </div>
                  </div>
                )
              }) : null
            }
          </div>
        )}
      />
    </>
  )
}

export default CommonNoticeBoardListComponent;