import React, {useEffect} from 'react';
import { $ } from 'react-jquery-plugin';
import './styles.css';
import { useState } from 'react';
import { getSpecificUser, userBookings, getParticularUserContentCustom, getUserProfileData, getParticularUserContent, convertTimeStampToTime, getUpcomingVisits,addRiskAssessmentLogs,getRiskAssessmentLogs,deleteRiskAssessmentLogs,updateRiskAssessmentLogs } from '../../../functions/api';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { useSelector } from 'react-redux'
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import moment from 'moment';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DummyImage from '../../../images/user2.png'

//Import Images
import UserLargeMedia from '../../../images/user-large-media.png';


const ViewProfilePage = (props) => {

	const history = useHistory();
	const uid = props.match.params.id;
	const [dob, setDob] = useState('')
	const store = useSelector(state=>state.auth)
	const [commencementDate, setCommencementDate] = useState('')
	const [email, setEmail] = useState('')
	
	const [username, setUsername] = useState('')
	const [userTasks, setUserTasks] = useState([]);
	const [userVisits, setUserVisits] = useState([]);
	const [userPicture, setUserPicture] = useState();
	const [userRole, setUserRole] = useState('')
	const [showLoader, setShowLoader] = useState(true)
	//

	const [userBookingsLinks, setUserBookingsLinks] = useState([])


	

    useEffect(() => {

		try {
			let userSavedPic = window.localStorage.getItem("user_picture")
			if (userSavedPic) {
				setUserPicture(userSavedPic);
			}
		} catch (error) {

		}
    	$(".accordion-content:not(:first-of-type)").css("display", "none");
        $(".js-accordion-title:first-of-type").addClass("open");
        
        $(".js-accordion-title").click(function () {
            $(".open").not(this).removeClass("open").next().slideUp(300);
            $(this).toggleClass("open").next().slideToggle(300);
      	});	
	    
		$(function(){
		$('.mdc-text-field--outlined .mdc-text-field__input').on('focusout', function(){
			if($(this).val().length > 0) {
				$(this).addClass('active');
			}else{
				$(this).removeClass('active');
			}
		})
		})

		async function getInitialData(){
			let authToken = window.localStorage.getItem('auth_token');
			let user_name  = window.localStorage.getItem('name');

			if(store.userRole !== undefined)
			{
				setUserRole(store.userRole)
			}

			let _userTasks = await getParticularUserContentCustom("task",user_name);
			if(_userTasks.status === 200){
				setShowLoader(false)
				if(_userTasks.data.data.length > 0){
					setUserTasks(_userTasks.data.data);
				}
			}
			else
			{
				setShowLoader(false)
			}

			let _userVisits = await getParticularUserContentCustom("visits", user_name);
			
			if(_userVisits.status === 200){
				setShowLoader(false)
				if(_userVisits.data.data.length>0){
					setUserVisits(_userVisits.data.data);
				}
			}
			else
			{
				setShowLoader(false)
			}

			let bookings = await userBookings(uid);
			if(bookings.status === 200){
				setShowLoader(false)
				if(bookings.data.data.length>0){
					setUserBookingsLinks(bookings.data.data);
				}
			}else{
				setShowLoader(false)
			}

			let response = await getSpecificUser(uid, authToken);
			if(response.status === 200){
				setShowLoader(false)
				if(response.data.data.field_first_name !== undefined && response.data.data.field_first_name.length > 0){
		            if(response.data.data.field_last_name !== undefined && response.data.data.field_last_name.length > 0){
		                let lname = response.data.data.field_last_name[0].value
		                setUsername(response.data.data.field_first_name[0].value+" "+lname);
		            }
		            else
		            {
		               setUsername(response.data.data.field_first_name[0].value); 
		            }
		        }

				if(response.data.data.mail !== undefined && response.data.data.mail !== ''){
					let mail = response.data.data.mail[0] ? response.data.data.mail[0].value : "";
					setEmail(mail);
				}

				if(response.data.data.head_office_team_profiles.length > 0){
					let profileId = response.data.data.head_office_team_profiles[0].target_id;
					let profileData = await getUserProfileData(profileId);
					if(profileData.status === 200){
						if(profileData.data.data.field_d_o_b !== undefined && profileData.data.data.field_d_o_b !== ""){
							let dob = profileData.data.data.field_d_o_b[0] ? profileData.data.data.field_d_o_b[0].value : "";
							setDob(dob);
						}

						if(profileId !== "")
            			{
							let auth_token = window.localStorage.getItem('auth_token');
             				let profile_response = await getUserProfileData(profileId, auth_token)
              				if(profile_response.status === 200){

								if(profile_response.data.data !== '' && profile_response.data.data !== undefined){
									if(profile_response.data.data.field_profile_picture !== undefined && profile_response.data.data.field_profile_picture !== ''){
										let user_picture = profile_response.data.data.field_profile_picture[0] ? profile_response.data.data.field_profile_picture[0].url : "";
										setUserPicture(user_picture);
									}
								}
              				} 
            			}

						if(profileData.data.data.field_commencement_date !== undefined && profileData.data.data.field_commencement_date !=="" ){
          					let date = profileData.data.data.field_commencement_date[0] ? profileData.data.data.field_commencement_date[0].value : ""
          					setCommencementDate(date)
        				}
					}
				}
			}
			else
			{
				setShowLoader(false)
			}
		} 
		
		getInitialData()
		
    },[store.userRole]);

    return (
		<>
			<ToastContainer />
			<LoaderComponent displayLoader={showLoader} />
			{
				userRole == 1 ? 
				<EducatorsMainHeader />
				:
				<HeadOfficeMainHeader />
			}
			
			<div className="main-wrapper mtb-70">
		    <div className="container">	
			    <div className="dashboard-wrap">
				    <div className="row">
						<div className="col-lg-12">
							<div className="common-header-row common-header-with-large-text risk--assignment">
								<div className="risk-assi-title">
									{/* <h4>{username}</h4>	 */}
								</div>
							</div>
								<div className="user">
									<div className="user-profile-block text-center viewprofile">
										<div className="media">
											<img width="100" height="100" src={userPicture ? userPicture : DummyImage}/>
										</div>
										{
											email ? 
											  <h1 className="username">{username}</h1>
											: null
										}
										<Link to={`/co-ordination-unit-edit-profile`} className="edit-profile" >Edit profile</Link>
									</div>	
								</div>	
								<div className="user-pages-accordion-wrap view-profile-page">
									<div id="accordion" className="accordion-container">
										{/* <h4 className="accordion-title js-accordion-title">Profile</h4>
										<div className="accordion-content">
											<div className="row">
												<div className="col-md-4">
													<div className="user-caption-wrap">
														<label>D.O.B</label>
														<span><Moment format ="DD-MM-YYYY">{dob}</Moment></span>	
													</div>	
												</div>
                        						<div className="col-md-4">
													<div className="user-caption-wrap">
														<label>Commencement Date</label>
														<span><Moment format ="DD-MM-YYYY">{commencementDate}</Moment></span>	
													</div>	
												</div>
                      						</div>		
										</div>  */}
                    					<h4 className="accordion-title js-accordion-title">Mentoring & Support</h4>	
                      					<div className="accordion-content">
                        					<div className="task-listing">
												{
													userVisits.length > 0 ? userVisits.map((visit, index) => (
														<div key={index} className="mdc-form-field">
                           								{ 
                           	 								visit.field_date !== undefined && visit.field_date !== '' ? 
                           	 								<span className="date-field">{visit.field_date[0] ? <Moment format="DD-MMM-YYYY">{visit.field_date[0].value}</Moment> : ""}</span> : null
                           	 							}
														{
															visit.nid ? 
															<label><Link to={`/m&s-detail-page/${visit.nid[0].value}`}>{visit.title[0].value}</Link></label>
															: null
														} 
                       		 							</div>
													)) : null
												}
                      						</div>	
                                        </div> 
                                        <h4 className="accordion-title js-accordion-title">Tasks</h4> 
                                        <div className="accordion-content">
                                            <div className="task-listing">
												{
													userTasks.length > 0 ? userTasks.map((task, index) => (
														<div key={index} className="mdc-form-field">
                                                    		<label><Link to={`/task-detail-page/${task.nid[0].value}`}>{task.title[0].value}</Link></label>
                                                		</div>

													)) : null
												}
                                            </div>	
                                        </div>
										<h4 className="accordion-title js-accordion-title">Links</h4> 
										<div className="accordion-content">
                                            <div className="task-listing">
												{
													userBookingsLinks.length > 0 ? userBookingsLinks.map((bookingLink, index) => (
														<div key={index} className="mdc-form-field">
                                                    		<span className="date-field custom-title-link"><Link to={`/link-detail-page/${bookingLink.field_link_name}`}>{bookingLink.field_link_name_1}</Link></span>
															<label>Number of Seats Booked : {bookingLink.field_no_of_seats}</label>
                                                		</div>

													)) : null
												}
                                            </div>	
                                        </div>
										{/* <h4 className="accordion-title js-accordion-title">Course Details</h4> 
										<div className="accordion-content">

										<div className="col-md-4">
													<div className="user-caption-wrap">
														<label>First Aid Course</label>
														<span><Moment format ="DD-MM-YYYY">{dob}</Moment></span>	
													</div>	
												</div>
                        						<div className="col-md-4">
													<div className="user-caption-wrap">
														<label>CPR Course</label>
														<span><Moment format ="DD-MM-YYYY">{commencementDate}</Moment></span>	
													</div>	
												</div>
                                            <div className="task-listing">
											
                                            </div>	
                                        </div> */}
                
                                    </div>
									
								</div>	
							</div>	
				        </div>
			        </div>		
		        </div>	
	        </div>
		</>
        );
    }

export default ViewProfilePage;