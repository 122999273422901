import React, { useEffect, useState } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import Lists from '../../../commonComponents/commonListComponent/Lists';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { getAllContent } from '../../../functions/api';
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom';
import EmptyData from '../../../commonComponents/EmptyData/EmptyData';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';


const LinksPage = (props) => {

  const store = useSelector(state => state.auth)
  const [allLinks, setAllLinks] = useState({})
  const [userRole, setUserRole] = useState(1)
  const [noData, setNoData] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [sort, set_sort] = useState('upcoming');

  async function getAllInitalData() {
    setUserRole(store.userRole)
    let linksArray = await getAllContent('links')
    if (linksArray.data !== undefined && linksArray.data.data.length > 0) {
      setShowLoader(false)
      let newLinksList = linksArray.data.data
      setAllLinks(newLinksList)
    }else {
      setShowLoader(false)
      setNoData(true)
    }
    let newScheduleList = linksArray.data.data;
    if (sort === 'asc') {
      let sortedAsc = newScheduleList.sort((a, b) => Date.parse(a.field_date[0].value) - Date.parse(b.field_date[0].value));
      setAllLinks(sortedAsc)
    } else if (sort === 'desc') {
      let sortedDesc = newScheduleList.sort((a, b) => Date.parse(b.field_date[0].value) - Date.parse(a.field_date[0].value));
      setAllLinks(sortedDesc)
    } else {
      let sortedUpcoming = newScheduleList.filter((a) => {
        return a.field_date[0].value >= moment(new Date()).format('YYYY-MM-DD')
      });
      let sortedUpcomingNew = sortedUpcoming.sort((a, b) => Date.parse(a.field_date[0].value) - Date.parse(b.field_date[0].value));
      setAllLinks(sortedUpcomingNew)
    }
  }


  useEffect(() => {
    getAllInitalData();
    window.localStorage.removeItem('folderParentId');
  }, [store.userRole, sort])


  return (
    <>
      {
        userRole === 1 || userRole === 3 ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }

      <LoaderComponent displayLoader={showLoader} />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            <div className="row">
              {/* <h1>Link program</h1> */}
              <div className="col-md-12">
                <div className="content-block-right">
                  <div className="notice-listing-block link-page">

                    <div className="common-header-row">
                      <h4>LINK Program

                        {
                          userRole === 2 ?
                            <Link style={{ marginLeft: '15px' }} to={`/add-venue`} class="mdc-button mdc-button--raised ">
                              <span className="mdc-button__label">Add Link Venue</span>
                            </Link>
                            : null
                        }

                      </h4>
                      {
                        /*
                        userRole == 2 ? 
                        <Link to={`/add-business`} class="mdc-button mdc-button--raised ">
                            <span className="mdc-button__label">Add Business</span>
                        </Link>
                        : null
                        */
                      }


                      {
                        userRole === 2 ?
                          <Link to={`/add-new-link`} class="mdc-button mdc-button--raised green-btn"><span class="mdc-button__label"> Add New Link </span> </Link>
                          : null
                      }


                      {
                        userRole === 2 ?
                          <Link to={`/add-session`} class="mdc-button mdc-button--raised ">
                            <span className="mdc-button__label">Add facilitator</span>
                          </Link>
                          : null
                      }

                      <div>
                        <span>  Date By : </span>
                        <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={sort}>
                          <MenuItem value={"asc"} onClick={() => set_sort('asc')}>Oldest To Newest</MenuItem>
                          <MenuItem value={"desc"} onClick={() => set_sort('desc')}>Newest To Oldest</MenuItem>
                          <MenuItem value={"upcoming"} onClick={() => set_sort('upcoming')}>Upcoming</MenuItem>
                        </Select>
                      </div>:
                    </div>


                    {
                      noData === true ? <EmptyData title="No Links Found!" desc={userRole === 1 || userRole === 3 ? 'There are No Links to be displayed right now!' : 'Links created by Co ordination Unit will be displayed here. Click Add New to assign One.'} /> :
                        <Lists data={allLinks} />
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LinksPage;