import React, { useEffect, useState } from 'react';
import HeaderOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { $ } from 'react-jquery-plugin';
import Calendar from 'react-calendar';
import Moment from 'react-moment';
import './styles.css';
import Select from 'react-select';
import { createVisit, getAllEducators, CoordinatorList } from '../../../functions/api';
import { uploadImage, getLeaves } from '../../../functions/api';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.css'
import SignaturePad from 'react-signature-canvas'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));



const NewMentoringSupportPage = (props) => {

	let digitalSignaturePath = ''
	const classes = useStyles();

	const history = useHistory();
	const [title, setTitle] = useState('');
	const [educatorName, setEducatorName] = useState('')
	const [venue, setVenue] = useState('');
	const [addImagePath, setAddImagePath] = useState([{ name: "", path: '' }]);
	const [addImageName, setAddImageName] = useState('');
	const [visitNotes, setVisitNotes] = useState('');
	const [educatorsList, setEducatorsList] = useState([])
	const [visitMethodName, setVisitMethodName] = useState(null);
	const [visitMethod, setVisitMethod] = useState(null);
	const [showCalendar, setCalendar] = useState(false);
	const [showTimePicker, setTimePicker] = useState(false);
	const [timeValue, setTimeValue] = useState('');
	const [calendarValue, setCalendarValue] = useState();
	const [msDate, setMsDate] = useState('');
	const [addFile, setAddFile] = useState('');
	const [options, setOptions] = useState([]);
	const [digitalSignatureName, setdigitalSignatureName] = useState('digital_signature.png');
	const [ajaxLoader, setAjaxLoader] = useState(false);
	const [sigPad, setSigPad] = useState()
	const [uploadImagePath, setUploadImagePath] = useState([]);
	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
	const [leavesArray, setLeavesArray] = useState([])
	const [multipleUrls, setMultipleUrls] = useState([""]);
	const [cordinator, setCordinator] = useState('');
	const [blockButton, setBlockButton] = useState(false);
	const [taskTodo, setTaskTodo] = useState('To-Do')

	const methods = ["Phone", "Email", "Home", "Link", "Park", "WFDC Office", "Other Location"]
	const taskMethods = ["To-Do", "Completed", "In Progress"]


	function calendar() {
		if (showCalendar === true) {
			setCalendar(false)
		}
		else {
			setCalendar(true)
		}
	}

	function timepicker() {
		setTimePicker(true)
	}

	const educatorsHandler = (event) => {
		setEducatorName(event);

	};

	const getCoordinator = (event) => {

		setCordinator(event);
	}

	//get all cordinator list
	const [cordinator_option, set_cordinator_option] = useState([])
	async function get_cordinator() {
		let list = await CoordinatorList();

		let opt_array = [];
		if (list.data.data.length > 0) {
			list.data.data.map((v) => {
				const obj = {};
				obj['value'] = v.uid;
				obj['label'] = v.name;
				opt_array.push(obj);
			})
			set_cordinator_option(opt_array);

		}
		// if(list.status == 200){
		// 	let arr=[];
		// 	
		// 	if(list.data.data.length > 0){
		// 		 list.data.data.map((v)=>{
		// 			arr.push(v.name)
		// 		 });
		// 		 setCordinator(arr);
		// 		 
		// 	}
		// }
		// else{
		// 	
		// }
	}

	const calendarHandler = (newDate) => {

		let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
		let year = newDate.getFullYear()
		let date = newDate.getDate()
		let fullYear = date + "-" + month + "-" + year

		setMsDate(fullYear)
		setCalendar(false)
	}

	const timeHandler = (newTime) => {
		setTimeValue(newTime)
	}

	const selectMethod = (method) => {
		setVisitMethodName(method)
		if (method === "Email") {
			setVisitMethod("280")
		} else if (method === "Home") {
			setVisitMethod("281")
		} else if (method === "Link") {
			setVisitMethod("282")
		} else if (method === "Phone") {
			setVisitMethod("279")
		} else if (method === "Park") {
			setVisitMethod("4984")
		} else if (method === "WFDC Office") {
			setVisitMethod("4983")
		} else if (method === "Other Location") {
			setVisitMethod("283")
		}
	}

	const addFileHandler = (event) => {
		setAddFile(event.target.files[0].name);
	}

	const addFileResourcesHandler = (event, index) => {

		if (event.target.files && event.target.files[0]) {
			let imagesArray = addImagePath;
			if (event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && event.target.files[0].type !== 'application/msword') {
				toast.error('Invalid File Type. File can only be of the following types : pdf, doc, docx', {
					position: 'top-center'
				});
				return false;
			} else {
				if (event.target.files[0].size > 5000000) {
					toast.error('File size exceeds the limit of 5MB', {
						position: 'top-center'
					});
					return false;
				} else {
					var reader = new FileReader();
					reader.onload = function (e) {
						imagesArray[index].name = event.target.files[0].name
						imagesArray[index].path = e.target.result
						setAddImagePath([...imagesArray])
					};
					reader.readAsDataURL(event.target.files[0]);
				}
			}


		}
	}

	const onChangeTitleHandler = (event) => {
		setTitle(event.target.value);
	}

	const onChangeVenueHandler = (event) => {
		setVenue(event.target.value);
	}

	const onChangeNotesHandler = (event) => {
		setVisitNotes(event.target.value);
	}

	const onChangeVideoHandler = (event, index) => {
		let newArray = multipleUrls
		newArray[index] = event.target.value
		setMultipleUrls([...newArray])
	}





	useEffect(() => {

		//Dropdown toggle
		$(function () {
			$('.dropdown-toggle').click(function () {
				$(this).next('.dropdown').slideToggle();
			});

			$(document).click(function (e) {
				var target = e.target;
				if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
					$('.dropdown').slideUp();
				}
			});
		});


		$(function () {
			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
				if ($(this).val().length > 0) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			})
		})

		async function getInitialData() {
			setAjaxLoader(true)
			let sortedEducatorsList = await getAllEducators();
			setEducatorsList(sortedEducatorsList.data.data);

			let opt_array = [];
			if (sortedEducatorsList.data.data.length > 0) {
				sortedEducatorsList.data.data.map((edu, index) => {
					const obj = {};
					obj['value'] = edu.uid;
					obj['label'] = edu.name;
					opt_array.push(obj);
				})
				setOptions(opt_array);
				setAjaxLoader(false)
			}
		}
		getInitialData();
		get_cordinator();

	}, [uploadImagePath]);

	// submit form
	const mentoringSupportSubmission = async (event) => {

		event.preventDefault();
		setAjaxLoader(true);
		let name = title;
		let educator_id = educatorName.value ? educatorName.value : "";
		let method = visitMethod;
		let date = moment(msDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
		let location = venue;
		let agenda = visitNotes;
		let totalTimeStamp = null;
		let cordinator_id = cordinator.value ? cordinator.value : "";
		let urlsArray = []
		if (multipleUrls.length > 0) {
			multipleUrls.map((data) => {
				if (data !== "") {
					urlsArray.push({ "value": data })
				}
			})
		}


		if (title === undefined || title === '') {
			toast.error('Title is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if (visitMethod === null || visitMethod === '') {
			toast.error('Please choose visit method', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if (msDate === undefined || msDate === '') {
			toast.error('Date is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}
		if (educatorName === undefined || educatorName === '') {
			toast.error('Assign Educator is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}
		

		setBlockButton(false)

		const getDatesBetweenDates = (startDate, endDate) => {
			let dates = []
			//to avoid modifying the original date
			const theDate = new Date(startDate)
			while (theDate < endDate || theDate.valueOf() === endDate.valueOf()) {
				dates = [...dates, new Date(theDate)]
				theDate.setDate(theDate.getDate() + 1)
			}
			return dates
		}

		//getLeaves
		let checkLeaveResponse = {};
		if (educator_id !== '' && date !== '') {
			let selected_date = new Date(date).setHours(0, 0, 0, 0);
			let leaves = await getLeaves(educator_id);
			if (leaves.status === 200) {
				setLeavesArray(leaves.data.data)
				if (leaves.data.data.length > 0) {
					for (let mydata of leaves.data.data) {
						for (let rangeDate of getDatesBetweenDates(new Date(mydata.start_date), new Date(mydata.end_date))) {
							if (selected_date.valueOf() === rangeDate.valueOf()) {
								checkLeaveResponse['response'] = date
								checkLeaveResponse['success'] = false
							}
						}
					}

				}
			}
		}
		if (checkLeaveResponse['response'] !== undefined) {
			setShowAvailabilityModal(true)
			setAjaxLoader(false);
			setBlockButton(false)
			return false;
		}
		//end


		if (timeValue !== "") {
			let timeArray = timeValue.split(':')
			let hours = timeArray[0]
			let minutes = timeArray[1]
			totalTimeStamp = moment.duration(`${timeValue}:00`).asSeconds()
		}


		if (addImagePath[0].path !== "" && uploadImagePath.length > 0)  // when both file and images were upload

		{
			// alert("Called")
			let allResources = []

			// 
			// 
			// setAjaxLoader(false);
			// return;
			addImagePath.map(async (data, index) => {
            //  alert("true")
				let response = await uploadImage(data.name, data.path);  // upload Resources
        // console.log('response', response);
				if (response.status == 201) {
					let fid = response.data.data.fid[0].value;
					allResources.push({ "target_id": fid })
					if (allResources.length === addImagePath.length) {
						let allImagesArray = []
						uploadImagePath.map(async (data) => {

							let uploadImageResponse = await uploadImage(data[0].name, data[0].path)
							if (uploadImageResponse.status === 201) {
								let imageFid = uploadImageResponse.data.data.fid[0].value
								allImagesArray.push({ "target_id": imageFid })
								if (allImagesArray.length === uploadImagePath.length) {
									let data = { imageFid: allImagesArray, video: urlsArray, title: name, field_headoffice: cordinator_id ,educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: allResources, taskTodo: taskTodo };
									// console.log("data",data);
									let res = await createVisit(data);
									if (res.status == 201) {
										setAjaxLoader(false);
										history.push('/m&s');
										window.localStorage.removeItem('fileFidMandS');
									} else {
										setAjaxLoader(false);
										toast.error(res.message, {
											position: 'top-center'
										});
									}
								}
							}
							else {
								setAjaxLoader(false);
								toast.error(uploadImageResponse.message, {
									position: 'top-center'
								});
							}
						})
					}
				} else {
					setAjaxLoader(false);
					toast.error(response.message, {
						position: 'top-center'
					});
				}
			})
		}

		else if (addImagePath[0].path !== "" && uploadImagePath.length === 0)   // when only resources uploaded
		{
			// alert("true1")
			let allResources = []
			addImagePath.map(async (data, index) => {

				let response = await uploadImage(data.name, data.path);  // upload Resources
				if (response.status === 201) {
					let fid = response.data.data.fid[0].value;
					allResources.push({ "target_id": fid })
					if (allResources.length === addImagePath.length) {
						let data = { imageFid: null, video: urlsArray, title: name, field_headoffice: cordinator_id, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: allResources, taskTodo: taskTodo };
						// console.log("data---",data);
						let res = await createVisit(data);
						if (res.status == 201) {
							setAjaxLoader(false);
							history.push('/m&s');
							window.localStorage.removeItem('fileFidMandS');
						} else {
							setAjaxLoader(false);
							toast.error(res.message, {
								position: 'top-center'
							});
						}
					}
				} else {
					setAjaxLoader(false);
					toast.error(response.message, {
						position: 'top-center'
					});
				}
			})
		}

		else if (addImagePath[0].path === "" && addImagePath.length === 1 && uploadImagePath.length > 0)  // when only images uploaded
		{
			let allImagesArray = []
			uploadImagePath.map(async (data) => {

				let uploadImageResponse = await uploadImage(data[0].name, data[0].path)
				if (uploadImageResponse.status === 201) {
					let imageFid = uploadImageResponse.data.data.fid[0].value
					allImagesArray.push({ "target_id": imageFid })
					if (allImagesArray.length === uploadImagePath.length) {
						let data = { imageFid: allImagesArray, video: urlsArray, title: name,field_headoffice: cordinator_id, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: null, taskTodo: taskTodo };
						let res = await createVisit(data);
						if (res.status == 201) {
							setAjaxLoader(false);
							history.push('/m&s');
							window.localStorage.removeItem('fileFidMandS');
						} else {
							setAjaxLoader(false);
							toast.error(res.message, {
								position: 'top-center'
							});
						}
					}
				}
				else {
					setAjaxLoader(false);
					toast.error(uploadImageResponse.message, {
						position: 'top-center'
					});
				}
			})

		}

		else {
			let data = { imageFid: null, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: null, field_headoffice: cordinator_id, taskTodo: taskTodo };
			// 
			// setAjaxLoader(false);
			let res = await createVisit(data);
			// 
			if (res.status == 201) {
				setAjaxLoader(false);
				history.push('/m&s');
				window.localStorage.removeItem('fileFidMandS');
			} else {
				setAjaxLoader(false);
				toast.error(res.message, {
					position: 'top-center'
				});
			}
		}

	}

	const removeSpecificImage = (index) => {
		let imagesArray = uploadImagePath
		imagesArray.splice(index, 1)
		setUploadImagePath([...imagesArray])
	}

	const addMoreUrls = () => {
		let newArray = multipleUrls
		newArray.push("")
		setMultipleUrls([...newArray])
	}

	const addMoreResources = () => {
		let newArray = addImagePath
		newArray.push({ name: '', path: '' })
		setAddImagePath([...newArray])
	}

	const addMoreImages = () => {
		document.getElementById('get_file').onclick = function () {
			document.getElementById('attach-file').click();
		}
		// let newArray = uploadImagePath
		// newArray.push({name:'',path:''})
		// setUploadImagePath([...newArray])
	}

	const removeSpecificResource = (index) => {
		let newArray = addImagePath
		newArray.splice(index, 1)
		setAddImagePath([...newArray])
	}

	const removeSpecificUrls = (index) => {
		let newArray = multipleUrls
		newArray.splice(index, 1)
		setMultipleUrls([...newArray])
	}





	const uploadImageHandler = (event, field) => {
		let imagesArray = [];


		//alert(imagesArray.length)

		if (event.target.files && event.target.files[0]) {
			if (Object.keys(event.target.files).length <= 7) {

				Object.values(event.target.files).map(function (filesData, index) {
					let name = filesData.name;
					if (field === "image") {

						if (filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png') {
							toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
								position: 'top-center'
							});
							return false;
						} else {
							if (filesData.size > 5000000) {
								toast.error('Image size exceeds the limit of 5MB', {
									position: 'top-center'
								});
								return false;
							} else {
								var reader = new FileReader();
								reader.onload = function (event) {
									imagesArray.push({ 'name': name, 'path': event.target.result })
									//
									setUploadImagePath([...uploadImagePath, imagesArray])
								};
								reader.readAsDataURL(filesData);
							}
						}
					}
				})
			}
			else {
				toast.error("You can't upload more than 7 images", { position: 'top-center' });
			}


		}

	}

	return (
		<div>
			<ToastContainer />
			<HeaderOfficeMainHeader />
			<LoaderComponent displayLoader={ajaxLoader} />
			<div className="main-wrapper">
				<div className="container">
					<div className="user-pages-wrap">
						<div className="row">
							<div className="col-lg-12">
								<div className="user-types-wrap course-detail-page text-center">
									<div className="common-header-row common-header-with-large-text">
										<h4>Plan New Mentoring & Support</h4>
										<div className="block-right">
											<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
											{/* <Link to={`/add-new-task`} className="mdc-button mdc-button--raised add-task-btn">
												<span className="mdc-button__label">Add task</span>
											</Link> */}
										</div>
									</div>

									<form onSubmit={mentoringSupportSubmission} className="sign-up-form">
										<div className="row">
											<div className="col-md-12">
												<div className="row">
													<div className="col-lg-4 col-md-6">
														<label className="mdc-text-field mdc-text-field--outlined">
															<input maxlength="20" onChange={onChangeTitleHandler} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	<span className="mdc-floating-label" id="my-label-id">Title</span>
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															</span>
														</label>
													</div>

													<div className="col-lg-4 col-md-6">
														<Select className="search-select-field"
															placeholder="Select Educator"
															style={{ width: 100 }}
															value={educatorName}
															options={options}
															onChange={educatorsHandler}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<Select className="search-select-field"
															placeholder="Assign Coordinator"
															style={{ width: 100 }}
															value={cordinator}
															options={cordinator_option}
															onChange={getCoordinator}
														/>
													</div>


													<div className="col-lg-2 col-md-6">
														<div className="calendar-wrap">
															<label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar()} >
																<input type="text" readOnly className="mdc-text-field__input" aria-labelledby="my-label-id" style={{ cursor: "pointer" }} />
																<span className="mdc-notched-outline">
																	<span className="mdc-notched-outline__leading"></span>
																	<span className="mdc-notched-outline__notch">
																		<span className="mdc-floating-label" id="my-label-id">{msDate ? msDate : "Date"}</span>
																	</span>
																	<span className="mdc-notched-outline__trailing"></span>
																	<span className="material-icons">calendar_today</span>
																</span>
															</label>
															<div>
																{
																	showCalendar === true ? <Calendar value={calendarValue} onChange={calendarHandler} /> : null
																}
															</div>
														</div>
													</div>

													<div className="col-lg-2 col-md-6 text-center justify-content-center">
														<label className="mdc-text-field mdc-text-field--outlined">
															<TimePicker

																onChange={(timestamp, time) => timeHandler(time)}
																defaultOpenValue={moment('00:00', 'HH:mm')}
																format='HH:mm'
																bordered={false}
																style={{ fontSize: 20, marginTop: 10, color: "#959595", width: "100%" }}
															//placeholder=""
															/>
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	{/* <span className="mdc-floating-label" id="my-label-id">Select Time</span> */}
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															</span>
														</label>
													</div>

													{/* <TimePicker 
															onChange={(timestamp,time)=>timeHandler(time)} 
															defaultOpenValue={moment('00:00', 'HH:mm')} 
															format='HH:mm'
														/> */}


													<div className="col-lg-4 col-md-6">
														<div className="select-field">
															<label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
																<span className="mdc-notched-outline">
																	<span className="mdc-notched-outline__leading"></span>
																	<span className="mdc-notched-outline__notch">
																		<span className="mdc-floating-label" id="my-label-id">{visitMethodName ? visitMethodName : "Visit Method"}</span>
																	</span>
																	<span className="mdc-notched-outline__trailing"></span>
																</span>
																<span className="material-icons">arrow_drop_down</span>
															</label>
															{

															}
															<ul className="dropdown">
																<>
																	{
																		methods.map((item, index) =>
																			<li onClick={() => selectMethod(item)} key={index}>{item}</li>
																		)
																	}
																</>
															</ul>
														</div>

													</div>

													{/* Task Todo List Starts  */}
													<div className="select-field col-md-4">
														<label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	<span className="mdc-floating-label" id="my-label-id">{taskTodo ? taskTodo : "Task Status"}</span>
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															</span>
															<span className="material-icons">arrow_drop_down</span>
														</label>
														{

														}
														<ul className="dropdown">
															<>
																{
																	taskMethods.map((item, index) =>
																		<li onClick={() => setTaskTodo(item)} key={index}>{item}</li>
																	)
																}
															</>
														</ul>
													</div>
													{/* Task Todo List Ends  */}

													{/* <div className="col-lg-4 col-md-6">
														<label className="mdc-text-field mdc-text-field--outlined">
															<input maxlength="50" onChange={onChangeVenueHandler} type="text" className='mdc-text-field__input active' aria-labelledby="my-label-id"/>
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	<span className="mdc-floating-label" id="my-label-id">Address</span>
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															</span>
														</label>
													</div> */}
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12">
												<div className="outlined-textfield-wrap mt-10">
													<div className="material-textfield">
														<textarea maxlength="200" onChange={onChangeNotesHandler} id="message" className="form__field" placeholder="" rows="6"></textarea>
														<label>Agenda</label>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-8 col-sm-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													<input onChange={(e) => uploadImageHandler(e, "image")} type="file" accept="image/jpg,image/jpeg,image/png" id="attach-file" className="mdc-text-field__input" aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">Upload Image</span>
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
													<span className="material-icons">image</span>

												</label>
												<small className="short-description">Image can only be of the following types : jpg, jpeg, png with limit upto 5MB</small>
												<>

													{

														uploadImagePath.length > 0 && uploadImagePath.length <= 1 ?
															uploadImagePath.map((data, index) =>
																<> 
                                 {/* {console.log("D-A-T-A->", data)} */}
																	<img height="100" width="100" src={data[index].path} />
																	<button className='close-btn' type="button" onClick={(e) => { removeSpecificImage(index) }} ><span className="material-icons close">close</span></button>

																</>
															)
															:
															// 
															uploadImagePath.map((data, index) =>
																// console.log("NUMBER OF IMAGES",data[0])
																<>  
                                {/* {console.log("D-A-T-A->", data)} */}
																	<img height="100" width="100" src={data[0].path} />
																	<button className='close-btn' type="button" onClick={(e) => { removeSpecificImage(index) }} ><span className="material-icons close">close</span></button>

																</>
															)
													}
												</>
											</div>
											<div className="col-md-4 col-sm-4">
												<button type="button" id='get_file' className="mdc-button mdc-button--raised mt-5" onClick={addMoreImages} >
													<span className="mdc-button__label button-style-visit">Add More Images</span>
												</button>
											</div>

											{/* <span class="material-icons addIconStyle" onClick={(e)=>uploadImageHandler(e,"image")}>add_circle</span> */}
										</div>
										{/* HERE 20 APRIL  */}



										{
											addImagePath.length > 0 ?
												addImagePath.map((data, index) => {
													return (
														<div className="row">
															<div className="col-md-8 col-sm-4 add-more-resource-cross">
																<label forhtml="add-video" className="mdc-text-field mdc-text-field--outlined">
																	<input onChange={(e) => addFileResourcesHandler(e, index)} type="file" id="add-video" className="mdc-text-field__input" aria-labelledby="my-label-id" accept=".pdf,.doc,.docx" />
																	<span className="mdc-notched-outline">
																		<span className="mdc-notched-outline__leading"></span>
																		<span className="mdc-notched-outline__notch">
																			<span className="mdc-floating-label" id="my-label-id">{data.name ? data.name : "Add Resources"}</span>
																		</span>
																		<span className="mdc-notched-outline__trailing"></span>
																	</span>
																	<span className="material-icons">attach_file</span>
																</label>
																<small className="short-description">File can only be of the following types : pdf, doc, docx with limit upto 5MB</small>
															</div>
															{index == 0 ?
																<div className="col-md-4 col-sm-4">
																	<button type="button" className="mdc-button mdc-button--raised mt-5" onClick={addMoreResources} >
																		<span className="mdc-button__label button-style-visit">Add More Resources</span>
																	</button>
																</div> : null}
															{
																index > 0 ?
																	<button className='close-btn resource-button cross-btn-create-visit' type="button" onClick={(e) => { removeSpecificResource(index) }}><span className="material-icons close">close</span></button>
																	:
																	null
															}

														</div>

													)
												})
												:
												null
										}



										{
											multipleUrls.length > 0 ?
												multipleUrls.map((data, index) => {
													return (
														<div className="row">
															<div className="col-md-8 col-sm-8 add-more-url-cross">
																<label className="mdc-text-field mdc-text-field--outlined">
																	<input onChange={(e) => onChangeVideoHandler(e, index)} value={data} type="text" multiple className={data ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
																	<span className="mdc-notched-outline">
																		<span className="mdc-notched-outline__leading"></span>
																		<span className="mdc-notched-outline__notch">
																			<span className="mdc-floating-label" id="my-label-id">Add Youtube/Vimeo Video Url</span>
																		</span>
																		<span className="mdc-notched-outline__trailing"></span>
																	</span>
																	<span className="material-icons">videocam</span>
																</label>
															</div>
															{index == 0 ?
																<div className="col-md-4 col-sm-4">
																	<button type="button" className="mdc-button mdc-button--raised mt-5" onClick={addMoreUrls}>
																		<span className="mdc-button__label button-style-visit">Add More Urls</span>
																	</button>
																</div> : null}

															{
																index > 0 ?
																	<button className='close-btn resource-button cross-btn-create-visit' type="button" onClick={(e) => { removeSpecificUrls(index) }}><span className="material-icons close">close</span></button>
																	:
																	null
															}
														</div>
													)
												})
												:
												null
										}



										<div className="row">
											<button type='submit' disabled={blockButton} className="mdc-button mdc-button--raised mt-5">
												<span className="mdc-button__label">Submit</span>
											</button>
										</div>

									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog open={showAvailabilityModal} onClose={() => setShowAvailabilityModal(false)} aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">User is not available on following dates-</DialogTitle>
				<DialogContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>S.No.</TableCell>
									<TableCell>Start Date</TableCell>
									<TableCell>End Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									leavesArray.length > 0 ? leavesArray.map((items, index) => (
										<TableRow key={index}>
											<TableCell>{index + 1}</TableCell>
											<TableCell><Moment format="DD-MM-YYYY">{items.start_date}</Moment></TableCell>
											<TableCell><Moment format="DD-MM-YYYY">{items.end_date}</Moment></TableCell>
										</TableRow>

									)) : null
								}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setShowAvailabilityModal(false); setBlockButton(false) }} color="primary" autoFocus>OK</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default NewMentoringSupportPage;