import React, { useState, useEffect } from 'react';

//Import Images
import noTaskImage from '../../images/no-task.png';

const EmptyData = (props) => {

    return (
        <div class="main-wrapper mtb-70">
		    <div class="container">	
			    <div class="dashboard-wrap no-task-page">
				    <div class="row">
						<div class="col-md-12">
							<div class="content-block-right">
								<div class="task-listing-block">
									<div class="text-center message-block">
										<img src={noTaskImage} />
										<h1>{props.title}</h1>
										<p>{props.desc}</p>
									</div>	
								</div>
							</div>	
					    </div>	
				    </div>
			    </div>		
		    </div>	
	    </div> 
    )
}

export default EmptyData;