import React, { useEffect } from "react";
import { $ } from "react-jquery-plugin";
import "./styles.css";
import { useState } from "react";
import {
  getSpecificUser,
  getUserProfileData,
  getUpcomingVisits,
  addRiskAssessmentLogs,
  getRiskAssessmentLogs,
  deleteRiskAssessmentLogs,
  updateRiskAssessmentLogs,
  getOccupant,
  addProfessionalDevelop,
  getProfessionalDevelops,
  deleteProfessioanlDevelopLogs,
  addQualityImprovmentPlan,
  getQualitymprovmentData,
  updateUserProfile,
  updateProfessionalProfile,
  uploadImage,
  updateUserProfileCeasedDate,
  updateProfessionalDevelopmentLog,
  updateQualityImprovement,
  addOccupants,
  UpdatetheOccupants,
  upDateOccupants,
  uploadCertificatesAPI,
  updateEducatorSideDataAPI,
  getSpefificVenue,
  addBusinessDays,
  updateUserData,
  getEngagementLogsData,
  addEngagementLogs,
  deleteEngagementRecordLogs,
  editEngagementLogs,
  addEmergencyContacts,
  getEmergencyConatcts,
  // updateUserResetData,
  // updateAsthmaResetData
} from "../../../functions/api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import HeadOfficeMainHeader from "../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader";
import EducatorsMainHeader from "../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader";
import LoaderComponent from "../../../commonComponents/LoaderComponent/LoaderComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Calendar from "react-calendar";
import moment from "moment";
import {
  ToastContainer,
  toast
} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//Import Images
import { makeStyles } from "@material-ui/core";
import EducatorFolders from "../../Educators/Folders/EducatorFolders";
import pdfIcons from '../../../images/pdfImage.png';
import excelIcons from '../../../images/excelImage.png';
import docIcons from '../../../images/docImage.png';
import ImageIcon from '../../../images/image_icon.png';
import { baseUrl } from "../../../config";
import DummyImage from '../../../images/user2.png'
import { confirmAlert } from "react-confirm-alert";
import { decode } from 'html-entities';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const professionalDevelopmentMethods = [
  "webinar",
  "reading",
  "workshop",
  "conference",
  "meeting",
  "podcast"
];
// const QualityImprovmentPlanSelect = [
//   "QA1",
//   "QA2",
//   "QA3",
//   "QA4",
//   "QA5",
//   "QA6",
//   "QA7",
// ];
const QualityImprovmentPlanSelect = [
  { key: "QA1 – Education Program and Practice", value: 'QA1' },
  { key: "QA2 – Children’s Health and Safety", value: 'QA2' },
  { key: "QA3 – Physical Environment", value: 'QA3' },
  { key: "QA4 – Staffing Arrangements", value: 'QA4' },
  { key: "QA5 – Relationships with Children", value: 'QA5' },
  { key: "QA6 – Collaborative Partnerships ", value: 'QA6' },
  { key: "QA7 – Governance and Leadership ", value: 'QA7' },
]
function getKeyByValue(value) {
  const matchingObject = QualityImprovmentPlanSelect.find(item => item.value === value);
  return matchingObject ? matchingObject.key : null;
}
const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"];

const sorter = {
  "sunday": 0,
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
}

let initialPDStates = {
  date: "",
  showDateCalender: false,
  method: "",
  showMethod: false,
  courseName: "",
  organisation: "",
  duration: "",
  showDurationCalender: false,
  certificate: [],
  fid: ''
};
let initialEngagementLogsObject = {
  startDate:'',
  endDate:'',
  primaryEdu:'',
  showStartDateCalender:false,
  showEndDateCalender:false
}
const EducatorViewProfilePage = (props) => {
  const history = useHistory();
  const uid = props.match.params.id;
  const [dob, setDob] = useState("");
  const store = useSelector((state) => state.auth);
  const [commencementDate, setCommencementDate] = useState("");
  const allOperationalDays = [{ id: 268, val: 'Sunday' },
  { id: 269, val: 'Monday' }, { id: 270, val: 'Tuesday' }, { id: 274, val: 'Wednesday' },
  { id: 271, val: 'Thursday' }, { id: 272, val: 'Friday' }, { id: 273, val: 'Saturday' }];
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [ceasedDate, setCeasedDate] = useState("");
  const [ceaseCalendar, setCeasecalendar] = useState(false);
  const [profileUid, setProfileId] = useState();
  const [username, setUsername] = useState("");
  const [userVisits, setUserVisits] = useState([]);
  const [userPicture, setUserPicture] = useState();
  const [userRole, setUserRole] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const value = new Date()
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEngagementLog, setOpenEngagementLog] = useState(false);
  const [openEditEngagementLog, setOpenEditEngagementLog] = useState(false);
  const [editEngagementLogId, setEditEngagementLogId] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [completedDate, setCompletedDate] = useState("");
  const [showCompletedDateCalender, setShowCompletedDateCalender] = useState(false);
  const [timeFrameDate, setTimeFrameDate] = useState(false);
  const [showReviewedDateCalender, setShowReviewedDateCalender] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [furtherAction, setFurtherAction] = useState("");
  const [riskAssessmentArray, setRiskAssessmentArray] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showTaskStatusDropdown, setShowTaskStatusDropdown] = useState(false);
  const [editRiskAssessmentId, setEditRiskAssessmentId] = useState("");
  const [sortedValue, setSortedValue] = useState('All');
  const [firstAidCourse, setFirstAidCourse] = useState();
  const [firstAidCompletionDate, setFirstAidCompletionDate] = useState('');
  const [cprCourseCompletionDate, setCprCourseCompletionDate] = useState('');
  const [asthCourseCompletionDate, setAsthCourseCompletionDate] = useState('');
  const [poolCourseCompletionDate, setPoolCourseCompletionDate] = useState('');
  // const [anapCourseCompletionDate, setAnapCourseCompletionDate] = useState('');
  // const [asthmaCourse, setAsthmaCourse] = useState();
  const [cprCourse, setCprCourse] = useState();
  const [firstAidCourseReminder, setFirstAidCourseReminder] = useState('')
  const [crpCourseReminder, setCrpCourseReminder] = useState('')
  // const [asthmaCourseReminder, setAsthmaCourseReminder] = useState('')
  // const [anaphylaxisCourseReminder, setaAnaphylaxisCourseReminder] = useState('')
  // const [anaphylaxisCourse, setAnaphylaxisCourse] = useState('');
  const [firstAidCal, setfirstAidCal] = useState(false);
  const [cprCourseCal, setcprCourseCal] = useState(false);
  const [asthCourseCal, setasthCourseCal] = useState(false);
  const [anapCourseCal, setanapCourseCal] = useState(false);
  const [poolCourseCal, setPoolCourseCal] = useState(false);
  const [poolExpiryCourseCal, setPoolExpiryCourseCal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [uploadCertificate, setUploadCertificate] = useState([])
  const [openProfessionalDevelopmentModal, setOpenProfessionalDevelopmentModal] = useState(false);
  // Quality improvment Plan
  const [qualityImprovmentPlan, setQualityImprovmentPlan] = useState(false);
  const [qualityImprovmentPlanStates, setQualityImprovmentPlanStates] = useState("");
  const [showQualityImprovmentDropdown, setShowQualityImprovmentDropdown] = useState(false);
  const [editQualityImprovmentPlanModal, setEditQualityImprovmentPlanModal] = useState(false);
  const [qualityId, setQualityId] = useState("");
  const [AreaForImprovment, setAreaForImprovment] = useState("");
  const [nqsConcept, setNqsConcept] = useState("");
  // const [outcomeOrGoal, setOutcomeOrGoal] = useState("");
  const [getOutcome, setGetOutcome] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [sucessMeasure, setSucessMeasure] = useState("");
  const [professionalDevelop, setProfessionalDevelop] = useState([]);
  const [qualityImpromentArray, setQualityImpromentArray] = useState([]);
  const [recordEngagementArray, setRecordEngagementArray] = useState([]);
  const [professionalDevelopmentStates, setProfessionalDevelopmentStates] = useState(initialPDStates);
  const [addEngagementLogsObject, setAddEngagementLogsObject] = useState(initialEngagementLogsObject);
  const [professional_time, set_professional_time] = useState('');
  const [error, setError] = useState({ methodError: '' });
  const [dateofbirthCalendar, setDateofbirthCalendar] = useState(false);
  const [commenceCalendar, setCommenceCalendar] = useState(false);
  const [publicLiabilityInsuranceRemainder, setPublicLiabilityInsuranceRemainder] = useState('')

  //USER PROFILE ID STATE
  const [user_profile_id, set_user_profile_id] = useState('');
  const [profileID, setProfileID] = useState('');

  //USER ID
  let userID = window.localStorage.getItem('uid');

  // Residential Details 
  const [eduBlueCard, setEduBlueCard] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [sightedDate, setSightedDate] = useState('');

  //New Fields
  const [eduTeacherReg, setEduTeacherReg] = useState('');
  const [teacherExpiryDate, setTeacherExpiryDate] = useState('');

  // Public Liability
  const [insuranceProvider, setInsuranceProvider] = useState();
  const [policyNumber, setPolicyNumber] = useState();
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState();
  const [showInsuranceExpiryCal, setInsuranceExpiryCal] = useState(false);

  // Vehicle
  const [licenceNumber, setLicenceNumber] = useState();
  const [vehicleMaintenanceDate, setVehicleMaintenanceDate] = useState();
  const [vehicleMaintenanceCal, setVehicleMaintenanceCal] = useState(false);
  const [vehicleAuditDate, setVehicleAuditDate] = useState();
  const [vehicleAuditCal, setVehicleAuditCal] = useState(false);
  const [vehicleMake, setVehicleMake] = useState();
  const [vehicleModel, setVehicleModel] = useState();
  const [vehicleYear, setVehicleYear] = useState();
  const [vehicleRegisteration, setVehicleRegisteration] = useState();
  const [registrationExpiryCal, setRegistrationExpiryCal] = useState(false);
  const [registrationExpiryDate, setRegisterationExpiryDate] = useState();
  const [licenceExpiryDate, setLicenceExpiryDate] = useState();
  const [licenceExpiryCal, setLicenceExpiryCal] = useState(false);
  const [restraintDate, setRestraintDate] = useState();
  const [maintenanceDate, setMaintenanceDate] = useState();
  const [restraintOpen, setRestraintOpen] = useState(false);
  const [maintenanceOpen, setMaintenanceOpen] = useState(false)

  //  Education Details  :
  const [registerNumber, setRegisterNumber] = useState('')
  let categories = [
    "Environment",
    // "Emergency & Evacuation",
    "Emergency Evacuation and Lockdown",
    "Animals / Pets",
    "Water Safety",
    "Excursion",
    "Enrolment Forms",
    // "Transport",
    "Medical Management",
    "Sleep and Rest",
    "Safe Arrival and Delivery of Children",
  ];
  //DropDown Value Residence
  let residence = [
    "Residence",
    "Venue"
  ];
  //DropDown Value Status--BasicInfo
  const statusBasic = [
    "Educator",
    "Educator Assistant",
    "Relief Educator"
  ];

  const clearOptions = [
    "Completion Date",
    "Reminder",
    "Expiry Date",
    "Certificate",
    "Clear All"
  ];

  // Basic Information

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [homeNumber, setHomeNumber] = useState('');
  const [suburbName, setSuburbName] = useState('');
  //New Fields Basic Information 
  const [basicResidence, setBasicResidence] = useState('')
  const [basicStatus, setBasicStatus] = useState('')

  // New Field For Personal Profile Edit .
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');

  // Up and down on Onclick Functionality 
  const [consistId, setConsisitId] = useState(['0']);
  const [toggleOn, setToggleOn] = useState(false);
  const [qualificationIssueDate, setQualificationIssueDate] = useState()
  const [annualAssessmentDate, setAnnualAssessmentDate] = useState()
  const [annualAssessmentCalendar, setAnnualAssessmentCalendar] = useState(false)
  const [annualAssessmentReminder, setAnnualAssessmentReminder] = useState('')

  // Remainders
  const [drivingLiscenceReminder, setDrivingLiscenceReminder] = useState('');
  const [auditDateReminder, setAuditDateRemainder] = useState('');
  const [maintenanceDateRemainder, setMaintenanceDateremainder] = useState('');
  const [vehicleRegReminder, setVehicleRegReminder] = useState('');
  const [vehicleRegisterationReminder, setVehicleRegisterationReminder] = useState('')
  const [poolSafetyCourseReminder, setPoolSafetyCourseReminder] = useState('')


  // Calendars
  const [expiryDateCalender, setExpiryDateCalender] = useState(false)
  const [sightedDateCalender, setSightedDateCalender] = useState(false)
  //New Fields Calendar
  const [teacherDateCalender, setTeacherDateCalender] = useState(false)
  // const Check

  const [editableData, setEditableData] = useState([])
  const [isEdit, setIsEdit] = useState(false);
  const [clearPoolSafetyOption, setClearPoolSafetyOption] = useState('');

  // Data for Field Occupants
  const [childDataOccupant, setChildDataOccupant] = useState([{
    field_occupant_first_name: [{ "value": '' }],
    field_occupant_last_name: [{ "value": '' }],
    field_occupant_date_of_birth: [{ "value": '' }],
    show_calendar: false,
    field_all_occupants: ''
  }])

  // Data for Field Adult Occupants
  const [adultDataOccupant, setAdultDataOccupant] = useState([{
    field_occupant_first_name: [{ "value": '' }],
    field_occupant_last_name: [{ "value": '' }],
    field_occupant_date_of_birth: [{ "value": '' }],
    show_calendar: false,
    show_calendar_blue_card: false,
    show_calendar_sighted_date: false,
    show_calendar_teacher_date: false,
    field_all_occupants: '',
    field_occupant_blue_card: [{ "value": '' }],
    field_occupant_card_expiry_date: [{ "value": '' }],
    field_occupant_date_signed: [{ "value": '' }],
    field_reminder_blue_card: [{ "value": '' }],
    field_teacher_registration: [{ "value": '' }],
    field_teacher_expiry_date: [{ "value": '' }],
    field_teacher_adult_reminder: [{ "value": '' }],
    dropDown: false,
    dropDown1: false
  }])

  // For Certificates 
  const [firstAidCertificate, setFirstAidCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [cprCertificate, setCprCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [asthmaCertificate, setAsthmaCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [anaphylaxisCertificate, setAnaphylaxisCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [poolCertificate, setPoolCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [publicInsuranceCertificate, setPublicInsuranceCertificate] = useState([{ value: '', id: '', url: '' }]);

  // Reminder 
  const [educatorReminder, setEducatorReminder] = useState('')
  const [educatorTeacherReminder, setEducatorTeacherReminder] = useState('')

  // Education
  const [qualificationDetail, setQualificationDetail] = useState([{
    qualification_detail: '',
    issue_date: '',
    on_calender: false
  },])
  const [qualificationCount, setQualificationCount] = useState(1)

  // Expiray dates for First  Aid Courses
  const [firstAidExpiry, setFirstAidExpiry] = useState(false);
  const [firstAidExpiryDate, setFirstAidExpiryDate] = useState('');
  const [poolExpiryDate, setPoolExpiryDate] = useState('');
  const [cprExpiry, setCprExpiry] = useState(false);
  const [cprExpiryDate, setCprExpiryDate] = useState('')
  const [asthmaExpiry, setAsthmaExpiry] = useState(false)
  const [asthmaExpiryDate, setAsthmaExpiryDate] = useState('')
  const [anaphylaxixExpiry, setAnaphlaxixExpiry] = useState(false)
  const [anaphylaxisExpiryDate, setAnaphylaxisExpiryDate] = useState('')
  const [annualAssessmentExpiry, setAnnualAssessmentExpiry] = useState(false)
  const [annualAssessmentExpiryDate, setAnnualAssessmentExpiryDate] = useState('')
  const [operationalDaysTiming, setOperationalDaysTimings] = useState([]);
  const [selectedIdsOfOD, setSelectedIdsOfOD] = useState([])
  const [contacts, setContacts] = useState([{ field_title:"",
    field_phone:""}, { field_title:"",
      field_phone:""}]);
  const [medicalConditions, setMedicalConditions] = useState([""]);
  //--------------------------STATE FOR pdl -------------------
  const [isEditPDL, setIsEditPDL] = useState(false)

  useEffect(() => {
    $(".accordion-content:not(:first-of-type)").css("display", "none");
    $(".js-accordion-title:first-of-type").addClass("open");
    $(".js-accordion-title").click(function () {
      $(".open").not(this).removeClass("open").next().slideUp(300);
      $(this).toggleClass("open").next().slideToggle(300);
    });
    $(function () {
      $(".mdc-text-field--outlined .mdc-text-field__input").on(
        "focusout",
        function () {
          if ($(this).val().length > 0) {
            $(this).addClass("active");
          } else {
            $(this).removeClass("active");
          }
        }
      );
    });
    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
          $('.dropdown').slideUp();
        }
      });
    });
  }, [])

  useEffect(() => {
    window.localStorage.removeItem('folderParentId');
    onPageInit()
    // eslint-disable-next-line
  }, [store.userRole, professionalDevelopmentStates.certificate.length]);

  async function onPageInit() {
    // let userSavedPic = window.localStorage.getItem("user_picture")
    // if (userSavedPic) {
    //   setUserPicture(userSavedPic);
    // }
    // getProfessionalDevelop();
    // getQualitymprovmentDataList();
    // getRiskAssessmentLogsData();
    async function getInitialData() {
      window.localStorage.setItem('educator_uid', uid);
      let authToken = window.localStorage.getItem("auth_token");
      if (store.userRole !== undefined) {
        setUserRole(store.userRole);
      }
      let _userVisits = await getUpcomingVisits(uid);
      if (_userVisits.status === 200) {
        setShowLoader(false);
        if (_userVisits.data.data.length > 0) {
          let currentStatus = _userVisits.data.data.filter((item => item.field_status_visit === 'Completed'))
          setUserVisits(currentStatus);
        }
      } else {
        setShowLoader(false);
      }
      let responseData = await getEmergencyConatcts(uid)
      let filteredArray = responseData?.data?.data && responseData?.data?.data.length > 0
        ? responseData?.data?.data.filter(item => item.field_title !== '' && item.field_phone !== '')
        : [{ field_title: "", field_phone: "" }, { field_title: "", field_phone: "" }];
      if (filteredArray.length == 1) {
        filteredArray.push({ field_title: "", field_phone: "" })
      }
      if (filteredArray.length > 0) {
        setContacts(filteredArray)
      }

      let response = await getSpecificUser(uid, authToken);
      if (response.status === 200) {
        set_user_profile_id(uid)
        const firstName = response?.data?.data?.field_first_name?.[0]?.value;
        const lastName = response?.data?.data?.field_last_name?.[0]?.value;
        const username = lastName ? `${firstName} ${lastName}` : firstName;
        setUsername(username);
        const mail = response?.data?.data?.mail?.[0]?.value;
        setEmail(mail);
        if (response.data.data.educator_profiles.length > 0) {
          let profileId = response.data.data.educator_profiles[0].target_id;
          let profileData = await getUserProfileData(profileId);
  setProfileID(profileId)

          if (profileData.status === 200) {
            let mainProfileData = profileData.data.data
            if(mainProfileData.field_please_provide_details_bel && mainProfileData.field_please_provide_details_bel.length > 0){
              const valuesArray = mainProfileData.field_please_provide_details_bel.map(item => item.value);
              setMedicalConditions(valuesArray)
            }
            if (mainProfileData.field_adult_occupants && mainProfileData.field_adult_occupants.length > 0) {
              let requiredData = [];
              for (let i = 0; i < mainProfileData.field_adult_occupants.length; i++) {
                let resultantIds = [];
                const responseParagraph = await getOccupant(mainProfileData.field_adult_occupants[i].target_id);
                if (mainProfileData.field_adult_occupants[i]?.target_type) {
                  let firstName = responseParagraph?.data?.data?.field_occupant_first_name?.[0]?.value || '';
                  let lastName = responseParagraph?.data?.data?.field_occupant_last_name?.[0]?.value || '';
                  let dob = responseParagraph?.data?.data?.field_occupant_date_of_birth?.[0]?.value || '';
                  let blueCard = responseParagraph?.data?.data?.field_occupant_blue_card?.[0]?.value || '';
                  let expiryDate = responseParagraph?.data?.data?.field_occupant_card_expiry_date?.[0]?.value || '';
                  let sightedDate = responseParagraph?.data?.data?.field_occupant_date_signed?.[0]?.value || '';
                  let reminder = responseParagraph?.data?.data?.field_reminder_blue_card?.[0]?.value || '';
                  let teacherReg = responseParagraph?.data?.data?.field_teacher_registration?.[0]?.value || '';
                  let teacherExpDate = responseParagraph?.data?.data?.field_teacher_expiry_date?.[0]?.value || '';
                  let teacherReminder = responseParagraph?.data?.data?.field_teacher_adult_reminder?.[0]?.value || '';
                  resultantIds.push({
                    "target_id": mainProfileData.field_adult_occupants[i].target_id,
                    "target_revision_id": mainProfileData.field_adult_occupants[i].target_revision_id
                  })
                  requiredData.push({
                    field_occupant_first_name: [{ "value": firstName }],
                    field_occupant_last_name: [{ "value": lastName }],
                    field_occupant_date_of_birth: [{ "value": dob }],
                    show_calendar: false,
                    show_calendar_blue_card: false,
                    show_calendar_sighted_date: false,
                    show_calendar_teacher_date: false,
                    field_all_occupants: resultantIds,
                    field_occupant_blue_card: [{ "value": blueCard }],
                    field_occupant_card_expiry_date: [{ "value": expiryDate }],
                    field_occupant_date_signed: [{ "value": sightedDate }],
                    field_reminder_blue_card: [{ "value": reminder }],
                    field_teacher_registration: [{ "value": teacherReg }],
                    field_teacher_expiry_date: [{ "value": teacherExpDate }],
                    field_teacher_adult_reminder: [{ "value": teacherReminder }]
                  })
                }
              }
              setAdultDataOccupant(requiredData);
            }

            if (mainProfileData.field_all_occupants && mainProfileData.field_all_occupants.length > 0) {
              setShowLoader(true)
              let requiredData = [];
              for (let i = 0; i < mainProfileData.field_all_occupants.length; i++) {
                let resultantIds = [];
                const responseParagraph = await getOccupant(mainProfileData.field_all_occupants[i].target_id);
                if (mainProfileData.field_all_occupants[i]?.target_type) {
                  let firstName = responseParagraph?.data?.data?.field_occupant_first_name?.[0]?.value || '';
                  let lastName = responseParagraph?.data?.data?.field_occupant_last_name?.[0]?.value || '';
                  let dob = responseParagraph?.data?.data?.field_occupant_date_of_birth?.[0]?.value || '';
                  resultantIds.push({
                    "target_id": mainProfileData.field_all_occupants[i].target_id,
                    "target_revision_id": mainProfileData.field_all_occupants[i].target_revision_id
                  })
                  requiredData.push({
                    field_occupant_first_name: [{ "value": firstName }],
                    field_occupant_last_name: [{ "value": lastName }],
                    field_occupant_date_of_birth: [{ "value": dob }],
                    show_calendar: false,
                    field_all_occupants: resultantIds
                  })
                }
              }
              setShowLoader(false)
              setChildDataOccupant(requiredData)
            }
            const dob = mainProfileData.field_d_o_b?.[0]?.value ?? "";
            setDob(dob);

            if (profileId !== "") {
              let auth_token = window.localStorage.getItem("auth_token");
              let profile_response = await getUserProfileData(
                profileId,
                auth_token
              );
              if (profile_response.status === 200) {
                const userPicture = profile_response?.data?.data?.field_profile_picture?.[0]?.url ?? "";
                if (userPicture !== "") {
                  setUserPicture(userPicture);
                }
              }
            }

            // Certificates Data of Courses
            const updateCertificate = (certificateType) => {
              const certificate = mainProfileData?.[certificateType]?.[0];
              if (certificate) {
                const { target_id: id = '', url = '' } = certificate;
                const value = url.slice(66);
                switch (certificateType) {
                  case 'field_certificate_document':
                    setFirstAidCertificate([{ value, id, url }]);
                    break;
                  case 'field_cpr_certificate':
                    setCprCertificate([{ value, id, url }]);
                    break;
                  case 'field_asthma_course_certificate':
                    setAsthmaCertificate([{ value, id, url }]);
                    break;
                  case 'field_anaphylaxis_certificate':
                    setAnaphylaxisCertificate([{ value, id, url }]);
                    break;
                  case 'field_pool_safety_cert':
                    setPoolCertificate([{ value, id, url }])
                    break;
                  case 'field_public_ins_certificate':
                    setPublicInsuranceCertificate([{ value, id, url }])
                    break;
                  default:
                    break;
                }
              }
            };
            // Usage
            updateCertificate('field_certificate_document');
            updateCertificate('field_cpr_certificate');
            // updateCertificate('field_asthma_course_certificate');
            // updateCertificate('field_anaphylaxis_certificate'); 
            updateCertificate('field_pool_safety_cert');
            updateCertificate('field_public_ins_certificate');

            const updateStateIfNotEmpty = (dataKey, setStateFunction) => {
              const dataValue = mainProfileData?.[dataKey]?.[0]?.value ?? '';
              if (dataValue !== '') {
                setStateFunction(dataValue);
              }
            };

            const updates = [
              { key: 'field_residence', setter: setBasicResidence },
              { key: 'field_status', setter: setBasicStatus },
              { key: 'field_educator_reminder', setter: setEducatorReminder },
              { key: 'field_blue_card_', setter: setEduBlueCard },
              { key: 'field_blue_card_expiry_date', setter: setExpiryDate },
              { key: 'field_signed_date', setter: setSightedDate },
              { key: 'field_teacher_registration', setter: setEduTeacherReg },
              { key: 'field_teacher_expiry_date', setter: setTeacherExpiryDate },
              { key: 'field_teacher_reminder', setter: setEducatorTeacherReminder },
              { key: 'field_registration', setter: setRegisterNumber },
              { key: 'field_insurance_provider', setter: setInsuranceProvider },
              { key: 'field_public_reminder_date', setter: setPublicLiabilityInsuranceRemainder },
              { key: 'field_policy_number', setter: setPolicyNumber },
              { key: 'field_insurance_expiry_date', setter: setInsuranceExpiryDate },
              { key: 'field_licence_number', setter: setLicenceNumber },
              { key: 'field_vehicle_maintenance_date', setter: setVehicleMaintenanceDate },
              { key: 'field_vehicle_expiry_date', setter: setMaintenanceDate },
              { key: 'field_vehicle_restraint_expiry', setter: setRestraintDate },
              { key: 'field_vehicle_audit_date', setter: setVehicleAuditDate },
              { key: 'field_vehicle_make', setter: setVehicleMake },
              { key: 'field_license_exp_reminder_date', setter: setDrivingLiscenceReminder },
              { key: 'field_reg_exp_reminder_date', setter: setVehicleRegisterationReminder },
              { key: 'field_restraint_audit_reminder', setter: setAuditDateRemainder },
              { key: 'field_reg_exp_reminder_date', setter: setVehicleRegReminder },
              { key: 'field_maintenance_reminder_date', setter: setMaintenanceDateremainder },
              { key: 'field_vehicle_model', setter: setVehicleModel },
              { key: 'field_vehicle_year', setter: setVehicleYear },
              { key: 'field_registration', setter: setVehicleRegisteration },
              { key: 'field_registration_expiry_date', setter: setRegisterationExpiryDate },
              { key: 'field_licence_expiry_date', setter: setLicenceExpiryDate },
              { key: 'field_commencement_date', setter: setCommencementDate },
              { key: 'field_annual_assessment_completi', setter: setAnnualAssessmentDate },
              { key: 'field_business_expiry_date', setter: setAnnualAssessmentExpiryDate },
              { key: 'field_set_reminder_frequency', setter: setAnnualAssessmentReminder },
              { key: 'field_ceased_date', setter: setCeasedDate },
              { key: 'field_first_aid_course', setter: setFirstAidCourse },
              { key: 'field_first_aid_completion_date', setter: setFirstAidCompletionDate },
              { key: 'field_first_aid_course_reminder', setter: setFirstAidCourseReminder },
              { key: 'field_cpr_course', setter: setCprCourse },
              { key: 'field_cpr_course_completion_date', setter: setCprCourseCompletionDate },
              // { key: 'field_asthma_expiry_date', setter: setAsthmaExpiryDate },
              // { key: 'field_anaphylaxis_expiry_date', setter: setAnaphylaxisExpiryDate },
              { key: 'field_cpr_expiry_date', setter: setCprExpiryDate },
              { key: 'field_first_aid_expiry_date', setter: setFirstAidExpiryDate },
              { key: 'field_cpr_course_reminder', setter: setCrpCourseReminder },
              // { key: 'field_asthma_course', setter: setAsthmaCourse },
              // { key: 'field_asth_course_complete_date', setter: setAsthCourseCompletionDate },
              // { key: 'field_asthma_course_reminder', setter: setAsthmaCourseReminder },
              // { key: 'field_anaphylaxis_course', setter: setAnaphylaxisCourse },
              // { key: 'field_anap_course_complete_date', setter: setAnapCourseCompletionDate },
              // { key: 'field_anaphylxis_course_reminder', setter: setaAnaphylaxisCourseReminder },
              { key: 'field_pool_safety_cert_comp_date', setter: setPoolCourseCompletionDate },
              { key: 'field_pool_safety_cert_exp_date', setter: setPoolExpiryDate },
              { key: 'field_pool_safety_cert_set_remin', setter: setPoolSafetyCourseReminder },
            ];

            const updatePromises = updates.map(({ key, setter }) =>
              updateStateIfNotEmpty(key, setter)
            );

            await Promise.all(updatePromises);

            // Multi Qualification Data Getting
            if (mainProfileData.field_qualification !== undefined && mainProfileData.field_qualification !== "" && mainProfileData.field_issue_date !== undefined) {
              let qualification_data = [];
              for (let i = 0; i < mainProfileData.field_qualification.length; i++) {
                const qualification = mainProfileData.field_qualification[i] ? mainProfileData.field_qualification[i].value : ""
                const issue_date = mainProfileData.field_issue_date[i] ? mainProfileData.field_issue_date[i].value : ''
                qualification_data.push({
                  qualification_detail: qualification,
                  issue_date: issue_date,
                  on_calender: false
                })
              }
              setQualificationDetail(qualification_data)
              setQualificationCount(mainProfileData.field_qualification.length)
            }
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        } else {
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
      }
    }

    try {
      await Promise.all([
        getProfessionalDevelop(),
        getQualitymprovmentDataList(),
        getRiskAssessmentLogsData(),
        getInitialData(),
        getEngagementLogsDataList()

      ]);
      // console.log('All functions executed successfully in parallel.');
    } catch (error) {
      console.error('Error occurred:', error);
    }
    function setCustomValidityForElement(elementId, message) {
      var element = document.getElementById(elementId);
      if (element !== null) {
        element.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity(message);
          } else {
            e.target.setCustomValidity("");
          }
        };
      }
    }
    function validateForm() {
      setCustomValidityForElement("completedDate", "Please select created date");
      setCustomValidityForElement("reviewedDate", "Please select reviewed date");
      setCustomValidityForElement("completionTimeframe", "Please enter the Timeframe for completion");
      setCustomValidityForElement("furtherAction", "Please enter the Further Action");
    }
    validateForm();
    getAllInitialData();
  }



  const commencementCalendar = () => {
    if (commenceCalendar === true) {
      setCommenceCalendar(false)
    }
    else {
      setCommenceCalendar(true)
    }
  }

  const annualAssessmentCalendarType = () => {
    if (annualAssessmentCalendar === true) {
      setAnnualAssessmentCalendar(false)
    }
    else {
      setAnnualAssessmentCalendar(true)
    }
  }
  const annualAssessmentCalendarExpiry = () => {
    if (annualAssessmentExpiry === true) {
      setAnnualAssessmentExpiry(false)
    }
    else {
      setAnnualAssessmentExpiry(true)
    }
  }

  const openCloseCalender = (type) => {
    if (type === 'expiryDate') {
      if (expiryDateCalender === true) {
        setExpiryDateCalender(false)
      } else { setExpiryDateCalender(true) }
    }

    if (type === 'sightedDate') {
      if (sightedDateCalender === true) {
        setSightedDateCalender(false)
      } else { setSightedDateCalender(true) }
    }
    if (type === 'teacherExpiryDate') {
      if (teacherDateCalender === true) {
        setTeacherDateCalender(false)
      } else { setTeacherDateCalender(true) }
    }
  }

  const commencementCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCommencementDate(fullYear)
    setCommenceCalendar(false)
  }
  const insuranceExpiryCalendar = () => {
    if (showInsuranceExpiryCal === true) {
      setInsuranceExpiryCal(false);
    }
    else {
      setInsuranceExpiryCal(true);
    }

  }

  const sightedDateCalendarSet = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setSightedDate(fullYear)
    setSightedDateCalender(false);
  }


  const expiryDateCalendar = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setExpiryDate(fullYear)
    setExpiryDateCalender(false);
  }

  const teacherDateCalendar = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setTeacherExpiryDate(fullYear)
    setTeacherDateCalender(false);
  }


  const annualAssessmentCalendarSet = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setAnnualAssessmentDate(fullYear)
    setAnnualAssessmentCalendar(false);
  }
  const annualAssessmentCalendarExpirySet = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setAnnualAssessmentExpiryDate(fullYear)
    setAnnualAssessmentExpiry(false);
  }

  const insuranceExpiryCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setInsuranceExpiryDate(fullYear)
    setInsuranceExpiryCal(false)
  }

  const vehicleMaintenanceCalendar = () => {
    if (vehicleMaintenanceCal === true) {
      setVehicleMaintenanceCal(false)
    }
    else {
      setVehicleMaintenanceCal(true)
    }
  }
  const vehicleMaintenanceExpiryCalendar = () => {
    if (maintenanceOpen === true) {
      setMaintenanceOpen(false)
    }
    else {
      setMaintenanceOpen(true)
    }
  }


  const vehicleMaintenanceCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setVehicleMaintenanceDate(fullYear)
    setVehicleMaintenanceCal(false)
  }

  const vehicleMaintenanceExpiryCalendarHandler = (newDate) => {

    let fullYear = moment(newDate).format('YYYY-MM-DD')

    setMaintenanceDate(fullYear)
    setMaintenanceOpen(false)
  }

  const vehicleAuditCalendar = () => {
    if (vehicleAuditCal === true) {
      setVehicleAuditCal(false)
    }
    else {
      setVehicleAuditCal(true)
    }
  }
  const vehicleAuditExpiryCalendar = () => {
    if (restraintOpen === true) {
      setRestraintOpen(false)
    }
    else {
      setRestraintOpen(true)
    }
  }

  const vehicleAuditCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setVehicleAuditDate(fullYear)
    setVehicleAuditCal(false)
  }
  const vehicleAuditExpiryCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')

    setRestraintDate(fullYear)
    setRestraintOpen(false)
  }

  const registrationExpiryCalendar = () => {
    if (registrationExpiryCal === true) {
      setRegistrationExpiryCal(false)
    }
    else {
      setRegistrationExpiryCal(true)
    }
  }

  const registrationExpiryCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setRegisterationExpiryDate(fullYear)
    setRegistrationExpiryCal(false)
  }

  const licenceExpiryCalendar = () => {
    if (licenceExpiryCal === true) {
      setLicenceExpiryCal(false)
    }
    else {
      setLicenceExpiryCal(true)
    }
  }

  const licenceExpiryCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setLicenceExpiryDate(fullYear)
    setLicenceExpiryCal(false)
  }

  // Invalid date issue 
  function _onDateFormat(date) {
    let newDate = date.split('-')
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
  }

  //ADD 
  async function professionaSubmit(e, type) {
    e.preventDefault();
    if (type) {
      setShowLoader(true);
      let object;
      if (!(uploadCertificate.length > 0)) {
        object = {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
          "field_task_status": [{ "value": professionalDevelopmentStates.method }],
          "field_course_name": [
            { "value": professionalDevelopmentStates.courseName }
          ],
          "field_organisation": [
            { "value": professionalDevelopmentStates.organisation }
          ],
        }
      }
      else {
        let professionalImage = await uploadImage(uploadCertificate[0].name, uploadCertificate[0].path)
        if (professionalImage.status === 201) {
          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ],
            field_new_upload_certificate: [{ target_id: professionalImage.data.data.fid[0].value }]
          }
        } else {
          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ]
          }
        }
      }

      const response = await updateProfessionalDevelopmentLog(editableData.id, object);
      if (response.status === 200) {
        await getProfessionalDevelop();
        toast.success("Professional Development log item updated successfully", {
          position: "top-center",
        });
        setShowLoader(false);
        setProfessionalDevelopmentStates(initialPDStates);
        setOpenProfessionalDevelopmentModal(false);
      } else {
        setShowLoader(false);
      }
      return;
    } else {
      if (validateFieldsPd()) {

        setShowLoader(true);
        let openingHours = professional_time;
        let timeArray = openingHours.split(':')
        let hours = timeArray[0]
        let minutes = timeArray[1]
        let finalOpeningHours = hours * 3600 + minutes * 60

        let object =
        {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          field_date_completed: [{ value: professionalDevelopmentStates.date }],
          field_task_status: [{ value: professionalDevelopmentStates.method }],
          field_course_name: [
            { value: professionalDevelopmentStates.courseName },
          ],
          field_organisation: [
            { value: professionalDevelopmentStates.organisation },
          ],
          field_select_time: [{ value: finalOpeningHours }]

        };

        if (professionalDevelopmentStates.certificate.length > 0) {
          let _imagesArray = []
          professionalDevelopmentStates.certificate.map(async (data) => {

            let professionalImage = await uploadImage(data.name, data.path)

            if (professionalImage.status === 201) {
              _imagesArray.push({ target_id: professionalImage.data.data.fid[0].value })

              if (_imagesArray.length === professionalDevelopmentStates.certificate.length) {
                Object.assign(object, { "field_new_upload_certificate": _imagesArray })


                let addProfessionalD = await addProfessionalDevelop(object);
                if (addProfessionalD.status === 201) {
                  let responseData = addProfessionalD.data.data

                  let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
                  let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";

                  //if for updateProfessionalProfile
                  if (professionalId !== "" && revisionId !== "") {
                    let user_id = uid

                    let profile_id = await getSpecificUser(user_id)
                    if (profile_id.status === 200) {
                      let objects =
                      {
                        "type": [{
                          "target_id": "professional_development",
                          "target_type": "paragraphs_type"
                        }],
                        "profileId": profile_id.data.data.educator_profiles[0].target_id,
                      };

                      let field_quality_arr = [];
                      if (professionalDevelop.length > 0) {
                        professionalDevelop.map((v) => {
                          return field_quality_arr.push({
                            "target_id": v.id,
                            "target_revision_id": v.revision_id
                          })
                        })
                      }

                      field_quality_arr.push({
                        "target_id": professionalId,
                        "target_revision_id": revisionId
                      })

                      let obj = {
                        "type": "educator",
                        "uid": [{
                          "target_id": userID
                        }],
                        "field_professional_development": field_quality_arr,
                      }

                      let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                      if (updateProfessionalDevelop.status === 200) {
                        await getProfessionalDevelop();
                        toast.success("Professional Development log item added successfully", {
                          position: "top-center",
                        });
                        setShowLoader(false);
                        setProfessionalDevelopmentStates(initialPDStates);
                        setOpenProfessionalDevelopmentModal(false);
                      } else {
                        setShowLoader(false);
                        toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                      }
                    }
                    else {
                      setShowLoader(false);
                      toast.error('something went wrong', { position: "top-center" });
                    }
                  }
                }
                else {
                  setShowLoader(false);
                  toast.error(addProfessionalD.message, { position: "top-center" });
                }
              }
              setShowLoader(false);
              await getProfessionalDevelop();
            } else {
              setShowLoader(false);
              toast.error(professionalImage.message, { position: "top-center" });
            }
          });
        }
        else {
          let addProfessionalD = await addProfessionalDevelop(object);
          if (addProfessionalD.status === 201) {
            let responseData = addProfessionalD.data.data

            let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
            let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";
            if (professionalId !== "" && revisionId !== "") {
              let user_id = uid
              let profile_id = await getSpecificUser(user_id)
              if (profile_id.status === 200) {
                let objects =
                {
                  "type": [{
                    "target_id": "professional_development",
                    "target_type": "paragraphs_type"
                  }],
                  "profileId": profile_id.data.data.educator_profiles[0].target_id,
                };
                let field_quality_arr = [];
                if (professionalDevelop.length > 0) {
                  professionalDevelop.map((v) => {
                    return field_quality_arr.push({
                      "target_id": v.id,
                      "target_revision_id": v.revision_id
                    })
                  })
                }
                field_quality_arr.push({
                  "target_id": professionalId,
                  "target_revision_id": revisionId
                })
                let obj = {
                  "type": "educator",
                  "uid": [{
                    "target_id": userID
                  }],
                  "field_professional_development": field_quality_arr,
                }
                let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                if (updateProfessionalDevelop.status === 200) {
                  toast.success("Professional Development log item added successfully", {
                    position: "top-center",
                  });
                  setShowLoader(false);
                  setOpenProfessionalDevelopmentModal(false)
                  await getProfessionalDevelop();
                } else {
                  setShowLoader(false);
                  toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                }

              }
              else {
                setShowLoader(false);
                toast.error('something went wrong', { position: "top-center" });
              }
            }
          }
          else {
            setShowLoader(false);
            toast.error(addProfessionalD.message, { position: "top-center" });
          }
        }
      }
    }

  }

  const handleOpenRecordEditEngagement = (data) => {
    let dataObj = {
      startDate: data.start_date,
      endDate: data.end_date,
      primaryEdu: data.primary_educator,
      showStartDateCalender: false,
      showEndDateCalender: false
    }
    setEditEngagementLogId(data.id)
    setAddEngagementLogsObject(dataObj)
    setOpenEditEngagementLog(true)
  }

  async function addEngagementLogsApi(e, type) {
    e.preventDefault()
    if(type){
      let object = {
        user_id: [{ value: userID }],
        primary_educator: [{ value: addEngagementLogsObject.primaryEdu }],
        start_date: [{ value: addEngagementLogsObject.startDate }],
        end_date: [{ value: addEngagementLogsObject.endDate }]
      };
      let addEngagementLogsApiResponse = await editEngagementLogs(object, editEngagementLogId);
      if (addEngagementLogsApiResponse.status === 200) {
        setOpenEditEngagementLog(false)
        setEditEngagementLogId('')
        setAddEngagementLogsObject(initialEngagementLogsObject)
        toast.success("Record of engagement log edited successfully", { position: "top-center" });
        getEngagementLogsDataList()
      } else {
        setShowLoader(false);
        toast.error(addEngagementLogsApiResponse.message, {
          position: "top-center",
        });
      }
    }else{
      let object = {
        user_id: [{ value: userID }],
        primary_educator: [{ value: addEngagementLogsObject.primaryEdu }],
        start_date: [{ value: addEngagementLogsObject.startDate }],
        end_date: [{ value: addEngagementLogsObject.endDate }]
      };
      let addEngagementLogsApiResponse = await addEngagementLogs(object);
      if (addEngagementLogsApiResponse.status === 200) {
        setOpenEngagementLog(false)
        toast.success("Record of engagement logs added successfully", { position: "top-center" });
        getEngagementLogsDataList()
      } else {
        setShowLoader(false);
        toast.error(addEngagementLogsApiResponse.message, {
          position: "top-center",
        });
      }
    }
  }


  //EDIT PROFESSIONAL DEVELOPMENT
  async function editProfessional(e) {
    setEditableData(e);
    const response = await getOccupant(e.id);
    setIsEdit(true);
    setIsEditPDL(true);
    const fidCheck = response.data.data.field_new_upload_certificate && response.data.data.field_new_upload_certificate.length > 0 && response.data.data.field_new_upload_certificate[0].target_id;

    setOpenProfessionalDevelopmentModal(true)
    set_professional_time(e.field_select_time)

    let object = { "name": (e.field_new_upload_certificate).slice(29, 47), "path": `${baseUrl}${e.field_new_upload_certificate}` }
    if (e.field_new_upload_certificate) {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [object],
        fid: fidCheck ? fidCheck : ''
      })
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [],
        fid: ''
      })
    }
  }

  //ADD QUALITY IMPROVMENT
  const createQualityImprovment = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    if (isEdit) {
      let object = {
        "type": [
          {
            "target_id": "quality_improvement_plan",
            "target_type": "paragraphs_type",
          },
        ],
        "field_area_for_improvement": [{ value: AreaForImprovment }],
        "field_how_will_i_get_outcome": [{ value: getOutcome }],
        "field_nqs_concept_standard": [{ value: nqsConcept }],
        "field_nqs_quality_area": [{ value: qualityImprovmentPlanStates }],
        // "field_outcome_or_goal_do_i_seek": [{ value: outcomeOrGoal }],
        "field_success_measure": [{ value: sucessMeasure }],
        "field_updated_timeframe": [{ value: timeFrame }],
      };

      const response = await updateQualityImprovement(qualityId, object);
      if (response.status === 200) {
        toast.success("Quality Improvement log item updated successfully", {
          position: "top-center",
        });
        onPageInit()
        setEditQualityImprovmentPlanModal(false)
        setTimeFrameDate(response.data.data.field_updated_timeframe)
        await getQualitymprovmentData();
      } else {
        setShowLoader(false);
      }

      return;
    } else {
      let object = {
        type: [
          {
            target_id: "quality_improvement_plan",
            target_type: "paragraphs_type",
          },
        ],
        field_area_for_improvement: [{ value: AreaForImprovment }],
        field_how_will_i_get_outcome: [{ value: getOutcome }],
        field_nqs_concept_standard: [{ value: nqsConcept }],
        field_nqs_quality_area: [{ value: qualityImprovmentPlanStates }],
        // field_outcome_or_goal_do_i_seek: [{ value: outcomeOrGoal }],
        field_success_measure: [{ value: sucessMeasure }],
        field_updated_timeframe
          : [{ value: timeFrame }],
      };

      let qualityImprovmentPlanResponse = await addQualityImprovmentPlan(object);
      if (qualityImprovmentPlanResponse.status === 201) {
        let target_id = qualityImprovmentPlanResponse.data.data.id[0].value
        let reveison_id = qualityImprovmentPlanResponse.data.data.revision_id[0].value
        let field_quality_arr = [];
        if (qualityImpromentArray.length > 0) {
          qualityImpromentArray.map((v) => {
            return field_quality_arr.push({
              "target_id": v.id,
              "target_revision_id": v.revision_id
            })
          })
        }

        field_quality_arr.push({
          "target_id": target_id,
          "target_revision_id": reveison_id
        })

        let obj = {
          "type": "educator",
          "uid": [{
            "target_id": userID
          }],
          "field_quality_improvement": field_quality_arr,
        }

        await updateProfessionalProfile(obj, profileUid);

        toast.success("Quality Improvment Assessment Log item added successfully", { position: "top-center" });
        onPageInit()
        setQualityImprovmentPlan(false);
        setEditQualityImprovmentPlanModal(false)
        await getQualitymprovmentDataList();
      }
      else {
        setShowLoader(false);
        toast.error(qualityImprovmentPlanResponse.message, {
          position: "top-center",
        });
      }
    };
  }

  //EDIT QUALITY IMPROVMENT
  const editQualityImprovement = (data) => {
    setQualityId(data.id);
    setQualityImprovmentPlanStates(data.field_nqs_quality_area)
    setAreaForImprovment(decode(data.field_area_for_improvement))
    setNqsConcept(decode(data.field_nqs_concept_standard))
    // setOutcomeOrGoal(decode(data.field_outcome_or_goal_do_i_seek))
    setGetOutcome(decode(data.field_how_will_i_get_outcome))
    setTimeFrame(data.field_updated_timeframe)
    setSucessMeasure(decode(data.field_success_measure))
    setEditQualityImprovmentPlanModal(true)
  }

  // Empty QUALITY IMPROVEMENT
  const emptyQualityImprovement = () => {
    setQualityImprovmentPlanStates('')
    setAreaForImprovment('')
    setNqsConcept('')
    // setOutcomeOrGoal('')
    setGetOutcome('')
    setTimeFrame('')
    setSucessMeasure('')
  }

  //GET QUALITY IMPROVMENT
  const getQualitymprovmentDataList = async () => {
    let responseData = await getQualitymprovmentData();
    if (responseData.status === 200) {
      if (responseData.data.data !== undefined && responseData.data.data.length > 0) {
        setShowLoader(false);
        setIsEdit(true);
        setQualityImpromentArray(responseData.data.data);
      } else {
        setShowLoader(false);
        setQualityImpromentArray([]);
      }
    } else {
      setShowLoader(false);
      toast.error(responseData.message, { position: "top-center" });
    }
  };

    //GET Record of Engagement Logs
    const getEngagementLogsDataList = async () => {
      let responseData = await getEngagementLogsData();
      if (responseData.status === 200) {
        if (responseData.data.data !== undefined && responseData.data.data.length > 0) {
          setShowLoader(false);
          setRecordEngagementArray(responseData.data.data);
        } else {
          setShowLoader(false);
          setRecordEngagementArray([]);
        }
      } else {
        setShowLoader(false);
        toast.error(responseData.message, { position: "top-center" });
      }
    };
  

  //GET ALL PROFESSIONAL DEVELOPMENT LISTING
  const getProfessionalDevelop = async () => {
    let response = await getProfessionalDevelops();
    if (response.status === 200) {
      if (response.data.data !== undefined && response.data.data.length > 0) {
        setProfessionalDevelop(response.data.data);
      } else {
        setProfessionalDevelop([]);
      }
    } else {
      toast.error(response.message, { position: "top-center" });
    }
  };

  //DELETE PROFESSIONAL DEVELOPMENT LOG AND QUALITY IMPROVMENT PLAN
  const deleteProfessioanlDevelop = async (id) => {
    setShowLoader(true);
    let response = await deleteProfessioanlDevelopLogs(id);
    if (response.status === 204) {
      setShowLoader(false);
      toast.success("Professional development Log item deleted successfully", {
        position: "top-center",
      });
      let newData = []
      if (professionalDevelop && professionalDevelop.length) {
        professionalDevelop.map((val) => {
          if (val.id !== id) {
            let tagretId = val.id;
            let revisionId = val.revision_id;
            return newData.push({ "target_id": tagretId, "target_revision_id": revisionId })
          }
          return null
        })
      }

      let profileNewData = {
        "type": [{ "target_id": "educator", "target_type": "profile_type" }],
        "field_professional_development": newData
      }
      await upDateOccupants(profileUid, profileNewData)
      setProfessionalDevelop([...professionalDevelop])
      await getProfessionalDevelop();
    } else {
      setShowLoader(false);
      toast.error(response.message, { position: "top-center" });
    }
  };

  function confirmDeleteProfessional(id) {
    confirmAlert({
      title: 'Do you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteProfessioanlDevelop(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const deleteQualityImprovement = async (id) => {
    setShowLoader(true);
    let response = await deleteProfessioanlDevelopLogs(id);
    if (response.status === 204) {
      setShowLoader(false);
      toast.success("Quality improvement plan item deleted successfully", {
        position: "top-center",
      });
      let newData = []
      if (qualityImpromentArray && qualityImpromentArray.length) {
        qualityImpromentArray.map((val) => {
          if (val.id !== id) {
            let tagretId = val.id;
            let revisionId = val.revision_id;
            return newData.push({ "target_id": tagretId, "target_revision_id": revisionId })
          }
          return null
        })
      }
      let profileNewData = {
        "type": [{ "target_id": "educator", "target_type": "profile_type" }],
        "field_quality_improvement": newData
      }
      await upDateOccupants(profileUid, profileNewData)
      setQualityImpromentArray([...qualityImpromentArray])
      await getQualitymprovmentDataList();
    } else {
      setShowLoader(false);
      toast.error(response.message, { position: "top-center" });
    }
  };

  function confirmDeleteQuality(id) {

    confirmAlert({
      title: 'Do you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteQualityImprovement(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  function confirmDeleteEngagementRecord(id) {

    confirmAlert({
      title: 'Do you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteRecordEngagement(id)
        }, 
        // deleteEngagementRecordLogs
        {
          label: 'No',
        }
      ]
    });
  }


  const deleteRecordEngagement = async (id) => {
    setShowLoader(true);
    let response = await deleteEngagementRecordLogs(id);
    if (response.status === 200) {
      setShowLoader(false);
      toast.success("Record of Engagemnet deleted successfully", {
        position: "top-center",
      });
      let newData = []
      if (recordEngagementArray && recordEngagementArray.length) {
        recordEngagementArray.map((val) => {
          if (val.id !== id) {
            return newData.push(val)
          }
          return null
        })
      }
      setRecordEngagementArray(newData)
    } else {
      setShowLoader(false);
      toast.error(response.message, { position: "top-center" });
    }
  };

  //28 Feb Working
  const getRiskAssessmentLogsData = async (sort_value = sortedValue) => {
    let sort = sort_value
    if (sort == 'Emergency Evacuation and Lockdown'){
      sort = 'All'
    }

    var responseData = await getRiskAssessmentLogs(uid, encodeURIComponent(sort));

    if (responseData.status === 200) {
      if (
        responseData.data.data !== undefined &&
        responseData.data.data.length > 0
      ) {
        if (sort_value == 'Emergency Evacuation and Lockdown'){
          let filterArray = responseData.data.data.filter((value) => value.category == 'Emergency & Evacuation' || value.category == 'Emergency Evacuation and Lockdown')
          return setRiskAssessmentArray(filterArray)
        }
        setRiskAssessmentArray(responseData.data.data);
      } else {
        setRiskAssessmentArray([]);
      }
    } else {
      toast.error(responseData.message, { position: "top-center" });
    }
  };

  const onChangeCompleteDate = (newDate) => {
    let fullYear = moment(newDate).format('DD-MM-YYYY');
    setCompletedDate(fullYear);
    setCurrentDate(fullYear);
    setShowCompletedDateCalender(false);
  };

  const showHideCalender = (value) => {
    if (value === "completed") {
      if (showCompletedDateCalender === true) {
        setShowCompletedDateCalender(false);
      } else {
        setShowCompletedDateCalender(true);
      }
    } else {
      if (showReviewedDateCalender === true) {
        setShowReviewedDateCalender(false);
      } else {
        setShowReviewedDateCalender(true);
      }
    }
  };

  const selectCategories = (value) => {
    setSelectedCategory(value);
    setShowCategoryDropdown(false);
  };
  const selectQualityImprovment = (value) => {
    setQualityImprovmentPlanStates(value);
    setShowQualityImprovmentDropdown(false);
  };
  const validateFields = () => {
    return true
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    let validateFieldsRes = validateFields();
    if (validateFieldsRes === true) {
      let object = {
        user_id: [{ value: uid }],
        date: [{ value: completedDate }],
        category: [{ value: selectedCategory }],
        further_action: [{ value: furtherAction }],
        additional_notes: [{ value: additionalNotes }],
      };
      let riskAssessmentResponse = await addRiskAssessmentLogs(object);
      if (riskAssessmentResponse.status === 200) {
        toast.success("Risk Assessment Log item added successfully", {
          position: "top-center",
        });
        setShowLoader(false);
        setOpen(false);
        await getRiskAssessmentLogsData();
      } else {
        setShowLoader(false);
        toast.error(riskAssessmentResponse.message, { position: "top-center" });
      }
    }
  };

  async function getAllInitialData() {
    let user_id = uid
    let auth_token = window.localStorage.getItem('auth_token');
    let user_response = await getSpecificUser(user_id, auth_token);
    if (user_response.status === 200) {

      if (user_response.data.data !== '' && user_response.data.data !== undefined) {
        if (user_response.data.data.field_first_name) {
          let name = user_response.data.data.field_first_name[0] ? user_response.data.data.field_first_name[0].value : "";
          setFirstName(name);
        }

        if (user_response.data.data.field_last_name) {
          let lastName = user_response.data.data.field_last_name[0] ? user_response.data.data.field_last_name[0].value : "";
          setLastName(lastName);
        }
        if (user_response.data.data.educator_profiles.length > 0) {
          let profileId = user_response.data.data.educator_profiles[0].target_id;
          setProfileId(profileId);
          let profileData = await getUserProfileData(profileId);
          if (profileData.status === 200) {

            if (profileData.data.data.field_home_number !== undefined && profileData.data.data.field_home_number !== "") {
              let _home = profileData.data.data.field_home_number[0] ? profileData.data.data.field_home_number[0].value : ""
              setHomeNumber(_home)
            }
            if (profileData.data.data.field_postcode !== undefined && profileData.data.data.field_postcode !== "") {
              let _postcode = profileData.data.data.field_postcode[0] ? profileData.data.data.field_postcode[0].value : ""
              setPostalCode(_postcode)
            }
            if (profileData.data.data.field_street_address !== undefined && profileData.data.data.field_street_address !== "") {
              let _street = profileData.data.data.field_street_address[0] ? profileData.data.data.field_street_address[0].value : ""
              if (_street.includes('<p>')) {
                setStreet($(_street).text());
              } else {
                setStreet(_street);
              }
            }

            if (profileData.data.data.field_suburb_name !== undefined && profileData.data.data.field_suburb_name !== "") {
              let _suburb = profileData.data.data.field_suburb_name[0] ? profileData.data.data.field_suburb_name[0].value : ""
              setSuburbName(_suburb)
            }
            if (profileData.data.data.field_business_operational_days !== undefined && profileData.data.data.field_business_operational_days !== "") {
              let timings = []
              let days = []
              let requiredResult = []
              profileData.data.data.field_business_operational_days.map(async function (value) {
                const operationalDays = await getSpefificVenue(value.target_id);
                days.push(operationalDays.data.data?.field_operational_days[0]?.target_id)
                timings.push({
                  opening_hours: moment.utc((operationalDays.data.data.field_operational_hours[0]?.from) * 1000).format('HH:mm'),
                  closing_hours: moment.utc((operationalDays.data.data.field_operational_hours[0]?.to) * 1000).format('HH:mm'),
                  id: operationalDays.data.data.field_operational_days[0]?.target_id
                })
                if (profileData.data.data.field_business_operational_days.length === timings.length) {
                  for (let i = 0; i < allOperationalDays.length; i++) {
                    let result = timings.find(({ id }) => id === allOperationalDays[i].id)

                    if (result === undefined) {
                      requiredResult.push({
                        opening_hours: null,
                        closing_hours: null,
                        id: allOperationalDays[i].id
                      })
                    } else {
                      requiredResult.push(result)
                    }
                  }
                }
              })
              setSelectedIdsOfOD(days)
              setOperationalDaysTimings(requiredResult)
            }
          }
        }
      }
    }
  }

  //ceased calendar
  const ceasedCalendar = () => {
    if (ceaseCalendar === true) {
      setCeasecalendar(false);
    }
    else {
      setCeasecalendar(true);
    }

  };
  //ceased calendar
  const ceasedCalendarHandler = async (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCeasedDate(fullYear)
    setCeasecalendar(false)
    const data = { ceasedDate: fullYear, uid: uid }
    let response = await updateUserProfileCeasedDate(user_profile_id, data);
    if (response.status === 200) {
    }
    else {
    }

  }
  // Timeframe Calendar
  const showTimeframeDateCalender = () => {
    setTimeFrameDate(!timeFrameDate)
  };

  const timeFrameCalendarHandler = async (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD');
    setTimeFrame(fullYear)
    setTimeFrameDate(false)
  }

  const resetValues = () => {
    let dateFormat = moment(currentDate).format("YYYY-MM-DD");
    setCurrentDate(dateFormat);
    setSelectedCategory("");
    setQualityImprovmentPlanStates("");
    setCompletedDate("");
    setFurtherAction("");
    setAdditionalNotes("");
  };
  const editRiskAssessment = (data) => {
    if (data.id !== null && data.id !== undefined) {
      setEditRiskAssessmentId(data.id);
    }
    if (data.category !== null && data.category !== undefined) {
      setSelectedCategory(data.category);
    }
    if (data.further_action !== null && data.further_action !== undefined) {
      setFurtherAction(decode(data.further_action));
    }
    if (data.additional_notes !== null && data.additional_notes !== undefined) {
      setAdditionalNotes(data.additional_notes);
    }
    if (data.date !== null && data.date !== undefined) {
      let currentDateFormat = moment.unix(data.date).format("DD-MM-YYYY");
      setCompletedDate(currentDateFormat);
    }

    setOpenEditModal(true);
  };
  const updateRiskAssessment = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    let validateFieldsRes = validateFields();
    if (validateFieldsRes === true) {
      let object = {
        user_id: [{ value: uid }],
        date: [{ value: completedDate }],
        category: [{ value: selectedCategory }],
        further_action: [{ value: furtherAction }],
        additional_notes: [{ value: additionalNotes }],
      };
      let riskAssessmentResponse = await updateRiskAssessmentLogs(
        editRiskAssessmentId,
        object
      );
      if (riskAssessmentResponse.status === 200) {
        setShowLoader(false);
        toast.success("Risk Assessment Log item updated successfully", {
          position: "top-center",
        });
        setEditRiskAssessmentId("");
        setOpenEditModal(false);
        await getRiskAssessmentLogsData();
      } else {
        setShowLoader(false);
        toast.error(riskAssessmentResponse.message, { position: "top-center" });
      }
    }
  };

  const deleteRiskAssessment = async (id) => {
    setShowLoader(true);
    let dataResponse = await deleteRiskAssessmentLogs(id);
    if (dataResponse.status === 200) {
      setShowLoader(false);
      toast.success("Risk Assessment Log item deleted successfully", {
        position: "top-center",
      });
      await getRiskAssessmentLogsData();
    } else {
      setShowLoader(false);
      toast.error(dataResponse.message, { position: "top-center" });
    }
  };

  function confirmDeleteRisk(id) {

    confirmAlert({
      title: 'Do you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteRiskAssessment(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const showHideDropdown = (value) => {
    if (value === "category") {
      if (showCategoryDropdown === true) {
        setShowCategoryDropdown(false);
      } else {
        setShowCategoryDropdown(true);
      }
    } else if (value === "NqsQualityArea") {
      if (showQualityImprovmentDropdown === true) {
        setShowQualityImprovmentDropdown(false);
      } else {
        setShowQualityImprovmentDropdown(true);
      }
    } else if (value === "pdMethods") {
      let _show = professionalDevelopmentStates.showMethod;
      if (_show === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: true,
        });
      }
    } else {
      if (showTaskStatusDropdown === true) {
        setShowTaskStatusDropdown(false);
      } else {
        setShowTaskStatusDropdown(true);
      }
    }
  };
  const openAddModal = () => {
    resetValues();
    setOpen(true);
  };
  const openPDModal = () => {
    resetValues();
    setIsEdit(false)
    setIsEditPDL(false)
    setProfessionalDevelopmentStates(initialPDStates);
    setOpenProfessionalDevelopmentModal(true);
  };

  const openQualityImprovmentModal = () => {
    setIsEdit(false)
    emptyQualityImprovement()
    setQualityImprovmentPlan(true);
  };

  const setOpenEngagementModal = () => {
    setOpenEngagementLog(true)
  }
  const showHideEngagementCalender = (value) => {
    if (value === "startDate") {
      let _calenderDisplay = addEngagementLogsObject.showStartDateCalender;
      if (_calenderDisplay === true) {
        setAddEngagementLogsObject({
          ...addEngagementLogsObject,
          showStartDateCalender: false,
        });
      } else {
        setAddEngagementLogsObject({
          ...addEngagementLogsObject,
          showStartDateCalender: true,
        });
      }
    } else {
      let _durationCalenderDisplay =
      addEngagementLogsObject.showEndDateCalender;
      if (_durationCalenderDisplay === true) {
        setAddEngagementLogsObject({
          ...addEngagementLogsObject,
          showEndDateCalender: false,
        });
      } else {
        setAddEngagementLogsObject({
          ...addEngagementLogsObject,
          showEndDateCalender: true,
        });
      }
    }
  };

  const showHidePDCalender = (value) => {
    if (value === "date") {
      let _calenderDisplay = professionalDevelopmentStates.showDateCalender;
      if (_calenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: true,
        });
      }
    } else {
      let _durationCalenderDisplay =
        professionalDevelopmentStates.showDurationCalender;
      if (_durationCalenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: true,
        });
      }
    }
  };
  const onChangePDDate = (newDate, field) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1;
    let year = newDate.getFullYear();
    let date = newDate.getDate();
    let fullYear = year + "-" + month + "-" + date;
    if (field === "date") {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: fullYear,
        showDateCalender: false,
      });
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        duration: fullYear,
        showDurationCalender: false,
      });
    }
  };

  const onChangeEngagementRecordDate = (newDate, field) => {
    // let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1;
    // let year = newDate.getFullYear();
    // let date = newDate.getDate();
    // let fullYear = year + "-" + month + "-" + date;
    let fullYear = moment(newDate).format('YYYY-MM-DD')

    if (field === "startDate") {
      setAddEngagementLogsObject({
        ...addEngagementLogsObject,
        startDate: fullYear,
        showStartDateCalender: false,
      });
    } else {
      setAddEngagementLogsObject({
        ...addEngagementLogsObject,
        endDate: fullYear,
        showEndDateCalender: false,
      });
    }
  };

  const uploadImages = (event) => {
    setProfessionalDevelopmentStates({ ...professionalDevelopmentStates, fid: '' })
    if (event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5) {
      if (event.target.files[0].type !== "image/jpg" && event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" && event.target.files[0].type !== "application/pdf") {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = function (events) {
              imageObject["path"] = events.target.result;
            };
            imagesArray.push(imageObject);
            setUploadCertificate([imagesArray[0]])
            setProfessionalDevelopmentStates({
              ...professionalDevelopmentStates,
              certificate: [...imagesArray],
            });
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };
  const removeSpecificImage = (index) => {
    let imagesArray = professionalDevelopmentStates.certificate;
    imagesArray.splice(index, 1);
    setProfessionalDevelopmentStates({
      ...professionalDevelopmentStates,
      certificate: [...imagesArray],
    });
  };

  const validateFieldsPd = () => {
    let formIsValid = true;
    if (professionalDevelopmentStates.method === "") {
      setError((error) => ({
        ...error,
        methodError: "Please enter your Method",
      }));
      formIsValid = false;
    }
    return formIsValid;
  };

  //Sort data by Risk Type
  const SortRiskByType = async (sort) => {
    setSortedValue(sort);
    await getRiskAssessmentLogsData(sort);
  }

  // Save Profile  Calls Here
  async function saveProfile(key) {
    setShowLoader(true)
    let user_id = uid
    let data = []

    // if (dob !== '' && dob !== undefined) {
      // data.push({ dob: dob })
    // } if (commencementDate !== '' && commencementDate !== undefined) {
    //   data.push({ commencementDate: commencementDate })
    // } if (ceasedDate !== '' && ceasedDate !== undefined) {
    //   data.push({ ceasedDate: ceasedDate })
    // } if (insuranceExpiryDate !== '' && insuranceExpiryDate !== undefined) {
    //   data.push({ insuranceExpiryDate: insuranceExpiryDate })
    // } 
    if (registrationExpiryDate !== '' && registrationExpiryDate !== undefined) {
      data.push({ registrationExpiryDate: registrationExpiryDate })
    } if (vehicleAuditDate !== '' && vehicleAuditDate !== undefined) {
      data.push({ vehicleAuditDate: vehicleAuditDate })
    } if (vehicleMaintenanceDate !== '' && vehicleMaintenanceDate !== undefined) {
      data.push({ vehicleMaintenanceDate: vehicleMaintenanceDate })
    } if (licenceExpiryDate !== '' && licenceExpiryDate !== undefined) {
      data.push({ licenceExpiryDate: licenceExpiryDate })
    // } if (firstAidCompletionDate !== '' && firstAidCompletionDate !== undefined) {
    //   data.push({ firstAidCourseCompletionDate: firstAidCompletionDate })
    // } if (cprCourseCompletionDate !== '' && cprCourseCompletionDate !== undefined) {
    //   data.push({ cprCourseCompletionDate: cprCourseCompletionDate })
    // } if (asthCourseCompletionDate !== '' && asthCourseCompletionDate !== undefined) {
    //   data.push({ asthmaCourseCompletionDate: asthCourseCompletionDate })
      // } if (anapCourseCompletionDate !== '' && anapCourseCompletionDate !== undefined) {
      //   data.push({ anapCourseCompletionDate: anapCourseCompletionDate })
    // } if (insuranceExpiryDate !== '' && insuranceExpiryDate !== undefined) {
    //   data.push({ insuranceExpiryDate: insuranceExpiryDate })
    } if (qualificationIssueDate !== '' && qualificationIssueDate !== undefined) {
      data.push({ qualificationIssueDate: qualificationIssueDate })
    // } if (annualAssessmentDate !== '' && annualAssessmentDate !== undefined) {
      // data.push({ annualAssessmentDate: annualAssessmentDate })
    }
    if (restraintDate !== '' && restraintDate !== undefined) {
      data.push({ restraintDate: restraintDate })
    } if (maintenanceDate !== '' && maintenanceDate !== undefined) {
      data.push({ maintenanceDate: maintenanceDate })
    }
    // if (firstAidExpiryDate !== '' && firstAidExpiryDate !== undefined) {
    //   data.push({ firstAidExpiryDate: firstAidExpiryDate })
    // }
    if (asthmaExpiryDate !== '' && asthmaExpiryDate !== undefined) {
      data.push({ asthmaExpiryDate: asthmaExpiryDate })
    }
    // if (cprExpiryDate !== '' && cprExpiryDate !== undefined) {
    //   data.push({ cprExpiryDate: cprExpiryDate })
    // }
    if (anaphylaxisExpiryDate !== '' && anaphylaxisExpiryDate !== undefined) {
      data.push({ anaphylaxisExpiryDate: anaphylaxisExpiryDate })
    }
    // if (annualAssessmentExpiryDate !== '' && annualAssessmentExpiryDate !== undefined) {
    //   data.push({ annualAssessmentExpiryDate: annualAssessmentExpiryDate })
    // }
    if (key != 'completionDate' && key != 'allPoolData'){
      if (poolCourseCompletionDate !== '' && poolCourseCompletionDate !== undefined) {
        data.push({ poolCourseCompletionDate: poolCourseCompletionDate })
      }
    }
    if(key != 'expiry' && key != 'allPoolData'){
      if (poolExpiryDate !== '' && poolExpiryDate !== undefined) {
        data.push({ poolExpiryDate: poolExpiryDate })
      }
    }

    let textData = {
      registration: registerNumber,
      uid: user_id,
      insuranceProvider: insuranceProvider,
      policyNumber: policyNumber,
      first_name: firstName,
      last_name: lastName,
      licenceNumber: licenceNumber,
      vehicleMake: vehicleMake,
      vehicleModel: vehicleModel,
      vehicleYear: vehicleYear,
      vehicleRegisteration: vehicleRegisteration,
      // asthmaCourse: asthmaCourse,
      firstAidCourse: firstAidCourse,
      cprCourse: cprCourse,
      // anapCourse: anaphylaxisCourse,
      firstAidCourseReminder: firstAidCourseReminder,
      cprCourseReminder: crpCourseReminder,
      // asthamCourseReminder: asthmaCourseReminder,
      // anapCourseReminder: anaphylaxisCourseReminder,
      suburbName: suburbName,
      street: street,
      postal_code: postalCode,
      mobile_number: homeNumber,
      annualAssessmentReminder: annualAssessmentReminder,
      publicLiabilityInsuranceRemainder: publicLiabilityInsuranceRemainder,
      drivingLiscenceReminder: drivingLiscenceReminder,
      auditDateReminder: auditDateReminder,
      maintenanceDateRemainder: maintenanceDateRemainder,
      vehicleRegReminder: vehicleRegReminder,
      vehicleRegisterationReminder: vehicleRegisterationReminder,
      field_cpr_certificate: cprCertificate[0].id ? cprCertificate[0].id : null,
      // field_anaphylaxis_certificate: anaphylaxisCertificate[0].id ? anaphylaxisCertificate[0].id : null,
      // field_asthma_course_certificate: asthmaCertificate[0].id ? asthmaCertificate[0].id : null,
      field_public_ins_certificate: publicInsuranceCertificate[0].id ? publicInsuranceCertificate[0].id : null,
      field_certificate_document: firstAidCertificate[0].id ? firstAidCertificate[0].id : null,
      basicResidence: basicResidence,
      basicStatus: basicStatus,
      poolSafetyCert: (key != 'poolCertificate' && key != 'allPoolData') ? poolCertificate[0].id ? poolCertificate[0].id : null : null,
      poolSafetyCompletionDate: (key != 'completionDate' && key != 'allPoolData') ? poolCourseCompletionDate : null,
      poolExpiryDate: (key != 'expiry' && key != 'allPoolData') ? poolExpiryDate : null,
      poolSafetyCourseReminder: (key != 'reminder' && key != 'allPoolData') ? poolSafetyCourseReminder : null,
      insuranceExpiryDate:insuranceExpiryDate,
      firstAidCourseCompletionDate: firstAidCompletionDate,
      firstAidExpiryDate:firstAidExpiryDate,
      cprCourseCompletionDate:cprCourseCompletionDate,
      annualAssessmentExpiryDate:annualAssessmentExpiryDate,
      annualAssessmentDate:annualAssessmentDate,
      ceasedDate:ceasedDate,
      commencementDate:commencementDate,
      cprExpiryDate:cprExpiryDate,
      dob:dob,
      emergencyContacts:contacts,
      field_please_provide_details_bel:medicalConditions
    }
    var newObj = Object.assign({}, ...(data.map(item => item)));
    const finalData = Object.assign({}, textData, newObj);

    let response = await updateUserProfile(profileUid, finalData);
    if (response.status === 200) {
      setShowLoader(false);
    } else {
      setShowLoader(false)
      toast.error(response.message, {
        position: 'top-center'
      });
    }

    let responseUser = await updateUserData(uid, textData);
    if (responseUser.status === 200) {
    setShowLoader(false)
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
    } else {
      setShowLoader(false)
      toast.error(responseUser.message, {
        position: 'top-center'
      });
    }
  }

  /// Course Calender
  const coursesCalendar = (name) => {
    // firstAidExpiryDate
    if (name === 'firstAidExpiryDate') {
      if (firstAidExpiry === true) {
        setFirstAidExpiry(false);
      } else {
        setFirstAidExpiry(true);
      }
      setSelectedField('firstAidExpiryDate')
    }
    // CPR Expiry
    if (name === 'CPRExpiry') {
      if (cprExpiry === true) {
        setCprExpiry(false);
      } else {
        setCprExpiry(true);
      }
      setSelectedField('CPRExpiry')
    }
    // Asthma Expiry
    if (name === 'AsthmaExpiry') {
      if (asthmaExpiry === true) {
        setAsthmaExpiry(false);
      } else {
        setAsthmaExpiry(true);
      }
      setSelectedField('AsthmaExpiry')
    }
    // Set Anaphylaxis Expiry
    if (name === 'AnaphylaxisExpiry') {
      if (anaphylaxixExpiry === true) {
        setAnaphlaxixExpiry(false);
      } else {
        setAnaphlaxixExpiry(true);
      }
      setSelectedField('AnaphylaxisExpiry')
    }
    if (name === 'firstaidcompletiondate') {
      if (firstAidCal === true) {
        setfirstAidCal(false);
      } else {
        setfirstAidCal(true);
      }
      setSelectedField('firstaidcompletiondate')
    }

    if (name === 'cprcoursecompletiondate') {
      if (cprCourseCal === true) {
        setcprCourseCal(false);
      }
      else {
        setcprCourseCal(true);
      }
      setSelectedField('cprcoursecompletiondate')
    }

    if (name === 'asthcoursecompletiondate') {
      if (asthCourseCal === true) {
        setasthCourseCal(false);
      }
      else {
        setasthCourseCal(true);
      }
      setSelectedField('asthcoursecompletiondate')
    }

    if (name === 'poolCourseCompletionDate') {
      if (poolCourseCal === true) {
        setPoolCourseCal(false);
      }
      else {
        setPoolCourseCal(true);
      }
      setSelectedField('poolCourseCompletionDate')
    }
    if (name === 'poolExpiryDate') {
      if (poolExpiryCourseCal === true) {
        setPoolExpiryCourseCal(false);
      }
      else {
        setPoolExpiryCourseCal(true);
      }
      setSelectedField('poolExpiryDate')
    }

  }


  const coursesCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    if (selectedField === 'firstaidcompletiondate') {
      setFirstAidCompletionDate(fullYear)
      setfirstAidCal(false)
    }
    if (selectedField === 'AnaphylaxisExpiry') {
      setAnaphylaxisExpiryDate(fullYear)
      setAnaphlaxixExpiry(false)
    }
    if (selectedField === 'CPRExpiry') {
      setCprExpiryDate(fullYear)
      setCprExpiry(false)
    }
    if (selectedField === 'AsthmaExpiry') {
      setAsthmaExpiryDate(fullYear)
      setAsthmaExpiry(false)
    }

    if (selectedField === 'firstAidExpiryDate') {
      setFirstAidExpiryDate(fullYear)
      setFirstAidExpiry(false)
    }

    if (selectedField === 'cprcoursecompletiondate') {
      setCprCourseCompletionDate(fullYear)
      setcprCourseCal(false)
    }

    if (selectedField === 'asthcoursecompletiondate') {
      setAsthCourseCompletionDate(fullYear)
      setasthCourseCal(false)
    }
    if (selectedField === 'poolExpiryDate') {
      setPoolExpiryDate(fullYear)
      setPoolExpiryCourseCal(false)
    }
    if (selectedField === 'poolCourseCompletionDate') {
      setPoolCourseCompletionDate(fullYear)
      setPoolCourseCal(false)
    }
  }


  // Remainder 
  const selectReminder = (field, selectedValue) => {

    if (field === "educatorReminder") {
      setEducatorReminder(selectedValue);
    }
    if (field === "firstAid") {
      setFirstAidCourseReminder(selectedValue)
    }
    if (field === "crp") {
      setCrpCourseReminder(selectedValue)
    }
    if (field === 'annualAssessment') {
      setAnnualAssessmentReminder(selectedValue)
    }
    if (field === 'publicLiability') {
      setPublicLiabilityInsuranceRemainder(selectedValue)
    }
    if (field === 'drivingLiscenceReminder') {
      setDrivingLiscenceReminder(selectedValue)
    }
    if (field === 'auditDateReminder') {
      setAuditDateRemainder(selectedValue)
    }
    if (field === 'maintenanceDateRemainder') {
      setMaintenanceDateremainder(selectedValue)
    }
    if (field === 'vehicleRegReminder') {
      setVehicleRegReminder(selectedValue)
    }
    if (field === 'vehicleRegistration') {
      setVehicleRegisterationReminder(selectedValue)
    }
    if (field === "educatorTeacherReminder") {
      setEducatorTeacherReminder(selectedValue);
    }
    if (field === 'pool_safety') {
      setPoolSafetyCourseReminder(selectedValue)
    }
  }

  // Basic Information 
  const dobCalendar = (index) => {
    if (dateofbirthCalendar === true) {
      setDateofbirthCalendar(false)
    }
    else {
      setDateofbirthCalendar(true)
    }
  }

  //Residence Value
  const handleStatusChange = (event) => {
    setBasicStatus(event.target.value);
  };
  const handleResidenceChange = (event) => {
    setBasicResidence(event.target.value);
  };

  // DOB Calendar Handler
  const dobCalendarHandler = (newDate) => {
    let requiredDate = moment(newDate).format('YYYY-MM-DD')
    setDob(requiredDate)
    setDateofbirthCalendar(false)
  }

  const setAccordionOn = (id) => {
    setToggleOn(!toggleOn);
    if (consistId.includes(id)) {
      setConsisitId([]);
    } else {
      setConsisitId(id);
    }
  }

  const openDocument = (data) => {
    if (!(data === 'Not Available')) {
      window.open(`${baseUrl}${data}`, "_blank")
    }
  }

  const addNewFields = () => {
    setChildDataOccupant([...childDataOccupant, {
      field_occupant_first_name: [{ "value": '' }],
      field_occupant_last_name: [{ "value": '' }],
      field_occupant_date_of_birth: [{ "value": '' }],
      show_calendar: false
    }])
  }

  const removeLastChild = () => {
    childDataOccupant.pop();
    setChildDataOccupant([...childDataOccupant])
  }

  const removeChild = async (index, target_id) => {
    let response = await deleteProfessioanlDevelopLogs(target_id);
    if (response.status === 204) {
      toast.success("Child Occupant Deleted Successfully", {
        position: "top-center",
      });
      let newData = []
      if (childDataOccupant && childDataOccupant.length) {
        childDataOccupant.map((val) => {
          if (val.field_all_occupants && val.field_all_occupants.length) {
            val.field_all_occupants.map((item => {
              if (item.target_id !== target_id) {
                let tagretId = item.target_id;
                let revisionId = item.target_revision_id;
                return newData.push({ "target_id": tagretId, "target_revision_id": revisionId })
              }
              return null
            }))
          }
          return null
        })
      }
      let profileNewData = {
        "type": [{ "target_id": "educator", "target_type": "profile_type" }],
        "field_all_occupants": newData
      }
      await upDateOccupants(profileUid, profileNewData)
      onPageInit()
      setChildDataOccupant([...childDataOccupant])
    }
  }

  //Remove Adult 
  const removeAdult = async (index, target_id) => {
    let response = await deleteProfessioanlDevelopLogs(target_id);
    if (response.status === 204) {
      toast.success("Adult Occupant Deleted Successfully", {
        position: "top-center",
      });

      let newDataAdult = []
      if (adultDataOccupant && adultDataOccupant.length) {

        adultDataOccupant.map((val) => {
          if (val.field_all_occupants && val.field_all_occupants.length) {
            val.field_all_occupants.map((item => {
              if (item.target_id !== target_id) {
                let tagretId = item.target_id;
                let revisionId = item.target_revision_id;
                return newDataAdult.push({ "target_id": tagretId, "target_revision_id": revisionId })
              }
              return null
            }))
          }
          return null
        })
      }
      let profileNewData = {
        "type": [{ "target_id": "educator", "target_type": "profile_type" }],
        "field_adult_occupants": newDataAdult
      }
      const responseProfileUpdate = await upDateOccupants(profileUid, profileNewData)
      if (responseProfileUpdate.status.status === 200) {
      }
      onPageInit()
      setAdultDataOccupant([...adultDataOccupant])
    }
  }

  const onChangeText = (data, index, event) => {
    let { name, value } = event.target;
    let markers = [...childDataOccupant];
    markers[index] = { ...markers[index], [name]: [{ value: value }] };
    setChildDataOccupant([...markers])
  }

  // Child DOB Set
  const childOccupantsDOB = (index, value) => {
    if (childDataOccupant[index].show_calendar === true) {
      let markers = [...childDataOccupant];
      markers[index] = { ...markers[index], show_calendar: false };
      setChildDataOccupant([...markers])
    }
    else {
      let markers = [...childDataOccupant];
      markers[index] = { ...markers[index], show_calendar: true };
      setChildDataOccupant([...markers])
    }
  }

  const onChangeDate = (data, index, event, requiredField) => {
    let markers = [...childDataOccupant];
    markers[index] = { ...markers[index], show_calendar: false };
    markers[index] = { ...markers[index], [requiredField]: [{ value: moment(event).format('YYYY-MM-DD') }] };
    setChildDataOccupant([...markers])
  }

  const updateTheOccupants = async () => {
    setShowLoader(true)
    let idsArray = [];

    let authToken = await window.localStorage.getItem('auth_token')
    childDataOccupant.forEach(async function (res, index) {
      if (res.field_all_occupants) {

        if (res.field_occupant_date_of_birth[0].value || res.field_occupant_first_name[0].value || res.field_occupant_last_name[0].value) {
          let data = {
            "type": [{ "target_id": "all_occupants", "target_type": "paragraphs_type" }],
            field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value || null}],
          }

          if (res.field_occupant_first_name[0].value) {
            data = {
              ...data,
              field_occupant_first_name: [{ "value": res.field_occupant_first_name[0].value }],
            }
          }

          if (res.field_occupant_last_name[0].value) {
            data = {
              ...data,
              field_occupant_last_name: [{ "value": res.field_occupant_last_name[0].value }],
            }
          }

          // if (res.field_occupant_date_of_birth[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value }],
          //   }
          // }
          const pathedData = await UpdatetheOccupants(res.field_all_occupants[0].target_id, data);
          let tagretId = pathedData.data.data.id[0].value;
          let revisionId = pathedData.data.data.revision_id[0].value;
          idsArray.push({ "target_id": tagretId, "target_revision_id": revisionId })
        }

      } else {
        if (res.field_occupant_date_of_birth[0].value || res.field_occupant_first_name[0].value || res.field_occupant_last_name[0].value) {
          let data = {
            "type": [{ "target_id": "all_occupants", "target_type": "paragraphs_type" }],
            field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value || null}],
          }

          if (res.field_occupant_first_name[0].value) {
            data = {
              ...data,
              field_occupant_first_name: [{ "value": res.field_occupant_first_name[0].value }],
            }
          }

          if (res.field_occupant_last_name[0].value) {
            data = {
              ...data,
              field_occupant_last_name: [{ "value": res.field_occupant_last_name[0].value }],
            }
          }

          // if (res.field_occupant_date_of_birth[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value }],
          //   }
          // }
          const responseChildOccupant = await addOccupants(data, authToken);
          if (responseChildOccupant.status === 201) {
            let tagretId = responseChildOccupant.data.data.id[0].value;
            let revisionId = responseChildOccupant.data.data.revision_id[0].value;
            idsArray.push({ "target_id": tagretId, "target_revision_id": revisionId })
          }
        }
      }

      if (idsArray.length === childDataOccupant.length) {
        const profileUpdateData = {
          "type": [{ "target_id": "educator", "target_type": "profile_type" }],
          "uid": [{ "target_id": uid }],
          "field_all_occupants": idsArray
        }
        const responseProfileUpdate = await upDateOccupants(profileUid, profileUpdateData);
        if (responseProfileUpdate.status.status === 200) {
          setShowLoader(false);
          toast.success('Profile Updated Successfully!!', {
            position: 'top-center'
          })
        } else {
          setShowLoader(false);
        }
      }
    })
  }

  // Adult Occupant 
  const addNewFieldsAdultOccupant = () => {
    setAdultDataOccupant([...adultDataOccupant, {
      field_occupant_first_name: [{ "value": '' }],
      field_occupant_last_name: [{ "value": '' }],
      field_occupant_date_of_birth: [{ "value": '' }],
      show_calendar: false,
      show_calendar_blue_card: false,
      show_calendar_sighted_date: false,
      field_occupant_blue_card: [{ "value": '' }],
      field_occupant_card_expiry_date: [{ "value": '' }],
      field_occupant_date_signed: [{ "value": '' }],
      field_reminder_blue_card: [{ "value": '' }],
      field_teacher_registration: [{ "value": '' }],
      field_teacher_expiry_date: [{ "value": '' }],
      field_teacher_adult_reminder: [{ "value": '' }],
      dropDown: false,
      dropDown1: false
    }])
  }

  const removeLastChildAdult = () => {
    adultDataOccupant.pop();
    setAdultDataOccupant([...adultDataOccupant])
  }

  // Add Date Dynamically in the Field Of Adult Occupants
  const onChangeDateAdultOccupant = (data, index, event, requiredField) => {
    let markers = [...adultDataOccupant];
    markers[index] = { ...markers[index], show_calendar: false };
    markers[index] = { ...markers[index], show_calendar_blue_card: false };
    markers[index] = { ...markers[index], show_calendar_sighted_date: false };
    markers[index] = { ...markers[index], show_calendar_teacher_date: false };
    markers[index] = { ...markers[index], [requiredField]: [{ value: moment(event).format('YYYY-MM-DD') }] };
    setAdultDataOccupant([...markers])
  }

  // Adult Occupant  DOB Set
  const adultOccupantDOBSet = (index, value) => {
    let markers = [...adultDataOccupant];
    markers[index] = { ...markers[index], [value]: true };
    setAdultDataOccupant([...markers])
  }
  // change Text in Adult Occupant
  const onChangeTextAdultOccupant = (data, index, event) => {
    let { name, value } = event.target;
    let markers = [...adultDataOccupant];
    markers[index] = { ...markers[index], [name]: [{ value: value }] };
    setAdultDataOccupant([...markers])
  }

  // change DropDown Value in Adult Occupant
  const onChangeListAdultOccupant = (index, event) => {
    let markers = [...adultDataOccupant];
    markers[index] = { ...markers[index], field_reminder_blue_card: [{ value: event }] }
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['dropDown']: false }
    setAdultDataOccupant([...markers])
  }

  const onChangeListTeacherOccupant = (index, event) => {
    let markers = [...adultDataOccupant];
    markers[index] = { ...markers[index], field_teacher_adult_reminder: [{ value: event }] }
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['dropDown1']: false }
    setAdultDataOccupant([...markers])
  }


  const initDropDown = (data, index) => {
    let markers = [...adultDataOccupant];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['dropDown']: true }
    setAdultDataOccupant([...markers])
  }
  const initDrop = (data, index) => {
    let markers = [...adultDataOccupant];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['dropDown1']: true }
    setAdultDataOccupant([...markers])
  }

  // Off Model with Data :
  const offModelWithData = () => {
    setAreaForImprovment('')
    setQualityImprovmentPlan(false)
  }

  // Update Adult Occupants :
  const updateAdultOccupantsData = async () => {
    setShowLoader(true)
    let idsArray = [];
    let authToken = window.localStorage.getItem('auth_token')
    adultDataOccupant.forEach(async function (res, index) {
      if (res.field_all_occupants) {

        if (res.field_occupant_date_of_birth[0].value || res.field_occupant_first_name[0].value || res.field_occupant_last_name[0].value
          || res.field_occupant_blue_card[0].value || res.field_occupant_card_expiry_date[0].value || res.field_occupant_date_signed[0].value
          || res.field_reminder_blue_card[0].value || res.field_teacher_registration[0].value || res.field_teacher_expiry_date[0].value
          || res.field_teacher_adult_reminder[0].value
        ) {
          let data = {
            "type": [{ "target_id": "adult_occupants", "target_type": "paragraphs_type" }],
            field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value || null }],
            field_occupant_card_expiry_date: [{ "value": res.field_occupant_card_expiry_date[0].value || null}],
            field_occupant_date_signed: [{ "value": res.field_occupant_date_signed[0].value || null}],
            field_teacher_expiry_date: [{ "value": res.field_teacher_expiry_date[0].value || null}],
          }

          if (res.field_occupant_first_name[0].value) {
            data = {
              ...data,
              field_occupant_first_name: [{ "value": res.field_occupant_first_name[0].value }],
            }
          }

          if (res.field_occupant_last_name[0].value) {
            data = {
              ...data,
              field_occupant_last_name: [{ "value": res.field_occupant_last_name[0].value }],
            }
          }

          // if (res.field_occupant_date_of_birth[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value }],
          //   }
          // }
          if (res.field_occupant_blue_card[0].value) {
            data = {
              ...data,
              field_occupant_blue_card: [{ "value": res.field_occupant_blue_card[0].value }],
            }
          }
          // if (res.field_occupant_card_expiry_date[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_card_expiry_date: [{ "value": res.field_occupant_card_expiry_date[0].value }],
          //   }
          // }
          // if (res.field_occupant_date_signed[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_signed: [{ "value": res.field_occupant_date_signed[0].value }],
          //   }
          // }

          if (res.field_reminder_blue_card[0].value) {
            data = {
              ...data,
              field_reminder_blue_card: [{ "value": res.field_reminder_blue_card[0].value }],
            }
          }

          if (res.field_teacher_registration[0].value) {
            data = {
              ...data,
              field_teacher_registration: [{ "value": res.field_teacher_registration[0].value }],
            }
          }

          // if (res.field_teacher_expiry_date[0].value) {
          //   data = {
          //     ...data,
          //     field_teacher_expiry_date: [{ "value": res.field_teacher_expiry_date[0].value }],
          //   }
          // }
          if (res.field_teacher_adult_reminder[0].value) {
            data = {
              ...data,
              field_teacher_adult_reminder: [{ "value": res.field_teacher_adult_reminder[0].value }],
            }
          }
          const pathedData = await UpdatetheOccupants(res.field_all_occupants[0].target_id, data);
          let tagretId = pathedData.data.data.id[0].value;
          let revisionId = pathedData.data.data.revision_id[0].value;
          idsArray.push({ "target_id": tagretId, "target_revision_id": revisionId })
        }

      } else {
        if (res.field_occupant_date_of_birth[0].value || res.field_occupant_first_name[0].value || res.field_occupant_last_name[0].value) {
          let data = {
            "type": [{ "target_id": "adult_occupants", "target_type": "paragraphs_type" }],
            field_occupant_card_expiry_date: [{ "value": res.field_occupant_card_expiry_date[0].value || null }],
            field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value || null}],
            field_occupant_date_signed: [{ "value": res.field_occupant_date_signed[0].value || null}],
            field_teacher_expiry_date: [{ "value": res.field_teacher_expiry_date[0].value || null}],
          }

          if (res.field_occupant_first_name[0].value) {
            data = {
              ...data,
              field_occupant_first_name: [{ "value": res.field_occupant_first_name[0].value }],
            }
          }

          if (res.field_occupant_last_name[0].value) {
            data = {
              ...data,
              field_occupant_last_name: [{ "value": res.field_occupant_last_name[0].value }],
            }
          }

          // if (res.field_occupant_date_of_birth[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_of_birth: [{ "value": res.field_occupant_date_of_birth[0].value }],
          //   }
          // }
          if (res.field_occupant_blue_card[0].value) {
            data = {
              ...data,
              field_occupant_blue_card: [{ "value": res.field_occupant_blue_card[0].value }],
            }
          }
          // if (res.field_occupant_card_expiry_date[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_card_expiry_date: [{ "value": res.field_occupant_card_expiry_date[0].value }],
          //   }
          // }
          // if (res.field_occupant_date_signed[0].value) {
          //   data = {
          //     ...data,
          //     field_occupant_date_signed: [{ "value": res.field_occupant_date_signed[0].value }],
          //   }
          // }

          if (res.field_reminder_blue_card[0].value) {
            data = {
              ...data,
              field_reminder_blue_card: [{ "value": res.field_reminder_blue_card[0].value }],
            }
          }
          if (res.field_teacher_registration[0].value) {
            data = {
              ...data,
              field_teacher_registration: [{ "value": res.field_teacher_registration[0].value }],
            }
          }
          // if (res.field_teacher_expiry_date[0].value) {
          //   data = {
          //     ...data,
          //     field_teacher_expiry_date: [{ "value": res.field_teacher_expiry_date[0].value }],
          //   }
          // }
          if (res.field_teacher_adult_reminder[0].value) {
            data = {
              ...data,
              field_teacher_adult_reminder: [{ "value": res.field_teacher_adult_reminder[0].value }],
            }
          }
          const responseChildOccupant = await addOccupants(data, authToken);
          if (responseChildOccupant.status === 201) {
            let tagretId = responseChildOccupant.data.data.id[0].value;
            let revisionId = responseChildOccupant.data.data.revision_id[0].value;
            idsArray.push({ "target_id": tagretId, "target_revision_id": revisionId })
          }
        }
      }
      if (idsArray.length === adultDataOccupant.length) {
        const profileUpdateData = {
          "type": [{ "target_id": "educator", "target_type": "profile_type" }],
          "uid": [{ "target_id": uid }],
          "field_adult_occupants": idsArray
        }

        const responseProfileUpdate = await upDateOccupants(profileUid, profileUpdateData);
        if (responseProfileUpdate.status.status === 200) {
          setShowLoader(false);
          toast.success('Profile Updated Successfully!!', {
            position: 'top-center'
          })
        } else {
          setShowLoader(false);
        }
      }
    })
  }


  // Upload different Types of Certificate :
  const uploadCertificateForCourses = (event, fieldName) => {
    if (
      event.target.files !== undefined &&
      Object.keys(event.target.files).length > 0 &&
      Object.keys(event.target.files).length <= 5
    ) {
      if (
        event.target.files[0].type !== "image/jpg" &&
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "application/pdf"
      ) {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = async function (events) {
              imageObject["path"] = events.target.result;
              const responseAPI = await uploadCertificatesAPI(imageObject.name, events.target.result, fieldName)
              imagesArray.push(imageObject);
              if (fieldName === 'first_aid') {
                setFirstAidCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'cpr_couse') {
                setCprCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'asthma_course') {
                setAsthmaCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'anaphylaxis_course') {

                setAnaphylaxisCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'pool_certificate') {
                setPoolCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              
              if (fieldName === 'public_insurance') {
                setPublicInsuranceCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
            };
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };

  const updateEducatorSideData = async () => {
    setShowLoader(true);
    let data = {
      "type": "educator",
      "uid": [{ "target_id": uid }],
      field_teacher_expiry_date: [{ "value": teacherExpiryDate || null }],
      field_signed_date: [{ "value": sightedDate || null }],
      field_blue_card_expiry_date: [{ "value": expiryDate || null }],

    }

    if (educatorReminder) {
      data = {
        ...data,
        field_educator_reminder: [{ "value": educatorReminder }],
      }
    }
    if (eduBlueCard) {
      data = {
        ...data,
        field_blue_card_: [{ "value": eduBlueCard }],
      }
    }
    // if (expiryDate) {
    //   data = {
    //     ...data,
    //     field_blue_card_expiry_date: [{ "value": expiryDate }],
    //   }
    // }
    // if (sightedDate) {
    //   data = {
    //     ...data,
    //     field_signed_date: [{ "value": sightedDate || null}],
    //   }
    // }
    if (eduTeacherReg) {
      data = {
        ...data,
        field_teacher_registration: [{ "value": eduTeacherReg }],
      }
    }
    // if (teacherExpiryDate) {
    //   data = {
    //     ...data,
    //     field_teacher_expiry_date: [{ "value": teacherExpiryDate || null }],
    //   }
    // }
    if (educatorTeacherReminder) {
      data = {
        ...data,
        field_teacher_reminder: [{ "value": educatorTeacherReminder }],
      }
    }

    const profileUpdate = await updateEducatorSideDataAPI(profileUid, data);
    if (profileUpdate.status === 200) {
      onPageInit()
      toast.success("Successfully Updated !!");
    } else {
      setShowLoader(false);
    }
  }

  // Qualification Part 
  const addMoreQualification = () => {
    setQualificationDetail([...qualificationDetail,
    {
      qualification_detail: '',
      issue_date: '',
      on_calender: false
    }])
  }

  const onChangeQualification = (event, index) => {
    let { value } = event.target;
    let markers = [...qualificationDetail];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["qualification_detail"]: value };
    setQualificationDetail(markers)
  }
  const qualificationIssueDateSet = (index) => {
    let markers = [...qualificationDetail];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["on_calender"]: true };
    setQualificationDetail([...markers])
  }

  const onChangeIssueDateQualification = (value, index, event) => {
    let markers = [...qualificationDetail];
    markers[index] = { ...markers[index], on_calender: false };
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["issue_date"]: moment(event).format('YYYY-MM-DD') };
    setQualificationDetail([...markers])
  }

  const saveQualificaitonProfile = async () => {
    let qualification = [];
    let issue_date = [];
    setShowLoader(true);
    for (let i = 0; i < qualificationDetail.length; i++) {
      qualification.push({ "value": qualificationDetail[i].qualification_detail });
      issue_date.push({ "value": qualificationDetail[i].issue_date || null });
    }
    const configData = {
      "type": "educator",
      "uid": [{ "target_id": uid }],
      "field_qualification": qualification,
      field_issue_date: issue_date
    }
    const responseData = await upDateOccupants(profileUid, configData);
    if (responseData.status === 200) {
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
      onPageInit()
    } else {
      setShowLoader(false);
    }
  }

  const removeQualification = () => {
    qualificationDetail.pop();
    setQualificationIssueDate([...qualificationDetail])
  }

  const openingTimings = (value, index, items) => {
    setSelectedIdsOfOD([...selectedIdsOfOD, items.id])
    let markers = [...operationalDaysTiming]
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['opening_hours']: value }
    setOperationalDaysTimings([...markers])
  }

  const closingTimings = (value, index, items) => {
    let markers = [...operationalDaysTiming]
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ['closing_hours']: value }
    setOperationalDaysTimings([...markers])

  }


  const saveTheBusinessInformation = async () => {
    setShowLoader(true)
    let idsArray = [];
    let requiredData = [];
    let authToken = window.localStorage.getItem('auth_token')


    for (let i = 0; i < operationalDaysTiming.length; i++) {
      if (operationalDaysTiming[i].opening_hours != null && operationalDaysTiming[i].closing_hours != null) {
        requiredData.push(operationalDaysTiming[i])
      }
    }

    selectedIdsOfOD && requiredData && requiredData.length > 0 && requiredData.map(async function (value, index) {
      let object = {
        "type": [{
          "target_id": "business_days",
          "target_type": "paragraphs_type"
        }],
        "field_operational_days": [{ target_id: value.id }],
        "field_operational_hours": [{ from: moment.duration(`${value.opening_hours}:00`).asSeconds(), to: moment.duration(`${value.closing_hours}:00`).asSeconds() }]
      }
      const resposne = await addBusinessDays(object, authToken)
      let tagretId = resposne.data.data.id[0].value;
      let revisionId = resposne.data.data.revision_id[0].value;
      if (resposne.status === 201) {
        idsArray.push({ "target_id": tagretId, "target_revision_id": revisionId })
        if (idsArray.length === requiredData.length) {
          const profileUpdateData = {
            "type": [{ "target_id": "educator" }],
            "uid": [{ "target_id": uid }],
            "field_business_operational_days": idsArray
          }
          const responseProfileUpdate = await upDateOccupants(profileUid, profileUpdateData);
          if (responseProfileUpdate.status.status === 200) {
            saveProfile()
          }
        }
      }
    })
    setShowLoader(false)
  }


  const OperationalDaysUnselect = async (e, value, index, id) => {
    if (selectedIdsOfOD.includes(id)) {
      const filterdList = selectedIdsOfOD.filter(element => element !== id)
      const filteredData = operationalDaysTiming.filter(item => item.id === id);
      const reaminingData = operationalDaysTiming.filter(item => item.id !== id)
      const data = [...reaminingData, { 'opening_hours': null, 'closing_hours': null, id: filteredData[0].id }]
      const sortedData = await data.sort(function sortByDay(a, b) {
        let day1 = filterDayName(a.id).toLowerCase();
        let day2 = filterDayName(b.id).toLowerCase();
        return sorter[day1] - sorter[day2];
      });
      setOperationalDaysTimings(sortedData)
      setSelectedIdsOfOD(filterdList);
    } else {
      setSelectedIdsOfOD([...selectedIdsOfOD, id])
    }
  }


  useEffect(() => {
  }, [completedDate])


  const filterDayName = (id) => {
    const filterElement = allOperationalDays.filter(item => item.id === id)
    return filterElement[0].val
  }

  const setDropdownValue = (e) => { 
    e.preventDefault()
    if(clearPoolSafetyOption != ''){
      switch (clearPoolSafetyOption) {
        case 'Completion Date':
          setPoolCourseCompletionDate('')
          setClearPoolSafetyOption('')
          saveProfile("completionDate")
          break;
        case 'Reminder':
          setPoolSafetyCourseReminder('')
          setClearPoolSafetyOption('')
          saveProfile("reminder")
          break;
        case 'Expiry Date':
          setPoolExpiryDate('')
          setClearPoolSafetyOption('')
          saveProfile("expiry")
          break;
        case 'Certificate':
          setPoolCertificate([{ value: '', id: '', url: '' }]);
          setClearPoolSafetyOption('')
          saveProfile('poolCertificate')
          break;
        case 'Clear All':
          setPoolCourseCompletionDate('')
          setPoolSafetyCourseReminder('')
          setPoolExpiryDate('')
          setPoolCertificate([{ value: '', id: '', url: '' }]);
          setClearPoolSafetyOption('')
          saveProfile('allPoolData')
          break;
        default:
          break;
      }
    }
  }

  function confirmClearField(e) {
    confirmAlert({
      title: 'Are you sure you want to Clear Field?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => setDropdownValue(e)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  function onClearDate(index, fieldName) {
    let markers = [...adultDataOccupant]; 
    markers[index] = { ...markers[index], [fieldName]: [{ "value": '' }] };
    setAdultDataOccupant(markers);
  }

  function onClearChildDate(index, fieldName) {
    let markers = [...childDataOccupant]; 
    markers[index] = { ...markers[index], [fieldName]: [{ "value": '' }] };
    setChildDataOccupant(markers);
  }

  function onClearQualificationDate(index) {
    let markers = [...qualificationDetail]; 
    markers[index] = { ...markers[index], issue_date:'' };
    setQualificationDetail(markers);
  }

  const handleContactChange = (index, type,event) => {    
    const updatedContacts = [...contacts];
    if(type == 'name'){
      updatedContacts[index].field_title = event.target.value;
    }else{
      updatedContacts[index].field_phone = event.target.value.replace(/\D/g, "");
    }
    setContacts(updatedContacts);
  };

  const handleMedicalChange = (index, event) => {
    const updatedConditions = [...medicalConditions];
    updatedConditions[index] = event.target.value;
    setMedicalConditions(updatedConditions);
  };

  const addMedicalCondition = () => {
    setMedicalConditions([...medicalConditions, ""]);
  };
  const removeUrlField=(index)=>{
      let urls = medicalConditions
      urls.splice(index,1)
      setMedicalConditions([...urls])
    }

    const addEmergencyContactsApi = async () => {
      setShowLoader(true)
      let bodyData = [];
      
      contacts.forEach(data => {
        let bodyItem = {
          title: data.field_title,
          phone: data.field_phone
        };
        if (data.id) {
          bodyItem.target_id = data.id;
        }
        bodyData.push(bodyItem);
      });
    
      try {
        const response = await addEmergencyContacts(uid,profileID, bodyData);
        if (response.status === 200) {
          toast.success('Profile Updated Successfully!!', {
            position: 'top-center'
          });
        } else {
          setShowLoader(false)
          toast.error(response.message, {
            position: 'top-center'
          });
        }
      } catch (error) {
        console.error('Error adding emergency contacts:', error);
      }
    };

  // ADD Record of Engagement Log
  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      {userRole === 1 ? <EducatorsMainHeader /> : <HeadOfficeMainHeader />}
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
             {/* eslint-disable-next-line */}
             <a
                href="#"
                onClick={() => history.goBack()}
                className="dropdown-list"
              >
                <span className="material-icons">arrow_back</span> Go Back
              </a>
            <div className="common-header-row common-header-with-large-text risk--assignment">
              <div className="risk-assi-btns">
              {userRole === 1 ? (
                  <button
                    class="mdc-button mdc-button--raised"
                    onClick={setOpenEngagementModal}
                  >
                    <span class="mdc-button__label">
                      ADD RECORD OF ENGAGEMENT LOG
                    </span>
                  </button>
                ) : null}
                {userRole === 1 ? (
                  <button
                    class="mdc-button mdc-button--raised"
                    onClick={openAddModal}
                  >
                    <span class="mdc-button__label">
                      ADD RISK ASSESSMENT LOG
                    </span>
                  </button>
                ) : null}
                {userRole === 1 ? (
                  <button
                    class="mdc-button mdc-button--raised"
                    onClick={openPDModal}
                  >
                    <span class="mdc-button__label">
                      Add Professional Development Log
                    </span>
                  </button>
                ) : null}
                {userRole === 1 ? (
                  <button
                    class="mdc-button mdc-button--raised"
                    onClick={openQualityImprovmentModal}
                  >
                    <span class="mdc-button__label">
                      Add Quality Improvement Plan
                    </span>
                  </button>
                ) : null}
              </div>
             
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user">
                  <div className="user-profile-block text-center educator-view-profile-page">
                    <div className="media">
                      <img width="100" height="100" src={userPicture ? userPicture : DummyImage} alt="" />
                    </div>
                    {username ? <h1 className="email">{username}</h1> : null}
                  </div>
                </div>
                <div className="user-pages-accordion-wrap view-profile-page">
                  <div>
                    <div id="accordion" className="accordion-container">
                      {/* Baisc Information Start's Here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('0')}>Basic Information {toggleOn && consistId.includes('0') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input readOnly type="email" onChange={(e) => setEmail(e.target.value)} value={email} className={email ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Email*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => dobCalendar()}>
                                <input value={dob ? moment(dob).format('DD-MM-YYYY') : dob} type="text" className={dob ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="dob" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {dob && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setDob('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  dateofbirthCalendar === true ? <Calendar onChange={dobCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="15" value={homeNumber} onChange={(e) => setHomeNumber(e.target.value.replace(/\D/g, ""))} type="text" className={homeNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Mobile Number*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">phone</span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={street} onChange={(e) => { setStreet(e.target.value) }} type="text" className={street ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Street Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={suburbName} onChange={(e) => setSuburbName(e.target.value)} type="text" className={suburbName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Suburb Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>

                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" className={postalCode ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Postal Code*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="select-field">
                              <span className="reminder-field-style">Residence or Venue**</span>
                              <select
                                className="mdc-select custom-select"
                                value={basicResidence}
                                onChange={handleResidenceChange}
                              >
                                <option value="" style={{ visibility: "hidden" }}>Select Residence</option>
                                {residence.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="select-field">
                              <span className="reminder-field-style">Status*</span>
                              <select
                                className="mdc-select custom-select"
                                value={basicStatus}
                                onChange={handleStatusChange}
                              >
                                <option value="" style={{ visibility: "hidden" }}>Select Status</option>
                                {statusBasic.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <button className="mdc-button mdc-button--raised green-btn"
                          type="submit" value='submit' onClick={saveProfile}
                          style={{ marginTop: "12px" }}>
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>


                      <h4 className="accordion-title js-accordion-title" >
                        Qualification {toggleOn && consistId.includes('1') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        {qualificationDetail && qualificationDetail.length > 0 ? qualificationDetail.map((value, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="text" onChange={(e) => onChangeQualification(e, index)} value={value.qualification_detail} className={value.qualification_detail ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Qualification*</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => qualificationIssueDateSet(index)} >
                                    <input value={value.issue_date ? moment(value.issue_date).format('DD-MM-YYYY') : value.issue_date} type="text" className={value.issue_date ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Issue Date*</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {value.issue_date && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    onClearQualificationDate(index);
                                  }}
                                >
                                  clear
                                </span>
                              )}
                                  <div>
                                    {
                                      value.on_calender === true ? <Calendar onChange={(e) => onChangeIssueDateQualification(value, index, e)} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              {index < qualificationCount ? null : <span className="material-icons align-at-end" style={{ cursor: "pointer" }} onClick={removeQualification}>clear</span>}
                            </div>
                          )
                        }) : null}
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveQualificaitonProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                        <button className="mdc-button mdc-button--raised green-btn ml-5" type="submit" value='submit' onClick={addMoreQualification}  >
                          <span className="mdc-button__label">Add Qualification</span>
                        </button>

                      </div>

                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('11')}>
                        Pool Safety Certification {toggleOn && consistId.includes('11') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-lg-12 d-flex justify-content-end align-items-center">
                            <div className="select-field">
                              <select
                                className="mdc-select custom-select-small"
                                value={clearPoolSafetyOption}
                                style={{ width: '120px', height: '45px', fontSize: '12px', padding: '0 0px' }}
                                onChange={(e) => setClearPoolSafetyOption(e.target.value)}
                              >
                                <option value="" style={{ visibility: "hidden" }}>Select Option</option>
                                {clearOptions.map((item, index) => (
                                  <option value={item} key={`clear-option-${index}-key`}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <button
                              className="mdc-button mdc-button--raised"
                              style={{ height: '30px', padding: '0 10px', fontSize: '10px' }}
                              onClick={confirmClearField}>
                              Clear
                            </button>
                          </div>
                          <div className="col-lg-3 col-md-4 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('poolCourseCompletionDate')}>
                                <input value={poolCourseCompletionDate ? moment(poolCourseCompletionDate).format('DD-MM-YYYY') : poolCourseCompletionDate} type="text" className={poolCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="poolCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  poolCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-4">
                            <div className="select-field">
                              <span className="reminder-field-style">Set Reminder*</span>
                              <select
                                className="mdc-select custom-select-small"
                                value={poolSafetyCourseReminder}
                                onChange={(e) => setPoolSafetyCourseReminder(e.target.value)}
                              >
                                <option value="" style={{ visibility: "hidden" }}>Set Reminder</option>
                                {reminder.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('poolExpiryDate')}>
                                <input value={poolExpiryDate ? moment(poolExpiryDate).format('DD-MM-YYYY') : poolExpiryDate} type="text" className={poolExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  // <input value={''} type="text" className={"mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="poolCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  poolExpiryCourseCal === true ? <Calendar minDate={new Date(poolCourseCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'pool_certificate')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{poolCertificate[0].value ? poolCertificate[0].value : "Upload Certificate"}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">image</span>
                            </label>
                            <>
                              {
                                poolCertificate.length > 0 && poolCertificate[0].value !== '' ?
                                  poolCertificate.map((items) => {
                                    let imageUrl1 = items.url
                                    if (!items.url.includes(baseUrl)) {
                                      imageUrl1 = baseUrl + items.url
                                    }
                                    let splittedArray = items.value.split(".")
                                    let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                      splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                        : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                          : imageUrl1
                                    return (
                                      <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                        <figure>
                                          <a href={imageUrl1} target="_blank" rel="noopener noreferrer"><img className="image-setting" style={{ maxHeight: '200px', maxWidth: '250px' }} src={uploadedImageIcon} alt="" /></a>
                                        </figure>
                                      </li>
                                    )
                                  })
                                  : null
                              }
                            </>
                          </div>
                          <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                            <span className="mdc-button__label">Update</span>
                          </button>
                        </div>
                      </div>
                      {/* Busniness Information Starts Here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('2')}>
                        Business Information {toggleOn && consistId.includes('2') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => commencementCalendar()} >
                                <input value={commencementDate ? moment(commencementDate).format('DD-MM-YYYY') : commencementDate} type="text" className={commencementDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="commencement" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Commencement Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {commencementDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setCommencementDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  commenceCalendar === true ? <Calendar onChange={commencementCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => ceasedCalendar()} >
                                <input value={ceasedDate ? moment(ceasedDate).format('DD-MM-YYYY') : ceasedDate} type="text" className={ceasedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="ceasedDate" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Ceased Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {ceasedDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setCeasedDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  ceaseCalendar === true ? <Calendar onChange={ceasedCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Educator Details Ends Here  */}
                        {/* Operational day Start  */}
                        <label>Operational Days*</label>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="operational-days-block">
                              <ul className="operational-days-listing">
                                <div className="row">
                                  {operationalDaysTiming && operationalDaysTiming.length > 0 ? operationalDaysTiming.map((items, index) => {
                                    return (
                                      <div className="col-md-12">
                                        <div className="flex">
                                          <li dayId={items.id} className={selectedIdsOfOD.includes(items.id) ? 'active col-md-6' : 'col-md-6'} onClick={(e) => OperationalDaysUnselect(e, items.val, index, items.id)} key={index}>{filterDayName(items.id)}</li>
                                          <div >
                                            <TextField id="time" label="Opening Hours*" type="time" className={classes.textField} value={items && items.opening_hours ? items.opening_hours : ''}
                                              variant="outlined" style={{ marginBottom: 10 }} InputLabelProps={{ shrink: true }} onChange={(e) => openingTimings(e.target.value, index, items)} />
                                          </div>
                                          <div >
                                            <TextField id="time" label="Closing Hours*" type="time" variant="outlined" value={items && items.closing_hours ? items.closing_hours : ''}
                                              InputLabelProps={{ shrink: true }} onChange={(e) => closingTimings(e.target.value, index, items)} style={{ marginTop: 10, marginBottom: 10 }} className={classes.textField} />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                  ) : null
                                  }
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* Operational Days End  */}

                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveTheBusinessInformation} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* Business Information Ends Here  */}
                      {/* Annual Assessment of Residence Starts Here */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('2')}>
                        Annual Assessment of Residence<span className="material-icons align-arrow-end">keyboard_arrow_down</span>
                      </h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => annualAssessmentCalendarType()} >
                                <input value={annualAssessmentDate ? moment(annualAssessmentDate).format('DD-MM-YYYY') : annualAssessmentDate} type="text" className={annualAssessmentDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="commencement" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Completion Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {annualAssessmentDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setAnnualAssessmentDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  annualAssessmentCalendar === true ? <Calendar onChange={annualAssessmentCalendarSet} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => annualAssessmentCalendarExpiry()} >
                                <input value={annualAssessmentExpiryDate ? moment(annualAssessmentExpiryDate).format('DD-MM-YYYY') : annualAssessmentExpiryDate} type="text" className={annualAssessmentExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="commencement" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {annualAssessmentExpiryDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setAnnualAssessmentExpiryDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  annualAssessmentExpiry === true ? <Calendar onChange={annualAssessmentCalendarExpirySet} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder*</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{annualAssessmentReminder ? annualAssessmentReminder : "Set Annual Assessment Reminder "}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("annualAssessment", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* Annual Assessment of Residence Ends Here */}

                      {/* Residentail Occupants start */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('3')}>
                        Residential Occupants & Blue Cards {toggleOn && consistId.includes('3') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}

                      </h4>


                      <div className="accordion-content">
                        <h2 className="title">
                          Educator
                        </h2>
                        <div className="row">

                          <div className="col-md-4">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-4">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Surname*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-md-4">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setEduBlueCard(e.target.value)} value={eduBlueCard} className={eduBlueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Blue Card*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => openCloseCalender('expiryDate')} >
                                <input value={expiryDate ? moment(expiryDate).format('DD-MM-YYYY') : expiryDate} type="text" className={expiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {expiryDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setExpiryDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  expiryDateCalender === true ? <Calendar onChange={expiryDateCalendar} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => openCloseCalender('sightedDate')} >
                                <input value={sightedDate ? moment(sightedDate).format('DD-MM-YYYY') : sightedDate} type="text" className={sightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {sightedDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setSightedDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  sightedDateCalender === true ? <Calendar onChange={sightedDateCalendarSet} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder*</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{educatorReminder ? educatorReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("educatorReminder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="10" value={eduTeacherReg} onChange={(e) => { setEduTeacherReg(e.target.value); e.target.setCustomValidity("") }} type="text" className={eduTeacherReg ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                aria-labelledby="my-label-id" id="educatorRegisteration" required onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Teacher Registration Number</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => openCloseCalender('teacherExpiryDate')} >
                                <input value={teacherExpiryDate ? moment(teacherExpiryDate).format('DD-MM-YYYY') : teacherExpiryDate} type="text" className={teacherExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {teacherExpiryDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setTeacherExpiryDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  teacherDateCalender === true ? <Calendar onChange={teacherDateCalendar} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder*</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{educatorTeacherReminder ? educatorTeacherReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("educatorTeacherReminder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-12 mb-20">
                            <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={updateEducatorSideData}>
                              <span className="mdc-button__label">Update</span>
                            </button>
                          </div>
                        </div>
                        <>
                          <h2 className="title">
                            Adult Occupants
                          </h2>
                          <div>
                            {adultDataOccupant && adultDataOccupant.length > 0 && adultDataOccupant.map((data, index) => {
                              return (
                                <div>
                                  <h3>{`Adult Occupant : ${index + 1}`}</h3>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                        <input maxlength="20" name="field_occupant_first_name" type="text" onChange={(e) => onChangeTextAdultOccupant(data, index, e)} value={data.field_occupant_first_name[0].value} className={data.field_occupant_first_name[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                        <span className="mdc-notched-outline">
                                          <span className="mdc-notched-outline__leading"></span>
                                          <span className="mdc-notched-outline__notch">
                                            <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                          </span>
                                          <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                      </label>
                                    </div>

                                    <div className="col-md-4">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                        <input maxlength="20" type="text" name="field_occupant_last_name" onChange={(e) => onChangeTextAdultOccupant(data, index, e)} value={data.field_occupant_last_name[0].value} className={data.field_occupant_last_name[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                        <span className="mdc-notched-outline">
                                          <span className="mdc-notched-outline__leading"></span>
                                          <span className="mdc-notched-outline__notch">
                                            <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                                          </span>
                                          <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                      <div className="calendar-wrap">
                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => adultOccupantDOBSet(index, "show_calendar")}>
                                          <input
                                            name="field_occupant_date_of_birth"
                                            value={data.field_occupant_date_of_birth[0].value ? moment(data.field_occupant_date_of_birth[0].value).format('DD-MM-YYYY') : data.field_occupant_date_of_birth[0].value}
                                            className={data.field_occupant_date_of_birth[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                            aria-labelledby="my-label-id"
                                            id="dob"
                                            onBlur={(e) => e.target.setCustomValidity('')}
                                            onKeyPress={(e) => e.preventDefault()} // Prevent manual typing
                                            autoComplete="off"
                                            style={{ cursor: 'pointer' }}
                                          />
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                            <span className="material-icons">calendar_today</span>
                                          </span>
                                        </label>
                                        {/* Cross button for clearing date, moved outside the label */}
                                        {data.field_occupant_date_of_birth[0].value && (
                                          <span
                                            className="material-icons align-at-end"
                                            style={{ zIndex:1, position:'absolute', right:50, top:30, cursor: 'pointer'}} // Adjusted margin for better positioning
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevents triggering the calendar open event
                                              onClearDate(index, "field_occupant_date_of_birth");
                                            }}
                                          >
                                            clear
                                          </span>
                                        )}
                                        <div>
                                          {data.show_calendar === true && (
                                            <Calendar onChange={(e) => onChangeDateAdultOccupant(value, index, e, "field_occupant_date_of_birth")} />
                                          )}
                                        </div>
                                      </div>
                                    </div>



                                    <div className="col-md-3">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                        <input maxlength="20" type="text" name="field_occupant_blue_card" onChange={(e) => onChangeTextAdultOccupant(data, index, e)} value={data.field_occupant_blue_card[0].value} className={data.field_occupant_blue_card[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                        <span className="mdc-notched-outline">
                                          <span className="mdc-notched-outline__leading"></span>
                                          <span className="mdc-notched-outline__notch">
                                            <span className="mdc-floating-label" id="my-label-id">Blue Card*</span>
                                          </span>
                                          <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                      <div className="calendar-wrap">
                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => adultOccupantDOBSet(index, "show_calendar_blue_card")}>
                                          <input name="field_occupant_card_expiry_date" value={data.field_occupant_card_expiry_date[0].value ? moment(data.field_occupant_card_expiry_date[0].value).format('DD-MM-YYYY') : data.field_occupant_card_expiry_date[0].value} className={data.field_occupant_card_expiry_date[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                            id="dob" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                            <span className="material-icons">calendar_today</span>
                                          </span>

                                        </label>
                                        {data.field_occupant_card_expiry_date[0].value && (
                                          <span
                                            className="material-icons align-at-end"
                                            style={{ zIndex:1, position:'absolute', right:50, top:30, cursor: 'pointer'}} // Adjusted margin for better positioning
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevents triggering the calendar open event
                                              onClearDate(index, "field_occupant_card_expiry_date");
                                            }}
                                          >
                                            clear
                                          </span>
                                        )}
                                        <div>
                                          {
                                            data.show_calendar_blue_card === true ? <Calendar onChange={(e) => onChangeDateAdultOccupant(value, index, e, "field_occupant_card_expiry_date")} /> : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                      <div className="calendar-wrap">
                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => adultOccupantDOBSet(index, "show_calendar_sighted_date")}>
                                          <input name="field_occupant_date_signed" value={data.field_occupant_date_signed[0].value ? moment(data.field_occupant_date_signed[0].value).format('DD-MM-YYYY') : data.field_occupant_date_signed[0].value} className={data.field_occupant_date_signed[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                            id="dob" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                            <span className="material-icons">calendar_today</span>
                                          </span>

                                        </label>
                                        {data.field_occupant_date_signed[0].value && (
                                          <span
                                            className="material-icons align-at-end"
                                            style={{ zIndex:1, position:'absolute', right:50, top:30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevents triggering the calendar open event
                                              onClearDate(index, "field_occupant_date_signed");
                                            }}
                                          >
                                            clear
                                          </span>
                                        )}
                                        <div>
                                          {
                                            data.show_calendar_sighted_date === true ? <Calendar onChange={(e) => onChangeDateAdultOccupant(value, index, e, "field_occupant_date_signed")} /> : null
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 custom-col">
                                      <div className="select-field"
                                        onMouseUp={() => initDropDown(data, index)}
                                      >
                                        <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                          <label className="reminder-field-style">Set Reminder*</label>
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">{data.field_reminder_blue_card[0].value ? data.field_reminder_blue_card[0].value : "Set Reminder"}</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                          </span>
                                          <span className="material-icons">arrow_drop_down</span>
                                        </label>
                                        {data.dropDown &&
                                          <ul className="dropdown1">
                                            <>
                                              {
                                                reminder.map((item, i) =>
                                                  <li onClick={() => onChangeListAdultOccupant(index, item)} key={i}>{item}</li>
                                                )
                                              }
                                            </>
                                          </ul>
                                        }
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                        <input maxlength="20" type="text" name="field_teacher_registration"
                                          onChange={(e) => onChangeTextAdultOccupant(data, index, e)}
                                          value={data.field_teacher_registration[0].value}
                                          className={data.field_teacher_registration[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                          aria-labelledby="my-label-id" />
                                        <span className="mdc-notched-outline">
                                          <span className="mdc-notched-outline__leading"></span>
                                          <span className="mdc-notched-outline__notch">
                                            <span className="mdc-floating-label" id="my-label-id">Teacher Registration Number*</span>
                                          </span>
                                          <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                      <div className="calendar-wrap">
                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => adultOccupantDOBSet(index, "show_calendar_teacher_date")}>
                                          <input name="field_teacher_expiry_date" value={data.field_teacher_expiry_date[0].value ? moment(data.field_teacher_expiry_date[0].value).format('DD-MM-YYYY') : data.field_teacher_expiry_date[0].value} className={data.field_teacher_expiry_date[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                            id="teacher" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                            <span className="material-icons">calendar_today</span>
                                          </span>

                                        </label>
                                        {data.field_teacher_expiry_date[0].value && (
                                          <span
                                            className="material-icons align-at-end"
                                            style={{ zIndex:1, position:'absolute', right:50, top:30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevents triggering the calendar open event
                                              onClearDate(index, "field_teacher_expiry_date");
                                            }}
                                          >
                                            clear
                                          </span>
                                        )}
                                        <div>
                                          {
                                            data.show_calendar_teacher_date === true ? <Calendar onChange={(e) => onChangeDateAdultOccupant(value, index, e, "field_teacher_expiry_date")} /> : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 custom-col">
                                      <div className="select-field"
                                        onMouseUp={() => initDrop(data, index)}
                                      >
                                        <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                          <label className="reminder-field-style">Set Reminder*</label>
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">{data.field_teacher_adult_reminder[0].value ? data.field_teacher_adult_reminder[0].value : "Set Reminder"}</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                          </span>
                                          <span className="material-icons">arrow_drop_down</span>
                                        </label>
                                        {data.dropDown1 &&
                                          <ul className="dropdown1">
                                            <>
                                              {
                                                reminder.map((item, i) =>
                                                  <li onClick={() => onChangeListTeacherOccupant(index, item)} key={i}>{item}</li>
                                                )
                                              }
                                            </>
                                          </ul>
                                        }
                                      </div>
                                    </div>
                                    {data.field_all_occupants && data.field_all_occupants.length > 0 ? data.field_all_occupants.map((val) => {
                                      return (
                                        <span className="material-icons" style={{ cursor: "pointer" }} onClick={() => { removeAdult(index, val.target_id) }}>clear</span>
                                      )
                                    })
                                      : <span className="material-icons align-at-end" style={{ cursor: "pointer" }} onClick={removeLastChildAdult}>clear</span>}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="custom-dashed-button mark-button-center">
                              <button className="mdc-button mdc-button--outlined btn-large" onClick={addNewFieldsAdultOccupant}>
                                <span className="mdc-button__ripple"></span>
                                <span className="mdc-button__label">Add More Adult  Occupants</span>
                              </button>
                            </div>
                            <div className="mb-20">
                              <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={updateAdultOccupantsData}>
                                <span className="mdc-button__label">Update</span>
                              </button>
                            </div>
                          </div>
                        </>
                        <h2 className="title">
                          Child Occupants
                        </h2>
                        {childDataOccupant && childDataOccupant.length > 0 && childDataOccupant.map((value, index) => {
                          return (
                            <div>
                              <h3>{`Child Occupant : ${index + 1}`}</h3>
                              <div className="row">
                                <div className="col-md-4">
                                  <label className="mdc-text-field mdc-text-field--outlined">
                                    <input maxlength="20" type="text" name="field_occupant_first_name" onChange={(e) => onChangeText(value, index, e)} value={value.field_occupant_first_name[0].value} className={value.field_occupant_first_name[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">First Name</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label className="mdc-text-field mdc-text-field--outlined">
                                    <input maxlength="20" type="text" name="field_occupant_last_name" onChange={(e) => onChangeText(value, index, e)} value={value.field_occupant_last_name[0].value} className={value.field_occupant_last_name[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Last Name</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                  </label>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                  <div className="calendar-wrap" >
                                    <label className="mdc-text-field mdc-text-field--outlined" onClick={() => childOccupantsDOB(index, "show_calendar")}>
                                      <input name="field_occupant_date_of_birth" value={value.field_occupant_date_of_birth[0].value ? moment(value.field_occupant_date_of_birth[0].value).format('DD-MM-YYYY') : value.field_occupant_date_of_birth[0].value} className={value.field_occupant_date_of_birth[0].value ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                        id="dob" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                      <span className="mdc-notched-outline">
                                        <span className="mdc-notched-outline__leading"></span>
                                        <span className="mdc-notched-outline__notch">
                                          <span className="mdc-floating-label" id="my-label-id">D.O.B</span>
                                        </span>
                                        <span className="mdc-notched-outline__trailing"></span>
                                        <span className="material-icons">calendar_today</span>
                                      </span>

                                    </label>
                                    {value.field_occupant_date_of_birth[0].value && (
                                      <span
                                        className="material-icons align-at-end"
                                        style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevents triggering the calendar open event
                                          onClearChildDate(index, "field_occupant_date_of_birth");
                                        }}
                                      >
                                        clear
                                      </span>
                                    )}
                                    <div>
                                      {
                                        value.show_calendar === true ? <Calendar onChange={(e) => onChangeDate(value, index, e, "field_occupant_date_of_birth")} /> : null
                                      }
                                    </div>
                                  </div>
                                </div>
                                {value.field_all_occupants && value.field_all_occupants.length > 0 ? value.field_all_occupants.map((val) => {
                                  return (
                                    <span className="material-icons" style={{ cursor: "pointer" }} onClick={() => { removeChild(index, val.target_id) }}>clear</span>
                                  )
                                })
                                  : <span className="material-icons align-at-end" style={{ cursor: "pointer" }} onClick={removeLastChild}>clear</span>}
                              </div>
                            </div>
                          )
                        })}
                        <div className="custom-dashed-button mark-button-center" onClick={addNewFields}>
                          <button className="mdc-button mdc-button--outlined btn-large">
                            <span className="mdc-button__ripple"></span>
                            <span className="mdc-button__label">Add More Child Occupants</span>
                          </button>
                        </div>

                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={updateTheOccupants}>
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>

                      {/* Residential Occupants Ends  */}
                      {/* Public Liability  Starts here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('4')}>
                        Public Liability Insurance {toggleOn && consistId.includes('4') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" value={insuranceProvider} onChange={(e) => { setInsuranceProvider(e.target.value); e.target.setCustomValidity("") }} type="text" className={insuranceProvider ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="insuranceProvider" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Insurance Provider</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>

                            </label>
                          </div>

                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" value={policyNumber} onChange={(e) => { setPolicyNumber(e.target.value); e.target.setCustomValidity("") }} type="text" className={policyNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="policyNumber" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Policy Number</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => insuranceExpiryCalendar()}>
                                <input value={insuranceExpiryDate ? moment(insuranceExpiryDate).format('DD-MM-YYYY') : insuranceExpiryDate} type="text" className={insuranceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="insuranceExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {insuranceExpiryDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setInsuranceExpiryDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                              <div>
                                {
                                  showInsuranceExpiryCal === true ? <Calendar onChange={insuranceExpiryCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          {/* Remainder Field */}
                          <div className="col-lg-6 col-md-6 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{publicLiabilityInsuranceRemainder ? publicLiabilityInsuranceRemainder : "Set Reminder "}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("publicLiability", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                        <div className="col-lg-6 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'public_insurance')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{publicInsuranceCertificate[0].value ? publicInsuranceCertificate[0].value : "Upload Certificate"}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">image</span>
                            </label>
                            <>
                              {
                                publicInsuranceCertificate.length > 0 && publicInsuranceCertificate[0].value !== '' ?
                                  publicInsuranceCertificate.map((items) => {
                                    let imageUrl1 = items.url
                                    if (!items.url.includes(baseUrl)) {
                                      imageUrl1 = baseUrl + items.url
                                    }
                                    let splittedArray = items.value.split(".")
                                    let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                      splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                        : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                          : imageUrl1
                                    return (
                                      <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                        <figure>
                                          <a href={imageUrl1} target="_blank" rel="noopener noreferrer"><img className="image-setting" style={{ maxHeight: '200px', maxWidth: '250px' }} src={uploadedImageIcon} alt="" /></a>
                                        </figure>
                                      </li>
                                    )
                                  })
                                  : null
                              }
                            </>
                          </div>
                        </div>
                        <div className="mb-20">
                          <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                            <span className="mdc-button__label">Update</span>
                          </button>
                        </div>
                      </div>
                      {/* Public Liability Ends Here  */}
                      {/* First Aid start */}
                      <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('5')}>
                        First Aid Certifications {toggleOn && consistId.includes('5') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-left">First Aid Course</label>
                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={firstAidCourse} onChange={(e) => { setFirstAidCourse(e.target.value); e.target.setCustomValidity(""); }} type="text" className={firstAidCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                    aria-labelledby="my-label-id" maxlength="20" id="firstAidCourse" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('firstaidcompletiondate')}>
                                    <input value={firstAidCompletionDate ? moment(firstAidCompletionDate).format('DD-MM-YYYY') : firstAidCompletionDate} type="text" className={firstAidCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="firstAidCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {firstAidCompletionDate && (
                                <span
                                  className="material-icons align-at-end"
                                  style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the calendar open event
                                    setFirstAidCompletionDate('');
                                  }}
                                >
                                  clear
                                </span>
                              )}
                                  <div>
                                    {
                                      firstAidCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{firstAidCourseReminder ? firstAidCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("firstAid", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>
                              {/* Add Certificate Field  */}
                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('firstAidExpiryDate')}>
                                    <input value={firstAidExpiryDate ? moment(firstAidExpiryDate).format('DD-MM-YYYY') : firstAidExpiryDate} type="text" className={firstAidExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="firstAidCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {firstAidExpiryDate && (
                                    <span
                                      className="material-icons align-at-end"
                                      style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the calendar open event
                                        setFirstAidExpiryDate('');
                                      }}
                                    >
                                      clear
                                    </span>
                                  )}
                                  <div>
                                    {
                                      firstAidExpiry === true ? <Calendar minDate={new Date(firstAidCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'first_aid')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{firstAidCertificate[0].value ? firstAidCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                  {
                                    firstAidCertificate.length > 0 && firstAidCertificate[0].value !== '' ?
                                      firstAidCertificate.map((items) => {
                                        let imageUrl1 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl1 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl1
                                        return (
                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl1} target="_blank" rel="noopener noreferrer"><img className="image-setting" style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="text-left">CPR Course</label>
                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined">
                                    <input value={cprCourse} onChange={(e) => { setCprCourse(e.target.value); e.target.setCustomValidity("") }} type="text" className={cprCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                      aria-labelledby="my-label-id" id="cprCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('cprcoursecompletiondate')} >
                                    <input value={cprCourseCompletionDate ? moment(cprCourseCompletionDate).format('DD-MM-YYYY') : cprCourseCompletionDate} type="text" className={cprCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="cprCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {cprCourseCompletionDate && (
                                    <span
                                      className="material-icons align-at-end"
                                      style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the calendar open event
                                        setCprCourseCompletionDate('');
                                      }}
                                    >
                                      clear
                                    </span>
                                  )}
                                  <div>
                                    {
                                      cprCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{crpCourseReminder ? crpCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("crp", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('CPRExpiry')}>
                                    <input value={cprExpiryDate ? moment(cprExpiryDate).format('DD-MM-YYYY') : cprExpiryDate} type="text" className={cprExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="cprExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {cprExpiryDate && (
                                    <span
                                      className="material-icons align-at-end"
                                      style={{ zIndex: 1, position: 'absolute', right: 50, top: 30, cursor: 'pointer' }} // Adjusted margin for better positioning
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the calendar open event
                                        setCprExpiryDate('');
                                      }}
                                    >
                                      clear
                                    </span>
                                  )}
                                  <div>
                                    {
                                      cprExpiry === true ? <Calendar minDate={new Date(cprCourseCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'cpr_couse')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{cprCertificate[0].value ? cprCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                </>
                                <>
                                  {
                                    cprCertificate.length > 0 && cprCertificate[0].url !== '' ?
                                      cprCertificate.map((items) => {
                                        let imageUrl2 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl2 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl2
                                        return (

                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl2} target="_blank" rel="noopener noreferrer"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div>
                          {/* Hidden because client removed this section basecamp link https://3.basecamp.com/4229098/buckets/21450366/todos/7125391313 */}
                          {/* <div className="col-md-6">
                            <div className="d-flex">
                              <label className="text-left">Asthma Course</label>
                              <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={resetAsthmaCourse}>
                                <span className="mdc-button__label">Reset</span>
                              </button>
                            </div>

                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={asthmaCourse} type="text" onChange={(e) => { setAsthmaCourse(e.target.value); e.target.setCustomValidity("") }} className={asthmaCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                    aria-labelledby="my-label-id" id="asthmaCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('asthcoursecompletiondate')}>
                                    <input value={asthCourseCompletionDate ? moment(asthCourseCompletionDate).format('DD-MM-YYYY') : asthCourseCompletionDate} type="text" className={asthCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="asthCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      asthCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{asthmaCourseReminder ? asthmaCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("asthma", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('AsthmaExpiry')}>
                                    <input value={asthmaExpiryDate ? moment(asthmaExpiryDate).format('DD-MM-YYYY') : asthmaExpiryDate} type="text" className={asthmaExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="asthmaExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      asthmaExpiry === true ? <Calendar minDate={new Date(asthCourseCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'asthma_course')}
                                    accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{asthmaCertificate[0].value ? asthmaCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                </>
                                <>
                                  {
                                    asthmaCertificate.length > 0 && asthmaCertificate[0].url !== '' ?
                                      asthmaCertificate.map((items) => {
                                        let imageUrl3 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl3 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl3
                                        return (
                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl3} target="_blank" rel="noopener noreferrer"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex">
                              <label className="text-left">Anaphylaxis Course</label>
                              <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={resetCourse}>
                                <span className="mdc-button__label">Reset</span>
                              </button>
                            </div>
                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={anaphylaxisCourse} onChange={(e) => { setAnaphylaxisCourse(e.target.value); e.target.setCustomValidity(""); }} type="text" className={anaphylaxisCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                    aria-labelledby="my-label-id" id="anaphylaxisCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('anapcoursecompletiondate')}>
                                    <input value={anapCourseCompletionDate ? moment(anapCourseCompletionDate).format('DD-MM-YYYY') : anapCourseCompletionDate} type="text" className={anapCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="anapCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      anapCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{anaphylaxisCourseReminder ? anaphylaxisCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("anaphylaxis", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('AnaphylaxisExpiry')}>
                                    <input value={anaphylaxisExpiryDate ? moment(anaphylaxisExpiryDate).format('DD-MM-YYYY') : anaphylaxisExpiryDate} type="text" className={anaphylaxisExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="anaphylaxisExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      anaphylaxixExpiry === true ? <Calendar minDate={new Date(anapCourseCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'anaphylaxis_course')}
                                    accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{anaphylaxisCertificate[0].value ? anaphylaxisCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                </>
                                <>
                                  {
                                    anaphylaxisCertificate.length > 0 && anaphylaxisCertificate[0].url !== '' ?
                                      anaphylaxisCertificate.map((items) => {
                                        let imageUrl4 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl4 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl4
                                        return (

                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl4} target="_blank" rel="noopener noreferrer"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div> */}
                          {/* Above code Hidden because client removed this section basecamp link https://3.basecamp.com/4229098/buckets/21450366/todos/7125391313 */}
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* First Aid End  */}
                       {/* Emergency Contact */}
                      <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('emergency')}>
                        Emergency Contacts {toggleOn && consistId.includes('emergency') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div>
                          {contacts.map((contact, index) => (
                            <div className="row" key={index} style={{ marginBottom: "10px" }}>
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" value={contact.field_title} onChange={(e) => handleContactChange(index, "name", e)} type="text" className={contact.field_title ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="field_title" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" value={contact.field_phone} onChange={(e) => handleContactChange(index,"phone", e)} type="text" className={contact.field_phone ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="field_phone" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Phone</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          ))}
                          {/* <button onClick={addContact}>Add More Contacts</button> */}
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={addEmergencyContactsApi} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                       {/* Emergency Contact  */}
                         {/* Medical Considerations start */}
                      <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('medical')}>
                      Medical Conditions and Considerations {toggleOn && consistId.includes('medical') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div>
                          <h3> Please provide details below (e.g. - Condition, Asthma, Allergies, Anaphylaxis, Medications)</h3>
                          {medicalConditions.map((condition, index) => (
                            <div className="row">
                              <div key={`medical-condtion-${index}`} className="col-md-11">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={condition} onChange={(e) => handleMedicalChange(index, e)} type="text" className={condition ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="condition" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Medical Conditions or Considerations</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              {
                                index > 0 ?
                                  <button style={{ width: '30px', height: '30px' }} type="button" className="mt-25" onClick={() => removeUrlField(index)}><span className="material-icons close">close</span></button>
                                  :
                                  null
                              }
                            </div>
                          ))}
                           <div className="custom-dashed-button mark-button-center" style={{ marginTop: "30px" }}>
                              <button className="mdc-button mdc-button--outlined btn-large" onClick={addMedicalCondition}>
                                <span className="mdc-button__ripple"></span>
                                <span className="mdc-button__label">Add More Medical Conditions</span>
                              </button>
                            </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                       {/* Emergency Contact and Medical Considerations ends here */}
                      {/* Vehicle Starts here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('6')}>
                        Vehicle {toggleOn && consistId.includes('6') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div className="row custom-row">
                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="10" value={vehicleMake} onChange={(e) => { setVehicleMake(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleMake ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="vehicleMake" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Vehicle Make</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="10" value={vehicleModel} onChange={(e) => { setVehicleModel(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleModel ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="vehicleModel" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Vehicle Model</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="4" type="text" value={vehicleYear} onChange={(e) => { if (e.target.value.length <= 4) { setVehicleYear(e.target.value.replace(/\D/g, "")) } e.target.setCustomValidity("") }} className={vehicleYear ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="vehicleYear" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Vehicle Year</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="10" value={vehicleRegisteration} onChange={(e) => { setVehicleRegisteration(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleRegisteration ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                aria-labelledby="my-label-id" id="vehicleRegisteration" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Registration</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-6 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => registrationExpiryCalendar()} >
                                <input value={registrationExpiryDate ? moment(registrationExpiryDate).format('DD-MM-YYYY') : registrationExpiryDate} type="text" className={registrationExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" id="registrationExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Registration Expiry Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  registrationExpiryCal === true ? <Calendar onChange={registrationExpiryCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setRegisterationExpiryDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{vehicleRegisterationReminder ? vehicleRegisterationReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("vehicleRegistration", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => vehicleMaintenanceCalendar()}>
                                <input value={vehicleMaintenanceDate ? moment(vehicleMaintenanceDate).format('DD-MM-YYYY') : vehicleMaintenanceDate} type="text" className={vehicleMaintenanceDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" id="vehicleMaintenanceDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Maintenance Completed Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  vehicleMaintenanceCal === true ? <Calendar onChange={vehicleMaintenanceCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setVehicleMaintenanceDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => vehicleMaintenanceExpiryCalendar()}>
                                <input value={maintenanceDate ? moment(maintenanceDate).format('DD-MM-YYYY') : maintenanceDate} type="text" className={maintenanceDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" id="maintenanceDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  maintenanceOpen === true ? <Calendar onChange={vehicleMaintenanceExpiryCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setMaintenanceDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{auditDateReminder ? auditDateReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("auditDateReminder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => vehicleAuditCalendar()}>
                                <input value={vehicleAuditDate ? moment(vehicleAuditDate).format('DD-MM-YYYY') : vehicleAuditDate} type="text" className={vehicleAuditDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="vehicleAuditDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Vehicle / Child Restraint Completed date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  vehicleAuditCal === true ? <Calendar onChange={vehicleAuditCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setVehicleAuditDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => vehicleAuditExpiryCalendar()}>
                                <input value={restraintDate ? moment(restraintDate).format('DD-MM-YYYY') : restraintDate} type="text" className={restraintDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="restraintDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  restraintOpen === true ? <Calendar onChange={vehicleAuditExpiryCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setRestraintDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{maintenanceDateRemainder ? maintenanceDateRemainder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("maintenanceDateRemainder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="18" value={licenceNumber} onChange={(e) => { setLicenceNumber(e.target.value); e.target.setCustomValidity("") }} type="text" className={licenceNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="licenceNumber" onBlur={(e) => e.target.setCustomValidity("")} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Licence number</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => licenceExpiryCalendar()}>
                                <input value={licenceExpiryDate ? moment(licenceExpiryDate).format('DD-MM-YYYY') : licenceExpiryDate} type="text" className={licenceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="licenceExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Licence Expiry Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  licenceExpiryCal === true ? <Calendar onChange={licenceExpiryCalendarHandler} /> : null
                                }
                                <button className="mdc-button mdc-button--raised green-btn" onClick={() => setLicenceExpiryDate('')}>Clear</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder*</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{drivingLiscenceReminder ? drivingLiscenceReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("drivingLiscenceReminder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/*Vehicle Ends Here */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('7')}>
                        Mentoring & Support {toggleOn && consistId.includes('7') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      <div className="accordion-content">
                        <div className="task-listing">
                          <div className="row flex-row flex-nowrap">
                            {userVisits.length > 0
                              ? userVisits.map((visit, index) => (
                                <div key={index} className="mdc-form-field col-md-4">
                                  {visit.field_date ? (
                                    <span className="date-field">
                                      {visit.field_date ? (
                                        <Moment format="DD-MMM-YYYY">
                                          {visit.field_date}
                                        </Moment>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  ) : null}
                                  <span className="highlight-assigned-to"><div dangerouslySetInnerHTML={{ __html: `${visit.field_headoffice === "Anonymous" || visit.field_headoffice === "" ? "Assign Coordinator" : visit.field_headoffice}` }}></div></span>
                                  {visit.nid ? (
                                    <label>
                                      <Link to={`/m&s-detail-page/${visit.nid}`}>
                                        {visit.title}
                                      </Link>
                                    </label>
                                  ) : null}

                                </div>
                              ))
                              : null}
                          </div>
                        </div>
                      </div>
                      {
                        userRole === 2 ? (
                          <div>
                            <h4 className="accordion-title js-accordion-title">
                              Locker
                            </h4>
                            <EducatorFolders show={true} educatorId={uid} />
                          </div>
                        ) :
                          null
                      }
                      <>
                        <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('9')}>
                          Risk Assessment Logs {toggleOn && consistId.includes('9') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                        </h4>
                        <div className="accordion-content">
                          <div className="task-listing">
                            <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={sortedValue}>
                              <MenuItem value="All" onClick={() => { SortRiskByType('All') }}>All</MenuItem>
                              <MenuItem value="Environment" onClick={() => { SortRiskByType('Environment') }}>Environment</MenuItem>
                              <MenuItem value="Emergency Evacuation and Lockdown" onClick={() => { SortRiskByType('Emergency Evacuation and Lockdown') }}>Emergency Evacuation and Lockdown</MenuItem>
                              <MenuItem value="Animals / Pets" onClick={() => { SortRiskByType('Animals / Pets') }}>Animals / Pets</MenuItem>
                              <MenuItem value="Water Safety" onClick={() => { SortRiskByType('Water Safety') }}>Water Safety</MenuItem>
                              <MenuItem value="Excursion" onClick={() => { SortRiskByType('Excursion') }}>Excursion</MenuItem>
                              <MenuItem value="Enrolment Forms" onClick={() => { SortRiskByType('Enrolment Forms') }}>Enrolment Forms</MenuItem>
                              <MenuItem value="Transport" onClick={() => { SortRiskByType('Transport') }}>Transport</MenuItem>
                              <MenuItem value="Medical Management" onClick={() => { SortRiskByType('Medical Management') }}>Medical Management</MenuItem>
                              <MenuItem value="Sleep and Rest" onClick={() => { SortRiskByType('Sleep and Rest') }}>Sleep and Rest</MenuItem>
                              <MenuItem value="Safe Arrival and Delivery of Children" onClick={() => { SortRiskByType('Safe Arrival and Delivery of Children') }}>Safe Arrival and Delivery of Children</MenuItem>
                            </Select>
                            {
                              riskAssessmentArray.length ?
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Notes</TableCell>
                                        {userRole === 1 ? (
                                          <TableCell>Modify</TableCell>
                                        ) : null}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {riskAssessmentArray.map(
                                        (data, index) => (
                                          <TableRow
                                            key={index}
                                            className="col-md-12"
                                          >
                                            <TableCell>
                                              <Moment format="DD-MM-YYYY">
                                                {new Date(parseInt(data.date) * 1000)}
                                              </Moment>
                                            </TableCell>
                                            <TableCell>
                                              {data.category}
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.further_action }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              {data.additional_notes}
                                            </TableCell>
                                            {userRole === 1 ? (
                                              <TableCell>
                                                <button
                                                  className="bg-green action-btn-style"
                                                  onClick={() =>
                                                    editRiskAssessment(data)
                                                  }
                                                >
                                                  Edit
                                                </button>
                                                <button
                                                  className="bg-red action-btn-style del"
                                                  onClick={() =>
                                                    confirmDeleteRisk(
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </button>
                                              </TableCell>
                                            ) : null}
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                : <h3 style={{ textAlign: 'center' }}>No Result</h3>
                            }
                          </div>
                        </div>
                      </>
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('10')}>
                        Professional Development Logs {toggleOn && consistId.includes('10') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      {
                        professionalDevelop.length > 0 && professionalDevelop[0].field_date_completed ? (
                          <>
                            <div className="accordion-content">
                              <div className="task-listing">
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Course Name</TableCell>
                                        <TableCell>organisation</TableCell>
                                        <TableCell>Certificate</TableCell>
                                        {userRole === 1 ? (
                                          <TableCell>Modify</TableCell>
                                        ) : null}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {professionalDevelop.map((data, index) => (
                                        data.field_date_completed ?
                                          <TableRow key={index} className="col-md-12">
                                            <TableCell>
                                              {data.field_date_completed ? (
                                                <Moment format="DD-MM-YYYY">
                                                  {data.field_date_completed}
                                                </Moment>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              {data.field_task_status}
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_course_name }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_organisation }} ></div>
                                            </TableCell>
                                            <TableCell onClick={() => { openDocument(data.field_new_upload_certificate ? (data.field_new_upload_certificate) : 'Not Available') }}>
                                              <figure>
                                                {data.field_new_upload_certificate ? <img style={{ cursor: 'pointer', height: 50, width: 50 }} src={(data.field_new_upload_certificate.split('.')[1]) === "pdf" ? pdfIcons :
                                                  (data.field_new_upload_certificate.split('.')[1]) === "xslx" || (data.field_new_upload_certificate.split('.')[1]) === "xsl" ? excelIcons
                                                    : (data.field_new_upload_certificate.split('.')[1]) === "doc" || (data.field_new_upload_certificate.split('.')[1]) === "docx" ? docIcons
                                                      : ImageIcon} alt="" /> : "Not Available"}
                                              </figure>
                                            </TableCell>
                                            {
                                              userRole === 1 ?
                                                <TableCell>
                                                  <button className="bg-green action-btn-style" onClick={() => editProfessional(data)}>Edit</button>
                                                  <button className="bg-red action-btn-style del" onClick={() => confirmDeleteProfessional(data.id)} >Delete</button> </TableCell>
                                                : null
                                            }
                                          </TableRow>
                                          : null
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                          </>
                        ) : <h3>No Professional Development Logs Available</h3>}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('quality')}>
                        Quality Improvement Plans {toggleOn && consistId.includes('quality') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      {
                        qualityImpromentArray.length > 0 ? (
                          <>
                            <div className="accordion-content">
                              <div className="task-listing">
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>NQS Quality Area</TableCell>
                                        <TableCell>Area for Improvement</TableCell>
                                        <TableCell>NQS Concept/Standard</TableCell>
                                        {/* <TableCell>
                                          What outcome or goal do I seek?
                                        </TableCell> */}
                                        <TableCell>
                                          How will I get this outcome
                                          (steps/training/resources)
                                        </TableCell>
                                        <TableCell>Timeframe</TableCell>
                                        <TableCell>Success Measure</TableCell>
                                        <TableCell>Modify</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {qualityImpromentArray.map((data, index) => (
                                        data.field_area_for_improvement ?
                                          <TableRow key={index} className="col-md-12">
                                            <TableCell>
                                              {
                                                getKeyByValue(data.field_nqs_quality_area)
                                                  ? getKeyByValue(data.field_nqs_quality_area)
                                                  : ""
                                              }
                                              {/* {data.field_nqs_quality_area} */}
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_area_for_improvement }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_nqs_concept_standard }} ></div>
                                            </TableCell>
                                            {/* <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_outcome_or_goal_do_i_seek }} ></div>
                                            </TableCell> */}
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_how_will_i_get_outcome }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              {data.field_updated_timeframe ? (
                                                moment(data.field_updated_timeframe).format('DD-MM-YYYY')
                                              ) : data.field_updated_timeframe}
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_success_measure }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              <button
                                                className="bg-green action-btn-style"
                                                onClick={() =>
                                                  editQualityImprovement(data)
                                                }
                                              >
                                                Edit
                                              </button>{" "}
                                              <button
                                                className="bg-red action-btn-style mt-10"
                                                onClick={() =>
                                                  confirmDeleteQuality(data.id)
                                                }
                                              >
                                                Delete
                                              </button>{" "}
                                            </TableCell>
                                          </TableRow>
                                          : null
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                          </>
                        ) : <h3>No  Quality Improvement Plans Available</h3>}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('12')}>
                        Record of Engagement Logs {toggleOn && consistId.includes('12') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      <div className="accordion-content">
                        {
                          recordEngagementArray.length > 0 ? (
                            <>
                              <div className="task-listing">
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Primary Educator</TableCell>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell>
                                        <TableCell>Modify</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {recordEngagementArray.map((data, index) => (
                                          <TableRow key={index} className="col-md-12">
                                            <TableCell>
                                            {data.primary_educator}
                                            </TableCell>
                                            <TableCell>
                                            {data.start_date}
                                              {/* {data.start_date ? (
                                                moment(data.start_date).format('DD-MM-YYYY')
                                              ) : data.start_date} */}
                                            </TableCell>
                                            <TableCell>
                                            {data.end_date}
                                              {/* {data.end_date ? (
                                                moment(data.end_date).format('DD-MM-YYYY')
                                              ) : data.end_date} */}
                                            </TableCell>
                                            <TableCell>
                                              <button
                                                className="bg-green action-btn-style"
                                                onClick={() =>
                                                  handleOpenRecordEditEngagement(data)
                                                }
                                              >
                                                Edit
                                              </button>
                                              {"   "}
                                              <button
                                                className="bg-red action-btn-style"
                                                onClick={() =>
                                                  confirmDeleteEngagementRecord(data.id)
                                                }
                                              >
                                                Delete
                                              </button>
                                            </TableCell>
                                          </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </>
                          ) : <h3>No Record of Engagement Logs Available</h3>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ADD Professional Development Diaog */}
      <Dialog
        open={openProfessionalDevelopmentModal}
        onClose={() => setOpenProfessionalDevelopmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Professional Development Log
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => professionaSubmit(e, isEditPDL)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHidePDCalender("date")}
                      >
                        <input
                          value={professionalDevelopmentStates.date ? _onDateFormat(professionalDevelopmentStates.date) : professionalDevelopmentStates.date}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            professionalDevelopmentStates.date
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="completedDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Date Completed *
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {professionalDevelopmentStates.showDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangePDDate(date, "date")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("pdMethods")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {professionalDevelopmentStates.method
                                ? professionalDevelopmentStates.method
                                : "Method *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {professionalDevelopmentStates.showMethod === true ? (
                        <ul className="dropdown-2">
                          {professionalDevelopmentMethods.map((data) => (
                            <li
                              onClick={() =>
                                setProfessionalDevelopmentStates({
                                  ...professionalDevelopmentStates,
                                  method: data,
                                  showMethod: false,
                                })
                              }
                            >
                              {data}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.courseName
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="courseName"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.courseName}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            courseName: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Course Name *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.organisation
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="completionTimeframe"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.organisation}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            organisation: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Organisation *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        onChange={(e) => uploadImages(e)}
                        type="file"
                        accept="image/jpg,image/jpeg,image/png,.pdf"
                        id="attach-file"
                        className="mdc-text-field__input"
                        aria-labelledby="my-label-id"
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Upload Certificate
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">image</span>
                    </label>
                    <small className="short-description mt-25">
                      Image can only be of the following types : jpg, jpeg, pdf
                      png with limit upto 5MB
                    </small>
                    <>
                      {professionalDevelopmentStates.certificate.length > 0
                        ? professionalDevelopmentStates.certificate.map(
                          (data, index) => {
                            let splittedArray = data.name.split(".")
                            let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                              splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                  : data.path
                            return (
                              <>
                                <img height="100" width="100" src={uploadedImageIcon} alt="" />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    removeSpecificImage(index);
                                  }}
                                >
                                  <span className="material-icons close">
                                    close
                                  </span>
                                </button>
                              </>
                            )
                          }
                        )
                        : null}
                    </>
                  </div>
                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenProfessionalDevelopmentModal(false);
                      setProfessionalDevelopmentStates(initialPDStates);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ADD Risk Assessment Diaog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ADD RISK ASSESSMENT LOG
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={onSubmit}>
                <div className="row">
                  {<div className="col-lg-6 col-md-6 custom-col">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideCalender("completed")}
                      >
                        <input
                          value={completedDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            completedDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="completedDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Created On*
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {showCompletedDateCalender === true ? (
                          <Calendar onChange={onChangeCompleteDate} />
                        ) : null}
                      </div>
                    </div>
                  </div>}
                  <div className="col-lg-6 col-md-6 custom-col">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("category")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {selectedCategory
                                ? selectedCategory
                                : "Select Category *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {showCategoryDropdown === true ? (
                        <ul className="dropdown-2">
                          <>
                            {categories.map((data, index) => (
                              <li
                                onClick={() => selectCategories(data)}
                                key={index}
                              >
                                {" "}
                                {data}{" "}
                              </li>
                            ))}
                          </>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="outlined-textfield-wrap mt-10">
                      <div className="material-textfield">
                        <textarea
                          onChange={(e) => {
                            setFurtherAction(e.target.value);
                            e.target.setCustomValidity("");
                          }}
                          className={
                            furtherAction ? "form__field active" : "form__field"
                          }
                          placeholder=""
                          rows="6"
                          maxlength="180"
                          id="furtherAction"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")}
                        ></textarea>
                        <label>Action *</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="outlined-textfield-wrap mt-10">
                      <div className="material-textfield">
                        <textarea
                          onChange={(e) => {
                            setAdditionalNotes(e.target.value);
                          }}
                          className={
                            additionalNotes
                              ? "form__field active"
                              : "form__field"
                          }
                          placeholder=""
                          rows="6"
                          maxlength="180"
                          id="additionalNotes"
                        ></textarea>
                        <label>Notes</label>
                      </div>
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Edit Risk Assessment Diaog */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          EDIT RISK ASSESSMENT LOG
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={updateRiskAssessment}>
                <div className="row">

                  {<div className="col-lg-6 col-md-6 custom-col">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideCalender("completed")}
                      >
                        <input
                          value={completedDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            completedDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="currentDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Created On*
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {showCompletedDateCalender === true ? (
                          <Calendar onChange={onChangeCompleteDate} />
                        ) : null}
                      </div>
                    </div>
                  </div>}

                  <div className="col-lg-6 col-md-6 custom-col">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("category")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {selectedCategory
                                ? selectedCategory
                                : "Select Category *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {showCategoryDropdown === true ? (
                        <ul className="dropdown-2">
                          <>
                            {categories.map((data, index) => (
                              <li
                                onClick={() => selectCategories(data)}
                                key={index}
                              >
                                {" "}
                                {data}{" "}
                              </li>
                            ))}
                          </>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="outlined-textfield-wrap mt-10">
                      <div className="material-textfield">
                        <textarea
                          onChange={(e) => {
                            setFurtherAction(e.target.value);
                            e.target.setCustomValidity("");
                          }}
                          className={
                            furtherAction ? "form__field active" : "form__field"
                          }
                          placeholder=""
                          rows="6"
                          maxlength="180"
                          id="furtherAction"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")}
                          value={furtherAction}
                        ></textarea>
                        <label>Action *</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="outlined-textfield-wrap mt-10">
                      <div className="material-textfield">
                        <textarea
                          onChange={(e) => {
                            setAdditionalNotes(e.target.value);
                            e.target.setCustomValidity("");
                          }}
                          className={
                            additionalNotes
                              ? "form__field active"
                              : "form__field"
                          }
                          placeholder=""
                          rows="6"
                          maxlength="180"
                          value={additionalNotes}
                        ></textarea>
                        <label>Notes</label>
                      </div>
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    class="mdc-button mdc-button--raised green-btn"
                    type="button"
                    onClick={() => {
                      setOpenEditModal(false);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Update</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Add Quality Improvment plan Dialog */}
      <Dialog
        open={qualityImprovmentPlan}
        onClose={offModelWithData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Create Quality Improvement Plan
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={createQualityImprovment}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("NqsQualityArea")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {
                                getKeyByValue(qualityImprovmentPlanStates)
                                  ? getKeyByValue(qualityImprovmentPlanStates)
                                  : "NQS Quality Area *"
                              }
                              {/* {qualityImprovmentPlanStates
                                ? qualityImprovmentPlanStates
                                : "NQS Quality Area *"} */}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {showQualityImprovmentDropdown === true ? (
                        <ul className="dropdown-2">
                          <>
                            {QualityImprovmentPlanSelect.map((data, index) => (
                              <li
                                onClick={() => selectQualityImprovment(data.value)}
                                key={index}
                              >
                                {" "}
                                {data.key}{" "}
                              </li>
                            ))}
                          </>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          AreaForImprovment
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="AreaForImprovment"
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        maxLength={400}
                        value={AreaForImprovment}
                        onChange={(e) => setAreaForImprovment(e.target.value)}

                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Area for Improvement
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        maxlength="8"
                        type="text"
                        name="nqsConcept"
                        className={
                          nqsConcept
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        maxLength={400}
                        value={nqsConcept}
                        onChange={(e) => setNqsConcept(e.target.value)}

                        // required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            NQS Concept/Standard
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        type="text"
                        name="outcomeOrGoal"
                        className={
                          outcomeOrGoal
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={outcomeOrGoal}
                        onChange={(e) => setOutcomeOrGoal(e.target.value)}
                        maxLength={400}
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            What outcome or goal do I seek?
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div> */}
                  <div className="col-lg-12 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        type="text"

                        style={{ wordWrap: "break-word" }}
                        name="getOutcome"
                        maxLength={400}
                        className={
                          getOutcome
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={getOutcome}
                        onChange={(e) => setGetOutcome(e.target.value)}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            How will I get this outcome
                            (steps/training/resources)
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 custom-col mt-5">
                    {/* timeFrame Date Start */}
                    <div className="col-lg-6 col-md-6">
                      <div className="calendar-wrap">
                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showTimeframeDateCalender()} >
                          <input
                            value={timeFrame ? _onDateFormat(timeFrame) : timeFrame}
                            type="text"
                            className={timeFrame ? "mdc-text-field__input active" : "mdc-text-field__input"}
                            aria-labelledby="my-label-id"
                            id="timeFrame"
                            required
                            onBlur={(e) => e.target.setCustomValidity("")}
                            onkeypress="return false;"
                            autoComplete="off" style={{ cursor: "pointer" }}
                          />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Time Frame Date**</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                            <span className="material-icons">calendar_today</span>
                          </span>
                        </label>
                        <div>
                          {
                            timeFrameDate === true ? <Calendar onChange={timeFrameCalendarHandler} /> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* TimeFrame Date End  */}
                  </div>
                  <div className="col-lg-12 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined long-text-area-field">
                      <textarea
                        autofocus
                        rows="30"
                        className={
                          sucessMeasure
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="sucessMeasure"
                        aria-labelledby="my-label-id"
                        value={sucessMeasure}
                        onChange={(e) => setSucessMeasure(e.target.value)}
                        id="completionTimeframe"
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Success Measure
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setQualityImprovmentPlan(false);
                    }} >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Edit Quality Improvment plan Dialog */}
      {/* changes by client https://3.basecamp.com/4229098/buckets/21450366/todos/7171513088 */}
      <Dialog
        open={editQualityImprovmentPlanModal}
        onClose={() => setEditQualityImprovmentPlanModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Quality Improvement Plan
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={createQualityImprovment}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("NqsQualityArea")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {
                                getKeyByValue(qualityImprovmentPlanStates)
                                  ? getKeyByValue(qualityImprovmentPlanStates)
                                  : "NQS Quality Area *"
                              }
                              {/* {qualityImprovmentPlanStates
                                ? qualityImprovmentPlanStates
                                : "NQS Quality Area *"} */}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {showQualityImprovmentDropdown === true ? (
                        <ul className="dropdown-2">
                          <>
                            {QualityImprovmentPlanSelect.map((data, index) => (
                              <li
                                onClick={() => selectQualityImprovment(data.value)}
                                key={index}
                              >
                                {" "}
                                {data.key}{" "}
                              </li>
                            ))}
                          </>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          AreaForImprovment
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="AreaForImprovment"
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={AreaForImprovment}
                        onChange={(e) => setAreaForImprovment(e.target.value)}

                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Area for Improvement
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        maxlength="8"
                        type="text"
                        name="nqsConcept"
                        className={
                          nqsConcept
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={nqsConcept}
                        onChange={(e) => setNqsConcept(e.target.value)}

                        // required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            NQS Concept/Standard
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  {/* changes by client https://3.basecamp.com/4229098/buckets/21450366/todos/7171513088 */}
                  {/* <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        type="text"
                        name="outcomeOrGoal"
                        className={
                          outcomeOrGoal
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={outcomeOrGoal}
                        onChange={(e) => setOutcomeOrGoal(e.target.value)}
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            What outcome or goal do I seek?
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div> */}
                  <div className="col-lg-12 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        type="text"
                        name="getOutcome"
                        className={
                          getOutcome
                            ? "mdc-text-field__input active mt-4"
                            : "mdc-text-field__input"
                        }
                        aria-labelledby="my-label-id"
                        id="completionTimeframe"
                        value={getOutcome}
                        onChange={(e) => setGetOutcome(e.target.value)}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            How will I get this outcome
                            (steps/training/resources)
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-6 custom-col  mt-5">
                    {/* timeFrame Date Start */}
                    <div className="col-lg-6 col-md-6">
                      <div className="calendar-wrap">
                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showTimeframeDateCalender()} >
                          <input
                            value={timeFrame ? _onDateFormat(timeFrame) : timeFrame}
                            type="text"
                            className={timeFrame ? "mdc-text-field__input active" : "mdc-text-field__input"}
                            aria-labelledby="my-label-id"
                            id="timeFrame"
                            required
                            onBlur={(e) => e.target.setCustomValidity("")}
                            onkeypress="return false;"
                            autoComplete="off" style={{ cursor: "pointer" }}
                          />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Time Frame Date**</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                            <span className="material-icons">calendar_today</span>
                          </span>
                        </label>
                        <div>
                          {
                            timeFrameDate === true ? <Calendar onChange={timeFrameCalendarHandler} /> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* TimeFrame Date End  */}
                    <div className="col-lg-12 col-md-6 custom-col mt-5">
                      <label className="mdc-text-field mdc-text-field--outlined long-text-area-field">
                        <textarea
                          autofocus
                          rows="30"
                          className={
                            sucessMeasure
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          type="text"
                          name="sucessMeasure"
                          aria-labelledby="my-label-id"
                          value={sucessMeasure}
                          onChange={(e) => setSucessMeasure(e.target.value)}
                          id="completionTimeframe"
                          onBlur={(e) => e.target.setCustomValidity("")}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span className="mdc-floating-label" id="my-label-id">
                              Success Measure
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setEditQualityImprovmentPlanModal(false);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
       {/* ADD RECORD OF ENGAGEMENT LOG */}
       <Dialog
        open={openEngagementLog}
        onClose={() => setOpenEngagementLog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        RECORD OF ENGAGEMENT LOG
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => addEngagementLogsApi(e, false)}>
                <div className="row bottom-margin-box">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideEngagementCalender("startDate")}
                      >
                        <input
                          value={addEngagementLogsObject.startDate ? _onDateFormat(addEngagementLogsObject.startDate) : addEngagementLogsObject.startDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            addEngagementLogsObject.startDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="startDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Start Date
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {addEngagementLogsObject.showStartDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangeEngagementRecordDate(date, "startDate")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideEngagementCalender("endDate")}
                      >
                        <input
                          value={addEngagementLogsObject.endDate ? _onDateFormat(addEngagementLogsObject.endDate) : addEngagementLogsObject.endDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            addEngagementLogsObject.endDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="endDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              End Date
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {addEngagementLogsObject.showEndDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangeEngagementRecordDate(date, "endDate")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          addEngagementLogsObject.primaryEdu
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="primaryEdu"
                        aria-labelledby="my-label-id"
                        value={addEngagementLogsObject.primaryEdu}
                        onChange={(e) => {
                          setAddEngagementLogsObject({
                            ...addEngagementLogsObject,
                            primaryEdu: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                             Primary Educator
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                 
                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenEngagementLog(false)
                      setAddEngagementLogsObject(initialEngagementLogsObject)
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
       {/* Edit RECORD OF ENGAGEMENT LOG */}
       <Dialog
        open={openEditEngagementLog}
        onClose={() => {
          setOpenEditEngagementLog(false)
          setEditEngagementLogId('')
          setAddEngagementLogsObject(initialEngagementLogsObject)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         EDIT RECORD OF ENGAGEMENT LOG
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => addEngagementLogsApi(e, true)}>
                <div className="row bottom-margin-box">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideEngagementCalender("startDate")}
                      >
                        <input
                          value={addEngagementLogsObject.startDate ? _onDateFormat(addEngagementLogsObject.startDate) : addEngagementLogsObject.startDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            addEngagementLogsObject.startDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="startDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Start Date
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {addEngagementLogsObject.showStartDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangeEngagementRecordDate(date, "startDate")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideEngagementCalender("endDate")}
                      >
                        <input
                          value={addEngagementLogsObject.endDate ? _onDateFormat(addEngagementLogsObject.endDate) : addEngagementLogsObject.endDate}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            addEngagementLogsObject.endDate
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="endDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              End Date
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {addEngagementLogsObject.showEndDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangeEngagementRecordDate(date, "endDate")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          addEngagementLogsObject.primaryEdu
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="primaryEdu"
                        aria-labelledby="my-label-id"
                        value={addEngagementLogsObject.primaryEdu}
                        onChange={(e) => {
                          setAddEngagementLogsObject({
                            ...addEngagementLogsObject,
                            primaryEdu: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                             Primary Educator
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                 
                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenEditEngagementLog(false)
                      setEditEngagementLogId('')
                      setAddEngagementLogsObject(initialEngagementLogsObject)
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default EducatorViewProfilePage;