import React,{ useState, useEffect } from 'react';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { $ } from 'react-jquery-plugin';
import './styles.css';
import { useHistory } from "react-router-dom";
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import {updateLinkDetails, convertTimeStampToTimeCustom, getLinksContent, getAllVenue, getSpecificTaxonomy, getSpefificVenue, uploadDocument } from '../../../functions/api';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { riskAssesmentFile } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import imageToBase64 from 'image-to-base64/browser';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ageLimitsOptions = ["U/A", "Adults", "No_limit"]

const EditLink=(props) => {

	const classes = useStyles();

	const history = useHistory();
	const [title, setTitle] = useState('')
    const [showCalendar, setCalendar] = useState(false);
    const [msDate, setMsDate] = useState('');
    const [showTimePicker, setTimePicker] = useState(false);
    const [linkNotes,setLinkNotes] = useState('');  
	const [calendarValue, setCalendarValue] = useState();
    const [timeValue, setTimeValue] = useState("");
    const [endTimeValue, setEndTimeValue] = useState("");
    const [numberofSeats, setNumberofSeats] = useState();
	const [ajaxLoader, setAjaxLoader] = useState(false);
	const [additionalInfo, setAdditionalInfo] = useState("");
	const [uploadFilePath, setUploadFilePath] = useState([]);

	//
	const [businessName, setBusinessName] = useState('');
	const [businessOptions, setBusinessOptions] = useState([]);
	const [facilitatorName, setFacilitatorName] = useState('');
	const [facilitatorOptions, setFacilitatorOptions] = useState([]);
	const [sessionName, setSessionName] = useState('');
	const [sessionOptions, setSessionOptions] = useState([]);
	const [venueName, setVenueName] = useState('');
	const [venueOptions, setVenueOptions] = useState([]);
	const [fieldAge, setFieldAge] = useState('');
	const [isImageUpdated, setIsImageUpdated] = useState(false)

    function calendar(){
    	if(showCalendar === true)
    	{
    		setCalendar(false)
    	}
    	else
    	{
    		setCalendar(true)
    	}
	}

	function timepicker(){
		setTimePicker(true)
	}

    const calendarHandler=(newDate) => {

		let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = year+"-"+month+"-"+date

		setMsDate(fullYear)
		setCalendar(false)
	}

    const timeHandler=(newTime, type)=>{    
      	if(type === "start")
	    {
	    	setTimeValue(newTime)
	    } 
	    else
	    {
	    	setEndTimeValue(newTime)
	    } 
		setTimePicker(false)
    }

    function onChangeSeatsHandler(event){
		setNumberofSeats(event.target.value.replace(/\D/g, ""));
	}


    function get_url_extension( url ) {
    	return url.split(/[#?]/)[0].split('.').pop().trim();
	}

  const getInitialContent=async()=>
  {
  	const allVenueResponse = await getAllVenue()
  	if(allVenueResponse.status === 200)
  	{
  		if(allVenueResponse.data !== undefined && allVenueResponse.data.data.length > 0)
  		{
  			let newArray = [];
					allVenueResponse.data.data.map((data, index) => {
						let obj = {};
						obj['value'] = data.id;
						obj['label'] = data.field_venue_name;
						obj['id'] = data.revision_id;
						newArray.push(obj);
					})
  			setVenueOptions(newArray)
  		}
  	}

  	const businessResponse = await getLinksContent('business_name')
  	if(businessResponse.status === 200)
  	{
  		if(businessResponse.data !== undefined && businessResponse.data.data.length > 0)
  		{
  			let newArray = [];
					businessResponse.data.data.map((data, index) => {
						let obj = {};
						obj['value'] = data.tid;
						obj['label'] = data.name;
						newArray.push(obj);
					})
  			setBusinessOptions(newArray)
  		}
  	}

  	const facilitatorResponse = await getLinksContent('facilitator_name')
  	if(facilitatorResponse.status === 200)
  	{
  		if(facilitatorResponse.data !== undefined && facilitatorResponse.data.data.length > 0)
  		{
  			let newArray = [];
					facilitatorResponse.data.data.map((data, index) => {
						let obj = {};
						obj['value'] = data.tid;
						obj['label'] = data.name;
						newArray.push(obj);
					})
  			setFacilitatorOptions(newArray)
  		}
  	}

  	const sessionResponse = await getLinksContent('session')
  	if(sessionResponse.status === 200)
  	{
  		if(sessionResponse.data !== undefined && sessionResponse.data.data.length > 0)
  		{
  			let newArray = [];
					sessionResponse.data.data.map((data, index) => {
						let obj = {};
						obj['value'] = data.tid;
						obj['label'] = data.name;
						newArray.push(obj);
					})
  			setSessionOptions(newArray)
  		}
  	}

  }

	useEffect(() => {

        async function getInitialData(){
            let link_id = props.match.params.id          
            if(link_id !== ''){
                let responseData = await NodeDetails(link_id)

                if(responseData.status === 200){
                    const regex = /(<([^>]+)>)/ig;

                    if(responseData.data.data.field_date)
                    {
                        let eventDate = responseData.data.data.field_date.length > 0 ? responseData.data.data.field_date[0].value : ""
                        setMsDate(eventDate)
                    }

                    if(responseData.data.data.title)
                    {
                        let _title = responseData.data.data.title.length > 0 ? responseData.data.data.title[0].value : ""
                        setTitle(_title)
                    }

                    if(responseData.data.data.field_time){
                        let timestamp = responseData.data.data.field_time.length > 0 ? responseData.data.data.field_time[0].value : ""
                        if(timestamp !== "" && timestamp !== undefined){
                            var newDateTime = await convertTimeStampToTimeCustom(timestamp)
                            setTimeValue(newDateTime)
                        }else{
                            setTimeValue("N/A")
                        }
                    }

                    if(responseData.data.data.field_session_end_time){
                        let timestamp = responseData.data.data.field_session_end_time.length > 0 ? responseData.data.data.field_session_end_time[0].value : ""
                        if(timestamp !== "" && timestamp !== undefined){
                            var newDateTime = await convertTimeStampToTimeCustom(timestamp)
                            setEndTimeValue(newDateTime)
                        }else{
                            setEndTimeValue("N/A")
                        }
                    }

                    if(responseData.data.data.field_number_of_seats)
                    {
                        let noOfSeats = responseData.data.data.field_number_of_seats.length > 0 ? responseData.data.data.field_number_of_seats[0].value : "N/A"
                        setNumberofSeats(noOfSeats)
                    }

                    if(responseData.data.data.field_addition_info)
                    {
                        let eventAdditionalInfo = responseData.data.data.field_addition_info.length > 0 ? responseData.data.data.field_addition_info[0].value.replace(regex, '') : "N/A"
                        setAdditionalInfo(eventAdditionalInfo)
                    }

                    if(responseData.data.data.field_document !== undefined && responseData.data.data.field_document.length > 0)
                    {
                    	let imageName = responseData.data.data.field_document[0].url.substring(responseData.data.data.field_document[0].url.lastIndexOf('/')+1)
                    	let path = responseData.data.data.field_document[0].url
                    	let newArray = []
                    	newArray.push({'name': imageName,'path':path})
                    	setUploadFilePath(newArray)
                    }

                    if(responseData.data.data.field_business_name)
                    {
                        let _businessName = responseData.data.data.field_business_name.length > 0 ? responseData.data.data.field_business_name[0].target_id : ""
                        if(_businessName !== "")
                        {
                        	let _taxonomyResponse = await getSpecificTaxonomy(responseData.data.data.field_business_name[0].target_id)
                        	if(_taxonomyResponse.status === 200)
                        	{	
                        		if(_taxonomyResponse.data !== undefined && _taxonomyResponse.data.data !== undefined)
                        		{
                        			let obj ={"value": responseData.data.data.field_business_name[0].target_id, "label" : _taxonomyResponse.data.data.name[0].value }
                        			setBusinessName(obj)
                        		}
                        	}
                        }
                        
                    }

                    if(responseData.data.data.field_facilitator)
                    {
                        let _facilator = responseData.data.data.field_facilitator.length > 0 ? responseData.data.data.field_facilitator[0].target_id : ""
                        if(_facilator !== "")
                        {
                        	let _facilatorResponse = await getSpecificTaxonomy(responseData.data.data.field_facilitator[0].target_id)
                        	if(_facilatorResponse.status === 200)
                        	{	
                        		if(_facilatorResponse.data !== undefined && _facilatorResponse.data.data !== undefined)
                        		{
                        			let obj ={"value": responseData.data.data.field_facilitator[0].target_id, "label" : _facilatorResponse.data.data.name[0].value }
                        			setFacilitatorName(obj)
                        		}
                        	}
                        }
                    }

                    if(responseData.data.data.field_session)
                    {
                        let _session = responseData.data.data.field_session.length > 0 ? responseData.data.data.field_session[0].target_id : ""
                        if(_session !== "")
                        {
                        	let _sessionResponse = await getSpecificTaxonomy(responseData.data.data.field_session[0].target_id)
                        	if(_sessionResponse.status === 200)
                        	{	
                        		if(_sessionResponse.data !== undefined && _sessionResponse.data.data !== undefined)
                        		{
                        			let obj ={"value": responseData.data.data.field_session[0].target_id, "label" : _sessionResponse.data.data.name[0].value }
                        			setSessionName(obj)
                        		}
                        	}
                        }
                    }

                    if(responseData.data.data.field_venue_link)
                    {
                        let _venue = responseData.data.data.field_venue_link.length > 0 ? responseData.data.data.field_venue_link[0].target_id : ""
                        if(_venue !== "")
                        {
                        	let _venueResponse = await getSpefificVenue(responseData.data.data.field_venue_link[0].target_id)
                        	if(_venueResponse.status === 200)
                        	{	
                        		if(_venueResponse.data !== undefined && _venueResponse.data.data !== undefined)
                        		{
                        			let obj ={"value": _venueResponse.data.data.id[0].value, "label" : _venueResponse.data.data.field_venue_name[0].value, "id":_venueResponse.data.data.revision_id[0].value }
                        			setVenueName(obj)
                        		}
                        	}
                        }
                    }
                    
                    if(responseData.data.data.field_age)
                    {
                        let _age = responseData.data.data.field_age.length > 0 ? responseData.data.data.field_age[0].value.replace(regex, '') : "N/A"
                        setFieldAge(_age)
                    }

                }
            }
        }

		$(function() { 
			$('.dropdown-toggle').click(function() { 
			$(this).next('.dropdown').slideToggle();
		});

			$(document).click(function(e) { 
				var target = e.target; 
				if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
				{ $('.dropdown').slideUp(); }
			});
		});

		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})

    	getInitialData()
    	getInitialContent()

	},[]);


	const selectCovidPlanDoc=(event) => {
		if(event.target.files && event.target.files[0]) {
			if(Object.keys(event.target.files).length <= 5 && uploadFilePath.length < 5) 
			{
				let imagesArray = []
				Object.values(event.target.files).map(function(filesData, index) {
					let name  = filesData.name;
					if(filesData.type !== 'application/pdf'){
					toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png, pdf', {
			        position: 'top-center'
		        });
		        return false;
					}
					else{
						if(filesData.size > 5000000){
	              			toast.error('Image size exceeds the limit of 5MB', {
					        	position: 'top-center'
				        	});
				        	return false;
						}else{
							setIsImageUpdated(true)
				          	var reader = new FileReader();
				        	reader.onload = function (event) {
				        		imagesArray.push({'name' : name, 'path': event.target.result})
				          	setUploadFilePath([...imagesArray])
				        	};
							reader.readAsDataURL(filesData);
						}
					}
				})
		  }
		  else
		  {
		  	toast.error("You can't upload more than 5 COVID-19 Safe Plan", { position: 'top-center' });
		  }
		}
	}

	const linkSubmission=async(event) => {
		event.preventDefault();
		setAjaxLoader(true);

        let date = msDate.replace(/\//g, '-');
        let seats = numberofSeats;
        let totalTimeStamp, endTimeStamp;

		// if(businessName.value === undefined || businessName.value === ''){
		// 	toast.error('Business name is required', {
		// 		position: 'top-center'
		// 	});
		// 	setAjaxLoader(false);
		// 	return false;
		// }

		// if(facilitatorName.value === undefined || facilitatorName.value === ''){
		// 	toast.error('Facilitator name is required', {
		// 		position: 'top-center'
		// 	});
		// 	setAjaxLoader(false);
		// 	return false;
		// }

		if(venueName.value === undefined || venueName.value === ''){
			toast.error('Venue name is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(sessionName.value === undefined || sessionName.value === ''){
			toast.error('Session name is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(timeValue === undefined || timeValue === ''){
			toast.error('Time is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(msDate === undefined || msDate === ''){
			toast.error('Date is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(timeValue !== "")
		{
			let timeArray = timeValue.split(':')		
			let hours = timeArray[0]
			let minutes = timeArray[1]
			totalTimeStamp = hours* 3600 + minutes*60
		}

		if(fieldAge === undefined || fieldAge === ''){
			toast.error('Field age is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(numberofSeats === undefined || numberofSeats === ''){
			toast.error('Number Of Seats are required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(additionalInfo === undefined || additionalInfo === ''){
			toast.error('Additional info is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(endTimeValue !== "")
		{
			let endTimeArray = endTimeValue.split(':')		
			let hours = endTimeArray[0]
			let minutes = endTimeArray[1]
			endTimeStamp = hours* 3600 + minutes*60
		}



		let data = {
				        "type": [{ "target_id": "links" }],
				        "title" :[{ "value": title}],
				        "field_date": [{ "value": date }],
				        "field_time": [{ "value": totalTimeStamp }],
				        "field_session_end_time":[{ "value": endTimeStamp }],
				        "field_number_of_seats": [{ "value": seats }],
				        "field_addition_info": [{ "value": additionalInfo }],
				        // "field_facilitator":[{ "target_id": facilitatorName.value } ],
				        // "field_business_name": [{ "target_id": businessName.value }],
				        "field_session": [{ "target_id": sessionName.value }],
				        "field_venue_link": [{ "target_id": venueName.value, "target_revision_id": venueName.id }],
				        "field_age": [{ "value": fieldAge }],
				    }
		// if(uploadFilePath.length>0)
		// {
		// 	if(isImageUpdated === true)
		// 	{
		// 		let uploadFileArray = []
		// 		uploadFilePath.map(async(imageData)=>{
		// 			let resObj = await uploadDocument(imageData.name, imageData.path, "create");
		// 			if(resObj.status === 201)
		// 			{
		// 				let newObj = { "target_id": resObj.data.data.fid[0].value }
		// 				uploadFileArray.push(newObj)
		// 				if(uploadFileArray.length === uploadFilePath.length)
		// 				{
		// 					Object.assign(data, { "field_document": [{ "target_id": resObj.data.data.fid[0].value }] }) 
							let res = await updateLinkDetails(props.match.params.id, data);
							if(res.status === 200){
								history.push('/link');
							}
							else{
								toast.error(res.message, {
									position: 'top-center'
								});
								setAjaxLoader(false);
							// }
						// }
					// }
			// 	})
			// }
			// else
			// {
			// 	let res = await updateLinkDetails(props.match.params.id, data);
			// 	if(res.status === 200){
			// 		history.push('/link');
			// 	}
			// 	else{
			// 		toast.error(res.message, {
			// 			position: 'top-center'
			// 		});
			// 		setAjaxLoader(false);
			// 	}
			// }
		}
		// else
		// {
		// 	toast.error("Upload Document is required", {
		// 				position: 'top-center'
		// 			});
		// 			setAjaxLoader(false);
		// }
		
		
						
		
		
	}	

    return (
		<div>
			<ToastContainer />
			<LoaderComponent displayLoader={ajaxLoader} />
       		<HeadOfficeMainHeader />
			<div className="main-wrapper">
		        <div className="container">	
			        <div className="user-pages-wrap">
				        <div className="row">
						    <div className="col-lg-12">
							    <div className="user-types-wrap course-detail-page text-center">
								    <div className="common-header-row common-header-with-large-text">
									    <h4>Edit Link Session</h4>	
                                        <div className="block-right">
                                            <a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
                                        </div>
								    </div>
								    <form onSubmit={linkSubmission} className="sign-up-form">
									    <div className="row">
										    <div className="col-md-12">
											    <div className="row">
											    	<div className="col-lg-4 col-md-4">
													    <label className="mdc-text-field mdc-text-field--outlined">
			                               				<input maxlength="30" onChange={(e)=>setTitle(e.target.value)} type="text" className={title !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" value={title} />
												        <span className="mdc-notched-outline">
												            <span className="mdc-notched-outline__leading"></span>
												            <span className="mdc-notched-outline__notch">
												                <span className="mdc-floating-label" id="my-label-id">Title</span>
												            </span>
												            <span className="mdc-notched-outline__trailing"></span>
												        </span>
													    </label>
												    </div>

												    <div className="col-lg-4 col-md-4">
														<Select className="search-select-field" placeholder="Session Name" style={{ width: 100 }} value={sessionName} options={sessionOptions} onChange={(e)=>setSessionName(e)} />
													</div>

												    <div className="col-lg-4 col-md-4">
														<Select className="search-select-field" placeholder="Venue Name" style={{ width: 100 }} value={venueName} options={venueOptions} onChange={(e)=>setVenueName(e)} />
													</div>

													{
														/*
														<div className="col-lg-4 col-md-4">
															<Select className="search-select-field" placeholder="Business Name" style={{ width: 100 }} value={businessName} options={businessOptions} onChange={(e)=>setBusinessName(e)} />
														</div>
														*/
													}


													{
														/*
														<div className="col-lg-4 col-md-4">
															<Select className="search-select-field" placeholder="Facilitator Name" style={{ width: 100 }} value={facilitatorName} options={facilitatorOptions} onChange={(e)=>setFacilitatorName(e)} />
														</div>
														*/
													}

													<div className="col-lg-4 col-md-4">
														<div className="calendar-wrap">
															<label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar()} >
															<input type="text" readOnly className="mdc-text-field__input" aria-labelledby="my-label-id" style={{cursor:"pointer"}}/>
																<span className="mdc-notched-outline">
																	<span className="mdc-notched-outline__leading"></span>
																	<span className="mdc-notched-outline__notch">
																		<span className="mdc-floating-label" id="my-label-id">{msDate ? <Moment format="DD-MM-YYYY">{msDate}</Moment>: "Date"}</span>
																	</span>
																	<span className="mdc-notched-outline__trailing"></span>
																<span className="material-icons">calendar_today</span>
																</span>
															</label>
															<div>
															{ 
																showCalendar === true ? <Calendar value={calendarValue} onChange={calendarHandler}/> : null
															}   
															</div>
														</div>
												    </div>

												    <div className="col-lg-4 col-md-4">
												    	<TextField id="time" label="Start Time" type="time"
									                    className={classes.textField} value={timeValue} variant="outlined"
									                    InputLabelProps={{shrink: true}} onChange={(e)=>timeHandler(e.target.value,"start")}/>
													</div>

													<div className="col-lg-4 col-md-4">
												    	<TextField id="time" label="End Time" type="time"
									                    className={classes.textField} value={endTimeValue} variant="outlined"
									                    InputLabelProps={{shrink: true}} onChange={(e)=>timeHandler(e.target.value,"end")}/>
													</div>
												    
                                                    	

												    <div className="col-lg-4 col-md-4">
													    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="30" value={fieldAge} onChange={(e)=>setFieldAge(e.target.value)} type="text" className={fieldAge !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"/>
													        <span className="mdc-notched-outline">
													            <span className="mdc-notched-outline__leading"></span>
													            <span className="mdc-notched-outline__notch">
													                <span className="mdc-floating-label" id="my-label-id">Age Group</span>
													            </span>
													            <span className="mdc-notched-outline__trailing"></span>
													        </span>
													    </label>
												    </div>

												    <div className="col-lg-4 col-md-4">
													    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="6" value={numberofSeats} onChange={onChangeSeatsHandler} type="text" className={numberofSeats ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"/>
													        <span className="mdc-notched-outline">
													            <span className="mdc-notched-outline__leading"></span>
													            <span className="mdc-notched-outline__notch">
													                <span className="mdc-floating-label" id="my-label-id">Maximum Capacity</span>
													            </span>
													            <span className="mdc-notched-outline__trailing"></span>
													        </span>
													    </label>
												    </div>

												    {/* <div className="col-lg-4 col-md-4">
														<label className="mdc-text-field mdc-text-field--outlined">
					                       				<input onChange={(e)=>selectCovidPlanDoc(e)} type="file" accept=".pdf" id="attach-file"  className="mdc-text-field__input" aria-labelledby="my-label-id"/>
													        <span className="mdc-notched-outline">
													            <span className="mdc-notched-outline__leading"></span>
													            <span className="mdc-notched-outline__notch">
													                <span className="mdc-floating-label" id="my-label-id">{uploadFilePath.length > 0 ? uploadFilePath[0].name : "Upload Document[Term/Session Plan]"}</span>
													            </span>
													            <span className="mdc-notched-outline__trailing"></span>
													        </span>
													        <span className="material-icons">image</span>
													    </label>
															<small className="short-description">Upload only pdf with limit upto 5MB</small>
													</div> */}

											    </div>
										    </div>
									    </div>	
                                        <div className="row">
											<div className="col-md-12">
											    <div className="outlined-textfield-wrap mt-10">
												    <div className="material-textfield">
													    <textarea maxlength = "200" value = {additionalInfo} id="message" className={additionalInfo !== '' ? 'form__field active' : 'form__field'} placeholder="" rows="6" onChange={(e)=>setAdditionalInfo(e.target.value)}></textarea>
													    <label>Additional Info</label>
													</div>
												</div>
											</div>
                                        </div>


                                        <button className="mdc-button mdc-button--raised mt-40">
                                            <span className="mdc-button__label">Submit</span>
                                        </button>
								    </form>
							    </div>	
						    </div>	
				        </div>
			        </div>		
		        </div>	
	        </div> 
	    </div>	 
    )
}

export default EditLink;
