import React, { useState } from 'react';
import HeaderWithoutLogin from '../../../commonComponents/HeaderWithoutLogin/HeaderWithoutLogin';
import { useHistory } from "react-router-dom";

//Import Images
import user from '../../../../src/images/user.png';
import user1 from '../../../../src/images/user1.png';

const UserRolesPage = () => {

  let history = useHistory();
  const [userRole, setUserRole] = useState(1);
  const [educatorActiveClass, setEducatorActiveClass] = useState("active");
  const [headOfficeActiveClass, setHeadOfficeActiveClass] = useState("");

  const selectUserRole = (role) => {

    if (role === 1) {
      setUserRole(1);
      setEducatorActiveClass("active");
      setHeadOfficeActiveClass("");
    } else if (role === 2) {
      setUserRole(2);
      setHeadOfficeActiveClass("active");
      setEducatorActiveClass("");
    }
  }

  const goNext = () => {
    if (userRole === 1) {
      history.push('/signup-educator');
    } else {
      history.push('/signup-co-ordination-unit');
    }
  }

  return (
    <div>
      <HeaderWithoutLogin />
      <div className="main-wrapper">
        <div className="container" >
          <div className="user-pages-wrap" >
            <div className="row" >
              <div className="col-md-8 offset-md-2" >
                <div className="user-types-wrap text-center" >
                  <h1 className="common-heaidng48" > What role are you playing ? </h1>
                  <h3 className="small-title" > Please select the account type, you are willing to create. </h3>
                  <ul className="user-bloxes-list" >
                    <li onClick={
                      () => selectUserRole(1)
                    }
                      className={educatorActiveClass} >
                      <figure className="media-row" > < img src={user1} alt='' /> </figure >
                      <span className="designaiton"
                        id="educator" > Educator </span>
                      <p> A Branch of WISE </p>
                    </li> <li onClick={
                      () => selectUserRole(2)
                    }
                      className={headOfficeActiveClass} >
                      <figure className="media-row" >
                        <img src={user} alt='' />
                      </figure >
                      <span className="designaiton"
                        id="head-office-team" > Co-ordination Unit </span>
                      <p> Managing Everthing </p>
                    </li>
                  </ul>
                  <button onClick={
                    () => goNext()
                  }
                    className="mdc-button mdc-button--raised green-btn" >
                    <span className="mdc-button__label" > next </span> </button >
                </div>
              </div >
            </div>
          </div >
        </div>
      </div>
    </div>
  )
}

export default UserRolesPage;