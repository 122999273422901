import React, { useState, useEffect } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { $ } from 'react-jquery-plugin';
import { useDispatch } from 'react-redux';
import { getLoggedInUserDetail } from '../../../redux/actions/auth';
import { appliedLeaves, getParticularUserContent, getReminders, getLeaves, getReminderOfChildOccupants, updateLeaves, deleteLeaves, userBookings } from '../../../functions/api';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from "react-modern-calendar-datepicker";
import LeaveCalendar from 'react-calendar';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './styles.css';
import { Modal } from 'react-responsive-modal';
import { getTaskListingAsPerDate, getVisitListingAsPerDate } from '../../../functions/api';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Moment from 'react-moment';
import { Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { confirmAlert } from 'react-confirm-alert';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'


const EducatorsDashboard = () => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [calendarDates, setCalendarDates] = useState([]);
  const [calendarTasks, setCalendarTasks] = useState([]);
  const [calendarVisits, setCalendarVisits] = useState([]);
  const [remindersArray, setRemindersArray] = useState([]);
  const [calendarReminderNotices, setCalendarReminderNotices] = useState([]);
  const [leaveModal, setLeaveModal] = useState(false)
  const [selectedLeaveVal, setSelectedLeaveVal] = useState()
  const [oneDayLeaveCal, setOneDayLeaveCal] = useState(false);
  const [multipleDaysStartCal, setMultipleDaysStartCal] = useState(false)
  const [multipleDaysEndCal, setMultipleDaysEndCal] = useState(false)
  const [oneDayLeaveValue, setOnedayLeaveValue] = useState()
  const [leavesId, setLeavesId] = useState();
  const [leaveStartDate, setLeaveStartDate] = useState();
  const [leaveEndDate, setLeaveEndDate] = useState();
  const [allLeaves, setAllLeaves] = useState([]);
  const [showLoader, setShowLoader] = useState(true)
  const [allLinksBooked, setLinksBooked] = useState([])
  const [timeStampEnd, setTimeStampEnd] = useState()
  const [timeStampStart, setTimeStampStart] = useState()
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");

  useEffect(() => {
    window.localStorage.removeItem('folderParentId')
    getAllCalenderData()
  }, [])

  const getDayMonthDay = (date) => {
    var dateObj = new Date(date)
    var month = dateObj.getUTCMonth() + 1
    var day = dateObj.getUTCDate()
    var year = dateObj.getUTCFullYear()

    var newDate = {};
    newDate['year'] = year;
    newDate['month'] = month;
    newDate['day'] = day;

    return newDate;
  }

  //converting weeks into days
  const weeksIntoDays = (weeks) => {
    let days = parseInt(weeks) * 7;
    return days;
  }

  const month = (date) => {
    const m = date.getMonth() + 1;
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  };

  const custom_day = (date) => {
    const m = date.getDate();
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  }
  // Leave Process
  const applyForLeave = (data, isEdit) => {
    if (isEdit) {
      setDefaultStartDate(new Date(data.start_date));
      setDefaultEndDate(new Date(data.end_date));
    } else {
      setDefaultStartDate("");
      setDefaultEndDate("");
    }
    data?.end_date === data?.start_date ? setSelectedLeaveVal("onedayleave") : setSelectedLeaveVal("multipledaysleave")
    if (data.start_date === data.end_date) {
      setOnedayLeaveValue(data.start_date)
    } else {
      setOnedayLeaveValue('')
    }
    if (data.start_date !== data.end_date) {
      setLeaveStartDate(data.start_date)
      setLeaveEndDate(data.end_date)
      setLeavesId(data.id)
    } else {
      setLeaveStartDate('')
      setLeaveEndDate('')
      setLeavesId(data.id)
    }
    setLeaveModal(true)
    setOneDayLeaveCal(false)
    setMultipleDaysStartCal(false)
    setMultipleDaysEndCal(false)
  }

  //---------------------- UPDATE LEAVE-----------------------
  const updateLeave = () => {

  }

  const leaveHandler = (event) => {
    setSelectedLeaveVal(event.target.value)

  }

  const leaveCalendarClickable = (value) => {
    if (value === 'onedayleave') {
      setOneDayLeaveCal(true);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(false);
    } else if (value === 'startdate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(true);
      setMultipleDaysEndCal(false);
    } else if (value === 'enddate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(true);
    }
  }

  const oneDayLeavesCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setOnedayLeaveValue(fullYear)
    setOneDayLeaveCal(false)
  }

  const multipleLeavesStartCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampStart(d)

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveStartDate(fullYear)
    setMultipleDaysStartCal(false)

  }

  const multipleLeavesEndCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampEnd(d)

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveEndDate(fullYear)
    setMultipleDaysEndCal(false)
  }


  async function deleteLeavesData(id) {
    let response = await deleteLeaves(id);
    if (response.status === 200) {

      getAllCalenderData()
      toast.success('Leaves has been deleted successfully!', {
        position: 'top-center'
      });
      getLeavesData()
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteLeaves(id) {

    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLeavesData(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  async function oneDayLeaveApplicationForm(e) {
    e.preventDefault();
    setShowLoader(true)
    var new_date = moment(oneDayLeaveValue, "YYYY-MM-DD");
    var defaultEndDate = new_date.add(0, 'days').format('YYYY-MM-DD');
    let data = { startDate: oneDayLeaveValue, endDate: defaultEndDate, userId: window.localStorage.getItem('uid') }
    if (oneDayLeaveValue !== '') {


      if (leavesId !== undefined) {
        let response = await updateLeaves(data, leavesId);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Updated Successfully !!', { position: 'top-center' });
          getAllCalenderData()
          setLeaveModal(false);
          getLeavesData()
        }
      }
      else {
        let response = await appliedLeaves(data);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Submitted !!', { position: 'top-center' });
          setLeaveModal(false);
          getLeavesData()
        } else {
          setShowLoader(false)
          toast.error(response.message, { position: 'top-center' });
        }
      }
    } else {
      setShowLoader(false)
      toast.error('Date is required', { position: 'top-center' });
    }

  }

  async function multipleDaysLeaveApplicationForm(e) {
    e.preventDefault();
    setShowLoader(true);

    let data = { startDate: leaveStartDate, endDate: leaveEndDate, userId: window.localStorage.getItem('uid') }
    if (leaveStartDate !== '' && leaveEndDate !== '') {
      if (new Date(timeStampStart) < new Date(timeStampEnd)) {
        if (leavesId !== undefined) {
          let response = await updateLeaves(data, leavesId);
          if (response.status === 200) {
            toast.success('Updated Successfully !!', { position: 'top-center' });
            setShowLoader(false)
            setLeaveModal(false);
            getLeavesData()
          }
        }
        else {
          let response = await appliedLeaves(data);
          if (response.status === 200) {
            setShowLoader(false)
            toast.success('Submitted !!', { position: 'top-center' });
            setLeaveModal(false)
            getLeavesData()
          } else {
            setShowLoader(false)
            toast.error(response.message, { position: 'top-center' });
          }
        }
      }
      else {
        setShowLoader(false)
        toast.error('Start Date must be lesser than End Date', { position: 'top-center' });
      }
    } else {
      setShowLoader(false)
      toast.error('Start Date and End Date is required', { position: 'top-center' });
    }
  }

  async function getLeavesData() {
    let res = await getLeaves(window.localStorage.getItem('uid'));
    if (res.status === 200) {
      if (res.data.data.length > 0) {
        setAllLeaves(res.data.data);
      }
    }
  }

  useEffect(() => {
    setSelectedLeaveVal('onedayleave')
    getLeavesData()

    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

    async function getAllInitialData() {
      if (window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined) {
        window.localStorage.removeItem("educatorSignupDetail");  //remove signup page detail
      }
      if (window.localStorage.getItem('headOfficeSignupDetail') !== null && window.localStorage.getItem('headOfficeSignupDetail') !== undefined) {
        window.localStorage.removeItem("headOfficeSignupDetail");  //remove signup page detail
      }
      let uid = window.localStorage.getItem('uid');
      let authToken = window.localStorage.getItem('auth_token');
      if (authToken !== null && uid !== '') {
        let responseData = await dispatch(getLoggedInUserDetail(uid, authToken))
        if (responseData.status !== 200) {
          toast.error(responseData.message, { position: 'top-center' });
        }
      }
    }
    getAllInitialData()
  }, [])

  function getFinalDate(initialDate, reminderDays) {
    const d = new Date(initialDate);
    d.setDate(d.getDate() - reminderDays);
    return new Date(d);
  }
  const getAllCalenderData = async () => {
    let allDatesArray = []
    let uid = window.localStorage.getItem('uid');
    let user_role = window.localStorage.getItem('user_role');

    let calendarTasksArray = await getParticularUserContent('task', uid);
    if (calendarTasksArray.data !== undefined && calendarTasksArray.data.data.length > 0) {

      for (const mydata of calendarTasksArray.data.data) {
        const fieldDate = mydata.field_date;
        if (fieldDate && fieldDate[0]?.value) {
          const taskDate = getDayMonthDay(fieldDate[0].value);
          if (taskDate) {
            allDatesArray.push({ className: 'greenDay', ...taskDate });
          }
        }
      }
    }

    //getLinksBooked
    const calendarLinksBooked = await userBookings(uid);

    for (const mydatabooked of calendarLinksBooked.data.data) {
      const fieldDate = mydatabooked.field_date;
      if (fieldDate && fieldDate !== '') {
        const linkBookedDate = getDayMonthDay(fieldDate);
        if (linkBookedDate) {
          allDatesArray.push({ className: 'greenDay', ...linkBookedDate });
        }
      }
    }

    let visitsArray = await getParticularUserContent('visits', uid)
    if (visitsArray.data?.data && visitsArray.data.data.length > 0) {
      for (const mydata of visitsArray.data.data) {
        const fieldDate = mydata.field_date;
        if (fieldDate && fieldDate[0]?.value) {
          const visitDate = getDayMonthDay(fieldDate[0].value);
          if (visitDate) {
            allDatesArray.push({ className: 'greenDay', ...visitDate });
          }
        }
      }
    }

    let _remindersArray = [];
    let reminders = await getReminders(uid, user_role);
    // console.log('reminders', reminders.data?.data);
    let allReminderDates = [];
    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          const expiryDate = reminderData[0]?.[`field_${fieldName}`];
          const courseReminder = reminderData[0]?.[`field_${courseName.toLowerCase()}_course_reminder`];

          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [`${courseName.toLowerCase()}_course_reminder_date`]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: `${courseName === 'first_aid' ? 'First Aid' : 'CPR'} Course`,
              [`${courseName.toLowerCase()}_course_reminder`]: `expires in ${courseReminder}`,
              date: formattedDate
            };

            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
            if(expiryDate != ""){
              const finalDate2 = new Date(expiryDate);
              const formattedDate2 = moment(finalDate2).format('DD-MM-YYYY');
              const courseCompletionDate2 = getDayMonthDay(finalDate2);
              const customObj = {
                [`${courseName.toLowerCase()}_course_reminder_date`]: `${finalDate2.getFullYear()}-${month(finalDate2)}-${custom_day(finalDate2)}`,
                course_name: `${courseName === 'first_aid' ? 'First Aid' : 'CPR'} Course`,
                [`${courseName.toLowerCase()}_course_reminder`]: 'expires today',
                date: formattedDate2
              };
  
              const obj = { className: 'greenDay', ...courseCompletionDate2 };
              pushToArrays(obj, customObj);
            }
          }
        }

        function pushToArrays(obj, customObj) {
          allDatesArray.push(obj);
          _remindersArray.push(obj);
          allReminderDates.push(customObj);
        }

        // const courses = ['FirstAid', 'CPR', 'Asthma', 'anaphylaxis', 'vehicle_restraint', 'vehicle', 'registration'];
        // Removed asthma and anaphylaxis becaquse client removed this from web
        const courses = ['first_aid', 'CPR'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));

        function processRemainderExpiry(reminderData, expiry, reminder, courseName, prefix) {
          const expiryDate = reminderData[0]?.[expiry];
          const courseReminder = reminderData[0]?.[reminder];
          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                reminderDays = d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [prefix]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: courseName,
              [`reminder`]: `expires in ${courseReminder}`,
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
            if(expiryDate != ""){
              const finalDate2 = new Date(expiryDate);
              const formattedDate2 = moment(expiryDate).format('DD-MM-YYYY');
              const courseCompletionDate2 = getDayMonthDay(finalDate2);
              const customObj = {
                [prefix]: `${finalDate2.getFullYear()}-${month(finalDate2)}-${custom_day(finalDate2)}`,
                course_name: courseName,
                [`reminder`]: `expires today`,
                date: formattedDate2
              };
              const obj = { className: 'greenDay', ...courseCompletionDate2 };
              pushToArrays(obj, customObj);
            }
          }
        }
        processRemainderExpiry(reminders.data.data, "field_pool_safety_cert_exp_date", "field_pool_safety_cert_set_remin", "Pool Safety Certification Course", "field_pool_safety_reminder_date")
        processRemainderExpiry(reminders.data.data, 'field_registration_expiry_date', 'field_reg_exp_reminder_date', 'Registration', "field_registration_expiry_date");
        processRemainderExpiry(reminders.data.data, 'field_insurance_expiry_date', 'field_public_reminder_date', "Public Insurance", "field_insurance_expiry_date");
        processRemainderExpiry(reminders.data.data, 'field_vehicle_expiry_date', 'field_restraint_audit_reminder', 'Vehicle', "field_vehicle_expiry_date");
        processRemainderExpiry(reminders.data.data, 'field_vehicle_restraint_expiry', 'field_maintenance_reminder_date', 'Vehicle Child / Restraint', "field_vehicle_restraint_expiry");
        processRemainderExpiry(reminders.data.data, 'field_business_expiry_date', 'field_set_reminder_frequency', 'Annual Assessment of Residence', "field_business_expiry_date");

        // field_maintenance_reminder_date, field_vehicle_audit_date

        function processEducatorRemainderExpiry(reminderData) {
          const expiryDate = reminderData[0]?.[`field_blue_card_expiry_date`];
          const courseReminder = reminderData[0]?.[`field_educator_reminder`];
          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                reminderDays = d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [`field_blue_card_reminder_date`]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: `Educator Blue Card`,
              [`reminder`]: reminderDays > 1 ? `expires in ${courseReminder}` : 'expires today',
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
          }
        }
        processEducatorRemainderExpiry(reminders.data.data)
        function processTeacherRemainderExpiry(reminderData) {
          const expiryDate = reminderData[0]?.[`field_teacher_expiry_date`];
          const courseReminder = reminderData[0]?.[`field_teacher_reminder`];
          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                reminderDays = d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [`field_educator_teacher_reminder_date`]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: `Educator Teacher Registration`,
              [`reminder`]: reminderDays > 1 ? `expires in ${courseReminder}` : 'expires today',
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
          }
        }
        processTeacherRemainderExpiry(reminders.data.data)

        function processLicenseExpiry(reminders) {
          const licenseExpiryDate = reminders.data.data[0]?.field_licence_expiry_date;
          const licenseReminderDate = reminders.data.data[0]?.field_license_exp_reminder_date;
          if (licenseExpiryDate !== '' && licenseReminderDate !== '') {
            const d = new Date(licenseExpiryDate);
            let reminderDays = 0;

            switch (licenseReminderDate) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(licenseReminderDate);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                reminderDays = d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              field_licence_expiry_date: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: 'Vehicle Licence',
              [`reminder`]: `expires in ${licenseReminderDate}`,
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
            if(licenseExpiryDate != ""){
              const d = new Date(licenseExpiryDate);
              const finalDate = new Date(licenseExpiryDate);
              const formattedDate = moment(finalDate).format('DD-MM-YYYY');
              const licenseExpiryDateObj = getDayMonthDay(finalDate);
              const customObj = {
                field_licence_expiry_date: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
                course_name: 'Vehicle Licence',
                reminder: `expires today`,
                date: formattedDate
              };
              const obj = { className: 'greenDay', ...licenseExpiryDateObj };
              pushToArrays(obj, customObj);
            }
          }
        }

        processLicenseExpiry(reminders);

        function processExpiryWithReminder(reminderDate, reminderDays, courseName, courseType) {
          if (reminderDate !== '' && reminderDays !== '') {
            const d = new Date(reminderDate);
            const finalDate = getFinalDate(d, reminderDate);
            // const formattedDate = moment(finalDate).format('DD-MM-YYYY')
            const formattedDate = moment(reminderDate).format('YYYY-MM-DD')
            const courseExpiryDateObj = getDayMonthDay(formattedDate);
            
            const customObj = {
              [`${courseType}_expiry_date`]: `${formattedDate}`,
              course_name: courseName,
              reminder: reminderDays > 1 ? `expires in ${reminderDate}` : 'expires today',
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseExpiryDateObj };
            pushToArrays(obj, customObj);
          }
        }

        const blueCardExpiryDate = reminders.data.data[0]?.field_blue_card_expiry_date;
        const educatorReminder = reminders.data.data[0]?.field_educator_reminder;
        processExpiryWithReminder(blueCardExpiryDate, educatorReminder, 'Educator Blue Card', 'field_blue_card');

        const teacherExpiryDate = reminders.data.data[0]?.field_teacher_expiry_date;
        const teacherReminder = reminders.data.data[0]?.field_teacher_reminder;
        processExpiryWithReminder(teacherExpiryDate, teacherReminder, 'Teacher Registration', 'field_teacher');

        // const insuranceExpiryDate = reminders.data.data[0]?.field_insurance_expiry_date;
        // const publicReminderDate = reminders.data.data[0]?.field_public_reminder_date;
        // processExpiryWithReminder(insuranceExpiryDate, publicReminderDate, 'Public Liability Insurance', 'field_insurance');

        // const businessExpiryDate = reminders.data.data[0]?.field_business_expiry_date;
        // const setReminderFrequency = reminders.data.data[0]?.field_set_reminder_frequency;
        // processExpiryWithReminder(businessExpiryDate, setReminderFrequency, 'Annual Assessment of Residence', 'field_business');

        const adultOccupantResponse = await getReminderOfChildOccupants(uid);
        if (adultOccupantResponse.status === 200) {
          adultOccupantResponse && adultOccupantResponse.data.data.forEach((value) => {
            const processExpiryDate = (expiryDate, reminderType, prefix) => {
              if (expiryDate !== '' && reminderType !== '') {
                let d = new Date(expiryDate);
                if (['8 Weeks', '6 Weeks', '4 Weeks', '2 Weeks', '1 Week'].includes(reminderType)) {
                  d.setDate(d.getDate() - weeksIntoDays(reminderType));
                } else if (reminderType === '1 Day') {
                  d.setDate(d.getDate() - 1);
                } else {
                  d.setDate(d.getDate());
                }
                const finalDate = d.getFullYear() + '-' + month(d) + '-' + custom_day(d);
                const reminderDate = new Date(expiryDate);
                const customObj = {
                  [prefix]: finalDate,
                  course_name: `Adult Occupant Card (${value.field_occupant_first_name} ${value.field_occupant_last_name})`,
                  reminder: `expires in ${reminderType}`,
                  date: moment(reminderDate).format('DD-MM-YYYY')
                };
                const expiryDateObj = getDayMonthDay(finalDate);
                const obj = { className: 'greenDay', ...expiryDateObj };
                allDatesArray.push(obj);
                _remindersArray.push(obj);
                allReminderDates.push(customObj);
                if (expiryDate !== '') {
                  const originalExpiryDate = new Date(expiryDate);
                  const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
                  const originalCustomObj = {
                    [prefix]: `${originalExpiryDate.getFullYear()}-${month(originalExpiryDate)}-${custom_day(originalExpiryDate)}`,
                    course_name: `Adult Occupant Card (${value.field_occupant_first_name} ${value.field_occupant_last_name})`,
                    reminder: 'expires today',
                    date: moment(originalExpiryDate).format('DD-MM-YYYY')
                  };
                  const originalObj = { className: 'greenDay', ...originalExpiryDateObj };
                  allDatesArray.push(originalObj);
                  _remindersArray.push(originalObj);
                  allReminderDates.push(originalCustomObj);
                }
              }
            };
            processExpiryDate(value.field_occupant_card_expiry_date, value.field_reminder_blue_card, 'field_occupant_card_expiry_date');
            processExpiryDate(value.field_teacher_expiry_date, value.field_teacher_adult_reminder, 'field_teacher_expiry_date_adult');
          });
        }
      }
    }
    setShowLoader(false)
    setCalendarDates(allDatesArray);
    setRemindersArray(allReminderDates);
  }

  function _onDateFormat(date) {
    if (!date) {
      return ""
    }

    let newDate = date.split('-')
    let day = newDate[2].split(' 00:00:00')
    return `${day[0]}-${newDate[1]}-${newDate[0]}`
  }


  async function calendarHandler(date) {
    let d = ''
    let m = ''
    if (date.day < 10) {
      d = "0".concat(date.day)
    } else {
      d = date.day
    }

    if (date.month < 10) {
      m = "0".concat(date.month)
    } else {
      m = date.month
    }

    let Date = date.year + "-" + m + "-" + d
    setShowLoader(true)
    setCalendarTasks([])
    setCalendarVisits([])
    setCalendarVisits([])
    setLinksBooked([])
    setOpen(true)


    let userId = window.localStorage.getItem('uid');
    const calendarLinksBooked = await userBookings(userId);
    if (calendarLinksBooked.status === 200) {
      const filteredData = calendarLinksBooked.data.data.filter(data => data.field_date === Date);
      setLinksBooked(filteredData);
    }

    var uid = window.localStorage.getItem('uid')
    var month = date.month < 10 ? '0' + date.month : date.month;
    var day = date.day < 10 ? '0' + date.day : date.day;

    let fullYear = date.year + "-" + month + "-" + day
    const processListing = (listing, setStateFunction) => {
      setShowLoader(false);
      if (listing.status === 200 && listing.data?.data?.length > 0) {
        const newArray = listing.data.data.map(item => {
          const obj = {};
          if (item.title !== undefined && item.title !== '') {
            obj['title'] = item.title[0]?.value || null;
          }
          if (item.nid !== undefined && item.nid !== '') {
            obj['nid'] = item.nid[0]?.value || null;
          }
          return obj;
        });
        setStateFunction(newArray);
      }
    }
    const taskListing = await getTaskListingAsPerDate(fullYear, uid);
    processListing(taskListing, setCalendarTasks);
    const visitListing = await getVisitListingAsPerDate(fullYear, uid);
    processListing(visitListing, setCalendarVisits);

    let calendarRemindersArray = [];
    if (remindersArray.length > 0) {
      setShowLoader(false)
      const reminderProperties = [
        { key: 'first_aid_course_reminder_date', timingKey: 'first_aid_course_reminder' },
        { key: 'cpr_course_reminder_date', timingKey: 'cpr_course_reminder' },
        // { key: 'field_vehicle_restraint_expiry', timingKey: 'vehicle_restraint_expiry' },
        // { key: 'field_vehicle_expiry_date', timingKey: 'vehicle_restraint_expiry' },
        // { key: 'field_registration_expiry_date', timingKey: 'field_set_reminder_frequency' },
        { key: 'field_licence_expiry_date', timingKey: 'reminder' },
        // { key: 'field_insurance_expiry_date', timingKey: 'reminder' },
        { key: 'field_occupant_card_expiry_date', timingKey: 'reminder' },
        { key: 'field_teacher_expiry_date_adult', timingKey: 'reminder' },
        { key: 'field_business_expiry_date', timingKey: 'reminder' },
        { key: 'field_blue_card_reminder_date', timingKey: 'reminder' },
        { key: 'field_educator_teacher_reminder_date', timingKey: 'reminder' },
        { key: 'field_blue_card_expiry_date', timingKey: 'reminder' },
        { key: 'field_teacher_expiry_date', timingKey: 'reminder' },
        { key: 'field_pool_expiry_date', timingKey: 'reminder' },
        { key: 'field_pool_safety_reminder_date', timingKey: 'reminder' },
        { key: 'field_pool_safety_reminder', timingKey: 'reminder' },
        { key: 'field_registration_expiry_date', timingKey: 'reminder' },
        { key: 'field_insurance_expiry_date', timingKey: 'reminder' },
        { key: 'field_vehicle_expiry_date', timingKey: 'reminder' },
        { key: 'field_vehicle_restraint_expiry', timingKey: 'reminder' },
      ];
      // field_pool_expiry_date
      for (let reminder of remindersArray) {
        for (let prop of reminderProperties) {
          if (fullYear === reminder[prop.key]) {
            let obj = {
              course_name: reminder.course_name,
              weekly_timings: reminder[prop.timingKey],
              date: reminder.date
            };
            calendarRemindersArray.push(obj);
          }
        }
      }

    }
    else {
      setShowLoader(false)
    }
    setCalendarReminderNotices(calendarRemindersArray);
  }



  // 
  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      <EducatorsMainHeader />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap educator-dashboard-page">

            <div className="col-md-4">
              <button onClick={applyForLeave} className="mdc-button mdc-button--raised">Advise Leave Dates</button>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <TableContainer className="educators-leaves-listing">
                    <h1 className='ceneterd-text' >Leave Dates</h1>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          allLeaves.length > 0 ? allLeaves.map((leave, index) => (
                            <TableRow key={index}>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.start_date}</Moment></TableCell>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.end_date}</Moment></TableCell>
                              <TableCell><span onClick={() => applyForLeave(leave, true)} style={{ cursor: "pointer" }} className="material-icons">edit</span></TableCell>
                              <TableCell><span onClick={() => confirmDeleteLeaves(leave.id)} style={{ cursor: "pointer" }} className="material-icons">delete</span></TableCell>
                            </TableRow>
                          )) : null
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-block-right">
                  <div className="calender-block full-width-calender">
                    <Calendar customDaysClassName={calendarDates} onChange={(date) => calendarHandler(date)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => [setOpen(false), setCalendarReminderNotices([])]} center>
        <div className="main-wrapper">
          <div className="container">
            <div className="dashboard-wrap no-tabs">
              <div className="calender-popup calender-popup-with-tabs">
                <div className='c-box-parent'>
                  <h2 className='reminder-dashboard-popup'>Alerts</h2>
                  <div className='c-tab tab1 mb-3'>
                    <p className='reminder-popup-subheading'>Reminders</p>
                    <div className='c-content'>
                      <div className='course-detail-last pb-25'>
                        <>
                          {
                            <div className="reminders-tab-info">
                              <ul style={{ width: '100%' }}>
                                {
                                  calendarReminderNotices.length > 0 ? calendarReminderNotices.map((reminder, index) => {
                                    return (
                                      <li className="list-content" key={index}>
                                        <h2>{reminder.course_name} {reminder.weekly_timings}</h2>
                                      </li>
                                    )
                                  }) : <h2 style={{ fontWeight: '900' }}>No Alerts</h2>
                                }
                              </ul>
                            </div>
                          }
                        </>
                      </div>
                    </div>
                  </div>
                  <div className='c-tab tab2 mb-3'>
                    <p className='reminder-popup-subheading'> M&S </p>
                    <div className='c-content'>
                      <ul style={{ width: '100%' }}>
                        {
                          calendarVisits.length > 0 ? calendarVisits.map((visit, index) => (
                            <li className="list-content" key={index}>
                              <div><h2>{visit.title}</h2></div>
                              <div>
                                <Link to={`/m&s-detail-page/${visit.nid}`} className="mdc-button mdc-button--raised green-btn">
                                  <span className="mdc-button__label">View details</span>
                                </Link>
                              </div>
                            </li>
                          )) : <h2 style={{ fontWeight: '900' }}>No Alerts</h2>
                        }
                      </ul>
                    </div>
                  </div>
                  <div className='c-tab tab3 mb-3'>
                    <p className='reminder-popup-subheading'>Tasks</p>
                    <div className='c-content'>
                      <>
                        {
                          <ul style={{ width: '100%' }}>
                            {
                              calendarTasks.length > 0 ? calendarTasks.map((task, index) => (
                                <li className="list-content" key={index}>
                                  <h2>{task.title}</h2>
                                  <Link to={`/task-detail-page/${task.nid}`} className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">View details</span>
                                  </Link>
                                </li>
                              )) : <h2 style={{ fontWeight: '900' }}>No Alerts</h2>
                            }
                          </ul>
                        }
                      </>
                    </div>
                  </div>
                  <div className='c-tab tab4 mb-3'>
                    <p className='reminder-popup-subheading'>LINK</p>
                    <div className='c-content'>
                      <>
                        {
                          <ul style={{ width: '100%' }}>
                            {
                              allLinksBooked.length > 0 ? allLinksBooked?.map((booked, index) => (
                                // console.log('allLinks', allLinksBooked),
                                <li className="list-content" key={index}>
                                  <h2 className='list-content-title'>{booked?.field_link_name_1}</h2>
                                  <Link to={`/link-detail-page/${booked?.nid_1}`} className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">View details</span>
                                  </Link>
                                </li>
                              )) : <h2 style={{ fontWeight: '900' }}>No Alerts</h2>
                            }
                          </ul>
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={leaveModal} onClose={() => setLeaveModal(false)} center>
        <FormControlLabel value="onedayleave" label="One Day Leave" control={<Radio value="onedayleave" checked={selectedLeaveVal === "onedayleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'A' }} />} />
        <FormControlLabel value="multipledaysleave" label="Multiple Days Leave" control={<Radio value="multipledaysleave" checked={selectedLeaveVal === "multipledaysleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'B' }} />} />
        <>
          {selectedLeaveVal === 'onedayleave' ?
            <form onSubmit={oneDayLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('onedayleave')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={_onDateFormat(oneDayLeaveValue)} type="text" className={oneDayLeaveValue !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">{selectedLeaveVal === 'multipledaysleave' ? 'Start Date' : 'Date'}</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        oneDayLeaveCal === true ? <LeaveCalendar onChange={oneDayLeavesCalendarHandler} value={defaultStartDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button className="mdc-button mdc-button--raised mt-40">
                <span className="mdc-button__label" onClick={() => updateLeave(leavesId)}>Submit</span>
              </button>
            </form>
            :
            <form onSubmit={multipleDaysLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('startdate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveStartDate ? _onDateFormat(leaveStartDate) : leaveStartDate} type="text" className={leaveStartDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">Start Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysStartCal === true ? <LeaveCalendar onChange={multipleLeavesStartCalendarHandler} value={defaultStartDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('enddate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveEndDate ? _onDateFormat(leaveEndDate) : leaveEndDate} type="text" className={leaveEndDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">End Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysEndCal === true ? <LeaveCalendar onChange={multipleLeavesEndCalendarHandler} value={defaultEndDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button className="mdc-button mdc-button--raised mt-40">
                <span className="mdc-button__label" onClick={() => updateLeave(leavesId)}>Submit</span>
              </button>
            </form>
          }
        </>
      </Modal>
    </>
  )
}

export default EducatorsDashboard;