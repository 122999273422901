import React, { useState, useEffect } from 'react';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { $ } from 'react-jquery-plugin';
import { getAllEducators, uploadImage, postEntityMedia } from '../../../functions/api';
import './styles.css';
import { createNoticeBoard } from '../../../functions/api';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from 'react-calendar';
import moment from 'moment';

const CreateNewNoticePage = () => {

  const [options, setOptions] = useState([]);
  const [educatorsList, setEducatorsList] = useState([])
  const [educatorName, setEducatorName] = useState('')
  const [url, setUrl] = useState("")
  const [noticeNotes, setNoticeNotes] = useState("");
  const [addImageName, setAddImageName] = useState("");
  const [addImagePath, setAddImagePath] = useState([]);
  const history = useHistory();
  const [title, setTitle] = useState();
  const [ajaxLoader, setAjaxLoader] = useState(false);
  const [showCalendar, setShowCalender] = useState(false)
  const [publishDate, setPublishDate] = useState('')
  const [archievedDate, setArchievedDate] = useState('')
  const [showArchiveCalendar, setShowArchiveCalender] = useState(false)
  const [multipleUrls, setMultipleUrls] = useState([])
  const [urls, setUrls] = useState('')
  const [blockButton, setBlockButton] = useState(false)

  function onChangeUrl(event) {
    setUrls(event.target.value);
    setMultipleUrls([{
      title: event.target.value,
      uri: event.target.value
    }])
  }
  function onChangeUrlHandler(event, index) {
    let newArray = multipleUrls;
    newArray[index].title = event.target.value
    newArray[index].uri = event.target.value
    setMultipleUrls([...newArray])
  }

  function onChangeNoticesHandler(event) {
    setNoticeNotes(event.target.value);
  }

  function onChangeTitleHandler(event) {
    setTitle(event.target.value);
  }

  useEffect(() => {

    $('#chooseFile').bind('change', function () {
      var filename = $("#chooseFile").val();
      if (/^\s*$/.test(filename)) {
        $(".file-upload").removeClass('active');
        $("#noFile").text("No file chosen...");
      } else {
        $(".file-upload").addClass('active');
        $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
      }
    });

    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) { $('.dropdown').slideUp(); }
      });
    });

    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

    async function getInitialData() {
      let sortedEducatorsList = await getAllEducators();
      setEducatorsList(sortedEducatorsList.data.data);
      let opt_array = [];
      if (sortedEducatorsList.data.data.length > 0) {
        sortedEducatorsList.data.data.map((edu, index) => {
          const obj = {};
          obj['value'] = edu.uid;
          obj['label'] = edu.name;
          opt_array.push(obj);
        })
        setOptions(opt_array);
      }
    }

    getInitialData()

  }, [addImagePath.length]);

  const educatorsHandler = (event) => {
    setEducatorName(event);
  };

  const addImageHandler = (event) => {
    if (event.target.files && event.target.files[0]) {

      let totalLength = Object.keys(event.target.files).length + addImagePath.length
      if (totalLength <= 7) {
        let imagesArray = addImagePath
        Object.values(event.target.files).map(function (filesData, index) {
          if (filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png') {
            toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
              position: 'top-center'
            });
            return false;
          } else {
            if (filesData.size > 5000000) {
              toast.error('Image size exceeds the limit of 5MB', {
                position: 'top-center'
              });
              return false;
            } else {
              var reader = new FileReader();
              reader.onload = function (e) {
                imagesArray.push({ 'name': filesData.name, 'path': e.target.result })
                setAddImagePath([...imagesArray])
              };
              reader.readAsDataURL(filesData);
            }
          }
        })
      }
      else {
        toast.error("You can't upload more than 7 images", { position: 'top-center' });
      }

    }
  }


  const noticeSubmission = async (event) => {
    event.preventDefault();
    setAjaxLoader(true)
    let notes = noticeNotes;
    let main_title = title;
    let noticeUrls = []
    let _archievedDate = "";
    if (archievedDate) {
      _archievedDate = moment(archievedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
    } else {

    }
    let _publishedDate = moment(publishDate, 'DD-MM-YYYY').format('YYYY-MM-DD')

    if (multipleUrls.length > 0) {
      multipleUrls.map(data => {
        let _objData = { "title": data, "uri": data };
        noticeUrls.push(_objData)
      })
    }
    if (title === undefined || title === '') {
      toast.error('Title is required', {
        position: 'top-center'
      });
      setAjaxLoader(false);
      return false;
    }

    setBlockButton(true)
    if (addImagePath.length > 0) {
      let allImagesArray = []
      addImagePath.map(async (data) => {
        let uploadImageResponse = await uploadImage(data.name, data.path)
        if (uploadImageResponse.status === 201) {
          let postMediaResponse = await postEntityMedia(uploadImageResponse.data.data.filename[0].value, uploadImageResponse.data.data.fid[0].value)
          if (postMediaResponse.status == 201) {
            let imageFid = postMediaResponse.data.data.mid[0].value
            allImagesArray.push({ "target_id": imageFid })
            if (allImagesArray.length === addImagePath.length) {
              let data;
              if (_archievedDate) {
                data = {
                  title: main_title,
                  link: multipleUrls,
                  notes: notes, fid:
                    allImagesArray,
                  date: _publishedDate,
                  archiveDate: _archievedDate
                };
              } else {
                data = {
                  title: main_title,
                  link: multipleUrls,
                  notes: notes, fid:
                    allImagesArray,
                  date: _publishedDate
                };
              }

              let res = await createNoticeBoard(data);
              if (res.status == 201) {
                setAjaxLoader(false);
                history.push('/notices');
              } else {
                setAjaxLoader(false);
                toast.error(res.message, {
                  position: 'top-center'
                });
              }
            }
          }
          else {
            setAjaxLoader(false);
            toast.error(postMediaResponse.message, {
              position: 'top-center'
            });
          }

        }
        else {
          setAjaxLoader(false);
          toast.error(uploadImageResponse.message, {
            position: 'top-center'
          });
        }
      })

    }
    else {
      let data = {
        title: main_title,
        link: multipleUrls,
        notes: notes,
        fid: null,
        date: _publishedDate,
        archiveDate: _archievedDate
      };
      let res = await createNoticeBoard(data);
      if (res.status == 201) {
        setAjaxLoader(false);
        localStorage.removeItem('noticeImageFid');
        history.push('/notices');
      } else {
        setAjaxLoader(false);
        toast.error(res.message, { position: 'top-center' });
      }
    }

  }

  const removeSpecificImage = (index) => {
    let imagesArray = addImagePath
    imagesArray.splice(index, 1)
    setAddImagePath([...imagesArray])
  }

  function calendar(type) {
    if (type === "published") {
      if (showCalendar === true) {
        setShowCalender(false)
      }
      else {
        setShowCalender(true)
      }
    }
    else {
      if (showArchiveCalendar === true) {
        setShowArchiveCalender(false)
      }
      else {
        setShowArchiveCalender(true)
      }
    }

  }

  const calendarHandler = (newDate, type) => {

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = date + "-" + month + "-" + year

    if (type === "published") {
      setPublishDate(fullYear)
      setShowCalender(false)
    }
    else {
      setArchievedDate(fullYear)
      setShowArchiveCalender(false)
    }

  }

  const addUrlField = () => {
    let urls = multipleUrls
    urls.push({ title: "", uri: "" })
    setMultipleUrls([...urls])
  }

  const removeUrlField = (index) => {
    let urls = multipleUrls
    urls.splice(index, 1)
    setMultipleUrls([...urls])
  }

  return (
    <div>
      <ToastContainer />
      <LoaderComponent displayLoader={ajaxLoader} />
      <HeadOfficeMainHeader />
      <div className="main-wrapper">
        <div className="container">
          <div className="user-pages-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="user-types-wrap course-detail-page">
                  <div className="common-header-row common-header-with-large-text">
                    <h4>Create Notice</h4>
                    <div className="block-right">
                      <a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
                    </div>
                  </div>

                  <form onSubmit={noticeSubmission} className="sign-up-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-lg-4 col-md-6">
                            <div className="outlined-textfield-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input maxlength="100" onChange={onChangeTitleHandler} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Title</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar("published")}>
                                <input value={publishDate} onkeypress="return false;" type="text" className={publishDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  required autoComplete="off" onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Publish Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {
                                showCalendar === true ?
                                  <Calendar
                                    minDate={new Date()}
                                    dateFormat="ddd"
                                    onChange={(date) => calendarHandler(date, "published")}
                                  />
                                  :
                                  null
                              }
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar("archived")}>
                                <input value={archievedDate} onkeypress="return false;" type="text" className={archievedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  autoComplete="off" onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Archived Date</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              {
                                showArchiveCalendar === true ?
                                  <Calendar
                                    minDate={new Date()}
                                    dateFormat="ddd"
                                    onChange={(date) => calendarHandler(date, "archive")}
                                  />
                                  :
                                  null
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="outlined-textfield-wrap mt-25">
                          <div className="material-textfield">
                            <textarea onChange={onChangeNoticesHandler} id="message" className="form__field" placeholder="" rows="6"></textarea>
                            <label>Additional Information</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mt-25 upload-doc-block">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input accept="image/jpg,image/jpeg,image/png" multiple onChange={addImageHandler} readOnly type="file" id="add-photo" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{addImageName ? addImageName : "Add Photo"}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">image</span>
                            </label>
                            <small className="short-description">Image can only be of the following types : jpg, jpeg, png with limit upto 5MB</small>
                            <div className='uploaded-block'>
                              <>
                                {
                                  addImagePath.length > 0 ?
                                    addImagePath.map((data, index) => {
                                      return (
                                        <>
                                          <div className='upload-content'>
                                            <div className='media'>
                                              <img height="50" width="50" src={data.path} />
                                            </div>
                                            <button type="button" onClick={(e) => { removeSpecificImage(index) }} ><span className="material-icons close">close</span></button>
                                          </div>
                                        </>
                                      )
                                    })

                                    :
                                    null
                                }
                              </>
                            </div>
                          </div>
                          {multipleUrls.length == 0 &&
                            <div className="col-lg-6 col-md-6">
                              <div className="outlined-textfield-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input
                                    value={urls}
                                    onChange={(e) => onChangeUrl(e)}
                                    type="text"
                                    className="mdc-text-field__input active"
                                    aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Url</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                            </div>

                          }

                        </div>
                      </div>
                    </div>


                    {
                      multipleUrls.length > 0 ?
                        multipleUrls.map((data, index) => {
                          return (
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="outlined-textfield-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined">
                                    <input
                                      value={data.uri}
                                      onChange={(e) => onChangeUrlHandler(e, index)}
                                      type="text"
                                      className={data.uri ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                      aria-labelledby="my-label-id" />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Url</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {
                                index > 0 ?
                                  <button type="button" className="mt-25" onClick={() => removeUrlField(index)}><span className="material-icons close">close</span></button>
                                  :
                                  null
                              }

                            </div>
                          )
                        })
                        :
                        null
                    }

                    <div className="row">
                      <div className='col-12'>
                        <button className="mdc-button mdc-button--raised mt-25" type="button" onClick={addUrlField}>Add More Urls</button>
                        <button type='submit' disabled={blockButton} className="mdc-button mdc-button--raised mt-25 ml-10">
                          <span className="mdc-button__label">Submit</span>
                        </button>
                      </div>
                    </div>
                    {/* <button type='submit' disabled={blockButton} className="mdc-button mdc-button--raised mt-25">
                                    			<span className="mdc-button__label">Submit</span>
                                    		</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateNewNoticePage;
