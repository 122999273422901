import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleEducatorSignupForm, getAuthToken } from '../../../redux/actions/auth';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { $ } from 'react-jquery-plugin';
const reminderData = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]

const Insurance = () => {
    
    const dispatch = useDispatch();
    const [value, setValue] = useState(new Date());
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [dateField, setDateField] = useState('')

    const [insuranceProvider, setInsuranceProvider] = useState("")
    const [policyNumber, setPolicyNumber] = useState("")

    const [selectedDate, setSelectedDate] = useState('');
    const [reminder , setReminder] = useState('');

    useEffect(()=>{
      $(function() { 
        $('.dropdown-toggle').click(function() { 
            $(this).next('.dropdown').slideToggle();
        });

        $(document).click(function(e) { 
        var target = e.target; 
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
          { $('.dropdown').slideUp(); }
        });
    });

        function getInitialData()
        {
            if(window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined)
            { 
                let signupFormDetail = JSON.parse(window.localStorage.getItem('educatorSignupDetail'))
                if(signupFormDetail[4] !== null && signupFormDetail[4] !== undefined)
                {
                    setInsuranceProvider(signupFormDetail[4].insuranceDetailData.insuranceProvider)
                    setPolicyNumber(signupFormDetail[4].insuranceDetailData.policyNumber)
                    setSelectedDate(signupFormDetail[4].insuranceDetailData.expiryDate)
                }

            }
        }
        getInitialData()

        function validateForm()
        {
            var policyNumberValidate = document.getElementById("policyNumber");
            if(policyNumberValidate !== null){
              policyNumberValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Policy Number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var insuranceProviderValidate = document.getElementById("insuranceProvider");
            if(insuranceProviderValidate !== null){
              insuranceProviderValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Insurance Provider can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var expiryDateValidate = document.getElementById("expiryDate");
            if(expiryDateValidate !== null){
              expiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Expiry date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

        }   

        validateForm() 

    },[])

    const changeHandler=(e,name, value) => {
        
        if(name=='policyNumber')
        {
            setPolicyNumber(value);
            e.target.setCustomValidity("")
        }

        if(name=='insuranceProvider')
        {
            setInsuranceProvider(value);
            e.target.setCustomValidity("")
       }
    }   


    const onChangeDate=(newDate)=>
    {   
      let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
      let year = newDate.getFullYear()
      let date = newDate.getDate()
      let fullYear = date+"-"+month+"-"+year 

      setSelectedDate(fullYear)
      setDisplayDatePicker(false)
    }

    const goNext=async(e) => {
        e.preventDefault();
        let insuranceDetailData = { "insuranceDetailData": { insuranceProvider: insuranceProvider,
        policyNumber: policyNumber, expiryDate:selectedDate, reminder : reminder }}
        dispatch(handleEducatorSignupForm(6, insuranceDetailData,4))

    }

    const goBack=()=>
    {
      dispatch(handleEducatorSignupForm(4))  
    }

    const showHideCalender=()=>
    {
        if(displayDatePicker === true)
        {
            setDisplayDatePicker(false)
        }
        else
        {
            setDisplayDatePicker(true)
        } 
    }
    const selectReminder=(selectedValue)=>
    {
    
      
       setReminder(selectedValue)
    }
    

    return (
      <>  
        <ToastContainer />  
        <div className="main-wrapper">
            <div className="container">
                <div className="user-pages-wrap">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <div className="user-types-wrap text-center">
                            <h1 className="common-heaidng48">Public Liability Insurance</h1>
                            <h3 className="small-title">Please fill the required details</h3>
                            <form className="sign-up-form" onSubmit = {(e) => goNext(e)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="200" type="text" name="insuranceProvider" className={insuranceProvider ? "mdc-text-field__input active" : "mdc-text-field__input"} value = {insuranceProvider} 
                                            onChange={(e) => changeHandler(e,'insuranceProvider', e.target.value)} aria-labelledby="my-label-id"  id = "insuranceProvider" required onBlur={(e)=>e.target.setCustomValidity("")}/>
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Insurance Provider*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>   
                                    </div>
                                    <div className="col-md-12">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input type="text" maxlength="20" name="policyNumber" value = {policyNumber} className={policyNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} onChange={(e) => changeHandler(e,'policyNumber', e.target.value)} 
                                            aria-labelledby="my-label-id" id = "policyNumber" required onBlur={(e)=>e.target.setCustomValidity("")}/>
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Policy Number*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>   
                                    </div>

                                    <div className="col-md-12" >
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={showHideCalender}>
                                                <input value={selectedDate} type="text" className={selectedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                onkeypress="return false;" autoComplete="off" id="expiryDate" required onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                              
                                            {
                                                displayDatePicker === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            } 
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                <span className="mdc-notched-outline">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{reminder ? reminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                <ul className="dropdown">
                                                <>
                                                    {
                                                        reminderData.map((item,index)=>{
                                                        return(
                                                            <li onClick={()=>selectReminder(item)} >{item}</li>
                                                        )}
                                                        )
                                                    }
                                                </>
                                                </ul>
                                            </div>  
                                        </div>


                                          {/* <div className="col-lg-4 col-md-4 custom-col">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{reminder ? reminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                {

                                                }
                                                <ul className="dropdown" >
                                                <>
                                                    {
                                                        reminderData.map((item,index)=>

                                                            <li onClick={()=>selectReminder(item)} >{item}</li>
                                                        )
                                                    }
                                               </>
                                                </ul>
                                            </div>  
                                        </div> */}

                                </div>
                                <div className="next-prev-btns">
                                    <button className="mdc-button mdc-button--raised" onClick = {goBack}>
                                      <span className="mdc-button__label">previous</span>
                                    </button>
                                    <button className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">next</span>
                                    </button>
                                </div>
                            </form>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default Insurance;