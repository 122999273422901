import React, { useState, useEffect } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { $ } from 'react-jquery-plugin';
import { useDispatch } from 'react-redux';
import { getLoggedInUserDetail } from '../../../redux/actions/auth';
import { appliedLeaves, getLeaves, updateLeaves, deleteLeaves, getCaBTReminders, getParticularReminderOfAdultCabt } from '../../../functions/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from "react-modern-calendar-datepicker";
import LeaveCalendar from 'react-calendar';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './styles.css';
import { Modal } from 'react-responsive-modal';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Moment from 'react-moment';
import { Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { confirmAlert } from 'react-confirm-alert';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'


const CaBTDashboard = () => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [calendarDates, setCalendarDates] = useState([]);
  const [remindersArray, setRemindersArray] = useState([]);
  const [calendarReminderNotices, setCalendarReminderNotices] = useState([]);
  const [leaveModal, setLeaveModal] = useState(false)
  const [selectedLeaveVal, setSelectedLeaveVal] = useState()
  const [oneDayLeaveCal, setOneDayLeaveCal] = useState(false);
  const [multipleDaysStartCal, setMultipleDaysStartCal] = useState(false)
  const [multipleDaysEndCal, setMultipleDaysEndCal] = useState(false)
  const [oneDayLeaveValue, setOnedayLeaveValue] = useState()
  const [leavesId, setLeavesId] = useState();
  const [leaveStartDate, setLeaveStartDate] = useState();
  const [leaveEndDate, setLeaveEndDate] = useState();
  const [allLeaves, setAllLeaves] = useState([]);
  const [showLoader, setShowLoader] = useState(true)
  const [timeStampEnd, setTimeStampEnd] = useState()
  const [timeStampStart, setTimeStampStart] = useState()
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");

  useEffect(() => {
    window.localStorage.removeItem('folderParentId')
    getAllCalenderData()
  }, [])

  const getDayMonthDay = (date) => {
    var dateObj = new Date(date)
    var month = dateObj.getUTCMonth() + 1
    var day = dateObj.getUTCDate()
    var year = dateObj.getUTCFullYear()

    var newDate = {};
    newDate['year'] = year;
    newDate['month'] = month;
    newDate['day'] = day;

    return newDate;
  }

  //converting weeks into days
  const weeksIntoDays = (weeks) => {
    let days = parseInt(weeks) * 7;
    return days;
  }

  const month = (date) => {
    const m = date.getMonth() + 1;
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  };

  const custom_day = (date) => {
    const m = date.getDate();
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  }
  // Leave Process
  const applyForLeave = (data, isEdit) => {
    if (isEdit) {
      setDefaultStartDate(new Date(data.start_date));
      setDefaultEndDate(new Date(data.end_date));
    } else {
      setDefaultStartDate("");
      setDefaultEndDate("");
    }
    data?.end_date === data?.start_date ? setSelectedLeaveVal("onedayleave") : setSelectedLeaveVal("multipledaysleave")
    if (data.start_date === data.end_date) {
      setOnedayLeaveValue(data.start_date)
    } else {
      setOnedayLeaveValue('')
    }
    if (data.start_date !== data.end_date) {
      setLeaveStartDate(data.start_date)
      setLeaveEndDate(data.end_date)
      setLeavesId(data.id)
    } else {
      setLeaveStartDate('')
      setLeaveEndDate('')
      setLeavesId(data.id)
    }
    setLeaveModal(true)
    setOneDayLeaveCal(false)
    setMultipleDaysStartCal(false)
    setMultipleDaysEndCal(false)
  }

  //---------------------- UPDATE LEAVE-----------------------
  const updateLeave = () => {

  }

  const leaveHandler = (event) => {
    setSelectedLeaveVal(event.target.value)

  }

  const leaveCalendarClickable = (value) => {
    if (value === 'onedayleave') {
      setOneDayLeaveCal(true);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(false);
    } else if (value === 'startdate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(true);
      setMultipleDaysEndCal(false);
    } else if (value === 'enddate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(true);
    }
  }

  const oneDayLeavesCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setOnedayLeaveValue(fullYear)
    setOneDayLeaveCal(false)
  }

  const multipleLeavesStartCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampStart(d)

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveStartDate(fullYear)
    setMultipleDaysStartCal(false)

  }

  const multipleLeavesEndCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampEnd(d)

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveEndDate(fullYear)
    setMultipleDaysEndCal(false)
  }


  async function deleteLeavesData(id) {
    let response = await deleteLeaves(id);
    if (response.status === 200) {

      getAllCalenderData()
      toast.success('Leaves has been deleted successfully!', {
        position: 'top-center'
      });
      getLeavesData()
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteLeaves(id) {

    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLeavesData(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  async function oneDayLeaveApplicationForm(e) {
    e.preventDefault();
    setShowLoader(true)
    var new_date = moment(oneDayLeaveValue, "YYYY-MM-DD");
    var defaultEndDate = new_date.add(0, 'days').format('YYYY-MM-DD');
    let data = { startDate: oneDayLeaveValue, endDate: defaultEndDate, userId: window.localStorage.getItem('uid') }
    if (oneDayLeaveValue !== '') {

      if (leavesId !== undefined) {
        let response = await updateLeaves(data, leavesId);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Updated Successfully !!', { position: 'top-center' });
          getAllCalenderData()
          setLeaveModal(false);
          getLeavesData()
        }
      }
      else {
        let response = await appliedLeaves(data);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Submitted !!', { position: 'top-center' });
          setLeaveModal(false);
          getLeavesData()
        } else {
          setShowLoader(false)
          toast.error(response.message, { position: 'top-center' });
        }
      }
    } else {
      setShowLoader(false)
      toast.error('Date is required', { position: 'top-center' });
    }

  }

  async function multipleDaysLeaveApplicationForm(e) {
    e.preventDefault();
    setShowLoader(true);

    let data = { startDate: leaveStartDate, endDate: leaveEndDate, userId: window.localStorage.getItem('uid') }
    if (leaveStartDate !== '' && leaveEndDate !== '') {
      if (new Date(timeStampStart) < new Date(timeStampEnd)) {
        if (leavesId !== undefined) {
          let response = await updateLeaves(data, leavesId);
          if (response.status === 200) {
            toast.success('Updated Successfully !!', { position: 'top-center' });
            setShowLoader(false)
            setLeaveModal(false);
            getLeavesData()
          }
        }
        else {
          let response = await appliedLeaves(data);
          if (response.status === 200) {
            setShowLoader(false)
            toast.success('Submitted !!', { position: 'top-center' });
            setLeaveModal(false)
            getLeavesData()
          } else {
            setShowLoader(false)
            toast.error(response.message, { position: 'top-center' });
          }
        }
      }
      else {
        setShowLoader(false)
        toast.error('Start Date must be lesser than End Date', { position: 'top-center' });
      }
    } else {
      setShowLoader(false)
      toast.error('Start Date and End Date is required', { position: 'top-center' });
    }
  }

  async function getLeavesData() {
    let res = await getLeaves(window.localStorage.getItem('uid'));
    if (res.status === 200) {
      if (res.data.data.length > 0) {
        setAllLeaves(res.data.data);
      }
    }
  }

  useEffect(() => {
    setSelectedLeaveVal('onedayleave')
    getLeavesData()

    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

    async function getAllInitialData() {
      if (window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined) {
        window.localStorage.removeItem("educatorSignupDetail");  //remove signup page detail
      }
      if (window.localStorage.getItem('headOfficeSignupDetail') !== null && window.localStorage.getItem('headOfficeSignupDetail') !== undefined) {
        window.localStorage.removeItem("headOfficeSignupDetail");  //remove signup page detail
      }
      let uid = window.localStorage.getItem('uid');
      let authToken = window.localStorage.getItem('auth_token');
      if (authToken !== null && uid !== '') {
        let responseData = await dispatch(getLoggedInUserDetail(uid, authToken))
        if (responseData.status !== 200) {
          toast.error(responseData.message, { position: 'top-center' });
        }
      }
    }
    getAllInitialData()
  }, [])

  function getFinalDate(initialDate, reminderDays) {
    const d = new Date(initialDate);
    d.setDate(d.getDate() - reminderDays);
    return new Date(d);
  }
  const getAllCalenderData = async () => {
    let allDatesArray = []
    let uid = window.localStorage.getItem('uid');
    let _remindersArray = [];
    let reminders = await getCaBTReminders(uid);
    let allReminderDates = [];
    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          const expiryDate = reminderData[0]?.[`field_${fieldName}`];
          const courseReminder = reminderData[0]?.[`field_${courseName.toLowerCase()}_course_reminder`];

          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [`${courseName.toLowerCase()}_course_reminder_date`]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: `${courseName === 'First_Aid' ? 'First Aid' : 'CPR'} Course`,
              [`${courseName.toLowerCase()}_course_reminder`]: `expires in ${courseReminder}`,
              date: formattedDate
            };

            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
            if (expiryDate !== '') {
              const originalExpiryDate = new Date(expiryDate);
              const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
              const originalCustomObj = {
                [`${courseName.toLowerCase()}_course_reminder_date`]: `${originalExpiryDate.getFullYear()}-${month(originalExpiryDate)}-${custom_day(originalExpiryDate)}`,
                course_name: `${courseName === 'First_Aid' ? 'First Aid' : 'CPR'} course`,
                [`${courseName.toLowerCase()}_course_reminder`]: 'expires today',
                date: expiryDate
              };
              const originalObj = { className: 'greenDay', ...originalExpiryDateObj };
              allDatesArray.push(originalObj);
              _remindersArray.push(originalObj);
              allReminderDates.push(originalCustomObj);
            }
          }
        }

        function pushToArrays(obj, customObj) {
          allDatesArray.push(obj);
          _remindersArray.push(obj);
          allReminderDates.push(customObj);
        }

        const courses = ['First_Aid', 'CPR'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));

        function processEducatorRemainderExpiry(reminderData) {
          const expiryDate = reminderData[0]?.[`field_blue_card_expiry_date`];
          const courseReminder = reminderData[0]?.[`field_educator_reminder`];
          if (expiryDate !== '' && courseReminder !== '') {
            const d = new Date(expiryDate);
            let reminderDays = 0;

            switch (courseReminder) {
              case '8 Weeks':
              case '6 Weeks':
              case '4 Weeks':
              case '2 Weeks':
              case '1 Week':
                reminderDays = weeksIntoDays(courseReminder);
                d.setDate(d.getDate() - reminderDays);
                break;
              case '1 Day':
                reminderDays = d.setDate(d.getDate() - 1);
                break;
              default:
                break;
            }

            const finalDate = new Date(d);
            const formattedDate = moment(finalDate).format('DD-MM-YYYY');
            const courseCompletionDate = getDayMonthDay(finalDate);
            const customObj = {
              [`field_blue_card_reminder_date`]: `${finalDate.getFullYear()}-${month(finalDate)}-${custom_day(finalDate)}`,
              course_name: `Cabt Blue Card`,
              [`reminder`]: reminderDays > 1 ? `expires in ${courseReminder}` : 'expires today',
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseCompletionDate };
            pushToArrays(obj, customObj);
          }
        }
        processEducatorRemainderExpiry(reminders.data.data)
      

        function processExpiryWithReminder(reminderDate, reminderDays, courseName, courseType) {
          if (reminderDate !== '' && reminderDays !== '') {
            const d = new Date(reminderDate);
            const finalDate = getFinalDate(d, reminderDate);
            const formattedDate = moment(reminderDate).format('YYYY-MM-DD')
            const courseExpiryDateObj = getDayMonthDay(formattedDate);

            const customObj = {
              [`${courseType}_expiry_date`]: `${formattedDate}`,
              course_name: courseName,
              reminder: reminderDays > 1 ? `expires in ${reminderDate}` : 'expires today',
              date: formattedDate
            };
            const obj = { className: 'greenDay', ...courseExpiryDateObj };
            pushToArrays(obj, customObj);
          }
        }

        const blueCardExpiryDate = reminders.data.data[0]?.field_blue_card_expiry_date;
        const educatorReminder = reminders.data.data[0]?.field_educator_reminder;
        processExpiryWithReminder(blueCardExpiryDate, educatorReminder, 'CaBT Blue Card', 'field_blue_card');
      }
    }
    setShowLoader(false)
    setCalendarDates(allDatesArray);
    setRemindersArray(allReminderDates);
  }

  function _onDateFormat(date) {
    if (!date) {
      return ""
    }

    let newDate = date.split('-')
    let day = newDate[2].split(' 00:00:00')
    return `${day[0]}-${newDate[1]}-${newDate[0]}`
  }


  async function calendarHandler(date) {
    let d = ''
    let m = ''
    if (date.day < 10) {
      d = "0".concat(date.day)
    } else {
      d = date.day
    }

    if (date.month < 10) {
      m = "0".concat(date.month)
    } else {
      m = date.month
    }

    let Date = date.year + "-" + m + "-" + d
    setShowLoader(true)
    setOpen(true)

    var month = date.month < 10 ? '0' + date.month : date.month;
    var day = date.day < 10 ? '0' + date.day : date.day;

    let fullYear = date.year + "-" + month + "-" + day
    let calendarRemindersArray = [];
    if (remindersArray.length > 0) {
      setShowLoader(false)
      const reminderProperties = [
        { key: 'first_aid_course_reminder_date', timingKey: 'first_aid_course_reminder' },
        { key: 'cpr_course_reminder_date', timingKey: 'cpr_course_reminder' },
        { key: 'field_business_expiry_date', timingKey: 'reminder' },
        { key: 'field_blue_card_reminder_date', timingKey: 'reminder' },
        { key: 'field_blue_card_expiry_date', timingKey: 'reminder' },
      ];
      for (let reminder of remindersArray) {
        for (let prop of reminderProperties) {
          if (fullYear === reminder[prop.key]) {
            let obj = {
              course_name: reminder.course_name,
              weekly_timings: reminder[prop.timingKey],
              date: reminder.date
            };
            calendarRemindersArray.push(obj);
          }
        }
      }

    }
    else {
      setShowLoader(false)
    }
    setCalendarReminderNotices(calendarRemindersArray);
  }

  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      <EducatorsMainHeader />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap educator-dashboard-page">

            <div className="col-md-4">
              <button onClick={applyForLeave} className="mdc-button mdc-button--raised">Advise Leave Dates</button>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <TableContainer className="educators-leaves-listing">
                    <h1 className='ceneterd-text' >Leave Dates</h1>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          allLeaves.length > 0 ? allLeaves.map((leave, index) => (
                            <TableRow key={index}>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.start_date}</Moment></TableCell>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.end_date}</Moment></TableCell>
                              <TableCell><span onClick={() => applyForLeave(leave, true)} style={{ cursor: "pointer" }} className="material-icons">edit</span></TableCell>
                              <TableCell><span onClick={() => confirmDeleteLeaves(leave.id)} style={{ cursor: "pointer" }} className="material-icons">delete</span></TableCell>
                            </TableRow>
                          )) : null
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-block-right">
                  <div className="calender-block full-width-calender">
                    <Calendar customDaysClassName={calendarDates} onChange={(date) => calendarHandler(date)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => [setOpen(false), setCalendarReminderNotices([])]} center>
        <div className="main-wrapper">
          <div className="container">
            <div className="dashboard-wrap no-tabs">
              <div className="calender-popup calender-popup-with-tabs">
                <div className='c-box-parent'>
                  <h2 className='reminder-dashboard-popup'>Alerts</h2>
                  <div className='c-tab tab1 mb-3'>
                    <p className='reminder-popup-subheading'>Reminders</p>
                    <div className='c-content'>
                      <div className='course-detail-last pb-25'>
                        <>
                          {
                            <div className="reminders-tab-info">
                              <ul style={{ width: '100%' }}>
                                {
                                  calendarReminderNotices.length > 0 ? calendarReminderNotices.map((reminder, index) => {
                                    return (
                                      <li className="list-content" key={index}>
                                        <h2>{reminder.course_name} {reminder.weekly_timings}</h2>
                                      </li>
                                    )
                                  }) : <h2 style={{ fontWeight: '900' }}>No Alerts</h2>
                                }
                              </ul>
                            </div>
                          }
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={leaveModal} onClose={() => setLeaveModal(false)} center>
        <FormControlLabel value="onedayleave" label="One Day Leave" control={<Radio value="onedayleave" checked={selectedLeaveVal === "onedayleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'A' }} />} />
        <FormControlLabel value="multipledaysleave" label="Multiple Days Leave" control={<Radio value="multipledaysleave" checked={selectedLeaveVal === "multipledaysleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'B' }} />} />
        <>
          {selectedLeaveVal === 'onedayleave' ?
            <form onSubmit={oneDayLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('onedayleave')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={_onDateFormat(oneDayLeaveValue)} type="text" className={oneDayLeaveValue !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">{selectedLeaveVal === 'multipledaysleave' ? 'Start Date' : 'Date'}</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        oneDayLeaveCal === true ? <LeaveCalendar onChange={oneDayLeavesCalendarHandler} value={defaultStartDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button className="mdc-button mdc-button--raised mt-40">
                <span className="mdc-button__label" onClick={() => updateLeave(leavesId)}>Submit</span>
              </button>
            </form>
            :
            <form onSubmit={multipleDaysLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('startdate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveStartDate ? _onDateFormat(leaveStartDate) : leaveStartDate} type="text" className={leaveStartDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">Start Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysStartCal === true ? <LeaveCalendar onChange={multipleLeavesStartCalendarHandler} value={defaultStartDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div onClick={() => leaveCalendarClickable('enddate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveEndDate ? _onDateFormat(leaveEndDate) : leaveEndDate} type="text" className={leaveEndDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">End Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysEndCal === true ? <LeaveCalendar onChange={multipleLeavesEndCalendarHandler} value={defaultEndDate} minDate={new Date()} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button className="mdc-button mdc-button--raised mt-40">
                <span className="mdc-button__label" onClick={() => updateLeave(leavesId)}>Submit</span>
              </button>
            </form>
          }
        </>
      </Modal>
    </>
  )
}

export default CaBTDashboard;