import React, { useEffect, useState } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { useHistory } from 'react-router-dom';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { getSpecificTaxonomy, uploadImage, uploadVideo, addMeetingNotes, updateMeetingNotes, getAllMeetingNotes, editNewVenue, updateEducatorNotesVisit } from '../../../functions/api';
import { useSelector } from "react-redux"
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { convertTimeStampToTime, getSpecificUser } from '../../../functions/api';
import SignaturePad from 'react-signature-canvas'
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../../config'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Loader from "react-loader-spinner";
import moment from 'moment';

import './styles.css'
import styles from './styles.module.css'

import blankImage from '../../../images/no_sign.png';
import logo from '../../../images/default_logo.jpg';
let signatureImage = '';
let educatorSignature = ''

const EducatorVisitDetail = (props) => {

  const history = useHistory();
  const store = useSelector(state => state.auth)
  const [userRole, setUserRole] = useState(1)
  const [title, setTitle] = useState('')
  const [date, setDate] = useState("N/A")
  const [time, setTime] = useState("N/A")
  const [meetingType, setMeetingType] = useState("N/A")
  const [meetingMethod, setMeetingMethod] = useState("N/A")
  const [meetingTypeUrl, setMeetingTypeUrl] = useState("")
  const [videoUrl, setVideoUrl] = useState([])
  const [venue, setVenue] = useState("")
  const [videoId, setVideoId] = useState([])
  const [vimeoId, setVimeoId] = useState([])
  const [agenda, setAgenda] = useState("")
  const [file, setFile] = useState("")
  const [createdBy, setCreatedBy] = useState("")
  const [assignedTo, setAssignedTo] = useState("")

  const [sigPad, setSigPad] = useState()
  const [sigPadEducator, setSigPadEducator] = useState()
  const [meetingNotes, setMeetingNotes] = useState('')
  const [meetingImages, setMeetingImages] = useState([])
  const [meetingImagesLength, setMeetingImagesLength] = useState(0)
  const [meetingVideoes, setMeetingVideoes] = useState([])
  const [meetingVideoesLength, setMeetingVideoesLength] = useState(0)
  const [allMeetingNotes, setAllMeetingNotes] = useState([])
  const [previousMeetingNotesArray, setPreviousMeetingNotesArray] = useState([])
  const [displayLoader, setDisplayLoader] = useState(true)
  const [uploadedImage, setUplaodedImage] = useState([]);
  const [visitsDocuments, setVisitDocuments] = useState([])
  const [open, setOpen] = useState(false);
  const [visitLocation, setVisitLocation] = useState("")

  const [notes, setNotes] = useState("")
  const [eduNotes, setEduNotes] = useState('')
  const [eduSignature, setEduSignature] = useState("")
  const [cordSignature, setcordSignature] = useState("")

  let userId = window.localStorage.getItem('uid')

  const regex = /(<([^>]+)>)/ig;

  async function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  }

  async function getVimeoId(url) {
    var url = url;
    var id = url.substring(url.lastIndexOf('/') + 1);
    return id;
  }

  async function getInitialValues() {
    setUserRole(store.userRole)
    let visit_id = props.match.params.id
    if (visit_id !== '') {
      try {
        let responseData = await NodeDetails(visit_id)
        if (responseData && responseData.status === 200) {
          if (responseData.data.data.title !== undefined && responseData.data.data.title !== '') {
            let taskTitle = responseData.data.data.title[0] ? responseData.data.data.title[0].value : ""
            setTitle(taskTitle)
          }

          if (responseData.data.data.field_date !== undefined && responseData.data.data.field_date !== '') {
            let date = responseData.data.data.field_date[0] ? responseData.data.data.field_date[0].value : ""
            setDate(date)
          }

          if (responseData.data.data.field_meeting_notes !== undefined && responseData.data.data.field_meeting_notes.length > 0) {
            let notesArray = []
            responseData.data.data.field_meeting_notes.map(async (items) => {
              let singleObj = { "target_id": items.target_id, "target_revision_id": items.target_revision_id }
              notesArray.push(singleObj)
              setPreviousMeetingNotesArray(notesArray)
            })
          }

          if (responseData.data.data.field_venue !== undefined && responseData.data.data.field_venue.length > 0) {
            setVisitLocation(responseData.data.data.field_venue[0].value)
          }

          if (responseData.data.data.field_file !== undefined && responseData.data.data.field_file.length > 0) {
            setVisitDocuments(responseData.data.data.field_file)
          }

          if (responseData.data.data.field_visit_method !== undefined && responseData.data.data.field_visit_method !== '') {
            let visits = responseData.data.data.field_visit_method[0] ? responseData.data.data.field_visit_method[0].target_id : ""
            if (visits !== "") {
              if (visits === 280) {
                setMeetingMethod("Email")
              } else if (visits === 281) {
                setMeetingMethod("Home")
              } else if (visits === 282) {
                setMeetingMethod("Link")
              } else if (visits === 279) {
                setMeetingMethod("Phone")
              } else if (visits === 4984) {
                setMeetingMethod("Park")
              } else if (visits === 4983) {
                setMeetingMethod("WFDC Office")
              } else if (visits === 283) {
                setMeetingMethod("Other Location")
              }
            }
          }

          if (responseData.data.data.field_time !== undefined && responseData.data.data.field_time !== '') {
            let time = responseData.data.data.field_time[0] ? responseData.data.data.field_time[0].value : ""

            setTime(convertTimeStampToTime(time))
          }

          // console.log("responseData.data.data.field_headoffice", responseData.data.data.field_headoffice);
          if (responseData.data.data.field_headoffice.length > 0) {
            if (responseData.data.data.field_headoffice[0]?.target_id === 0) {
              setCreatedBy('Assign Coordinator')
            } else {
              let target_id = responseData.data.data.field_headoffice[0] ? responseData.data.data.field_headoffice[0].target_id : ""
              let res = await getSpecificUser(target_id, window.localStorage.getItem('auth_token'));

              if (res.data.data.field_first_name.length) {
                let cord_first_name = res.data.data.field_first_name.length !== "" ? res.data.data.field_first_name[0].value : ""
                let cord_last_name = res.data.data.field_last_name.length ? res.data.data.field_last_name[0].value : ''
                let cordName = cord_first_name + " " + cord_last_name
                setCreatedBy(cordName)
              }
              // else{
              //     setCreatedBy('First Name & Last Name Missing')
              // }
            }
          } else {
            setCreatedBy('Assign Coordinator')
          }

          if (responseData.data.data.field_visitor.length > 0) {
            if (responseData.data.data.field_visitor[0]?.target_id === 0) {
              setAssignedTo('Assign Educator')
            } else {
              let target_id = responseData.data.data.field_visitor[0] ? responseData.data.data.field_visitor[0].target_id : ""
              let res = await getSpecificUser(target_id, window.localStorage.getItem('auth_token'));

              if (res.data.data.field_first_name.length) {
                let edu_first_name = res.data.data.field_first_name.length !== "" ? res.data.data.field_first_name[0].value : ""
                let edu_last_name = res.data.data.field_last_name.length ? res.data.data.field_last_name[0].value : ''
                let edudName = edu_first_name + " " + edu_last_name
                setAssignedTo(edudName)
              }
              // else{
              //     setCreatedBy('First Name & Last Name Missing')
              // }
            }
          } else {
            setAssignedTo('Assign Coordinator')
          }

          if (responseData.data.data.field_agenda !== "" && responseData.data.data.field_agenda !== undefined) {
            let msAgenda = responseData.data.data.field_agenda[0] ? responseData.data.data.field_agenda[0].value : ""
            setAgenda(msAgenda)
          }
          if (responseData.data.data.field_notes !== "" && responseData.data.data.field_notes !== undefined) {
            let msNotes = responseData.data.data.field_notes[0] ? responseData.data.data.field_notes[0].value : ""
            setNotes(msNotes)
          }
          if (responseData.data.data.field_user_notes !== undefined && responseData.data.data.field_user_notes !== '') {
            let notes = responseData.data.data.field_user_notes[0] && responseData.data.data.field_user_notes[0].value ? responseData.data.data.field_user_notes[0].value.replace(regex, '') : ""
            setEduNotes(notes)
          }
          if (responseData.data.data.field_coordinator_signature !== undefined && responseData.data.data.field_coordinator_signature !== '') {
            let signatureCord = responseData.data.data.field_coordinator_signature[0] && responseData.data.data.field_coordinator_signature[0].url ? responseData.data.data.field_coordinator_signature[0].url : ""
            setcordSignature(signatureCord)
          }
          if (responseData.data.data.field_educator_signature !== undefined && responseData.data.data.field_educator_signature !== '') {
            let signatureEdu = responseData.data.data.field_educator_signature[0] && responseData.data.data.field_educator_signature[0].url ? responseData.data.data.field_educator_signature[0].url : ""
            setEduSignature(signatureEdu)
          }

          if (responseData.data.data.field_image !== "" && responseData.data.data.field_image !== undefined) {
            if (responseData.data.data.field_image.length > 0) {
              let imagesArrayRef = []
              responseData.data.data.field_image.map(async (imageData) => {
                await imagesArrayRef.push(imageData.url)
                await setUplaodedImage(imagesArrayRef)
              })
            }
          }

          if (responseData.data.data.field_embed_video !== undefined && responseData.data.data.field_embed_video.length > 0) {
            let _videoId = []
            let _vimeoId = []
            let _videoUrl = []
            responseData.data.data.field_embed_video.map(async (data) => {
              let regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
              if (data.value) {
                let match = data.value.match(regExp);
                if (match && match[1].length == 11) {
                  _videoId.push(match[1])
                  setVideoId(_videoId)
                } else {
                  let vimeoId = await getVimeoId(match)
                  _vimeoId.push(vimeoId)
                  setVimeoId(_vimeoId)
                }
              } else {
                let videoUrl = logo
                _videoUrl.push(videoUrl)
                setVideoUrl(_videoUrl)
              }
            })


          }
          setDisplayLoader(false)
        }
        else {
          setDisplayLoader(false)
        }
      } catch (error) {
        setDisplayLoader(false)
      }
    }

  }

  const getNotesData = async () => {
    let visit_id = props.match.params.id
    if (visit_id !== "" && visit_id !== undefined) {
      const meetingNotesRes = await getAllMeetingNotes(visit_id)
      if (meetingNotesRes.status === 200) {
        if (meetingNotesRes.data.data.length > 0) {
          setAllMeetingNotes(meetingNotesRes.data.data)
        }
      }
      else {
        toast.error(meetingNotesRes.message, { position: 'top-center' });
      }
    }
    else {
      toast.error("M&S id is invalid", { position: 'top-center' });
    }

  }

  useEffect(() => {

    getInitialValues()

    getNotesData()

  }, [props, meetingImages, meetingVideoes.length])

  function navigateToEdit() {
    props.history.push(`/edit-m&s/${props.match.params.id}`)
  }

  const uploadImages = (event) => {
    if (event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5) {
      if (event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png') {
        toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
          position: 'top-center'
        });
        return false;
      } else {

        if (event.target.files[0].size > 2000000) {
          toast.error('Image size exceeds the limit of 2MB', {
            position: 'top-center'
          });
          return false;
        } else {

          let imagesArray = []
          Object.values(event.target.files).map(function (key, index) {
            let imageObject = {
              name: '',
              path: ''
            }
            if (key.name !== undefined) {
              imageObject['name'] = key.name
            }

            var reader = new FileReader();
            reader.onload = function (events) {
              imageObject['path'] = events.target.result
            };

            imagesArray.push(imageObject)
            setMeetingImages(imagesArray)
            setMeetingImagesLength(imagesArray.length)
            reader.readAsDataURL(key);
          });
        }
      }
    }
    else {
      toast.error("You can't upload more than 5 images", { position: 'top-center' });
    }
  }

  const uploadVideos = (event) => {
    if (event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5) {

      if (event.target.files[0].type !== 'video/mp4') {
        toast.error('Invalid Video Type. Video can only be of the following types : mp4', {
          position: 'top-center'
        });
        return false;
      } else {
        if (event.target.files[0].size > 2000000) {
          toast.error('Video size exceeds the limit of 2MB', {
            position: 'top-center'
          });
          return false;
        } else {

          let videoArray = []
          Object.values(event.target.files).map(function (key, index) {
            let videoObject = {
              name: '',
              path: ''
            }
            if (key.name !== undefined) {
              videoObject['name'] = key.name
            }

            var reader = new FileReader();
            reader.onload = function (events) {
              videoObject['path'] = events.target.result
            };

            videoArray.push(videoObject)
            setMeetingVideoes(videoArray)
            setMeetingVideoesLength(videoArray.length)
            reader.readAsDataURL(key);
          });
        }
      }
    }
    else {
      toast.error("You can't upload more than 5 videos", { position: 'top-center' });
    }
  }

  const validateFormFields = () => {
    let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')


    if (meetingNotes !== "") {
      if (digitalSignaturePath !== "" && digitalSignaturePath.length > 130) {
        return true
      }
      else {
        setDisplayLoader(false)
        toast.error("Please enter digital Signature", { position: 'top-center' });
        return false
      }
    }
    else {
      setDisplayLoader(false)
      toast.error("Please enter the notes", { position: 'top-center' });
      return false
    }

  }

  const submitMeetingNotes = async (e) => {
    e.preventDefault()
    setDisplayLoader(true)
    let res = validateFormFields()

    if (res === true) {
      if (meetingImages.length > 0 && meetingVideoes.length > 0) {
        let imageId = []
        let videoId = []
        meetingImages.map(async (data) => {
          const imageUploadResponse = await uploadImage(data.name, data.path)

          if (imageUploadResponse.status === 201) {
            if (imageUploadResponse.data.data !== undefined && imageUploadResponse.data.data.fid.length > 0) {
              let imageFid = {
                "target_id": imageUploadResponse.data.data.fid[0].value,
                "description": ""
              }

              await imageId.push(imageFid)
              if (imageId.length === meetingImages.length) {
                meetingVideoes.map(async (videoData) => {
                  const videoUploadResponse = await uploadVideo(videoData.name, videoData.path)

                  if (videoUploadResponse.status === 201) {
                    if (videoUploadResponse.data.data !== undefined && videoUploadResponse.data.data.fid.length > 0) {
                      let videoFid = {
                        "target_id": videoUploadResponse.data.data.fid[0].value,
                        "description": ""
                      }
                      videoId.push(videoFid)

                      if (videoId.length === meetingVideoes.length) {
                        let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
                        const signatureUploadResponse = await uploadImage("signature.png", digitalSignaturePath)

                        if (signatureUploadResponse.status === 201) {
                          let signFid = [{
                            "target_id": signatureUploadResponse.data.data.fid[0].value,
                            "description": ""
                          }]
                          uploadMeetingNotesDetail(imageId, videoId, signFid)
                        }
                        else {
                          setDisplayLoader(false)
                          toast.error("signature upload issue" + signatureUploadResponse.message, { position: 'top-center' });
                        }
                      }
                    }
                    else {
                      setDisplayLoader(false)
                      videoId.push([])
                    }
                  }
                  else {
                    setDisplayLoader(false)
                    toast.error("videos upload issue" + videoUploadResponse.message, { position: 'top-center' });
                  }
                })
              }
            }
            else {
              setDisplayLoader(false)
              imageId.push([])
            }
          }
          else {
            setDisplayLoader(false)
            toast.error("images upload issue" + imageUploadResponse.message, { position: 'top-center' });
          }
        })
      }

      else if (meetingImages.length > 0) {
        let imageId = []
        meetingImages.map(async (data) => {
          const imageUploadResponse = await uploadImage(data.name, data.path)

          if (imageUploadResponse.status === 201) {
            if (imageUploadResponse.data.data !== undefined && imageUploadResponse.data.data.fid.length > 0) {
              let imageFid = {
                "target_id": imageUploadResponse.data.data.fid[0].value,
                "description": ""
              }
              imageId.push(imageFid)
              if (imageId.length === meetingImages.length) {
                let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
                const signatureUploadResponse = await uploadImage("signature.png", digitalSignaturePath)

                if (signatureUploadResponse.status === 201) {
                  let signFid = [{
                    "target_id": signatureUploadResponse.data.data.fid[0].value,
                    "description": ""
                  }]
                  uploadMeetingNotesDetail(imageId, [], signFid)
                }
                else {
                  setDisplayLoader(false)
                  toast.error("signature upload issue" + signatureUploadResponse.message, { position: 'top-center' });
                }
              }
            }
            else {
              setDisplayLoader(false)
              imageId.push([])
            }
          }
          else {
            setDisplayLoader(false)
            toast.error("images upload issue" + imageUploadResponse.message, { position: 'top-center' });
          }
        })
      }

      else if (meetingVideoes.length > 0) {
        let videoId = []
        meetingVideoes.map(async (videoData) => {
          const videoUploadResponse = await uploadVideo(videoData.name, videoData.path)

          if (videoUploadResponse.status === 201) {
            if (videoUploadResponse.data.data !== undefined && videoUploadResponse.data.data.fid.length > 0) {
              let videoFid = {
                "target_id": videoUploadResponse.data.data.fid[0].value,
                "description": ""
              }
              videoId.push(videoFid)

              if (videoId.length === meetingVideoes.length) {
                let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
                const signatureUploadResponse = await uploadImage("signature.png", digitalSignaturePath)

                if (signatureUploadResponse.status === 201) {
                  let signFid = [{
                    "target_id": signatureUploadResponse.data.data.fid[0].value,
                    "description": ""
                  }]
                  uploadMeetingNotesDetail([], videoId, signFid)
                }
                else {
                  setDisplayLoader(false)
                  toast.error("signature upload issue" + signatureUploadResponse.message, { position: 'top-center' });
                }
              }
            }
            else {
              setDisplayLoader(false)
              videoId.push([])
            }
          }
          else {
            setDisplayLoader(false)
            toast.error("videos upload issue" + videoUploadResponse.message, { position: 'top-center' });
          }
        })
      }
      else {
        let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
        const signatureUploadResponse = await uploadImage("signature.png", digitalSignaturePath)

        if (signatureUploadResponse.status === 201) {
          let signFid = [{
            "target_id": signatureUploadResponse.data.data.fid[0].value,
            "description": ""
          }]
          uploadMeetingNotesDetail([], [], signFid)
        }
        else {
          setDisplayLoader(false)
          toast.error("signature upload issue" + signatureUploadResponse.message, { position: 'top-center' });
        }
      }
    }


  }


  const uploadMeetingNotesDetail = async (imageIds, videosId, signatureImageId) => {
    let visit_id = props.match.params.id

    let imagesObject = imageIds.length > 0 ? imageIds : []
    let videoObject = videosId.length > 0 ? videosId : []
    let dataObj
    if (userRole === 2) {
      dataObj = {
        "type": [{
          "target_id": "meeting_notes",
          "target_type": "paragraphs_type"
        }],
        "field_meeting_notes": [{ "value": meetingNotes }],
        "field_meeting_signature": signatureImageId,
        "field_user": [{ "target_id": userId }],
        "field_meeting_video": videoObject,
        "field_images": imagesObject
      }
    } else {
      dataObj = {
        "type": [{
          "target_id": "meeting_notes",
          "target_type": "paragraphs_type"
        }],
        "field_meeting_notes": [{ "value": meetingNotes }],
        "field_educator_signature": signatureImageId,
        "field_user": [{ "target_id": userId }],
        "field_meeting_video": videoObject,
        "field_images": imagesObject
      }
    }

    let updateVisitMeetingResponse = await addMeetingNotes(dataObj)

    if (updateVisitMeetingResponse.status === 201) {
      if (updateVisitMeetingResponse.data.data !== undefined) {
        let selectedObject = previousMeetingNotesArray.length > 0 ? previousMeetingNotesArray.concat({ "target_id": updateVisitMeetingResponse.data.data.id[0].value, "target_revision_id": updateVisitMeetingResponse.data.data.revision_id[0].value }) : [{ "target_id": updateVisitMeetingResponse.data.data.id[0].value, "target_revision_id": updateVisitMeetingResponse.data.data.revision_id[0].value }]
        let object = {
          "type": [{ "target_id": "visits" }],
          "field_meeting_notes": selectedObject,
        }
        let meetingRes = await updateMeetingNotes(visit_id, object)
        if (meetingRes.status === 200) {
          setDisplayLoader(false)
          toast.success("Meeting Notes Added Successfully", { position: 'top-center' });
          setSigPad('')
          setMeetingNotes('')
          setMeetingImages([])
          setMeetingVideoes([])
          getInitialValues()
          getNotesData()
          setOpen(false)
        }
        else {
          setDisplayLoader(false)
          toast.error(meetingRes.message, { position: 'top-center' });
        }
      }

    }
    else {
      setDisplayLoader(false)
      toast.error(updateVisitMeetingResponse.message, { position: 'top-center' });
    }

  }

  const cancelModal = () => {
    setOpen(false)
    setSigPad('')
    setMeetingNotes('')
    setMeetingImages([])
    setMeetingVideoes([])
    setOpen(false)
  }

  const removeSpecificImage = (index) => {
    let _imgArray = meetingImages
    _imgArray.splice(index, 1)
    setMeetingImages([..._imgArray])
  }

  const removeSpecificVideo = (index) => {
    let _videosArray = meetingVideoes
    _videosArray.splice(index, 1)
    setMeetingVideoes([..._videosArray])
  }

  const submitEduNotes = async () => {
    if (eduNotes === '' || eduNotes === undefined){
      toast.error('Please enter Educator Notes', {
        position: 'top-center'
      });
      return false
    }else{
     let data = { educator_notes: eduNotes }

     let response = await updateEducatorNotesVisit(props.match.params.id, data)
     if (response.status == 200) {
      toast.success(response.message, {
        position: 'top-center'
      });
      history.push('/m&s');
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
    }
  }


  if (displayLoader) {
    return <LoaderComponent displayLoader={displayLoader} />
  }

  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={displayLoader} />
      {
        userRole === 1 ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <div className="main-wrapper mtb-70">
        <div className="container">
          {title !== "" ?
            <div className="dashboard-wrap">
              <div className="common-header-row common-header-with-large-text">
                <h4>{title}</h4>
                {/* {`/edit-m&s`} */}
                {userRole == 2 ? (
                  <button class="mdc-button mdc-button--raised" onClick={() => navigateToEdit()}><span class="mdc-button__label">Edit M & S</span></button>
                ) : null}
                {/* <button class="mdc-button mdc-button--raised" onClick={() => setOpen(true)}><span class="mdc-button__label">ADD Notes</span></button> */}
                <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="sidebar">
                    <div className="time-date-row">
                      <div className="time-block">
                        <text><span className="material-icons">schedule</span>Time</text>
                        <p>{time}</p>
                      </div>
                      <div className="date-block">
                        <text><span className="material-icons">date_range</span>Date</text>
                        <p>{moment(date).format('DD-MM-YYYY')}</p>
                      </div>
                    </div>
                    <div className="list-block meeting-list">
                      <h5 className="title18">Visit Method</h5>
                      <ul>
                        <li>{meetingMethod}</li>
                      </ul>
                    </div>
                    <div className="list-block meeting-list">
                      <h5 className="title18">Coordinator Assigned</h5>
                      <ul>
                        <li>{createdBy ? createdBy : "Assign Coordinator"}</li>
                      </ul>
                    </div>
                    {
                      store.userRole === 2 ?
                        <div className="list-block meeting-list">
                          <h5 className="title18">Assigned To</h5>
                          <ul>
                            <li>{assignedTo ? assignedTo : "Assign Educator"}</li>
                          </ul>
                        </div>
                        :
                        null
                    }

                    {
                      visitLocation ?
                        <div className="list-block meeting-list">
                          <h5 className="title18">Address</h5>
                          <ul>
                            <li>{visitLocation}</li>
                          </ul>
                        </div>
                        :
                        null
                    }

                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content-block-right">

                    <div className="detail-media-block">
                      {
                        uploadedImage.length > 0 ?
                          uploadedImage.map(uploadImageData =>
                            <img src={uploadImageData} />)
                          : null
                      }
                    </div>

                    {
                      videoId.length > 0 ?
                        videoId.map((data) => {
                          return (
                            <iframe width="420" height="315" src={`//www.youtube.com/embed/${data}`}> </iframe>
                          )
                        })
                        :
                        null
                    }

                    {
                      vimeoId.length > 0 ?
                        vimeoId.map((data) => {
                          return (
                            <iframe width="420" height="315" src={`//player.vimeo.com/video/${data}`}> </iframe>
                          )
                        })
                        :
                        null
                    }

                    {
                      videoUrl.length > 0 ?
                        videoUrl.map((data) => {
                          return (
                            <img src={data} />
                          )
                        })
                        :
                        null
                    }

                    {
                      agenda ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Agenda</h5>
                          <div className="material-textfield">
                            <textarea maxlength="200" placeholder="" rows="6" readOnly>{agenda}</textarea>
                          </div>
                        </div>
                        : null
                    }

                    {
                      notes ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Notes</h5>
                          <div className="material-textfield">
                            <textarea maxlength="200" placeholder="" rows="6" readOnly>{notes}</textarea>
                          </div>
                        </div>
                        : null
                    }
                    
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Educator Notes</h5>
                          <div className="material-textfield">
                            <textarea maxlength="200" placeholder="" rows="6" onChange={(e) => setEduNotes(e.target.value)} readOnly={userRole === 1 ? false : true} >{eduNotes}</textarea>
                          </div>
                        </div>
                         <div className="row">
                          {
                            userRole === 1 ?
                            <button type='submit' onClick={submitEduNotes} className="mdc-button mdc-button--raised mt-5">
                           <span className="mdc-button__label">Submit</span>
                         </button> : null
                        }
                       </div>
                    {
                      cordSignature ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Digital Signature (WFDC Representative)</h5>
                          <img src={cordSignature} width="200" height="200" />
                        </div>
                        : null
                    }
                    {
                      eduSignature ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Digital Signature (Educator)</h5>
                          <img src={eduSignature} width="200" height="200" />
                        </div>
                        : null
                    }

                    {
                      visitsDocuments.length > 0 ?
                        visitsDocuments.map((data, index) => {
                          let fileResourceName = (data.url).split('/').pop()
                          return (
                            <div className="meeting-notes">
                              {
                                index === 0 ?
                                  <h5 className="title18"><span className="material-icons">description</span>Resources</h5>
                                  :
                                  null
                              }

                              <p><a target="_blank" href={data.url} download>{fileResourceName}</a></p>

                            </div>
                          )
                        })
                        : null
                    }

                    {
                      venue ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Venue</h5>
                          <p>{venue}</p>
                        </div>
                        : null
                    }


                    {
                      allMeetingNotes.length > 0 ?
                        allMeetingNotes.map((data, index) => {
                          const regex = /(<([^>]+)>)/ig; // remove html tags
                          let imageUrl = data.field_images ? data.field_images : ""
                          let imagesArray = []
                          if (imageUrl !== "") {
                            let allImages = imageUrl.split(",")
                            imagesArray = allImages
                          }
                          let signImage = data.field_meeting_signature ? baseUrl + data.field_meeting_signature : ""
                          signatureImage = signImage
                          let educatorImage = data.field_educator_signature ? baseUrl + data.field_educator_signature : ''
                          educatorSignature = (educatorImage)
                          let videoUrl = data.field_meeting_video ? data.field_meeting_video : ""
                          let videoArray = []
                          if (videoUrl !== "") {
                            let allVideos = videoUrl.split(",")
                            videoArray = allVideos
                          }
                          let notesTitle = data.field_meeting_notes ? data.field_meeting_notes.replace(regex, '') : ""

                          return (
                            <>
                              {/* {
                                                            notesTitle !== "" ?
                                                                <div className="meeting-notes">
                                                                    <h5 className="title18">{index + 1}. Meeting Notes created by {data.field_user}</h5>
                                                                </div>
                                                                :
                                                                null
                                                        } */}

                              {/* {
                                                            notesTitle !== "" ?
                                                                <div className="meeting-notes">
                                                                    <div className="material-textfield">
                                                                    <h5 className="title18"><span className="material-icons">description</span>Notes</h5>
                                                                    <textarea className='meeting-notes-pre-aligned' maxlength="200" placeholder="" rows="6" readOnly>{notesTitle}</textarea>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        } */}

                              {
                                imagesArray.length > 0 ?
                                  imagesArray.map((data, i) => {
                                    let imageFullPath = baseUrl + data.trim()
                                    return (
                                      <div className="meeting-notes signature-field">
                                        {
                                          i === 0 ?
                                            <h5 className="title18"><span className="material-icons">description</span>Meeting Images</h5>
                                            :
                                            null
                                        }
                                        <img src={imageFullPath} width="200" height="200" />
                                      </div>
                                    )
                                  })
                                  : null
                              }


                              {
                                videoArray.length > 0 ?
                                  videoArray.map((data, i) => {
                                    let videoFullPath = baseUrl + data.trim()
                                    return (
                                      <div className="meeting-notes signature-field">
                                        {
                                          i === 0 ?
                                            <h5 className="title18"><span className="material-icons">description</span>Meeting Videos</h5>
                                            :
                                            null
                                        }
                                        <iframe src={videoFullPath} />
                                      </div>
                                    )
                                  })
                                  : null
                              }


                              {/* {
                                                            cordSignature ?
                                                                <div className="meeting-notes">
                                                                    <h5 className="title18"><span className="material-icons">description</span>Digital Signature (WFDC Representative)</h5>
                                                                    <img src={cordSignature} width="200" height="200" />
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            eduSignature ?
                                                                <div className="meeting-notes">
                                                                    <h5 className="title18"><span className="material-icons">description</span>Digital Signature (Educator)</h5>
                                                                    <img src={eduSignature} width="200" height="200" />
                                                                </div>
                                                                : null
                                                        } */}

                            </>
                          )
                        })
                        :
                        null
                    }
                  </div>
                </div>
              </div>
            </div> :
            <div className="common-header-row common-header-with-large-text">
              <h4>M & S Not Found</h4>
              <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
            </div>
          }
        </div>
      </div>


      {/*  Meeting Notes Diaog */}

      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Meeting Notes</DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form className="form-meeting-notes">
                <div className="row">
                  <div className="col-md-12">
                    <div className="outlined-textfield-wrap mt-10">
                      <div className="material-textfield">
                        <textarea onChange={(e) => { setMeetingNotes(e.target.value) }} className={meetingNotes ? 'form__field active' : 'form__field'} placeholder="" rows="6"
                        ></textarea>
                        <label>Write notes here</label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="meeting-notes">
                                    <button className="mdc-button mdc-button--raised" type="button">
                                        <input onChange={(e) => uploadImages(e)} type="file" accept="image/jpg,image/jpeg,image/png" multiple style={{ opacity: 0, position: 'absolute' }} />
                                        Add Image
                                    </button>
                                    <small className="short-description mt-5">Image can only be of the following types : jpg, jpeg, png with limit upto 2MB</small>
                                    {
                                        meetingImages.length > 0 ?
                                            meetingImages.map((items, index) => {
                                                if (items.path) {
                                                    return (
                                                        <>
                                                            <img src={items.path} width="200" height="200" />
                                                            <button type="button" onClick={(e) => { removeSpecificImage(index) }} ><span className="material-icons close">close</span></button>
                                                        </>
                                                    )
                                                }
                                            }
                                            )
                                            :
                                            null
                                    }
                                    
                                </div> */}

                <div className="meeting-notes">
                  {/* <button  className="mdc-button mdc-button--raised" type="button">
                                        <input onChange={(e)=>uploadVideos(e)} type="file" accept=".mp4" multiple style={{opacity:0,position:'absolute'}}  />
                                        Add Video
                                    </button>
                                    <small className="short-description mt-5">Video can only be of the following types : mp4 with limit upto 2MB</small>
                                    {
                                        meetingVideoes.length > 0 ?
                                            meetingVideoes.map((items, index) => {
                                                if (items.path) {
                                                    return (
                                                        <>
                                                            <video height="200" width="200" id="video" src={items.path} controls />
                                                            <button type="button" onClick={(e) => { removeSpecificVideo(index) }}><span className="material-icons close">close</span></button>
                                                        </>)
                                                }
                                            }
                                            )
                                            :
                                            null
                                    } */}
                </div>

                {signatureImage ? (
                  <div className="meeting-notes">
                    <h5 className="title18">WFDC Representative</h5>
                    <div className="signature-block">
                      <img src={signatureImage} width="200" height="200" />
                    </div>
                  </div>
                ) : (
                  userRole === 2 ? (
                    <div className="meeting-notes">
                      <h5 className="title18">WFDC Representative</h5>
                      <div className="signature-block">
                        <SignaturePad canvasProps={{ className: styles.sigPad }} ref={(ref) => setSigPad(ref)} />
                      </div>
                    </div>
                  ) : (
                    < div className="meeting-notes">
                      <h5 className="title18">WFDC Representative</h5>
                      <div className="signature-block">
                        <img src={blankImage} width="200" height="100" />
                      </div>
                    </div>
                  )
                )
                }
                {
                  educatorSignature && educatorSignature ? (
                    <div className="meeting-notes">
                      <h5 className="title18">Digital Signature (Educator)</h5>
                      <div className="signature-block">
                        <img src={educatorSignature} width="200" height="200" />
                      </div>
                    </div>
                  ) : (
                    userRole === 1 ? (
                      <div className="meeting-notes">
                        <h5 className="title18">Digital Signature (Educator)</h5>
                        <div className="signature-block">
                          <SignaturePad readOnly canvasProps={{ className: styles.sigPad }} ref={(ref) => setSigPad(ref)} />
                        </div>
                      </div>
                    ) : (
                      < div className="meeting-notes">
                        <h5 className="title18">Digital Signature (Educator)</h5>
                        <div className="signature-block">
                          <img src={blankImage} width="200" height="100" />
                        </div>
                      </div>
                    )
                  )
                }


              </form>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelModal} color="primary">Cancel</Button>
          <Button onClick={submitMeetingNotes} color="primary" autoFocus>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EducatorVisitDetail;