import React, { useEffect, useState } from 'react';
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import './styles.css';
import Calendar from 'react-calendar';
import Moment from 'react-moment';
import TimePicker from 'react-time-picker';
import { useSelector } from "react-redux"
import Select from 'react-select';
import { getAllEducators, getLeaves, taskTitleList } from '../../../functions/api';
import { Link, useHistory } from 'react-router-dom';
import { createTask } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImage } from '../../../functions/api';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css'
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));


const AddNewTaskPage = () => {

	const classes = useStyles();
	const store = useSelector(state => state.auth)
	const history = useHistory();
	const [userRole, setUserRole] = useState(1)
	const [title, setTitle] = useState('');
	const [taskTitleOptions, setTaskTitleOptions] = useState([]);
	const [showCalendar, setCalendar] = useState(false);
	const [msDate, setMsDate] = useState('');
	const [timeValue, setTimeValue] = useState("");
	const [options, setOptions] = useState([]);
	const [showTimePicker, setTimePicker] = useState(false);
	const [educatorName, setEducatorName] = useState('')
	const [taskNotes, setTaskNotes] = useState('');
	const [educatorsList, setEducatorsList] = useState([])
	const [calendarValue, setCalendarValue] = useState();
	const [video, setVideo] = useState();
	const [link, setLink] = useState();
	const [ajaxLoader, setAjaxLoader] = useState(false);
	const [addImageName, setAddImageName] = useState("");
	const [addImagePath, setAddImagePath] = useState("");
	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
	const [leavesArray, setLeavesArray] = useState([])
	const [blockButton, setBlockButton] = useState(false)
	const [status, setStatus] = useState({ value: 'To-Do', label: 'To-Do' });
	const [timeStampStart, setTimeStampStart] = useState()

	const taskOptions = [
		{ value: 'To-Do', label: 'To-Do' },
		{ value: 'In Progress', label: 'In Progress' },
		{ value: 'Completed', label: 'Completed' },
	];

	function calendar() {
		if (showCalendar === false) {
			setCalendar(true)
		}
		else {
			setCalendar(false)
		}

	}

	const taskTitleHandler = (event) => {
		setTitle(event.target.value);
	};

	const taskStatusHandler = (e) => {
		setStatus(e);
		console.log("event", e.value);
	}

	const educatorsHandler = (event) => {
		setEducatorName(event);
	};

	const calendarHandler = (newDate) => {
		console.log("newDate", newDate);
		let d = new Date(newDate).getTime()
		setTimeStampStart(d)
		console.log(d);

		let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
		let year = newDate.getFullYear()
		let date = newDate.getDate()
		let fullYear = year + "-" + month + "-" + date

		setMsDate(fullYear)
		setCalendar(false)
	}
	
	function _onDateFormat(date) {
		if (!date) {
			return ""
		}

		let newDate = date.split('-')
		let newValue = `${newDate[2]}-${newDate[1]}-${newDate[0]}`
		console.log("new--", newValue);

		return (newValue)
	}

	const timeHandler = (newTime) => {
		setTimeValue(newTime)
		setTimePicker(false)
	}

	const onChangeNotesHandler = (event) => {
		setTaskNotes(event.target.value);
	}

	useEffect(() => {

		$('#chooseFile').bind('change', function () {
			var filename = $("#chooseFile").val();
			if (/^\s*$/.test(filename)) {
				$(".file-upload").removeClass('active');
				$("#noFile").text("No file chosen...");
			} else {
				$(".file-upload").addClass('active');
				$("#noFile").text(filename.replace("C:\\fakepath\\", ""));
			}
		});

		if (userRole === 1 || userRole === 3) {

			let _name = window.localStorage.getItem('name')
			setEducatorName(_name);
		}

		$(function () {
			$('.dropdown-toggle').click(function () {
				$(this).next('.dropdown').slideToggle();
			});

			$(document).click(function (e) {
				var target = e.target;
				if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) { $('.dropdown').slideUp(); }
			});
		});

		$(function () {
			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
				if ($(this).val().length > 0) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			})
		})

		async function getInitialData() {

			if (store.userRole === 2) {
				let sortedEducatorsList = await getAllEducators();
				setEducatorsList(sortedEducatorsList.data.data);
				let opt_array = [];
				if (sortedEducatorsList.data.data.length > 0) {
					if (store.userRole == 1) {
						sortedEducatorsList.data.data.map((edu, index) => {
							if (edu.field_archive == 'Off') {
								const obj = {};
								obj['value'] = edu.uid;
								obj['label'] = edu.name;
								opt_array.push(obj);
							}
						})
						setOptions(opt_array[0].value);

					} else {
						sortedEducatorsList.data.data.map((edu, index) => {
							if (edu.field_archive == 'Off') {
								const obj = {};
								obj['value'] = edu.uid;
								obj['label'] = edu.name;
								opt_array.push(obj);
							}
						})
						setOptions(opt_array);
					}
				}
			} else if (store.userRole === 1 || store.userRole === 3) {
				let _userId = window.localStorage.getItem('uid')
				let _name = window.localStorage.getItem('name')
				let namesArray = [{ 'value': _userId, 'label': _name }]
				setOptions([...namesArray])
			}

			let titleOptions = await taskTitleList()
			if (titleOptions.status === 200) {
				if (titleOptions.data !== undefined && titleOptions.data.data.length > 0) {
					let newArray = [];
					titleOptions.data.data.map((data, index) => {
						var listName = data.name.replace(/&amp;/g, '&');
						let obj = {};
						obj['value'] = data.name;
						obj['label'] = listName;
						newArray.push(obj);
					})
					setTaskTitleOptions(newArray)
				}
			}

		}

		getInitialData()

		setUserRole(store.userRole)

	}, [store.userRole]);

	async function taskSubmission(e) {

		e.preventDefault();

		setAjaxLoader(true);
		let _name = window.localStorage.getItem('name');

		let main_title = title;
		let educator_id = userRole === 1 || userRole === 3 ? options[0].value : educatorName.value

		// educatorName.value ?  educatorName.value : "";
		let date = msDate.replace(/\//g, '-');
		let notes = taskNotes;

		if (title === undefined || title === '') {
			toast.error('Task title is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if (msDate === undefined || msDate === '') {
			toast.error('Date is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		// if (notes === undefined || notes === '') {
		// 	toast.error('Task notes is required', {
		// 		position: 'top-center'
		// 	});
		// 	setAjaxLoader(false);
		// 	return false;
		// }

		setBlockButton(true);
		const getDatesBetweenDates = (startDate, endDate) => {
			let dates = []
			//to avoid modifying the original date
			const theDate = new Date(startDate)
			while (theDate < endDate || theDate.valueOf() === endDate.valueOf()) {
				dates = [...dates, new Date(theDate)]
				theDate.setDate(theDate.getDate() + 1)
			}
			return dates
		}

		//getLeaves
		let checkLeaveResponse = {};
		if (educator_id !== '' && date !== '') {
			let selected_date = new Date(date).setHours(0, 0, 0, 0);
			let leaves = await getLeaves(educator_id);
			if (leaves.status === 200) {
				if (leaves.data.data.length > 0) {
					setLeavesArray(leaves.data.data)
					for (let mydata of leaves.data.data) {
						for (let rangeDate of getDatesBetweenDates(new Date(mydata.start_date), new Date(mydata.end_date))) {
							if (selected_date.valueOf() === rangeDate.valueOf()) {
								checkLeaveResponse['response'] = date
								checkLeaveResponse['success'] = false
							}
						}
					}
				}
			}
		}
		if (checkLeaveResponse['response'] !== undefined && store.userRole == 2) {
			setShowAvailabilityModal(true)
			setAjaxLoader(false);
			setBlockButton(false)
			return false;
		}


		let data = { title: main_title, educator_id: educator_id, date: date, notes: notes, status: status };
		let res = await createTask(data);
		if (res.status == 201) {
			setAjaxLoader(false);
			history.push('/tasks');
		} else {
			toast.error(res.message, {
				position: 'top-center'
			});
			setAjaxLoader(false);
		}

	}

	return (
		<>
			<ToastContainer />
			<LoaderComponent displayLoader={ajaxLoader} />
			{
				userRole === 1 || userRole === 3 ?
					<EducatorsMainHeader />
					:
					<HeadOfficeMainHeader />
			}
			<div className="main-wrapper">
				<div className="container">
					<div className="user-pages-wrap">
						<div className="row">
							<div className="col-lg-12">
								<div className="user-types-wrap course-detail-page text-center">
									<div className="common-header-row common-header-with-large-text">
										<h4>New Task</h4>
										<div className="block-right">
											<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
										</div>
									</div>

									<form onSubmit={taskSubmission} className="sign-up-form">
										<div className="row">
											<div className="col-md-12">
												<div className="row">

												<div className="col-lg-4 col-md-4">
                            <div className="outlined-textfield-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input maxlength="100" onChange={taskTitleHandler} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Task Name</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                          </div>
													{/* {
														userRole == 1 ?
															<div className="col-lg-4 col-md-4">
																<Select className="search-select-field" placeholder="Task Name" style={{ width: 100 }} value={title} options={taskTitleOptions} onChange={taskTitleHandler} />
															</div>
															:
															<div className="col-lg-3 col-md-3">
																<Select className="search-select-field" placeholder="Task Name" style={{ width: 100 }} value={title} options={taskTitleOptions} onChange={taskTitleHandler} />
															</div>
													} */}

													{
														userRole == 2 ?
															<div className="col-lg-3 col-md-3">
																<Select className="search-select-field" placeholder="Select Educator" style={{ width: 100 }} value={educatorName} options={options} onChange={educatorsHandler} />
																<small>*Leave it empty to assign task it globally</small>
															</div>
															:
															null

													}


													<div className="col-lg-3 col-md-3">
														<div className="calendar-wrap">
															<label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar()}>
																<input readOnly type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" style={{ cursor: "pointer" }} />
																<span className="mdc-notched-outline">
																	<span className="mdc-notched-outline__leading"></span>
																	<span className="mdc-notched-outline__notch">
																		<span className="mdc-floating-label" id="my-label-id">{msDate ? _onDateFormat(msDate) : "Due Date"}</span>
																	</span>
																	<span className="mdc-notched-outline__trailing"></span>
																	<span className="material-icons">calendar_today</span>
																</span>
															</label>
															<div>
																{
																	showCalendar === true ? <Calendar value={calendarValue} onChange={calendarHandler} /> : null
																}
															</div>
														</div>
													</div>

													<div className="col-lg-3 col-md-3">
														<Select className="search-select-field" placeholder="Select Status" value={status} onChange={(event) => taskStatusHandler(event)} options={taskOptions} style={{ width: 100 }}>
															{/* <option value="to-do">To-Do</option>
															<option value="in-progress">In-progress</option>
															<option value="completed">Completed</option> */}
														</Select>
													</div>
												</div>
											</div>
										</div>




										<div className="row">
											<div className="col-md-12">
												<div className="outlined-textfield-wrap mt-10">
													<div className="material-textfield">
														<textarea maxlength="200" onChange={onChangeNotesHandler} id="message" className="form__field" placeholder="" rows="6"></textarea>
														<label>Task Notes</label>
													</div>
												</div>
											</div>
										</div>
										<button type='submit' disabled={blockButton} className="mdc-button mdc-button--raised mt-5">
											<span className="mdc-button__label">Submit</span>
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog open={showAvailabilityModal && store.userRole == 2} onClose={() => setShowAvailabilityModal(false)} aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">User is not available on following dates-</DialogTitle>
				<DialogContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>S.No.</TableCell>
									<TableCell>Start Date</TableCell>
									<TableCell>End Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									leavesArray.length > 0 ? leavesArray.map((items, index) => (
										<TableRow key={index}>
											<TableCell>{index + 1}</TableCell>
											<TableCell><Moment format="DD-MM-YYYY">{items.start_date}</Moment></TableCell>
											<TableCell><Moment format="DD-MM-YYYY">{items.end_date}</Moment></TableCell>
										</TableRow>

									)) : null
								}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setShowAvailabilityModal(false); setBlockButton(false) }} color="primary" autoFocus>OK</Button>
				</DialogActions>
			</Dialog>
		</>
	)

}

export default AddNewTaskPage;