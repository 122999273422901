import React, { useState, useEffect, useRef } from 'react';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { $ } from 'react-jquery-plugin';
import { useDispatch } from 'react-redux';
import { getLoggedInUserDetail } from '../../../redux/actions/auth';
import { getCalendarTasksDataGlobally, getCalendarVisitsDataGlobally, getUserBirthdayByDate, getParticularUserContentCustom, getReminders, appliedLeaves, archiveDataOfEducator, getLeavesOfEducator, getUpcomingMandS, getCurrentLeaves, getSpecificUser, getRemindersGlobal, getReminderOfAdultOccupants, getReminderEdu, getReminderOfAdultEdu, deleteLeavesCordinator, getLeavesofCordinator, updateLeavesCordinator, getReminderEduNewApi, getReminderOfAdultEduNew, getCaBTRemindersList, getReminderOfAdultCabt } from '../../../functions/api';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from "react-modern-calendar-datepicker";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Modal } from 'react-responsive-modal';
import './styles.css';
import { getTaskListingForHeadofficeAsPerDate, getVisitListingForHeadofficeAsPerDate } from '../../../functions/api';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import Moment from 'react-moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
//Import Images
import LeaveCalendar from 'react-calendar';
import { decode } from 'html-entities';
import ReactToPrint from 'react-to-print';
import { confirmAlert } from 'react-confirm-alert';

const HeadOfficeDashboard = () => {

  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const store = useDispatch(state => state.auth);
  const [calendarDates, setCalendarDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [showUsername, setShowUsername] = useState([]);
  const [remindersArray, setRemindersArray] = useState([]);
  const [calendarTasks, setCalendarTasks] = useState([]);
  const [calendarVisits, setCalendarVisits] = useState([]);
  const [calendarReminderNotices, setCalendarReminderNotices] = useState([]);
  const [showLoader, setShowLoader] = useState(true)
  const [calendarReminderEdu, setCalendarReminderEdu] = useState([]);
  const [calendarReminderCabt, setCalendarReminderCabt] = useState([]);
  const [calendarReminderAdult, setCalendarReminderAdult] = useState({});
  const [selectedTab, setSelectedTab] = useState(1)
  const [leaveModal, setLeaveModal] = useState(false)
  const [oneDayLeaveCal, setOneDayLeaveCal] = useState(false);
  const [multipleDaysStartCal, setMultipleDaysStartCal] = useState(false)
  const [multipleDaysEndCal, setMultipleDaysEndCal] = useState(false)
  const [oneDayLeaveValue, setOnedayLeaveValue] = useState()
  const [leaveStartDate, setLeaveStartDate] = useState();
  const [leaveEndDate, setLeaveEndDate] = useState();
  const [allLeaves, setAllLeaves] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [mAnds, setmAnds] = useState([]);
  //Tabs
  const [selectedLeaveVal, setSelectedLeaveVal] = useState("onedayleave")
  const [atozSort, setAtoZSort] = useState('AtoZ')
  const [leavesId, setLeavesId] = useState();
  const [allEduReminder, setAllEduReminder] = useState('All')
  const [filterModal, setFilterModal] = useState(false)
  const [showFilterLeave, setShowFilterLeave] = useState([])
  const [showFilterData, setShowFilterData] = useState(false)
  const [timeStampEnd, setTimeStampEnd] = useState()
  const [timeStampStart, setTimeStampStart] = useState()
  const [cordinatorUserID, setCordinatorUserID] = useState('')
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  //converting weeks into days
  const weeksIntoDays = (weeks) => {
    let days = parseInt(weeks) * 7;
    return days;
  }

  const getDayMonthDay = (date) => {
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newDate = {};
    newDate['year'] = year;
    newDate['month'] = month;
    newDate['day'] = day;
    return newDate;
  }

  const current = async () => {
    try {
      const res = await getCurrentLeaves();
      if (res.status === 200 && res.data.data.length > 0) {
        setShowLoader(true);
        const arr = await Promise.all(res.data.data.map(async (v) => {
          try {
            const response = await getSpecificUser(v.uid);
            if (response.status === 200) {
              const firstName = response.data.data.field_first_name?.[0]?.value || '';
              const lastName = response.data.data.field_last_name?.[0]?.value || '';
              return {
                firstName,
                lastName,
                id: v.id,
                uid: v.uid,
                start_date: v.start_date,
                end_date: v.end_date,
              };
            }
          } catch (error) {
            console.error('Error fetching user details:', error);
          }
        }));
        // const filterData = arr.filter(item => new Date(item.end_date).getTime() >= new Date().getTime());
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // console.log('currentDate ', currentDate);
        const filterData = arr.filter((item) => {
          const startDate = new Date(item.end_date);
          startDate.setHours(0, 0, 0, 0);
          // console.log('startDate ', startDate);
          return startDate.getTime() >= currentDate;
        });
        const sortedData = filterData.sort((a, b) => Date.parse(a.start_date) - Date.parse(b.start_date));
        setAllLeaves(sortedData);
      }
    } catch (error) {
      console.error('Error fetching current leaves:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const archiveLeave = async () => {
    setShowLoader(true);
    try {
      const res = await archiveDataOfEducator(window.localStorage.getItem('uid'));
      if (res.status === 200 && res.data.data.length > 0) {
        const promises = res.data.data.map(async (v) => {
          const response = await getSpecificUser(v.uid);
          const { field_first_name, field_last_name } = response.data.data;
          const firstName = field_first_name.length ? field_first_name[0].value : '';
          const lastName = field_last_name.length ? field_last_name[0].value : '';
          return {
            firstName,
            lastName,
            id: v.id,
            uid: v.uid,
            start_date: v.start_date,
            end_date: v.end_date,
          };
        });
        const arr = await Promise.all(promises);
        const filterData = arr.filter(item => new Date(item.end_date).getTime() < new Date() - 24 * 60 * 60 * 1000);
        const sortArchive = filterData.sort((a, b) => Date.parse(a.start_date) - Date.parse(b.start_date));
        setAllLeaves(sortArchive);
        setShowLoader(false);
        setIsArchive(true);
      } else {
        toast.error(res.response.message, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const allLeavesTab = async () => {
    try {
      const res = await getCurrentLeaves();
      if (res.status !== 200 || res.data.data.length === 0) {
        setShowLoader(false);
        return;
      }
      setShowLoader(true);
      const arr = await Promise.all(res.data.data.map(async (v) => {
        const response = await getSpecificUser(v.uid);
        if (response.status === 200) {
          const firstName = response.data.data.field_first_name?.[0]?.value || '';
          const lastName = response.data.data.field_last_name?.[0]?.value || '';
          return {
            firstName,
            lastName,
            id: v.id,
            uid: v.uid,
            start_date: v.start_date,
            end_date: v.end_date,
          };
        }
        return null;
      }));
      const validData = arr.filter(item => item !== null);
      const sortedData = validData.sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date));
      setAllLeaves(sortedData);
    } catch (error) {
      console.error("Error in allLeavesTab:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const applyForLeave = (data, isEdit) => {
    if (isEdit) {
      setDefaultStartDate(new Date(data.start_date));
      setDefaultEndDate(new Date(data.end_date));
    } else {
      setDefaultStartDate("");
      setDefaultEndDate("");
    }
    data?.end_date === data?.start_date ? setSelectedLeaveVal("onedayleave") : setSelectedLeaveVal("multipledaysleave")
    if (data.start_date === data.end_date) {
      setOnedayLeaveValue(data.start_date)
    } else {
      setOnedayLeaveValue('')
    }
    if (data.start_date !== data.end_date) {
      setLeaveStartDate(data.start_date)
      setLeaveEndDate(data.end_date)
      setLeavesId(data.id)
    } else {
      setLeaveStartDate('')
      setLeaveEndDate('')
      setLeavesId(data.id)
    }
    setLeaveModal(true)
    setOneDayLeaveCal(false)
    setMultipleDaysStartCal(false)
    setMultipleDaysEndCal(false)
  }
  const leaveHandler = (event) => {
    setSelectedLeaveVal(event.target.value)
  }

  const filterLeave = () => {
    setFilterModal(true)
    setOnedayLeaveValue('')
    setLeaveStartDate('')
    setLeaveEndDate('')
  }

  async function oneDayLeaveApplicationForm(e) {
    e.preventDefault();
    setShowLoader(true)
    var new_date = moment(oneDayLeaveValue, "YYYY-MM-DD");
    var defaultEndDate = new_date.add(0, 'days').format('YYYY-MM-DD');
    let data = { startDate: oneDayLeaveValue, endDate: defaultEndDate, userId: window.localStorage.getItem('uid') }
    if (oneDayLeaveValue !== '') {


      if (leavesId !== undefined) {
        let response = await updateLeavesCordinator(data, leavesId);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Updated Successfully !!', { position: 'top-center' });
          setShowLoader(false)
          setLeaveModal(false);
          getAllInitialData()
        }

      }
      else {
        let response = await appliedLeaves(data);
        if (response.status === 200) {
          setShowLoader(false)
          toast.success('Your Leave Application Submitted Successfully!!', { position: 'top-center' });
          setLeaveModal(false);
          getAllInitialData()
          let res = await getLeavesOfEducator(window.localStorage.getItem('uid'));
          if (res.status === 200) {

            if (res.data.data.length > 0) {
              getAllInitialData()
            }
          }
        } else {
          toast.error(response.message, { position: 'top-center' });
        }
      }
    } else {
      setShowLoader(false)
      toast.error('Date is required', { position: 'top-center' });
    }

  }
  const multipleLeavesStartCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampStart(d)
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveStartDate(fullYear)
    setMultipleDaysStartCal(false)
  }

  async function multipleDaysLeaveApplicationForm(e, id) {
    e.preventDefault();
    setShowLoader(true)
    let data = { startDate: leaveStartDate, endDate: leaveEndDate, userId: window.localStorage.getItem('uid') }
    if (leaveStartDate !== '' && leaveEndDate !== '') {
      if (new Date(timeStampStart) < new Date(timeStampEnd)) {
        if (leavesId !== undefined) {
          let response = await updateLeavesCordinator(data, leavesId, id);
          if (response.status === 200) {
            toast.success('Updated Successfully !!', { position: 'top-center' });
            setShowLoader(false)
            setLeaveModal(false);
            getAllInitialData()
          }
        }
        else {
          let response = await appliedLeaves(data);
          if (response.status === 200) {
            setShowLoader(false)
            toast.success('Your Leave Application Submitted Successfully!!', { position: 'top-center' });
            setLeaveModal(false)
            getAllInitialData()
            let res = await getLeavesOfEducator(window.localStorage.getItem('uid'));
            if (res.status === 200) {
              if (res.data.data.length > 0) {
                getAllInitialData()
              }
            }
          } else {
            setShowLoader(false)
            toast.error(response.message, { position: 'top-center' });
          }
        }
      }
      else {
        setShowLoader(false)
        toast.error('Start Date must be lesser than End Date', { position: 'top-center' });
      }
    } else {
      setShowLoader(false)
      toast.error('Start Date and End Date is required', { position: 'top-center' });
    }
  }
  const multipleLeavesEndCalendarHandler = (newDate) => {
    let d = new Date(newDate).getTime()
    setTimeStampEnd(d)

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setLeaveEndDate(fullYear)
    setMultipleDaysEndCal(false)
  }

  async function deleteLeavesData(id) {
    let response = await deleteLeavesCordinator(id);
    if (response.status === 200) {
      toast.success('Leaves has been deleted successfully!', {
        position: 'top-center'
      });
      setAllLeaves((prevLeaves) => prevLeaves.filter(leave => leave.id !== id));

      getLeavesData()
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteLeaves(id) {
    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLeavesData(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }
  function leaveCalendarClickable(value) {
    if (value === 'onedayleave') {
      setOneDayLeaveCal(true);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(false);
    } else if (value === 'startdate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(true);
      setMultipleDaysEndCal(false);
    } else if (value === 'enddate') {
      setOneDayLeaveCal(false);
      setMultipleDaysStartCal(false);
      setMultipleDaysEndCal(true);
    }
  }
  const filterCalendarClickable = (value) => {
    setShowFilterData(false)
    if (value === 'onedayleave') {
      setOneDayLeaveCal(true)
    }

    if (value === 'startdate') {
      setMultipleDaysStartCal(true)

    }

    if (value === 'enddate') {
      setMultipleDaysEndCal(true)
    }

  }


  const checkFilterData = () => {

    if (allLeaves.length > 0) {
      let filterPrint = []
      let filterLeaveStart = moment(leaveStartDate).format("X");
      let filterLeaveEnd = moment(leaveEndDate).format("X");
      // console.log({
      //   filterLeaveStart,
      //   filterLeaveEnd
      // })
      allLeaves.map((item) => {
        let filterStart = moment(item.start_date).format("X");
        let filterEnd = moment(item.end_date).format("X");
        return filterLeaveStart <= filterStart && filterLeaveEnd >= filterEnd;
      })
      setShowFilterLeave(filterPrint)
      setShowFilterData(true)
    }
  }

  const oneDayLeavesCalendarHandler = (newDate) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date
    setOnedayLeaveValue(fullYear)
    setOneDayLeaveCal(false)
  }

  async function getLeavesData() {
    try {
      let res = await getLeavesofCordinator(window.localStorage.getItem('uid'));

      if (res.status === 200) {
        const leavesData = res.data.data;

        if (leavesData.length > 0) {
          setAllLeaves(leavesData);
        } else {
          console.warn('Leaves data is empty or not an array:', leavesData);
        }
      } else {
        console.error('Error fetching leaves data:', res.status, res.data.message);
      }
    } catch (error) {
      console.error('Error in getLeavesData:', error);
    }
  }

  async function getAllInitialData() {
    const removeLocalStorageItem = (key) => {
      const item = window.localStorage.getItem(key);
      if (item !== null && item !== undefined) {
        window.localStorage.removeItem(key);
      }
    };

    removeLocalStorageItem("educatorSignupDetail");
    removeLocalStorageItem("headOfficeSignupDetail");

    const authToken = window.localStorage.getItem("auth_token");
    const uid = window.localStorage.getItem("uid");

    if (authToken && uid) {
      try {
        const responseData = await dispatch(getLoggedInUserDetail(uid, authToken));
        if (responseData.status !== 200) {
          toast.error(responseData.message, { position: "top-center" });
        }
      } catch (error) {
        console.error(error);
      }
    }

    try {
      const res = await getCurrentLeaves()
      if (res.status === 200 && res.data.data.length > 0) {
        setShowLoader(true);
        const arr = await Promise.all(
          res.data.data.map(async (v) => {
            const response = await getSpecificUser(v.uid);
            if (response.status === 200) {
              const firstName = response.data.data.field_first_name?.[0]?.value || "";
              const lastName = response.data.data.field_last_name?.[0]?.value || "";
              return {
                firstName,
                lastName,
                id: v.id,
                uid: v.uid,
                start_date: v.start_date,
                end_date: v.end_date,
              };
            }
          })
        );

        // const filterData = arr.filter((item) => new Date(item.end_date).getTime() >= new Date().getTime());
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // console.log('currentDate ', currentDate);
        const filterData = arr.filter((item) => {
          const startDate = new Date(item.end_date);
          startDate.setHours(0, 0, 0, 0);
          // console.log('startDate ', startDate);
          return startDate.getTime() >= currentDate;
        });
        const sortedData = filterData.sort((a, b) => Date.parse(a.start_date) - Date.parse(b.start_date));
        setAllLeaves(sortedData);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  }

  useEffect(() => {
    $(function () {
      // 最初のコンテンツ以外は非表示
      $(".accordion-content:not(:first-of-type)").css("display", "none");
      // 矢印の向きを変えておく
      $(".js-accordion-title:first-of-type").addClass("open");

      $(".js-accordion-title").click(function () {
        $(".open").not(this).removeClass("open").next().slideUp(300);
        $(this).toggleClass("open").next().slideToggle(300);
      });
    });

    setCordinatorUserID(window.localStorage.getItem('uid'))
    globalCalenderData()
  }, [store.userRole])

  useEffect(() => {
    setCordinatorUserID(window.localStorage.getItem('uid'))
    getAllInitialData()
  }, [])

  const globalCalenderData = async () => {
    setShowLoader(true);
    const uid = await window.localStorage.getItem('uid');
    setSelectedTab(1);
    let allDatesArray = [];

    const fetchAndProcessDates = async (type, dateField) => {
      const dataArray = await getParticularUserContentCustom(type, '');
      if (dataArray.data !== undefined && dataArray.data.data.length > 0) {
        let data1 = dataArray.data.data
        if (type === "task") {
          data1 = dataArray.data.data.filter(item => item.uid[0].target_id === Number(uid))
        }
        for (const mydata of data1) {
          const fieldDate = mydata.field_date?.[0]?.value || mydata[dateField]?.[0]?.value;
          if (fieldDate) {
            const date = { className: 'orangeDay', ...getDayMonthDay(fieldDate) };
            allDatesArray.push(date);
          }
        }
      }
    };
    await Promise.all([
      fetchAndProcessDates('task', 'field_date'),
      fetchAndProcessDates('visits', 'field_date'),
    ]);

    setCalendarDates(allDatesArray);
    await newReminders()
    // setShowLoader(false);
  };

  const month = (date) => {
    const m = date.getMonth() + 1;
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  };

  const custom_day = (date) => {
    const m = date.getDate();
    if (m.toString().length === 1) {
      return `0${m}`;
    } else {
      return m;
    }
  }

  const newReminders = async () => {
    let reminders = await getReminderEduNewApi();
    let remindersAdult = await getReminderOfAdultEduNew();
    let allReminderDates = []
    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          reminderData.forEach(element => {
            const expiryDate = element[`field_${fieldName}`];
            const courseReminder = element[`field_${courseName.toLowerCase()}_course_reminder`];

            if (expiryDate !== '' && expiryDate !== undefined && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const courseCompletionDate = getDayMonthDay(finalDate);
              const obj = { className: 'orangeDay', ...courseCompletionDate };
              allReminderDates.push(obj);

              if (expiryDate !== '') {
                const originalExpiryDate = new Date(expiryDate);
                const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
                const originalObj = { className: 'orangeDay', ...originalExpiryDateObj };
                allReminderDates.push(originalObj);
              }
            }
          });
        }
        // field_vehicle_expiry_date 
        const courses = ['FirstAid', 'CPR', 'vehicle_restraint'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));


        function processRemainderExpiry(reminderData, fieldName, reminderField) {
          reminderData.forEach(element => {
            const expiryDate = element?.[fieldName];
            const courseReminder = element?.[reminderField];
            if (expiryDate && courseReminder) {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const courseCompletionDate = getDayMonthDay(finalDate);
              const obj = { className: 'orangeDay', ...courseCompletionDate };
              allReminderDates.push(obj);
            }
            if (expiryDate) {
              const originalExpiryDate = new Date(expiryDate);
              const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
              const originalObj = { className: 'orangeDay', ...originalExpiryDateObj };
              allReminderDates.push(originalObj);
            }
          });
        }

        processRemainderExpiry(reminders.data.data, 'field_pool_safety_cert_exp_date', 'field_pool_safety_cert_set_remin');
        processRemainderExpiry(reminders.data.data, 'field_blue_card_expiry_date', 'field_educator_reminder');
        processRemainderExpiry(reminders.data.data, 'field_teacher_expiry_date', 'field_teacher_reminder');
        processRemainderExpiry(reminders.data.data, 'field_licence_expiry_date', 'field_license_exp_reminder_date');
        processRemainderExpiry(reminders.data.data, 'field_vehicle_restraint_expiry', 'field_maintenance_reminder_date');
        processRemainderExpiry(reminders.data.data, 'field_registration_expiry_date', 'field_reg_exp_reminder_date');
        processRemainderExpiry(reminders.data.data, 'field_insurance_expiry_date', 'field_public_reminder_date');
        processRemainderExpiry(reminders.data.data, 'field_vehicle_expiry_date', 'field_restraint_audit_reminder');
        processRemainderExpiry(reminders.data.data, 'field_business_expiry_date', 'field_set_reminder_frequency')


        reminders.data.data.forEach(element => {
          function processExpiryWithReminder(reminderDate, reminderDays) {
            if (reminderDate !== '' && reminderDays !== '') {
              const d = new Date(reminderDate);
              const formattedDate = moment(d).format('YYYY-MM-DD')
              const courseExpiryDateObj = getDayMonthDay(formattedDate);

              const obj = { className: 'orangeDay', ...courseExpiryDateObj };
              allReminderDates.push(obj);
            }
          }
          const blueCardExpiryDate = element?.field_blue_card_expiry_date;
          const educatorReminder = element?.field_educator_reminder;
          processExpiryWithReminder(blueCardExpiryDate, educatorReminder);

          const teacherExpiryDate = element?.field_teacher_expiry_date;
          const teacherReminder = element?.field_teacher_reminder;
          processExpiryWithReminder(teacherExpiryDate, teacherReminder);

          // const insuranceExpiryDate = element?.field_insurance_expiry_date;
          // const publicReminderDate = element?.field_public_reminder_date;
          // processExpiryWithReminder(insuranceExpiryDate, publicReminderDate);

          // const businessExpiryDate = element?.field_business_expiry_date;
          // const setReminderFrequency = element?.field_set_reminder_frequency;
          // processExpiryWithReminder(businessExpiryDate, setReminderFrequency);

          const poolExpiryDate = element?.field_pool_safety_cert_exp_date;
          const setPoolReminderFrequency = element?.field_pool_safety_cert_set_remin;
          processExpiryWithReminder(poolExpiryDate, setPoolReminderFrequency);
        })

        if (remindersAdult.status === 200) {
          remindersAdult && remindersAdult.data.data.forEach((value) => {
            const processExpiryDate = (expiryDate, reminderType) => {
              if (expiryDate !== '' && reminderType !== '') {
                let d = new Date(expiryDate);
                if (['8 Weeks', '6 Weeks', '4 Weeks', '2 Weeks', '1 Week'].includes(reminderType)) {
                  d.setDate(d.getDate() - weeksIntoDays(reminderType));
                } else if (reminderType === '1 Day') {
                  d.setDate(d.getDate() - 1);
                } else {
                  d.setDate(d.getDate());
                }
                const finalDate = d.getFullYear() + '-' + month(d) + '-' + custom_day(d);

                const expiryDateObj = getDayMonthDay(finalDate);
                const obj = { className: 'orangeDay', ...expiryDateObj };
                allReminderDates.push(obj);
                if (expiryDate !== '') {
                  const originalExpiryDate = new Date(expiryDate);
                  const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
                  const originalObj = { className: 'orangeDay', ...originalExpiryDateObj };
                  allReminderDates.push(originalObj);
                }
              }
            };
            processExpiryDate(value.field_occupant_card_expiry_date, value.field_reminder_blue_card, 'field_occupant_card_expiry_date');
            processExpiryDate(value.field_teacher_expiry_date, value.field_teacher_adult_reminder, 'field_teacher_expiry_date_adult');
          });
        }
      }
    }

    setCalendarDates(oldArray => [...allReminderDates, ...oldArray]);
    await caBtUserReminders()
    setShowLoader(false);
  }

  const caBtUserReminders = async () => {
    let reminders = await getCaBTRemindersList()
    let allReminderDates = []

    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          reminderData.forEach(element => {
            const expiryDate = element[`field_${fieldName}`];
            const courseReminder = element[`field_${courseName.toLowerCase()}_course_reminder`];

            if (expiryDate !== '' && expiryDate !== undefined && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const courseCompletionDate = getDayMonthDay(finalDate);
              const obj = { className: 'orangeDay', ...courseCompletionDate };
              allReminderDates.push(obj);

              if (expiryDate !== '') {
                const originalExpiryDate = new Date(expiryDate);
                const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
                const originalObj = { className: 'orangeDay', ...originalExpiryDateObj };
                allReminderDates.push(originalObj);
              }
            }
          });
        }
        const courses = ['first_aid', 'CPR'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));

        function processRemainderExpiry(reminderData, fieldName, reminderField) {
          reminderData.forEach(element => {
            const expiryDate = element?.[fieldName];
            const courseReminder = element?.[reminderField];
            if (expiryDate && courseReminder) {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const courseCompletionDate = getDayMonthDay(finalDate);
              const obj = { className: 'orangeDay', ...courseCompletionDate };
              allReminderDates.push(obj);
            }
            if (expiryDate) {
              const originalExpiryDate = new Date(expiryDate);
              const originalExpiryDateObj = getDayMonthDay(originalExpiryDate);
              const originalObj = { className: 'orangeDay', ...originalExpiryDateObj };
              allReminderDates.push(originalObj);
            }
          });
        }

        processRemainderExpiry(reminders.data.data, 'field_blue_card_expiry_date', 'field_educator_reminder');
        processRemainderExpiry(reminders.data.data, 'field_teacher_expiry_date', 'field_teacher_reminder');

        setCalendarDates(oldArray => [...allReminderDates, ...oldArray]);
      }
    }
  }

  async function personalCalender() {
    try {
      setShowLoader(true);
      setSelectedTab(2);

      let allDatesArray = [];
      let _remindersArray = [];

      const user_name = window.localStorage.getItem('name');
      const uid = window.localStorage.getItem('uid');

      const processContent = async (contentType, dateField, customFunction) => {
        const contentArray = await getParticularUserContentCustom(contentType, user_name);
        if (contentArray.data?.data?.length > 0) {
          for (const mydata of contentArray.data.data) {
            const fieldDate = mydata[dateField]?.[0]?.value || null;
            if (fieldDate) {
              const contentDate = getDayMonthDay(fieldDate);
              allDatesArray.push({ className: 'greenDay', ...contentDate });
              customFunction(mydata, fieldDate);
            }
          }
        }
      };

      const processReminder = (courseType, expiryDate, frequency) => {
        if (expiryDate && frequency) {
          let d = new Date(expiryDate);
          if (['8 Weeks', '6 Weeks', '4 Weeks', '2 Weeks', '1 Week'].includes(frequency)) {
            d.setDate(d.getDate() - weeksIntoDays(frequency));
          } else {
            d.setDate(d.getDate() - 1);
          }
          const final_date = d.toISOString().split('T')[0];
          const custom_obj = {
            [`${courseType}_reminder_date`]: final_date,
            course_name: `${courseType} Course`,
            [`${courseType}_reminder`]: frequency
          };
          const current_obj = {
            [`${courseType}_reminder_date_update`]: expiryDate,
            course_name: `${courseType} Course`,
            reminder: 'today'
          };
          const completion_date = getDayMonthDay(final_date);
          const obj = { className: 'greenDay', ...completion_date };
          const currentDate = getDayMonthDay(expiryDate);
          const current = { className: 'greenDay', ...currentDate };
          allDatesArray.push(obj, current);
          _remindersArray.push(custom_obj, current_obj);
        }
      };

      await processContent('task', 'field_date', () => { });
      await processContent('visits', 'field_date', () => { });

      const reminders = await getReminders(uid, 'head_office_team');
      if (reminders.data?.data?.length > 0) {
        const data = reminders.data.data[0];
        processReminder('first_aid', data.field_first_expiry_date, data.field_first_aid_course_reminder);
        processReminder('cpr', data.field_cpr_course_expiry_date, data.field_cpr_course_reminder);
        // processReminder('asthma', data.field_asthma_course_expiry, data.field_asthma_course_reminder);
        // processReminder('anaphylaxis', data.field_anaphylaxis_course_expiry, data.field_anaphylxis_course_reminder);
        processReminder('blue_card', data.field_blue_card_expiry_date, data.field_blue_card_frequency);
        setRemindersArray(_remindersArray);
      }

      setCalendarDates(allDatesArray);
    } finally {
      setShowLoader(false);
    }
  }

  const selectedReminder = (value, e) => {
    e.preventDefault()
    setShowLoader(true)
    let reminderCount = 0
    if (calendarReminderEdu.length) {
      calendarReminderEdu.forEach((reminder) => {
        if (reminder.reminderName.includes(value)) {
          reminderCount++;
        }
      })
    }
    setCalendarReminderAdult({ value, reminderCount })
    setAllEduReminder(value)
    setShowLoader(false)
  }
  // Sorting for reminder type for all educator
  const SortByAtoZ = (e, val) => {
    e.preventDefault()
    setAtoZSort(val)
  }
  if (atozSort === 'AtoZ') {
    calendarReminderEdu && calendarReminderEdu.sort((a, b) => (a.reminderName > b.reminderName) ? 1 : -1)
  } else if (atozSort === 'ZtoA') {
    calendarReminderEdu && calendarReminderEdu.sort((a, b) => (a.reminderName < b.reminderName) ? 1 : -1)
  }

  function checkIfOldDate(oldDate, calendarDate) {
    // console.log(oldDate, calendarDate);
    let rem_date = getDayMonthDay(oldDate);
    const currentDate = getDayMonthDay(calendarDate);

    var date1 = moment(Object.values(rem_date).join('-'), "YYYY-MM-DD");
    var date2 = moment(Object.values(currentDate).join('-'), "YYYY-MM-DD");
    var days = date1.diff(date2, 'days')
    if (days < 0) {
      return date1.from(date2);
    }
    return null;
  }


  async function calendarHandler(date, event) {
    const selectedDate = date;
    const dayString = String(selectedDate.day).padStart(2, '0');
    const monthString = String(selectedDate.month).padStart(2, '0');
    const formattedDate = `${selectedDate.year}-${monthString}-${dayString}`;
    setOpen(true)
    setShowLoader(true)
    setShowUsername([])
    setCalendarTasks([])
    setCalendarVisits([])
    setCalendarReminderEdu([])
    setCalendarReminderCabt([])

    var uid = window.localStorage.getItem('uid')
    var month = date.month < 10 ? '0' + date.month : date.month;
    var day = date.day < 10 ? '0' + date.day : date.day;

    if (selectedTab === 1) {
      let fullYear = date.year + "-" + month + "-" + day

      let taskListing = await getCalendarTasksDataGlobally(fullYear);
      let visitListing = await getCalendarVisitsDataGlobally(fullYear)
      let reminderListing = await getRemindersGlobal(fullYear)
      let reminderAdultList = await getReminderOfAdultOccupants(fullYear)
      let reminderAll = await getReminderEdu()
      let reminderAdultAll = await getReminderOfAdultEdu()
      await newRemindersDetails(fullYear)

      if (taskListing.status === 200 && taskListing.data.data.length > 0) {
        let newList = taskListing.data.data.filter(item => item.uid[0].target_id === parseInt(uid));

        let tasksArray = newList.map(taskList => {
          let obj = {
            title: taskList.title?.[0]?.value || null,
            nid: taskList.nid?.[0]?.value || null
          };
          return obj;
        });

        setCalendarTasks(tasksArray);
      }

      if (visitListing.status === 200 && visitListing.data.data.length > 0) {
        let visitsArray = [];
        let visitNames = [];
        for (let visitList of visitListing.data.data) {
          let obj = {
            title: visitList.title?.[0]?.value || null,
            nid: visitList.nid?.[0]?.value || null,
            target_id: visitList.field_visitor?.[0]?.target_id || null,
          };
          if (obj.target_id) {
            try {
              const emailData = await getUpcomingMandS(obj.target_id);
              if (emailData?.status === 200) {
                let fullName =
                  emailData.data.data.field_first_name[0]?.value +
                  " " +
                  emailData.data.data.field_last_name[0]?.value;
                visitNames.push(fullName);
              }
            } catch (error) {
              console.error("Error fetching email data:", error);
            }
          }
          visitsArray.push(obj);
        }
        setCalendarVisits(visitsArray);
        setmAnds(visitNames);
        setShowLoader(false);
      }

      // let reminderArray = [];
      setAllEduReminder('All')
      setAtoZSort('AtoZ')

      if (reminderListing.status === 200 && reminderListing.data.data.length > 0) {
        const reminderArray = [];
        for (let reminderList of reminderListing.data.data) {
          const firstName = reminderList.field_first_name.length ? decode(reminderList.field_first_name) : '';
          const lastName = reminderList.field_last_name.length ? decode(reminderList.field_last_name) : '';
          const eduName = `${firstName} ${lastName}`;

          const checkAndPushReminder = (field, reminderText) => {
            if (fullYear === reminderList[field]) {
              reminderArray.push({ eduName, reminderName: reminderText });
            }
          };
          checkAndPushReminder('field_business_expiry_date', 'Annual Assessment expires today');
          checkAndPushReminder('field_registration_expiry_date', 'Vehicle Registration expires today');
          checkAndPushReminder('field_licence_expiry_date', 'Drivers Licence expires today');
          checkAndPushReminder('field_vehicle_expiry_date', 'Vehicle Maintenance expires today');
          checkAndPushReminder('field_vehicle_restraint_expiry', 'Vehicle Child/Restraint expires today');
          checkAndPushReminder('field_first_aid_expiry_date', 'First Aid expires today');
          checkAndPushReminder('field_cpr_expiry_date', 'CPR expires today');
          checkAndPushReminder('field_insurance_expiry_date', 'Public Insurance expires today');
          checkAndPushReminder('field_blue_card_expiry_date', 'Educator Blue Card Expiry Date expires today');
          checkAndPushReminder('field_teacher_expiry_date', 'Educator Teachers Registration expires today');
          checkAndPushReminder('field_pool_safety_cert_exp_date', 'Pool Safety Certificate expires today');
          // checkAndPushReminder('field_asthma_expiry_date', 'Asthma expires today');
          // checkAndPushReminder('field_anaphylaxis_expiry_date', 'Anaphylaxis expires today');
        }
        // setCalendarReminderEdu(reminderArray);
        setCalendarReminderEdu(oldArray => [...reminderArray, ...oldArray]);

        setShowLoader(false);
      }
      let reminderExpired = [];

      if (reminderAll.status === 200 && reminderAll.data.data.length > 0) {
        reminderAll.data.data.forEach((reminderFilter) => {
          let firstName = reminderFilter.field_first_name.length ? decode(reminderFilter.field_first_name) : '';
          let lastName = reminderFilter.field_last_name.length ? decode(reminderFilter.field_last_name) : '';
          let eduName = firstName + ' ' + lastName;
          if (firstName && lastName) {
            const checkAndPushReminder = (field, message) => {
              let fieldValue = checkIfOldDate(reminderFilter[field], fullYear);
              if (fieldValue && reminderFilter[field]) {
                reminderExpired.push({
                  eduName,
                  reminderName: `${message} ${fieldValue}`,
                  // reminderName: `${message} ${fieldValue} (${reminderFilter[field]})`
                });
              }
            };

            checkAndPushReminder('field_blue_card_expiry_date', 'Educator Blue Card Expiry Date expires');
            checkAndPushReminder('field_business_expiry_date', 'Annual Assessment expires');
            checkAndPushReminder('field_first_aid_expiry_date', 'First Aid expires');
            // checkAndPushReminder('field_asthma_expiry_date', 'Asthma expires');
            checkAndPushReminder('field_cpr_expiry_date', 'CPR expires');
            // checkAndPushReminder('field_anaphylaxis_expiry_date', 'Anaphylaxis expires');
            checkAndPushReminder('field_registration_expiry_date', 'Vehicle Registration expires');
            checkAndPushReminder('field_vehicle_restraint_expiry', 'Vehicle Child/Restraint expires');
            checkAndPushReminder('field_vehicle_expiry_date', 'Vehicle Maintenance expires');
            checkAndPushReminder('field_licence_expiry_date', 'Drivers Licence expires');
            checkAndPushReminder('field_insurance_expiry_date', 'Public Insurance expires');
            checkAndPushReminder('field_teacher_expiry_date', 'Educator Teachers Registration expires');
            checkAndPushReminder('field_pool_safety_cert_exp_date', 'Pool Safety Certificate expires');
          }
        });

        setCalendarReminderEdu(oldArray => [...reminderExpired, ...oldArray]);
      }

      if (reminderAdultList.status === 200 && reminderAdultList.data.data.length > 0) {
        const reminderArrays = reminderAdultList.data.data.map(reminderList => {
          let reminderName = '';
          let firstName = reminderList.field_first_name.length ? decode(reminderList.field_first_name) : '';
          let lastName = reminderList.field_last_name.length ? decode(reminderList.field_last_name) : '';
          let eduName = `${firstName} ${lastName}`;
          let adultName = `${reminderList.field_occupant_first_name} ${reminderList.field_occupant_last_name}`;
          if (fullYear === reminderList.field_occupant_card_expiry_date) {
            reminderName = 'Adult Occupant Blue Card expires today';
          } else if (fullYear === reminderList.field_teacher_expiry_date) {
            reminderName = 'Adult Occupant Teachers Registration expires today';
          }
          return {
            eduName,
            reminderName,
            adultName
          };
        });
        setCalendarReminderEdu(oldArray => [...reminderArrays, ...oldArray]);
        setShowLoader(false);
      }

      if (reminderAdultAll.status === 200 && reminderAdultAll.data.data.length > 0) {
        const reminderArrays = reminderAdultAll.data.data
          .filter(reminderFilterAdult => reminderFilterAdult.field_first_name.length || reminderFilterAdult.field_last_name.length)
          .map(reminderFilterAdult => {
            let firstName = reminderFilterAdult.field_first_name.length ? decode(reminderFilterAdult.field_first_name) : '';
            let lastName = reminderFilterAdult.field_last_name.length ? decode(reminderFilterAdult.field_last_name) : '';
            let eduName = `${firstName} ${lastName}`;
            let adultName = '';
            if (firstName && lastName) {
              let field_occupant_card_expiry_date = checkIfOldDate(reminderFilterAdult.field_occupant_card_expiry_date, fullYear);
              let field_teacher_expiry_date = checkIfOldDate(reminderFilterAdult.field_teacher_expiry_date, fullYear);

              if (field_occupant_card_expiry_date) {
                adultName = `${reminderFilterAdult.field_occupant_first_name} ${reminderFilterAdult.field_occupant_last_name}`;
                return {
                  eduName,
                  reminderName: `Adult Occupant Blue Card expires ${field_occupant_card_expiry_date}`,
                  // reminderName: `Adult Occupant Blue Card expires ${field_occupant_card_expiry_date} - (${reminderFilterAdult.field_occupant_card_expiry_date})`, 
                  adultName
                };
              }

              if (field_teacher_expiry_date) {
                adultName = `${reminderFilterAdult.field_occupant_first_name} ${reminderFilterAdult.field_occupant_last_name}`;
                return {
                  eduName,
                  reminderName: `Adult Occupant Teachers Registration expires ${field_teacher_expiry_date}`,
                  // reminderName: `Adult Occupant Teachers Registration expires ${field_teacher_expiry_date} - (${reminderFilterAdult.field_teacher_expiry_date})`, 
                  adultName
                };
              }
            }
            return null;
          })
          .filter(Boolean);
        setCalendarReminderEdu(oldArray => [...reminderArrays, ...oldArray]);
      }
    }

    if (selectedTab === 2) {
      setCalendarTasks([])
      let fullYear = date.year + "-" + month + "-" + day
      let taskListing = await getTaskListingForHeadofficeAsPerDate(fullYear, uid);
      let visitListing = await getVisitListingForHeadofficeAsPerDate(fullYear, uid);
      if (taskListing.status === 200 && taskListing.data.data.length > 0) {
        const tasksArray = taskListing.data.data.map(taskList => {
          const obj = {
            title: taskList.title?.[0]?.value || null,
            nid: taskList.nid?.[0]?.value || null
          };
          return obj;
        });
        setCalendarTasks(tasksArray);
      }
      if (visitListing.status === 200 && visitListing.data.data.length > 0) {
        const visitsArray = visitListing.data.data.map(visitList => {
          const obj = {
            title: visitList.title?.[0]?.value || null,
            nid: visitList.nid?.[0]?.value || null
          };
          return obj;
        })
        setCalendarVisits(visitsArray);
      }
      let calendarRemindersArray = [];
      const reminderTypes = [
        { type: 'first_aid', field: 'first_aid_reminder_date' },
        { type: 'cpr_course', field: 'cpr_course_reminder_date' },
        { type: 'field_blue_card', field: 'field_blue_card_expiry_date' },
        { type: 'field_blue_card_updated', field: 'field_blue_card_expiry_date_updated' },
        { type: 'cpr_course_update', field: 'cpr_course_reminder_date_update' },
        { type: 'first_aid_update', field: 'first_aid_reminder_date_update' },
      ];

      if (remindersArray.length > 0) {
        for (let reminder of remindersArray) {
          for (let reminderType of reminderTypes) {
            const dateField = reminder[reminderType.field];
            if (fullYear === dateField) {
              let obj = {};
              obj['course_name'] = `${reminder.course_name} expires`;
              obj['weekly_timings'] = reminder.reminder;
              calendarRemindersArray.push(obj);
              break; // Exit the loop once a match is found
            }
          }
        }
      }
      setCalendarReminderNotices(calendarRemindersArray);
    }
    setShowLoader(false)
    const usernames = await getUserBirthdayByDate(formattedDate)
    setShowUsername(usernames)
  }

  const newRemindersDetails = async (fullYear) => {
    caBTReminderDetails(fullYear)
    setShowLoader(true)
    let reminders = await getReminderEduNewApi();
    let remindersAdult = await getReminderOfAdultEduNew();
    // let reminders = await getRemindersGlobal();
    // let remindersAdult = await getReminderOfAdultOccupants();
    let allReminderDates = []

    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element[`field_${fieldName}`];
            const courseReminder = element[`field_${courseName.toLowerCase()}_course_reminder`];

            if (expiryDate !== '' && expiryDate !== undefined && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              let reminderText =`${courseName === 'first_aid' ? 'First Aid' : 'CPR'} expires in ${courseReminder}`
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
              // allReminderDates.push(obj);
            }
          });
        }
        const courses = ['first_aid', 'CPR'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));
        function processLicenseExpiry(reminders) {
          reminders.data?.data?.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const licenseExpiryDate = element?.field_licence_expiry_date;
            const licenseReminderDate = element?.field_license_exp_reminder_date;
            if (licenseExpiryDate !== '' && licenseReminderDate !== '') {
              const d = new Date(licenseExpiryDate);
              let reminderDays = 0;

              switch (licenseReminderDate) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(licenseReminderDate);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }
              let reminderText = `Vehicle Licence expires in ${licenseReminderDate}`
              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          });
        }

        processLicenseExpiry(reminders);

        function processRemainderExpiry(reminderData, expiry, reminder, message) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element?.[expiry];
            const courseReminder = element?.[reminder];
            if (expiryDate !== '' && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }
              let reminderText = `${message} in ${courseReminder}`
              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          })
        }

        processRemainderExpiry(reminders.data.data, 'field_registration_expiry_date', 'field_reg_exp_reminder_date', 'Registration expires');
        processRemainderExpiry(reminders.data.data, 'field_insurance_expiry_date', 'field_public_reminder_date', "Public Insurance expires");
        processRemainderExpiry(reminders.data.data, 'field_pool_safety_cert_exp_date', 'field_pool_safety_cert_set_remin', "Pool Safety Certification expires");
        processRemainderExpiry(reminders.data.data, 'field_vehicle_expiry_date', 'field_restraint_audit_reminder', 'Vehicle expires');
        processRemainderExpiry(reminders.data.data, 'field_vehicle_restraint_expiry', 'field_maintenance_reminder_date', 'Vehicle Child/Restraint expires');
        processRemainderExpiry(reminders.data.data, 'field_business_expiry_date', 'field_set_reminder_frequency', 'Annual Assessment of Residence expires');

        function processEducatorRemainderExpiry(reminderData) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element?.[`field_blue_card_expiry_date`];
            const courseReminder = element?.[`field_educator_reminder`];
            if (expiryDate !== '' && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              let reminderText = `Educator Blue Card expires in ${courseReminder}`
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          })
        }
        processEducatorRemainderExpiry(reminders.data.data)
        function processTeacherRemainderExpiry(reminderData) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element?.[`field_teacher_expiry_date`];
            const courseReminder = element?.[`field_teacher_reminder`];
            if (expiryDate !== '' && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }
              let reminderText = `Educator Teacher Registration expires in ${courseReminder}`
              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          })
        }
        processTeacherRemainderExpiry(reminders.data.data)

        if (remindersAdult.status === 200) {
          remindersAdult && remindersAdult.data.data.forEach((value) => {
            const firstName = value.field_first_name.length ? decode(value.field_first_name) : '';
            const lastName = value.field_last_name.length ? decode(value.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const processExpiryDate = (expiryDate, reminderType, type) => {
              if (expiryDate !== '' && reminderType !== '') {
                let d = new Date(expiryDate);
                if (['8 Weeks', '6 Weeks', '4 Weeks', '2 Weeks', '1 Week'].includes(reminderType)) {
                  d.setDate(d.getDate() - weeksIntoDays(reminderType));
                } else if (reminderType === '1 Day') {
                  d.setDate(d.getDate() - 1);
                } else {
                  d.setDate(d.getDate());
                }
                // const finalDate = d.getFullYear() + '-' + month(d) + '-' + custom_day(d);
                // let reminderText = `Adult Occupant Card (${value.field_occupant_first_name} ${value.field_occupant_last_name}) expires in ${reminderType}`

                const finalDate = new Date(d);
                const finalDate1 = finalDate.toISOString().split('T')[0]
                if (fullYear === finalDate1) {
                  if (type === 'field_occupant_card_expiry_date') {
                    let adultName = `${value.field_occupant_first_name} ${value.field_occupant_last_name}`;
                    allReminderDates.push({
                      eduName,
                      reminderName: `Adult Occupant Blue Card expires in ${reminderType}`,
                      adultName
                    })
                  }

                  if (type === 'field_teacher_expiry_date') {
                    let adultName = `${value.field_occupant_first_name} ${value.field_occupant_last_name}`;
                    allReminderDates.push({
                      eduName,
                      reminderName: `Adult Occupant Teachers Registration expires in ${reminderType}`,
                      adultName
                    })
                  }
                  // allReminderDates.push({ eduName, reminderName: reminderText });
                }

              }
            };
            processExpiryDate(value.field_occupant_card_expiry_date, value.field_reminder_blue_card, 'field_occupant_card_expiry_date');
            processExpiryDate(value.field_teacher_expiry_date, value.field_teacher_adult_reminder, 'field_teacher_expiry_date');
          });
        }
      }
    }

    setCalendarReminderEdu(oldArray => [...allReminderDates, ...oldArray]);
    setShowLoader(false);
  }

  const caBTReminderDetails = async (fullYear) => {
    let reminders = await getCaBTRemindersList();
    let allReminderDates = []

    if (reminders.data) {
      if (reminders.data.data.length > 0) {

        function processExpiry(reminderData, fieldName, courseName) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element[`field_${fieldName}`];
            const courseReminder = element[`field_${courseName.toLowerCase()}_course_reminder`];

            if (expiryDate !== '' && expiryDate !== undefined && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              let reminderText = `${courseName === 'first_aid' ? 'First Aid' : 'CPR'} expires in ${courseReminder}`
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
              if (fullYear === expiryDate){
                reminderText = `${courseName === 'first_aid' ? 'First Aid' : 'CPR'} expires today`
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          });
        }
        const courses = ['first_aid', 'CPR'];
        courses.forEach(course => processExpiry(reminders.data.data, `${course.toLowerCase()}_expiry_date`, course));

        function processTeacherRemainderExpiry(reminderData) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element?.[`field_teacher_expiry_date`];
            const courseReminder = element?.[`field_teacher_reminder`];
            if (expiryDate !== '' && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }
              let reminderText = `Cabt Teacher Registration expires in ${courseReminder}`
              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
              if (fullYear === expiryDate) {
                reminderText = `Cabt Teacher Registration expires today`
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          })
        }
        processTeacherRemainderExpiry(reminders.data.data)

        function processEducatorRemainderExpiry(reminderData) {
          reminderData.forEach(element => {
            const firstName = element.field_first_name.length ? decode(element.field_first_name) : '';
            const lastName = element.field_last_name.length ? decode(element.field_last_name) : '';
            const eduName = `${firstName} ${lastName}`;
            const expiryDate = element?.[`field_blue_card_expiry_date`];
            const courseReminder = element?.[`field_educator_reminder`];
            if (expiryDate !== '' && courseReminder !== '') {
              const d = new Date(expiryDate);
              let reminderDays = 0;

              switch (courseReminder) {
                case '8 Weeks':
                case '6 Weeks':
                case '4 Weeks':
                case '2 Weeks':
                case '1 Week':
                  reminderDays = weeksIntoDays(courseReminder);
                  d.setDate(d.getDate() - reminderDays);
                  break;
                case '1 Day':
                  reminderDays = d.setDate(d.getDate() - 1);
                  break;
                default:
                  break;
              }

              const finalDate = new Date(d);
              const finalDate1 = finalDate.toISOString().split('T')[0]
              let reminderText = `Cabt Blue Card expires in ${courseReminder}`
              if (fullYear === finalDate1) {
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
              if (fullYear === expiryDate){
                reminderText = `Cabt Blue Card expires today`
                allReminderDates.push({ eduName, reminderName: reminderText });
              }
            }
          })
        }
        processEducatorRemainderExpiry(reminders.data.data)



        // setCalendarReminderCabt(allReminderDates)
      }
    }
    setCalendarReminderCabt(allReminderDates);
  }

  function _onDateFormat(date) {
    if (!date) {
      return ""
    }

    let newDate = date.split('-')
    let day = newDate[2].split(' 00:00:00')
    return `${day[0]}-${newDate[1]}-${newDate[0]}`
  }
  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      <HeadOfficeMainHeader />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="row">
                    <div className="col text-center">
                      <button onClick={applyForLeave} className="mdc-button mdc-button--raised">Leave</button>
                    </div>
                    <div className="col text-center">
                      <button onClick={archiveLeave} className="mdc-button mdc-button--raised">Archive</button>
                    </div>
                    <div className="col text-center">
                      <button onClick={current} className="mdc-button mdc-button--raised">Current</button>
                    </div>
                    <div className="col text-center">
                      <button onClick={allLeavesTab} className="mdc-button mdc-button--raised" style={{ marginTop: "12px", float: "left", marginLeft: "12px" }}>All Leave</button>
                    </div>
                    <div className="col text-center">
                      <button onClick={filterLeave} className="mdc-button mdc-button--raised" style={{ marginTop: "12px", float: "left", marginLeft: "-62px" }}>Filter</button>
                    </div>
                  </div>

                  <TableContainer className="educators-leaves-listing">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Educator Name</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isArchive === false ? (
                          allLeaves.length > 0 ? allLeaves.map((leave, index) => (
                            <TableRow key={index}>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.start_date}</Moment></TableCell>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.end_date}</Moment></TableCell>
                              <TableCell><h3>{leave.firstName + " " + leave.lastName}</h3></TableCell>
                              {leave.uid === cordinatorUserID ? <TableCell>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                  <span onClick={() => applyForLeave(leave, true)} style={{ cursor: "pointer" }} className="material-icons">
                                    edit
                                  </span>
                                  <span onClick={() => confirmDeleteLeaves(leave.id)} style={{ cursor: "pointer" }} className="material-icons">
                                    delete
                                  </span>
                                </div>
                              </TableCell> : ""}
                            </TableRow>

                          )) :
                            (<TableRow>
                              <TableCell><h2>NO</h2></TableCell>
                              <TableCell><h2>RECORD</h2></TableCell>
                              <TableCell><h2>FOUND !! </h2></TableCell>
                            </TableRow>)


                        ) : (
                          allLeaves.length > 0 ? allLeaves.map((leave, index) => (
                            <TableRow key={index}>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.start_date}</Moment></TableCell>
                              <TableCell><Moment format="DD-MM-YYYY">{leave.end_date}</Moment></TableCell>
                              <TableCell><h3>{leave.firstName + " " + leave.lastName}</h3></TableCell>
                            </TableRow>

                          )) : (<TableRow>
                            <TableCell><h2>NO</h2></TableCell>
                            <TableCell><h2>RECORD</h2></TableCell>
                            <TableCell><h2>FOUND !! </h2></TableCell>
                          </TableRow>)
                        )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>

              <div className="col-md-8">
                <div className="content-block-right">
                  <div className="row">
                    <div class="col-md-12 text-center calender-top-button">
                      <button className={selectedTab === 1 ? "mdc-button mdc-button--raised" : "mdc-button"} onClick={globalCalenderData}>WFDC </button>
                      <button className={selectedTab === 2 ? "mdc-button mdc-button--raised" : "mdc-button"} onClick={personalCalender}>Personal</button>
                    </div>
                  </div>
                  <div className="calender-block full-width-calender">
                    <Calendar customDaysClassName={calendarDates} onChange={calendarHandler} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)} center>
        <div className="main-wrapper">
          <div className="container">
            <div className="dashboard-wrap no-tabs">
              <div className="calender-popup calender-popup-with-tabs">
                <div className='c-box-parent'>
                  <h2 className='reminder-dashboard-popup'>Alerts</h2>

                  <div className='c-tab tab1 mb-3'>

                    <p className='reminder-popup-subheading'>User's Birthday</p>
                    <div>
                      {showUsername.length > 0 ? (
                        showUsername.map((username, index) => (
                          <div key={index}>
                            <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Users : {username}</span>
                          </div>
                        ))
                      ) : (
                        <div>
                          <span style={{ fontWeight: 'bold', fontSize: '17px' }}>No User's Birthday</span>
                        </div>
                      )}
                    </div>
                    <br />
                  </div>
                  {
                    selectedTab === 1 ?

                      <div className='c-tab tab1 mb-3'>



                        <div className="reminder-sort">
                          <span> Sort By Reminder Type : </span>
                          <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={atozSort}
                          >
                            <MenuItem value="AtoZ" onClick={(e) => SortByAtoZ(e, 'AtoZ')}>A to Z</MenuItem>
                            <MenuItem value="ZtoA" onClick={(e) => SortByAtoZ(e, 'ZtoA')}>Z to A</MenuItem>
                          </Select>
                        </div>
                        <div className="reminder-group" style={{ marginBottom: 10 }}>
                          <span>  Group By : </span>
                          <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={allEduReminder}>
                            <MenuItem value="All" onClick={(e) => selectedReminder("All", e)}>All</MenuItem>
                            <MenuItem value="Annual Assessment expires" onClick={(e) => selectedReminder("Annual Assessment expires", e)}>Annual Assessment expires</MenuItem>
                            <MenuItem value="Vehicle Registration expires" onClick={(e) => selectedReminder("Vehicle Registration expires", e)}>Vehicle Registration expires</MenuItem>
                            <MenuItem value="Drivers Licence expires" onClick={(e) => selectedReminder("Drivers Licence expires", e)}>Drivers Licence expires</MenuItem>
                            <MenuItem value="Vehicle Maintenance expires" onClick={(e) => selectedReminder("Vehicle Maintenance expires", e)}>Vehicle Maintenance expires</MenuItem>
                            <MenuItem value="Vehicle Child/Restraint expires" onClick={(e) => selectedReminder("Vehicle Child/Restraint expires", e)}>Vehicle Child/Restraint expires</MenuItem>
                            {/* <MenuItem value="Asthma expires" onClick={(e) => selectedReminder("Asthma expires", e)}>Asthma expires</MenuItem> */}
                            <MenuItem value="First Aid expires" onClick={(e) => selectedReminder("First Aid expires", e)}>First Aid expires</MenuItem>
                            <MenuItem value="CPR expires" onClick={(e) => selectedReminder("CPR expires", e)}>CPR expires</MenuItem>
                            {/* <MenuItem value="Anaphylaxis expires" onClick={(e) => selectedReminder("Anaphylaxis expires", e)}>Anaphylaxis expires</MenuItem> */}
                            <MenuItem value="Public Insurance expires" onClick={(e) => selectedReminder("Public Insurance expires", e)}>Public Insurance expires</MenuItem>
                            <MenuItem value="Adult Occupant Blue Card expires" onClick={(e) => selectedReminder("Adult Occupant Blue Card expires", e)}>Adult Occupant Blue Card expires</MenuItem>
                            <MenuItem value="Adult Occupant Teachers Registration expires" onClick={(e) => selectedReminder("Adult Occupant Teachers Registration expires", e)}>Adult Occupant Teachers Registration expires</MenuItem>
                            <MenuItem value="Educator Blue Card expires" onClick={(e) => selectedReminder("Educator Blue Card expires", e)}>Educator Blue Card expires</MenuItem>
                            <MenuItem value="Educator Teachers Registration expires" onClick={(e) => selectedReminder("Educator Teachers Registration expires", e)}>Educator Teachers Registration expires</MenuItem>
                          </Select>
                        </div>

                        <p className='reminder-popup-subheading'>Reminders Expiring Today</p>
                        {/* {console.log(insuranceExpires,'==================')} */}
                        {/* {
												insuranceExpires && insuranceExpires.length > 0 ? (
													<ul>
													{insuranceExpires.map((reminder, index) => (
														<div key={index}>
														<h2 style={{fontSize: '18px',lineHeight: '24px',color: '#000',fontWeight: '600'}}>
														Educator Name: {reminder?.eduName}<br/>
														Reminder Name:	{reminder?.reminderLiablityInsurance}
														</h2>
														</div>
													))}
													</ul>
												) : (
													<h2 style={{fontSize: '18px',lineHeight: '24px',color: '#000',fontWeight: '600'}}>No Alerts</h2>
												)
												} */}



                        <div className='c-content'>
                          <div className='course-detail-last pb-25'>
                            <ul style={{ width: '100%' }}>
                              {
                                allEduReminder === "All" ?
                                  <>
                                    {
                                      calendarReminderEdu.length > 0 ? calendarReminderEdu.map((reminder, index) => (
                                        <li key={index}>
                                          <h2>Educator Name : {reminder.eduName} <br></br> Reminder Name: {reminder.reminderName}
                                            {reminder.adultName ? <h2>Adult Occupant Full Name : {reminder.adultName}</h2> : null}</h2>
                                        </li>
                                      ))  : null
                                      // : <h2>No Alerts</h2>
                                    }
                                    {
                                      calendarReminderCabt.length > 0 ? calendarReminderCabt.map((reminder, index) => (
                                        <li key={index}>
                                          <h2>CaBT Name : {reminder.eduName} <br></br> Reminder Name: {reminder.reminderName}
                                            {reminder.adultName ? <h2>Adult Occupant Full Name : {reminder.adultName}</h2> : null}</h2>
                                        </li>
                                      )) : null
                                    }
                                    {
                                      calendarReminderEdu.length === 0 && calendarReminderCabt.length === 0 ? 
                                        <h2>No Alerts</h2> : null
                                    }
                                  </> :
                                  <>
                                    {/* {console.log({calendarReminderEdu, calendarReminderAdult})} */}
                                    {
                                      calendarReminderAdult && calendarReminderAdult && calendarReminderAdult.reminderCount === 0 ?
                                        <h2>not found</h2>
                                        :
                                        calendarReminderEdu.length > 0 ? calendarReminderEdu.map((reminder, index) => (
                                          <li key={index}>
                                            {
                                              reminder.reminderName.includes(calendarReminderAdult.value) &&
                                              <h2>
                                                Educator Name : {reminder.eduName} <br></br> Reminder Name: {reminder.reminderName}
                                                {reminder.adultName ? <h2>Adult Occupant Full Name : {reminder.adultName}</h2> : null}
                                              </h2>
                                            }
                                          </li>
                                        )) : <h2>No Alerts</h2>
                                    }
                                  </>
                              }
                            </ul>
                          </div>
                        </div>
                      </div> :

                      <div className='c-tab tab1 mb-3'>
                        <p className='reminder-popup-subheading'>Reminders</p>
                        <div className='c-content'>
                          <div className='course-detail-last pb-25'>
                            <ul style={{ width: '100%' }}>
                              <>
                                {
                                  calendarReminderNotices.length > 0 ? calendarReminderNotices.map((reminder, index) => (
                                    <li key={index}>
                                      <h2>{reminder.course_name} {reminder.weekly_timings}</h2>
                                    </li>
                                  )) : <h2>No Alerts</h2>
                                }
                              </>
                            </ul>
                          </div>
                        </div>
                      </div>
                  }


                  <div className='c-tab tab2 mb-3'>
                    <p className='reminder-popup-subheading'> M&S </p>
                    <div className='c-content'>
                      <ul style={{ width: '100%' }}>
                        <>
                          {
                            calendarVisits.length > 0 ? calendarVisits.map((visit, index) => (
                              <li className="list-content" key={index}>
                                {/* Temporarily Added untill API is done  */}
                                <div><h2>Users : {mAnds[index] ? mAnds[index] : "NONE"}</h2></div>
                                <div>
                                  <Link to={`/m&s-detail-page/${visit.nid}`} className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">View details</span>
                                  </Link>
                                </div>
                              </li>
                            )) : <h2>No Alerts</h2>
                          }
                        </>
                      </ul>
                    </div>
                  </div>
                  <div className='c-tab tab3 mb-3'>
                    <p className='reminder-popup-subheading'>Tasks</p>
                    <div className='c-content'>
                      <ul style={{ width: '100%' }}>
                        <>
                          {
                            calendarTasks.length > 0 ? calendarTasks.map((task, index) => (
                              <li className="list-content" key={index}>
                                <div><h2>{task.title}</h2></div>
                                <div>
                                  <Link to={`/task-detail-page/${task.nid}`} className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">View details</span>
                                  </Link>
                                </div>
                              </li>
                            )) : <h2>No Alerts</h2>
                          }
                        </>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {



        <Modal open={leaveModal} onClose={() => setLeaveModal(false)} center>
          <FormControlLabel value="onedayleave" label="One Day Leave" control={<Radio value="onedayleave" checked={selectedLeaveVal === "onedayleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'A' }} />} />
          <FormControlLabel value="multipledaysleave" label="Multiple Days Leave" control={<Radio value="multipledaysleave" checked={selectedLeaveVal === "multipledaysleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'B' }} />} />
          <>
            {selectedLeaveVal === 'onedayleave' ?
              <form onSubmit={oneDayLeaveApplicationForm}>
                <div className="row">
                  <div onClick={() => leaveCalendarClickable('onedayleave')} className="col-md-12">
                    <div className="calendar-wrap">
                      <label className="mdc-text-field mdc-text-field--outlined">
                        <input value={oneDayLeaveValue ? _onDateFormat(oneDayLeaveValue) : oneDayLeaveValue} type="text" className={oneDayLeaveValue !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span className="mdc-floating-label" id="my-label-id">{selectedLeaveVal === 'multipledaysleave' ? 'Start Date' : 'Date'}</span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">calendar_today</span>
                      </label>
                      <div>
                        {
                          oneDayLeaveCal === true ? <LeaveCalendar onChange={oneDayLeavesCalendarHandler} minDate={new Date()} value={defaultStartDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button className="mdc-button mdc-button--raised mt-40">
                  <span className="mdc-button__label">Submit</span>
                </button>
              </form>
              :
              <form onSubmit={multipleDaysLeaveApplicationForm}>
                <div className="row">
                  <div onClick={() => leaveCalendarClickable('startdate')} className="col-md-12">
                    <div className="calendar-wrap">
                      <label className="mdc-text-field mdc-text-field--outlined">
                        <input value={leaveStartDate ? _onDateFormat(leaveStartDate) : leaveStartDate} type="text" className={leaveStartDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span className="mdc-floating-label" id="my-label-id">Start Date</span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">calendar_today</span>
                      </label>
                      <div>
                        {
                          multipleDaysStartCal === true ? <LeaveCalendar onChange={multipleLeavesStartCalendarHandler} minDate={new Date()} value={defaultStartDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div onClick={() => leaveCalendarClickable('enddate')} className="col-md-12">
                    <div className="calendar-wrap">
                      <label className="mdc-text-field mdc-text-field--outlined">
                        <input value={leaveEndDate ? _onDateFormat(leaveEndDate) : leaveEndDate} type="text" className={leaveEndDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span className="mdc-floating-label" id="my-label-id">End Date</span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">calendar_today</span>
                      </label>
                      <div>
                        {
                          multipleDaysEndCal === true ? <LeaveCalendar onChange={multipleLeavesEndCalendarHandler} minDate={new Date()} value={defaultEndDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button className="mdc-button mdc-button--raised mt-40">
                  <span className="mdc-button__label" >Submit</span>
                </button>
              </form>
            }
          </>
        </Modal>}
      {/* {console.log('onee',oneDayLeaveValue)} */}


      <Modal open={filterModal} onClose={() => setFilterModal(false)} center>
        {
          !filterModal ?
            <div>
              <FormControlLabel value="onedayleave" label="One Day Leave" control={<Radio value="onedayleave" checked={selectedLeaveVal === "onedayleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'A' }} />} />
              <FormControlLabel value="multipledaysleave" label="Multiple Days Leave" control={<Radio value="multipledaysleave" checked={selectedLeaveVal === "multipledaysleave"} onChange={leaveHandler} name="radio-button-demo" inputProps={{ 'aria-label': 'B' }} />} />
            </div>
            : null
        }
        <>
          {selectedLeaveVal === 'onedayleave' ?
            <form onSubmit={oneDayLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => filterCalendarClickable('onedayleave')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={oneDayLeaveValue ? _onDateFormat(oneDayLeaveValue) : oneDayLeaveValue} type="text" className={oneDayLeaveValue !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">{selectedLeaveVal === 'multipledaysleave' ? 'Start Date' : 'Date'}</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        oneDayLeaveCal === true ? <LeaveCalendar onChange={oneDayLeavesCalendarHandler} minDate={new Date()} value={defaultStartDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <button className="mdc-button mdc-button--raised mt-40">
									<span className="mdc-button__label">Submit</span>
								</button> */}
            </form>
            :
            <form onSubmit={multipleDaysLeaveApplicationForm}>
              <div className="row">
                <div onClick={() => filterCalendarClickable('startdate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveStartDate ? _onDateFormat(leaveStartDate) : leaveStartDate} type="text" className={leaveStartDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">Start Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysStartCal === true ? <LeaveCalendar onChange={multipleLeavesStartCalendarHandler} minDate={new Date()} value={defaultStartDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div onClick={() => filterCalendarClickable('enddate')} className="col-md-12">
                  <div className="calendar-wrap">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input value={leaveEndDate ? _onDateFormat(leaveEndDate) : leaveEndDate} type="text" className={leaveEndDate !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">End Date</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">calendar_today</span>
                    </label>
                    <div>
                      {
                        multipleDaysEndCal === true ? <LeaveCalendar onChange={multipleLeavesEndCalendarHandler} minDate={new Date()} value-={defaultEndDate} style={{ color: '#ccc', pointerEvents: 'none' }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" className='mdc-button mdc-button--raised' onClick={() => checkFilterData()}>
                View Data
              </button>

              <div>
                {
                  leaveStartDate && leaveEndDate && showFilterData ?
                    showFilterLeave.length ?
                      <div>
                        <div ref={componentRef}><br></br>
                          <div>
                            <h1 className="print-only-heading centered-heading">Leaves Data</h1>
                          </div>
                          <TableContainer className="educators-leaves-listing">
                            <Table >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Start Data</TableCell>
                                  <TableCell>End Date</TableCell>
                                  <TableCell>Educator Name</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* Render the table rows */}
                                {showFilterLeave.map((item) => {
                                  // console.log({ item });
                                  let educator_name = item.firstName + " " + item.lastName;
                                  let filterStart = moment(item.start_date).format("X");
                                  let filterEnd = moment(item.end_date).format("X");
                                  return (
                                    <TableRow key={item.id}>
                                      <TableCell>{moment.unix(filterStart).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell>{moment.unix(filterEnd).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell><h3>{educator_name}</h3></TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>

                        </div><br></br>
                        <ReactToPrint
                          trigger={() => <button type='button' className='mdc-button mdc-button--raised' style={{ margin: "auto" }}>Print</button>}
                          content={() => componentRef.current}
                        />
                      </div>
                      : <div><h1>No leaves found</h1></div>
                    : null
                }

              </div>
            </form>
          }
        </>
      </Modal>
    </>
  )
}

export default HeadOfficeDashboard;