import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { convertTimeStampToTime, deleteNode } from '../../functions/api';
import './styles.css';
//Import Images
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { PaginatedList } from 'react-paginated-list';

const Lists = (props) => {

  const [data, setData] = useState([])
  const [userRole, setUserRole] = useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdownIndex, setDropdownIndex] = useState('')
  const [noDataLabel, setNoDataLabel] = useState(false)

  function getTime(timestamp) {
    if (timestamp !== "" && timestamp !== undefined) {
      let eventTime = convertTimeStampToTime(timestamp)
      return eventTime
    } else {
      let eventTime = "N/A"
      return eventTime
    }
  }

  function getDate(mydate) {
    let _date = moment(mydate).format('DD-MM-YYYY')
    return _date
  }

  async function deleteItem(item) {
    if (data.length > 0) {
      const linkData = data.filter(i => i.nid[0].value !== item)
      await setData([...linkData])
    }
  }

  async function deleteLink(linkId) {
    let response = await deleteNode(linkId);
    if (response.status === 204) {
      deleteItem(linkId)
      toast.success('Link has been deleted successfully!', {
        position: 'top-center'
      });
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteLink(linkId) {
    setAnchorEl(null);
    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLink(linkId)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  useEffect(() => {

    if (props.data !== undefined && props.data.length > 0) {
      setNoDataLabel(false)
      setData(props.data)
    }
    else {
      if (props.data.length === 0) {
        setNoDataLabel(true)
      }

      if (props.data === undefined) {
        setNoDataLabel(false)
      }
    }

    let role = window.localStorage.getItem('user_role');
    setUserRole(role)

  }, [props]);

  const handleClick = (event, index) => {
    setDropdownIndex(index)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToastContainer />
      <ul className="notice-list">

        <PaginatedList
          list={data}
          itemsPerPage={6}
          renderList={(list) => (
            <>
              {
                list.length > 0 ? list.map((listData, index) => {
                  return (
                    <li>
                      <div className="notice-info">
                        <Link to={`/link-detail-page/${listData.nid[0].value}`}>
                          <p>{listData.title.length > 0 ? listData.title[0].value : ''}</p>
                          <span>{listData.field_date.length > 0 ? getDate(listData.field_date[0].value) : ''} at {listData.field_time.length > 0 ? getTime(listData.field_time[0].value) : ""}</span>
                        </Link>

                        <>
                          {userRole === 'head_coordinator' ?

                            <>
                              <span onClick={(e) => handleClick(e, index)} className="dropdown-toggle material-icons line-icon">more_vert</span>
                              {dropdownIndex === index ?
                                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                  <Link className="editLink" to={`/edit-link/${listData.nid[0].value}`}><MenuItem>Edit</MenuItem></Link>
                                  <MenuItem onClick={() => confirmDeleteLink(listData.nid[0].value)}>Delete</MenuItem>
                                </Menu> : null
                              }
                            </>
                            : null
                          }
                        </>
                      </div>
                    </li>
                  )
                })
                  :
                  <>
                    {
                      noDataLabel === true ?
                        <label>No Records Found!!</label>
                        :
                        null
                    }
                  </>

              }

            </>
          )}
        />
      </ul>
    </>
  )
}

export default Lists;