import React, { useEffect } from "react";
import { $ } from "react-jquery-plugin";
import "./styles.css";
import { useState } from "react";
import {
  getSpecificUser,
  getUserProfileData,
  getOccupant,
  addProfessionalDevelop,
  deleteProfessioanlDevelopLogs,
  updateProfessionalProfile,
  uploadImage,
  updateUserProfileCeasedDate,
  updateProfessionalDevelopmentLog,
  upDateOccupants,
  uploadCertificatesAPI,
  updateEducatorSideDataAPI,
  getSpefificVenue,
  updateUserData,
  updateCabtProfile,
  getCabtProfessionalDevelops,
  addEmergencyContacts,
  getEmergencyConatcts,
} from "../../../functions/api";
import { useHistory } from "react-router";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import EducatorsMainHeader from "../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader";
import LoaderComponent from "../../../commonComponents/LoaderComponent/LoaderComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Calendar from "react-calendar";
import moment from "moment";
import {
  ToastContainer,
  toast
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//Import Images
import { makeStyles } from "@material-ui/core";
import pdfIcons from '../../../images/pdfImage.png';
import excelIcons from '../../../images/excelImage.png';
import docIcons from '../../../images/docImage.png';
import ImageIcon from '../../../images/image_icon.png';
import { baseUrl } from "../../../config";
import DummyImage from '../../../images/user2.png'
import { confirmAlert } from "react-confirm-alert";
import { decode } from 'html-entities';
import HeadOfficeMainHeader from "../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const professionalDevelopmentMethods = [
  "webinar",
  "reading",
  "workshop",
  "conference",
  "meeting",
  "podcast"
];

const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"];

let initialPDStates = {
  date: "",
  showDateCalender: false,
  method: "",
  showMethod: false,
  courseName: "",
  organisation: "",
  duration: "",
  showDurationCalender: false,
  certificate: [],
  fid: ''
};
const CaBTViewProfilePage = (props) => {
  const history = useHistory();
  const uid = props.match.params.id;
  const [dob, setDob] = useState("");
  const store = useSelector((state) => state.auth);
  const [commencementDate, setCommencementDate] = useState("");
  const allOperationalDays = [{ id: 268, val: 'Sunday' },
  { id: 269, val: 'Monday' }, { id: 270, val: 'Tuesday' }, { id: 274, val: 'Wednesday' },
  { id: 271, val: 'Thursday' }, { id: 272, val: 'Friday' }, { id: 273, val: 'Saturday' }];
  const [email, setEmail] = useState("");
  const [ceasedDate, setCeasedDate] = useState("");
  const [ceaseCalendar, setCeasecalendar] = useState(false);
  const [profileUid, setProfileId] = useState();
  const [username, setUsername] = useState("");
  // const [userVisits, setUserVisits] = useState([]);
  const [userPicture, setUserPicture] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [completedDate, setCompletedDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showTaskStatusDropdown, setShowTaskStatusDropdown] = useState(false);
  const [firstAidCourse, setFirstAidCourse] = useState();
  const [firstAidCompletionDate, setFirstAidCompletionDate] = useState('');
  const [cprCourseCompletionDate, setCprCourseCompletionDate] = useState('');
  const [cprCourse, setCprCourse] = useState();
  const [firstAidCourseReminder, setFirstAidCourseReminder] = useState('')
  const [crpCourseReminder, setCrpCourseReminder] = useState('')
  const [firstAidCal, setfirstAidCal] = useState(false);
  const [cprCourseCal, setcprCourseCal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [uploadCertificate, setUploadCertificate] = useState([])
  const [openProfessionalDevelopmentModal, setOpenProfessionalDevelopmentModal] = useState(false);
  // Quality improvment Plan
  const [professionalDevelop, setProfessionalDevelop] = useState([]);
  const [professionalDevelopmentStates, setProfessionalDevelopmentStates] = useState(initialPDStates);
  const [professional_time, set_professional_time] = useState('');
  const [error, setError] = useState({ methodError: '' });
  const [dateofbirthCalendar, setDateofbirthCalendar] = useState(false);
  const [commenceCalendar, setCommenceCalendar] = useState(false);
  const [studyCalendar, setStudyCalendar] = useState(false);
  const [unitNumber, setUnitNumber] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [businessRole, setBusinessRole] = useState('');

  //USER PROFILE ID STATE
  const [user_profile_id, set_user_profile_id] = useState('');
  const [profileID, setProfileID] = useState('');

  //USER ID
  // let userID = window.localStorage.getItem('uid');
  let userID = props.match.params.id;

  // Residential Details 
  const [eduBlueCard, setEduBlueCard] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [sightedDate, setSightedDate] = useState('');

  // Public Liability
  const [policyNumber, setPolicyNumber] = useState();

  // Vehicle
  const [restraintDate, setRestraintDate] = useState();
  const [maintenanceDate, setMaintenanceDate] = useState();

  //DropDown Value Residence
  let residence = [
    "Residence",
    "Venue"
  ];

  // Basic Information
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [homeNumber, setHomeNumber] = useState('');
  const [suburbName, setSuburbName] = useState('');
  //New Fields Basic Information 
  const [basicResidence, setBasicResidence] = useState('')

  // New Field For Personal Profile Edit .
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');

  // Up and down on Onclick Functionality 
  const [consistId, setConsisitId] = useState(['0']);
  const [toggleOn, setToggleOn] = useState(false);
  const [qualificationIssueDate, setQualificationIssueDate] = useState()

  // Remainders
  const [auditDateReminder, setAuditDateRemainder] = useState('');

  // Calendars
  const [expiryDateCalender, setExpiryDateCalender] = useState(false)
  const [sightedDateCalender, setSightedDateCalender] = useState(false)
  //New Fields Calendar
  const [teacherDateCalender, setTeacherDateCalender] = useState(false)
  // const Check

  const [editableData, setEditableData] = useState([])

  // Data for Field Adult Occupants
  const [adultDataOccupant, setAdultDataOccupant] = useState([{
    field_occupant_first_name: [{ "value": '' }],
    field_occupant_last_name: [{ "value": '' }],
    field_occupant_date_of_birth: [{ "value": '' }],
    show_calendar: false,
    show_calendar_blue_card: false,
    show_calendar_sighted_date: false,
    show_calendar_teacher_date: false,
    field_all_occupants: '',
    field_occupant_blue_card: [{ "value": '' }],
    field_occupant_card_expiry_date: [{ "value": '' }],
    field_occupant_date_signed: [{ "value": '' }],
    field_reminder_blue_card: [{ "value": '' }],
    field_teacher_registration: [{ "value": '' }],
    field_teacher_expiry_date: [{ "value": '' }],
    field_teacher_adult_reminder: [{ "value": '' }],
    dropDown: false,
    dropDown1: false
  }])

  // For Certificates 
  const [firstAidCertificate, setFirstAidCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [cprCertificate, setCprCertificate] = useState([{ value: '', id: '', url: '' }]);

  // Reminder
  const [educatorReminder, setEducatorReminder] = useState('')
  const [educatorTeacherReminder, setEducatorTeacherReminder] = useState('')

  // Education
  const [qualificationDetail, setQualificationDetail] = useState([{
    qualification_detail: '',
    issue_date: '',
    on_calender: false
  },])
  const [qualificationCount, setQualificationCount] = useState(1)

  // Expiray dates for First  Aid Courses
  const [firstAidExpiry, setFirstAidExpiry] = useState(false);
  const [firstAidExpiryDate, setFirstAidExpiryDate] = useState('');
  const [cprExpiry, setCprExpiry] = useState(false);
  const [cprExpiryDate, setCprExpiryDate] = useState('')
  const [asthmaExpiry, setAsthmaExpiry] = useState(false)
  const [anaphylaxixExpiry, setAnaphlaxixExpiry] = useState(false)

  //--------------------------STATE FOR pdl -------------------
  const [isEditPDL, setIsEditPDL] = useState(false)

  const [studyCourseName, setStudyCourseName] = useState('');
  const [studyCourseDate, setStudyCourseDate] = useState('');
  const [contacts, setContacts] = useState([{ field_title:"",
    field_phone:""}, { field_title:"",
      field_phone:""}]);
  const [medicalConditions, setMedicalConditions] = useState([""]);

  useEffect(() => {
    $(".accordion-content:not(:first-of-type)").css("display", "none");
    $(".js-accordion-title:first-of-type").addClass("open");
    $(".js-accordion-title").click(function () {
      $(".open").not(this).removeClass("open").next().slideUp(300);
      $(this).toggleClass("open").next().slideToggle(300);
    });
    $(function () {
      $(".mdc-text-field--outlined .mdc-text-field__input").on(
        "focusout",
        function () {
          if ($(this).val().length > 0) {
            $(this).addClass("active");
          } else {
            $(this).removeClass("active");
          }
        }
      );
    });
    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
          $('.dropdown').slideUp();
        }
      });
    });
  }, [store.userRole, professionalDevelopmentStates.certificate.length])

  useEffect(() => {
    window.localStorage.removeItem('folderParentId');
    onPageInit()
    // eslint-disable-next-line
  }, [store.userRole, professionalDevelopmentStates.certificate.length]);

  async function onPageInit() {
    async function getInitialData() {
      // window.localStorage.setItem('educator_uid', uid);

      let responseData = await getEmergencyConatcts(uid)
      let filteredArray = responseData?.data?.data && responseData?.data?.data.length > 0 ? responseData?.data?.data.filter(item => item.field_title !== '' && item.field_phone !== '') :
        [{
          field_title: "",
          field_phone: ""
        }, {
          field_title: "",
          field_phone: ""
        }];
      if (filteredArray.length == 1) {
        filteredArray.push({ field_title: "", field_phone: "" })
      }
      if (filteredArray.length > 0) {
        setContacts(filteredArray)
      }

      let authToken = window.localStorage.getItem("auth_token");
      let response = await getSpecificUser(uid, authToken);
      if (response.status === 200) {
        set_user_profile_id(uid)
        const firstName = response?.data?.data?.field_first_name?.[0]?.value;
        const lastName = response?.data?.data?.field_last_name?.[0]?.value;
        const username = lastName ? `${firstName} ${lastName}` : firstName;
        setUsername(username);
        const mail = response?.data?.data?.mail?.[0]?.value;
        setEmail(mail);
        if (response.data.data.educator_profiles.length > 0) {
          let profileId = response?.data?.data?.educator_profiles[0]?.target_id || null;
          if(profileId != null || profileId != undefined){
            setProfileID(profileId)
            let profileData = await getUserProfileData(profileId);
            if (profileData.status === 200) {
              let mainProfileData = profileData.data.data
  
              const dob = mainProfileData.field_d_o_b?.[0]?.value ?? "";
              setDob(dob);


              if(mainProfileData.field_please_provide_details_bel && mainProfileData.field_please_provide_details_bel.length > 0){
                const valuesArray = mainProfileData.field_please_provide_details_bel.map(item => item.value);
                setMedicalConditions(valuesArray)
              }
  
              if (mainProfileData.field_study_course_title && mainProfileData.field_study_course_title.length > 0) {
                let studyCoursName = mainProfileData.field_study_course_title[0]?.value ?? ''
                setStudyCourseName(studyCoursName)
              }
              if (mainProfileData.field_study_course_date && mainProfileData.field_study_course_date.length > 0) {
                let studyCourseDate = mainProfileData.field_study_course_date[0]?.value ?? ''
                setStudyCourseDate(studyCourseDate)
              }
              if (mainProfileData.field_cabt_business_role !== undefined && mainProfileData.field_cabt_business_role !== "") {
                let _businessRole = mainProfileData.field_cabt_business_role[0] ? mainProfileData.field_cabt_business_role[0].value : ""
  
                if (_businessRole.includes('<p>')) {
                  setBusinessRole($(_businessRole).text());
                } else {
                  setBusinessRole(_businessRole);
                }
              }
              if (profileId !== "") {
                let auth_token = window.localStorage.getItem("auth_token");
                let profile_response = await getUserProfileData(
                  profileId,
                  auth_token
                );
                if (profile_response.status === 200) {
                  const userPicture = profile_response?.data?.data?.field_profile_picture?.[0]?.url ?? "";
                  if (userPicture !== "") {
                    setUserPicture(userPicture);
                  }
                }
              }
  
              // Certificates Data of Courses
              const updateCertificate = (certificateType) => {
                const certificate = mainProfileData?.[certificateType]?.[0];
                if (certificate) {
                  const { target_id: id = '', url = '' } = certificate;
                  const value = url.slice(66);
                  switch (certificateType) {
                    case 'field_certificate_document':
                      setFirstAidCertificate([{ value, id, url }]);
                      break;
                    case 'field_cpr_certificate':
                      setCprCertificate([{ value, id, url }]);
                      break;
                    default:
                      break;
                  }
                }
              };
              // Usage
              updateCertificate('field_certificate_document');
              updateCertificate('field_cpr_certificate');
  
              const updateStateIfNotEmpty = (dataKey, setStateFunction) => {
                const dataValue = mainProfileData?.[dataKey]?.[0]?.value ?? '';
                if (dataValue !== '') {
                  setStateFunction(dataValue);
                }
              };
  
              const updates = [
                { key: 'field_residence', setter: setBasicResidence },
                { key: 'field_educator_reminder', setter: setEducatorReminder },
                { key: 'field_blue_card_', setter: setEduBlueCard },
                { key: 'field_blue_card_expiry_date', setter: setExpiryDate },
                { key: 'field_signed_date', setter: setSightedDate },
                { key: 'field_teacher_reminder', setter: setEducatorTeacherReminder },
                { key: 'field_policy_number', setter: setPolicyNumber },
                { key: 'field_vehicle_expiry_date', setter: setMaintenanceDate },
                { key: 'field_vehicle_restraint_expiry', setter: setRestraintDate },
                { key: 'field_restraint_audit_reminder', setter: setAuditDateRemainder },
                { key: 'field_commencement_date', setter: setCommencementDate },
                { key: 'field_ceased_date', setter: setCeasedDate },
                { key: 'field_first_aid_course', setter: setFirstAidCourse },
                { key: 'field_first_aid_completion_date', setter: setFirstAidCompletionDate },
                { key: 'field_first_aid_course_reminder', setter: setFirstAidCourseReminder },
                { key: 'field_cpr_course', setter: setCprCourse },
                { key: 'field_cpr_course_completion_date', setter: setCprCourseCompletionDate },
                { key: 'field_cpr_expiry_date', setter: setCprExpiryDate },
                { key: 'field_first_aid_expiry_date', setter: setFirstAidExpiryDate },
                { key: 'field_cpr_course_reminder', setter: setCrpCourseReminder },
              ];
  
              const updatePromises = updates.map(({ key, setter }) =>
                updateStateIfNotEmpty(key, setter)
              );
  
              await Promise.all(updatePromises);
  
              // Multi Qualification Data Getting
              if (mainProfileData.field_qualification !== undefined && mainProfileData.field_qualification !== "" && mainProfileData.field_issue_date !== undefined) {
                let qualification_data = [];
                for (let i = 0; i < mainProfileData.field_qualification.length; i++) {
                  const qualification = mainProfileData.field_qualification[i] ? mainProfileData.field_qualification[i].value : ""
                  const issue_date = mainProfileData.field_issue_date[i] ? mainProfileData.field_issue_date[i].value : ''
                  qualification_data.push({
                    qualification_detail: qualification,
                    issue_date: issue_date,
                    on_calender: false
                  })
                }
                setQualificationDetail(qualification_data)
                setQualificationCount(mainProfileData.field_qualification.length)
              }
              setShowLoader(false);
            } else {
              setShowLoader(false);
            }
          } else {
            setShowLoader(false);
          }
        } else {
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
      }
    }

    try {
      await Promise.all([
        getProfessionalDevelop(),
        getInitialData()

      ]);
    } catch (error) {
      setShowLoader(false);
      console.log('error in promise', error);
      
    }
    function setCustomValidityForElement(elementId, message) {
      var element = document.getElementById(elementId);
      if (element !== null) {
        element.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity(message);
          } else {
            e.target.setCustomValidity("");
          }
        };
      }
    }
    function validateForm() {
      setCustomValidityForElement("completedDate", "Please select created date");
      setCustomValidityForElement("reviewedDate", "Please select reviewed date");
      setCustomValidityForElement("completionTimeframe", "Please enter the Timeframe for completion");
      setCustomValidityForElement("furtherAction", "Please enter the Further Action");
    }
    validateForm();
    getAllInitialData();
  }

  const commencementCalendar = () => {
    if (commenceCalendar === true) {
      setCommenceCalendar(false)
    }
    else {
      setCommenceCalendar(true)
    }
  }

  const studyCalendarBlock = () => {
    if (studyCalendar === true) {
      setStudyCalendar(false)
    }
    else {
      setStudyCalendar(true)
    }
  }

  const openCloseCalender = (type) => {
    if (type === 'expiryDate') {
      if (expiryDateCalender === true) {
        setExpiryDateCalender(false)
      } else { setExpiryDateCalender(true) }
    }

    if (type === 'sightedDate') {
      if (sightedDateCalender === true) {
        setSightedDateCalender(false)
      } else { setSightedDateCalender(true) }
    }
    if (type === 'teacherExpiryDate') {
      if (teacherDateCalender === true) {
        setTeacherDateCalender(false)
      } else { setTeacherDateCalender(true) }
    }
  }

  const commencementCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCommencementDate(fullYear)
    setCommenceCalendar(false)
  }

  const studyCalenderHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setStudyCourseDate(fullYear)
    setStudyCalendar(false)
  }

  const sightedDateCalendarSet = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setSightedDate(fullYear)
    setSightedDateCalender(false);
  }


  const expiryDateCalendar = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setExpiryDate(fullYear)
    setExpiryDateCalender(false);
  }

  // const teacherDateCalendar = (newDate) => {
  //   let fullYear = moment(newDate).format('YYYY-MM-DD')
  //   setTeacherExpiryDate(fullYear)
  //   setTeacherDateCalender(false);
  // }

  // Invalid date issue 
  function _onDateFormat(date) {
    let newDate = date.split('-')
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
  }

  //ADD 
  async function professionaSubmit(e, type) {
    e.preventDefault();
    if (type) {
      setShowLoader(true);
      let object;
      if (!(uploadCertificate.length > 0)) {
        object = {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
          "field_task_status": [{ "value": professionalDevelopmentStates.method }],
          "field_course_name": [
            { "value": professionalDevelopmentStates.courseName }
          ],
          "field_organisation": [
            { "value": professionalDevelopmentStates.organisation }
          ],
        }
      }
      else {
        let professionalImage = await uploadImage(uploadCertificate[0].name, uploadCertificate[0].path)
        if (professionalImage.status === 201) {
          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ],
            field_new_upload_certificate: [{ target_id: professionalImage.data.data.fid[0].value }]
          }
        } else {
          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ]
          }
        }
      }

      const response = await updateProfessionalDevelopmentLog(editableData.id, object);
      if (response.status === 200) {
        await getProfessionalDevelop();
        toast.success("Professional Development log item updated successfully", {
          position: "top-center",
        });
        setShowLoader(false);
        setProfessionalDevelopmentStates(initialPDStates);
        setOpenProfessionalDevelopmentModal(false);
      } else {
        setShowLoader(false);
      }
      return;
    } else {
      if (validateFieldsPd()) {

        setShowLoader(true);
        let openingHours = professional_time;
        let timeArray = openingHours.split(':')
        let hours = timeArray[0]
        let minutes = timeArray[1]
        let finalOpeningHours = hours * 3600 + minutes * 60

        let object =
        {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          field_date_completed: [{ value: professionalDevelopmentStates.date }],
          field_task_status: [{ value: professionalDevelopmentStates.method }],
          field_course_name: [
            { value: professionalDevelopmentStates.courseName },
          ],
          field_organisation: [
            { value: professionalDevelopmentStates.organisation },
          ],
          field_select_time: [{ value: finalOpeningHours }]

        };

        if (professionalDevelopmentStates.certificate.length > 0) {
          let _imagesArray = []
          professionalDevelopmentStates.certificate.map(async (data) => {

            let professionalImage = await uploadImage(data.name, data.path)

            if (professionalImage.status === 201) {
              _imagesArray.push({ target_id: professionalImage.data.data.fid[0].value })

              if (_imagesArray.length === professionalDevelopmentStates.certificate.length) {
                Object.assign(object, { "field_new_upload_certificate": _imagesArray })


                let addProfessionalD = await addProfessionalDevelop(object);
                if (addProfessionalD.status === 201) {
                  let responseData = addProfessionalD.data.data

                  let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
                  let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";

                  //if for updateProfessionalProfile
                  if (professionalId !== "" && revisionId !== "") {
                    let user_id = uid

                    let profile_id = await getSpecificUser(user_id)
                    if (profile_id.status === 200) {
                      let objects =
                      {
                        "type": [{
                          "target_id": "professional_development",
                          "target_type": "paragraphs_type"
                        }],
                        "profileId": profile_id.data.data.educator_profiles[0].target_id,
                      };

                      let field_quality_arr = [];
                      if (professionalDevelop.length > 0) {
                        professionalDevelop.map((v) => {
                          return field_quality_arr.push({
                            "target_id": v.id,
                            "target_revision_id": v.revision_id
                          })
                        })
                      }

                      field_quality_arr.push({
                        "target_id": professionalId,
                        "target_revision_id": revisionId
                      })

                      let obj = {
                        "type": "cabt",
                        "uid": [{
                          "target_id": userID
                        }],
                        "field_professional_development": field_quality_arr,
                      }

                      let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                      if (updateProfessionalDevelop.status === 200) {
                        await getProfessionalDevelop();
                        toast.success("Professional Development log item added successfully", {
                          position: "top-center",
                        });
                        setShowLoader(false);
                        setProfessionalDevelopmentStates(initialPDStates);
                        setOpenProfessionalDevelopmentModal(false);
                      } else {
                        setShowLoader(false);
                        toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                      }
                    }
                    else {
                      setShowLoader(false);
                      toast.error('something went wrong', { position: "top-center" });
                    }
                  }
                }
                else {
                  setShowLoader(false);
                  toast.error(addProfessionalD.message, { position: "top-center" });
                }
              }
              setShowLoader(false);
              await getProfessionalDevelop();
            } else {
              setShowLoader(false);
              toast.error(professionalImage.message, { position: "top-center" });
            }
          });
        }
        else {
          let addProfessionalD = await addProfessionalDevelop(object);
          if (addProfessionalD.status === 201) {
            let responseData = addProfessionalD.data.data

            let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
            let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";
            if (professionalId !== "" && revisionId !== "") {
              let user_id = uid
              let profile_id = await getSpecificUser(user_id)
              if (profile_id.status === 200) {
                let objects =
                {
                  "type": [{
                    "target_id": "professional_development",
                    "target_type": "paragraphs_type"
                  }],
                  "profileId": profile_id.data.data.educator_profiles[0].target_id,
                };
                let field_quality_arr = [];
                if (professionalDevelop.length > 0) {
                  professionalDevelop.map((v) => {
                    return field_quality_arr.push({
                      "target_id": v.id,
                      "target_revision_id": v.revision_id
                    })
                  })
                }
                field_quality_arr.push({
                  "target_id": professionalId,
                  "target_revision_id": revisionId
                })
                let obj = {
                  "type": "cabt",
                  "uid": [{
                    "target_id": userID
                  }],
                  "field_professional_development": field_quality_arr,
                }
                let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                if (updateProfessionalDevelop.status === 200) {
                  toast.success("Professional Development log item added successfully", {
                    position: "top-center",
                  });
                  setShowLoader(false);
                  setOpenProfessionalDevelopmentModal(false)
                  await getProfessionalDevelop();
                } else {
                  setShowLoader(false);
                  toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                }

              }
              else {
                setShowLoader(false);
                toast.error('something went wrong', { position: "top-center" });
              }
            }
          }
          else {
            setShowLoader(false);
            toast.error(addProfessionalD.message, { position: "top-center" });
          }
        }
      }
    }

  }

  //EDIT PROFESSIONAL DEVELOPMENT
  async function editProfessional(e) {
    setEditableData(e);
    const response = await getOccupant(e.id);
    setIsEditPDL(true);
    const fidCheck = response.data.data.field_new_upload_certificate && response.data.data.field_new_upload_certificate.length > 0 && response.data.data.field_new_upload_certificate[0].target_id;

    setOpenProfessionalDevelopmentModal(true)
    set_professional_time(e.field_select_time)

    let object = { "name": (e.field_new_upload_certificate).slice(29, 47), "path": `${baseUrl}${e.field_new_upload_certificate}` }
    if (e.field_new_upload_certificate) {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [object],
        fid: fidCheck ? fidCheck : ''
      })
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [],
        fid: ''
      })
    }
  }

  //GET ALL PROFESSIONAL DEVELOPMENT LISTING
  const getProfessionalDevelop = async (edit) => {
    setProfessionalDevelop([]);
    let response = await getCabtProfessionalDevelops(uid);
    if (response.status === 200) {
      if (response.data.data !== undefined && response.data.data.length > 0) {
        setProfessionalDevelop(response.data.data);

        if (edit === true) {

          let newData = []
          if (response.data.data && response.data.data.length) {
            response.data.data.map((val) => {
              // if (val.id !== id) {
              let tagretId = val.id;
              let revisionId = val.revision_id;
              return newData.push({ "target_id": tagretId, "target_revision_id": revisionId })
              // }
              return null
            })
          }

          let profileNewData = {
            "type": [{ "target_id": "cabt", "target_type": "profile_type" }],
            "field_professional_development": newData
          }
          await upDateOccupants(profileUid, profileNewData)
        }



      } else {
        setProfessionalDevelop([]);
      }
    } else {
      toast.error(response.message, { position: "top-center" });
    }
  };

  //DELETE PROFESSIONAL DEVELOPMENT LOG AND QUALITY IMPROVMENT PLAN
  const deleteProfessioanlDevelop = async (id) => {
    setShowLoader(true);
    let response = await deleteProfessioanlDevelopLogs(id);
    if (response.status === 204) {
      setShowLoader(false);
      toast.success("Professional development Log item deleted successfully", {
        position: "top-center",
      });
      let edit = true
      await getProfessionalDevelop(edit);

      // setProfessionalDevelop([...professionalDevelop])
    } else {
      setShowLoader(false);
      toast.error(response.message, { position: "top-center" });
    }
  };

  function confirmDeleteProfessional(id) {
    confirmAlert({
      title: 'Do you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteProfessioanlDevelop(id)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  async function getAllInitialData() {
    let user_id = uid
    let auth_token = window.localStorage.getItem('auth_token');
    let user_response = await getSpecificUser(user_id, auth_token);
    if (user_response.status === 200) {

      if (user_response.data.data !== '' && user_response.data.data !== undefined) {
        if (user_response.data.data.field_first_name) {
          let name = user_response.data.data.field_first_name[0] ? user_response.data.data.field_first_name[0].value : "";
          setFirstName(name);
        }

        if (user_response.data.data.field_last_name) {
          let lastName = user_response.data.data.field_last_name[0] ? user_response.data.data.field_last_name[0].value : "";
          setLastName(lastName);
        }
        if (user_response.data.data.educator_profiles.length > 0) {
          let profileId = user_response.data.data.educator_profiles[0].target_id;
          setProfileId(profileId);
          let profileData = await getUserProfileData(profileId);
          if (profileData.status === 200) {

            if (profileData.data.data.field_home_number !== undefined && profileData.data.data.field_home_number !== "") {
              let _home = profileData.data.data.field_home_number[0] ? profileData.data.data.field_home_number[0].value : ""
              setHomeNumber(_home)
            }
            if (profileData.data.data.field_cabt_unit_number !== undefined && profileData.data.data.field_cabt_unit_number !== "") {
              let _unitNumber = profileData.data.data.field_cabt_unit_number[0] ? profileData.data.data.field_cabt_unit_number[0].value : ""
              setUnitNumber(_unitNumber)
            }
            if (profileData.data.data.field_postcode !== undefined && profileData.data.data.field_postcode !== "") {
              let _postcode = profileData.data.data.field_postcode[0] ? profileData.data.data.field_postcode[0].value : ""
              setPostalCode(_postcode)
            }
            if (profileData.data.data.field_cabt_street_number !== undefined && profileData.data.data.field_cabt_street_number !== "") {
              let _streetNumber = profileData.data.data.field_cabt_street_number[0] ? profileData.data.data.field_cabt_street_number[0].value : ""
              setStreetNumber(_streetNumber)
            }
            if (profileData.data.data.field_street_address !== undefined && profileData.data.data.field_street_address !== "") {
              let _street = profileData.data.data.field_street_address[0] ? profileData.data.data.field_street_address[0].value : ""
              if (_street.includes('<p>')) {
                setStreet($(_street).text());
              } else {
                setStreet(_street);
              }
            }

            if (profileData.data.data.field_suburb_name !== undefined && profileData.data.data.field_suburb_name !== "") {
              let _suburb = profileData.data.data.field_suburb_name[0] ? profileData.data.data.field_suburb_name[0].value : ""
              setSuburbName(_suburb)
            }
          }
        }
      }
    }
  }

  //ceased calendar
  const ceasedCalendar = () => {
    if (ceaseCalendar === true) {
      setCeasecalendar(false);
    }
    else {
      setCeasecalendar(true);
    }

  };
  //ceased calendar
  const ceasedCalendarHandler = async (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCeasedDate(fullYear)
    setCeasecalendar(false)
    const data = { ceasedDate: fullYear, uid: uid }
    let response = await updateUserProfileCeasedDate(user_profile_id, data);
    if (response.status === 200) {
    }
    else {
    }

  }

  const resetValues = () => {
    let dateFormat = moment(currentDate).format("YYYY-MM-DD");
    setCurrentDate(dateFormat);
    setCompletedDate("");
  };

  const showHideDropdown = (value) => {
    if (value === "category") {
      if (showCategoryDropdown === true) {
        setShowCategoryDropdown(false);
      } else {
        setShowCategoryDropdown(true);
      }
    } else if (value === "pdMethods") {
      let _show = professionalDevelopmentStates.showMethod;
      if (_show === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: true,
        });
      }
    } else {
      if (showTaskStatusDropdown === true) {
        setShowTaskStatusDropdown(false);
      } else {
        setShowTaskStatusDropdown(true);
      }
    }
  };

  const openPDModal = () => {
    resetValues();
    setIsEditPDL(false)
    setProfessionalDevelopmentStates(initialPDStates);
    setOpenProfessionalDevelopmentModal(true);
  };

  const showHidePDCalender = (value) => {
    if (value === "date") {
      let _calenderDisplay = professionalDevelopmentStates.showDateCalender;
      if (_calenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: true,
        });
      }
    } else {
      let _durationCalenderDisplay =
        professionalDevelopmentStates.showDurationCalender;
      if (_durationCalenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: true,
        });
      }
    }
  };
  const onChangePDDate = (newDate, field) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1;
    let year = newDate.getFullYear();
    let date = newDate.getDate();
    let fullYear = year + "-" + month + "-" + date;

    if (field === "date") {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: fullYear,
        showDateCalender: false,
      });
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        duration: fullYear,
        showDurationCalender: false,
      });
    }
  };

  const uploadImages = (event) => {
    setProfessionalDevelopmentStates({ ...professionalDevelopmentStates, fid: '' })
    if (event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5) {
      if (event.target.files[0].type !== "image/jpg" && event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" && event.target.files[0].type !== "application/pdf") {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = function (events) {
              imageObject["path"] = events.target.result;
            };
            imagesArray.push(imageObject);
            setUploadCertificate([imagesArray[0]])
            setProfessionalDevelopmentStates({
              ...professionalDevelopmentStates,
              certificate: [...imagesArray],
            });
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };
  const removeSpecificImage = (index) => {
    let imagesArray = professionalDevelopmentStates.certificate;
    imagesArray.splice(index, 1);
    setProfessionalDevelopmentStates({
      ...professionalDevelopmentStates,
      certificate: [...imagesArray],
    });
  };

  const validateFieldsPd = () => {
    let formIsValid = true;
    if (professionalDevelopmentStates.method === "") {
      setError((error) => ({
        ...error,
        methodError: "Please enter your Method",
      }));
      formIsValid = false;
    }
    return formIsValid;
  };

  async function saveStudy() {
    setShowLoader(true)
    let user_id = uid
    let data = []

    if (studyCourseName !== '' && studyCourseName !== undefined) {
      data.push({ field_study_course_title: studyCourseName })
    }
    if (studyCourseDate !== '' && studyCourseDate !== undefined) {
      data.push({ field_study_course_date: studyCourseDate })
    }

    let textData = {
      uid: user_id,
      field_study_course_title: studyCourseName,
      field_study_course_date: studyCourseDate
    }
    var newObj = Object.assign({}, ...(data.map(item => item)));
    const finalData = Object.assign({}, textData, newObj);

    let response = await updateCabtProfile(profileUid, finalData);
    if (response.status === 200) {
      setShowLoader(false);
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
    } else {
      setShowLoader(false)
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  // Save Profile  Calls Here
  async function saveProfile() {
    setShowLoader(true)
    let user_id = uid
    let data = []

    if (dob !== '' && dob !== undefined) {
      data.push({ dob: dob })
    } if (commencementDate !== '' && commencementDate !== undefined) {
      data.push({ commencementDate: commencementDate })
    } if (firstAidCompletionDate !== '' && firstAidCompletionDate !== undefined) {
      data.push({ firstAidCourseCompletionDate: firstAidCompletionDate })
    } if (cprCourseCompletionDate !== '' && cprCourseCompletionDate !== undefined) {
      data.push({ cprCourseCompletionDate: cprCourseCompletionDate })
    } if (qualificationIssueDate !== '' && qualificationIssueDate !== undefined) {
      data.push({ qualificationIssueDate: qualificationIssueDate })
    }
    if (restraintDate !== '' && restraintDate !== undefined) {
      data.push({ restraintDate: restraintDate })
    } if (maintenanceDate !== '' && maintenanceDate !== undefined) {
      data.push({ maintenanceDate: maintenanceDate })
    }
    if (firstAidExpiryDate !== '' && firstAidExpiryDate !== undefined) {
      data.push({ firstAidExpiryDate: firstAidExpiryDate })
    }
    if (cprExpiryDate !== '' && cprExpiryDate !== undefined) {
      data.push({ cprExpiryDate: cprExpiryDate })
    }
    if (studyCourseName !== '' && studyCourseName !== undefined) {
      data.push({ field_study_course_title: studyCourseName })
    }
    if (studyCourseDate !== '' && studyCourseDate !== undefined) {
      data.push({ field_study_course_date: studyCourseDate })
    }

    let textData = {
      uid: user_id,
      policyNumber: policyNumber,
      first_name: firstName,
      last_name: lastName,
      firstAidCourse: firstAidCourse,
      cprCourse: cprCourse,
      firstAidCourseReminder: firstAidCourseReminder,
      cprCourseReminder: crpCourseReminder,
      suburbName: suburbName,
      street: street,
      postal_code: postalCode,
      mobile_number: homeNumber,
      auditDateReminder: auditDateReminder,
      field_cpr_certificate: cprCertificate[0].id ? cprCertificate[0].id : null,
      field_certificate_document: firstAidCertificate[0].id ? firstAidCertificate[0].id : null,
      basicResidence: basicResidence,
      // basicStatus: basicStatus
      field_study_course_title: studyCourseName,
      field_study_course_date: studyCourseDate,
      unitNumber: unitNumber,
      streetNumber: streetNumber,
      businessRole: businessRole,
      field_please_provide_details_bel:medicalConditions

    }
    var newObj = Object.assign({}, ...(data.map(item => item)));
    const finalData = Object.assign({}, textData, newObj);

    let response = await updateCabtProfile(profileUid, finalData);
    if (response.status === 200) {
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
      setShowLoader(false);
    } else {
      setShowLoader(false)
      toast.error(response.message, {
        position: 'top-center'
      });
    }

    let responseUser = await updateUserData(uid, textData);
    if (responseUser.status === 200) {
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
    } else {
      setShowLoader(false)
      toast.error(responseUser.message, {
        position: 'top-center'
      });
    }
  }

  /// Course Calender
  const coursesCalendar = (name) => {
    // firstAidExpiryDate
    if (name === 'firstAidExpiryDate') {
      if (firstAidExpiry === true) {
        setFirstAidExpiry(false);
      } else {
        setFirstAidExpiry(true);
      }
      setSelectedField('firstAidExpiryDate')
    }
    // CPR Expiry
    if (name === 'CPRExpiry') {
      if (cprExpiry === true) {
        setCprExpiry(false);
      } else {
        setCprExpiry(true);
      }
      setSelectedField('CPRExpiry')
    }
    // Asthma Expiry
    if (name === 'AsthmaExpiry') {
      if (asthmaExpiry === true) {
        setAsthmaExpiry(false);
      } else {
        setAsthmaExpiry(true);
      }
      setSelectedField('AsthmaExpiry')
    }
    // Set Anaphylaxis Expiry
    if (name === 'AnaphylaxisExpiry') {
      if (anaphylaxixExpiry === true) {
        setAnaphlaxixExpiry(false);
      } else {
        setAnaphlaxixExpiry(true);
      }
      setSelectedField('AnaphylaxisExpiry')
    }
    if (name === 'firstaidcompletiondate') {
      if (firstAidCal === true) {
        setfirstAidCal(false);
      } else {
        setfirstAidCal(true);
      }
      setSelectedField('firstaidcompletiondate')
    }

    if (name === 'cprcoursecompletiondate') {
      if (cprCourseCal === true) {
        setcprCourseCal(false);
      }
      else {
        setcprCourseCal(true);
      }
      setSelectedField('cprcoursecompletiondate')
    }
  }


  const coursesCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    if (selectedField === 'firstaidcompletiondate') {
      setFirstAidCompletionDate(fullYear)
      setfirstAidCal(false)
    }
    if (selectedField === 'CPRExpiry') {
      setCprExpiryDate(fullYear)
      setCprExpiry(false)
    }
    if (selectedField === 'firstAidExpiryDate') {
      setFirstAidExpiryDate(fullYear)
      setFirstAidExpiry(false)
    }

    if (selectedField === 'cprcoursecompletiondate') {
      setCprCourseCompletionDate(fullYear)
      setcprCourseCal(false)
    }
  }


  // Remainder 
  const selectReminder = (field, selectedValue) => {

    if (field === "educatorReminder") {
      setEducatorReminder(selectedValue);
    }
    if (field === "firstAid") {
      setFirstAidCourseReminder(selectedValue)
    }
    if (field === "crp") {
      setCrpCourseReminder(selectedValue)
    }
    if (field === 'auditDateReminder') {
      setAuditDateRemainder(selectedValue)
    }
    if (field === "educatorTeacherReminder") {
      setEducatorTeacherReminder(selectedValue);
    }
  }

  // Basic Information 
  const dobCalendar = (index) => {
    if (dateofbirthCalendar === true) {
      setDateofbirthCalendar(false)
    }
    else {
      setDateofbirthCalendar(true)
    }
  }

  const handleResidenceChange = (event) => {
    setBasicResidence(event.target.value);
  };

  // DOB Calendar Handler
  const dobCalendarHandler = (newDate) => {
    let requiredDate = moment(newDate).format('YYYY-MM-DD')
    setDob(requiredDate)
    setDateofbirthCalendar(false)
  }

  const setAccordionOn = (id) => {
    setToggleOn(!toggleOn);
    if (consistId.includes(id)) {
      setConsisitId([]);
    } else {
      setConsisitId(id);
    }
  }

  const openDocument = (data) => {
    if (!(data === 'Not Available')) {
      window.open(`${baseUrl}${data}`, "_blank")
    }
  }

  // Upload different Types of Certificate :
  const uploadCertificateForCourses = (event, fieldName) => {
    if (
      event.target.files !== undefined &&
      Object.keys(event.target.files).length > 0 &&
      Object.keys(event.target.files).length <= 5
    ) {
      if (
        event.target.files[0].type !== "image/jpg" &&
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "application/pdf"
      ) {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = async function (events) {
              imageObject["path"] = events.target.result;
              const responseAPI = await uploadCertificatesAPI(imageObject.name, events.target.result, fieldName)
              imagesArray.push(imageObject);
              if (fieldName === 'first_aid') {
                setFirstAidCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'cpr_couse') {
                setCprCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
            };
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };

  const updateEducatorSideData = async () => {
    setShowLoader(true);
    let data = {
      "type": "cabt",
      "uid": [{ "target_id": uid }],
    }

    if (educatorReminder) {
      data = {
        ...data,
        field_educator_reminder: [{ "value": educatorReminder }],
      }
    }
    if (eduBlueCard) {
      data = {
        ...data,
        field_blue_card_: [{ "value": eduBlueCard }],
      }
    }
    if (expiryDate) {
      data = {
        ...data,
        field_blue_card_expiry_date: [{ "value": expiryDate }],
      }
    }
    if (sightedDate) {
      data = {
        ...data,
        field_signed_date: [{ "value": sightedDate }],
      }
    }
    if (educatorTeacherReminder) {
      data = {
        ...data,
        field_teacher_reminder: [{ "value": educatorTeacherReminder }],
      }
    }

    const profileUpdate = await updateEducatorSideDataAPI(profileUid, data);
    if (profileUpdate.status === 200) {
      onPageInit()
      toast.success("Successfully Updated !!");
    } else {
      setShowLoader(false);
    }
  }

  // Qualification Part 
  const addMoreQualification = () => {
    setQualificationDetail([...qualificationDetail,
    {
      qualification_detail: '',
      issue_date: '',
      on_calender: false
    }])
  }

  const onChangeQualification = (event, index) => {
    let { value } = event.target;
    let markers = [...qualificationDetail];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["qualification_detail"]: value };
    setQualificationDetail(markers)
  }
  const qualificationIssueDateSet = (index) => {
    let markers = [...qualificationDetail];
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["on_calender"]: true };
    setQualificationDetail([...markers])
  }

  const onChangeIssueDateQualification = (value, index, event) => {
    let markers = [...qualificationDetail];
    markers[index] = { ...markers[index], on_calender: false };
    // eslint-disable-next-line
    markers[index] = { ...markers[index], ["issue_date"]: moment(event).format('YYYY-MM-DD') };
    setQualificationDetail([...markers])
  }

  const saveQualificaitonProfile = async () => {
    let qualification = [];
    let issue_date = [];
    setShowLoader(true);
    for (let i = 0; i < qualificationDetail.length; i++) {
      qualification.push({ "value": qualificationDetail[i].qualification_detail });
      issue_date.push({ "value": qualificationDetail[i].issue_date });
    }
    const configData = {
      "type": "cabt",
      "uid": [{ "target_id": uid }],
      "field_qualification": qualification,
      field_issue_date: issue_date
    }
    const responseData = await upDateOccupants(profileUid, configData);
    if (responseData.status.status === 200) {
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
      setShowLoader(false);
      // onPageInit()
    } else {
      setShowLoader(false);
    }
  }

  const removeQualification = () => {
    qualificationDetail.pop();
    setQualificationIssueDate([...qualificationDetail])
  }

  useEffect(() => {
  }, [completedDate])


  const filterDayName = (id) => {
    const filterElement = allOperationalDays.filter(item => item.id === id)
    return filterElement[0].val
  }


  const handleContactChange = (index, type,event) => {    
    const updatedContacts = [...contacts];
    if(type == 'name'){
      updatedContacts[index].field_title = event.target.value;
    }else{
      updatedContacts[index].field_phone = event.target.value.replace(/\D/g, "");
    }
    setContacts(updatedContacts);
  };

  const handleMedicalChange = (index, event) => {
    const updatedConditions = [...medicalConditions];
    updatedConditions[index] = event.target.value;
    setMedicalConditions(updatedConditions);
  };

  const addMedicalCondition = () => {
    setMedicalConditions([...medicalConditions, ""]);
  };
  const removeUrlField=(index)=>{
      let urls = medicalConditions
      urls.splice(index,1)
      setMedicalConditions([...urls])
    }

    const addEmergencyContactsApi = async () => {
      let bodyData = [];
      
      contacts.forEach(data => {
        let bodyItem = {
          title: data.field_title,
          phone: data.field_phone
        };
        if (data.id) {
          bodyItem.target_id = data.id;
        }
        bodyData.push(bodyItem);
      });
    
      try {
        const response = await addEmergencyContacts(uid, profileID, bodyData);
        if (response.status === 200) {
          toast.success('Profile Updated Successfully!!', {
            position: 'top-center'
          });
        } else {
          setShowLoader(false)
          toast.error(response.message, {
            position: 'top-center'
          });
        }
      } catch (error) {
        console.error('Error adding emergency contacts:', error);
      }
    };

  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />

      {
        window.localStorage.getItem('user_role') === 'head_coordinator' ?
          <HeadOfficeMainHeader /> :
          <EducatorsMainHeader />
      }
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            <div className="common-header-row common-header-with-large-text risk--assignment">
              <div className="risk-assi-btns">
                <button
                  class="mdc-button mdc-button--raised"
                  onClick={openPDModal}
                >
                  <span class="mdc-button__label">
                    Add Professional Development Log
                  </span>
                </button>
              </div>
              {/* eslint-disable-next-line */}
              <a
                href="#"
                onClick={() => history.goBack()}
                className="dropdown-list"
              >
                <span className="material-icons">arrow_back</span> Go Back
              </a>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user">
                  <div className="user-profile-block text-center educator-view-profile-page">
                    <div className="media">
                      <img width="100" height="100" src={userPicture ? userPicture : DummyImage} alt="" />
                    </div>
                    {username ? <h1 className="email">{username}</h1> : null}
                  </div>
                </div>
                <div className="user-pages-accordion-wrap view-profile-page">
                  <div>
                    <div id="accordion" className="accordion-container">
                      {/* Baisc Information Start's Here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('0')}>Basic Information {toggleOn && consistId.includes('0') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input readOnly type="email" onChange={(e) => setEmail(e.target.value)} value={email} className={email ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Email*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => dobCalendar()}>
                                <input value={dob ? moment(dob).format('DD-MM-YYYY') : dob} type="text" className={dob ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="dob" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  dateofbirthCalendar === true ? <Calendar onChange={dobCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="15" value={homeNumber} onChange={(e) => setHomeNumber(e.target.value.replace(/\D/g, ""))} type="text" className={homeNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Mobile Number*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">phone</span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={suburbName} onChange={(e) => setSuburbName(e.target.value)} type="text" className={suburbName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Suburb Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>

                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" className={postalCode ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Post Code*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="50" value={unitNumber} onChange={(e) => { setUnitNumber(e.target.value) }} type="text" className={unitNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Unit Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="50" value={streetNumber} onChange={(e) => { setStreetNumber(e.target.value) }} type="text" className={streetNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Street Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                          <div className="col-lg-6 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="25" value={street} onChange={(e) => { setStreet(e.target.value) }} type="text" className={street ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Street Name*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                        </div>

                        <button className="mdc-button mdc-button--raised green-btn"
                          type="submit" value='submit' onClick={saveProfile}
                          style={{ marginTop: "12px" }}>
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>

                      {/* Busniness Information Starts Here  */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('2')}>
                        Business Information {toggleOn && consistId.includes('2') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => commencementCalendar()} >
                                <input value={commencementDate ? moment(commencementDate).format('DD-MM-YYYY') : commencementDate} type="text" className={commencementDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="commencement" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Commencement Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  commenceCalendar === true ? <Calendar onChange={commencementCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => ceasedCalendar()} >
                                <input value={ceasedDate ? moment(ceasedDate).format('DD-MM-YYYY') : ceasedDate} type="text" className={ceasedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="ceasedDate" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Ceased Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  ceaseCalendar === true ? <Calendar onChange={ceasedCalendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input value={businessRole} onChange={(e) => setBusinessRole(e.target.value)} type="text" className={businessRole ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Role*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        </div>

                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* Business Information Ends Here  */}

                      <h4 className="accordion-title js-accordion-title" >
                        Qualification {toggleOn && consistId.includes('1') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        {qualificationDetail && qualificationDetail.length > 0 ? qualificationDetail.map((value, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="text" onChange={(e) => onChangeQualification(e, index)} value={value.qualification_detail} className={value.qualification_detail ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Qualification*</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => qualificationIssueDateSet(index)} >
                                    <input value={value.issue_date ? moment(value.issue_date).format('DD-MM-YYYY') : value.issue_date} type="text" className={value.issue_date ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Issue Date*</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      value.on_calender === true ? <Calendar onChange={(e) => onChangeIssueDateQualification(value, index, e)} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              {index < qualificationCount ? null : <span className="material-icons align-at-end" style={{ cursor: "pointer" }} onClick={removeQualification}>clear</span>}
                            </div>
                          )
                        }) : null}
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveQualificaitonProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                        <button className="mdc-button mdc-button--raised green-btn ml-5" type="submit" value='submit' onClick={addMoreQualification}  >
                          <span className="mdc-button__label">Add Qualification</span>
                        </button>

                      </div>

                      <h4 className="accordion-title js-accordion-title" >
                        Studying {toggleOn && consistId.includes('9') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>

                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input type="text" onChange={(e) => setStudyCourseName(e.target.value)} value={studyCourseName} className={studyCourseName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Course*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => studyCalendarBlock()}>
                                <input value={studyCourseDate ? moment(studyCourseDate).format('DD-MM-YYYY') : studyCourseDate} type="text" className={studyCourseDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">CommencementDate Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  studyCalendar === true ? <Calendar onChange={studyCalenderHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveStudy} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* First Aid start */}
                      <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('5')}>
                        First Aid Certifications {toggleOn && consistId.includes('5') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-left">First Aid Course</label>
                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={firstAidCourse} onChange={(e) => { setFirstAidCourse(e.target.value); e.target.setCustomValidity(""); }} type="text" className={firstAidCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                    aria-labelledby="my-label-id" maxlength="20" id="firstAidCourse" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('firstaidcompletiondate')}>
                                    <input value={firstAidCompletionDate ? moment(firstAidCompletionDate).format('DD-MM-YYYY') : firstAidCompletionDate} type="text" className={firstAidCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="firstAidCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      firstAidCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{firstAidCourseReminder ? firstAidCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("firstAid", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>
                              {/* Add Certificate Field  */}
                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('firstAidExpiryDate')}>
                                    <input value={firstAidExpiryDate ? moment(firstAidExpiryDate).format('DD-MM-YYYY') : firstAidExpiryDate} type="text" className={firstAidExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="firstAidCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      firstAidExpiry === true ? <Calendar minDate={new Date(firstAidCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'first_aid')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{firstAidCertificate[0].value ? firstAidCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                  {
                                    firstAidCertificate.length > 0 && firstAidCertificate[0].value !== '' ?
                                      firstAidCertificate.map((items) => {
                                        let imageUrl1 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl1 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl1
                                        return (
                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl1} target="_blank" rel="noopener noreferrer"><img className="image-setting" style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="text-left">CPR Course</label>
                            <div className="row custom-row">
                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined">
                                    <input value={cprCourse} onChange={(e) => { setCprCourse(e.target.value); e.target.setCustomValidity("") }} type="text" className={cprCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                      aria-labelledby="my-label-id" id="cprCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('cprcoursecompletiondate')} >
                                    <input value={cprCourseCompletionDate ? moment(cprCourseCompletionDate).format('DD-MM-YYYY') : cprCourseCompletionDate} type="text" className={cprCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="cprCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      cprCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 custom-col">
                                <div className="select-field">
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    <label className="reminder-field-style">Set Reminder*</label>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">{crpCourseReminder ? crpCourseReminder : "Set Reminder"}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  <ul className="dropdown">
                                    <>
                                      {
                                        reminder.map((item, index) =>
                                          <li onClick={() => selectReminder("crp", item)} key={index}>{item}</li>
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 custom-col">
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('CPRExpiry')}>
                                    <input value={cprExpiryDate ? moment(cprExpiryDate).format('DD-MM-YYYY') : cprExpiryDate} type="text" className={cprExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                      id="cprExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  <div>
                                    {
                                      cprExpiry === true ? <Calendar minDate={new Date(cprCourseCompletionDate)} onChange={coursesCalendarHandler} /> : null
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'cpr_couse')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{cprCertificate[0].value ? cprCertificate[0].value : "Upload Certificate"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">image</span>
                                </label>
                                <>
                                </>
                                <>
                                  {
                                    cprCertificate.length > 0 && cprCertificate[0].url !== '' ?
                                      cprCertificate.map((items) => {
                                        let imageUrl2 = items.url
                                        if (!items.url.includes(baseUrl)) {
                                          imageUrl2 = baseUrl + items.url
                                        }
                                        let splittedArray = items.value.split(".")
                                        let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                          splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                            : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                              : imageUrl2
                                        return (

                                          <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                            <figure>
                                              <a href={imageUrl2} target="_blank" rel="noopener noreferrer"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} alt="" /></a>
                                            </figure>
                                          </li>
                                        )
                                      })
                                      : null
                                  }
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* First Aid End  */}
                       {/* Emergency Contact */}
                       <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('emergency')}>
                        Emergency Contact {toggleOn && consistId.includes('emergency') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div>
                          {contacts.map((contact, index) => (
                            <div className="row" key={index} style={{ marginBottom: "10px" }}>
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" value={contact.field_title} onChange={(e) => handleContactChange(index, "name", e)} type="text" className={contact.field_title ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="field_title" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" value={contact.field_phone} onChange={(e) => handleContactChange(index,"phone", e)} type="text" className={contact.field_phone ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="field_phone" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Phone</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={addEmergencyContactsApi} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                      {/* Emergency Contact ends here */}
                      {/* Medical Considerations start */}
                      <h4 className="accordion-title js-accordion-title " onClick={() => setAccordionOn('medical')}>
                      Medical Conditions and Considerations {toggleOn && consistId.includes('medical') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}</h4>
                      <div className="accordion-content">
                        <div>
                          <h3> Please provide details below (e.g. - Condition, Asthma, Allergies, Anaphylaxis, Medications)</h3>
                          {medicalConditions.map((condition, index) => (
                            <div className="row">
                              <div key={`medical-condtion-${index}`} className="col-md-11">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={condition} onChange={(e) => handleMedicalChange(index, e)} type="text" className={condition ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="condition" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Medical Conditions or Considerations</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              {
                                index > 0 ?
                                  <button style={{ width: '30px', height: '30px' }} type="button" className="mt-25" onClick={() => removeUrlField(index)}><span className="material-icons close">close</span></button>
                                  :
                                  null
                              }
                            </div>
                          ))}
                          <div className="custom-dashed-button mark-button-center" style={{ marginTop: "30px" }}>
                            <button className="mdc-button mdc-button--outlined btn-large" onClick={addMedicalCondition}>
                              <span className="mdc-button__ripple"></span>
                              <span className="mdc-button__label">Add More Medical Conditions</span>
                            </button>
                          </div>
                        </div>
                        <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                          <span className="mdc-button__label">Update</span>
                        </button>
                      </div>
                       {/* Emergency Contact and Medical Considerations ends here */}
                      {/* Residentail Occupants start */}
                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('3')}>
                        Blue Cards {toggleOn && consistId.includes('3') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}

                      </h4>


                      <div className="accordion-content">
                        <h2 className="title">
                          CaBT
                        </h2>
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" type="text" onChange={(e) => setEduBlueCard(e.target.value)} value={eduBlueCard} className={eduBlueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Blue Card*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => openCloseCalender('expiryDate')} >
                                <input value={expiryDate ? moment(expiryDate).format('DD-MM-YYYY') : expiryDate} type="text" className={expiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  expiryDateCalender === true ? <Calendar onChange={expiryDateCalendar} /> : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => openCloseCalender('sightedDate')} >
                                <input value={sightedDate ? moment(sightedDate).format('DD-MM-YYYY') : sightedDate} type="text" className={sightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  sightedDateCalender === true ? <Calendar onChange={sightedDateCalendarSet} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4 custom-col">
                            <div className="select-field">
                              <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                <label className="reminder-field-style">Set Reminder*</label>
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{educatorReminder ? educatorReminder : "Set Reminder"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">arrow_drop_down</span>
                              </label>
                              <ul className="dropdown">
                                <>
                                  {
                                    reminder.map((item, index) =>
                                      <li onClick={() => selectReminder("educatorReminder", item)} key={index}>{item}</li>
                                    )
                                  }
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-12 mb-20">
                            <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={updateEducatorSideData}>
                              <span className="mdc-button__label">Update</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Residential Occupants Ends  */}

                      <h4 className="accordion-title js-accordion-title" onClick={() => setAccordionOn('10')}>
                        Professional Development Logs {toggleOn && consistId.includes('10') ? <span className="material-icons align-arrow-end">keyboard_arrow_up</span> : <span className="material-icons align-arrow-end">keyboard_arrow_down</span>}
                      </h4>
                      {
                        professionalDevelop.length > 0 && professionalDevelop[0].field_date_completed ? (
                          <>
                            <div className="accordion-content">
                              <div className="task-listing">
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Course Name</TableCell>
                                        <TableCell>organisation</TableCell>
                                        <TableCell>Certificate</TableCell>
                                        {/* {userRole === 1 ? ( */}
                                        <TableCell>Modify</TableCell>
                                        {/* ) : null} */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {professionalDevelop.map((data, index) => (
                                        data.field_date_completed ?
                                          <TableRow key={index} className="col-md-12">
                                            <TableCell>
                                              {data.field_date_completed ? (
                                                <Moment format="DD-MM-YYYY">
                                                  {data.field_date_completed}
                                                </Moment>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              {data.field_task_status}
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_course_name }} ></div>
                                            </TableCell>
                                            <TableCell>
                                              <div dangerouslySetInnerHTML={{ __html: data.field_organisation }} ></div>
                                            </TableCell>
                                            <TableCell onClick={() => { openDocument(data.field_new_upload_certificate ? (data.field_new_upload_certificate) : 'Not Available') }}>
                                              <figure>
                                                {data.field_new_upload_certificate ? <img style={{ cursor: 'pointer', height: 50, width: 50 }} src={(data.field_new_upload_certificate.split('.')[1]) === "pdf" ? pdfIcons :
                                                  (data.field_new_upload_certificate.split('.')[1]) === "xslx" || (data.field_new_upload_certificate.split('.')[1]) === "xsl" ? excelIcons
                                                    : (data.field_new_upload_certificate.split('.')[1]) === "doc" || (data.field_new_upload_certificate.split('.')[1]) === "docx" ? docIcons
                                                      : ImageIcon} alt="" /> : "Not Available"}
                                              </figure>
                                            </TableCell>
                                            {/* {
                                              userRole === 1 ? */}
                                            <TableCell>
                                              <button className="bg-green action-btn-style" onClick={() => editProfessional(data)}>Edit</button>
                                              <button className="bg-red action-btn-style del" onClick={() => confirmDeleteProfessional(data.id)} >Delete</button> </TableCell>
                                            {/* : null
                                            } */}
                                          </TableRow>
                                          : null
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                          </>
                        ) : <h3>No Professional Development Logs Available</h3>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ADD Professional Development Diaog */}
      <Dialog
        open={openProfessionalDevelopmentModal}
        onClose={() => setOpenProfessionalDevelopmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Professional Development Log
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => professionaSubmit(e, isEditPDL)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHidePDCalender("date")}
                      >
                        <input
                          value={professionalDevelopmentStates.date ? _onDateFormat(professionalDevelopmentStates.date) : professionalDevelopmentStates.date}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            professionalDevelopmentStates.date
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="completedDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Date Completed *
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {professionalDevelopmentStates.showDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangePDDate(date, "date")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("pdMethods")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {professionalDevelopmentStates.method
                                ? professionalDevelopmentStates.method
                                : "Method *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {professionalDevelopmentStates.showMethod === true ? (
                        <ul className="dropdown-2">
                          {professionalDevelopmentMethods.map((data) => (
                            <li
                              onClick={() =>
                                setProfessionalDevelopmentStates({
                                  ...professionalDevelopmentStates,
                                  method: data,
                                  showMethod: false,
                                })
                              }
                            >
                              {data}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.courseName
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="courseName"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.courseName}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            courseName: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Course Name *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.organisation
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="completionTimeframe"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.organisation}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            organisation: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Organisation *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        onChange={(e) => uploadImages(e)}
                        type="file"
                        accept="image/jpg,image/jpeg,image/png,.pdf"
                        id="attach-file"
                        className="mdc-text-field__input"
                        aria-labelledby="my-label-id"
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Upload Certificate
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">image</span>
                    </label>
                    <small className="short-description mt-25">
                      Image can only be of the following types : jpg, jpeg, pdf
                      png with limit upto 5MB
                    </small>
                    <>
                      {professionalDevelopmentStates.certificate.length > 0
                        ? professionalDevelopmentStates.certificate.map(
                          (data, index) => {
                            let splittedArray = data.name.split(".")
                            let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                              splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                  : data.path
                            return (
                              <>
                                <img height="100" width="100" src={uploadedImageIcon} alt="" />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    removeSpecificImage(index);
                                  }}
                                >
                                  <span className="material-icons close">
                                    close
                                  </span>
                                </button>
                              </>
                            )
                          }
                        )
                        : null}
                    </>
                  </div>
                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenProfessionalDevelopmentModal(false);
                      setProfessionalDevelopmentStates(initialPDStates);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ADD Professional Development Diaog */}
      <Dialog
        open={openProfessionalDevelopmentModal}
        onClose={() => setOpenProfessionalDevelopmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Professional Development Log
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => professionaSubmit(e, isEditPDL)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHidePDCalender("date")}
                      >
                        <input
                          value={professionalDevelopmentStates.date !== '' ? moment(professionalDevelopmentStates.date).format('DD-MM-YYYY') : ''}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            professionalDevelopmentStates.date
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="completedDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Date Completed *
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {professionalDevelopmentStates.showDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangePDDate(date, "date")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("pdMethods")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {professionalDevelopmentStates.method
                                ? professionalDevelopmentStates.method
                                : "Method *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {professionalDevelopmentStates.showMethod === true ? (
                        <ul className="dropdown-2">
                          {professionalDevelopmentMethods.map((data) => (
                            <li
                              onClick={() =>
                                setProfessionalDevelopmentStates({
                                  ...professionalDevelopmentStates,
                                  method: data,
                                  showMethod: false,
                                })
                              }
                            >
                              {data}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.courseName
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="courseName"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.courseName}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            courseName: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Course Name *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.organisation
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="completionTimeframe"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.organisation}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            organisation: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Organisation *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>

                  <div className="col-lg-6 col-md-6 mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        onChange={(e) => uploadImages(e)}
                        type="file"
                        accept="image/jpg,image/jpeg,image/png,.pdf"
                        id="attach-file"
                        className="mdc-text-field__input"
                        aria-labelledby="my-label-id"
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Upload Certificate
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">image</span>
                    </label>
                    <small className="short-description mt-25">
                      Image can only be of the following types : jpg, jpeg, pdf
                      png with limit upto 5MB
                    </small>
                    <>
                      {professionalDevelopmentStates.certificate.length > 0
                        ? professionalDevelopmentStates.certificate.map(

                          (data, index) => {
                            let splittedArray = data.name.split(".")
                            let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                              splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                  : data.path
                            return (
                              <>
                                <img height="100" width="100" src={uploadedImageIcon} />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    removeSpecificImage(index);
                                  }}
                                >
                                  <span className="material-icons close">
                                    close
                                  </span>
                                </button>
                              </>
                            )
                          }
                        )
                        : null}
                    </>
                  </div>


                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenProfessionalDevelopmentModal(false);
                      setProfessionalDevelopmentStates(initialPDStates);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CaBTViewProfilePage;