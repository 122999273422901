import React, { useEffect, useState } from 'react';
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import './styles.css';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { getAllEducators, getSpecificUser, getLeaves, convertTimeStampToTimeCustom, taskTitleList, addNewVenue, getVenueList, editNewVenue, venueFilesUpload, uploadDocument, riskAssesment } from '../../../functions/api';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { Link, useHistory, useParams } from 'react-router-dom';
import { createTask, updateNode } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImage } from '../../../functions/api';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import imageToBase64 from 'image-to-base64/browser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { baseUrl } from '../../../config';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));


const AddVenue = (props) => {

	const { id } = useParams();
	const classes = useStyles();
	const regex = /(<([^>]+)>)/ig;

	const history = useHistory();
	const [venueName, setVenueName] = useState('');
	const [venueLocation, setVenueLocation] = useState('');
	const [addFileName, setAddFileName] = useState('');
	const [addFilePath, setAddFilePath] = useState('');
	const [covidVenueFilePath, setCovidVenueFilePath] = useState([]);
	const [covidFile, setCovidFile] = useState([])
	const [addImagePath, setAddImagePath] = useState([]);
	const [venueDescription, setVenueDescription] = useState('');
	const [ajaxLoader, setAjaxLoader] = useState('');
	const [showImage, setShowImage] = useState([]);

	const [showImagePath, setShowImagePath] = useState([]);

	const [venueImage, setVenueImage] = useState([])
	const [venueImageURL, setVenueImageURL] = useState('');

	const [ristAssessmentObject, setRiskAssessmentObject] = useState([]);
	const [riskFile, setRiskFile] = useState([])
	const [venueImageObject, setVenueImageObject] = useState([]);
	const [covidDocumentObject, setCovidDocumentObject] = useState([]);
	const [blockButton , setBlockButton] = useState(false);


	useEffect(() => {

		$('#chooseFile').bind('change', function () {
			var filename = $("#chooseFile").val();
			if (/^\s*$/.test(filename)) {
				$(".file-upload").removeClass('active');
				$("#noFile").text("No file chosen...");
			} else {
				$(".file-upload").addClass('active');
				$("#noFile").text(filename.replace("C:\\fakepath\\", ""));
			}
		});

		$(function () {
			$('.dropdown-toggle').click(function () {
				$(this).next('.dropdown').slideToggle();
			});

			$(document).click(function (e) {
				var target = e.target;
				if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) { $('.dropdown').slideUp(); }
			});
		});

		$(function () {
			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
				if ($(this).val().length > 0) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			})
		})
		if (id) {
			editVenue();
		}

	}, []);

	const [img_data, set_img_data] = useState([]);

	async function editVenue() {

		let list = await getVenueList(id);
		// 
		// 
		if (list.status == 200) {
			let imgArr = [];
			let imgData = [];
			// 
			if (list.data.data[0].field_venue_images) {
				let splitImg = list.data.data[0].field_venue_images.split(',');
				// 
				var read = new FileReader();
				// 
				let nextSplit = splitImg[0].split("/")
				// 
				imgArr.push(baseUrl + "" + splitImg)
				// 
				// read.onload = ()=>{

				// 	var base64String =read.result;
				// 	
				// if(splitImg.length > 0){
				// 	splitImg.map((v)=>{
				// 		imgArr.push({'name' : 'abc', 'path': `${baseUrl}/${v ? v.replace(" /","") : " "}`})
				// 		setShowImagePath(imgArr.push({'name' : 'abc', 'path': `${baseUrl}/${v ? v.replace(" /","") : " "}`}))
				// 	});

				// 	read.readAsDataURL(addImagePath);
				// 	
				// 	base64String.substr(base64String.indexOf(', ') + 1));
				// }

				// var read = new FileReader();
				// read.onload = () =>{
				// 	
				// 	splitImg.map((v)=>{
				// 		imgData.push({'name' : 'abc', 'path': `${baseUrl}/${v ? v.replace(" /","") : " "}`})
				// 	});
				// 	
				// 	read.readAsDataURL(addImagePath);
				// }
				//}

			};
			// 
			setAddImagePath(imgArr);
			set_img_data(imgData);
			setShowImage(list.data.data[0].field_venue_images);
			// 

			// 
			setVenueName(list.data.data[0].field_venue_name);
			setVenueLocation(list.data.data[0].field_location);
			if (list.data.data[0].field_risk_assessment_doc !== '') {
				setAddFileName(list.data.data[0].field_risk_assessment_doc.slice(29, 70));
				// 
			}

			if (list.data.data[0].field_covid_19_safe_plan !== "") {
				setCovidVenueFilePath([{ 'name': list.data.data[0].field_covid_19_safe_plan.slice(29, 70), 'path': list.data.data[0].field_covid_19_safe_plan ? list.data.data[0].field_covid_19_safe_plan : '' }]);
			}
			if (list.data.data[0].field_venue_images !== "") {
				let object = { "path": `${baseUrl}${list.data.data[0].field_venue_images}` }
				setShowImagePath(object)
				setVenueImageURL(`${baseUrl}${list.data.data[0].field_venue_images}`)
			}
		}
		else {

		}

	}

	const addFileHandler = (event) => {

		if (event.target.files && event.target.files[0]) {
			let name = event.target.files[0].name;
			if (event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && event.target.files[0].type !== 'application/msword') {
				toast.error('Invalid File Type. File can only be of the following types : pdf, doc, docx', {
					position: 'top-center'
				});
				return false;
			} else {
				if (event.target.files[0].size > 5000000) {
					toast.error('File size exceeds the limit of 5MB', {
						position: 'top-center'
					});
					return false;
				} else {

					setAddFileName(name);

					var reader = new FileReader();
					reader.onload = function (event) {
						let result = { "name": name, "path": event.target.result }
						setRiskAssessmentObject([result])
						// 
						setAddFilePath(event.target.result)
					};
					reader.readAsDataURL(event.target.files[0]);
				}
			}
		}
	}

	const selectImages = (event) => {
		// 
		if (event.target.files && event.target.files[0]) {
			if (Object.keys(event.target.files).length <= 5 && addImagePath.length < 5) {
				let imagesArray = addImagePath.length > 0 ? addImagePath : []

				Object.values(event.target.files).map(function (filesData, index) {
					let name = filesData.name;
					if (filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png') {
						toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
							position: 'top-center'
						});
						return false;
					}
					else {
						if (filesData.size > 5000000) {
							toast.error('Image size exceeds the limit of 5MB', {
								position: 'top-center'
							});
							return false;
						}
						else {
							var reader = new FileReader();
							reader.onload = function (event) {
								imagesArray.push({ 'name': name, 'path': event.target.result })
								setVenueImageObject([{ 'name': name, 'path': event.target.result }])
								let object = { "path": `${baseUrl}/sites/default/files/2022-09/${name}` }
								// 
								setShowImagePath(object)
								setVenueImageURL(event.target.result)
								// 
								setVenueImage(imagesArray);

							};
							reader.readAsDataURL(filesData);
						}
					}
				})
			}
			else {
				toast.error("You can't upload more than 5 images", { position: 'top-center' });
			}
		}
	}

	const removeSpecificImage = (index) => {
		let newArray = addImagePath
		newArray.splice(index, 1)
		setAddImagePath([...newArray])
	}

	const selectCovidPlanDoc = (event) => {
		if (event.target.files && event.target.files[0]) {
			if (Object.keys(event.target.files).length <= 5 && covidVenueFilePath.length < 5) {
				let imagesArray = covidVenueFilePath.length > 0 ? [] : []
				// 
				Object.values(event.target.files).map(function (filesData, index) {
					let name = filesData.name;
					// 
					if (filesData.type !== 'application/pdf' && filesData.type !== 'image/jpg' && filesData.type !== 'image/png' && filesData.type !== 'image/jpeg' &&
						filesData.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && filesData.type !== 'application/msword') {
						toast.error('Invalid Type. You can only Upload the following types : jpg, jpeg, png, pdf, doc and docx', {
							position: 'top-center'
						});
						return false;
					}
					else {
						if (filesData.size > 5000000) {
							toast.error('Image size exceeds the limit of 5MB', {
								position: 'top-center'
							});
							return false;
						} else {
							var reader = new FileReader();
							reader.onload = function (event) {
								imagesArray.push({ 'name': name, 'path': event.target.result })
								setCovidDocumentObject([{ 'name': name, 'path': event.target.result }])
								setCovidVenueFilePath([...imagesArray])
							};
							reader.readAsDataURL(filesData);
						}
					}
				})
			}
			else {
				toast.error("You can't upload more than 5 COVID-19 Safe Plan", { position: 'top-center' });
			}
		}
	}

	const removeSpecificCovidPlan = (index) => {
		let newArray = covidVenueFilePath
		newArray.splice(index, 1)
		setCovidVenueFilePath([...newArray])
	}

	const validateFields = () => {
		let formIsValid = true;
		if (!venueName) {
			toast.error('Venue name is required', {
				position: 'top-center'
			});
			formIsValid = false
		}
		else if (!venueLocation) {
			toast.error('Venue Location is required', {
				position: 'top-center'
			});
			formIsValid = false
		}
		return formIsValid;
	}

	async function onSubmit(e) {

		e.preventDefault();
		setAjaxLoader(true)
		// 
		if (id) {
			setBlockButton(true)

			let obj = {
				"type": [{
					"target_id": "venue",
					"target_type": "paragraphs_type"
				}],
				"field_venue_name": [{ "value": venueName }],
				"field_location": [{ "value": venueLocation }],
			}
			if (ristAssessmentObject.length > 0) {

				const riskAssessmentTid = await riskAssesment(ristAssessmentObject[0].name, ristAssessmentObject[0].path);

				let obj = {
					"type": [{
						"target_id": "venue",
						"target_type": "paragraphs_type"
					}],
					"field_risk_assessment_doc": [{ "target_id": riskAssessmentTid.data.data.fid[0].value }]

				}

				await editNewVenue(id, obj);
			}
			if (venueImageObject.length > 0) {

				const venueFileTid = await venueFilesUpload(venueImageObject[0].name, venueImageObject[0].path);

				let obj = {
					"type": [{
						"target_id": "venue",
						"target_type": "paragraphs_type"
					}],
					"field_venue_images": [{ "target_id": venueFileTid.data.data.fid[0].value }]

				}
				await editNewVenue(id, obj);
			}
			if (covidDocumentObject.length > 0) {

				const covidDocTid = await uploadDocument(covidDocumentObject[0].name, covidDocumentObject[0].path, "venue");

				let obj = {
					"type": [{
						"target_id": "venue",
						"target_type": "paragraphs_type"
					}],
					"field_covid_19_safe_plan": [{ "target_id": covidDocTid.data.data.fid[0].value }]

				}
				await editNewVenue(id, obj);
			}
            

			if(!(ristAssessmentObject.length >0) && addFileName ==''){
			  obj = {
					"type": [{
						"target_id": "venue",
						"target_type": "paragraphs_type"
					}],
					"field_venue_name": [{ "value": venueName }],
					"field_location": [{ "value": venueLocation }],
					"field_risk_assessment_doc": [{ "target_id":  null}]
				}
				// alert("Risk Assessment")
				await editNewVenue(id, obj);
			}
			if(!(covidDocumentObject.length > 0) && covidVenueFilePath ==''){
				obj = {
					  "type": [{
						  "target_id": "venue",
						  "target_type": "paragraphs_type"
					  }],
					  "field_venue_name": [{ "value": venueName }],
					  "field_location": [{ "value": venueLocation }],
					  "field_covid_19_safe_plan": [{ "target_id":  null}]
				  }
				// alert("Covid Safety Plans")

				  await editNewVenue(id, obj);
			  }

			  if(!(venueImageObject.length > 0) && venueImageURL ==''){
				obj = {
					  "type": [{
						  "target_id": "venue",
						  "target_type": "paragraphs_type"
					  }],
					  "field_venue_name": [{ "value": venueName }],
					  "field_location": [{ "value": venueLocation }],
					  "field_venue_images": [{ "target_id":  null}]
				  }
				//   alert("Venue Image")
				//   alert("Works")
				  await editNewVenue(id, obj);
			  }



			const updateResponse = await editNewVenue(id, obj);
			if (updateResponse.status === 200) {
				toast.success("Venue updated successfully!!!", {
					position: 'top-center'
				});
				history.goBack();
			}

			// let obj =	{
			// 	"type":[{
			// 		"target_id":"venue",
			// 		"target_type": "paragraphs_type"
			// 	}],
			// 	"field_venue_name":[{"value": venueName}],
			// 	"field_location":[{"value": venueLocation}],
			// }
			// if(validateFields())
			// {	

			// 	
			// 	if(addFileName && covidVenueFilePath.length > 0 && addImagePath.length > 0){
			// 		// 
			// 		setAjaxLoader(false);

			// 		let response = await riskAssesment(addFileName, addFilePath);
			// 		if(response.status == 201){
			// 			setAjaxLoader(false);
			// 			
			// 			let imagesArray = []
			// 			var read= new FileReader();
			// 			read.onload=function (addImagePath){
			// 				// 
			// 				read.readAsDataURL(addImagePath);
			// 			}
			// 			// 
			// 			// venueImage.map(async(imageData)=>{
			// 				// 

			// 				let responseObj = await venueFilesUpload(venueImage[1].name, venueImage[1].path);
			// 				// 
			// 				if(responseObj.status === 201)
			// 				{
			// 					// setAjaxLoader(false);
			// 					let newObj = { "target_id": responseObj.data.data.fid[0].value }
			// 					imagesArray.push(newObj)
			// 					// 
			// 					// 

			// 					if(imagesArray.length === 1)
			// 					{	
			// 						// 
			// 						let covidImagesArray = []
			// 						covidVenueFilePath.map(async(imageData)=>{
			// 							// 
			// 							let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
			// 							if(covidResponseObj.status == 201)
			// 							{
			// 								setAjaxLoader(false);
			// 								// 
			// 								let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
			// 								covidImagesArray.push(newObj)
			// 								if(covidImagesArray.length == covidVenueFilePath.length)
			// 								{
			// 									let dataObject =	{
			// 										"type":[{
			// 										"target_id":"venue",
			// 										"target_type": "paragraphs_type"
			// 										}],
			// 										"field_venue_name":[{"value": venueName}],
			// 										"field_location":[{"value": venueLocation}],
			// 										"field_venue_images":[{"value": addImagePath}],
			// 										"field_risk_assessment_doc": [
			// 										{
			// 											"target_id": response.data.data.fid[0].value
			// 										}
			// 										],
			// 										"field_covid_19_safe_plan": covidImagesArray
			// 									}

			// 									let createResponse = await editNewVenue(id,dataObject)
			// 									if(createResponse.status == 200)
			// 									{
			// 										setAjaxLoader(false);
			// 										
			// 										toast.success("Venue updated successfully!!!", {
			// 											position: 'top-center'
			// 										});
			// 										setVenueName("")
			// 										setVenueLocation("")
			// 										setAddFileName("")
			// 										setAddFilePath("")
			// 										setAddImagePath([]);
			// 										history.push("/all-venues");
			// 									}
			// 									else
			// 									{
			// 										setAjaxLoader(false);
			// 										toast.error(createResponse.message, {
			// 											position: 'top-center'
			// 										});
			// 									}
			// 								}
			// 							}
			// 						})

			// 					}

			// 				}
			// 				else
			// 				{
			// 					setAjaxLoader(false);
			// 					toast.error(responseObj.message, {
			// 				position: 'top-center'
			// 			});
			// 				// }
			// 			}
			// 			// )

			// 		}else{
			// 			setAjaxLoader(false);
			// 			toast.error(response.message, {position: 'top-center'});
			// 		}
			// 	}
			// 	else if(covidVenueFilePath.length > 0 && addImagePath.length > 0){
			// 		

			// 		let imagesArray = []
			// 		var read= new FileReader();
			// 		read.onload=function (addImagePath){
			// 			
			// 			read.readAsDataURL(addImagePath);
			// 		}
			// 		addImagePath.map(async(imageData)=>{
			// 			let responseObj = await venueFilesUpload(imageData.name, imageData.path);
			// 			if(responseObj.status == 201)
			// 			{
			// 				setAjaxLoader(false);
			// 				
			// 				let newObj = { "target_id": responseObj.data.data.fid[0].value }
			// 				imagesArray.push(newObj)
			// 				if(imagesArray.length == addImagePath.length)
			// 				{	
			// 					let covidImagesArray = []
			// 					covidVenueFilePath.map(async(imageData)=>{
			// 						let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
			// 						if(covidResponseObj.status == 201)
			// 						{
			// 							setAjaxLoader(false);
			// 							
			// 							let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
			// 							covidImagesArray.push(newObj)
			// 							if(covidImagesArray.length == covidVenueFilePath.length)
			// 							{
			// 								
			// 								let dataObject =	{
			// 									"type":[{
			// 									"target_id":"venue",
			// 									"target_type": "paragraphs_type"
			// 									}],
			// 									"field_venue_name":[{"value": venueName}],
			// 									"field_location":[{"value": venueLocation}],
			// 									"field_venue_images":imagesArray,
			// 									"field_covid_19_safe_plan": covidImagesArray
			// 								}

			// 								let createResponse = await editNewVenue(id,dataObject)
			// 								if(createResponse.status == 200)
			// 								{
			// 									
			// 									setAjaxLoader(false);
			// 									toast.success("Venue updated successfully!!!", {
			// 										position: 'top-center'
			// 									});
			// 									setVenueName("")
			// 									setVenueLocation("")
			// 									setAddFileName("")
			// 									setAddFilePath("")
			// 									setAddImagePath([]);
			// 									history.push("/all-venues");
			// 								}
			// 								else
			// 								{
			// 									setAjaxLoader(false);
			// 									toast.error(createResponse.message, {
			// 										position: 'top-center'
			// 									});
			// 								}
			// 							}
			// 							else
			// 							{
			// 								
			// 							}
			// 						}
			// 					})

			// 				}

			// 			}
			// 			else
			// 			{
			// 				setAjaxLoader(false);
			// 				toast.error(responseObj.message, {
			// 			position: 'top-center'
			// 		});
			// 			}
			// 		})

			// 	}
			// 	else if(addFileName && covidVenueFilePath.length > 0){
			// 		

			// 		let response = await riskAssesment(addFileName, addFilePath);
			// 		if(response.status == 201){
			// 			

			// 			let covidImagesArray = []
			// 				covidVenueFilePath.map(async(imageData)=>{
			// 					
			// 					let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
			// 					if(covidResponseObj.status == 201)
			// 					{
			// 						
			// 						let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
			// 						covidImagesArray.push(newObj)
			// 						
			// 						if(covidImagesArray.length === covidVenueFilePath.length)
			// 						{										
			// 							let dataObject =	{
			// 								"type":[{
			// 								"target_id":"venue",
			// 								"target_type": "paragraphs_type"
			// 								}],
			// 								"field_venue_name":[{"value": venueName}],
			// 								"field_location":[{"value": venueLocation}],
			// 								// "field_venue_images":imagesArray,
			// 								"field_risk_assessment_doc": [
			// 								{
			// 									"target_id": response.data.data.fid[0].value
			// 								}
			// 								],
			// 								"field_covid_19_safe_plan": covidImagesArray
			// 							}

			// 							let createResponse = await editNewVenue(id,dataObject)
			// 							
			// 							if(createResponse.status == 200)
			// 							{
			// 								
			// 								setAjaxLoader(false);
			// 								toast.success("Venue updated successfully!!!", {
			// 									position: 'top-center'
			// 								});
			// 								setVenueName("")
			// 								setVenueLocation("")
			// 								setAddFileName("")
			// 								setAddFilePath("")
			// 								setAddImagePath([]);
			// 								history.push("/all-venues");
			// 							}
			// 							else
			// 							{
			// 								setAjaxLoader(false);
			// 								toast.error(createResponse.message, {
			// 									position: 'top-center'
			// 								});
			// 							}
			// 						}
			// 					}
			// 				})
			// 		}
			// 	}
			// 	else if(addImagePath.length > 0 && addFileName){
			// 		
			// 		let imagesArray = []
			// 		var read= new FileReader();
			// 		read.onload=function (addImagePath){
			// 			
			// 			read.readAsDataURL(addImagePath);
			// 		}
			// 		addImagePath.map(async(imageData)=>{
			// 			let responseObj = await venueFilesUpload(imageData.name, imageData.path);
			// 			if(responseObj.status == 201)
			// 			{
			// 				
			// 				let newObj = { "target_id": responseObj.data.data.fid[0].value }
			// 				imagesArray.push(newObj)
			// 				if(imagesArray.length == addImagePath.length)
			// 				{	
			// 					let response = await riskAssesment(addFileName, addFilePath);
			// 					if(response.status == 201)
			// 					{
			// 						let dataObject =	{
			// 							"type":[{
			// 							"target_id":"venue",
			// 							"target_type": "paragraphs_type"
			// 							}],
			// 							"field_venue_name":[{"value": venueName}],
			// 							"field_location":[{"value": venueLocation}],
			// 							"field_venue_images":imagesArray,
			// 							"field_risk_assessment_doc": [
			// 								{
			// 									"target_id": response.data.data.fid[0].value
			// 								}
			// 							]
			// 						}
			//                          
			// 						let createResponse = await editNewVenue(id,dataObject)
			// 							
			// 							if(createResponse.status == 200)
			// 							{
			// 								
			// 								setAjaxLoader(false);
			// 								toast.success("Venue updated successfully!!!", {
			// 									position: 'top-center'
			// 								});
			// 								setVenueName("")
			// 								setVenueLocation("")
			// 								setAddFileName("")
			// 								setAddFilePath("")
			// 								setAddImagePath([]);
			// 								history.push("/all-venues");
			// 							}
			// 							else
			// 							{
			// 								setAjaxLoader(false);
			// 								toast.error(createResponse.message, {
			// 									position: 'top-center'
			// 								});
			// 							}

			// 					}
			// 				}

			// 			}
			// 			else
			// 			{
			// 				setAjaxLoader(false);
			// 				toast.error(responseObj.message, {
			// 			position: 'top-center'
			// 		});
			// 			}
			// 		})
			// 	}
			// 	else if(addFileName){
			// 		
			// 		let riskAss = await riskAssesment(addFileName, addFilePath);
			// 		if(riskAss.status == 201){
			// 			
			// 			obj.field_risk_assessment_doc = [{
			// 				"target_id": riskAss.data.data.fid[0].value
			// 			}]
			// 			let addVenue = await editNewVenue(id,obj);
			// 			if(addVenue.status == 200){
			// 				setAjaxLoader(false);
			// 				toast.success("Venue updated successfully!!!", {position: 'top-center'});
			// 				setVenueName("");
			// 				setVenueLocation("");
			// 				setAddFileName("");
			// 				setAddFilePath("");
			// 				history.push("/all-venues");
			// 			}
			// 		}
			// 	}
			// 	else if(addImagePath.length > 0){
			// 		
			// 		let imgArray = []

			// 		addImagePath.map(async(v)=>{
			// 			
			// 			let venueImage = await venueFilesUpload(v.name,v.path);
			// 			if(venueImage.status == 201){
			// 				
			// 				let newObj = { "target_id": venueImage.data.data.fid[0].value}
			// 				imgArray.push(newObj)

			// 				obj.field_venue_images = imgArray;
			// 				

			// 				let addNameLocation = await editNewVenue(id,obj);
			// 				
			// 				if(addNameLocation.status == 200){
			// 					
			// 					setAjaxLoader(false);
			// 					toast.success("Venue updated successfully!!!", {position: 'top-center'});
			// 					setVenueName("");
			// 					setVenueLocation("");
			// 					setAddImagePath([]);
			// 					history.push("/all-venues");
			// 				}
			// 			}
			// 			
			// 		})	

			// 	}
			// 	else if(covidVenueFilePath.length > 0){
			// 		
			// 		let covidImagesArray = []
			// 			covidVenueFilePath.map(async(imageData)=>{
			// 			let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
			// 				if(covidResponseObj.status == 201)
			// 				{
			// 					
			// 					let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
			// 					covidImagesArray.push(newObj)
			// 					if(covidImagesArray.length == covidVenueFilePath.length)
			// 					{
			// 					obj.field_covid_19_safe_plan = covidImagesArray;
			// 					let createResponse = await editNewVenue(id,obj);
			// 						if(createResponse.status == 200)
			// 						{
			// 							setAjaxLoader(false);
			// 							toast.success("Venue updated successfully!!!", {
			// 								position: 'top-center'
			// 							});
			// 							setVenueName("");
			// 							setVenueLocation("");
			// 							setAddFileName("");
			// 							setAddFilePath("");
			// 							setAddImagePath([]);
			// 							history.push("/all-venues");
			// 						}
			// 						else
			// 						{
			// 							setAjaxLoader(false);
			// 							toast.error(createResponse.message, {
			// 								position: 'top-center'
			// 							});
			// 						}
			// 					}
			// 				}
			// 			})

			// 	}
			// 	else{
			// 		
			// 		let addNameLocation = await editNewVenue(id,obj);
			// 		
			// 		if(addNameLocation.status == 200){
			// 			toast.success("Venue updated successfully!!!", {position: 'top-center'});
			// 			setVenueName("");
			// 			setVenueLocation("");
			// 			history.push("/all-venues");
			// 		}
			// 	}
			// }
		} else {

			let obj = {
				"type": [{
					"target_id": "venue",
					"target_type": "paragraphs_type"
				}],
				"field_venue_name": [{ "value": venueName }],
				"field_location": [{ "value": venueLocation }],
			}

			if (validateFields()) {
				setBlockButton(true)
				if (addFileName && covidVenueFilePath.length > 0 && venueImage.length > 0) {
					// 
					let response = await riskAssesment(addFileName, addFilePath);
					if (response.status == 201) {
						let imagesArray = []
						venueImage.map(async (imageData) => {
							let responseObj = await venueFilesUpload(imageData.name, imageData.path);
							if (responseObj.status == 201) {
								let newObj = { "target_id": responseObj.data.data.fid[0].value }
								imagesArray.push(newObj)
								if (imagesArray.length === venueImage.length) {
									let covidImagesArray = []
									covidVenueFilePath.map(async (imageData) => {
										let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
										if (covidResponseObj.status == 201) {
											let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
											covidImagesArray.push(newObj)
											if (covidImagesArray.length === covidVenueFilePath.length) {
												let dataObject = {
													"type": [{
														"target_id": "venue",
														"target_type": "paragraphs_type"
													}],
													"field_venue_name": [{ "value": venueName }],
													"field_location": [{ "value": venueLocation }],
													"field_venue_images": imagesArray,
													"field_risk_assessment_doc": [
														{
															"target_id": response.data.data.fid[0].value
														}
													],
													"field_covid_19_safe_plan": covidImagesArray
												}
												// 
												let createResponse = await addNewVenue(dataObject)
												if (createResponse.status == 201) {
													setAjaxLoader(false);
													toast.success("Venue added successfully!!!", {
														position: 'top-center'
													});
													setVenueName("")
													setVenueLocation("")
													setAddFileName("")
													setAddFilePath("")
													setAddImagePath([])
													setBlockButton(false)
												}
												else {
													setAjaxLoader(false);
													toast.error(createResponse.message, {
														position: 'top-center'
													});
												}
											}
										}
									})

								}

							}
							else {
								setAjaxLoader(false);
								toast.error(responseObj.message, {
									position: 'top-center'
								});
							}
						})

					} else {
						setAjaxLoader(false);
						toast.error(response.message, { position: 'top-center' });
					}
				}
				else if (covidVenueFilePath.length > 0 && addImagePath.length > 0) {
					// 

					let imagesArray = []
					addImagePath.map(async (imageData) => {
						let responseObj = await venueFilesUpload(imageData.name, imageData.path);
						if (responseObj.status === 201) {
							// 
							let newObj = { "target_id": responseObj.data.data.fid[0].value }
							imagesArray.push(newObj)
							if (imagesArray.length === addImagePath.length) {
								let covidImagesArray = []
								covidVenueFilePath.map(async (imageData) => {
									let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
									if (covidResponseObj.status === 201) {
										// 
										let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
										covidImagesArray.push(newObj)
										if (covidImagesArray.length === covidVenueFilePath.length) {
											// 
											let dataObject = {
												"type": [{
													"target_id": "venue",
													"target_type": "paragraphs_type"
												}],
												"field_venue_name": [{ "value": venueName }],
												"field_location": [{ "value": venueLocation }],
												"field_venue_images": imagesArray,
												"field_covid_19_safe_plan": covidImagesArray
											}

											let createResponse = await addNewVenue(dataObject)
											if (createResponse.status === 201) {
												// 
												setAjaxLoader(false);
												toast.success("Venue submit successfully!!!", {
													position: 'top-center'
												});
												setVenueName("")
												setVenueLocation("")
												setAddFileName("")
												setAddFilePath("")
												setAddImagePath([])
											}
											else {
												setAjaxLoader(false);
												toast.error(createResponse.message, {
													position: 'top-center'
												});
											}
										}
										else {
											// 
										}
									}
								})

							}

						}
						else {
							setAjaxLoader(false);
							toast.error(responseObj.message, {
								position: 'top-center'
							});
						}
					})

				}
				else if (addFileName && covidVenueFilePath.length > 0) {
					// 

					let response = await riskAssesment(addFileName, addFilePath);
					if (response.status == 201) {
						// 

						let covidImagesArray = []
						covidVenueFilePath.map(async (imageData) => {
							let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
							if (covidResponseObj.status == 201) {
								// 
								let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
								covidImagesArray.push(newObj)
								if (covidImagesArray.length === covidVenueFilePath.length) {
									let dataObject = {
										"type": [{
											"target_id": "venue",
											"target_type": "paragraphs_type"
										}],
										"field_venue_name": [{ "value": venueName }],
										"field_location": [{ "value": venueLocation }],
										// "field_venue_images":imagesArray,
										"field_risk_assessment_doc": [
											{
												"target_id": response.data.data.fid[0].value
											}
										],
										"field_covid_19_safe_plan": covidImagesArray
									}

									let createResponse = await addNewVenue(dataObject)
									// 
									if (createResponse.status === 201) {
										// 
										setAjaxLoader(false);
										toast.success("Venue submit successfully!!!", {
											position: 'top-center'
										});
										setVenueName("")
										setVenueLocation("")
										setAddFileName("")
										setAddFilePath("")
										setAddImagePath([])
									}
									else {
										setAjaxLoader(false);
										toast.error(createResponse.message, {
											position: 'top-center'
										});
									}
								}
							}
						})
					}
				}
				else if (addImagePath.length > 0 && addFileName) {
					// 
					let imagesArray = []
					addImagePath.map(async (imageData) => {
						let responseObj = await venueFilesUpload(imageData.name, imageData.path);
						if (responseObj.status === 201) {
							// 
							let newObj = { "target_id": responseObj.data.data.fid[0].value }
							imagesArray.push(newObj)
							if (imagesArray.length === addImagePath.length) {
								let response = await riskAssesment(addFileName, addFilePath);
								if (response.status == 201) {
									let dataObject = {
										"type": [{
											"target_id": "venue",
											"target_type": "paragraphs_type"
										}],
										"field_venue_name": [{ "value": venueName }],
										"field_location": [{ "value": venueLocation }],
										"field_venue_images": imagesArray,
										"field_risk_assessment_doc": [
											{
												"target_id": response.data.data.fid[0].value
											}
										]
									}

									let createResponse = await addNewVenue(dataObject)
									// 
									if (createResponse.status === 201) {
										// 
										setAjaxLoader(false);
										toast.success("Venue submit successfully!!!", {
											position: 'top-center'
										});
										setVenueName("")
										setVenueLocation("")
										setAddFileName("")
										setAddFilePath("")
										setAddImagePath([])
									}
									else {
										setAjaxLoader(false);
										toast.error(createResponse.message, {
											position: 'top-center'
										});
									}

								}
							}

						}
						else {
							setAjaxLoader(false);
							toast.error(responseObj.message, {
								position: 'top-center'
							});
						}
					})
				}
				else if (addFileName) {
					// 
					let riskAss = await riskAssesment(addFileName, addFilePath);
					if (riskAss.status == 201) {
						obj.field_risk_assessment_doc = [{
							"target_id": riskAss.data.data.fid[0].value
						}]
						let addVenue = await addNewVenue(obj);
						if (addVenue.status == 201) {
							toast.success("Venue added successfully!!!", { position: 'top-center' });
							setVenueName("");
							setVenueLocation("");
							setAddFileName("");
							setAddFilePath("");
						}
					}
				}
				else if (addImagePath.length > 0) {
					// 
					let imgArray = []
					addImagePath.map(async (v) => {
						let venueImage = await venueFilesUpload(v.name, v.path);
						if (venueImage.status == 201) {
							let newObj = { "target_id": venueImage.data.data.fid[0].value }
							imgArray.push(newObj)
						}
						// 
					})
					obj.field_venue_images = imgArray;

					let addNameLocation = await addNewVenue(obj);
					// 
					if (addNameLocation.status == 201) {
						toast.success("Venue added successfully!!!", { position: 'top-center' });
						setVenueName("");
						setVenueLocation("");
						setAddImagePath([]);
					}

				}
				else if (covidVenueFilePath.length) {
					// 
					let covidImagesArray = []
					covidVenueFilePath.map(async (imageData) => {
						let covidResponseObj = await uploadDocument(imageData.name, imageData.path, "venue");
						if (covidResponseObj.status === 201) {
							let newObj = { "target_id": covidResponseObj.data.data.fid[0].value }
							covidImagesArray.push(newObj)
							if (covidImagesArray.length === covidVenueFilePath.length) {
								obj.field_covid_19_safe_plan = covidImagesArray;
								let createResponse = await addNewVenue(obj);
								if (createResponse.status === 201) {
									setAjaxLoader(false);
									toast.success("Venue added successfully!!!", {
										position: 'top-center'
									});
									setVenueName("")
									setVenueLocation("")
									setAddFileName("")
									setAddFilePath("")
									setAddImagePath([])
								}
								else {
									setAjaxLoader(false);
									toast.error(createResponse.message, {
										position: 'top-center'
									});
								}
							}
						}
					})

				}
				else {
					// 
					let addNameLocation = await addNewVenue(obj);
					// 
					if (addNameLocation.status == 201) {
						toast.success("Venue added successfully!!!", { position: 'top-center' });
						setVenueName("");
						setVenueLocation("");
					}
				}
			}
		}
		setAjaxLoader(false)
	}

	async function uploadVenueImage(name, path) {
		let venueImage = await venueFilesUpload(name, path);
		if (venueImage.status == 201) {
			return venueImage.data.data.fid[0].value;
		}
	}

	const setNullList = async () => {
		setShowImagePath({})
		setVenueImageURL('')

	}
	const setNull = async () => {
		setCovidVenueFilePath('')
		setCovidDocumentObject([])
	}
	const setNullRisk = async () => {
		setAddFileName('')
		setRiskAssessmentObject([])
	}

	return (
		<>
			<ToastContainer />
			<HeadOfficeMainHeader />
			<LoaderComponent displayLoader={ajaxLoader} />
			<div className="main-wrapper">
				<div className="container">
					<div className="user-pages-wrap">
						<div className="row">
							<div className="col-lg-12">
								<div className="user-types-wrap course-detail-page text-center">
									<div className="common-header-row common-header-with-large-text">
										<h4 onClick={() => console.log("img_data", img_data)}>{id ? "Update Venue" : "Add Venue"} </h4>
										<Link to={`/all-venues`} class="mdc-button mdc-button--raised ">
											<span className="mdc-button__label">View Existing Venue</span>
										</Link>
										<div className="block-right">
											<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
										</div>
									</div>

									<form onSubmit={onSubmit} className="sign-up-form">
										<div className="row">
											<div className="col-lg-6 col-md-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													<input maxlength="30"
														value={venueName}
														onChange={(e) => setVenueName(e.target.value)}
														type="text"
														className={venueName !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'}
														aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">Venue Name</span>
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
												</label>
											</div>

											<div className="col-lg-6 col-md-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													<input maxlength="30" value={venueLocation} onChange={(e) => setVenueLocation(e.target.value)} type="text" className={venueLocation !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">Location</span>
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
												</label>
											</div>


											<div className="col-lg-6 col-md-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													{addFileName ? (
														<label className="reminder-field-style">Risk Assessment</label>
													) : null}
													<input onChange={(e) => addFileHandler(e)} type="file" accept=".pdf,.docx,.doc" id="attach-file" aria-labelledby="my-label-id"
														className='mdc-text-field__input'
														placeholder="Risk Assessment"
													/>
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">{addFileName ? addFileName.replace(/%/g, '_') : "Risk Assessment"}</span>
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
													<span className="material-icons">attach_file</span>
												</label>
												<small className="short-description">File can only be of the following types : pdf, doc, docx with limit upto 5MB</small>
												<>
													
													{addFileName != '' && riskFile ? (

														<>

															<button type="button" onClick={() => setNullRisk()}><span className="material-icons close">close</span></button>
														</>
													) : null}
													
												</>



											</div>

											<div className="col-lg-6 col-md-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													{showImagePath && showImagePath.path ? (
														<label className="reminder-field-style">Upload Venue Image</label>
													) : null}
													<input onChange={(e) => selectImages(e)} multiple type="file" accept="image/jpg,image/jpeg,image/png" id="attach-file" className="mdc-text-field__input" aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">

															<span className="mdc-floating-label" id="my-label-id">{showImagePath && showImagePath.path ? showImagePath.path.slice(59, 74).replace(/%/g, '_') : "Upload Venue Image"}</span>
															{/* {console.log("J-D ImagePath",showImagePath)} */}
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
													<span className="material-icons">image</span>
												</label>
												{/* <small className="short-description">Upload maximum 5 image of the following types : jpg, jpeg, png with limit upto 2MB</small> */}
												<>
													{/* {
													venueImage.length > 0 ?
													venueImage.map((data,index)=>
													
														<> */}
													{venueImageURL != '' && venueImage ? (

														<>


															<img height="100" width="100" src={venueImageURL} />
															<button type="button" onClick={() => setNullList()}><span className="material-icons close">close</span></button>
														</>
													) : null}
													{/* ) 
													: null
												} */}
												</>
											</div>

											<div className="col-lg-6 col-md-6">
												<label className="mdc-text-field mdc-text-field--outlined">
													{covidVenueFilePath.length && covidVenueFilePath[0].name ? (
														<label className="reminder-field-style">COVID Safe Plans</label>
													) : null}
													<input onChange={(e) => selectCovidPlanDoc(e)} type="file" accept=".pdf" id="attach-file" className="mdc-text-field__input" aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">
																{covidVenueFilePath.length > 0 ?
																	(covidVenueFilePath[0].name).replace(/%/g, '_') : "COVID Safe Plans"}</span>
														</span>
														<span className="mdc-notched-outline__trailing">
														</span>
													</span>
													<span className="material-icons">attach_file</span>
												</label>
												<small className="short-description">Upload pdf, doc, docx and jpeg/png to COVID plan with limit upto 5MB</small>

												<>
													
													{covidVenueFilePath != '' && covidFile ? (

														<>



															<button type="button" onClick={() => setNull()}><span className="material-icons close">close</span></button>
														</>
													) : null}
													
												</>
											</div>

											{/* <div className="col-lg-6 col-md-6">
										    <div className="outlined-textfield-wrap mt-10">
										    	<div className="material-textfield">
										 	    	<textarea 
														id="message" 
														className="form__field" 
														placeholder="" rows="2"  
														value={venueDescription} 
														onChange={(e)=>setVenueDescription(e.target.value)} 
													></textarea>
										 	    	<label>Session Description</label>
										 	  	</div>
										   	</div>
									 	</div> */}

										</div>

										<button type='submit'  disabled={blockButton} className="mdc-button mdc-button--raised mt-5">
											<span className="mdc-button__label">{id ? "Update" : "Submit"}</span>
										</button>

									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}

export default AddVenue;