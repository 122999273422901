import React ,{useState, useEffect, useCallback} from "react"
import { useParams } from "react-router-dom";
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { getAllFolders, createNewFolder, getFolderFiles } from '../../../functions/api'
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import EducatorFolderDetail from './EducatorFolderDetail'
import EducatorFilesDetail from './EducatorFilesDetail'
import FolderNavigation from './FolderNavigation'


//Import Images
import favourite from '../../../images/favorite.svg';
import privateFolder from '../../../images/private.svg';
import folder from '../../../images/folder.svg';
import folderTwo from '../../../images/folder-two.svg';

//jquiery
import { $ } from 'react-jquery-plugin';



const EducatorFolders=({show, educatorId})=>
{
	const { alias1 } = useParams();
	const [allFolders, setAllFolders] = useState([]);
	const[ childFolderData , setChildFolderData] = useState([]);
	const [parentID , setParentID] = useState('');
	const [ glow, setGlow] = useState('')
	useEffect(()=>
	{
		
	},[])

	const setFoldersData=(value)=>{
		setAllFolders(value)
	}

	const ToastComponent = React.memo(props => {
		return(<ToastContainer />)
	});


	const getChildFolderData=(value)=>{
		// 
		setChildFolderData(value)
	}

	const getParentId =(value) =>{
		// 
		setParentID(value)
	}

	const getSelectedId =(value)=>{
		
		setGlow(value);
	}


	return(
	<>
		<ToastComponent />
		{!show ?(
		<EducatorsMainHeader />	):null}
		<div className="main-wrapper mtb-70">
			<div className="container">	
				<div className="dashboard-wrap">
					<div className="row">
							<FolderNavigation data={allFolders} educatorId={educatorId} getChildFolderData={getChildFolderData} getParentId={getParentId} glowId={glow}/>	

							{
								alias1 !== undefined ?
								<EducatorFilesDetail educatorId={educatorId} getSelectedId={getSelectedId} />
								:
								<EducatorFolderDetail educatorId={educatorId} getFolders={setFoldersData} childData={childFolderData} parentId={parentID} />
							}
								
					</div>
				</div>		
			</div>	
		</div>
	</>
	)
}

export default EducatorFolders