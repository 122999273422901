import React, {useState} from 'react';
import { useEffect } from 'react';
import { $ } from 'react-jquery-plugin';
import { getSpecificUser } from '../../../functions/api';
import Calendar from 'react-calendar';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './styles.css'
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getUserProfileData, getSpecificTaxonomy, convertTimeStampToTimeCustom, updateOccupants, getOccupant, updateUserProfile,deleteOccupant } from '../../../functions/api';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import moment from "moment"
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const locale = 'fr-CA';
const EditEducatorProfilePage = (props) => {

    let history = useHistory();
    const classes = useStyles();
    const [profileUid, setProfileId] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [dob, setDob] = useState();
    const [dateofbirthCalendar, setDateofbirthCalendar] = useState(false);
    const [commencementDate,setCommencementDate] = useState();
    const [commenceCalendar, setCommenceCalendar] = useState(false);
    const [ceasedDate, setCeasedDate] = useState();
    const [ceaseCalendar, setCeasecalendar] = useState(false);
    const [homeNumber,setHomeNumber] = useState('');
    const [suburbName,setSuburbName] = useState('');
    const [timing,setTiming] = useState([]);

    const [qualification, setQualification] = useState('');
    const [openingTimePick, setOpeningTimePick] = useState(false); 
    const [openingHours, setOpeningHours] = useState('')
    const [closingHours, setClosingHours] = useState('')
    const [closingTimePick, setClosingTimePick] = useState(false);

    //new added 
    const [address, setAddress] = useState('')
    const [educatorBlueCard , setEducatorBlueCard] =useState('')
    const [residentExpDate , setResidentExpDate]=useState('')
    const [residentSightedDate , setResidentSightedDate] = useState('')

    const[registerNumber , setRegisterNumber] = useState('')
    const[regExpiryDate , setRegExpiryDate] = useState('')
  var finalOpeningHours = "";
  var finalClosingHours ="";
    function openingTimepicker(){
		setOpeningTimePick(true)
	}

    const openingTimeHandler=(newTime)=>{    
      	setOpeningHours(newTime)
    }

    const closingTimepicker = () => {
        setClosingTimePick(true)
    }

    const closingTimeHandler=(newTime)=>{ 
      	setClosingHours(newTime)
    }


    const [asthmaCourse, setAsthmaCourse] = useState();
    const [firstAidCourse, setFirstAidCourse] = useState();
    const [cprCourse, setCprCourse] = useState();
    const [anapCourse, setAnapCourse] = useState();
    const [firstAidCourseCompletionDate, setFirstAidCourseCompletionDate] = useState();
    const [cprCourseCompletionDate, setCprCourseCompletionDate] = useState();
    const [asthmaCourseCompletionDate, setAsthmaCourseCompletionDate] = useState();
    const [anapCourseCompletionDate, setAnapCourseCompletionDate] = useState();
    const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]
    const [firstAidCourseReminder, setFirstAidCourseReminder] = useState();
    const [cprCourseReminder, setCprCourseReminder] = useState();
    const [asthamCourseReminder, setAsthmaCourseReminder] = useState();
    const [anapCourseReminder, setAnapCourseReminder] = useState();
    const [firstAidCourseCal, setFirstAidCourseCal] = useState(false)
    const [cprCourseCal, setCprCourseCal] = useState(false)
    const [asthmaCourseCal, setAsthmaCourseCal] = useState(false)
    const [anapCourseCal, setAnapCourseCal] = useState(false)
    
    const [insuranceProvider, setInsuranceProvider] = useState();
    const [policyNumber, setPolicyNumber] = useState();
    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState();
    const [showInsuranceExpiryCal, setInsuranceExpiryCal] = useState(false);
    const [ajaxLoader, setAjaxLoader] = useState(true);

    const[from,setFrom] = useState([]);
    const[to ,setTo] = useState([]);

    // Street Name and Post Code 

    const [streetName, setStreetName] = useState('');
    const [postCode, setPostCode] = useState('');

    const allOperationalDays = [{id: 264, val: 'All'}, {id: 268, val:'Sunday'}, 
        {id: 269, val:'Monday'}, {id: 270, val:'Tuesday'}, {id: 274, val: 'Wednesday'}, 
        {id: 271, val:'Thursday'}, {id: 272, val:'Friday'}, {id: 273, val:'Saturday'}];
     
    const [selectedOperationalDays, setSelectedOperationalDays] = useState([false, false ,false, false, false, false, false, false, false])
    const [operationalDays, setOperationalDays] = useState([]);
    
    const [operationalDaysIds, setOperationalDaysIds] = useState([]);
    const [finalOperationalDays, setFinalOperationalDays] =  useState([]);
    
    const [licenceNumber, setLicenceNumber] = useState();
    const [vehicleMaintenanceDate, setVehicleMaintenanceDate] = useState();
    const [vehicleMaintenanceCal, setVehicleMaintenanceCal] = useState(false);
    const [vehicleAuditDate, setVehicleAuditDate] = useState();
    const [vehicleAuditCal, setVehicleAuditCal] = useState(false);
    const [vehicleMake, setVehicleMake] = useState();
    const [vehicleModel, setVehicleModel] = useState();
    const [vehicleYear, setVehicleYear] = useState();
    const [vehicleRegisteration, setVehicleRegisteration] = useState();
    const [registrationExpiryCal, setRegistrationExpiryCal] = useState(false);
    const [registrationExpiryDate, setRegisterationExpiryDate] = useState();
    const [licenceExpiryDate, setLicenceExpiryDate] = useState();
    const [licenceExpiryCal, setLicenceExpiryCal] = useState(false);

    const [occupants, setOccupants] = useState([]);
    const [occupantName, setOccupantName] = useState();
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [selectedCalenderIndex, setSelectedCalenderIndex] = useState(0)
    const [dateField, setDateField] = useState('')
    const [removeOccupantId, setRemoveOccupantId] = useState([])
    const [updatedOccupant, setUpdatedOccupant] = useState([])
 // New Field For Personal Profile Edit .
  const [ street , setStreet] = useState('');
  const [postalCode , setPostalCode] = useState('');
    const validateFormFields=()=>
    {
        // if(operationalDays.length > 0)
        // {
            if(openingHours !== "")
            {   
                if(closingHours !== "")
                {         
                   return true
                }
                else
                {
                   // toast.error('Please select closing hours',{ position: 'top-center' });
                    return false
                }
            }
            else
            {
               // toast.error('Please select opening hours',{ position: 'top-center' });
                return false
            }
        // }
        // else
        // {
        //     toast.error('Please select operational days',{ position: 'top-center' });
        //     return false
        // }
    }


    // async function saveProfile(e) {
    //     e.preventDefault()
    //     setAjaxLoader(true)
    //     let user_id = window.localStorage.getItem('uid');

       

       
    //     //     // operational Days
    //     //     let final_operational_days = finalOperationalDays;
    //     //     for(let day of operationalDaysIds){
    //     //         let obj = {};
    //     //         obj['target_id'] = day
    //     //         final_operational_days.push(obj)
    //     //     }

    //     //     // opening hours
    //     //     let timeArray = openingHours.split(':')     
    //     //     let hours = timeArray[0]
    //     //     let minutes = timeArray[1]
    //     //  finalOpeningHours = hours* 3600 + minutes*60
            

    //     //     // closing hours
    //     //     let timesArray = closingHours.split(':')        
    //     //     let closhours = timesArray[0]
    //     //     let closminutes = timesArray[1]
    //     //  finalClosingHours = closhours* 3600 + closminutes*60

    //         //occupants
    //         // if(occupants.length > 0 && removeOccupantId.length === 0){
    //         //     for(let occupant of occupants){
    //         //         let occupant_id = occupant.occupant_id;

    //         //         let occupant_data = {
    //         //             occupantBlueCard: occupant.occupant_blue_card, 
    //         //             occupantBlueCardNumber: occupant.occupant_blue_card_number,
    //         //             occupantCardExpiryDate: occupant.occupant_card_expiry_date ? occupant.occupant_card_expiry_date : null,
    //         //             occupantDateOfBirth: occupant.occupant_date_of_birth ? occupant.occupant_date_of_birth : null,
    //         //             occupantName: occupant.occupant_name,
    //         //             occupantDateSigned: occupant.occupant_date_signed ? occupant.occupant_date_signed : null
    //         //         };
    //         //         

    //         //         let occupant_response = await updateOccupants(occupant_id, occupant_data);
    //         //         

    //         //         if(occupant_response.status === 200){
    //         //             setAjaxLoader(false)
    //         //             setFinalOperationalDays([])
    //         //             setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
    //         //             setOperationalDays([])
    //         //         }else{
    //         //             setAjaxLoader(false)
    //         //             toast.error('Occupant: '+occupant.occupant_name+' '+occupant_response.message, {
    //         //                 position: 'top-center'
    //         //             });
    //         //         }
    //         //     }
    //         // }

    //         // // delete occupant
    //         // if(removeOccupantId.length > 0)
    //         // {
    //         //     removeOccupantId.map(async(data)=>{
    //         //         let delResponse = await deleteOccupant(data)
    //         //         if(delResponse.status === 204)
    //         //         {
    //         //             let uploadData = {"field_all_occupants": updatedOccupant}
    //         //             let response = await updateUserProfile(profileUid, uploadData ,"updateOccupant");
    //         //             if(response.status == 200){
    //         //                 setAjaxLoader(false)
    //         //                 toast.success('Profile Updated Successfully!!', {
    //         //                     position: 'top-center'
    //         //                 });
    //         //                 setFinalOperationalDays([])
    //         //                 setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
    //         //                 setOperationalDays([])
    //         //                 getAllInitialData()
    //         //                 setRemoveOccupantId([])
    //         //                 setUpdatedOccupant([])
    //         //             }else{
    //         //                 setAjaxLoader(false)
    //         //                 toast.error(response.message, {
    //         //                     position: 'top-center'
    //         //                 });
    //         //             }
    //         //         }
    //         //         else
    //         //         {
    //         //             setAjaxLoader(false)
    //         //             toast.error(delResponse.message, {
    //         //                 position: 'top-center'
    //         //             });
    //         //         }
    //         //     })
    //         // }
           
    //             let data = {dob: dob,
    //                  openingHours: finalOpeningHours, 
    //                  closingHours: finalClosingHours, 
    //                  operationalDays: finalOperationalDays, 
    //                  commencementDate: commencementDate,
    //                  ceasedDate:ceasedDate,
    //                  qualification:qualification,
    //                 asthmaCourse: asthmaCourse,
    //                 firstAidCourse: firstAidCourse, 
    //                 cprCourse: cprCourse, 
    //                 anapCourse: anapCourse,
    //                 insuranceProvider: insuranceProvider, 
    //                 policyNumber: policyNumber, 
    //                 insuranceExpiryDate: insuranceExpiryDate,
    //                 licenceNumber: licenceNumber, 
    //                 vehicleMaintenanceDate: vehicleMaintenanceDate, 
    //                 vehicleAuditDate: vehicleAuditDate,
    //                 vehicleMake: vehicleMake, 
    //                 vehicleModel:vehicleModel, 
    //                 vehicleYear:vehicleYear, 
    //                 vehicleRegisteration: vehicleRegisteration,
    //                 registrationExpiryDate: registrationExpiryDate, 
    //                 licenceExpiryDate: licenceExpiryDate, 
    //                 uid: user_id,
    //                 firstAidCourseCompletionDate: firstAidCourseCompletionDate ? firstAidCourseCompletionDate : null , 
    //                 cprCourseCompletionDate: cprCourseCompletionDate ? cprCourseCompletionDate : null,
    //                 asthmaCourseCompletionDate: asthmaCourseCompletionDate ? asthmaCourseCompletionDate : null, 
    //                 anapCourseCompletionDate: anapCourseCompletionDate ? anapCourseCompletionDate : null,
    //                 firstAidCourseReminder: firstAidCourseReminder, 
    //                 cprCourseReminder: cprCourseReminder,
    //                 asthamCourseReminder: asthamCourseReminder, 
    //                 anapCourseReminder: anapCourseReminder, 
    //                 suburbName:suburbName
    //             };
    //             let response = await updateUserProfile(profileUid, data);
    //             if(response.status == 200){
    //                 // setAjaxLoader(false)
    //                 // setFinalOperationalDays([])
    //                 // setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
    //                 // setOperationalDays([])
    //                 // getAllInitialData()
    //                 toast.success('Profile Updated Successfully!!', {
    //                     position: 'top-center'
    //                 });
    //             }else{
    //                 setAjaxLoader(false)
    //                 toast.error(response.message, {
    //                     position: 'top-center'
    //                 });
    //             }
    //         }
        
       
    

    const firstAidCalendar=() => {
        if(firstAidCourseCal === true)
        {
            setFirstAidCourseCal(false)
        }
        else
        {
            setFirstAidCourseCal(true)
        }
    }


    const firstAidCalendarHandler=(newDate) => {
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setFirstAidCourseCompletionDate(fullYear)
        setFirstAidCourseCal(false)
        
    }

    const cprCourseCalendar=() => {
        if(cprCourseCal === true)
        {
            setCprCourseCal(false)
        }
        else
        {
            setCprCourseCal(true)
        }
    }

    const cprCourseCalendarHandler=(newDate) => {
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setCprCourseCompletionDate(fullYear)
        setCprCourseCal(false)
    }

    const asthmaCourseCalendar=() => {
        if(asthmaCourseCal === true)
        {
            setAsthmaCourseCal(false)
        }
        else
        {
            setAsthmaCourseCal(true)
        }
    }

    const asthmaCourseCalendarHandler=(newDate) => {
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setAsthmaCourseCompletionDate(fullYear)
        setAsthmaCourseCal(false)
    }

    const anapCourseCalendar=() => {
        if(anapCourseCal === true)
        {
            setAnapCourseCal(false)
        }
        else
        {
            setAnapCourseCal(true)
        }
    }

    const anapCourseCalendarHandler=(newDate) => {
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setAnapCourseCompletionDate(fullYear)
        setAnapCourseCal(false)
    }

    useEffect(() => {

        $(".accordion-content:not(:first-of-type)").css("display", "none");
        $(".js-accordion-title:first-of-type").addClass("open");
        
        $(".js-accordion-title").click(function () {
            $(".open").not(this).removeClass("open").next().slideUp(300);
            $(this).toggleClass("open").next().slideToggle(300);
        });


        $(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})
        

    getAllInitialData()

    function validateForm()
    {
        var dobValidate = document.getElementById("dob");
        if(dobValidate !== null){
          dobValidate.oninvalid = function(e) {
            if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            {
              e.target.setCustomValidity("Please select DOB");
            }
            else
            {
              e.target.setCustomValidity("");
            }
          };
        }

        var commencementDateValidate = document.getElementById("commencement");
        if(commencementDateValidate !== null){
          commencementDateValidate.oninvalid = function(e) {
            if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            {
              e.target.setCustomValidity("Please select commencement date");
            }
            else
            {
              e.target.setCustomValidity("");
            }
          };
        }

        var ceasedDateValidate = document.getElementById("ceasedDate");
        if(ceasedDateValidate !== null){
          ceasedDateValidate.oninvalid = function(e) {
            if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            {
              e.target.setCustomValidity("Please select ceased date");
            }
            else
            {
              e.target.setCustomValidity("");
            }
          };
        }

        var qualificationValidate = document.getElementById("qualification");
        if(qualificationValidate !== null){
          qualificationValidate.oninvalid = function(e) {
            if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            {
              e.target.setCustomValidity("Please enter qualification");
            }
            else
            {
              e.target.setCustomValidity("");
            }
          };
        }

        var firstAidCourseCodeValidate = document.getElementById("firstAidCourse");
            if(firstAidCourseCodeValidate !== null){
              firstAidCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("First Aid course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var crpCourseCodeValidate = document.getElementById("cprCourse");
            if(crpCourseCodeValidate !== null){
              crpCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("CRP course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var asthmaCourseCodeValidate = document.getElementById("asthmaCourse");
            if(asthmaCourseCodeValidate !== null){
              asthmaCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Asthma course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var anaphylaxisCourseCodeValidate = document.getElementById("anapCourse");
            if(anaphylaxisCourseCodeValidate !== null){
              anaphylaxisCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Anaphylaxis course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var firstAidCourseExpiryDateValidate = document.getElementById("firstAidCourseCompletionDate");
            if(firstAidCourseExpiryDateValidate !== null){
              firstAidCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("FirstAid Course completion date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var crpCourseExpiryDateValidate = document.getElementById("cprCourseCompletionDate");
            if(crpCourseExpiryDateValidate !== null){
              crpCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("CRP Course completions date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var asthmaCourseExpiryDateValidate = document.getElementById("asthmaCourseCompletionDate");
            if(asthmaCourseExpiryDateValidate !== null){
              asthmaCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Asthma Course completion date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var anaphylaxisCourseExpiryDateValidate = document.getElementById("anapCourseCompletionDate");
            if(anaphylaxisCourseExpiryDateValidate !== null){
              anaphylaxisCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Anaphylaxis Course comletion date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            //Insurance

            var policyNumberValidate = document.getElementById("policyNumber");
            if(policyNumberValidate !== null){
              policyNumberValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Policy Number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var insuranceProviderValidate = document.getElementById("insuranceProvider");
            if(insuranceProviderValidate !== null){
              insuranceProviderValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Insurance Provider can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var expiryDateValidate = document.getElementById("insuranceExpiryDate");
            if(expiryDateValidate !== null){
              expiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Insurance Expiry date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            //vehicle

            var vehicleMakeValidate = document.getElementById("vehicleMake");
            if(vehicleMakeValidate !== null){
              vehicleMakeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Vehicle make can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var vehicleModelValidate = document.getElementById("vehicleModel");
            if(vehicleModelValidate !== null){
              vehicleModelValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Vehicle model can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var vehicleYearValidate = document.getElementById("vehicleYear");
            if(vehicleYearValidate !== null){
              vehicleYearValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Vehicle year date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var registrationValidate = document.getElementById("vehicleRegisteration");
            if(registrationValidate !== null){
              registrationValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Registration can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var registryExpiryDateValidate = document.getElementById("registrationExpiryDate");
            if(registryExpiryDateValidate !== null){
              registryExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Registration Expiry date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var vehicleMaintenanceDateValidate = document.getElementById("vehicleMaintenanceDate");
            if(vehicleMaintenanceDateValidate !== null){
              vehicleMaintenanceDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Vehicle maintenance date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var vehicleAuditDateValidate = document.getElementById("vehicleAuditDate");
            if(vehicleAuditDateValidate !== null){
              vehicleAuditDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Vehicle audit date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var licenceNumberValidate = document.getElementById("licenceNumber");
            if(licenceNumberValidate !== null){
              licenceNumberValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Licence number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var licenceExpiryDateValidate = document.getElementById("licenceExpiryDate");
            if(licenceExpiryDateValidate !== null){
              licenceExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Licence expiry date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }


    }

    validateForm()

    },[])

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    async function getAllInitialData(){

            let uid = window.localStorage.getItem('uid');
            let auth_token = window.localStorage.getItem('auth_token');
          
            let user_response = await getSpecificUser(uid, auth_token);
            if(user_response.status === 200){
                setAjaxLoader(false)
                if(user_response.data.data !== '' && user_response.data.data !== undefined){
                    if(user_response.data.data.field_first_name){
                        let name = user_response.data.data.field_first_name[0] ? user_response.data.data.field_first_name[0].value : "";
                        setFirstName(name);
                    }

                    if(user_response.data.data.field_last_name){
                        let lastName = user_response.data.data.field_last_name[0] ? user_response.data.data.field_last_name[0].value : "";
                        setLastName(lastName);
                    }
                    if(user_response.data.data.mail){
                        let email = user_response.data.data.mail[0] ? user_response.data.data.mail[0].value : "";
                        setEmail(email);
                    }

                    if(user_response.data.data.educator_profiles.length > 0){
                        let profileId = user_response.data.data.educator_profiles[0].target_id;
                        setProfileId(profileId);
                        let profileData = await getUserProfileData(profileId);
                        if(profileData.status == 200){

                            if(profileData.data.data.field_home_number !== undefined && profileData.data.data.field_home_number !=="" ){
                                let _home = profileData.data.data.field_home_number[0] ? profileData.data.data.field_home_number[0].value : ""
                                setHomeNumber(_home)
                            }
                            if(profileData.data.data.field_postcode !== undefined && profileData.data.data.field_postcode !=="" ){
                                let _postcode = profileData.data.data.field_postcode[0] ? profileData.data.data.field_postcode[0].value : ""
                                setPostalCode(_postcode)
                            }
                            if(profileData.data.data.field_street_address !== undefined && profileData.data.data.field_street_address !=="" ){
                                let _street = profileData.data.data.field_street_address[0] ? profileData.data.data.field_street_address[0].value : ""
                                setStreet(_street);
                            }

                            if(profileData.data.data.field_suburb_name !== undefined && profileData.data.data.field_suburb_name !=="" ){
                                let _suburb = profileData.data.data.field_suburb_name[0] ? profileData.data.data.field_suburb_name[0].value : ""
                                setSuburbName(_suburb)
                            }

                            if(profileData.data.data.field_street_address !== undefined && profileData.data.data.field_street_address !== ""){
                                let street_address = profileData.data.data.field_street_address[0] ? profileData.data.data.field_street_address[0].value : "";
                               setStreetName(street_address)
                            }

                            if(profileData.data.data.field_postcode !== undefined && profileData.data.data.field_postcode !== ""){
                                let post_code = profileData.data.data.field_postcode[0] ? profileData.data.data.field_postcode[0].value : "";
                                setPostCode(post_code);
                            }

                            if(profileData.data.data.field_d_o_b !== undefined && profileData.data.data.field_d_o_b !== ""){
                                let dob = profileData.data.data.field_d_o_b[0] ? profileData.data.data.field_d_o_b[0].value : "";
                                setDob(dob);
                            }

                            if(profileData.data.data.field_commencement_date !== undefined && profileData.data.data.field_commencement_date !=="" ){
                                let date = profileData.data.data.field_commencement_date[0] ? profileData.data.data.field_commencement_date[0].value : ""
                                setCommencementDate(date)
                            }

                            if(profileData.data.data.field_ceased_date !== undefined && profileData.data.data.field_ceased_date !==""){
                                let date = profileData.data.data.field_ceased_date[0] ? profileData.data.data.field_ceased_date[0].value : ''
                                setCeasedDate(date)
                            }

                            if(profileData.data.data.field_business_operational_days !== undefined && profileData.data.data.field_business_operational_days !==""){

                                if(profileData.data.data.field_business_operational_days.length > 0){
                                    let operational_days_array = [];
                                    let days_Id = [];
                                    //Here Working
                    
                                    for(let mydata of profileData.data.data.field_business_operational_days)
                                    { 
                                        let occupant_id = await getOccupant(mydata.target_id);
                                        setTiming(occupant_id.data.data.field_operational_hours)
                                        let taxonomy_response = await getSpecificTaxonomy(occupant_id.data.data.field_operational_days[0].target_id, auth_token);
                                       
                                            
                                        if(taxonomy_response.data.data.name !== undefined && taxonomy_response.data.data.name !== "")
                                        {
                    
                                            let taxonomy_name = taxonomy_response.data.data.name[0] ? taxonomy_response.data.data.name[0].value : ''
                                                operational_days_array.push(taxonomy_name);

                                                let value = allOperationalDays.filter(data => 
                                                    data.val.includes(taxonomy_name)).map((mapData) => {
                                                        return mapData
                                                })
                                                
                                                if(value.length > 0){
                                                    // Get Index
                                                    Array.prototype.getIndexOf = function(el) {
                                                        var arr = this;
                                                        for (var i=0; i<arr.length; i++){
                                                            if(arr[i].val==el){
                                                            return i;
                                                            } 
                                                        }
                                                        return -1;
                                                    }
                                                    // Get Days Id
                                                    Array.prototype.getIndexId = function(el) {
                                                        var arr = this;
                                                        for (var i=0; i<arr.length; i++){
                                                            if(arr[i].val==el){
                                                            return arr[i].id;
                                                            } 
                                                        }
                                                        return -1;
                                                    }
                                                    
                                                    let index = allOperationalDays.getIndexOf(taxonomy_name)
                                                    let day_id = allOperationalDays.getIndexId(taxonomy_name)
                                                    let newArray = selectedOperationalDays;
                                                    
                                                    newArray[index] = true
                                                    days_Id.push(day_id)
                                                    setSelectedOperationalDays([...newArray])

                                                }
                                        }
                                    }
                                    setOperationalDays(operational_days_array);
                                    setOperationalDaysIds(days_Id.filter(onlyUnique));
                                }  
                            }

                            if(profileData.data.data.field_qualification !== undefined && profileData.data.data.field_qualification !==""){
                                let qualification = profileData.data.data.field_qualification[0] ? profileData.data.data.field_qualification[0].value : ''
                                setQualification(qualification);
                            }

                            if(profileData.data.data.field_asthma_course !== undefined && profileData.data.data.field_asthma_course !==""){
                                let asthma_course = profileData.data.data.field_asthma_course[0] ? profileData.data.data.field_asthma_course[0].value : ''
                                setAsthmaCourse(asthma_course);
                            }

                            if(profileData.data.data.field_first_aid_course !== undefined && profileData.data.data.field_first_aid_course !==""){
                                let first_aid_course = profileData.data.data.field_first_aid_course[0] ? profileData.data.data.field_first_aid_course[0].value : ''
                                setFirstAidCourse(first_aid_course);
                            }

                            if(profileData.data.data.field_cpr_course !== undefined && profileData.data.data.field_cpr_course !==""){
                                let cpr_course = profileData.data.data.field_cpr_course[0] ? profileData.data.data.field_cpr_course[0].value : ''
                                setCprCourse(cpr_course);
                            }

                            if(profileData.data.data.field_anaphylaxis_course !== undefined && profileData.data.data.field_anaphylaxis_course !==""){
                                let anap_course = profileData.data.data.field_anaphylaxis_course[0] ? profileData.data.data.field_anaphylaxis_course[0].value : ''
                                setAnapCourse(anap_course);
                            }

                            if(profileData.data.data.field_insurance_provider !== undefined && profileData.data.data.field_insurance_provider !==""){
                                let insurance_provider = profileData.data.data.field_insurance_provider[0] ? profileData.data.data.field_insurance_provider[0].value : ''
                                setInsuranceProvider(insurance_provider);
                            }

                            if(profileData.data.data.field_policy_number !== undefined && profileData.data.data.field_policy_number !==""){
                                let policy_number = profileData.data.data.field_policy_number[0] ? profileData.data.data.field_policy_number[0].value : ''
                                setPolicyNumber(policy_number);
                            }

                            if(profileData.data.data.field_insurance_expiry_date !== undefined && profileData.data.data.field_insurance_expiry_date !==""){
                                let insurance_expiry_date = profileData.data.data.field_insurance_expiry_date[0] ? profileData.data.data.field_insurance_expiry_date[0].value : ''
                                setInsuranceExpiryDate(insurance_expiry_date);
                            }

                            if(profileData.data.data.field_licence_number !== undefined && profileData.data.data.field_licence_number !==""){
                                let licence_number = profileData.data.data.field_licence_number[0] ? profileData.data.data.field_licence_number[0].value : ''
                                setLicenceNumber(licence_number);
                            }

                            if(profileData.data.data.field_vehicle_maintenance_date !== undefined && profileData.data.data.field_vehicle_maintenance_date !==""){
                                let vehicle_maintenance_date = profileData.data.data.field_vehicle_maintenance_date[0] ? profileData.data.data.field_vehicle_maintenance_date[0].value : ''
                                setVehicleMaintenanceDate(vehicle_maintenance_date);
                            }

                            if(profileData.data.data.field_vehicle_audit_date !== undefined && profileData.data.data.field_vehicle_audit_date !==""){
                                let vehicle_audit_date = profileData.data.data.field_vehicle_audit_date[0] ? profileData.data.data.field_vehicle_audit_date[0].value : ''
                                setVehicleAuditDate(vehicle_audit_date);
                            }

                            if(profileData.data.data.field_vehicle_make !== undefined && profileData.data.data.field_vehicle_make !==""){
                                let vehicle_make = profileData.data.data.field_vehicle_make[0] ? profileData.data.data.field_vehicle_make[0].value : ''
                                setVehicleMake(vehicle_make);
                            }

                            if(profileData.data.data.field_vehicle_model !== undefined && profileData.data.data.field_vehicle_model !==""){
                                let vehicle_model = profileData.data.data.field_vehicle_model[0] ? profileData.data.data.field_vehicle_model[0].value : ''
                                setVehicleModel(vehicle_model);
                            }

                            if(profileData.data.data.field_vehicle_year !== undefined && profileData.data.data.field_vehicle_year !==""){
                                let vehicle_year = profileData.data.data.field_vehicle_year[0] ? profileData.data.data.field_vehicle_year[0].value : ''
                                setVehicleYear(vehicle_year);
                            }
                            
                            if(profileData.data.data.field_registration !== undefined && profileData.data.data.field_registration !==""){
                                let registeration = profileData.data.data.field_registration[0] ? profileData.data.data.field_registration[0].value : ''
                                setVehicleRegisteration(registeration);
                            }

                            if(profileData.data.data.field_registration_expiry_date !== undefined && profileData.data.data.field_registration_expiry_date !==""){
                                let registration_expiry_date = profileData.data.data.field_registration_expiry_date[0] ? profileData.data.data.field_registration_expiry_date[0].value : ''
                                setRegisterationExpiryDate(registration_expiry_date);
                            }

                            if(profileData.data.data.field_licence_expiry_date !== undefined && profileData.data.data.field_licence_expiry_date !==""){
                                let licence_expiry_date = profileData.data.data.field_licence_expiry_date[0] ? profileData.data.data.field_licence_expiry_date[0].value : ''
                                setLicenceExpiryDate(licence_expiry_date);
                            }
//Check here 
                            if(profileData.data.data.field_operational_hours !== undefined && profileData.data.data.field_operational_hours !=="" ){
                                let fromDateTimeStamp = profileData.data.data.field_operational_hours[0] ? profileData.data.data.field_operational_hours[0].from : ""
                                let toDateTimeStamp = profileData.data.data.field_operational_hours[0] ? profileData.data.data.field_operational_hours[0].to : ""
                                if(toDateTimeStamp !== "" || fromDateTimeStamp !== ""){
                                    let toDate = convertTimeStampToTimeCustom(toDateTimeStamp)
                                    let fromDate = convertTimeStampToTimeCustom(fromDateTimeStamp)
                                    setOpeningHours(fromDate)
                                    setClosingHours(toDate)
                                }
                            }

                            if(profileData.data.data.field_first_aid_completion_date !== undefined && profileData.data.data.field_first_aid_completion_date !== ''){
                                let date = profileData.data.data.field_first_aid_completion_date[0] ? profileData.data.data.field_first_aid_completion_date[0].value : null
                                setFirstAidCourseCompletionDate(date)
                            }

                            if(profileData.data.data.field_cpr_course_completion_date !== undefined && profileData.data.data.field_cpr_course_completion_date !== ''){
                                let date = profileData.data.data.field_cpr_course_completion_date[0] ? profileData.data.data.field_cpr_course_completion_date[0].value : null
                                setCprCourseCompletionDate(date)
                            }

                            if(profileData.data.data.field_asth_course_complete_date !== undefined && profileData.data.data.field_asth_course_complete_date !== ''){
                                let date = profileData.data.data.field_asth_course_complete_date[0] ? profileData.data.data.field_asth_course_complete_date[0].value : null
                                setAsthmaCourseCompletionDate(date)
                            }

                            if(profileData.data.data.field_anap_course_complete_date !== undefined && profileData.data.data.field_anap_course_complete_date !== ''){
                                let date = profileData.data.data.field_anap_course_complete_date[0] ? profileData.data.data.field_anap_course_complete_date[0].value : null
                                setAnapCourseCompletionDate(date)
                            }

                            if(profileData.data.data.field_first_aid_course_reminder !== undefined && profileData.data.data.field_first_aid_course_reminder !== ''){
                                let reminder = profileData.data.data.field_first_aid_course_reminder[0] ? profileData.data.data.field_first_aid_course_reminder[0].value : '';
                                setFirstAidCourseReminder(reminder)
                            }
                            
                            if(profileData.data.data.field_cpr_course_reminder !== undefined && profileData.data.data.field_cpr_course_reminder !== ''){
                                let reminder = profileData.data.data.field_cpr_course_reminder[0] ? profileData.data.data.field_cpr_course_reminder[0].value : '';
                                setCprCourseReminder(reminder)
                            }

                            if(profileData.data.data.field_asthma_course_reminder !== undefined && profileData.data.data.field_asthma_course_reminder !== ''){
                                let reminder = profileData.data.data.field_asthma_course_reminder[0] ? profileData.data.data.field_asthma_course_reminder[0].value : '';
                                setAsthmaCourseReminder(reminder)
                            }

                            if(profileData.data.data.field_anaphylxis_course_reminder !== undefined && profileData.data.data.field_anaphylxis_course_reminder !== ''){
                                let reminder = profileData.data.data.field_anaphylxis_course_reminder[0] ? profileData.data.data.field_anaphylxis_course_reminder[0].value : '';
                                setAnapCourseReminder(reminder)
                            }
                            if(profileData.data.data.field_registration !== undefined && profileData.data.data.field_registration !== ''){
                                let reminder = profileData.data.data.field_registration[0] ? profileData.data.data.field_registration[0].value : '';
                                setRegisterNumber(reminder)
                            }
                            if(profileData.data.data.field_address !== undefined && profileData.data.data.field_address !== ''){
                                let reminder = profileData.data.data.field_address[0] ? profileData.data.data.field_address[0].value : '';
                                setAddress(reminder)
                            }
                            if(profileData.data.data.field_blue_card_expiry_date !== undefined && profileData.data.data.field_blue_card_expiry_date !== ''){
                                let reminder = profileData.data.data.field_blue_card_expiry_date[0] ? profileData.data.data.field_blue_card_expiry_date[0].value : '';
                                setResidentExpDate(reminder)
                            }
                            if(profileData.data.data.field_signed_date !== undefined && profileData.data.data.field_signed_date !== ''){
                                let reminder = profileData.data.data.field_signed_date[0] ? profileData.data.data.field_signed_date[0].value : '';
                                setResidentSightedDate(reminder)
                            }
                            if(profileData.data.data.field_blue_card_ !== undefined && profileData.data.data.field_blue_card_ !== ''){
                                let reminder = profileData.data.data.field_blue_card_[0] ? profileData.data.data.field_blue_card_[0].value : '';
                                setEducatorBlueCard(reminder)
                            }
                            if(profileData.data.data.field_registration_expiry_date !== undefined && profileData.data.data.field_registration_expiry_date !== ''){
                                let reminder = profileData.data.data.field_registration_expiry_date[0] ? profileData.data.data.field_registration_expiry_date[0].value : '';
                                setRegExpiryDate(reminder)
                            }

                            if(profileData.data.data.field_all_occupants !== undefined && profileData.data.data.field_all_occupants !==""){
                                if(profileData.data.data.field_all_occupants.length > 0){
                                    setUpdatedOccupant(profileData.data.data.field_all_occupants)
                                    let occupants_array = [];
                                    for(let mydata of profileData.data.data.field_all_occupants){
                                        let occupant_response = await getOccupant(mydata.target_id);
                                        if(occupant_response.data !== undefined)
                                        {
                                            if(occupant_response.data.data !== undefined && occupant_response.data.data !== ""){
                                                occupants_array.push(occupant_response.data.data);
                                            }
                                        }
                                    }

                                    if(occupants_array.length > 0){
                                        let custom_array = [];
                                        for(let occupant of occupants_array){
                                            let custom_object = {
                                                'occupant_name' : '',
                                                'occupant_blue_card' : '',
                                                'occupant_blue_card_number' : '', 
                                                'occupant_date_signed': '', 
                                                'occupant_card_expiry_date' : '',
                                                'occupant_revision_date' : '', 
                                                'occupant_date_of_birth' : '',
                                                'showDobCalender' : false,
                                                'showSignedCalender' : false,
                                                'showExpiryCalender' : false
                                            };

                                            if(occupant.field_occupant_name !== '' && occupant.field_occupant_name !== undefined){
                                                custom_object['occupant_name'] = occupant.field_occupant_name[0] ? occupant.field_occupant_name[0].value : "";
                                            }
                                            if(occupant.field_occupant_blue_card !== '' && occupant.field_occupant_blue_card !== undefined){
                                                custom_object['occupant_blue_card'] = occupant.field_occupant_blue_card[0] ? occupant.field_occupant_blue_card[0].value : "";
                                            }
                                            if(occupant.field_occupant_blue_card_number !== '' && occupant.field_occupant_blue_card_number !== undefined){
                                                custom_object['occupant_blue_card_number'] = occupant.field_occupant_blue_card_number[0] ? occupant.field_occupant_blue_card_number[0].value : "";
                                            }
                                            if(occupant.field_occupant_date_signed !== '' && occupant.field_occupant_date_signed !== undefined){
                                                custom_object['occupant_date_signed'] = occupant.field_occupant_date_signed[0] ? occupant.field_occupant_date_signed[0].value : "";
                                            }
                                            if(occupant.field_occupant_card_expiry_date !== '' && occupant.field_occupant_card_expiry_date !== undefined){
                                                custom_object['occupant_card_expiry_date'] = occupant.field_occupant_card_expiry_date[0] ? occupant.field_occupant_card_expiry_date[0].value : "";
                                            }
                                            if(occupant.field_occupant_date_of_birth !== '' && occupant.field_occupant_date_of_birth !== undefined){
                                                custom_object['occupant_date_of_birth'] = occupant.field_occupant_date_of_birth[0] ? occupant.field_occupant_date_of_birth[0].value : "";
                                            }
                                            if(occupant.id !== '' && occupant.id !== undefined){
                                                custom_object['occupant_id'] = occupant.id[0] ? occupant.id[0].value : "";
                                            }
                                            custom_array.push(custom_object);
                                        }
                                        setOccupants(custom_array);
                                    }
                                }  
                            }

                            
                        }
                    }
                }
            }
            else
            {
                setAjaxLoader(false)
            }
        }


    const commencementCalendar=() => {
        if(commenceCalendar === true)
        {
            setCommenceCalendar(false)
        }
        else
        {
            setCommenceCalendar(true)
        }
    }

    const commencementCalendarHandler=(newDate) => {
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
		setCommencementDate(fullYear)
        setCommenceCalendar(false)
    }

    const dobCalendar=() => {
        if(dateofbirthCalendar === true)
        {
            setDateofbirthCalendar(false)
        }
        else
        {
            setDateofbirthCalendar(true)
        }
    }

    const dobCalendarHandler=(newDate) => {

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year

        let requiredDate = moment(newDate).format('YYYY-MM-DD')
        
		setDob(requiredDate)
        setDateofbirthCalendar(false)
    }

    const ceasedCalendar=() => {
        if(ceaseCalendar === true)
        {
            setCeasecalendar(false);
        }
        else
        {
          setCeasecalendar(true);  
        }
        
    }

    const ceasedCalendarHandler=(newDate) => {

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
		setCeasedDate(fullYear)
        setCeasecalendar(false)
    }

    const insuranceExpiryCalendar=() => {
        if(showInsuranceExpiryCal === true)
        {
            setInsuranceExpiryCal(false);
        }
        else
        {
            setInsuranceExpiryCal(true);
        }
        
    }

    const insuranceExpiryCalendarHandler=(newDate) => {
        
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
		setInsuranceExpiryDate(fullYear)
        setInsuranceExpiryCal(false)
    }

    const vehicleMaintenanceCalendar=() => {
        if(vehicleMaintenanceCal === true)
        {
            setVehicleMaintenanceCal(false)
        }
        else
        {
            setVehicleMaintenanceCal(true)
        }
    }

    const vehicleMaintenanceCalendarHandler=(newDate) => {

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year
		setVehicleMaintenanceDate(fullYear)
        setVehicleMaintenanceCal(false)
    }

    const vehicleAuditCalendar=() => {
        if(vehicleAuditCal === true)
        {
            setVehicleAuditCal(false)
        }
        else
        {
            setVehicleAuditCal(true)
        }
    }

    const vehicleAuditCalendarHandler=(newDate) => {

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
		setVehicleAuditDate(fullYear)
        setVehicleAuditCal(false)
    }

    const registrationExpiryCalendar=() => {
        if(registrationExpiryCal === true)
        {
            setRegistrationExpiryCal(false)
        }
        else
        {
            setRegistrationExpiryCal(true)
        }
    }

    const registrationExpiryCalendarHandler=(newDate) => {
        
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setRegisterationExpiryDate(fullYear)
        setRegistrationExpiryCal(false)
    }

    const licenceExpiryCalendar=() => {
        if(licenceExpiryCal === true)
        {
            setLicenceExpiryCal(false)
        }
        else
        {
            setLicenceExpiryCal(true)
        }
    }

    const licenceExpiryCalendarHandler=(newDate) => {

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year;
        setLicenceExpiryDate(fullYear)
        setLicenceExpiryCal(false)
    }

    async function occupantInputHandler(index, value, field_name) {
        let newArray = occupants
        if(field_name == 'occupant_name'){
            newArray[index].occupant_name = value
        }
        if(field_name == 'occupant_blue_card'){
            newArray[index].occupant_blue_card = value
        }
        if(field_name == 'occupant_blue_card_number'){
            newArray[index].occupant_blue_card_number = value.replace(/\D/g, "")
        }
        setOccupants([...newArray])
    }

    const occupantsCalendar=(name, index) => {

        setDateField(name)

        if(index !== null && index !== undefined){
            setSelectedCalenderIndex(index);
        }

        let newArray = occupants

        if(name === "occupantcardexpirydate"){
            if(newArray[index].showExpiryCalender === true)
            {
                newArray[index].showExpiryCalender = false
            }
            else
            {
                newArray[index].showExpiryCalender = true
            }
        }
        if(name === "occupantdatesigned"){
            if(newArray[index].showSignedCalender === true)
            {
                newArray[index].showSignedCalender = false
            }
            else
            {
                newArray[index].showSignedCalender = true
            }
        }
        if(name === "occupantdateofbirth"){
            if(newArray[index].showDobCalender === true)
            {
                newArray[index].showDobCalender = false
            }
            else
            {
                newArray[index].showDobCalender = true
            }
        }
        setOccupants([...newArray])
    }

    const onChangeDate=(newDate)=> {

        let newArray = occupants

        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year; 

        if(dateField === "occupantcardexpirydate"){
            newArray[selectedCalenderIndex].occupant_card_expiry_date = fullYear
            newArray[selectedCalenderIndex].showExpiryCalender = false
        }
        if(dateField === "occupantdatesigned"){
            newArray[selectedCalenderIndex].occupant_date_signed = fullYear
            newArray[selectedCalenderIndex].showSignedCalender = false
        }
        if(dateField === "occupantdateofbirth"){
            newArray[selectedCalenderIndex].occupant_date_of_birth = fullYear
            newArray[selectedCalenderIndex].showDobCalender = false
        }
        setOccupants([...newArray])
    }


    const selectReminder=(name, value) => {
        if(name === 'first_aid'){
            setFirstAidCourseReminder(value)    
        }

        if(name === 'cpr'){
            setCprCourseReminder(value)
        }

        if(name  === 'asthma'){
            setAsthmaCourseReminder(value)
        }

        if(name  === 'anap'){
            setAnapCourseReminder(value)
        }
    }

    const selectOpertationalDays=(e, value, index, id)=>{

        let days_array = operationalDaysIds
        if(e.target.classList.contains('active') === false){
            days_array.push(id);
            setOperationalDaysIds([...days_array])
        }else if(e.target.classList.contains('active') === true){
            let idx = days_array.indexOf(id);
            days_array.splice(idx, 1);
            setOperationalDaysIds([...days_array])
        }

    	let _selectedDaysArrays = selectedOperationalDays
    	let _operationalDaysArrays = allOperationalDays
    	let _operationalDaysSelected = operationalDays
        
        if(index == 0 && _selectedDaysArrays[0]=== true)
        {
            
           _selectedDaysArrays = [false,false,false,false,false,false,false,false]
            let value = _operationalDaysSelected[index]
            setOperationalDays([])
        }

        else if(index == 0 && _selectedDaysArrays[0]=== false)
        {
           _selectedDaysArrays = [true,true,true,true,true,true,true,true]
            let value = _operationalDaysSelected[index]
            setOperationalDays(["Sun","Mon","Tue","Wed","Thu","Fri","Sat"])
        }

    	if(index !== 0 && _selectedDaysArrays[index]=== true)
    	{   
    		_selectedDaysArrays[index] = false
            _selectedDaysArrays[0] = false
    		let value = _operationalDaysArrays[index]
    		if(operationalDays.includes(value)===true)
    		{
    			_operationalDaysSelected.splice(index, 1);
    			setOperationalDays(_operationalDaysSelected)
    		}
    	}
    	else if(index !== 0 && _selectedDaysArrays[index]=== false)
    	{
    		_selectedDaysArrays[index] = true
            _selectedDaysArrays[0] = false
    		let value = _operationalDaysArrays[index]
    		if(operationalDays.includes(value)===false)
    		{
    			_operationalDaysSelected.push(value)
    			setOperationalDays(_operationalDaysSelected)
    		}

    	}
    	setSelectedOperationalDays([..._selectedDaysArrays])
        
    }

    const removeMoreNewFields=(e,index,selectedId)=>
    {
        e.preventDefault()
        let addMoreFiledsRef = occupants
        addMoreFiledsRef.splice(index,1)
        setOccupants([...addMoreFiledsRef])

        let newArray = []
        newArray.push(selectedId)
        setRemoveOccupantId(newArray)

        let updatedOccupantRef = updatedOccupant
        updatedOccupantRef.splice(index,1)
        setUpdatedOccupant([...updatedOccupantRef])
    }

    async function saveProfile(e) {
        e.preventDefault()
        setAjaxLoader(true)
        let user_id = window.localStorage.getItem('uid');
       //alert("Working")
        let res = validateFormFields()

       
        //     // operational Days
        //     let final_operational_days = finalOperationalDays;
        //     for(let day of operationalDaysIds){
        //         let obj = {};
        //         obj['target_id'] = day
        //         final_operational_days.push(obj)
        //     }

        //     // opening hours
        //     let timeArray = openingHours.split(':')     
        //     let hours = timeArray[0]
        //     let minutes = timeArray[1]
        //  finalOpeningHours = hours* 3600 + minutes*60
            

        //     // closing hours
        //     let timesArray = closingHours.split(':')        
        //     let closhours = timesArray[0]
        //     let closminutes = timesArray[1]
        //  finalClosingHours = closhours* 3600 + closminutes*60

            //occupants
            // if(occupants.length > 0 && removeOccupantId.length === 0){
            //     for(let occupant of occupants){
            //         let occupant_id = occupant.occupant_id;

            //         let occupant_data = {
            //             occupantBlueCard: occupant.occupant_blue_card, 
            //             occupantBlueCardNumber: occupant.occupant_blue_card_number,
            //             occupantCardExpiryDate: occupant.occupant_card_expiry_date ? occupant.occupant_card_expiry_date : null,
            //             occupantDateOfBirth: occupant.occupant_date_of_birth ? occupant.occupant_date_of_birth : null,
            //             occupantName: occupant.occupant_name,
            //             occupantDateSigned: occupant.occupant_date_signed ? occupant.occupant_date_signed : null
            //         };
            //         

            //         let occupant_response = await updateOccupants(occupant_id, occupant_data);
            //         

            //         if(occupant_response.status === 200){
            //             setAjaxLoader(false)
            //             setFinalOperationalDays([])
            //             setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
            //             setOperationalDays([])
            //         }else{
            //             setAjaxLoader(false)
            //             toast.error('Occupant: '+occupant.occupant_name+' '+occupant_response.message, {
            //                 position: 'top-center'
            //             });
            //         }
            //     }
            // }

            // // delete occupant
            // if(removeOccupantId.length > 0)
            // {
            //     removeOccupantId.map(async(data)=>{
            //         let delResponse = await deleteOccupant(data)
            //         if(delResponse.status === 204)
            //         {
            //             let uploadData = {"field_all_occupants": updatedOccupant}
            //             let response = await updateUserProfile(profileUid, uploadData ,"updateOccupant");
            //             if(response.status == 200){
            //                 setAjaxLoader(false)
            //                 toast.success('Profile Updated Successfully!!', {
            //                     position: 'top-center'
            //                 });
            //                 setFinalOperationalDays([])
            //                 setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
            //                 setOperationalDays([])
            //                 getAllInitialData()
            //                 setRemoveOccupantId([])
            //                 setUpdatedOccupant([])
            //             }else{
            //                 setAjaxLoader(false)
            //                 toast.error(response.message, {
            //                     position: 'top-center'
            //                 });
            //             }
            //         }
            //         else
            //         {
            //             setAjaxLoader(false)
            //             toast.error(delResponse.message, {
            //                 position: 'top-center'
            //             });
            //         }
            //     })
            // }
           
                let data = {dob: moment(dob).format("YYYY-MM-DD"),
                    //  openingHours: finalOpeningHours, 
                    //  closingHours: finalClosingHours, 
                    //  operationalDays: finalOperationalDays, 
                    //  commencementDate: commencementDate,
                    //  ceasedDate:ceasedDate,
                    //  qualification:qualification,
                    // asthmaCourse: asthmaCourse,
                    // firstAidCourse: firstAidCourse, 
                    // cprCourse: cprCourse, 
                    // anapCourse: anapCourse,
                    // insuranceProvider: insuranceProvider, 
                    // policyNumber: policyNumber, 
                    // insuranceExpiryDate: insuranceExpiryDate,
                    // licenceNumber: licenceNumber, 
                    // vehicleMaintenanceDate: vehicleMaintenanceDate, 
                    // vehicleAuditDate: vehicleAuditDate,
                    // vehicleMake: vehicleMake, 
                    // vehicleModel:vehicleModel, 
                    // vehicleYear:vehicleYear, 
                    // vehicleRegisteration: vehicleRegisteration,
                    // registrationExpiryDate: registrationExpiryDate, 
                    // licenceExpiryDate: licenceExpiryDate, 
                    uid: user_id,
                    // firstAidCourseCompletionDate: firstAidCourseCompletionDate ? firstAidCourseCompletionDate : null , 
                    // cprCourseCompletionDate: cprCourseCompletionDate ? cprCourseCompletionDate : null,
                    // asthmaCourseCompletionDate: asthmaCourseCompletionDate ? asthmaCourseCompletionDate : null, 
                    // anapCourseCompletionDate: anapCourseCompletionDate ? anapCourseCompletionDate : null,
                    // firstAidCourseReminder: firstAidCourseReminder, 
                    // cprCourseReminder: cprCourseReminder,
                    // asthamCourseReminder: asthamCourseReminder, 
                    // anapCourseReminder: anapCourseReminder, 
                    suburbName:suburbName,
                    street : street,
                    postal_code : postalCode,
                    mobile_number : homeNumber
                };

                
                let response = await updateUserProfile(profileUid, data);
                if(response.status == 200){
                    // setAjaxLoader(false)
                    // setFinalOperationalDays([])
                    // setSelectedOperationalDays([false, false ,false, false, false, false, false, false, false])
                    // setOperationalDays([])
                    // getAllInitialData()
                    toast.success('Profile Updated Successfully!!', {
                        position: 'top-center'
                    });
                    setAjaxLoader(false);
                }else{
                    setAjaxLoader(false)
                    toast.error(response.message, {
                        position: 'top-center'
                    });
                }
            }


    return (
        <>
            <ToastContainer />
            <LoaderComponent displayLoader={ajaxLoader} />
            <EducatorsMainHeader />
            <div className="main-wrapper mtb-70">
                <div className="container">	
                    <div className="dashboard-wrap">
                        <form  onSubmit={saveProfile} novalidate>
                        <div className="common-header-row common-header-with-large-text">
                            <h4>Edit Profile</h4>	
                            <div className="block-right">
                                <Link onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</Link>
                                <button className="mdc-button mdc-button--raised green-btn" >
                                    <span className="mdc-button__label">Save</span>
                                </button>
                            </div>	
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="user-pages-accordion-wrap">
                                    <div id="accordion" className="accordion-container">
                                        <h4 className="accordion-title js-accordion-title">Basic Information</h4>
                                        <div className="accordion-content">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20" readOnly type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20" readOnly type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                                
                                            </div>	

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input readOnly type="email" onChange={(e) => setEmail(e.target.value)} value={email} className={email ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Email*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <div className = "calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => dobCalendar()}>
                                                            <input value={dob ?moment(dob).format('DD-MM-YYYY') : dob} type="text" className={dob ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="dob" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{cursor:"pointer"}}/>
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                            <span className="material-icons">calendar_today</span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            dateofbirthCalendar === true ? <Calendar onChange={dobCalendarHandler}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="15"  value={homeNumber} onChange={(e) => setHomeNumber(e.target.value.replace(/\D/g, ""))} type="text" className={homeNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Mobile Number*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                        <span className="material-icons">phone</span>
                                                    </label>
                                                </div> 
                                                <div className="col-lg-6 col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="25" value={street} onChange={(e) => {setStreet(e.target.value)}} type="text" className={street ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Street Name*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div> 
                                                <div className="col-lg-6 col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="15" readOnly value={suburbName} onChange={(e) => setSuburbName(e.target.value.replace(/\D/g, ""))} type="text" className={suburbName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Suburb Name*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                      
                                                    </label>
                                                </div> 
                                                <div className="col-lg-6 col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="25" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" className={postalCode ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Postal Code*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div> 
                                               

                                            </div>
                                            
                                      
                                        </div> 

                                        {/* Residential Occupants

                                        <h4 className="accordion-title js-accordion-title">Residential Occupants</h4>
                                        <div className="accordion-content">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20"  type="text" onChange={(e) => setAddress(e.target.value)} value={address} className={address ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Address*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20"  type="text" onChange={(e) => setEducatorBlueCard(e.target.value)} value={educatorBlueCard} className={educatorBlueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Educator's Blue Card*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                                
                                            </div>	

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input  type="email" onChange={(e) => setResidentExpDate(e.target.value)} value={residentExpDate} className={residentExpDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <div className = "calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => dobCalendar()}>
                                                            <input value={residentExpDate} type="text" className={residentExpDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="dob" required onBlur={(e)=>e.target.setCustomValidity("")} autoComplete="off" />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                            <span className="material-icons">calendar_today</span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            dateofbirthCalendar === true ? <Calendar onChange={dobCalendarHandler}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>	
                                        </div> 

                                        <h4 className="accordion-title js-accordion-title">Educator Details</h4>
                                        <div className="accordion-content">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20"  type="text" onChange={(e) => setRegisterNumber(e.target.value)} value={registerNumber} className={registerNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Registration Number*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input maxlength="20"  type="text" onChange={(e) => setRegExpiryDate(e.target.value)} value={regExpiryDate} className={regExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                        <span className="mdc-notched-outline">
                                                        <span className="mdc-notched-outline__leading"></span>
                                                        <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                                        </span>
                                                        <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                                
                                            </div>	
	
                                        </div> 
                                        <h4 className="accordion-title js-accordion-title">Business Information</h4>
                                        <div className="accordion-content">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => commencementCalendar()} > 
                                                            <input value={commencementDate} type="text" className={commencementDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="commencement" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Commencement Date*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                            <span className="material-icons">calendar_today</span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            commenceCalendar === true ? <Calendar onChange={commencementCalendarHandler}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={() => ceasedCalendar()} >
                                                            <input value={ceasedDate} type="text" className={ceasedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="ceasedDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Ceased Date*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                            <span className="material-icons">calendar_today</span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            ceaseCalendar === true ? <Calendar onChange={ceasedCalendarHandler}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>	
                                            <div className="operational-days-block">
                                                <label onClick={()=> 
                                                <ul className="operational-days-listing">
                                                   
                                                { allOperationalDays.length > 0  ? allOperationalDays.map((items, index) => (
                                                    items.id === 264 ?
                                                    (
                                                    <li dayId = {items.id} className={selectedOperationalDays[index] === true ? 'active' : ''} onClick={(e)=>selectOpertationalDays(e, items.val,index,items.id)} key={index}>{items.val}</li>
                                                 
                                                    )
                                                    :
                                                   <li dayId = {items.id} className={selectedOperationalDays[index] === true ? 'active' : ''} onClick={(e)=>selectOpertationalDays(e, items.val,index,items.id)} key={index}>{items.val}</li>
                                                
                                                )) : null
                                                           
                                                }    
                                                </ul>
                                            </div>
                                            {operationalDaysIds && operationalDaysIds.length !== 0 && 
                                            operationalDaysIds.map((v)=>(
                                                <div className="row">
                                                    <div onClick={() => openingTimepicker()} className="col-md-3">
                                                        <TextField id="time" label="Opening Hours*" type="time" className={classes.textField} value={finalOpeningHours} variant="outlined" InputLabelProps={{shrink: true}} onChange={(e) => openingTimeHandler(e.target.value)} /> 
                                                    </div>
                                                    <div onClick={() => closingTimepicker()} className="col-md-3">        
                                                        <TextField id="time" label="Closing Hours*" type="time" variant="outlined" value={finalClosingHours} InputLabelProps={{ shrink: true }} onChange={(e)=>closingTimeHandler(e.target.value)} className={classes.textField} /> 
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="15" value={qualification} onChange={(e) => {setQualification(e.target.value); e.target.setCustomValidity("")}} type="text" className={qualification ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="qualification" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Qualification*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )) }
                                            		
                                        </div>
                                        <h4 className="accordion-title js-accordion-title">First Aid Certifications</h4> 
                                        <div className="accordion-content">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="text-left">First Aid Course</label>
                                                        <div className="row custom-row">
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <label className="mdc-text-field mdc-text-field--outlined">
                                                                    <input value={firstAidCourse} onChange={(e) => {setFirstAidCourse(e.target.value); e.target.setCustomValidity("") }} type="text" className={firstAidCourse ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                    id="firstAidCourse" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20"/>
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className = "calendar-wrap">
                                                                    <label className="mdc-text-field mdc-text-field--outlined" onClick={firstAidCalendar}>
                                                                        <input value={firstAidCourseCompletionDate} type="text" className={firstAidCourseCompletionDate ? 'mdc-text-field__input active' : 'mdc-text-field__input' } aria-labelledby="my-label-id"
                                                                        id="firstAidCourseCompletionDate" onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">calendar_today</span>
                                                                    </label>
                                                                    <>
                                                                    {
                                                                        firstAidCourseCal === true ? <Calendar onChange={firstAidCalendarHandler} /> : null
                                                                    }
                                                                    </>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className="select-field">
                                                                    <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">{firstAidCourseReminder ? firstAidCourseReminder : 'Set Reminder'}</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">arrow_drop_down</span>
                                                                    </label>
                                                                    <ul className="dropdown">
                                                                        <>
                                                                        {
                                                                            reminder.map((item,index)=>
                                                                                <li onClick={()=>selectReminder("first_aid",item)}>{item}</li>
                                                                            )
                                                                        }
                                                                        </>
                                                                    </ul>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="text-left">CPR Course</label>
                                                        <div className="row custom-row">
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <label className="mdc-text-field mdc-text-field--outlined">
                                                                    <input value={cprCourse} onChange={(e) => {setCprCourse(e.target.value); e.target.setCustomValidity("")}} type="text" className={cprCourse ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                    id="cprCourse" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" />
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                                            
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className = "calendar-wrap">
                                                                    <label className="mdc-text-field mdc-text-field--outlined" onClick={cprCourseCalendar}>
                                                                        <input value={cprCourseCompletionDate} type="text" className={cprCourseCompletionDate ? 'mdc-text-field__input active' : 'mdc-text-field__input' } aria-labelledby="my-label-id"
                                                                        id="cprCourseCompletionDate" onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">calendar_today</span>
                                                                    </label>
                                                                    <>
                                                                    {
                                                                        cprCourseCal === true ? <Calendar onChange={cprCourseCalendarHandler} /> : null
                                                                    }
                                                                    </>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className="select-field">
                                                                    <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">{cprCourseReminder ? cprCourseReminder : 'Set Reminder'}</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">arrow_drop_down</span>
                                                                    </label>
                                                                    <ul className="dropdown">
                                                                        <>
                                                                        {
                                                                            reminder.map((item,index)=>
                                                                                <li onClick={()=>selectReminder("cpr",item)}>{item}</li>
                                                                            )
                                                                        }
                                                                        </>
                                                                    </ul>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="text-left">Asthma Course</label>
                                                        <div className="row custom-row">
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <label className="mdc-text-field mdc-text-field--outlined">
                                                                    <input value={asthmaCourse} type="text" onChange={(e) => {setAsthmaCourse(e.target.value); e.target.setCustomValidity("") }} className={asthmaCourse ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                    id="asthmaCourse" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" />
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                                                
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className = "calendar-wrap">
                                                                    <label className="mdc-text-field mdc-text-field--outlined" onClick={asthmaCourseCalendar}>
                                                                        <input value={asthmaCourseCompletionDate} type="text" className={asthmaCourseCompletionDate ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"
                                                                        id="asthmaCourseCompletionDate" onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">calendar_today</span>
                                                                    </label>
                                                                    <>
                                                                    {
                                                                        asthmaCourseCal === true ? <Calendar onChange={asthmaCourseCalendarHandler} /> : null
                                                                    }
                                                                    </>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className="select-field">
                                                                    <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">{asthamCourseReminder ? asthamCourseReminder : 'Set Reminder'}</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">arrow_drop_down</span>
                                                                    </label>
                                                                    <ul className="dropdown">
                                                                        <>
                                                                        {
                                                                            reminder.map((item,index)=>
                                                                                <li onClick={()=>selectReminder("asthma",item)}>{item}</li>
                                                                            )
                                                                        }
                                                                        </>
                                                                    </ul>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="text-left">Anaphylaxis Course</label>
                                                        <div className="row custom-row">
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <label className="mdc-text-field mdc-text-field--outlined">
                                                                    <input value={anapCourse} onChange={(e) => { setAnapCourse(e.target.value); e.target.setCustomValidity("") }} type="text" className={anapCourse ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"
                                                                    id="anapCourse" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" />
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                                                
                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className = "calendar-wrap">
                                                                    <label className="mdc-text-field mdc-text-field--outlined" onClick = {anapCourseCalendar}>
                                                                        <input value = {anapCourseCompletionDate} type="text" className={anapCourseCompletionDate ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"
                                                                        id="anapCourseCompletionDate" onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off"  />
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">calendar_today</span>
                                                                    </label>
                                                                    <>
                                                                    {
                                                                        anapCourseCal === true ? <Calendar onChange={anapCourseCalendarHandler} /> : null
                                                                    }
                                                                    </>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 custom-col">
                                                                <div className="select-field">
                                                                    <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                                                        <span className="mdc-notched-outline">
                                                                        <span className="mdc-notched-outline__leading"></span>
                                                                        <span className="mdc-notched-outline__notch">
                                                                            <span className="mdc-floating-label" id="my-label-id">{anapCourseReminder ? anapCourseReminder : 'Set Reminder'}</span>
                                                                        </span>
                                                                        <span className="mdc-notched-outline__trailing"></span>
                                                                        </span>
                                                                        <span className="material-icons">arrow_drop_down</span>
                                                                    </label>
                                                                    <ul className="dropdown">
                                                                        <>
                                                                        {
                                                                            reminder.map((item,index)=>
                                                                                <li onClick={()=>selectReminder("anap",item)}>{item}</li>
                                                                            )
                                                                        }
                                                                        </>
                                                                    </ul>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <h4 className="accordion-title js-accordion-title">Public Liability Insurance</h4> 
                                            <div className="accordion-content">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="20" value={insuranceProvider} onChange={(e) => {setInsuranceProvider(e.target.value); e.target.setCustomValidity("") }} type="text" className={insuranceProvider ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="insuranceProvider" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Insurance Provider*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                            
                                                        </label>	
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="20" value={policyNumber} onChange={(e) => {setPolicyNumber(e.target.value); e.target.setCustomValidity("") }} type="text" className={policyNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="policyNumber" required onBlur={(e)=>e.target.setCustomValidity("")}  />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Policy Number*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>	
                                                    </div>

                                                    <div  className="col-md-6">
                                                        <div className="calendar-wrap">
                                                            <label className="mdc-text-field mdc-text-field--outlined" onClick = {() => insuranceExpiryCalendar()}>
                                                                <input value={insuranceExpiryDate} type="text" className={insuranceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                id="insuranceExpiryDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                                <span className="material-icons">calendar_today</span>
                                                            </label>
                                                            <div>
                                                            { 
                                                                showInsuranceExpiryCal === true ? <Calendar onChange={insuranceExpiryCalendarHandler}/> : null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>	
                                            <h4 className="accordion-title js-accordion-title">Vehicle</h4> 
                                            <div className="accordion-content">
                                                <div className="row custom-row">
                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="10" value={vehicleMake} onChange={(e) => {setVehicleMake(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleMake ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="vehicleMake" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Vehicle Make*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                                            
                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="10" value={vehicleModel} onChange={(e) => {setVehicleModel(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleModel ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="vehicleModel" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Vehicle Model*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="4" type="text" value={vehicleYear} onChange={(e) => {if(e.target.value.length<=4){setVehicleYear(e.target.value.replace(/\D/g, ""))} e.target.setCustomValidity("") }} className={vehicleYear ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="vehicleYear" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Vehicle Year*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="10" value={vehicleRegisteration} onChange={(e) => {setVehicleRegisteration(e.target.value); e.target.setCustomValidity("") }} type="text" className={vehicleRegisteration ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                            aria-labelledby="my-label-id" id="vehicleRegisteration" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Registration*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>	

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <div className="calendar-wrap">
                                                            <label className="mdc-text-field mdc-text-field--outlined" onClick = {() => registrationExpiryCalendar()} >
                                                                <input value = {registrationExpiryDate} type="text" className={registrationExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                                aria-labelledby="my-label-id" id="registrationExpiryDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Registration Expiry Date*</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                                <span className="material-icons">calendar_today</span>
                                                            </label>
                                                            <div>
                                                            { 
                                                                registrationExpiryCal === true ? <Calendar onChange={registrationExpiryCalendarHandler}/> : null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>	

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <div className = "calendar-wrap">
                                                            <label className="mdc-text-field mdc-text-field--outlined" onClick = {() => vehicleMaintenanceCalendar()}>
                                                                <input value={vehicleMaintenanceDate} type="text" className={vehicleMaintenanceDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                                aria-labelledby="my-label-id" id="vehicleMaintenanceDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle maintenance date*</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                                <span className="material-icons">calendar_today</span>
                                                            </label>
                                                            <div>
                                                                { 
                                                                    vehicleMaintenanceCal === true ? <Calendar onChange={vehicleMaintenanceCalendarHandler}/> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <div className="calendar-wrap">
                                                            <label className="mdc-text-field mdc-text-field--outlined" onClick = {() => vehicleAuditCalendar()}>
                                                                <input value={vehicleAuditDate} type="text" className={vehicleAuditDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                id="vehicleAuditDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Audit date*</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                                <span className="material-icons">calendar_today</span>
                                                            </label>
                                                            <div>
                                                            { 
                                                                vehicleAuditCal === true ? <Calendar onChange={vehicleAuditCalendarHandler}/> : null
                                                            }
                                                            </div>
                                                        </div>	
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <label className="mdc-text-field mdc-text-field--outlined">
                                                            <input maxlength="18" value={licenceNumber} onChange={(e) => {setLicenceNumber(e.target.value); e.target.setCustomValidity("") }} type="text" className={licenceNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                            id="licenceNumber" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Licence number*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            </span>
                                                        </label>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 custom-col">
                                                        <div className="calendar-wrap">
                                                            <label className="mdc-text-field mdc-text-field--outlined" onClick = {() => licenceExpiryCalendar()}>
                                                                <input value={licenceExpiryDate} type="text" className={licenceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                id="licenceExpiryDate" required onBlur={(e)=>e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Licence Expiry Date*</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                                <span className="material-icons">calendar_today</span>
                                                            </label>
                                                            <div>
                                                            { 
                                                                licenceExpiryCal === true ? <Calendar onChange={licenceExpiryCalendarHandler}/> : null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>	
                                            <h4 className="accordion-title js-accordion-title">Occupants</h4> 
                                            <div className="accordion-content">
                                            {
                                                occupants.length > 0 ? occupants.map((occupant, index) => (
                                                    <div className="occupants-fields-wrap">
                                                    <div key={index} className="row custom-row">
                                                        <div className="col-lg-4 col-md-6 custom-col">
                                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                                <input maxlength="20" onChange={(e) => occupantInputHandler(index, e.target.value, 'occupant_name')} value={occupant.occupant_name ? occupant.occupant_name : ""} type="text" className={occupant.occupant_name ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                                aria-labelledby="my-label-id" />
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Occupant Name</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                                            
                                                        <div className="col-lg-4 col-md-6 custom-col">
                                                            <div className="calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => occupantsCalendar('occupantdateofbirth',index)}>
                                                                    <input type="text" value={occupant.occupant_date_of_birth ? occupant.occupant_date_of_birth : ""} className={occupant.occupant_date_of_birth ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Occupant Date Of Birth</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                </label>
                                                                { 
                                                                    occupant.showDobCalender === true ? <Calendar onChange={onChangeDate}/> : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div  className="col-lg-4 col-md-6 custom-col">
                                                            <div className="calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => occupantsCalendar('occupantdatesigned',index)}>
                                                                    <input value={occupant.occupant_date_signed ? occupant.occupant_date_signed : ""} type="text" className={occupant.occupant_date_signed ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Occupant Date Signed</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                </label>
                                                                { 
                                                                    occupant.showSignedCalender === true ? <Calendar onChange={onChangeDate}/> : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-6 custom-col">
                                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                               <input maxlength="18" onChange={(e) => occupantInputHandler(index, e.target.value, 'occupant_blue_card_number')} value={occupant.occupant_blue_card_number ? occupant.occupant_blue_card_number : ""} type="text" className={occupant.occupant_blue_card_number ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Occupant Blue Card Number</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                            </label>
                                                        </div>	

                                                        <div className="col-lg-4 col-md-6 custom-col">
                                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                                <input maxlength="18" onChange={(e) => occupantInputHandler(index, e.target.value, 'occupant_blue_card')} value={occupant.occupant_blue_card ? occupant.occupant_blue_card : ""} type="text" className={occupant.occupant_blue_card ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                                <span className="mdc-notched-outline">
                                                                <span className="mdc-notched-outline__leading"></span>
                                                                <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Occupant Blue Card</span>
                                                                </span>
                                                                <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                            </label>
                                                        </div>	

                                                        <div className="col-lg-4 col-md-6 custom-col">
                                                            <div className="calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => occupantsCalendar('occupantcardexpirydate',index)}>
                                                                    <input type="text" value={occupant.occupant_card_expiry_date} className={occupant.occupant_card_expiry_date ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"/>
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Occupant Card Expiry Date</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    </span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                </label>
                                                                { 
                                                                    occupant.showExpiryCalender === true ? <Calendar onChange={onChangeDate}  formatDay ={
                                                                        (date) => new Intl.DateTimeFormat(
                                                                          locale, 
                                                                          {
                                                                            day: "2-digit",
                                                                            month: "2-digit",
                                                                            year: "numeric", 
                                                                            
                                                                           
                                                                          }).format(date)
                                                                        }
                                                                        /> : null
                                                                }
                                                            </div>
                                                            <button onClick={(e)=>{removeMoreNewFields(e, index,occupant.occupant_id)}} ><span className="material-icons close">close</span></button>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    )) : null
                                                }
                                            </div>	*/}
                                        </div> 
                                    </div>	
                                </div>	
                            </div>
                            </form>
                        </div>		
                    </div>	
                </div>
            </>
        )
    }

export default EditEducatorProfilePage;