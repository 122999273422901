import { baseUrl } from '../config';
const axios = require('axios').default;

function NodeDetails(nid) {
   
    let dateRef = Date().toLocaleString();
    return axios.get(`${baseUrl}/node/${nid}?_format=json&date=${dateRef}`, {})
        .then(async (response) => {
            if (response.status === 200) {
                
                return ({ status: response.status, data: response });
            }
        })
        .catch(function (error) {
            
        });
}

function TaskDetails(nid) {
    return axios.get(`${baseUrl}/node/${nid}?_format=json`, {})
        .then(async (response) => {
            if (response.status === 200) {
                
                return ({ status: response.status, data: response });
            }
        })
        .catch(function (error) {
            
        });
}


export { NodeDetails , TaskDetails }