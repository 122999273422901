import { baseUrl } from '../config';
import moment from 'moment'
import { Alert } from 'antd';
const axios = require('axios').default;
let username = 'dev_ln';
let password = 'dev@LN41';
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();
var current_date = yyyy + '-' + mm + '/' + dd;
var lastDay = new Date(yyyy, mm + 1, dd);
var archiveDate = current_date;

function formatDateTime() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-01 ${hours}:${minutes}:${seconds}`;
}
function formatEndDateTime() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-31 ${hours}:${minutes}:${seconds}`;
}

const id = "";
const getXcsrfToken = async () => {

  try {
    const response = await axios.get(`${baseUrl}/session/token`, {});
    return await (response.data);
  } catch (error) {
    // window.localStorage.clear();
    window.location.reload(false);
    window.location = `${window.location.origin}?error=token-expired`;
  }
}

async function getParticularUserContent(contentType, uid) {

  let filteredField = contentType === "visits" ? "field_visitor_target_id" : "field_assign_to_target_id"
  let dateRef = Date().toLocaleString()
  return axios.get(`${baseUrl}/contents/api?type=${contentType}&${filteredField}=${uid}?date=${dateRef}`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
export async function getUserBirthdayByDate(formattedDate) {
  try {
    const response = await axios.get(`${baseUrl}/professional_file/user_birthday?date=${formattedDate}`);
    if (Array.isArray(response.data)) {
      const usernames = response.data.map((item) => item.username);
      return usernames;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

async function getParticularUserContentCustom(contentType, uid) {

  let user_role = window.localStorage.getItem('user_role');
  let filteredField = '';
  let timestamp = Date.now();
  let archived = '';
  if (user_role === 'educator' || user_role === 'cabt') {
    filteredField = contentType === "visits" ? "field_visitor_target_id" : "field_assign_to_target_id"
    archived = contentType === "task" ? "&archived=0" : ''
  } else {
    filteredField = "authored_by"
  }

  return axios.get(`${baseUrl}/contents/api?type=${contentType}&${filteredField}=${uid}${archived}&${timestamp}`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getUpcomingVisits(uid, filterValue) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let dateRef = Date().toLocaleString()
  let userRole = window.localStorage.getItem('user_role');
  let userId = uid ? uid : window.localStorage.getItem('uid');
  let uname = window.localStorage.getItem('name');

  let url = `${baseUrl}/upcoming/M&S?_format=json&date=${dateRef}`
  if (userRole === "educator" || uid) {
    url = `${baseUrl}/upcoming/M&S?_format=json&visitor=${userId}&date=${dateRef}`
  } else if (filterValue === "personal") {
    url = `${baseUrl}/upcoming/M&S?_format=json&uid=${uname}&date=${dateRef}`
  }

  // console.log({
  //     url,
  //     userRole,
  //     filterValue,
  //     uname
  // });
  return axios.get(url, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

function currentDateFormatter() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

async function getUpcomingVisitsNew(pageCount, searchVal, uid, sortBy, showUpcomingMonth) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let dateRef = Date().toLocaleString()
  let userRole = window.localStorage.getItem('user_role');
  let userId = uid ? uid : window.localStorage.getItem('uid');
  let uname = window.localStorage.getItem('name');

  // let url = `${baseUrl}/upcoming/M&S?_format=json&date=${dateRef}`
  // if (userRole === "educator" || uid) {
  //   url = `${baseUrl}/upcoming/M&S?_format=json&visitor=${userId}&date=${dateRef}`
  // } else if (filterValue === "personal") {
  //   url = `${baseUrl}/upcoming/M&S?_format=json&uid=${uname}&date=${dateRef}`
  // }



  let currentDate = currentDateFormatter()

  let search = searchVal ? searchVal : ''
  let sort = sortBy ? sortBy : 'ASC'
  let page = pageCount ? pageCount : '0'
  let date = formatDateTime()
  let endDate = formatEndDateTime()
  let value = '&field_status_visit_value_op!=&field_status_visit_value=Completed'
  let url = `${baseUrl}/upcoming/M&S-new?_format=json&field_date_value=${date}&combine=${search}&sort_by=field_date_value&sort_order=${sort}&page=${page}${value}`
  if (userRole === "educator" || userRole === "cabt" || uid !== 0) {
    url = `${baseUrl}/upcoming/M&S-new?_format=json&field_date_value=${date}&combine=${search}&sort_by=field_date_value&sort_order=${sort}&page=${page}&visitor=${userId}${value}`
  } else if (showUpcomingMonth == 'upcoming') {
    url = `${baseUrl}/upcoming/M&S-new?_format=json&field_date_value=${date}&&field_date_value_1=${endDate}&combine=${search}&sort_by=field_date_value&sort_order=${sort}&page=${page}${value}`
  } else if (showUpcomingMonth == 'previous') {
    url = `${baseUrl}/upcoming/M&S-new?_format=json&field_date_value_1=${currentDate}&combine=${search}&sort_by=field_date_value&sort_order=${sort}&page=${page}${value}`
  }
// https://wiseappbackend.thecorella.com/upcoming/M%26S-new?_format=json&field_date_value_1=2024-11-06
// https://wiseappbackend.thecorella.com/upcoming/M&S-new?_format=json&field_date_value=2024-11-01%2015:48:43&combine=&sort_by=field_date_value&sort_order=ASC&page=0&field_status_visit_value_op!=&field_status_visit_value=Completed

  // console.log({
  //     url,
  //     userRole,
  //     filterValue,
  //     uname
  // });
  return axios.get(url, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getCompletedVisitsNew(uid) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let userId = uid ? uid : window.localStorage.getItem('uid');
  let url = `${baseUrl}/upcoming/M&S-new?_format=json&sort_by=field_date_value&sort_order=DESC&page=0&visitor=${userId}&field_status_visit_value_op==&field_status_visit_value=Completed`

  return axios.get(url, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
async function getCurrentVisits(uid, filterValue) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let dateRef = Date().toLocaleString()
  let userRole = window.localStorage.getItem('user_role');
  let userId = uid ? uid : window.localStorage.getItem('uid');
  let uname = window.localStorage.getItem('name');
  let filteredField = userRole === "educator" ? `${baseUrl}/upcoming/M&S?_format=json&visitor=${userId}&date=${dateRef}` :
    filterValue === "personal" ? `${baseUrl}/upcoming/M&S?_format=json&uid=${uname}` : `${baseUrl}/upcoming/M&S?_format=json&date=${dateRef}`

  return axios.get(filteredField, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
async function getUpcomingVisiter(userId, filterValue) {


  //THIS
  let dateRef = Date().toLocaleString()
  let filteredField = `${baseUrl}/upcoming/M&S?_format=json&visitor=${userId}&date=${dateRef}`

  return axios.get(filteredField, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getTaskAssign(Id) {
  let userId = await window.localStorage.getItem('uid')
  let filteredField = `${baseUrl}/api/task/assign?_format=json&field_assign_to=${Id}`

  return axios.get(filteredField, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
async function getUpcomingMandS(userId) {
  //https://wiseapp.thecorella.com/user/2671?_format=json
  //THIS
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  let filteredField = `${baseUrl}/user/${userId}?_format=json`

  return axios.get(filteredField, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
//22Feb
async function getAllContent(contentType) {
  let dateRef = Math.random();
  let url = `${baseUrl}/contents/api?type=${contentType}&t=${dateRef}`
  if (contentType == 'links') {
    url = `${baseUrl}/contents/api?type=${contentType}&t=${dateRef}&sort_order=DESC&sort_by=field_date_value`
  } else if (contentType == 'custom-task') {
    url = `${baseUrl}/view/task-api-update?type=task&t=${dateRef}`
  }

  return axios.get(url, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  })
}


async function getSpecificUser(uid, authToken) {


  const headers = {
    'Authorization': 'Basic ' + window.btoa(`${username}:${password}`),
    'Content-Type': 'application/json'
  }
  return axios.get(`${baseUrl}/user/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function createTask(data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "type": [{ "target_id": "task" }],
    "title": [{ "value": data.title }],
    "field_assign_to": [{ "target_id": data.educator_id ? data.educator_id : null }],
    "field_task_notes": [{ "value": data.notes }],
    "field_date": [{ "value": data.date }],
    "field_status": [{ "value": data.status.value }]
  }
  // console.log("data",data);
  // alert(bodyData.field_date)
  if (data.educator_id === "" || data.educator_id == undefined)
  // alert("data.educator_id")
  {
    Object.assign(bodyData, { "field_all": [{ "value": true }] })
  }


  return axios.post(`${baseUrl}/node?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 201) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function createNoticeBoard(data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "type": [{ "target_id": "notice_board" }],
    "body": [{ "value": data.notes }],
    "title": [{ "value": data.title }],
    "field_date": [{ "value": data.date }],
    "field_archived_date": [{ "value": data.archiveDate }],
    "field_url": data.link,
    "field_educator": [{ "target_id": data.educator_id }],
    "field_img": data.fid
  }

  return axios.post(`${baseUrl}/node?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 201) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function createLink(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.post(`${baseUrl}/node?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 201) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function createVisit(data) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  //     
  //     var hms = `${data.time}:00`;   // your input string
  //     var a = hms.split(':'); // split it at the colons
  //     

  // // minutes are worth 60 seconds. Hours are worth 60 minutes.
  //    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 

  let bodyData = {
    "type": [{ "target_id": "visits" }],
    "title": [{ "value": data.title }],
    "field_date": [{ "value": data.date }],
    "field_venue": [{ "value": data.location }],
    "field_visitor": [{ "target_id": data.educator_id ? data.educator_id : null }],
    "field_time": [{ "value": data.time }],
    "field_visit_method": [{ "target_id": data.method }],
    "field_embed_video": data.video.length > 0 ? data.video : null,
    "field_file": data.fid,
    "field_agenda": [{ "value": data.agenda }],
    "field_image": data.imageFid,
    "field_headoffice": [{ "target_id": data.field_headoffice ? data.field_headoffice : null }],
    "field_status_visit": [{ "value": data.taskTodo }]

  }
  //  

  if (data.educator_id === "") {
    Object.assign(bodyData, { "field_all": [{ "value": true }] })
  }

  return axios.post(`${baseUrl}/node?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 201) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function updateEducatorNotesVisit(visitId, data) {
  // 
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "type": [{ "target_id": "visits" }],
    "field_user_notes": [{ "value": data.educator_notes }]
  }


  return axios.patch(`${baseUrl}/node/${visitId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      // console.log({error});
      return ({ message: error.response.data.message })
    });
}

async function updateVisitDetails(visitId, data) {
  // 
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "type": [{ "target_id": "visits" }],
    "title": [{ "value": data.title }],
    "field_date": [{ "value": data.date }],
    "field_venue": [{ "value": data.location }],
    "field_visitor": [{ "target_id": data.educator_id ? data.educator_id : null }],
    "field_time": [{ "value": (data.time) }],
    "field_visit_method": [{ "target_id": data.method }],
    "field_embed_video": data.video,
    "field_signature": [{ "target_id": data.signature }],
    "field_agenda": [{ "value": data.agenda }],
    "field_headoffice": [{ "target_id": data.field_headoffice }],
    "field_status_visit": [{ "value": data.taskTodo ? data.taskTodo : "Todo" }],
    "field_notes": [{ "value": data.notes }],
  }

  if (data.educatorSignatureVisit) {
    bodyData = { ...bodyData, "field_educator_signature": [{ "target_id": data.educatorSignatureVisit }] }
  }
  if (data.coordinatorSignatureVisit) {
    bodyData = { ...bodyData, "field_coordinator_signature": [{ "target_id": data.coordinatorSignatureVisit }] }
  }

  if (data.imageFid !== undefined && data.imageFid !== null) {
    Object.assign(bodyData, { "field_image": data.imageFid })
  }

  if (data.fid !== undefined && data.fid !== null) {
    Object.assign(bodyData, { "field_file": data.fid })
  }

  return axios.patch(`${baseUrl}/node/${visitId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      // console.log({error});
      return ({ message: error.response.data.message })
    });
}


async function bookEvent(data) {
  let csrfToken = window.localStorage.getItem('login_csrf_token')
  let authToken = window.localStorage.getItem('auth_token')

  let bodyData = {
    "type": [{ "target_id": "booking" }],
    "title": [{ "value": "No of seats " + data.seats }],
    "field_link_name": [{ "target_id": data.linkName }],
    "field_no_of_seats": [{ "value": data.seats }]
  }

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  return axios.post(`${baseUrl}/node?_format=json`, bodyData, { headers }).then(async (response) => {
    if (response.status === 201) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getSpecificMedia(mediaId) {

  let authToken = window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'Content-Type': 'application/json'
  }
  return axios.get(`${baseUrl}/media/${mediaId}/edit?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return (response)
      } else {
        return ("")
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getSpecificTaxonomy(taxonomyId) {

  let authToken = window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'Content-Type': 'application/json'
  }
  return axios.get(`${baseUrl}/taxonomy/term/${taxonomyId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getUserProfileData(profileId) {

  let authToken = window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/profile/${profileId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function uploadImage(fileName, imageData) {
  //    
  //    
  if (fileName !== '' && imageData !== '') {
    let authToken = window.localStorage.getItem('auth_token')
    let csrfToken = await getXcsrfToken()

    // encodeURIComponent(fileName.replaceAll(' ', '-'))

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/octet-stream"
    }
    // console.log('headers', headers);

    let binaryImagedata = convertDataURIToBinary(imageData)
    // console.log('binaryImagedata', binaryImagedata);

    return axios.post(`${baseUrl}/file/upload/media/image/field_media_image?_format=json`, binaryImagedata, {
      headers: headers,
      credentials: 'include'
    })
      .then(async (response) => {
        if (response.status === 201) {
          return ({ status: response.status, data: response })
        } else {
          return ({ message: response.message })
        }
      })
      .catch(function (error) {
        console.log('error', error);
        return ({ message: error.response.data.message })
      });
  }
}

async function riskAssesmentFile(fileName, filepath) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  let binaryImagedata = convertDataURIToBinary(filepath)

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/octet-stream"
  }

  return axios.post(`${baseUrl}/file/upload/node/links/field_risk_assessment_file?_format=json`, binaryImagedata, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getAllEducators() {

  let authToken = await window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken
  }

  return axios.get(`${baseUrl}/api/educator/list?format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getCaBTList() {
  let authToken = await window.localStorage.getItem("auth_token");

  const headers = {
    Authorization: "Bearer " + authToken,
  };

  // https://wiseappbackend.thecorella.com/api/cabt-list?_format=json
  return axios
    .get(`${baseUrl}/api/cabt-list?_format=json`, {
      headers: headers,
      credentials: "include",
    })
    .then(async (response) => {
      if (response.status === 200) {
        return { status: response.status, data: response };
      } else {
        return { message: response.message };
      }
    })
    .catch(function (error) {
      return { message: error.response.data.message };
    });
}

async function getCoordinators() {
  let authToken = await window.localStorage.getItem("auth_token");

  const headers = {
    Authorization: "Bearer " + authToken,
  };

  return axios
    .get(`${baseUrl}/api/coordinator-list?format=json`, {
      headers: headers,
      credentials: "include",
    })
    .then(async (response) => {
      if (response.status === 200) {
        return { status: response.status, data: response };
      } else {
        return { message: response.message };
      }
    })
    .catch(function (error) {
      return { message: error.response.data.message };
    });
}

async function getArchiveEducators() {

  let authToken = await window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken
  }

  return axios.get(`${baseUrl}/api/educator/list?archive=1`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


function convertDataURIToBinary(dataURI) {

  var BASE64_MARKER = ';base64,';
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

async function logoutUser() {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/user/logout?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        delete axios.defaults.headers.common["Authorization"];
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getAllFolders(param) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,   // https://wiseapp.thecorella.com
    'X-CSRF-Token': csrfToken
  }

  let url = param !== undefined && param !== null ? `${baseUrl}/api/get/user-folders?_format=json&private=0` : `${baseUrl}/api/get/user-folders?_format=json`

  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error })
    });
}

async function createNewFolder(bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.post(`${baseUrl}/taxonomy/term?_format=json`, bodyData, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getFolderFiles(taxonomyId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.get(`${baseUrl}/api/${taxonomyId}/folder/files?_format=json`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error })
    });

}


async function getOccupant(occupantId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/entity/paragraph/${occupantId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        //  alert(JSON.stringify(response.data.field_operational_hours))
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function uploadAllDocs(fileName, fileData, taxonomyId) {

  if (fileName !== '' && fileData !== '') {
    let authToken = window.localStorage.getItem('auth_token')
    let csrfToken = await getXcsrfToken()

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/octet-stream"
    }

    let binaryFiledata = convertDataURIToBinary(fileData)

    return axios.post(`${baseUrl}/file/upload/media/document_file/field_media_file_1?_format=json`, binaryFiledata, {
      headers: headers,
      credentials: 'include'
    })
      .then(async (response) => {
        if (response.status === 201) {

          let objData = {
            "bundle": [{
              "target_id": "document_file"

            }],
            "name": [{
              "value": fileName
            }],

            "field_media_file_1": [{
              "target_id": response.data.fid[0].value
            }],
            "field_folder": [{
              "target_id": taxonomyId

            }]
          }

          const mediaHeaders = {
            'Authorization': 'Bearer ' + authToken,
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json"
          }
          return axios.post(`${baseUrl}/entity/media?_format=json`, objData, {
            headers: mediaHeaders
          })
            .then(async (res) => {
              if (res.status === 201) {
                return ({ status: res.status, data: res })
              }
            })
            .catch(function (err) {
              return ({ message: err.res.data.message })
            })
        }
      })
      .catch(function (error) {
        return ({ message: error.response.data.message })
      })
  }
}
async function updateUserProfileCeasedDate(profileId, data, value) {


  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  let bodyData;


  bodyData = {
    "type": "educator",
    "field_addition_info": [{ "value": "this is the  updated sample" }],
    "uid": [{ "target_id": data.uid }],
    "field_ceased_date": [{ "value": data.ceasedDate }]

  }


  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'

  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response, data: response })
      } else {
        return ({ message: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function updateUserProfile(profileId, data, value) {

  console.log('----- here i am');
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  let bodyData;

  if (value === "updateOccupant") {
    bodyData = { "type": "educator", 'field_all_occupants': data.field_all_occupants }
  } else if (value === undefined) {

    // bodyData = {
    //   "type": "educator",
    //   "uid": [{ "target_id": data.uid }],
    //   "field_suburb_name": [{ "value": data.suburbName }],
    //   "field_postcode": [{ "value": data.postal_code }],
    //   "field_street_address": [{ "value": data.street }],
    //   // "field_asthma_course": [{ "value": data.asthmaCourse }], field_anap_course_complete_date
    //   "field_cpr_course": [{ "value": data.cprCourse }],
    //   "field_first_aid_course": [{ "value": data.firstAidCourse }],
    //   "field_insurance_provider": [{ "value": data.insuranceProvider }],
    //   "field_licence_number": [{ "value": data.licenceNumber }],
    //   "field_policy_number": [{ "value": data.policyNumber }],
    //   "field_registration": [{ "value": data.vehicleRegisteration }],
    //   "field_d_o_b": [{ "value": data.dob }],
    //   "field_insurance_expiry_date": [{ "value": data.insuranceExpiryDate }],
    //   "field_licence_expiry_date": [{ "value": data.licenceExpiryDate }],
    //   "field_registration_expiry_date": [{ "value": data.registrationExpiryDate }],
    //   "field_vehicle_audit_date": [{ "value": data.vehicleAuditDate }],
    //   "field_vehicle_maintenance_date": [{ "value": data.vehicleMaintenanceDate }],
    //   "field_ceased_date": [{ "value": data.ceasedDate }],
    //   "field_commencement_date": [{ "value": data.commencementDate }],
    //   "field_vehicle_make": [{ "value": data.vehicleMake }],
    //   "field_vehicle_model": [{ "value": data.vehicleModel }],
    //   "field_vehicle_year": [{ "value": data.vehicleYear }],
    //   "field_operational_days": data.operationalDays,
    //   // "field_anaphylaxis_course": [{ "value": data.anapCourse }],
    //   "field_first_aid_completion_date": [{ "value": data.firstAidCourseCompletionDate }],
    //   "field_cpr_course_completion_date": [{ "value": data.cprCourseCompletionDate }],
    //   // "field_asth_course_complete_date": [{ "value": data.asthmaCourseCompletionDate }],
    //   // "field_anap_course_complete_date": [{ "value": data.anapCourseCompletionDate }],
    //   "field_first_aid_course_reminder": [{ "value": data.firstAidCourseReminder }],
    //   "field_cpr_course_reminder": [{ "value": data.cprCourseReminder }],
    //   // "field_asthma_course_reminder": [{ "value": data.asthamCourseReminder }],
    //   // "field_anaphylxis_course_reminder": [{ "value": data.anapCourseReminder }],
    //   "field_annual_assessment_completi": [{ "value": data.annualAssessmentDate }],
    //   "field_set_reminder_frequency": [{ "value": data.annualAssessmentReminder }],
    //   "field_maintenance_reminder_date": [{ "value": data.maintenanceDateRemainder }],
    //   "field_reg_exp_reminder_date": [{ "value": data.vehicleRegisterationReminder }],
    //   "field_restraint_audit_reminder": [{ "value": data.auditDateReminder }],
    //   "field_license_exp_reminder_date": [{ "value": data.drivingLiscenceReminder }],
    //   "field_public_reminder_date": [{ "value": data.publicLiabilityInsuranceRemainder }],
    //   "field_cpr_certificate": [{ "target_id": data.field_cpr_certificate }],
    //   // "field_anaphylaxis_certificate": [{ "target_id": data.field_anaphylaxis_certificate }],
    //   // "field_asthma_course_certificate": [{ "target_id": data.field_asthma_course_certificate }],
    //   "field_certificate_document": [{ "target_id": data.field_certificate_document }],
    //   "field_vehicle_expiry_date": [{ "value": data.maintenanceDate }],
    //   "field_vehicle_restraint_expiry": [{ "value": data.restraintDate }],
    //   "field_first_aid_expiry_date": [{ "value": data.firstAidExpiryDate }],
    //   "field_cpr_expiry_date": [{ "value": data.cprExpiryDate }],
    //   // "field_asthma_expiry_date": [{ "value": data.asthmaExpiryDate }],
    //   // "field_anaphylaxis_expiry_date": [{ "value": data.anaphylaxisExpiryDate }],
    //   "field_business_expiry_date": [{ "value": data.annualAssessmentExpiryDate }],
    //   "field_home_number": [{ "value": data.mobile_number }],
    //   "field_residence": [{ "value": data.basicResidence }],
    //   "field_status": [{ "value": data.basicStatus }],
    //   "field_pool_safety_cert":[{"target_id":data.poolSafetyCert}],
    //   "field_pool_safety_cert_comp_date":[{"value":data.poolCourseCompletionDate}],
    //   "field_pool_safety_cert_exp_date":[{"value":data.poolExpiryDate}],
    //   "field_pool_safety_cert_set_remin":[{"value":data.poolSafetyCourseReminder}]

    // }
    
    bodyData = {
      "type": "educator",
      "uid": [{ "target_id": data.uid }],
      "field_vehicle_restraint_expiry": [{ "value": data.restraintDate || null }],
      "field_registration_expiry_date": [{ "value": data.registrationExpiryDate || null}],
      "field_vehicle_expiry_date": [{ "value": data.maintenanceDate || null}],
      "field_licence_expiry_date": [{ "value": data.licenceExpiryDate || null}],
      "field_pool_safety_cert_comp_date": [{ "value": data.poolCourseCompletionDate || null}],
      "field_pool_safety_cert_set_remin": [{ "value": data.poolSafetyCourseReminder || null}],
      "field_pool_safety_cert": [{ "target_id": data.poolSafetyCert || null }],
      "field_pool_safety_cert_exp_date": [{ "value": data.poolExpiryDate || null}],
      "field_insurance_expiry_date": [{ "value": data.insuranceExpiryDate || null}],
      "field_first_aid_completion_date": [{ "value": data.firstAidCourseCompletionDate || null }],
      "field_first_aid_expiry_date": [{ "value": data.firstAidExpiryDate || null }],
      "field_cpr_course_completion_date": [{ "value": data.cprCourseCompletionDate || null }],
      "field_business_expiry_date": [{ "value": data.annualAssessmentExpiryDate || null }],
      "field_annual_assessment_completi": [{ "value": data.annualAssessmentDate || null}],
      "field_ceased_date": [{ "value": data.ceasedDate || null}],
      "field_commencement_date": [{ "value": data.commencementDate || null}],
      "field_cpr_expiry_date": [{ "value": data.cprExpiryDate || null}],
      "field_d_o_b": [{ "value": data.dob || null}],
      "field_please_provide_details_bel":data.field_please_provide_details_bel
      // "field_emergency_contacts":data.emergencyContacts
    }
    if (data.suburbName) {
      bodyData = {
        ...bodyData,
        "field_suburb_name": [{ "value": data.suburbName }]
      }
    }
    if (data.postal_code) {
      bodyData = {
        ...bodyData,
        "field_postcode": [{ "value": data.postal_code }]
      }
    }
    if (data.street) {
      bodyData = {
        ...bodyData,
        "field_street_address": [{ "value": data.street }]
      }
    }
    if (data.cprCourse) {
      bodyData = {
        ...bodyData,
        "field_cpr_course": [{ "value": data.cprCourse }]
      }
    }
    if (data.firstAidCourse) {
      bodyData = {
        ...bodyData,
        "field_first_aid_course": [{ "value": data.firstAidCourse }]
      }
    }
    if (data.insuranceProvider) {
      bodyData = {
        ...bodyData,
        "field_insurance_provider": [{ "value": data.insuranceProvider }]
      }
    }
    if (data.licenceNumber) {
      bodyData = {
        ...bodyData,
        "field_licence_number": [{ "value": data.licenceNumber }]
      }
    }
    if (data.policyNumber) {
      bodyData = {
        ...bodyData,
        "field_policy_number": [{ "value": data.policyNumber }]
      }
    }
    if (data.vehicleRegisteration) {
      bodyData = {
        ...bodyData,
        "field_registration": [{ "value": data.vehicleRegisteration }]
      }
    }
    // if (data.dob) {
    //   bodyData = {
    //     ...bodyData,
    //     "field_d_o_b": [{ "value": data.dob }]
    //   }
    // }
    if (data.vehicleAuditDate) {
      bodyData = {
        ...bodyData,
        "field_vehicle_audit_date": [{ "value": data.vehicleAuditDate }]
      }
    }
    if (data.vehicleMaintenanceDate) {
      bodyData = {
        ...bodyData,
        "field_vehicle_maintenance_date": [{ "value": data.vehicleMaintenanceDate }]
      }
    }
    if (data.vehicleMake) {
      bodyData = {
        ...bodyData,
        "field_vehicle_make": [{ "value": data.vehicleMake }]
      }
    }
    if (data.vehicleModel) {
      bodyData = {
        ...bodyData,
        "field_vehicle_model": [{ "value": data.vehicleModel }]
      }
    }
    if (data.vehicleYear) {
      bodyData = {
        ...bodyData,
        "field_vehicle_year": [{ "value": data.vehicleYear }]
      }
    }
    if (data.operationalDays) {
      bodyData = {
        ...bodyData,
        "field_operational_days": data.operationalDays
      }
    }
    if (data.firstAidCourseReminder) {
      bodyData = {
        ...bodyData,
        "field_first_aid_course_reminder": [{ "value": data.firstAidCourseReminder }]
      }
    }
    if (data.cprCourseReminder) {
      bodyData = {
        ...bodyData,
        "field_cpr_course_reminder": [{ "value": data.cprCourseReminder }]
      }
    }
    if (data.annualAssessmentReminder) {
      bodyData = {
        ...bodyData,
        "field_set_reminder_frequency": [{ "value": data.annualAssessmentReminder }]
      }
    }
    if (data.maintenanceDateRemainder) {
      bodyData = {
        ...bodyData,
        "field_maintenance_reminder_date": [{ "value": data.maintenanceDateRemainder }]
      }
    }
    if (data.vehicleRegisterationReminder) {
      bodyData = {
        ...bodyData,
        "field_reg_exp_reminder_date": [{ "value": data.vehicleRegisterationReminder }]
      }
    }
    if (data.auditDateReminder) {
      bodyData = {
        ...bodyData,
        "field_restraint_audit_reminder": [{ "value": data.auditDateReminder }]
      }
    }
    if (data.drivingLiscenceReminder) {
      bodyData = {
        ...bodyData,
        "field_license_exp_reminder_date": [{ "value": data.drivingLiscenceReminder }]
      }
    }
    if (data.publicLiabilityInsuranceRemainder) {
      bodyData = {
        ...bodyData,
        "field_public_reminder_date": [{ "value": data.publicLiabilityInsuranceRemainder }]
      }
    }
    if (data.field_cpr_certificate) {
      bodyData = {
        ...bodyData,
        "field_cpr_certificate": [{ "target_id": data.field_cpr_certificate }]
      }
    }
    if (data.field_certificate_document) {
      bodyData = {
        ...bodyData,
        "field_certificate_document": [{ "target_id": data.field_certificate_document }]
      }
    }
    if (data.mobile_number) {
      bodyData = {
        ...bodyData,
        "field_home_number": [{ "value": data.mobile_number }]
      }
    }
    if (data.basicResidence) {
      bodyData = {
        ...bodyData,
        "field_residence": [{ "value": data.basicResidence }]
      }
    }
    if (data.basicStatus) {
      bodyData = {
        ...bodyData,
        "field_status": [{ "value": data.basicStatus }]
      }
    }

    if (data.field_public_ins_certificate) {
      bodyData = {
        ...bodyData,
        "field_public_ins_certificate": [{ "target_id": data.field_public_ins_certificate }]
      }
    }
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      console.log({ response });
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      console.log({ error });
      return ({ message: error.response.data.message })
    });

}

async function getEmergencyConatcts(profileId) {
 
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/${profileId}/emergency-contacts`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function addEmergencyContacts(uid,profileId, data) {
  
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/api/add/user/${uid}/${profileId}?_format=json`, data, {
    headers:headers,
    credentials: 'include'
  }).then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function updateUserResetData(profileId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "type": "educator",
    "uid": [{ "target_id": data.uid }],
    "field_anaphylaxis_course": [{ "value": data.anapCourse }],
    "field_anaphylxis_course_reminder": [{ "value": data.anapCourseReminder }],
    "field_anaphylaxis_expiry_date": [{ "value": data.anaphylaxisExpiryDate }],
    "field_anaphylaxis_certificate": [{ "target_id": data.field_anaphylaxis_certificate }],
    "field_anap_course_complete_date": [{ "value": data.anapCourseCompletionDate }],
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
async function updateAsthmaResetData(profileId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "type": "educator",
    "uid": [{ "target_id": data.uid }],
    "field_asthma_course": [{ "value": data.asthmaCourse }],
    "field_asthma_course_reminder": [{ "value": data.asthamCourseReminder }],
    "field_asthma_expiry_date": [{ "value": data.asthmaExpiryDate }],
    "field_asthma_course_certificate": [{ "target_id": data.field_asthma_course_certificate }],
    "field_asth_course_complete_date": [{ "value": data.asthmaCourseCompletionDate }],
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function addFavouriteFolder(dataObject, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.post(`${baseUrl}/entity/flagging?_format=json`, dataObject, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
async function updateUserData(uid, data, value) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  let bodyData;


  bodyData = {
    "field_first_name": [{ "value": data.first_name }],
    "field_last_name": [{ "value": data.last_name }]

  }

  return axios.patch(`${baseUrl}/user/${uid}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {

      return ({ message: error.response.data.message })
    });

}

async function updateFolder(taxonomyId, bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  return axios.patch(`${baseUrl}/taxonomy/term/${taxonomyId}?_format=json`, bodyData, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0,
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken,
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function deleteSpecificFolder(taxonomyId) {

  let authToken = await window.localStorage.getItem('auth_token')

  return axios.delete(`${baseUrl}/taxonomy/term/${taxonomyId}?_format=json`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0,
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken,
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function deleteSpecificFile(mediaId) {

  let authToken = await window.localStorage.getItem('auth_token')

  return axios.delete(`${baseUrl}/media/${mediaId}/edit?_format=json`, {
    headers: {

      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0,
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken,
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function updateCabtProfile(profileId, data, value) {

  let authToken = await window.localStorage.getItem('auth_token')
  // let csrfToken = await getXcsrfToken()login_csrf_token
  let csrfToken = await window.localStorage.getItem('login_csrf_token')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  let bodyData
  if (value === "updateOccupant") {
    bodyData = { "type": "cabt", 'field_all_occupants': data.field_all_occupants }
  } else if (value === undefined) {

    bodyData = {
      "uid": [{ "target_id": data.uid }],
      "type": "cabt",
      "field_please_provide_details_bel":data.field_please_provide_details_bel,
    }
    if (data.suburbName) {
      bodyData = {
        ...bodyData,
        "field_suburb_name": [{ "value": data.suburbName }]
      }
    }
    if (data.postal_code) {
      bodyData = {
        ...bodyData,
        "field_postcode": [{ "value": data.postal_code }]
      }
    }
    if (data.street) {
      bodyData = {
        ...bodyData,
        "field_street_address": [{ "value": data.street }]
      }
    }
    if (data.cprCourse) {
      bodyData = {
        ...bodyData,
        "field_cpr_course": [{ "value": data.cprCourse }]
      }
    }
    if (data.firstAidCourse) {
      bodyData = {
        ...bodyData,
        "field_first_aid_course": [{ "value": data.firstAidCourse }]
      }
    }
    if (data.policyNumber) {
      bodyData = {
        ...bodyData,
        "field_policy_number": [{ "value": data.policyNumber }]
      }
    }
    if (data.dob) {
      bodyData = {
        ...bodyData,
        "field_d_o_b": [{ "value": data.dob }]
      }
    }
    if (data.commencementDate) {
      bodyData = {
        ...bodyData,
        "field_commencement_date": [{ "value": data.commencementDate }]
      }
    }
    if (data.operationalDays) {
      bodyData = {
        ...bodyData,
        "field_operational_days": data.operationalDays
      }
    }
    if (data.firstAidCourseCompletionDate) {
      bodyData = {
        ...bodyData,
        "field_first_aid_completion_date": [{ "value": data.firstAidCourseCompletionDate }]
      }
    }
    if (data.cprCourseCompletionDate) {
      bodyData = {
        ...bodyData,
        "field_cpr_course_completion_date": [{ "value": data.cprCourseCompletionDate }]
      }
    }
    if (data.firstAidCourseReminder) {
      bodyData = {
        ...bodyData,
        "field_first_aid_course_reminder": [{ "value": data.firstAidCourseReminder }]
      }
    }
    if (data.cprCourseReminder) {
      bodyData = {
        ...bodyData,
        "field_cpr_course_reminder": [{ "value": data.cprCourseReminder }]
      }
    }
    if (data.maintenanceDateRemainder) {
      bodyData = {
        ...bodyData,
        "field_maintenance_reminder_date": [{ "value": data.maintenanceDateRemainder }]
      }
    }
    if (data.auditDateReminder) {
      bodyData = {
        ...bodyData,
        "field_restraint_audit_reminder": [{ "value": data.auditDateReminder }]
      }
    }
    if (data.field_cpr_certificate) {
      bodyData = {
        ...bodyData,
        "field_cpr_certificate": [{ "target_id": data.field_cpr_certificate }]
      }
    }
    if (data.field_certificate_document) {
      bodyData = {
        ...bodyData,
        "field_certificate_document": [{ "target_id": data.field_certificate_document }]
      }
    }
    if (data.firstAidExpiryDate) {
      bodyData = {
        ...bodyData,
        "field_first_aid_expiry_date": [{ "value": data.firstAidExpiryDate }]
      }
    }
    if (data.cprExpiryDate) {
      bodyData = {
        ...bodyData,
        "field_cpr_expiry_date": [{ "value": data.cprExpiryDate }]
      }
    }
    if (data.annualAssessmentExpiryDate) {
      bodyData = {
        ...bodyData,
        "field_business_expiry_date": [{ "value": data.annualAssessmentExpiryDate }]
      }
    }
    if (data.mobile_number) {
      bodyData = {
        ...bodyData,
        "field_home_number": [{ "value": data.mobile_number }]
      }
    }
    if (data.basicResidence) {
      bodyData = {
        ...bodyData,
        "field_residence": [{ "value": data.basicResidence }]
      }
    }

    if (data.field_study_course_date) {
      bodyData = {
        ...bodyData,
        "field_study_course_date": [{ "value": data.field_study_course_date }]
      }
    }

    if (data.field_study_course_title) {
      bodyData = {
        ...bodyData,
        "field_study_course_title": [{ "value": data.field_study_course_title }]
      }
    }

    if (data.field_residence) {
      bodyData = {
        ...bodyData,
        "field_residence": [{ "value": data.field_residence }]
      }
    }
    if(data.image){
      bodyData = {
        ...bodyData,
        "field_profile_picture":[{"target_id":data.image}]
      }
    }
    if(data.unitNumber){
      bodyData = {
        ...bodyData,
        "field_cabt_unit_number":[{"value":data.unitNumber}]
      }
    }
    if(data.streetNumber){
      bodyData = {
        ...bodyData,
        "field_cabt_street_number":[{"value":data.streetNumber}]
      }
    }
    if(data.businessRole){
      bodyData = {
        ...bodyData,
        "field_cabt_business_role":[{"value":data.businessRole}]
      }
    }
  }
  let newData = JSON.stringify(bodyData)
  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, newData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      console.log('error ', error);
      return ({ message: error.response.data.message })
    });

}

async function updateHeadOfficeProfile(profileId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // 
  let bodyData = {
    "uid": [{ "target_id": data.uid }],
    "type": "head_office_team",
    "field_home_number": [{ "value": data.mobile_number }],
    "field_cpr_course": [{ "value": data.cprCourse }],
    "field_first_aid_course": [{ "value": data.firstAidCourse }],
    "field_first_aid_course_reminder": [{ "value": data.firstAidCourseReminder }],
    "field_cpr_course_reminder": [{ "value": data.crpCourseReminder }],
    "field_d_o_b": [{ "value": data.profileDob }],
    "field_commencement_date": [{ "value": data.commencementDate }],
    "field_head_ceased_date": [{ "value": data.ceasedDate }],
    "field_first_aid_completion_date": [{ "value": data.firstAidCompletionDate }],
    "field_cpr_course_completion_date": [{ "value": data.cprCourseCompletionDate }],
    "field_blue_card": [{ "value": data.blueCard }],
    "field_addition_info": [{ "value": data.blueCardAdditionalInfo }],
    "field_signed_date": [{ "value": data.blueCardSignedDate }],
    "field_suburb_name": [{ "value": data.suburbName }],
    "field_blue_card_expiry_date": [{ "value": data.blueCardCompletionDate }],
    "field_blue_card_frequency": [{ "value": data.blueCardReminder }],
    "field_first_expiry_date": [{ "value": data.firstAidExpiry }],
    "field_cpr_course_expiry_date": [{ "value": data.cprExpiry }],
    "field_first_aid_certificate": [{ "target_id": data.field_certificate_document ? data.field_certificate_document : null }],
    "field_cpr_course_certificate": [{ "target_id": data.field_cpr_certificate ? data.field_cpr_certificate : null }],
    "field_basic_postcode": [{ "value": data.postCode }],
    "field_street_name": [{ "value": data.street }],
    "field_street_number_head": [{ "value": data.streetNumber }],
    "field_unit_number_head": [{ "value": data.unitNumber }],
    "field_business_role": [{ "value": data.businessRole }],
    "field_please_provide_details_bel":data.field_please_provide_details_bel

  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getCompletedTasks() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/complete/task`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function completeTask(taskId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }


  let bodyData = {
    "type": "task",
    "field_completed": [{ "value": data.status }],
    "field_incomplete_reason": [{ "value": data.reason }],
    "field_signature": [{ "target_id": data.signature }],
    "field_archived": [{ "value": data.archived }]
  }
  return axios.patch(`${baseUrl}/node/${taskId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getLinkParticipatedUsers(linkId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/booking/${linkId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function getTaskListingAsPerDate(date, uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/listing/task/${date}/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getTaskListingForHeadofficeAsPerDate(date, uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/listing/task/headoffice/${date}/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getVisitListingAsPerDate(date, uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/listing/visit/${date}/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getLinksListingAsPerDate(date, uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/api/links/listing/${date}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getVisitListingForHeadofficeAsPerDate(date, uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/listing/visits/headoffice/${date}/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function filterTasks(status) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userRole = window.localStorage.getItem('user_role');
  let dateRef = Date().toLocaleString()
  let userId = await window.localStorage.getItem('uid')
  let currentDate = moment(dateRef).format('YYYY-MM-DD')
  let url;

  if (status === "due") {
    url = userRole === "educator" ? `${baseUrl}/api/task/date?_format=json&field_assign_to_target_id=${userId}&duedate=${currentDate}` : `${baseUrl}/api/task/date?_format=json&duedate=${currentDate}&cuid=${userId}`
  } else if (status === "over") {
    url = userRole === "educator" ? `${baseUrl}/api/task/date?_format=json&overdue=${currentDate}&field_assign_to_target_id=${userId}` : `${baseUrl}/api/task/date?_format=json&overdue=${currentDate}&cuid=${userId}`
  } else if (status === "today") {
    url = userRole === "educator" ? `${baseUrl}/api/task/date?_format=json&current=${currentDate}&field_assign_to_target_id=${userId}` : `${baseUrl}/api/task/date?_format=json&current=${currentDate}&cuid=${userId}`
  } else {
    url = userRole === "educator" ? `${baseUrl}/contents/api?type=task&field_assign_to_target_id=${userId}&type=task&task_status=${status}` : `${baseUrl}/contents/api?_format=json&type=task&task_status=${status}&cuid=${userId}`
  }

  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function postEntityMedia(name, imageId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  let bodyData = {
    "bundle": [{ "target_id": "image" }],
    "name": [{ "value": name }],
    "field_media_image": [{
      "target_id": imageId
    }]
  }

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/media?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminders(uid, profile_type) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/api/reminder/course/?_format=json&type=${profile_type}&uid=${uid}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getRemindersGlobal(date) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  // let filteredField =  "field_business_expiry_date" ? "field_business_expiry_date" : "field_first_aid_expiry_date"  ? "field_first_aid_expiry_date": null
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/api/reminder/educator?_format=json&field_business_expiry_date=${date}&field_first_aid_expiry_date=${date}&field_registration_expiry_date=${date}&licence_expiry_date=${date}&cpr_expiry_date=${date}&asthma_expiry_date=${date}&field_anaphylaxis_expiry_date=${date}&field_insurance_expiry_date=${date}&field_blue_card_expiry_date=${date}&field_teacher_expiry_date=${date}&vehicle_expiry_date=${date}&vehicle_restraint_expiry=${date}&field_pool_safety_cert_exp_date_value=${date}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminderEduNewApi() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  // let filteredField =  "field_business_expiry_date" ? "field_business_expiry_date" : "field_first_aid_expiry_date"  ? "field_first_aid_expiry_date": null
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseappbackend.thecorella.com/api/reminder/educator-new?_format=json
  return axios.get(`${baseUrl}/api/reminder/educator-new?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getCaBTRemindersList() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  // let filteredField =  "field_business_expiry_date" ? "field_business_expiry_date" : "field_first_aid_expiry_date"  ? "field_first_aid_expiry_date": null
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

// https://wiseappbackend.thecorella.com/api/reminder/cabt-list?_format=json
  return axios.get(`${baseUrl}/api/reminder/cabt-list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getCaBTReminders(uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  // let filteredField =  "field_business_expiry_date" ? "field_business_expiry_date" : "field_first_aid_expiry_date"  ? "field_first_aid_expiry_date": null
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

// https://wiseappbackend.thecorella.com/api/reminder/cabt-list?_format=json
  return axios.get(`${baseUrl}/api/reminder/cabt-list?_format=json&uid=${uid}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminderEdu() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  // let filteredField =  "field_business_expiry_date" ? "field_business_expiry_date" : "field_first_aid_expiry_date"  ? "field_first_aid_expiry_date": null
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseappbackend.thecorella.com/api/reminder/educator-new?_format=json
  return axios.get(`${baseUrl}/api/reminder/educator?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
async function getNewNotifications() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let dateRef = Date().toLocaleString()

  return axios.get(`${baseUrl}/api/notification?_format=json&created_for=${userId}&date=${dateRef}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      if (error.response) {
        return ({ message: error.response.data.message })
      } else {
        return ({ message: "Something went wrong on Server" })
      }

    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
async function getNewNotification() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let dateRef = Date().toLocaleString()

  return axios.get(`${baseUrl}/api/notification?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      if (error.response) {
        return ({ message: error.response.data.message })
      } else {
        return ({ message: "Something went wrong on Server" })
      }

    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function readCurrentNotification(nid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/notification/update/${nid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getGlobalNewNotifications() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/get/notification/api/${userId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function readGlobalNotification(dataObject) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.post(`${baseUrl}/notification/api/links/notice?_format=json`, dataObject, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


function convertTimeStampToTime(timeStamp) {

  let timeFormat = ""

  if (timeStamp >= 3600) {
    let hrs = timeStamp / 3600
    hrs = Math.floor(hrs)
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12 // 12hr format

    let getMinutesTimeStamp = timeStamp - 3600 * Math.floor(timeStamp / 3600)
    let mins = getMinutesTimeStamp / 60
    mins = Math.floor(mins)
    mins = mins < 10 ? '0' + mins : mins


    let interval = Math.floor(timeStamp / 3600) > 12 ? "PM" : "AM"

    timeFormat = hrs + ":" + mins + " " + interval
  } else {
    let mins = timeStamp / 60
    mins = Math.floor(mins)
    mins = mins < 10 ? '0' + mins : mins

    timeFormat = "00:" + mins + " AM"
  }

  return timeFormat
}


function convertTimeStampToTimeCustom(timeStamp) {

  let timeFormat = ""

  if (timeStamp >= 3600) {
    let hrs = timeStamp / 3600
    hrs = Math.floor(hrs)
    hrs = hrs < 10 ? '0' + hrs : hrs

    let getMinutesTimeStamp = timeStamp - 3600 * Math.floor(timeStamp / 3600)
    let mins = getMinutesTimeStamp / 60
    mins = Math.floor(mins)
    mins = mins < 10 ? '0' + mins : mins
    let interval = Math.floor(timeStamp / 3600) > 12 ? "PM" : "AM"
    timeFormat = hrs + ":" + mins
  } else {
    let mins = timeStamp / 60
    mins = Math.floor(mins)
    mins = mins < 10 ? '0' + mins : mins

    timeFormat = "00:" + mins
  }

  return timeFormat
}

async function updateOccupants(occupantId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "type": [{ "target_id": "all_occupants", "target_type": "paragraphs_type" }],
    "field_occupant_blue_card_number": [{ "value": data.occupantBlueCardNumber }],
    "field_occupant_name": [{ "value": data.occupantName }],
    "field_occupant_blue_card": [{ "value": data.occupantBlueCard }],
    "field_occupant_date_of_birth": [{ "value": data.occupantDateOfBirth }],
    "field_occupant_card_expiry_date": [{ "value": data.occupantCardExpiryDate }],
    "field_occupant_date_signed": [{ "value": data.occupantDateSigned }]
  }

  return axios.patch(`${baseUrl}/entity/paragraph/${occupantId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

//Delete and Edit API Calls
async function deleteNode(nodeId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.delete(`${baseUrl}/node/${nodeId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {

        return ({ status: response.status, data: response })
      } else {

        return ({ message: response.message })
      }
    })
    .catch(function (error) {

      return ({ message: error.response.data.message })
    });
}

// Update Node Details
async function updateNode(nodeId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }


  let bodyData = {
    "type": [{ "target_id": "task" }],
    "title": [{ "value": data.title }],
    "field_assign_to": [{ "target_id": data.educator_id ? data.educator_id : null }],
    "field_links": [{ "uri": data.link }],
    "field_embed_video": [{ "value": data.video }],
    "field_task_notes": [{ "value": data.notes }],
    "field_signature": [{ "target_id": data.signature }],
    "field_date": [{ "value": data.date }],
    "field_time": [{ "value": data.time }],
    "field_status": [{ "value": data.status.value }]
  }

  if (data.imageFid !== undefined && data.imageFid !== null) {
    Object.assign(bodyData, { "field_image": [{ "target_id": data.imageFid }] })
  }

  if (data.educator_id === "") {
    Object.assign(bodyData, { "field_all": [{ "value": true }] })
  }

  return axios.patch(`${baseUrl}/node/${nodeId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {

      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function deleteRegisteredUser(uid, authToken) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.delete(`${baseUrl}/user/${uid}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      // 
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function updateLinkDetails(linkId, bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.patch(`${baseUrl}/node/${linkId}?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function updateNoticeBoardDetails(noticeId, data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "type": [{ "target_id": "notice_board" }],
    "body": [{ "value": data.notes }],
    "title": [{ "value": data.title }],
    "field_date": [{ "value": data.date }],
    "field_archived_date": [{ "value": data.archiveDate }],
    "field_url": data.link,
    "field_educator": [{ "target_id": data.educator_id ? data.educator_id : null }],
    "field_img": data.fid
  }


  if (data.updatedDate !== undefined && data.updatedDate !== null) {
    Object.assign(bodyData, { "field_last_updated_on": [{ "value": data.updatedDate }] })
  }

  if (data.archive !== undefined && data.archive !== null) {
    Object.assign(bodyData, { "field_archived_notices": [{ "value": true }] })
  }

  return axios.patch(`${baseUrl}/node/${noticeId}?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

// Forgot Password
async function forgotPassword(email) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "mail": email,
  }

  return axios.post(`${baseUrl}/user/lost-password?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


// Forgot Password Reset
async function forgotPasswordReset(data) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "name": data.name,
    "temp_pass": data.temp_pass,
    "new_pass": data.new_pass
  }

  return axios.post(`${baseUrl}/user/lost-password-reset?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


// Sorting for Educators
async function sortEducators(sort, value) {

  let csrfToken = await getXcsrfToken()
  let authToken = window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let url = `${baseUrl}/api/educator/list`
  if (sort == 'archive') {
    url = `${url}?archive=1&status=0`
  } else {
    url = `${url}?archive_all=0&sort_order=${sort}`
  }
  if (value !== '' && value !== undefined) {
    url = `${url}&field_first_name_value=${value}`
  }
  // return axios.get(`${baseUrl}/api/educator/list?_format=json&sort_order=${sort}`, {

  return axios.get(url, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}
// Quality Improvment


async function addQualityImprovmentPlan(bodyData) {

  // 
  // console.log();
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {

    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status == 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ status: response.status, message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function addEngagementLogs(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {

    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.post(`${baseUrl}/api/record/engagement/log?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status == 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ status: response.status, message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function editEngagementLogs(bodyData, id) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {

    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
// /api/engagement/log/{id}
  return axios.patch(`${baseUrl}/api/engagement/log/${id}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status == 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ status: response.status, message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getQualitymprovmentData() {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = window.localStorage.getItem('educator_uid')

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/api/${userId}/quality-improvement?_format=json`, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getEngagementLogsData() {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = window.localStorage.getItem('educator_uid')

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  // https://wiseappbackend.thecorella.com/api/engagement/log/2996?_format=json
  return axios.get(`${baseUrl}/api/engagement/log/${userId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

// Search Educators
async function searchEducators(inputValue) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/api/educator/list?_format=json&field_first_name_value=${inputValue}`, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}
// search Notice
async function searchNotice(inputValue, val) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/notices/list?format=json&title=${inputValue}&field_archived_notices_value=${val}`, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function addOccupants(bodyData, authToken) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function addBusinessDays(bodyData, authToken) {

  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function cancelBooking(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/booking/cancel?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}



async function updateUserImage(profileId, data) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "type": "head_office_team",
    "field_profile_picture": [{ "target_id": data.image }],
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function addMeetingNotes(bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getTaskLogs(taskId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/get/task/log/${taskId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function uploadVideo(fileName, imageData) {

  if (fileName !== '' && imageData !== '') {
    let authToken = window.localStorage.getItem('auth_token')
    let csrfToken = await getXcsrfToken()

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/octet-stream"
    }

    let binaryImagedata = convertDataURIToBinary(imageData)

    return axios.post(`${baseUrl}/file/upload/paragraph/meeting_notes/field_meeting_video?_format=json`, binaryImagedata, {
      headers: headers,
      credentials: 'include'
    })
      .then(async (response) => {
        if (response.status === 201) {
          return ({ status: response.status, data: response })
        } else {
          return ({ message: response.message })
        }
      })
      .catch(function (error) {
        return ({ message: error.response.data.message })
      });
  }
}

async function archiveTask(taskId, uid, taskStatus) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "type": "task",
    "field_archived": [{ "value": taskStatus }],
    "field_archived_by": [{ "value": uid }]
  }

  return axios.patch(`${baseUrl}/node/${taskId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}



async function getArchivedTasks(uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let role = window.localStorage.getItem('user_role');

  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let filtered = ''
  if (role === 'educator') {
    filtered = 'field_assign_to_target_id'
  } else {
    filtered = 'authored_by'
  }
  return axios.get(`${baseUrl}/contents/api?_format=json&${filtered}=${uid}&type=task&archived=1`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function updateMeetingNotes(visitId, bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.patch(`${baseUrl}/node/${visitId}?_format=json`, bodyData, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getAllMeetingNotes(nid) {

  let authToken = await window.localStorage.getItem('auth_token')

  const headers = {
    'Authorization': 'Bearer ' + authToken
  }

  return axios.get(`${baseUrl}/api/meeting/notes?_format=json&nid=${nid}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getAllContentCustom(contentType) {

  let dateRef = Math.random();

  return axios.get(`${baseUrl}/contents/api?type=${contentType}&archived=0&t=${dateRef}`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}


async function appliedLeaves(data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "start_date": [{ "value": data.startDate }],
    "end_date": [{ "value": data.endDate }],
    "user_id": [{ "value": data.userId }]
  }

  return axios.post(`${baseUrl}/api/leaves`, bodyData, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function updateLeaves(data, id) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let bodyData = {
    "start_date": [{ "value": data.startDate }],
    "enddate": [{ "value": data.endDate }],
    "user_id": [{ "value": data.userId }]
  }

  return axios.patch(`${baseUrl}/api/leaves/${id}`, bodyData, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}
async function updateLeavesCordinator(data, id) {
  try {
    let authToken = window.localStorage.getItem('auth_token')
    let csrfToken = await getXcsrfToken()

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }
    let bodyData = {
      "start_date": [{ "value": data.startDate }],
      "first_name": [{ "value": data.firstName }],
      "last_name": [{ "value": data.lastName }],
      "id": [{ "value": data.Id }],
      "enddate": [{ "value": data.endDate }],
      "user_id": [{ "value": data.userId }]
    }


    const response = await axios.patch(`${baseUrl}/api/leaves/${id}`, bodyData, {
      headers: headers
    });

    if (response.status === 200) {
      return { status: response.status, data: response.data };
    } else {
      return { status: response.status, message: response.data.message };
    }
  } catch (error) {
    return { message: error.response.data.message };
  }
}

async function deleteLeaves(id) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }


  return axios.delete(`${baseUrl}/api/leaves/${id}`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function deleteLeavesCordinator(id) {
  try {
    let authToken = window.localStorage.getItem('auth_token');
    let csrfToken = await getXcsrfToken();

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }

    const response = await axios.delete(`${baseUrl}/api/leaves/${id}`, {
      headers: headers  // Add headers to the request
    });

    if (response.status === 200) {
      return { status: response.status, data: response.data };
    } else {
      return { status: response.status, message: response.data.message };
    }
  } catch (error) {
    return { message: error.response.data.message };
  }
}


async function getLeaves(userId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  // return axios.get(`${baseUrl}/get/leaves/api?_format=json&uid=${userId}&start_time=${current_date}`, {
  return axios.get(`${baseUrl}/get/leaves/api?_format=json&uid=${userId}`, {

  }).then(async (response) => {
    if (response.status === 200) {

      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  });

}

async function getLeavesofCordinator(userId) {
  try {
    let authToken = window.localStorage.getItem('auth_token');
    let csrfToken = await getXcsrfToken();

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }

    const response = await axios.get(`${baseUrl}/get/leaves/api?_format=json&uid=${userId}`, {
      headers: headers
    });

    if (response.status === 200) {
      return { status: response.status, data: response.data };
    } else {
      return { status: response.status, message: response.data.message };
    }
  } catch (error) {
    return { message: error.response.data.message };
  }
}


async function getLeavesOfEducator() {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/get/leaves/api?_format=json&start_time=${current_date}`, {

  }).then(async (response) => {
    if (response.status === 200) {

      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  });

}


async function archiveData(userId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/get/leaves/api?_format=json&end_time=${archiveDate}`, {

  }).then(async (response) => {
    if (response.status === 200) {

      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  });

}


async function archiveDataOfEducator(userId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/get/leaves/api?_format=json&end_time=${archiveDate}`, {

  }).then(async (response) => {
    if (response.status === 200) {

      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  });

}


async function getCalendarTasksDataGlobally(date) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  return axios.get(`${baseUrl}/contents/api?_format=json&type=task&field_date=${date}`, {
    headers: headers
  }).then(async (response) => {
    if (response.status === 200) {
      // 
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}


async function getCalendarVisitsDataGlobally(date) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/contents/api?_format=json&type=visits&field_date=${date}`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });

}

async function addRiskAssessmentLogs(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }


  return axios.post(`${baseUrl}/api/risk/assessment/log?_format=json`, bodyData, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getRiskAssessmentLogs(uid, sort) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let dateRef = Date().toLocaleString()

  return axios.get(`${baseUrl}/api/get/${uid}/risk/assessment/log?_format=json&date=${dateRef}&category=${sort}`, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function updateRiskAssessmentLogs(selectedRiskAssessmentId, bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.patch(`${baseUrl}/api/assessment/log/${selectedRiskAssessmentId}?_format=json`, bodyData, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function deleteRiskAssessmentLogs(selectedRiskAssessmentId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }


  // Step 1: Remove commas from the string
  const cleanedString = selectedRiskAssessmentId.replace(/,/g, '');
  
  // Step 2: Convert the cleaned string to a number
  const numberValue = parseFloat(cleanedString);
  
  return axios.delete(`${baseUrl}/api/assessment/log/${numberValue}?_format=json`, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function deleteOccupant(occupantId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.delete(`${baseUrl}/entity/paragraph/${occupantId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function unfavouriteFolder(folderId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.delete(`${baseUrl}/entity/flagging/${folderId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function userBookings(userId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/links/booked/${userId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function newNoticeList(value) {
  // 
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }


  let url = `${baseUrl}/notices/list?format=json&field_archived_notices_value=0&created_on=${value}`
  if (value === "all" || value === "archive") {
    url = `${baseUrl}/notices/list?_format=json&field_archived_notices_value=${value === "all" ? 0 : 1}`
  }

  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function taskTitleList() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/term/list/task_taxonomy?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function createContent(bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.post(`${baseUrl}/taxonomy/term?_format=json`, bodyData, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

//delete session list or facilitator list

async function deleteFacilitorList(id) {


  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.delete(`${baseUrl}/taxonomy/term/${id}?_format=json`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

//upload venue image
async function venueFilesUpload(fileName, filepath) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/octet-stream"
  }

  let binaryImagedata;
  // 
  if (filepath.includes("https")) {
    binaryImagedata = filepath
    // 
    return ({ status: 'ok' })
  } else {
    binaryImagedata = convertDataURIToBinary(filepath)
    // 
    return axios.post(`${baseUrl}/file/upload/node/links/field_risk_assessment_file?_format=json`, binaryImagedata, {
      headers: headers,
      credentials: 'include'
    })
      .then(async (response) => {
        if (response.status === 201) {
          return ({ status: response.status, data: response })
        } else {
          return ({ message: response.message })
        }
      }).catch(function (error) {
        return ({ message: error.response.data.message })
      });
  }
  // 
  // https://wiseapp.thecorella.com//sites/default/files/2022-01/2_30.jpg

}

//
//upload risk assesment
async function riskAssesment(fileName, filepath) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let binaryImagedata = convertDataURIToBinary(filepath);
  // 
  // alert({fileName});
  const headers = {
    'Authorization': 'Bearer ' + authToken,
    "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/octet-stream"
  }

  return axios.post(`${baseUrl}/file/upload/node/links/field_risk_assessment_file?_format=json`, binaryImagedata, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function addNewVenue(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

//single records of venue list
async function getVenueList(id) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/venue/${id}/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function editNewVenue(venueId, bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/entity/paragraph/${venueId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getLinksContent(param) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/term/list/${param}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getAllVenue() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/paragraph/venue?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

// delete venue
async function deleteVenue(id) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.delete(`${baseUrl}/entity/paragraph/${id}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 204) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getSpefificVenue(targetId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/entity/paragraph/${targetId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

// Search Educators
async function searchEducatorsBySuburb(inputValue) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/educator/list?_format=json&suburb_name=${inputValue}`, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getGlobalAssignedTask(status) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    // 'Authorization': 'Bearer ' + authToken,
    // 'X-CSRF-Token': csrfToken
  }

  let url = status === 0 ? `${baseUrl}/api/global/tasks/incomplete?_format=json` :
    status === 1 ? `${baseUrl}/api/global/tasks?_format=json&complete=1` :
      status === 3 ? `${baseUrl}/api/global/tasks?_format=json&archived=1` :
        `${baseUrl}/api/global/tasks?_format=json`

  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function markGlobalTaskComplete(taskId, data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "flag_id": [{ "target_id": "complete_flag" }],
    "entity_type": [{ "value": "node" }],
    "entity_id": [{ "value": taskId }],
    "uid": [{ "target_id": userId }],
    "field_signature": data.signature,
    "field_complete_task": [{ "value": data.taskCompleted }],
  }

  return axios.get(`${baseUrl}/api/task/complete/flag?_format=json&nid=${taskId}&uid=${userId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          let id = response.data[0].id
          return axios.patch(`${baseUrl}/entity/flagging/${id}?_format=json`, bodyData, {
            headers: headers,
            credentials: 'include'
          })
            .then(async (response) => {
              let updateBodyData = {
                "id": [{ "value": taskId }],
                "user_id": [{ "value": userId }]
              }
              if (response.status === 200) {
                return axios.post(`${baseUrl}/api/task/status/update?_format=json`, updateBodyData, {
                  headers: headers,
                  credentials: 'include'
                })
                  .then(async (response) => {
                    if (response.status === 200) {
                      return ({ status: response.status })
                    }
                  })
                  .catch(function (error) {
                    return ({ message: error.response.data.message })
                  });
              }
            })
            .catch(function (error) {
              return ({ message: error.response.data.message })
            });
        }
        else {
          return axios.post(`${baseUrl}/entity/flagging?_format=json`, bodyData, {
            headers: headers,
            credentials: 'include'
          })
            .then(async (response) => {
              let updateBodyData = {
                "id": [{ "value": taskId }],
                "user_id": [{ "value": userId }]
              }
              if (response.status === 201) {
                return axios.post(`${baseUrl}/api/task/status/update?_format=json`, updateBodyData, {
                  headers: headers,
                  credentials: 'include'
                })
                  .then(async (response) => {
                    if (response.status === 200) {
                      return ({ status: response.status })
                    }
                  })
                  .catch(function (error) {
                    return ({ message: error.response.data.message })
                  });
              }
            })
            .catch(function (error) {
              return ({ message: error.response.data.message })
            });
        }
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function markGlobalTaskUnComplete(taskId, data) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "flag_id": [{ "target_id": "complete_flag" }],
    "field_signature": data.signature,
    "field_complete_task": [{ "value": data.taskCompleted }],
    "field_reason": [{ "value": data.reason }]
  }


  return axios.get(`${baseUrl}/api/task/complete/flag?_format=json&nid=${taskId}&uid=${userId}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        let id = response.data[0].id
        return axios.patch(`${baseUrl}/entity/flagging/${id}?_format=json`, bodyData, {
          headers: headers,
          credentials: 'include'
        })
          .then(async (response1) => {
            let updateBodyData = {
              "id": [{ "value": taskId }],
              "user_id": [{ "value": userId }]
            }
            if (response1.status === 200) {
              return axios.post(`${baseUrl}/api/task/status/update?_format=json`, updateBodyData, {
                headers: headers,
                credentials: 'include'
              })
                .then(async (response2) => {
                  if (response2.status === 200) {
                    return ({ status: response2.status })
                  }
                })
                .catch(function (error) {
                  return ({ message: error.response2.data.message })
                });
            }
          })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getParticularTaskPreviousSignatures(taskId) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')
  let userRole = window.localStorage.getItem('user_role');

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let url = userRole === "educator" ? `${baseUrl}/api/task/gobal/signature?_format=json&nid=${taskId}&uid=${userId}` : `${baseUrl}/api/task/gobal/signature?_format=json&nid=${taskId}`
  // 
  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getGlobalAssignedVisits() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let userId = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/global/visits?_format=json`, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getAllTaskFlags() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0
  }

  return axios.get(`${baseUrl}/api/global/tasks/status?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function addProfessionalDevelop(bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.post(`${baseUrl}/entity/paragraph?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getCabtProfessionalDevelops(uid) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  // let uid = await window.localStorage.getItem('educator_uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/${uid}/professional-development/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getProfessionalDevelops() {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  let uid = await window.localStorage.getItem('educator_uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/${uid}/professional-development/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}
async function getProfessional(uid) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  // let uid = await window.localStorage.getItem('uid')

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/${uid}/professional/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function getRiskAssessmentLogSort(category, uid) {
  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  let dateRef = Date().toLocaleString()

  return axios.get(`${baseUrl}/api/get/${uid}/risk/assessment/log?_format=json&date=${dateRef}&category=${category}`, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function deleteProfessioanlDevelopLogs(parameterId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  // https://wiseapp.thecorella.com/entity/paragraph/{paragraph_id}?_format=json
  return axios.delete(`${baseUrl}/entity/paragraph/${parameterId}?_format=json`, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function deleteEngagementRecordLogs(parameterId) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  // https://wiseapp.thecorella.com/entity/paragraph/{paragraph_id}?_format=json
  return axios.delete(`${baseUrl}/api/engagement/log/${parameterId}?_format=json`, {}).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function uploadDocument(fileName, filepath, type) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  let binaryImagedata = await convertDataURIToBinary(filepath)

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/octet-stream"
  }

  let url = type === "venue" ? `${baseUrl}/file/upload/paragraph/venue/field_covid_19_safe_plan?_format=json` :
    `${baseUrl}/file/upload/node/links/field_document?_format=json`

  return axios.post(url, binaryImagedata, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    }).catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function updateProfessionalProfile(bodyData, profileId) {

  // 
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {

    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
//notification api
async function getAllNotificationList(id) {
  //alert(id);
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // console.log(headers);
  // 22 April
  return axios.get(`${baseUrl}/api/view/notification/${id}?_format=json`, {
    headers: headers,
    credentials: 'include'
  }).then((response) => {
    // console.log('response');
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  }).catch(function (error) {
    // window.localStorage.clear();
    // window.location = `${window.location.origin}?error=token-expired`;
    return ({ message: error.response.data.message })
  });
}

//read notification api
async function readNotification(obj) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.post(`${baseUrl}/entity/flagging?_format=json`, obj, {
    headers: headers,
  })

    .then(async (response) => {

      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {

      return ({ message: error.response.data.message })
    });
}

// list of session
async function getSessionList() {


  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/api/facilitator/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

// list of session with id
async function getSessionId(id) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.get(`${baseUrl}/api/facilitator/list/${id}?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {
        //HERE ----><----- HERE

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

//edit session or facilitators
async function editSession(tid, bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.patch(`${baseUrl}/taxonomy/term/${tid}?_format=json`, bodyData, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

//co-ordinator list for M&S

async function CoordinatorList() {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseapp.thecorella.com/api/coordinator/list?_format=json
  return axios.get(`${baseUrl}/api/coordinator/list?_format=json`, {
    headers: headers,
    credentials: 'include'
  })

    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function educatorListArchive(profileId, data, value) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  let bodyData;

  if (value === "updateOccupant") {
    bodyData = { "type": "educator", 'field_all_occupants': data.field_all_occupants }
  } else {

    bodyData = {
      "type": "educator",
      "uid": [{ "target_id": data.uid }],
      "field_archive": [{ "value": data.field_archive }]
    }
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers
    // credentials: 'include'
  })
    .then((response) => {
      if (response) {

        return ({ status: response.status, data: response })
      } else {

        return ({ message: response.message })
      }
    })
    .catch(function (error) {

      return ({ message: error.response.data.message })
    });

}

async function changeEducatorStatus(uid, status) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  let bodyData = {
    "status": [{ "value": `${status}` }]
  }
  // https://wiseappbackend.thecorella.com/user/2996(user id)?_format=json
  return axios.patch(`${baseUrl}/user/${uid}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response })
  });
}

async function archiveNotice(noticeId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }


  let bodyData = {
    "type": [{ "target_id": "notice_board" }],
    "field_archived_notices": [{ "value": true }]
  }



  return axios.patch(`${baseUrl}/node/${noticeId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response })
    });
}


// get Access to Private Folder 

async function getSubFolderBuID(uid) {

  axios.get(`${baseUrl}/api/get/user-folders/${uid}?_format=json`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  })
}

async function getPrivateFolders(uid) {
  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,   // https://wiseapp.thecorella.com
    'X-CSRF-Token': csrfToken
  }

  let url = `${baseUrl}/api/get/user-folders?_format=json&uid=${uid}`

  return axios.get(url, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function automaticFolderGeneratorAPI(userId) {
  const headers = {
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/view/folder/${userId}`

  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}



async function getFolderByParentIdAPI(parentID) {
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/api/get/user-folders?_format=json&id=${parentID}`

  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}


async function getListOfParentFolder(userId) {
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/api/get/get-main-folders/${userId}?_format=json`
  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getListOfParentFolderNew(userId) {
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/api/get-user-folders?uid=${userId}`
  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getListOfSubFolder(parentID) {
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/api/get/user-folders/${parentID}?_format=json`

  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      //
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}

async function getFolderAllAPI() {
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json'
  }
  let url = `${baseUrl}/api/get/user-folders?_format=json`;

  return axios.get(url, {
    headers: headers

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error })
  });
}




async function uploadMultiFilesInFolder(fileName, fileData) {

  if (fileName !== '' && fileData !== '') {
    let authToken = window.localStorage.getItem('auth_token')
    let csrfToken = await getXcsrfToken()

    const headers = {
      'Authorization': 'Bearer ' + authToken,
      "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/octet-stream"
    }

    let binaryFiledata = convertDataURIToBinary(fileData)

    return axios.post(`${baseUrl}/file/upload/media/document_file/field_media_file_1?_format=json`, binaryFiledata, {
      headers: headers,
      credentials: 'include'
    })
      .then(async (response) => {
        if (response.status === 201) {

          return response.data.fid[0].value;

          //     let objData = {
          //         "bundle": [{
          //             "target_id": "document_file"

          //         }],
          //         "name": [{
          //             "value": fileName
          //         }],

          //         "field_media_file_1": [{
          //             "target_id": response.data.fid[0].value
          //         }],
          //         "field_folder": [{
          //             "target_id": taxonomyId

          //         }]
          //     }

          //     const mediaHeaders = {
          //         'Authorization': 'Bearer ' + authToken,
          //         "X-CSRF-Token": csrfToken,
          //         "Content-Type": "application/json"
          //     }
          //     return axios.post(`${baseUrl}/entity/media?_format=json`, objData, {
          //             headers: mediaHeaders
          //         })
          //         .then(async(res) => {
          //             if (res.status === 201) {
          //                 return ({ status: res.status, data: res })
          //             }
          //         })
          //         .catch(function(err) {
          //             return ({ message: err.res.data.message })
          //         })
        }
      })
    // .catch(function(error) {
    //     return ({ message: error.response.data.message })
    // })
  }
}

async function updateTargetIdsOfImage(fileName, targetIds, taxonomyId) {
  // console.log('target_id', targetIds);
  // console.log('taxonomyId', taxonomyId);

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const mediaHeaders = {
    'Authorization': 'Bearer ' + authToken,
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/json"
  }

  let objData = {
    "bundle": [{
      "target_id": "document_file"

    }],
    "name": [{
      "value": fileName
    }],

    "field_media_file_1": targetIds,
    "field_folder": [{
      "target_id": taxonomyId

    }]
  }
  return axios.post(`${baseUrl}/entity/media?_format=json`, objData, {
    headers: mediaHeaders
  })
    .then(async (res) => {
      if (res.status === 201) {
        return ({ status: res.status, data: res })
      }
    })
    .catch(function (err) {
      return ({ message: err.res.data.message })
    })
}



async function getFilesListingByID(taxonomyId) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }

  return axios.get(`${baseUrl}/media/${taxonomyId}/edit?_format=json`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function updateProfessionalDevelopmentLog(profileId, bodyData) {


  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/entity/paragraph/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      console.log('data', error);
      // return ({ message: error.response.data.message })
    });

}

async function updateQualityImprovement(profileId, bodyData) {


  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/entity/paragraph/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {

      return ({ message: error.response.data.message })
    });

}

//  http://wiseappp.thecorella.com/notification/update/2722?total_count (Notification count)

async function getNotificationCountAPI(uid) {

  return axios.get(`${baseUrl}/notification/update/${uid}?total_count`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}


// http://wiseappp.thecorella.com/notification/update/2722?update_view
// Reset Notification
async function resetNotificationCountAPI(uid) {

  return axios.get(`${baseUrl}/notification/update/${uid}?update_view`, {

  }).then(async (response) => {
    if (response.status === 200) {
      return ({ status: response.status, data: response })
    } else {
      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {
    return ({ message: error.response.data.message })
  });
}


// TaskAction Complete
async function taskActionComplete(taskId) {
  let csrfToken = window.localStorage.getItem('login_csrf_token')
  let authToken = window.localStorage.getItem('auth_token')

  let bodyData = {
    "type": [{ "target_id": "task" }],
    "field_completed": [{ "value": 1 }]
  }

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }
  return axios.patch(`${baseUrl}/node/${taskId}?_format=json`, bodyData, {
    headers: headers
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}


async function UpdatetheOccupants(Id, bodyData) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/entity/paragraph/${Id}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });
}

async function upDateOccupants(profileId, data) {


  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, data, {
    headers: headers,
    credentials: 'include'

  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response, data: response })
      } else {
        return ({ message: response })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function uploadCertificatesAPI(fileName, filepath, type) {

  let authToken = window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Authorization': 'Bearer ' + authToken,
    "Content-Disposition": 'file;filename="' + encodeURIComponent(fileName.replaceAll(' ', '-')) + '"',
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/octet-stream"
  }
  // console.log('filepath', filepath);
  let binaryImagedata = await convertDataURIToBinary(filepath)
  // console.log('binaryImagedata', binaryImagedata);

  // let url = type === "first_aid" ? `${baseUrl}/file/upload/profile/educator/field_certificate_document?_format=json` : 
  //            type === "cpr_couse" ? `${baseUrl}/file/upload/profile/educator/field_cpr_certificate?_format=json` :
  //            type === "asthma_course" ? `${baseUrl}/file/upload/profile/educator/field_asthma_course_certificate?_format=json` : 
  //            type === "anaphylaxis_course" ?`${baseUrl}/file/upload/profile/educator/field_anaphylaxis_certificate?_format=json` : null;

  //   

  return axios.post(`${baseUrl}/file/upload/media/image/field_media_image?_format=json`, binaryImagedata, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 201) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    }).catch(function (error) {
      console.log(error);
      return ({ message: error.response.data.message })
    });
}


async function updateEducatorSideDataAPI(profileId, bodyData) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.patch(`${baseUrl}/profile/${profileId}?_format=json`, bodyData, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {

        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getGlobalTasksList() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/global/tasks?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminderOfChildOccupants(uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/reminder?_format=json&uid=${uid}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminderOfAdultOccupants(date) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/reminder/adult?_format=json&field_occupant_card_expiry_date=${date}&field_teacher_expiry_date=${date}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


async function getReminderOfAdultEdu() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }

  return axios.get(`${baseUrl}/api/reminder/adult?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}
async function getReminderOfAdultEduNew() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseappbackend.thecorella.com/api/reminder/adult-new?_format=json
  return axios.get(`${baseUrl}/api/reminder/adult-new?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getReminderOfAdultCabt() {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseappbackend.thecorella.com/api/reminder/adult-cabt?_format=json
  return axios.get(`${baseUrl}/api/reminder/adult-cabt?_format=json`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getParticularReminderOfAdultCabt(uid) {

  let authToken = await window.localStorage.getItem('auth_token')
  let csrfToken = await getXcsrfToken()

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken
  }
  // https://wiseappbackend.thecorella.com/api/reminder/adult-cabt?_format=json
  return axios.get(`${baseUrl}/api/reminder/adult-cabt?_format=json&uid=${uid}`, {
    headers: headers,
    credentials: 'include'
  })
    .then(async (response) => {
      if (response.status === 200) {
        return ({ status: response.status, data: response })
      } else {
        return ({ message: response.message })
      }
    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function getGlobalTask() {
  var config = {
    method: 'get',
    url: 'http://wiseappp.thecorella.com/contents/api?_format=json&field_all_value=1&type=task',
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0,
    }
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}


async function getCurrentLeaves(uid) {

  let authToken = window.localStorage.getItem('auth_token')
  let dateRef = Date().toLocaleString()

  let csrfToken = await getXcsrfToken()


  const headers = {
    'Authorization': 'Bearer ' + authToken,
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json'
  }

  return axios.get(`${baseUrl}/get/leaves/api?_format=json&date=${dateRef}`, {

  }).then(async (response) => {
    if (response.status === 200) {

      return ({ status: response.status, data: response })
    } else {

      return ({ status: response.status, message: response.message })
    }
  }).catch(function (error) {

    return ({ message: error.response.data.message })
  });

}

async function deleteSpecificFolderFile(fid) {

  return axios.get(`${baseUrl}/view/delete-folder/${fid}`, {

  })
    .then(async (response) => {
      return ({ status: response.status, data: response })

    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}

async function updateSpecificFolderFile(fid, name) {
  //   console.log("fid",fid,name);

  return axios.get(`${baseUrl}/view/update-file/${fid}?name=${name}`, {

  })
    .then(async (response) => {
      return ({ status: response.status, data: response })

    })
    .catch(function (error) {
      return ({ message: error.response.data.message })
    });

}


export {
  getGlobalTask,
  getCurrentLeaves,
  getReminderOfChildOccupants,
  getGlobalTasksList,
  updateEducatorSideDataAPI,
  uploadCertificatesAPI,
  getFilesListingByID,
  updateTargetIdsOfImage,
  getFolderAllAPI,
  getParticularUserContent,
  getParticularUserContentCustom,
  riskAssesmentFile,
  createLink,
  createNoticeBoard,
  createTask,
  createVisit,
  getAllEducators,
  getCoordinators,
  getCaBTList,
  getXcsrfToken,
  getAllContent,
  getSpecificUser,
  bookEvent,
  getSpecificMedia,
  convertTimeStampToTime,
  uploadImage,
  getSpecificTaxonomy,
  logoutUser,
  getUserProfileData,
  getAllFolders,
  getOccupant,
  createNewFolder,
  getFolderFiles,
  uploadAllDocs,
  updateUserProfile,
  addFavouriteFolder,
  updateFolder,
  updateCabtProfile,
  updateHeadOfficeProfile,
  getCompletedTasks,
  completeTask,
  deleteSpecificFolder,
  deleteSpecificFile,
  getLinkParticipatedUsers,
  getTaskListingAsPerDate,
  postEntityMedia,
  filterTasks,
  getReminders,
  getVisitListingAsPerDate,
  convertTimeStampToTimeCustom,
  getNewNotifications,
  readCurrentNotification,
  updateOccupants,
  getTaskListingForHeadofficeAsPerDate,
  getVisitListingForHeadofficeAsPerDate,
  getGlobalNewNotifications,
  readGlobalNotification,
  deleteNode,
  updateNode,
  getUpcomingVisits,
  getUpcomingVisitsNew,
  getCompletedVisitsNew,
  getUpcomingVisiter,
  deleteRegisteredUser,
  updateLinkDetails,
  updateNoticeBoardDetails,
  updateEducatorNotesVisit,
  updateVisitDetails,
  forgotPassword,
  forgotPasswordReset,
  addOccupants,
  searchEducators,
  sortEducators,
  getTaskLogs,
  cancelBooking,
  updateUserImage,
  addMeetingNotes,
  uploadVideo,
  archiveTask,
  getArchivedTasks,
  updateMeetingNotes,
  getAllMeetingNotes,
  getAllContentCustom,
  appliedLeaves,
  getLeaves,
  getLeavesofCordinator,
  getCalendarTasksDataGlobally,
  getCalendarVisitsDataGlobally,
  addRiskAssessmentLogs,
  getRiskAssessmentLogs,
  deleteRiskAssessmentLogs,
  updateRiskAssessmentLogs,
  deleteOccupant,
  unfavouriteFolder,
  userBookings,
  newNoticeList,
  taskTitleList,
  createContent,
  venueFilesUpload,
  addNewVenue,
  getLinksContent,
  getAllVenue,
  getSpefificVenue,
  searchEducatorsBySuburb,
  getGlobalAssignedTask,
  markGlobalTaskComplete,
  markGlobalTaskUnComplete,
  getParticularTaskPreviousSignatures,
  getGlobalAssignedVisits,
  getAllTaskFlags,
  addBusinessDays,
  searchNotice,
  getProfessionalDevelops,
  getCabtProfessionalDevelops,
  getProfessional,
  addProfessionalDevelop,
  deleteProfessioanlDevelopLogs,
  uploadDocument,
  addQualityImprovmentPlan,
  getQualitymprovmentData,
  getEngagementLogsData,
  addEngagementLogs,
  editEngagementLogs,
  deleteEngagementRecordLogs,
  updateQualityImprovement,
  updateProfessionalProfile,
  getAllNotificationList,
  readNotification,
  editNewVenue,
  getSessionList,
  deleteVenue,
  deleteFacilitorList,
  getVenueList,
  editSession,
  getSessionId,
  riskAssesment,
  CoordinatorList,
  archiveData,
  getLeavesOfEducator,
  archiveDataOfEducator,
  getRiskAssessmentLogSort,
  updateUserProfileCeasedDate,
  getArchiveEducators,
  educatorListArchive,
  changeEducatorStatus,
  archiveNotice,
  getUpcomingMandS,
  getPrivateFolders,
  automaticFolderGeneratorAPI,
  getFolderByParentIdAPI,
  getListOfParentFolder,
  getListOfParentFolderNew,
  uploadMultiFilesInFolder,
  updateProfessionalDevelopmentLog,
  getNotificationCountAPI,
  resetNotificationCountAPI,
  taskActionComplete,
  UpdatetheOccupants,
  upDateOccupants,
  getLinksListingAsPerDate,
  updateLeaves,
  updateLeavesCordinator,
  deleteLeaves,
  deleteLeavesCordinator,
  getSubFolderBuID,
  getListOfSubFolder,
  deleteSpecificFolderFile,
  updateUserData,
  getEmergencyConatcts,
  addEmergencyContacts,
  updateUserResetData,
  updateAsthmaResetData,
  updateSpecificFolderFile,
  getRemindersGlobal,
  getReminderOfAdultOccupants,
  getReminderEdu,
  getReminderEduNewApi,
  getCaBTRemindersList,
  getCaBTReminders,
  getReminderOfAdultEdu,
  getReminderOfAdultEduNew,
  getReminderOfAdultCabt,
  getParticularReminderOfAdultCabt,
  getNewNotification,
  getTaskAssign
}