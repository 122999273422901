import React, {useEffect, useState} from 'react';
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import './styles.css';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { getAllEducators, getSpecificUser, getLeaves, convertTimeStampToTimeCustom, taskTitleList, createContent,editSession,getSessionId , getSessionList, venueFilesUpload } from '../../../functions/api';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { Link, useHistory, useParams } from 'react-router-dom';
import { createTask, updateNode } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImage } from '../../../functions/api';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import imageToBase64 from 'image-to-base64/browser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const AddSession = (props) => {

	const {tid} =useParams();
	const classes = useStyles();
    const regex = /(<([^>]+)>)/ig;

	const history = useHistory();
	const [sessionName, setSessionName] = useState('');
	const [sessionDescription,setSessionDescription] = useState('');
	const [addFileName, setAddFileName] = useState('');
	const [addFilePath, setAddFilePath] = useState('');
	const [ajaxLoader, setAjaxLoader] = useState(false);

	const [blockButton , setBlockButton] = useState(false);

	useEffect(() => {

		$('#chooseFile').bind('change', function () {
  			var filename = $("#chooseFile").val();
  			if (/^\s*$/.test(filename)) {
    			$(".file-upload").removeClass('active');
    			$("#noFile").text("No file chosen..."); 
  			}else{
    			$(".file-upload").addClass('active');
    			$("#noFile").text(filename.replace("C:\\fakepath\\", "")); 
  			}
		});

		$(function() { 
			$('.dropdown-toggle').click(function() { 
			$(this).next('.dropdown').slideToggle();
		});

		$(document).click(function(e) { 
			var target = e.target; 
			if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
				{ $('.dropdown').slideUp(); }
			});
		});

		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})
		editFacilitators();
	},[]);

//edit facilitators
	async function editFacilitators(){

		
		if(tid){
			let res=await getSessionId(tid);
			
			setSessionName(res.data.data[0].name);
			setSessionDescription(res.data.data[0].description__value     );
			setAddFileName(res.data.data[0].field_attachment.slice(29,70));
		}


	}

	const addFileHandler=(event) => {

		if(event.target.files && event.target.files[0]) {
			let name  = event.target.files[0].name;
			if(event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && event.target.files[0].type  !== 'application/msword'){
				toast.error('Invalid File Type. File can only be of the following types : pdf, doc, docx', {
			        position: 'top-center'
		        });
		        return false;
			}else{
				if(event.target.files[0].size > 5000000){
					toast.error('File size exceeds the limit of 5MB', {
						position: 'top-center'
					});
					return false;
				}else{
					setAddFileName(name);
					var reader = new FileReader();
					reader.onload = function (event) {
						setAddFilePath(event.target.result)
					};
					reader.readAsDataURL(event.target.files[0]);
				}
			}
		}
	}

	const validateFields=()=>
	{
		if(sessionName !== ''){
			return true
		}
		else
		{
			setAjaxLoader(false);
			toast.error('Session name is required', {
				position: 'top-center'
			});
			return false;
		}
	}


	async function onSubmit(e){
		e.preventDefault();
		setAjaxLoader(true)
		let res = await validateFields()
		if(res === true)
		{  setBlockButton(true)

			if(tid)
			{
				
				
				if(addFileName !== "")
				{
					// 
					let response = await venueFilesUpload(addFileName, addFilePath);
						if(response.status == 201)
						{
							
							let Obj={
								"vid": [{"target_id": "session"}],
								"name": [{"value": sessionName}],
								"description": [{"value": sessionDescription}],
								"field_attachment":[{"target_id":response.data.data.fid[0].value}]
							}

							let updateResponse = await editSession(tid,Obj)
							if(updateResponse.status == 200)
							{
								// 
								setAjaxLoader(false);
								toast.success("facilitators updated successfully!!!", {
									position: 'top-center'
								});
								setSessionName("")
								setSessionDescription("")
								setAddFilePath("");
								setAddFileName("");
								editFacilitators();
								history.push("/facilitator-list");
							}
							else
							{
								
								setAjaxLoader(false);
								
								toast.error(updateResponse.message, {
									position: 'top-center'
								});
							}

						}
						else
				         {
							
							setAjaxLoader(false);
							toast.error(response.message, 
								{	position: 'top-center'
							});
						}
				}
				else
				{
					let Obj={
						"vid": [{"target_id": "session"}],
						"name": [{"value": sessionName}],
						"description": [{"value": sessionDescription}],
					 }


						let createResponse = await editSession(tid,Obj)
						if(createResponse.status == 200)
						{
							
							setAjaxLoader(false);
							toast.success("Session updated successfully!!!", {
							position: 'top-center'
							});
							setSessionName("")
							setSessionDescription("")
							editFacilitators();
							history.push("/facilitator-list");
							}
						else
						{
							
							setAjaxLoader(false);
							toast.error(createResponse.message, {
							position: 'top-center'
							});
						}

				}
				
			
			}
			else{
					if(addFilePath !== "")
					{
						let response = await venueFilesUpload(addFileName, addFilePath);
						if(response.status === 201){
							let dataObject =	{
																	"vid": [
																		{
																			"target_id": "session"
																		}
																	],
																	"name": [
																		{
																			"value": sessionName
																		}
																	],
																	"description": [
																		{
																			"value": sessionDescription
																		}
																	],
																	"field_attachment":[
																		{
																			"target_id":response.data.data.fid[0].value
																		}
																		]
																}
							let createResponse = await createContent(dataObject)
							if(createResponse.status === 201)
							{
								setAjaxLoader(false);
								toast.success("Session added successfully!!!", {
									position: 'top-center'
								});
								setSessionName("")
								setSessionDescription("")
								setAddFilePath("")
								setAddFileName("")
							}
							else
							{
								setAjaxLoader(false);
								toast.error(createResponse.message, {
									position: 'top-center'
								});
							}
						}
						else
						{
							setAjaxLoader(false);
							toast.error(response.message, {
					position: 'top-center'
					});
						}
					}
					else
					{
						let dataObject =	{
															"vid": [
																{
																	"target_id": "session"
																}
															],
															"name": [
																{
																	"value": sessionName
																}
															],
															"description": [
																{
																	"value": sessionDescription
																}
															]
														}


							let createResponse = await createContent(dataObject)
							if(createResponse.status === 201)
							{
								setAjaxLoader(false);
								toast.success("Session added successfully!!!", {
									position: 'top-center'
								});
								setBlockButton(false)
								setSessionName("")
								setSessionDescription("")
							}
							else
							{
								setAjaxLoader(false);
								toast.error(createResponse.message, {
									position: 'top-center'
								});
							}
					}
			}

			
		}
  }

    return (
    <>
		<ToastContainer />
		<HeadOfficeMainHeader />
		<LoaderComponent displayLoader={ajaxLoader} />  
	    <div className="main-wrapper">
		    <div className="container">	
			    <div className="user-pages-wrap">
				    <div className="row">
						<div className="col-lg-12">
							<div className="user-types-wrap course-detail-page text-center">
								<div className="common-header-row common-header-with-large-text">
									<h4>{tid ? "Update facilitator "  : "Add facilitator"}</h4>	
									<Link to={`/facilitator-list`} class="mdc-button mdc-button--raised ">
										<span className="mdc-button__label">View Existing facilitator</span>
									</Link>
									<div className="block-right">
										<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
									</div>
								</div>


								<form onSubmit= {onSubmit} className="sign-up-form">
									<div className="row">
										<div className="col-lg-6 col-md-6">
											<label className="mdc-text-field mdc-text-field--outlined">
												<input maxlength="30" value={sessionName} onChange={(e)=>setSessionName(e.target.value)} type="text" className={sessionName !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'}aria-labelledby="my-label-id" />
												<span className="mdc-notched-outline">
												<span className="mdc-notched-outline__leading"></span>
												<span className="mdc-notched-outline__notch">
													<span className="mdc-floating-label" id="my-label-id">Session Name</span>
												</span>
												<span className="mdc-notched-outline__trailing"></span>
												</span>
									   		</label>
									 	</div>

									 	<div className="col-lg-6 col-md-6">
									    	<label className="mdc-text-field mdc-text-field--outlined">
												{addFileName ?(
												<label className="reminder-field-style">Attachment Doc</label>
												): null}
												<input onChange={(e)=>addFileHandler(e)} type="file" accept=".pdf,.docx,.doc"  id="attach-file" className="mdc-text-field__input" aria-labelledby="my-label-id"/>
												<span className="mdc-notched-outline">
									           		<span className="mdc-notched-outline__leading"></span>
									            	<span className="mdc-notched-outline__notch">
									                	<span className="mdc-floating-label" id="my-label-id">{addFileName ? addFileName.replace(/%20/g,'_') : "Attachment Doc"}</span>
									           		</span>
									            	<span className="mdc-notched-outline__trailing"></span>
									        	</span>
									        	<span className="material-icons">attach_file</span>
									    	</label>
											<small className="short-description">File can only be of the following types : pdf, doc, docx with limit upto 5MB</small>
									 	</div>

									 	<div className="col-md-12">
										    <div className="outlined-textfield-wrap mt-10">
										    	<div className="material-textfield">
										 	    	<textarea maxlength="200" id="message" className="form__field" placeholder="" rows="6" onChange={(e)=>setSessionDescription(e.target.value)} value={sessionDescription}></textarea>
										 	    	<label >Session Description</label>
										 	  	</div>
										   	</div>
									 	</div>

									</div>
									<button type='submit' disabled={blockButton} className="mdc-button mdc-button--raised mt-5">
                  						<span className="mdc-button__label">{tid ? "Update" : "Submit"}</span>
                  					</button>
								</form>
							</div>	
						</div>	
				    </div>
			    </div>		
		    </div>	
	    </div>

    </>
    )

}

export default AddSession;