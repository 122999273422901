import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { $ } from 'react-jquery-plugin';
import { getSpecificUser, updateUserImage, getUserProfileData, uploadImage, logoutUser, getAllNotificationList, getNotificationCountAPI, updateCaBTUserImage, updateCabtProfile } from '../../functions/api';
import './styles.css';
import { Link } from 'react-router-dom';
import NotificationPopup from '../../commonComponents/NotificationPopup/NotificationPopup';
import { ToastContainer, toast } from 'react-toastify';
import LoaderComponent from '../../commonComponents/LoaderComponent/LoaderComponent'
import 'react-toastify/dist/ReactToastify.css';

//Import Images
import logo from '../../images/logo.svg';
import camera from '../../images/camera.png';
import user2 from '../../images/user2.png';

const EducatorsMainHeader = () => {

  let user_id = window.localStorage.getItem('uid');
  let user_role = window.localStorage.getItem('user_role');

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0)
  const [username, setUsername] = useState();
  const [userpicture, setUserpicture] = useState();
  const [tabsValue, setTabsValue] = useState([
    { link: '/educators-dashboard', icon: 'dashboard', name: 'Dashboard' },
    { link: '/notices', icon: 'task', name: 'Notices' },
    { link: '/m&s', icon: 'event_note', name: 'M & S' },
    { link: '/tasks', icon: 'task', name: 'Tasks' },
    { link: '/library', icon: 'folder', name: 'Locker' },
    { link: '/link', icon: 'assignment', name: 'Link' }])
  const [notificationPopup, setNotififcationPopup] = useState(false)
  const [profileId, setProfileId] = useState('');
  const [showLoader, setShowLoader] = useState(false)
  const [count, setCount] = useState('')

  //store all notification listing
  const [all_notification, set_all_notification] = useState([]);

  useEffect(() => {

    try {
      let userSavedPic = window.localStorage.getItem("user_picture")
      if (userSavedPic) {
        setUserpicture(userSavedPic);
      }
    } catch (error) {

    }

    $(".notification-block,.user-block").hover(function () {
      var isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children(".common-listing-block").stop().slideDown(300);
      } else {
        $(this).children(".common-listing-block").stop().slideUp(300);
      }
    });

    getInitialValues();

    $(function () {
      $('.dropdown-toggle').unbind('click').bind('click', function (e) {
      });

      $('.dropdown-toggle').on('click', function () {
        return false;
      });

      $('.dropdown-toggle').click(function (event) {
        event.preventDefault();
        $(this).next('.dropdown').slideToggle();
      });
      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
          $('.dropdown').slideUp();
        }
      });
    });
    $(".mobile-toggel-icon").click(function () {
      $(".header-bar .right-block .menu-list").slideToggle();
    });
    get_notification_user();
    getNotificationCount();

    let pathname = window.location.pathname

    if (user_role === 'cabt'){

      setTabsValue([
        { link: '/cabt-dashboard', icon: 'dashboard', name: 'Dashboard' },
        { link: '/notices', icon: 'task', name: 'Notices' }
        // { link: '/m&s', icon: 'event_note', name: 'M & S' },
        // { link: '/tasks', icon: 'task', name: 'Tasks' },
        // { link: '/link', icon: 'assignment', name: 'Link' }
      ])

        if (pathname.includes("cabt-dashboard")) { setActiveTab(0); }
        else if (pathname.includes("notices")) { setActiveTab(1) }
        else if (pathname.includes("m&s")) { setActiveTab(2) }
        else if (pathname.includes("tasks")) { setActiveTab(3) }
        else if (pathname.includes("link")) { setActiveTab(4) }

    }else{
      setTabsValue([
        { link: '/educators-dashboard', icon: 'dashboard', name: 'Dashboard' },
        { link: '/notices', icon: 'task', name: 'Notices' },
        { link: '/m&s', icon: 'event_note', name: 'M & S' },
        { link: '/tasks', icon: 'task', name: 'Tasks' },
        { link: '/library', icon: 'folder', name: 'Locker' },
        { link: '/link', icon: 'assignment', name: 'Link' }])
      if (pathname.includes("educators-dashboard")) { setActiveTab(0); }
      else if (pathname.includes("notices")) { setActiveTab(1) }
      else if (pathname.includes("m&s")) { setActiveTab(2) }
      else if (pathname.includes("tasks")) { setActiveTab(3) }
      else if (pathname.includes("library")) { setActiveTab(4) }
      else if (pathname.includes("link")) { setActiveTab(5) }

    }


    // eslint-disable-next-line
  }, []);

  const reRun = (data) => {
    if (data)
      getNotificationCount();
  }
  const getNotificationCount = async () => {
    const response = await getNotificationCountAPI(user_id);
    if (response.status === 200)
      setCount(response.data.data.data)
  }

  async function getInitialValues() {

    let uid = window.localStorage.getItem('uid');
    let auth_token = window.localStorage.getItem('auth_token');
    let user_response = await getSpecificUser(uid, auth_token);
    if (user_response.status === 200) {
      const userData = user_response.data.data;
      if (userData !== '' && userData !== undefined) {
        const firstName = userData.field_first_name[0]?.value || '';
        const lastName = userData.field_last_name?.[0]?.value || '';
        const username = lastName.length > 0 ? `${firstName} ${lastName}` : firstName;
        setUsername(username);
        if ((userData.educator_profiles !== undefined) || (userData.cabt_profiles !== undefined)) {
          const profileId = user_role === 'cabt' ? (userData.cabt_profiles[0]?.target_id || '') : (userData.educator_profiles[0]?.target_id || '');
          if (profileId !== '') {
            setProfileId(profileId);
            const profile_response = await getUserProfileData(profileId, auth_token);
            if (profile_response.status === 200) {
              const profileData = profile_response.data.data;
              if (profileData !== '' && profileData !== undefined) {
                const profilePicture = profileData.field_profile_picture?.[0]?.url || '';
                if (profilePicture !== '') {
                  setUserpicture(profilePicture);
                  window.localStorage.setItem('user_picture', profilePicture);
                }
              }
            }
          }
        }
      }
    }
  }

  const logout = async () => {
    setShowLoader(true)
    let logoutResponse = await logoutUser()
    if (logoutResponse.status === 200) {
      setShowLoader(false)
      window.localStorage.clear();
      history.push("/")
    } else {
      setShowLoader(false)
    }
  }

  const navigateUser = (e, value) => {
    e.preventDefault()
    if (value === "link") {
      history.push("/link")
    }
    if (value === "notices") {
      history.push("/notices")
    }
    if (value === "edit") {
      if (user_role === 'cabt'){
        history.push(`/cabt-profile/${user_id}`)
      }else{
        history.push(`/educators-profile/${user_id}`)
      }
    }
  }

  const showNotificationPopup = () => {
    if (notificationPopup === true) {
      setNotififcationPopup(false)
    } else {
      setNotififcationPopup(true);
    }
  }

  async function updateImage(e) {

    if (e.target.value.length > 0) {
      if (e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') {
        toast.error('Invalid Image Type. Images can only be of the following types : jpg, jpeg, png', {
          position: 'top-center'
        });
        return false;
      } else {
        if (e.target.files[0].size > 5000000) {
          toast.error('File size exceeds the limit of 5MB', {
            position: 'top-center'
          });
          return false;
        } else {
          var reader = new FileReader();
          reader.onload = await function (ev) {
            setUserpicture(ev.target.result)
            uploadUserImage(e.target.files[0].name, ev.target.result)
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }

  }

  const uploadUserImage = async (name, path) => {
    let imageRes = await uploadImage(name, path)
    if (imageRes.status === 201) {
      if (imageRes.data.data !== undefined && imageRes.data.data !== '') {
        if (imageRes.data.data.fid !== undefined && imageRes.data.data.fid !== '') {
          let fid = imageRes.data.data.fid[0] ? imageRes.data.data.fid[0].value : ''
          if (fid !== '') {
            // Update UserImage
            let uid = window.localStorage.getItem('uid');
            let data = user_role === 'cabt' ? {image: fid, uid:uid} : { image: fid }

            let res = user_role === 'cabt' ? await updateCabtProfile(profileId, data) : await updateUserImage(profileId, data);

            if (res.status === 200) {
              toast.success("Profile Image Updated Successfully!!", {
                position: 'top-center'
              });
              window.localStorage.removeItem('updateUserImagePath')
            } else {
              toast.error(res.message, {
                position: 'top-center'
              });
            }
          }
        }
      }
    } else {
      toast.error(imageRes.message, {
        position: 'top-center'
      });
    }
  }


  async function get_notification_user() {

    let uid = window.localStorage.getItem('uid');
    const res = await getAllNotificationList(uid);
    if (res && res.status === 200) {
      const filteredData = res.data.data.filter(item => item.content_type !== "taxonomy_public")
      const filterLast = filteredData.filter(function (member, index) { return index !== filteredData.length - 1; });
      set_all_notification(filterLast)
    }
  }

  const goBack = (url) => {
    history.push(url)
    if (url === '/library') {
      // window.location.reload()
    }
  }

  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      <div className="top-bar header-bar">
        <div className="container">
          <Link to={user_role === 'cabt' ? '/cabt-dashboard' : `/educators-dashboard`} className="main-logo" ><img src={logo} alt='' /></Link>
          <div className="right-block">
            <ul className="menu-list">
              <>
                {
                  tabsValue.map((items, index) =>
                    <li key={index} className={activeTab === index ? "active" : ""}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => goBack(items.link)}>
                        <span className="material-icons">{items.icon}</span>
                        {items.name}
                      </a>
                    </li>
                  )
                }
              </>
            </ul>
            <div onClick={showNotificationPopup} className="notification-block">
              <span className="material-icons notify-icon">
                notifications_active
              </span>
              {count ?
                <span className="count-field">
                  {count}
                </span> : null
              }
              {notificationPopup === true ?
                <div className="common-listing-block-notifications">
                  <NotificationPopup data={all_notification} rerun={reRun} />
                </div> : null
              }
            </div>
            <div className="user-block">
              <figure className="user-media"><img src={userpicture ? userpicture : user2} alt='' /></figure>
              <div className="common-listing-block EducatorsMainHeader">
                <div className="user-media-row">
                  <div className="media">
                    <input onChange={(e) => updateImage(e)} type="file" accept="image/jpg,image/png,image/jpeg" />
                    <img src={userpicture ? userpicture : user2} alt='' />s
                    <img className="small-thumb" src={camera} alt='' />
                  </div>
                  <div className="media-info">
                    <h2>{username}</h2>
                  </div>
                </div>
                <ul className="mdc-list mdc-list--two-line">
                  <li className="mdc-list-item" tabIndex="0" style={{ cursor: 'pointer' }}
                    onClick={(e) => navigateUser(e, "edit")}>
                      {
                        user_role === 'cabt' ?
                        <Link to={`/cabt-profile/${user_id}`}><span className="material-icons">account_circle</span></Link>
                        :
                        <Link to={`/educators-profile/${user_id}`}><span className="material-icons">account_circle</span></Link>
                      }
                    {/* eslint-disable-next-line */}
                    <span className="mdc-list-item__text">
                      {/* eslint-disable-next-line */}
                      <a className="link-wrap" >
                        <span className="mdc-list-item__primary-text">{user_role === 'cabt' ? 'CaBT': "Educator"} Information</span>
                        <span className="mdc-list-item__secondary-text">{user_role === 'cabt' ? 'CaBT': "Educator"} Details</span>
                        <span className="material-icons arrow-forward">arrow_forward_ios</span>
                      </a>
                    </span>
                  </li>
                  <li className="mdc-list-item logout" style={{ cursor: 'pointer' }} onClick={logout}>
                    <span className="material-icons">logout</span>
                    <span className="mdc-list-item__text">
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0)" className="link-wrap">
                        <span className="mdc-list-item__primary-text">Logout</span>
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <span class="material-icons mobile-toggel-icon">menu</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EducatorsMainHeader;