import React ,{useState} from "react"
import { useParams } from "react-router-dom";
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadOfficeFolderDetail from './HeadOfficeFolderDetail'
import HeadOfficeFilesDetail from './HeadOfficeFilesDetail'
import FolderNavigation from './FolderNavigation'

// const HeadOfficeFolders=({educatorId})=>
const HeadOfficeFolders=(props)=>
{
	const { alias1 } = useParams();
	const [allFolders, setAllFolders] = useState([])
	const[ childFolderData , setChildFolderData] = useState([])
	const [parentID , setParentID] = useState('');
	const [ glow, setGlow] = useState('')

	const setFoldersData=(value)=>{
		setAllFolders(value)
	}

	const ToastComponent = React.memo(props => {
		return(<ToastContainer />)
	});

	const getChildFolderData=(value)=>{
		setChildFolderData(value)
	}

	const getParentId =(value) =>{
		setParentID(value)
	}

	const getSelectedId =(value)=>{	
		setGlow(value);	
	}

	return(
	<>
		<ToastComponent />
		<HeadOfficeMainHeader />	
		<div className="main-wrapper mtb-70">
			<div className="container">	
				<div className="dashboard-wrap">
					<div className="row">
							<FolderNavigation uid={props?.location?.state?.uid ? props?.location?.state?.uid : null} data={allFolders} getChildFolderData={getChildFolderData} getParentId={getParentId} glowId={glow}/>	
							{
								alias1 !== undefined ?
								<HeadOfficeFilesDetail uid={props?.location?.state?.uid ? props?.location?.state?.uid : null} getSelectedId={getSelectedId} />
								:
								<HeadOfficeFolderDetail getFolders= {setFoldersData} childData={childFolderData} parentId={parentID}  />
							}
					</div>
				</div>		
			</div>	
		</div>
	</>
	)
}

export default HeadOfficeFolders