import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getNotificationCountAPI, readCurrentNotification, readGlobalNotification, resetNotificationCountAPI } from '../../functions/api';
import { useSelector } from 'react-redux';

const NotificationPopup = (props) => {
  let user_id = window.localStorage.getItem('uid');
  const [allNotifications, setAllNotifications] = useState([])
  const store = useSelector(state => state.auth)
  const [userRole, setUserRole] = useState('')
  const history = useHistory();
  let userId = window.localStorage.getItem('uid')

  useEffect(() => {
    if (store.userRole) {
      setUserRole(store.userRole)
    }
  }, [store.userRole])

  useEffect(() => {
    const resetNotification = async () => {
      const response = await resetNotificationCountAPI(user_id);
      if (response.status === 200) {
        getNotificationCount()
      }
    }
    resetNotification();
    // eslint-disable-next-line
  }, [])

  const getNotificationCount = async () => {
    await getNotificationCountAPI(user_id);
    props.rerun(true);
  }

  useEffect(() => {
    if (props.data !== undefined && props.globalData !== undefined) {
      let newNotificationListApi = props.data.concat(props.globalData);
      setAllNotifications(newNotificationListApi)
    }
    else if (props.data !== undefined) {
      setAllNotifications(props.data)
    }
    else if (props.globalData !== undefined) {
      setAllNotifications(props.globalData)
    }
  }, [props])


  //READ NOTIFCATION API
  async function read_notification(items) {

    let contentType = items.content_type
    if (userRole === 1) {
      if (contentType === "task") {
        history.push(`/task-detail-page/${items.link}`)
         await readCurrentNotification(items.link)
      }

      if (contentType === "visits") {
        history.push(`/m&s-detail-page/${items.link}`)
         await readCurrentNotification(items.link)
      }

      if (contentType === "links" || contentType === "booking") {
        history.push(`/link-detail-page/${items.link}`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
         await readGlobalNotification(dataObject)
      }

      if (contentType === "leave") {
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
       await readGlobalNotification(dataObject)
      }

      if (contentType === "notice_board") {
        history.push(`/notice-detail-page/${items.link}`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
         await readGlobalNotification(dataObject)
      }
    }
    else if (userRole === 2) {
      if (contentType === "task") {
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
        history.push(`/task-detail-page/${items.link}`)
        await readGlobalNotification(dataObject)
      }

      if (contentType === "visits") {
        history.push(`/m&s-detail-page/${items.link}`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
        await readGlobalNotification(dataObject)
      }

      if (contentType === "links" || contentType === "booking") {
        history.push(`/link-detail-page/${items.link}`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
         await readGlobalNotification(dataObject)
      }

      if (contentType === "leave") {
        history.push(`/co-ordination-unit-dashboard`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
        await readGlobalNotification(dataObject)
      }

      if (contentType === "notice_board") {
        history.push(`/notice-detail-page/${items.link}`)
        let dataObject = {
          "lid": [{ "value": items.lid }],
          "user_id": [{ "value": userId }]
        }
       await readGlobalNotification(dataObject)
      }
    }
  }

  return (
    <ul className="mdc-list mdc-list--two-line">
      {allNotifications.length > 0 ? allNotifications.map((items) => {
        return (
          <li className="mdc-list-item" tabIndex="0" onClick={() => read_notification(items)}>
            <span className="material-icons clr-green">bookmark</span>
            <span className="mdc-list-item__text">
              <span className="mdc-list-item__primary-text">{items.title}</span>
              <span className="mdc-list-item__secondary-text">
                <div dangerouslySetInnerHTML={{ __html: items.description && items.description }} ></div>
              </span>
            </span>
          </li>
        )
      }) : <span className="mdc-list-item__primary-text">No New Notifications!!!</span>}
    </ul>
  )
}

export default NotificationPopup;