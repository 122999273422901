import React, { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom";
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { getFolderAllAPI, getFolderFiles, updateTargetIdsOfImage, uploadAllDocs, uploadMultiFilesInFolder, deleteSpecificFolderFile, updateSpecificFolderFile } from '../../../functions/api'
import { baseUrl } from '../../../config'
import { toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import './styles.css';

import 'react-responsive-modal/styles.css'; //modal 
import { Modal } from 'react-responsive-modal'; //modal 

//Import Images
import favourite from '../../../images/favorite.svg';
import privateFolder from '../../../images/private.svg';
import folder from '../../../images/folder.svg';
import folderTwo from '../../../images/folder-two.svg';

import pdfIcons from '../../../images/pdfImage.png';
import excelIcons from '../../../images/excelImage.png';
import docIcons from '../../../images/docImage.png';
import ReactTooltip from "react-tooltip";
//jquery
import { $ } from 'react-jquery-plugin';
import { confirmAlert } from "react-confirm-alert";


const EducatorFilesDetail = (props) => {
	const { alias1 } = useParams();
	const [allFiles, setAllFiles] = useState([])
	const [open, setOpen] = useState(false);
	const [fileType, setFileType] = useState('')
	const [filePath, setFilePath] = useState('')
	const [folderName, setFolderName] = useState('')
	const [showLoader, setShowLoader] = useState(true)
	const [uploadImagePath, setUploadImagePath] = useState([])
	const [folderData, setFolderData] = useState([]);
	const [userFolderData, setUserFolderData] = useState([])

	const location = useLocation();
	const history = useHistory();

	const [previousIndex, setPreviosIndex] = useState(null);
	const [showOptions, setShowOptions] = useState([])
	//new state 
	const [ind, setIndex] = useState()
	const [dropdownIndex, setDropdownIndex] = useState(false)
	const [fileName, setFileName] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [fid, setFid] = useState()

	// 
	// 

	async function FolderNames() {
		setShowLoader(true)
		const response = await getFolderAllAPI();
		if (response.status === 200) {
			setUserFolderData(response.data.data)
			const filteredData = response.data.data.filter(item => item.tid === alias1.toString())

			setFolderData(filteredData);
			setShowLoader(false)

		} else {
			setShowLoader(false)
		}
	}
	const showHideOptions = (index) => {
		if (dropdownIndex) {
			setIndex()
			setDropdownIndex(false)
		} else {
			setIndex(index)
			setDropdownIndex(true)
		}
		// let newArray = showOptions;

		// if (previousIndex === index) {
		// 	setPreviosIndex(null)
		// 	newArray[index] = false
		// }
		// else {
		// 	if (newArray[index] === true) {
		// 		newArray[previousIndex] = false
		// 		setPreviosIndex(null)
		// 		newArray[index] = false
		// 	}
		// 	else {
		// 		newArray[previousIndex] = false
		// 		setPreviosIndex(index)
		// 		newArray[index] = true
		// 	}
		// }
	}

	async function getAllFilesData() {
		let foldersResponse = await getFolderFiles(alias1);

		if (foldersResponse.status === 200) {
			if (foldersResponse.data.data.length > 0) {
				setAllFiles(foldersResponse.data.data)

				setFolderName(foldersResponse.data.data[0].field_folder);
				// setShowLoader(false)

				// 
				// setFolderData()
			}
		}
		else {
			setShowLoader(false)
			setAllFiles([])
			toast.error(foldersResponse.message, { position: 'top-center' });
		}
	}

	useEffect(() => {
		FolderNames();
		getAllFilesData()

	}, [alias1])


	const displayDocument = (filePath) => {
		let splittedArray = filePath.split(".")
		setOpen(true)
		setFilePath(baseUrl + filePath)
		setFileType(splittedArray[1])
	}

	const onError = e => {

	};

	const uploadFiles = (e) => {
		setShowLoader(true)
		if (e.target.files && e.target.files[0]) {

			var reader = new FileReader();

			reader.onload = async function (event) {
				let uploadDocResponse = await uploadAllDocs(e.target.files[0].name, event.target.result, alias1);
				if (uploadDocResponse.status === 201) {
					await getAllFilesData()
					toast.success("Uploaded Successfully!!!", { position: 'top-center' });
					setShowLoader(false)
				}
				else {
					setShowLoader(false)
					toast.error(uploadDocResponse.message, { position: 'top-center' });
				}
			};

			reader.readAsDataURL(e.target.files[0]);
		}
	}

	async function handleImageDelete(data, index) {
		setShowLoader(true)
		if (ind == index) {
			const res = await deleteSpecificFolderFile(data?.fid)
			// console.log('res0----', res);
			if (res?.status === 200) {
				setAllFiles([])
				setShowLoader(false)
				toast.success("File Deleted Successfully", { position: 'top-center' });
				await getAllFilesData()
				setIndex()

			}
			else {

				setShowLoader(false)
				toast.error(res.message, { position: 'top-center' });

			}
		}
	}

	function confirmDeleteLockerFile(data,index) {

		confirmAlert({
			title: 'Are you sure you want to delete?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleImageDelete(data,index)
				},
				{
					label: 'No',
					onClick: () => showHideOptions(index)
				}
			]
		});
	}

	// Edit File Name Functionality

	const selectFileOptions = (id, index, filename) => {
		setFid(id)
		setFileName(filename)
		if (ind == index) {
			setShowModal(true)
			setDropdownIndex(false)
		}

	}

	const updateFileName = async () => {
		// console.log("data", fid, fileName);
		setShowLoader(true)
		let fileChangeName = await updateSpecificFolderFile(fid, fileName)
		// console.log("fileChangeName", fileChangeName);
		if (fileChangeName?.status === 200) {
			setAllFiles([])
			setShowLoader(false)
			toast.success("File Name Update Successfully", { position: 'top-center' });
			getAllFilesData()
			setIndex()
			setShowModal(false)
		}
		else {

			setShowLoader(false)
			toast.error(fileChangeName.message, { position: 'top-center' });

		}
	}

	const uploadImageHandler = (event, field) => {
		let imagesArray = [];
		let fidOfImage = [];


		//alert(imagesArray.length)

		if (event.target.files && event.target.files[0]) {

			let lengthOfFiles = Object.keys(event.target.files).length;
			if (Object.keys(event.target.files).length <= 7) {

				Object.values(event.target.files).map(function (filesData, index) {
					let name = filesData.name;
					if (field === "image") {

						// if(filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png'){
						// 	toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
						//         position: 'top-center'
						//     });
						//     return false;
						// }else{
						if (filesData.size > 5000000) {
							toast.error('Image size exceeds the limit of 5MB', {
								position: 'top-center'
							});
							return false;
						} else {
							setShowLoader(true)

							var reader = new FileReader();
							reader.onload = async function (event) {
								imagesArray.push({ 'name': name, 'path': event.target.result })
								const response = await uploadMultiFilesInFolder(name, event.target.result)
								//  
								fidOfImage.push({ "target_id": response })

								if (lengthOfFiles === fidOfImage.length) {

									const uploadDocResponse = await updateTargetIdsOfImage("Data_Media", fidOfImage, alias1);

									if (uploadDocResponse.status === 201) {
										setShowLoader(false)
										await getAllFilesData()
										toast.success("Uploaded Successfully!!!", { position: 'top-center' });
									}
									else {
										setShowLoader(false)
										toast.error(uploadDocResponse.message, { position: 'top-center' });
									}
								}


								// setUploadImagePath([...uploadImagePath , imagesArray])
							};
							reader.readAsDataURL(filesData);
						}
					}



					// }
				});


			}
			else {
				toast.error("You can't upload more than 7 images", { position: 'top-center' });
			}


		}

	}

	const navigateToPosition = async (Id) => {

		// 
		props.getSelectedId(Id)
		const filteredData = await userFolderData.filter(item => item.parent_target_id[0] === Id.toString())

		history.push({ pathname: '/library', state: { requiredData: filteredData } })
	}

	if (showLoader) {
		return <LoaderComponent displayLoader={showLoader} />
	}
	return (
		<>
			<div className="col-md-8">
				<div className="content-block-right">
					<div className='breadcrun-of-folder-path'>
						{folderData && folderData.length > 0 ? (
							<div className="row">
								<u>
									<h4 dangerouslySetInnerHTML={{ __html: folderData[0].name_1 }} onClick={() => navigateToPosition(folderData[0].parent_target_id[0])} style={{ cursor: 'pointer' }}></h4> </u>
								<span className="material-icons " style={{ margin: 4 }}>arrow_forward</span>
								<h4 dangerouslySetInnerHTML={{ __html: folderData[0].name }}></h4>
							</div>
						) : <h4>{folderName}</h4>}
					</div>
					<div className="common-header-row">

						<div className="btn-right upload-btn" >
							<ReactTooltip place="top" type="dark" effect="float" id="upload">
								<p>Accept jpg, jpeg, png, pdf, doc, docx formats only</p>
							</ReactTooltip>
							<div className="mdc-button mdc-button--raised green-btn" data-tip data-for='upload'>
								<input type="file" style={{ "opacity": "0", "position": "absolute", "height": "40px", cursor: 'pointer' }} multiple accept="image/jpg,image/png,.pdf,.doc,.docx" onChange={(e) => uploadImageHandler(e, "image")} />
								<span className="material-icons">upload</span> <span>Upload File</span>
							</div>
						</div>
					</div>

					<ul className="big-folders-list">
						<>
							{
								allFiles.length && !showLoader ?
									allFiles.map((items, index) => {
										let imageName = items?.filename
										let imageUrl = baseUrl + items.field_media_file_1
										let splittedArray = items.field_media_file_1.split(".")
										let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
											splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
												: splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
													: imageUrl
										let fileName = items.name.length > 12 ? items.name.substring(0, 12) + "...." : items.name.substring(0, 12)
										let splitFile = imageName.split(".");
										if (items?.fid) {
											return (
												<li onClick={() => displayDocument(items.field_media_file_1)} style={{ cursor: 'pointer', marginBottom: 40 }}  >
													<figure>
														<a href={baseUrl + items.field_media_file_1} target="_blank"><img src={uploadedImageIcon} /></a>
													</figure>
													<p>
														{imageName}
														<span className="material-icons" onClick={() => showHideOptions(index)} >more_vert</span>
													</p>
													{ind === index && dropdownIndex == true ?
														<ul className="dropdown-2">
															<li onClick={() => confirmDeleteLockerFile(items, index)}> Delete </li>
															<li onClick={() => selectFileOptions(items.fid, index, splitFile[0])}> Edit </li>
														</ul>
														: null
													}
												</li>
											)
										}
									})
									: (
										<div className="center-text-with-no-data">
											<h1>No Data Available</h1>
										</div>
									)
							}
						</>
					</ul>
					{
						showModal ?
							<div class="container">
								<div class="dashboard-wrap">
									<div class="row">
										<div class="col-lg-8 offset-lg-2 offset">
											<div class="add-folders-popup new-folder">
												<h3>Edit File Name</h3>
												<label className="mdc-text-field mdc-text-field--outlined">
													<input className={fileName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" value={fileName} onChange={(e) => setFileName(e.target.value)} name="folderName" aria-labelledby="my-label-id" />
													<span className="mdc-notched-outline">
														<span className="mdc-notched-outline__leading"></span>
														<span className="mdc-notched-outline__notch">
															<span className="mdc-floating-label" id="my-label-id">Edit File Name</span>
														</span>
														<span className="mdc-notched-outline__trailing"></span>
													</span>
												</label>


												<div class="cancel-btn-wrap">
													<button class="mdc-button" onClick={() => setShowModal(false)}>
														<span class="mdc-button__ripple"></span>
														<span class="mdc-button__label">cancel</span>
													</button>

													<button type="button" class="mdc-button" onClick={updateFileName}>
														<span class="mdc-button__ripple"></span>
														<span class="mdc-button__label">Update</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							:
							null
					}


				</div>

			</div>

			{/* <Modal open={open} onClose={()=>setOpen(false)} center>
			<iframe id="myFrame" src={filePath}></iframe>
      	</Modal> */}
		</>
	)
}



export default EducatorFilesDetail