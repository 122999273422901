import React, { useState, useEffect } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { newNoticeList, searchNotice } from '../../../functions/api';
import { useSelector } from "react-redux"
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import CommonNoticeBoardListComponent from '../../../commonComponents/CommonNoticeBoardListComponent/NoticeBoardList';
import { Link } from 'react-router-dom';
//Import Images
import EmptyData from '../../../commonComponents/EmptyData/EmptyData';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import moment from 'moment';

const NoticesPage = () => {

  const store = useSelector(state => state.auth)
  const [allNotices, setAllNotices] = useState([])
  const [userRole, setUserRole] = useState(1)
  const [showLoader, setShowLoader] = useState(true)
  const [noticeType, setNoticeType] = useState('all')
  const [noticeDays, setNoticeDays] = useState('days')
  const [inputData, setInputData] = useState()

  async function getAllInitalData() {
    setUserRole(store.userRole)
    let noticesArray = await newNoticeList("all");
    if (noticesArray.status === 200 && noticesArray.data.data.length > 0) {
      setShowLoader(false)
      let todayDate = moment(new Date()).format('YYYY-MM-DD');
      let filteredNotices = noticesArray.data.data.filter((data) => data.field_archived_date !== todayDate);
      setAllNotices((prevNotices) => [...prevNotices, ...filteredNotices]);
    } else {
      setShowLoader(false)
    }
  }

  async function getArchiveData() {
    let noticesArray = await newNoticeList("archive");
    if (noticesArray.status === 200 && noticesArray.data.data.length > 0) {
      setShowLoader(false)
      setAllNotices([...noticesArray.data.data])
    } else {
      setShowLoader(false)
    }
  }

  async function getDaysData(value) {
    let noticesArray = await newNoticeList(value);
    if (noticesArray.status === 200 && noticesArray.data.data.length > 0) {
      setShowLoader(false)
      await setAllNotices([...noticesArray.data.data])
    } else {
      setShowLoader(false)
    }
  }

  async function search(inputValue) {
    setInputData(inputValue)
    let response = await searchNotice(inputValue, (noticeType === 'all' ? 0 : 1))
    if (response.status === 200) {
      if (response.data !== undefined && response.data !== '') {
        setAllNotices([...response.data.data]);
      }
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  useEffect(() => {
    window.localStorage.removeItem('folderParentId');
    getAllInitalData();
  }, [store.userRole])

  const filterNoticeType = async (e, value) => {
    e.preventDefault()
    let filterValue = value === 0 ? "all" : "archived"
    setNoticeType(filterValue)
    setShowLoader(true)
    if (filterValue === "all") {
      await getAllInitalData()
    } else {
      await getArchiveData()
    }
  }

  const filterNoticeDays = async (e, value) => {
    e.preventDefault()

    if (value === 'all') {
      setShowLoader(true)
      await getDaysData(value)
      setNoticeDays(value)

    } else {
      let filterDaysValue = value === 0 ? "1" : value === 1 ? "2" : "3"
      setNoticeDays(filterDaysValue)
      setShowLoader(true)
      if (value === 0) {
        await getDaysData(filterDaysValue)
      } else if (value === 1) {
        await getDaysData(filterDaysValue)
      } else {
        await getDaysData(filterDaysValue)
      }
    }
  }


  if (showLoader) {
    return <LoaderComponent displayLoader={showLoader} />
  }

  return (
    <>
      {
        userRole === 1 || userRole === 3 ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <LoaderComponent displayLoader={showLoader} />
      <div>
        <div className="container">
          <div className="dashboard-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="content-block-right">
                  <div className="common-header-row">
                    <h4>Notice Board {userRole === 2 ? <Link to={`/add-new-notice`} class="mdc-button mdc-button--raised green-btn"><span class="mdc-button__label"> Add New Notice </span> </Link> : null}</h4>
                    <div className='select-boxes'>
                      <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                        value={noticeType}>
                        <MenuItem value="all" onClick={(e) => filterNoticeType(e, 0)}>All</MenuItem>
                        <MenuItem value="archived" onClick={(e) => filterNoticeType(e, 1)}>Archived</MenuItem>
                      </Select>
                      <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={noticeDays}>
                        <MenuItem value="days" disabled selected>Select Days</MenuItem>
                        <MenuItem value="all" onClick={(e) => filterNoticeDays(e, 'all')}>All</MenuItem>
                        <MenuItem value="1" onClick={(e) => filterNoticeDays(e, 0)}>Last 7 days</MenuItem>
                        <MenuItem value="2" onClick={(e) => filterNoticeDays(e, 1)}>Last 14 days</MenuItem>
                        <MenuItem value="3" onClick={(e) => filterNoticeDays(e, 2)}>Last 30 days</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <div className="col-lg-12 col-md-12">
                      <label className="mdc-text-field mdc-text-field--outlined search-educators">
                        <input type="text" onChange={(e) => search(e.target.value)} className={!inputData ? "mdc-text-field__input" : "mdc-text-field__input active"} aria-labelledby="my-label-id" />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span className="mdc-floating-label" id="my-label-id">Search By Notice</span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">saved_search</span>
                      </label>
                    </div>
                  </div>
                  {
                    allNotices.length > 0 ?
                      <>
                        <div className='row d-flex justify-content-between'>
                          <p className='adding-heading-css'>Title</p>
                          {userRole === 2 ?
                            <p className='adding-heading-right-css'>Actions</p>
                            : null}
                        </div>
                        <CommonNoticeBoardListComponent data={allNotices} noticeType={noticeType} />
                      </>
                      :
                      <EmptyData title="Notice Board is Empty!" desc={userRole === 1 ? 'There are no Notices for you from your Co Ordination Unit!' : 'Notices created by Co ordination Unit will be displayed here. Click Add New to create one.'} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoticesPage;