import React,{ useState, useEffect } from 'react';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { $ } from 'react-jquery-plugin';
import { getAllEducators, uploadImage, postEntityMedia, getSpecificUser } from '../../../functions/api';
import './styles.css';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { updateNoticeBoardDetails, getSpecificMedia } from '../../../functions/api';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";
import imageToBase64 from 'image-to-base64/browser';
import Calendar from 'react-calendar';
import moment from 'moment';

//Import Images

const EditNotice=(props) => {

	const [options, setOptions] = useState([]);
	const [educatorsList, setEducatorsList] = useState([])
	const [educatorName,setEducatorName]= useState('')
	const [url, setUrl] = useState([])
	const [noticeNotes, setNoticeNotes] = useState("");
	const [addImageName, setAddImageName] = useState("");
	const [addImagePath, setAddImagePath] = useState([]);
	const [previousImagesId, setPreviousImagesId] = useState([]);
	const [imageFid, setImageFid] = useState("");
	const history = useHistory();
	const [title, setTitle] = useState();
	const [ajaxLoader, setAjaxLoader] = useState(false);
	const [updateImage, setUpdateImage] = useState(false);

	//
	const [showCalendar, setShowCalender] = useState(false)
	const [publishDate, setPublishDate] = useState('')
	const [archievedDate, setArchievedDate] = useState('')
	const [showArchiveCalendar, setShowArchiveCalender] = useState(false)
	const [fieldName, setFieldName] = useState('')
	const [multipleUrls, setMultipleUrls] = useState([{title: '', uri: ''}])

    const regex = /(<([^>]+)>)/ig;

	function onChangeUrlHandler(event, index){
		
		let newArray = multipleUrls
		
		newArray[index].title = event.target.value
		newArray[index].uri = event.target.value
		setMultipleUrls([...newArray]);
	}

	function onChangeNoticesHandler(event){
		setNoticeNotes(event.target.value);
	}

	function onChangeTitleHandler(event){
		setTitle(event.target.value);
	}

    function get_url_extension( url ) {
    	return url.split(/[#?]/)[0].split('.').pop().trim();
	}

	useEffect(() => {
		
		$('#chooseFile').bind('change', function () {
  			var filename = $("#chooseFile").val();
  			if (/^\s*$/.test(filename)) {
    			$(".file-upload").removeClass('active');
    			$("#noFile").text("No file chosen..."); 
  			}else{
    			$(".file-upload").addClass('active');
    			$("#noFile").text(filename.replace("C:\\fakepath\\", "")); 
  			}
		});

		$(function() { 
			$('.dropdown-toggle').click(function() { 
			$(this).next('.dropdown').slideToggle();
		});

			$(document).click(function(e) { 
				var target = e.target; 
				if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
				{ $('.dropdown').slideUp(); }
			});
		});

		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})

		async function getInitialData(){
            let sortedEducatorsList = await getAllEducators();
            setEducatorsList(sortedEducatorsList.data.data);
			let opt_array = [];
			if(sortedEducatorsList.data.data.length > 0){
				sortedEducatorsList.data.data.map((edu, index) => {
					const obj = {};
					obj['value'] = edu.uid;
					obj['label'] = edu.name;
					opt_array.push(obj);
				})
				setOptions(opt_array);
			}

            // Get Notice Details
            let notice_id = props.match.params.id
            if(notice_id !==  ''){
                let responseData = await NodeDetails(notice_id)
                
                if(responseData.status === 200){
                    if(responseData.data.data.title !== undefined && responseData.data.data.title !== '')
                    {
                        let noticeTitle = responseData.data.data.title[0] ? responseData.data.data.title[0].value : ""
                        setTitle(noticeTitle)
                    }

                    if(responseData.data.data.body !== undefined && responseData.data.data.body !== '')
                    {
                        let noticeDetail = responseData.data.data.body[0] ? responseData.data.data.body[0].value.replace(regex, '') : "" 
                        setNoticeNotes(noticeDetail)
                    }

                    if(responseData.data.data.field_archived_date !== undefined && responseData.data.data.field_archived_date.length>0)
                    {
                        let _newDate = moment(responseData.data.data.field_archived_date[0].value,'YYYY-MM-DD').format('DD-MM-YYYY')
                        setArchievedDate(_newDate)
                    }

                    if(responseData.data.data.field_date !== undefined && responseData.data.data.field_date.length>0)
                    {
                        let _newDate = moment(responseData.data.data.field_date[0].value,'YYYY-MM-DD').format('DD-MM-YYYY')
                        setPublishDate(_newDate)
                    }

                    if(responseData.data.data.field_url !== undefined && responseData.data.data.field_url.length>0)
                    {
                        setMultipleUrls(responseData.data.data.field_url)
                    }

                    if(responseData.data.data.field_educator !== undefined && responseData.data.data.field_educator !== '')
                    {
                        let userId = responseData.data.data.field_educator[0] ? responseData.data.data.field_educator[0].target_id : ""
                        let specificUserResponseData = await getSpecificUser(userId)
                        if(specificUserResponseData.status === 200){
                            if(specificUserResponseData.data.data){
                                if(specificUserResponseData.data.data.name !== undefined && specificUserResponseData.data.data.name !== ''){
                                    let name = specificUserResponseData.data.data.name[0] ? specificUserResponseData.data.data.name[0].value : ""
                                    let obj = {}
                                    obj['value'] = responseData.data.data.field_educator[0].target_id;
								    obj['label'] = name;
                                    setEducatorName(obj);
                                }
                            }
                        }
                    }

                    if(responseData.data.data.field_img !== undefined && responseData.data.data.field_img.length>0)
                    {
                    	let _imagesArray = []
                    	let _targetIds = []
                    	responseData.data.data.field_img.map(async(data)=>
                    	{
                    		if(data.target_id)
                    		{
                    			let mediaResponse = await getSpecificMedia(data.target_id)
		                        if(mediaResponse.status == 200){
		                            if(mediaResponse.data.field_media_image.length>0){
		                            	let imagePath = mediaResponse.data.field_media_image[0].url
		                                let imageName = imagePath.substring(imagePath.lastIndexOf('/')+1)
		                                let imageId = mediaResponse.data.mid[0].value
		                                let _obj = {name:imageName,path: imagePath, id:imageId}
		                            	_targetIds.push({"target_id": imageId})
		                            	setPreviousImagesId(_targetIds)
		                                _imagesArray.push(_obj)
		                                setAddImagePath([..._imagesArray])
		                            }
		                        }
                    		}
                    	})
                    }
                }else{
                    
                }
            }

        }

        getInitialData()
        
	},[]);

	const educatorsHandler=(event) => {
    	setEducatorName(event);
  	};

	const addImageHandler=(event) => {
		if(event.target.files && event.target.files[0]) {

			let totalLength = Object.keys(event.target.files).length + addImagePath.length
			if(totalLength <=7)
			{
				let imagesArray = addImagePath
				Object.values(event.target.files).map(function(filesData, index) {
					if(filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png'){
						toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
					        position: 'top-center'
				        });
				        return false;
					}else{
						if(filesData.size > 5000000){
		             			toast.error('Image size exceeds the limit of 5MB', {
					        	position: 'top-center'
				        	});
				        	return false;
						}else{
			           		var reader = new FileReader();
							reader.onload = function (e) {
								setUpdateImage(true)
								imagesArray.push({'name' : filesData.name, 'path': e.target.result, id:""})
								setAddImagePath([...imagesArray])
							};
							reader.readAsDataURL(filesData);
						}
					}
				})
			}
			else
			{
				toast.error("You can't upload more than 7 images", { position: 'top-center' });
			}
			
		}
	}

	const noticeSubmission=async(event) => {
		event.preventDefault();
		setAjaxLoader(true);

		let link = url;
		let educator_id = educatorName.value;
		let notes = noticeNotes;
		let main_title = title;
		let currentDate = new Date()
		let updatedOn = moment(currentDate).format('YYYY-MM-DD')
		let _archievedDate = moment(archievedDate,'DD-MM-YYYY').format('YYYY-MM-DD') 
		let _publishedDate = moment(publishDate,'DD-MM-YYYY').format('YYYY-MM-DD')
		

		if(title === undefined || title === ''){
			toast.error('Title is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}


		if(updateImage === false)
		{
			let noticeUrls = []

			if(multipleUrls.length > 0)
			{
				multipleUrls.map(async(data)=>
				{
					let _objData = {"title": data.uri, "uri": data.uri};
					await noticeUrls.push(_objData)
					if(multipleUrls.length === noticeUrls.length)
					{
						let data = {title: main_title, link: noticeUrls, educator_id: educator_id, notes: notes, updatedDate:updatedOn,date:_publishedDate,archiveDate: _archievedDate,fid:previousImagesId};
						let res = await updateNoticeBoardDetails(props.match.params.id, data);
						if(res.status === 200){
							setAjaxLoader(false);
							localStorage.removeItem('noticeImageFid');
							history.push('/notices');
						}else{
							toast.error(res.message,{
					                 	position: 'top-center'
					            		});
							setAjaxLoader(false);
						}
					}
				})
			}

			
		}
		else
		{
			let allImagesArray = []
			addImagePath.map(async(data,index)=>
			{

				if(data.id === "")
				{
					let updatedIndex = index+1
					let uploadImageResponse = await uploadImage(data.name, data.path)
					if(uploadImageResponse.status === 201){
						let postMediaResponse = await postEntityMedia(uploadImageResponse.data.data.filename[0].value,uploadImageResponse.data.data.fid[0].value)
						if(postMediaResponse.status == 201){
							let imageFid = postMediaResponse.data.data.mid[0].value
							allImagesArray.push({ "target_id": imageFid })
							let allFilesArray = allImagesArray.concat(previousImagesId)
							if(addImagePath.length === updatedIndex)
							{
								let noticeUrls = []
								if(multipleUrls.length > 0)
								{
									multipleUrls.map(async(data)=>
									{
										let _objData = {"title": data.uri, "uri": data.uri};
										noticeUrls.push(_objData)
										if(multipleUrls.length === noticeUrls.length)
										{
											let data = {title: main_title, link: noticeUrls, educator_id: educator_id, notes: notes, updatedDate:updatedOn,date:_publishedDate,archiveDate: _archievedDate,fid:allFilesArray};
											let res = await updateNoticeBoardDetails(props.match.params.id, data);
											if(res.status === 200){
												setAjaxLoader(false);
												localStorage.removeItem('noticeImageFid');
												history.push('/notices');
											}else{
												toast.error(res.message,{
										                 	position: 'top-center'
										            		});
												setAjaxLoader(false);
											}
										}
									})
								}
								else
								{
									let data = { title: main_title, link:[], notes: notes,fid: allFilesArray, date:_publishedDate,archiveDate: _archievedDate, updatedDate:updatedOn};
									let res = await updateNoticeBoardDetails(props.match.params.id, data);
									if(res.status == 200){
										setAjaxLoader(false);
										history.push('/notices');
									}else{
										setAjaxLoader(false);
										toast.error(res.message,{
					                    	position: 'top-center'
					               		});
									}
								}
							}
						}
						else
						{
							setAjaxLoader(false);
									toast.error(postMediaResponse.message,{
				                    	position: 'top-center'
				               		});
						}
						
					}
					else
					{
						setAjaxLoader(false);
						toast.error(uploadImageResponse.message,{
	               			position: 'top-center'
	           			});
					}
				}
				
			})

		}
		
		
	}

	const removeSpecificImage=(index,selectedImageId)=>
	{
		let imagesArray = addImagePath
		imagesArray.splice(index,1)
        setAddImagePath([...imagesArray])

        let isExist =   Object.values(previousImagesId).some(function(data) {
						    return data.target_id === selectedImageId;
						});

        if(isExist === true)
        {
        	let idsArray = previousImagesId
			idsArray.splice(index,1)
	        setPreviousImagesId([...idsArray])
        }
	}


	function calendar(type){
		if(type === "published")
		{
			if(showCalendar === true)
			{
				setShowCalender(false)
			}
			else
			{
				setShowCalender(true)
			}
		}
		else
		{
			if(showArchiveCalendar === true)
			{
				setShowArchiveCalender(false)
			}
			else
			{
				setShowArchiveCalender(true)
			}
		}
		
	}

	const calendarHandler=(newDate,type) => {

		let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year

        if(type === "published")
        {
        	setPublishDate(fullYear)
			setShowCalender(false)
        }
        else
        {
        	setArchievedDate(fullYear)
        	setShowArchiveCalender(false)
        }
		
	}

	const addUrlField=()=>
	{
		let urls = multipleUrls
		urls.push({title:"",uri:""})
		setMultipleUrls([...urls])
	}

	const removeUrlField=(index)=>
	{
		let urls = multipleUrls
		urls.splice(index,1)
		setMultipleUrls([...urls])
	}

    return (
		<div>
			<ToastContainer />
       		<HeadOfficeMainHeader />
       		<LoaderComponent displayLoader={ajaxLoader} />
			<div className="main-wrapper">
				<div className="container">	
					<div className="user-pages-wrap">
						<div className="row">
								<div className="col-lg-12">
									<div className="user-types-wrap course-detail-page">
										<div className="common-header-row common-header-with-large-text">
											<h4>Edit Notice : {title}</h4>	
											<div className="block-right">
												<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
											</div>
										</div>
										<form onSubmit={noticeSubmission} className="sign-up-form">
											<div className="row">
												<div className="col-md-12">
													<div className="row">
														<div className="col-lg-4 col-md-4">
															<div className="outlined-textfield-wrap">
																<label className="mdc-text-field mdc-text-field--outlined">
																	<input maxlength="100" value={title} onChange={onChangeTitleHandler} type="text" className={title !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
																	<span className="mdc-notched-outline">
																		<span className="mdc-notched-outline__leading"></span>
																		<span className="mdc-notched-outline__notch">
																			<span className="mdc-floating-label" id="my-label-id">Title</span>
																		</span>
																		<span className="mdc-notched-outline__trailing"></span>
																	</span>
																</label>
															</div>
														</div>

														<div className="col-lg-4 col-md-6">
															<div className = "calendar-wrap">
					                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>calendar("published")}>
					                                                <input value={publishDate} onkeypress="return false;" type="text" className={publishDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
					                                                 required autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
					                                                <span className="mdc-notched-outline">
					                                                <span className="mdc-notched-outline__leading"></span>
					                                                <span className="mdc-notched-outline__notch">
					                                                    <span className="mdc-floating-label" id="my-label-id">Publish Date</span>
					                                                </span>
					                                                <span className="mdc-notched-outline__trailing"></span>
					                                                <span className="material-icons">calendar_today</span>
					                                                </span>
					                                            </label>	
					                                            {
					                                                showCalendar === true ?
					                                                <Calendar
																	  minDate={new Date()}
					                                                  dateFormat="ddd"
					                                                  onChange={(date)=>calendarHandler(date,"published")}
					                                                />
					                                                :
					                                                null
					                                            }
					                                        </div>
					                                    </div>

					                                    <div className="col-lg-4 col-md-6">
															<div className = "calendar-wrap">
					                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>calendar("archived")}>
					                                                <input value={archievedDate} onkeypress="return false;" type="text" className={archievedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
					                                                 required autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
					                                                <span className="mdc-notched-outline">
					                                                <span className="mdc-notched-outline__leading"></span>
					                                                <span className="mdc-notched-outline__notch">
					                                                    <span className="mdc-floating-label" id="my-label-id">Archived Date</span>
					                                                </span>
					                                                <span className="mdc-notched-outline__trailing"></span>
					                                                <span className="material-icons">calendar_today</span>
					                                                </span>
					                                            </label>	
					                                            {
					                                                showArchiveCalendar === true ?
					                                                <Calendar
																	  minDate={new Date()}
					                                                  dateFormat="ddd"
					                                                  onChange={(date)=>calendarHandler(date,"archive")}
					                                                />
					                                                :
					                                                null
					                                            }
					                                        </div>
					                                    </div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-12">
													<div className="outlined-textfield-wrap mt-25">
														<div className="material-textfield">
															<textarea value={noticeNotes} onChange={onChangeNoticesHandler} id="message" className={noticeNotes !== '' ? 'form__field active' : 'form__field'} placeholder="" rows="6"></textarea>
															<label>Additional Information</label>
														</div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-12 mt-25 upload-doc-block">
													<div className="row">
														<div className="col-lg-6 col-md-6">
															<label className="mdc-text-field mdc-text-field--outlined">
																<input accept="image/jpg,image/jpeg,image/png" multiple onChange={addImageHandler} readOnly type="file" id="add-photo" className="mdc-text-field__input" aria-labelledby="my-label-id" />
																<span className="mdc-notched-outline">
																	<span className="mdc-notched-outline__leading"></span>
																	<span className="mdc-notched-outline__notch">
																		<span className="mdc-floating-label" id="my-label-id">{addImageName ? addImageName : "Add Photo"}</span>
																	</span>
																	<span className="mdc-notched-outline__trailing"></span>
																</span>
																<span className="material-icons">image</span>
															</label>
															<small className="short-description">Image can only be of the following types : jpg, jpeg, png with limit upto 5MB</small>
															<>
															{
																addImagePath.length>0 ? 
																	addImagePath.map((data,index)=>
																	{
																		return(
																		<>
																			<img height="100" width="100" src = {data.path} />
																			<button type="button" onClick={(e)=>{removeSpecificImage(index, data.id)}} ><span className="material-icons close">close</span></button>
																		</>
																		)
																	})
																
																:
																null
															}
															</>
														</div>

													</div>	
												</div>
											</div>

											{
												multipleUrls.length > 0 ?
													multipleUrls.map((data,index)=>
													{
														return(
														<div className="row">
															<div className="col-lg-6 col-md-6">
																<div className="outlined-textfield-wrap">
																	<label className="mdc-text-field mdc-text-field--outlined">
																		<input value = {data.uri}
																			onChange={(e)=>onChangeUrlHandler(e, index)} 
																			type="text" 
																			className={ data.uri ? "mdc-text-field__input active": "mdc-text-field__input"} 
																			aria-labelledby="my-label-id" />
																		<span className="mdc-notched-outline">
																			<span className="mdc-notched-outline__leading"></span>
																			<span className="mdc-notched-outline__notch">
																				<span className="mdc-floating-label" id="my-label-id">Url</span>
																			</span>
																			<span className="mdc-notched-outline__trailing"></span>
																		</span>
																	</label>
																</div>
															</div>
															{
																index > 0 ?
																	<button type="button" className="mt-25" onClick={()=>removeUrlField(index)}><span className="material-icons close">close</span></button>
																:
																null
															}
															
														</div>
														)
													})
												:
												null
											}

											<div className="row">
											 <button className="mdc-button mdc-button--raised mt-25" type="button" onClick={addUrlField}>Add More Urls</button>
											</div>

											<button className="mdc-button mdc-button--raised mt-25">
                                    			<span className="mdc-button__label">Submit</span>
                                    		</button>
										</form>
									</div>	
								</div>	
							</div>
						</div>		
					</div>	
				</div> 
			</div>	 
    	)
}

export default EditNotice;
