import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getNotificationCountAPI, logoutUser } from '../../functions/api';
import { $ } from 'react-jquery-plugin';
import { Link } from 'react-router-dom';
import { getSpecificUser, uploadImage, updateUserImage, getNewNotifications, getUserProfileData } from '../../functions/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import NotificationPopup from '../../commonComponents/NotificationPopup/NotificationPopup';
import LoaderComponent from '../../commonComponents/LoaderComponent/LoaderComponent'

//Import Images
import logo from '../../images/logo.svg';
import camera from '../../images/camera.png';
import user2 from '../../images/user2.png';

let previousLength = 0

const HeadOfficeMainHeader = () => {

  let user_id = window.localStorage.getItem('uid');
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0)
  const [username, setUsername] = useState();
  const [profileId, setProfileId] = useState();
  const [userpicture, setUserpicture] = useState();
  const tabsValue = [
    { link: '/co-ordination-unit-dashboard', icon: 'dashboard', name: 'Dashboard' },
    { link: '/m&s', icon: 'event_note', name: 'M & S' },
    { link: '/educators-list', icon: 'manage_accounts', name: 'Educators' },
    { link: '/coordinators-list', icon: 'manage_accounts', name: 'Office' },
    { link: '/notices', icon: 'task', name: 'Notices' },
    { link: '/library', icon: 'folder', name: 'Locker' },
    { link: '/link', icon: 'assignment', name: 'Link' },
    { link: '/tasks', icon: 'task', name: 'Tasks' },
    { link: '/cabt-list', icon: 'manage_accounts', name: 'CaBT' }]
  const [allNotifications, setAllNotifications] = useState([])
  const [notificationPopup, setNotififcationPopup] = useState(false)
  const [ajaxLoader, setAjaxLoader] = useState(false);
  const [count, setCount] = useState('')

  useEffect(() => {
    // let userSavedPic = window.localStorage.getItem("user_picture")
    // if (userSavedPic) {
    //   setUserpicture(userSavedPic);
    // }
    getInitialValues()
    getNotificationCount()

    $(".notification-block,.user-block").hover(function () {
      var isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children(".common-listing-block").stop().slideDown(300);
      } else {
        $(this).children(".common-listing-block").stop().slideUp(300);
      }
    });

    $(".mobile-toggel-icon").click(function () {
      $(".header-bar .right-block .menu-list").slideToggle();
    });

    let pathname = window.location.pathname;

    if (pathname.includes("co-ordination-unit-dashboard")) { setActiveTab(0) }
    else if (pathname.includes("m&s") || pathname.includes("m&s-detail-page") || pathname.includes("add-new-m&s")) { setActiveTab(1) }
    else if (pathname.includes("educators-list") || pathname.includes("educators-profile")) { setActiveTab(2) }
    else if (pathname.includes("coordinators-list")) { setActiveTab(3) }
    else if (pathname.includes("notices")) { setActiveTab(4) }
    else if (pathname.includes("library")) { setActiveTab(5) }
    else if (pathname.includes("link") || pathname.includes("link-detail-page")) { setActiveTab(6) }
    else if (pathname.includes("tasks") || pathname.includes('task-detail-page') || pathname.includes("add-new-task") || pathname.includes('edit-task')) { setActiveTab(7) }

    async function getNotificationApiInitialData() {
      let responseData = await getNewNotifications(user_id)
      if (responseData.status === 200) {
        if (responseData.data !== undefined && responseData.data.data.length > 0) {
          if (responseData.data.data.length !== previousLength) {
            const filteredData = responseData.data.data.filter(item => item.content_type === "leave")
            setAllNotifications(filteredData)
            previousLength = responseData.data.data.length
          }
        }
      }  else {
        setAllNotifications([])
      }
    }
    getNotificationApiInitialData()
    // eslint-disable-next-line
  }, [allNotifications.length])

  async function getInitialValues() {

    let auth_token = window.localStorage.getItem('auth_token');
    let user_response = await getSpecificUser(user_id, auth_token);
    if (
      user_response?.status === 200 &&
      user_response?.data?.data?.field_first_name?.[0]?.value
    ) {
      const firstName = user_response.data.data.field_first_name[0].value;
      const lastName = user_response.data.data.field_last_name?.[0]?.value ?? '';
      setUsername(firstName + (lastName ? ' ' + lastName : ''));
    }

    const headOfficeTeamProfiles = user_response?.data?.data?.head_office_team_profiles;
    if (headOfficeTeamProfiles && headOfficeTeamProfiles[0]?.target_id) {
      const profileId = headOfficeTeamProfiles[0].target_id;
      setProfileId(profileId);

      const profile_response = await getUserProfileData(profileId, auth_token);
      if (profile_response.status === 200) {
        const profileData = profile_response?.data?.data;
        const profilePicture = profileData?.field_profile_picture?.[0]?.url || '';
        if (profilePicture) {
          setUserpicture(profilePicture);
          window.localStorage.setItem('user_picture', profilePicture);
        }
      }
    }
  }

  const showNotificationPopup = () => {
    if (notificationPopup === true) {
      setNotififcationPopup(false)
    } else {
      setNotififcationPopup(true);
    }
  }

  const logout = async () => {
    setAjaxLoader(true)
    let logoutResponse = await logoutUser()
    if (logoutResponse.status === 200) {
      setAjaxLoader(false)
      window.localStorage.clear();
      history.push("/")
    }
    else {
      setAjaxLoader(false)
    }
  }


  const reRun = (data) => {
    if (data) {
      getNotificationCount()
    }
  }

  const getNotificationCount = async () => {
    const response = await getNotificationCountAPI(user_id);
    if (response.status === 200) {
      setCount(response.data.data.data)
    }
  }

  const navigateUser = (e, value) => {
    e.preventDefault()

    if (value === "link") {
      history.push("/link")
    }

    if (value === "notices") {
      history.push("/notices")
    }

    if (value === "edit") {
      history.push(`/co-ordination-unit-edit-profile/${user_id}`)
    }

  }

  async function updateImage(e) {

    if (e.target.value.length > 0) {
      if (e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') {
        toast.error('Invalid Image Type. Images can only be of the following types : jpg, jpeg, png', {
          position: 'top-center'
        });
        return false;
      } else {
        if (e.target.files[0].size > 2000000) {
          toast.error('File size exceeds the limit of 2MB', {
            position: 'top-center'
          });
          return false;
        } else {
          var reader = new FileReader();
          reader.onload = await function (ev) {
            setUserpicture(ev.target.result)
            uploadUserImage(e.target.files[0].name, ev.target.result)
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }
  }

  const uploadUserImage = async (name, path) => {
    let imageRes = await uploadImage(name, path)
    if (imageRes.status === 201) {
      if (imageRes.data.data !== undefined && imageRes.data.data !== '') {
        if (imageRes.data.data.fid !== undefined && imageRes.data.data.fid !== '') {
          let fid = imageRes.data.data.fid[0] ? imageRes.data.data.fid[0].value : ''
          if (fid !== '') {
            // Update UserImage
            let data = { image: fid }
            let res = await updateUserImage(profileId, data);
            if (res.status === 200) {
              toast.success("Profile Image Updated Successfully!!", {
                position: 'top-center'
              });
              window.localStorage.removeItem('updateUserImagePath')
            } else {
              toast.error(res.message, {
                position: 'top-center'
              });
            }
          }
        }
      }
    } else {
      toast.error(imageRes.message, {
        position: 'top-center'
      });
    }
  }

  const goBack = (url) => {
    window.localStorage.removeItem('folderParentId');
    history.push(url)
    if (url === '/library') {
      // window.location.reload()
    }
  }

  return (
    <>
      <LoaderComponent displayLoader={ajaxLoader} />
      <div className="top-bar header-bar">
        <div className="container">
          <Link to={`/co-ordination-unit-dashboard`} className="main-logo"><img src={logo} alt='' /></Link>
          <div className="right-block">
            <ul className="menu-list">
              <>
                {
                  tabsValue.map((items, index) =>
                    <li key={index} className={activeTab === index ? "active" : ""}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => goBack(items.link)}>
                        <span className="material-icons">{items.icon}</span>
                        {items.name}
                      </a>
                    </li>
                  )
                }
              </>
            </ul>
            <div onClick={showNotificationPopup} className="notification-block">
              <span className="material-icons notify-icon">
                notifications_active
              </span>
              {count ?
                <span className="count-field">
                  {count}
                </span> : null
              }
              {notificationPopup === true ?
                <div className="common-listing-block-notifications">
                  <NotificationPopup data={allNotifications} rerun={reRun} />
                </div> : null
              }
            </div>
            <div className="user-block">
              <figure className="user-media"><img src={userpicture ? userpicture : user2} alt='' /></figure>
              <div className="common-listing-block HeadOfficeMainHeader">
                <div className="user-media-row">
                  <div className="media">
                    <input onChange={(e) => updateImage(e)} type="file" accept="image/jpg,image/png,image/jpeg" />
                    <img src={userpicture ? userpicture : user2} alt='' />
                    <img className="small-thumb" src={camera} alt='' />
                  </div>
                  <div className="media-info">
                    <h2>{username}</h2>
                  </div>
                </div>
                <ul className="mdc-list mdc-list--two-line">
                  <li className="mdc-list-item" tabIndex="0" onClick={(e) => navigateUser(e, "edit")}>
                    <Link to={`/co-ordination-unit-edit-profile/${user_id}`}><span className="material-icons">account_circle</span></Link>
                    <span className="mdc-list-item__text">
                      {/* eslint-disable-next-line */}
                      <a className="link-wrap">
                        <span className="mdc-list-item__primary-text">Co-ordinator Information</span>
                        <span className="mdc-list-item__secondary-text">Co-ordinator Details</span>
                        <span className="material-icons arrow-forward">arrow_forward_ios</span>
                      </a>
                    </span>
                  </li>
                  <li className="mdc-list-item logout" style={{ cursor: 'pointer' }} onClick={logout}>
                    <span className="material-icons">logout</span>
                    <span className="mdc-list-item__text">
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0)" className="link-wrap">
                        <span className="mdc-list-item__primary-text">Logout</span>
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <span class="material-icons mobile-toggel-icon">menu</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeadOfficeMainHeader;