import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleEducatorSignupForm } from '../../../redux/actions/auth';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Import Images
import profilepic from '../../.././images/profile-pic.png';



const BasicDetails=() => {

    const dispatch = useDispatch(); 
    const [value, setValue] = useState(new Date());
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [visibilityPasswordIcon, setVisibilityPasswordIcon] = useState('visibility_off')
    const [passwordVisibility, setPasswordVisibility] = useState('password')
    const [visibilityConfirmPasswordIcon, setVisibilityConfirmPasswordIcon] = useState('visibility_off')
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState('password');

    //create account form states
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [userName,setUserName] = useState('');
    const [profileImagePath,setProfileImagePath] = useState("");
    const [profileImageName,setProfileImageName] = useState("");
    const [phonenumber,setPhonenumber] = useState('');
    const [homeNumber,setHomeNumber] = useState('');
    const [suburbName,setSuburbName] = useState('');
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [uploadingNewImage, setUploadingNewImage] = useState(false)
    const [selectedDate, setSelectedDate] = useState('');  // dob
    const [address , setAddress] = useState('')
    const [postcode , setPostcode] = useState('')
    // form error states
    const [isPasswordSame, setIsPasswordSame] = useState(null)


    useEffect(()=>{
        dispatch(handleEducatorSignupForm(1))

        // setProfileImagePath(profilepic)

        function getInitialData()
        {
            if(window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined)
            { 
                let signupFormDetail = JSON.parse(window.localStorage.getItem('educatorSignupDetail'))
                if(signupFormDetail[0] !== null && signupFormDetail[0] !== undefined)
                {
                  setSuburbName(signupFormDetail[0].basicDetail.suburbName)
                  setHomeNumber(signupFormDetail[0].basicDetail.homeNumber)
                    setUserName(signupFormDetail[0].basicDetail.name)
                    setFirstName(signupFormDetail[0].basicDetail.firstName)
                    setLastName(signupFormDetail[0].basicDetail.lastName)
                    setPhonenumber(signupFormDetail[0].basicDetail.contact)
                    setEmail(signupFormDetail[0].basicDetail.email)
                    setPassword(signupFormDetail[0].basicDetail.password)
                    setConfirmPassword(signupFormDetail[0].basicDetail.password)
                    setSelectedDate(signupFormDetail[0].basicDetail.dob)
                    setProfileImagePath(signupFormDetail[0].basicDetail.imageData)
                    setProfileImageName(signupFormDetail[0].basicDetail.imageName)
                    setAddress(signupFormDetail[0].basicDetail.address)
                    setPostcode(signupFormDetail[0].basicDetail.postcode)
                }

            }
        }

        getInitialData()

        function validateForm()
        {
            var firstnameValidate = document.getElementById("firstName");
            if(firstnameValidate !== null){
              firstnameValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("First Name can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var lastNameValidate = document.getElementById("lastName");
            if(lastNameValidate !== null){
              lastNameValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Last Name can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var usernameValidate = document.getElementById("userName");
            if(usernameValidate !== null){
              usernameValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Username Name can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var contactValidate = document.getElementById("phonenumber");
            if(contactValidate !== null){
              contactValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Mobile number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var homeNumberValidate = document.getElementById("homeNumber");
            if(homeNumberValidate !== null){
              homeNumberValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Home number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var emailValidate = document.getElementById("email");
            if(emailValidate !== null){
              emailValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Email can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var passwordValidate = document.getElementById("password");
            if(passwordValidate !== null){
              passwordValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Password can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var addressValidate = document.getElementById("address");
            if(addressValidate !== null){
              addressValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Address can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var confirmPasswordValidate = document.getElementById("confirmPassword");
            if(confirmPasswordValidate !== null){
              confirmPasswordValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Confirm Password can't be empty");
                }
                else if(e.target.value !== "")
                {
                  e.target.setCustomValidity("Password and Confirm Password should be same");
                }
                else 
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var dobValidate = document.getElementById("dob");
            if(dobValidate !== null){
              dobValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Dob can't be empty");
                }
                else 
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var suburbNameValidate = document.getElementById("suburbName");
            if(suburbNameValidate !== null){
              suburbNameValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Suburb name can't be empty");
                }
                else 
                {
                  e.target.setCustomValidity("");
                }
              };
            }
        }

        validateForm()

    },[])

    const changeHandler=(e, name, value) => {
        
        if(name=='firstName')
        {
            setFirstName(value);
            // setUserName(value+lastName)
            e.target.setCustomValidity("")
        }
        else if(name=='lastName')
        {
            setLastName(value);
            // setUserName(firstName+value)
            e.target.setCustomValidity("")
        }
        else if(name=='phonenumber')
        {
            setPhonenumber(value.replace(/\D/g, ""));
            e.target.setCustomValidity("")
        }
        else if(name=='homeNumber')
        {
            setHomeNumber(value.replace(/\D/g, ""));  
            e.target.setCustomValidity("")
        }
        else if(name=='suburbName')
        {
            setSuburbName(value);  
            e.target.setCustomValidity("")
        }
        else if(name=='email')
        {
            setEmail(value);
            setUserName(value)
            e.target.setCustomValidity("")
        }
        else if(name=='password')
        {
            setPassword(value);
            if(value !== "")
            {
               if(value !== confirmPassword)
                {
                    setIsPasswordSame(false)
                }
                else
                {
                   setIsPasswordSame(true)
                } 
                e.target.setCustomValidity("")
            }
            
        }
        else if(name=='confirmPassword')
        {
            setConfirmPassword(value); 
            if(value !== password)
            {
                setIsPasswordSame(false)
            }
            else
            {
               setIsPasswordSame(true)
            }
            e.target.setCustomValidity("")
        }

        else if(name=='address')
        {
            setAddress(value)
            e.target.setCustomValidity("")
        }
        else if(name=='postcode')
        {
            setPostcode(value)
            e.target.setCustomValidity("")
        }

       
    }

    const onChangeDate=(newDate)=>
    {     
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year
           
        setSelectedDate(fullYear)
        setDisplayDatePicker(false)
    }

    const uploadImage=(e)=>
    {
        if (e.target.value.length > 0) {
          if(e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png'){
            toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
				      position: 'top-center'
			      });
			      return false;
          }else{
            if(e.target.files[0].size > 5000000){
              toast.error('Image size exceeds the limit of 5MB', {
				      position: 'top-center'
			      });
			        return false;
            }else{
              setUploadingNewImage(true)
            }
          }
        }

        if (e.target.files && e.target.files[0]) {

            setProfileImageName(e.target.files[0].name)  
            var reader = new FileReader();

            reader.onload = function (event) {
                setProfileImagePath(event.target.result)
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onClickImageSelection = async(e) => {
    if (e.target.value.length == 0) {
      setUploadingNewImage(false)
    }
  }

    const validateFields=()=>
    {
        if(profileImagePath !== "")
        {
            if(password === confirmPassword)
            {
                return true
            }
            else
            {
                toast.error("Password and Confirm Password profile picture",{ position: 'top-center'});
                return false
            }
        }
        else
        {
            toast.error("Please select profile picture",{ position: 'top-center'});
            return false
        }
    }

    const goNext=async(e) => {
        e.preventDefault();
        let res = validateFields()
        if(res === true)
        {
            let basicDetailData = { "basicDetail": { firstName:firstName, lastName:lastName, name:userName, contact:phonenumber, email:email, password:password, dob:selectedDate, 
            imageData: profileImagePath, imageName: profileImageName, uploadingNewImage:uploadingNewImage, 
            role:"educator",homeNumber: homeNumber, suburbName:suburbName , address : address , postcode :postcode }}
            dispatch(handleEducatorSignupForm(2, basicDetailData, 0))
        }
    }

    const showHideCalender=()=>
    {
        if(displayDatePicker === true)
        {
            setDisplayDatePicker(false)
        }
        else
        {
            setDisplayDatePicker(true)
        }
    }

    const showPassword=() => {
      if(visibilityPasswordIcon === 'visibility_off'){
        setVisibilityPasswordIcon('visibility');
        setPasswordVisibility('text')
      }

      if(visibilityPasswordIcon === 'visibility'){
        setVisibilityPasswordIcon('visibility_off');
        setPasswordVisibility('password')
      }
    }

    const showConfirmPassword=() => {

      if(visibilityConfirmPasswordIcon === 'visibility_off'){
        setVisibilityConfirmPasswordIcon('visibility');
        setConfirmPasswordVisibility('text')
      }

      if(visibilityConfirmPasswordIcon === 'visibility'){
        setVisibilityConfirmPasswordIcon('visibility_off');
        setConfirmPasswordVisibility('password')
      }

    }

    const updateUserName=(e)=>
    {
        setUserName(e.target.value)
    }


    return (
        <>
            <ToastContainer />
            <div className="main-wrapper">
                <div className="container">
                    <div className="user-pages-wrap">
                        <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="user-types-wrap text-center">
                                <h1 className="common-heaidng48">Create An Account</h1>
                                <h3 className="small-title">Welcome! Sign up using email to continue.</h3>
                                <div className="add-profile-pic">
                                    {
                                        profileImagePath === "" ? 
                                          <p className="caption" id="uploadImage">Add Profile Photo*</p>
                                        :
                                        null
                                    }
                                    <div class="profile-pic-inner">
                                      {
                                        profileImagePath ?
                                        <>
                                          <img src={profileImagePath} id="userImage" alt="user image"/>
                                          <input type="file" id="uploadImage" name="uploadImage" accept="image/jpg,image/png,image/jpeg" onChange={(e)=>uploadImage(e)} onClick={(e)=>onClickImageSelection(e)} style={{opacity:0,position:'absolute'}}/>
                                        </>
                                        :
                                        <>
                                          <img src={profilepic} id="userImage" alt="user image"/>
                                          <input type="file" id="uploadImage" name="uploadImage" accept="image/jpg,image/png,image/jpeg" onChange={(e)=>uploadImage(e)} onClick={(e)=>onClickImageSelection(e)} style={{opacity:0,position:'absolute'}}/>
                                          
                                        </>
                                      }
                                      </div>
                                      <small className="short-description mt-5">Image can only be of the following types : jpg, jpeg, png with limit upto 5MB</small>
                                </div>
                                <form className="sign-up-form" onSubmit = {(e) => goNext(e)}>
                                  <div className="row">
                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="20" className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" onChange={(e) => changeHandler(e,'firstName', e.target.value)} value = {firstName} name="firstName" 
                                            id="firstName" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>	
                                    </div>

                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="20" className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" onChange={(e) => changeHandler(e,'lastName', e.target.value)} value = {lastName} name="lastName" 
                                            id="lastName" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>    
                                    </div>

                                    <div className="col-lg-4 col-md-4 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={showHideCalender}>
                                                <input value={selectedDate} onkeypress="return false;" type="text" className={selectedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id="dob" required autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>  
                                            {
                                                displayDatePicker === true ?
                                                <Calendar
                                                  maxDate={new Date()}
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input className={phonenumber ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" onChange={(e) => changeHandler(e,'phonenumber', e.target.value)} 
                                            value = {phonenumber} name="phonenumber" maxlength="15" id="phonenumber" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Mobile*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>  
                                    </div>

                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="15" className={homeNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text"  onChange={(e) => {changeHandler(e,'homeNumber', e.target.value)}} value= {homeNumber} name="homeNumber" id="homeNumber" aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Home Number*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>   
                                    </div>


                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input className={email ? "mdc-text-field__input active" : "mdc-text-field__input"} type="email" onChange={(e) => changeHandler(e,'email', e.target.value)} 
                                            value = {email} name="email" id="email" maxlength="25" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />  
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Business Email Address*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>  
                                    </div>


                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="30" type="text" className={userName ? "mdc-text-field__input active" : "mdc-text-field__input"} required onChange={updateUserName} value= {userName} name="userName" id="userName" aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" >Username*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>
                                    </div>

                                    
                                    
                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input className={password ? "mdc-text-field__input active" : "mdc-text-field__input"} type={passwordVisibility} onChange={(e) => changeHandler(e,'password', e.target.value)} 
                                            value = {password} maxlength="15"  id="password" name="password" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Password*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                              <span onClick={showPassword} class="material-icons">{visibilityPasswordIcon}</span>
                                        </label>
                                        
                                    </div>
                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input className={confirmPassword ? "mdc-text-field__input active" : "mdc-text-field__input"} type={confirmPasswordVisibility} onChange={(e) => changeHandler(e,'confirmPassword', e.target.value)} 
                                            value = {confirmPassword} maxlength="15"  id="confirmPassword" name="confirmPassword" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")}/>
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Confirm Password*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                            <span onClick={showConfirmPassword} class="material-icons">{visibilityConfirmPasswordIcon}</span>
                                        </label>    
                                        {
                                            isPasswordSame === false ? <span>confirm password is not same as input password</span> : null
                                        }
                                    </div>

                               
                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input  className={address ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" required onChange={(e) => {changeHandler(e,'address', e.target.value)}} value= {address} name="address" id="address" aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Street Address*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>   
                                    </div>

                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="25" className={suburbName ? "mdc-text-field__input active" : "mdc-text-field__input"} required onChange={(e) => {changeHandler(e,'suburbName', e.target.value)}} type="text" value= {suburbName} name="suburbName" id="suburbName" aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Suburb Name</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>    
                                    </div>

                                    <div className="col-md-4 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input  className={postcode ? "mdc-text-field__input active" : "mdc-text-field__input"} type="number" required onChange={(e) => {changeHandler(e,'postcode', e.target.value)}} value= {postcode} name="postcode" id="postcode" aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Postcode*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>   
                                    </div>
                                    
                                    
                                    </div>
                                    <button className="mdc-button mdc-button--raised green-btn btn-large">
                                    <span className="mdc-button__label">next</span>
                                    </button>
                                </form>
                                
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default BasicDetails;