import React, { useState, useEffect } from 'react';
// import { baseUrl } from '../../config';
import { getAllEducators, searchEducators, sortEducators, searchEducatorsBySuburb, getArchiveEducators } from "../../../functions/api";
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import EducatorsList from '../../../commonComponents/CommonEducatorsListComponent/EducatorsList';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from '@material-ui/core/Select';

//Import Images
import line from '../../../images/line.png';
import user1 from '../../../images/user1.png';



const EducatorsListPage = (props) => {

	const [searchPlaceHolderValue, setSearchPlaceHolderValue] = useState('Type Educator name...');
	const [educatorsData, setEducatorsData] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [showLoader, setShowLoader] = useState(true)
	const [sortedValue, setSortedValue] = useState('ASC')
	const [isArchiveOrNot, setIsArchiveOrNot] = useState(false);
	const [searchData, setSearchData] = useState([])

	const StyledMenu = withStyles({
		paper: {
			border: '1px solid #d3d4d5',
		},
	})((props) => (
		<Menu elevation={0} getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		/>
	));

	const StyledMenuItem = withStyles((theme) => ({
		root: {
			'&:focus': {
				backgroundColor: theme.palette.primary.main,
				'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
					color: theme.palette.common.white,
				},
			},
		},
	}))(MenuItem);

	useEffect(() => {

		$(function () {
			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
				if ($(this).val().length > 0) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			})
		})


		getInitialData()

	}, [props, searchPlaceHolderValue])

	async function getInitialData() {
		setIsArchiveOrNot(false);
		let value = sortedValue === 'Archive' ? 'archive' : sortedValue
		let response = await sortEducators(value)
		if (response.status === 200) {
			setShowLoader(false)
			if (response.data.data.length > 0) {
				setEducatorsData(response.data.data);
			}
		}else {
			setShowLoader(false)
		}
	}

	async function search(inputValue) {
		let value = sortedValue === 'Archive' ? 'archive' : sortedValue
		let response = await sortEducators(value,inputValue)
		if (response.status === 200) {
			if (response.data !== undefined && response.data !== '') {
				if (response.data.data.length > 0) {
					setEducatorsData(response.data.data);
				} else {
					setEducatorsData([])
				}
			}
		} else {
			toast.error(response.message, {
				position: 'top-center'
			});
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	async function sorting(sort) {
		if (sort === 'ASC') {
			setSortedValue('ASC')
		}
		if (sort === 'DESC') {
			setSortedValue('DESC')
		}
		if (sort === 'archive') {
			setSortedValue('Archive')
			setIsArchiveOrNot(true);
		} else {
			setIsArchiveOrNot(false);
		}
		setShowLoader(true)
		let response = await sortEducators(sort)
		if (response.status === 200) {
			setShowLoader(false)
			if (response.data.data.length > 0) {
				setEducatorsData(response.data.data);
			} else {
				setEducatorsData([])
				setShowLoader(false)
			}
		} else {
			setShowLoader(false)
		}
	}

	return (
		<>
			<ToastContainer />
			<LoaderComponent displayLoader={showLoader} />
			<HeadOfficeMainHeader />
			<div className="main-wrapper mtb-70">
				<div className="container">
					<div className="dashboard-wrap">
						<div className="row">
							<div className="col-md-12">
								<div className="content-block-right">
									<div className="notice-listing-block educator-list-page-header">
										<div className="common-header-row">
											<h4>Educators</h4>

											<div className="block-right">
												{/* Sorting Button  */}
												<button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' >
													<span className="mdc-button__label"><a target={"_blank"} href={`https://wiseappbackend.thecorella.com/view/update/all_user_csv`} style={{ color: '#d9d9d9' }}>Export Educator Report</a></span>
												</button>
												<button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' >
													<span className="mdc-button__label"><a target={"_blank"} href={`https://wiseappbackend.thecorella.com/csv/mentoring_file/educator`} style={{ color: '#d9d9d9' }}>Export M&S Report</a></span>
												</button>
												<button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' >
													<span className="mdc-button__label"><a target={"_blank"} href={`https://wiseappbackend.thecorella.com/csv/professional_file/educator`} style={{ color: '#d9d9d9' }}>Export PD Report</a></span>
												</button>


												<Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={sortedValue}>
													<MenuItem value="ASC" onClick={() => sorting('ASC')}>A-Z</MenuItem>
													<MenuItem value="DESC" onClick={() => sorting('DESC')}>Z-A</MenuItem>
													<MenuItem value="Archive" onClick={() => sorting('archive')}>Archived Educator</MenuItem>
												</Select>
												<div className="search-block-content">
													<label className="mdc-text-field mdc-text-field--outlined search-educators">
														<input onChange={(e) => search(e.target.value)} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
														<span className="mdc-notched-outline">
															<span className="mdc-notched-outline__leading"></span>
															<span className="mdc-notched-outline__notch">
																<span className="mdc-floating-label" id="my-label-id">Search By First Name</span>
															</span>
															<span className="mdc-notched-outline__trailing"></span>
														</span>
														<span className="material-icons">saved_search</span>
													</label>
												</div>
											</div>
										</div>
										<div className="task-listing task-list-with-media">
											{/* {console.log("educatorsData", educatorsData)
											} */}
											{
												educatorsData && educatorsData.length ?
													<EducatorsList data={educatorsData} isArchive={isArchiveOrNot} />
													:

													<label> No Records Found!!</label>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EducatorsListPage;
