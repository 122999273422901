import React,{ useState, useEffect } from 'react';
import BasicDetails from './BasicDetails';
import HeaderWithoutLogin from '../../../commonComponents/HeaderWithoutLogin/HeaderWithoutLogin';
import BusinessInfo from './BusinessInfo';
import CourseDetails from './CourseDetails';
import Insurance from './Insurance';
import Vehicle from './Vehicle';
import ResidentialOccupants from './ResidentialOccupants';
import { useDispatch, useSelector } from 'react-redux';
import { $ } from 'react-jquery-plugin';

const EducatorSignup=(props)=> {

    const store = useSelector(state=>state.auth)
    const[next, setNext] = useState(1);

  useEffect(()=>
  {
    function getStateValues()
    {
      if(store.page !== undefined)
      {
        setNext(store.page)
      }
    }
    getStateValues()
    
    $(function (){
          $('.mdc-text-field--outlined .mdc-text-field__input').on('focusout', function(){
            if ($ (this).val().length > 0) {
              $ (this).addClass('active');
            }
            else {
              $ (this).removeClass('active');
            }
          })
        })
    
  },[store.page])

    return( 
       <div>
            <HeaderWithoutLogin />
            {
                next==1? 
                <BasicDetails />
                :
                next==2?
                <ResidentialOccupants />
                :
                next==3?
                <BusinessInfo />
                :
                next==4?
                <CourseDetails />
                :
                next==5?
                <Insurance />
                :
                <Vehicle />
            }
        </div>
               
    )
}

export default EducatorSignup;