import React, {useEffect, useState} from 'react';
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import './styles.css';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { getAllEducators, getSpecificUser, getLeaves, convertTimeStampToTimeCustom, taskTitleList, createContent } from '../../../functions/api';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { Link, useHistory } from 'react-router-dom';
import { createTask, updateNode } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImage } from '../../../functions/api';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import imageToBase64 from 'image-to-base64/browser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const AddBusiness = (props) => {

	const classes = useStyles();
    const regex = /(<([^>]+)>)/ig;

	const history = useHistory();
	const [businessName, setBusinessName] = useState('');
	const [ajaxLoader, setAjaxLoader] = useState(false);

	
	useEffect(() => {
		$('#chooseFile').bind('change', function () {
  			var filename = $("#chooseFile").val();
  			if (/^\s*$/.test(filename)) {
    			$(".file-upload").removeClass('active');
    			$("#noFile").text("No file chosen..."); 
  			}else{
    			$(".file-upload").addClass('active');
    			$("#noFile").text(filename.replace("C:\\fakepath\\", "")); 
  			}
		});

		$(function() { 
			$('.dropdown-toggle').click(function() { 
			$(this).next('.dropdown').slideToggle();
		});

		$(document).click(function(e) { 
			var target = e.target; 
			if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
				{ $('.dropdown').slideUp(); }
			});
		});

		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})

	},[]);

	const onChangeTextField=(e)=>
	{
		setBusinessName(e.target.value);
	}

	const validateFields=()=>
	{
		if(businessName === undefined || businessName === ''){
			toast.error('Business name is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}
		else
		{
			return true
		}
	}

	async function onSubmit(e){
		e.preventDefault();
		setAjaxLoader(true)
		let res = await validateFields()
		if(res === true)
		{
			let dataObject =	{
													"vid": [
												        {
												            "target_id": "business_name"
												        }
												    ],
													"name": [
												        {
												            "value": businessName
												        }
												    ] 
												}

			let createResponse = await createContent(dataObject)
			if(createResponse.status === 201)
			{
				setAjaxLoader(false);
				toast.success("Business name added successfully!!!", {
					position: 'top-center'
				});
				setBusinessName("")
			}
			else
			{
				setAjaxLoader(false);
				toast.error(createResponse.message, {
					position: 'top-center'
				});
			}
		}
  }


    return (
    <>
		<ToastContainer />
		<HeadOfficeMainHeader />
		<LoaderComponent displayLoader={ajaxLoader} />  
	    <div className="main-wrapper">
		    <div className="container">	
			    <div className="user-pages-wrap">
				    <div className="row">
						<div className="col-lg-12">
							<div className="user-types-wrap course-detail-page text-center">
								<div className="common-header-row common-header-with-large-text">
									<h4>New Business</h4>	
									<div className="block-right">
										<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
									</div>
								</div>

								<form onSubmit= {onSubmit} className="sign-up-form">
									<div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-lg-6 col-md-6">
													<label className="mdc-text-field mdc-text-field--outlined">
	                          <input maxlength="30" value={businessName} onChange={onChangeTextField} type="text" className={businessName !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'}aria-labelledby="my-label-id" />
											      <span className="mdc-notched-outline">
											        <span className="mdc-notched-outline__leading"></span>
											        <span className="mdc-notched-outline__notch">
											          <span className="mdc-floating-label" id="my-label-id">Business Name</span>
											        </span>
											        <span className="mdc-notched-outline__trailing"></span>
											      </span>
											    </label>
											  </div>	
											</div>
										</div>
									</div>

									<button className="mdc-button mdc-button--raised mt-5">
                    <span className="mdc-button__label">Submit</span>
                  </button>

								</form>
							</div>	
						</div>	
				    </div>
			    </div>		
		    </div>	
	    </div>
    </>
  )

}

export default AddBusiness;