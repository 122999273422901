import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, deleteRegisteredUser, automaticFolderGeneratorAPI } from '../../../functions/api';
import { registerUser, handleHeadOfficeSignupForm, getAuthToken, createProfile, login, setUserRole } from '../../../redux/actions/auth';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { $ } from 'react-jquery-plugin';
const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]


const BlueCard=(props)=> {

    const history = useHistory();
    const dispatch = useDispatch(); 
    const store = useSelector(state=>state.auth)
    const [value, setValue] = useState(new Date());
    const [dateField, setDateField] = useState('')

    // residential occupants
    const [blueCardNumber, setBlueCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [sightedDate, setSightedDate] = useState(''); 
    const [displayExpiryDatePicker, setDisplayExpiryDatePicker] = useState(false);
    const [displaySlightedDatePicker, setDisplaySlightedDatePicker] = useState(false);
    const [showLoader, setShowLoader] = useState(false)

    // New Field Addition
    const [blueCardReminder , setBlueCardReminder] = useState('')


    const [formAllData, setFormAllData] = useState([])
    const [selectedProfessionalDevelopmentCourse, setSelectedProfessionalDevelopmentCourse ]= useState("")
    const [data, setData] = useState([{status:"completed-tag",statusTitle:"COMPLETED"},{status:"inprogess-tag",statusTitle:"IN-PROGRESS"},
                                        {status:"completed-tag",statusTitle:"COMPLETED"},{status:"pending-tag",statusTitle:"PENDING"}])



    useEffect(()=>{
        $(function() { 
            $('.dropdown-toggle').click(function() { 
                $(this).next('.dropdown').slideToggle();
            });

            $(document).click(function(e) { 
            var target = e.target; 
            if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
              { $('.dropdown').slideUp(); }
            });
        });


        $(".mobile-toggel-icon").click(function(){
          $(".header-bar .right-block .menu-list").slideToggle();
        });
    },[])
    useEffect(()=>
    {
        if(window.localStorage.getItem('headOfficeSignupDetail') !== null && window.localStorage.getItem('headOfficeSignupDetail') !== undefined)
        {
          let storageValue = JSON.parse(window.localStorage.getItem('headOfficeSignupDetail'));
          setFormAllData(storageValue)
        }

        function getInitialData()
        {
            if(window.localStorage.getItem('headOfficeSignupDetail') !== null && window.localStorage.getItem('headOfficeSignupDetail') !== undefined)
            { 
                let signupFormDetail = JSON.parse(window.localStorage.getItem('headOfficeSignupDetail'))

                if(signupFormDetail[2] !== null && signupFormDetail[2] !== undefined)
                {
                    setBlueCardNumber(signupFormDetail[2].blueCardDetail.blueCardNumber)
                    setExpiryDate(signupFormDetail[2].blueCardDetail.expiryDate)
                    setSightedDate(signupFormDetail[2].blueCardDetail.sightedDate)
                }

            }
        }
        getInitialData()

        function validateForm()
        {
            var blueCardNumberValidate = document.getElementById("blueCardNumber");
            if(blueCardNumberValidate !== null){
              blueCardNumberValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Bluecard number can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var expiryDateValidate = document.getElementById("expiryDate");
            if(expiryDateValidate !== null){
              expiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Completion Date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }


            var sightedDateValidate = document.getElementById("sightedDate");
            if(sightedDateValidate !== null){
              sightedDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Sighted date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

        }
        
        validateForm()    

    },[])

    const onChangeDate=(newDate)=>
    {    
      let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
      let year = newDate.getFullYear()
      let date = newDate.getDate()
      let fullYear = date+"-"+month+"-"+year 

      // residential occupant
      if(dateField === "expiryDate")
      {
          setExpiryDate(fullYear)
          setDisplayExpiryDatePicker(false)
      }

      if(dateField === "sightedDate")
      {
          setSightedDate(fullYear)
          setDisplaySlightedDatePicker(false)
      }
      
      setDateField("")
    }

    const changeHandler=(e, name, value)=>
    {
        if(name==="blueCardNumber")
        {
            setBlueCardNumber(value.replace(/\D/g, ""))
            e.target.setCustomValidity("")
        }
        
    }

    const goBack=()=>
    {
       dispatch(handleHeadOfficeSignupForm(2))  
    }

    const showHideCalender=(field)=>
    {
        if(field === "expiryDate")
        {
            if(displayExpiryDatePicker === true)
            {
                setDisplayExpiryDatePicker(false);
            }
            else
            {
                setDisplayExpiryDatePicker(true);
            }
            
            setDateField("expiryDate")
        }

        if(field === "sightedDate")
        {
            if(displaySlightedDatePicker === true)
            {
                setDisplaySlightedDatePicker(false);
            }
            else
            {
                setDisplaySlightedDatePicker(true);
            }
            
            setDateField("sightedDate")
        }
    }

    const signup=async(e)=>
    {  
        e.preventDefault()
        setShowLoader(true)
        let registerResponseData = await dispatch(registerUser(formAllData[0]));
        if(registerResponseData.status === 200){

            // 
            const response = await automaticFolderGeneratorAPI(registerResponseData.data.uid[0].value);
            const message = response.data.data.msg;
            // 
            // toast.success(message);

            let username = formAllData[0].basicDetail.name;
            let password = formAllData[0].basicDetail.password;
            let authResponse = await dispatch(getAuthToken(username, password)) 
            if(authResponse.status === 200)
            {
                let imageName = formAllData[0].basicDetail.imageName
                let imageData = formAllData[0].basicDetail.imageData

                let imageRes = await uploadImage(imageName, imageData)
                let field_certificate_document, field_cpr_certificate,field_asthma_course_certificate, field_anaphylaxis_certificate;
                if(formAllData[1].courseDetails.firstAidImg.length > 0){
                    let firstImg = await uploadImage(formAllData[1].courseDetails.firstAidImg[0].name, formAllData[1].courseDetails.firstAidImg[0].path)
                    if(firstImg.status == 201){
                      field_certificate_document = firstImg.data.data.fid[0].value
                    }
                  }
                  if(formAllData[1].courseDetails.crpCourseImg.length > 0){
                    let cprImg = await uploadImage(formAllData[1].courseDetails.crpCourseImg[0].name, formAllData[1].courseDetails.crpCourseImg[0].path)
                    if(cprImg.status == 201){
                      field_cpr_certificate = cprImg.data.data.fid[0].value
                    }
                  }
                  if(formAllData[1].courseDetails.asthmaCourseImg.length > 0){
                    let asthmaImg = await uploadImage(formAllData[1].courseDetails.asthmaCourseImg[0].name, formAllData[1].courseDetails.asthmaCourseImg[0].path)
                    if(asthmaImg.status == 201){
                      field_asthma_course_certificate = asthmaImg.data.data.fid[0].value
                    }
                  }
                  if(formAllData[1].courseDetails.anaphlaxisImg.length > 0){
                    let anaphylaxisImg = await uploadImage(formAllData[1].courseDetails.anaphlaxisImg[0].name, formAllData[1].courseDetails.anaphlaxisImg[0].path)
                    if(anaphylaxisImg.status == 201){
                      field_anaphylaxis_certificate = anaphylaxisImg.data.data.fid[0].value
                    }
                }
                if(imageRes.status === 201)
                {
                    let _ab = formAllData[1].courseDetails.asthmaCourseExpiryDate

                    let _sightedDate = sightedDate ? moment(sightedDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _expiryDate = expiryDate ? moment(expiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _commencementDate = formAllData[0].basicDetail.commencementDate ? moment(formAllData[0].basicDetail.commencementDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _asthmaCourseExpiryDate = formAllData[1].courseDetails.asthmaCourseExpiryDate ? moment(formAllData[1].courseDetails.asthmaCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _crpCourseExpiryDate = formAllData[1].courseDetails.crpCourseExpiryDate ? moment(formAllData[1].courseDetails.crpCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _anaphylaxisCourseExpiryDate = formAllData[1].courseDetails.anaphylaxisCourseExpiryDate ? moment(formAllData[1].courseDetails.anaphylaxisCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _firstAidCourseExpiryDate = formAllData[1].courseDetails.firstAidCourseExpiryDate ? moment(formAllData[1].courseDetails.firstAidCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                    let _dob = formAllData[0].basicDetail.dob ? moment(formAllData[0].basicDetail.dob,'DD-MM-YYYY').format('YYYY-MM-DD') : null

                    let dataObject = {   
                      "type": "head_office_team",      
                   
                       "uid": [
                       {
                       "target_id": registerResponseData.data.uid[0].value
                       }
                       ],
                       "field_profile_picture" : [{"target_id" : imageRes.data.data.fid[0].value}],
                       "field_commencement_date" : [{ "value" : _commencementDate}],
                       "field_d_o_b" : [{ "value" : _dob}],
                       "field_asthma_course" : [{ "value" : formAllData[1].courseDetails.asthmaCourseCode}],  
                       "field_asth_course_complete_date" : [{ "value" : _asthmaCourseExpiryDate}],
                       "field_asthma_course_reminder" : [{ "value" : formAllData[1].courseDetails.asthmaCourseReminder}],  
                       "field_cpr_course" : [{ "value" : formAllData[1].courseDetails.crpCourseCode}],
                       "field_cpr_course_completion_date" : [{ "value" : _crpCourseExpiryDate}],
                        "field_cpr_course_reminder" : [{ "value" : formAllData[1].courseDetails.crpCourseReminder}],
                       "field_anaphylaxis_course" : [{ "value" : formAllData[1].courseDetails.anaphylaxisCourseCode}],
                       "field_anap_course_complete_date" :[{ "value" : _anaphylaxisCourseExpiryDate}],
                       "field_anaphylxis_course_reminder" :[{ "value" : formAllData[1].courseDetails.anaphylaxisCourseReminder}],
                       "field_first_aid_course" : [{ "value" : formAllData[1].courseDetails.firstAidCourseCode}],    
                       "field_first_aid_course_reminder" : [{ "value" : formAllData[1].courseDetails.firstAidCourseReminder}],
                        "field_first_aid_completion_date" : [{ "value" : _firstAidCourseExpiryDate}],          
                       "field_blue_card" : [{ "value" : blueCardNumber}], 
                       "field_addition_info" : [{ "value" : ""}],  
                       "field_signed_date"  : [{"value" : _sightedDate}],
                       "field_blue_card_expiry_date" : [{"value" : _expiryDate}],
                       "field_suburb_name":[{"value": formAllData[0].basicDetail.suburbName}],
                       "field_home_number":[{"value": formAllData[0].basicDetail.homeNumber}],
                       "field_first_expiry_date" : [{"value" :  formAllData[1].courseDetails.firstAidExpiry ? moment(formAllData[1].courseDetails.firstAidExpiry , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                       "field_cpr_course_expiry_date" : [{"value" : formAllData[1].courseDetails.cprExpiry ? moment(formAllData[1].courseDetails.cprExpiry , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                       "field_asthma_course_expiry" : [{"value" : formAllData[1].courseDetails.asthmaExpiry ? moment(formAllData[1].courseDetails.asthmaExpiry , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                       "field_anaphylaxis_course_expiry" : [{"value" : formAllData[1].courseDetails.anaphylaxisExpiry ? moment(formAllData[1].courseDetails.anaphylaxisExpiry, 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                       "field_first_aid_certificate" : [{"target_id" : field_certificate_document ? field_certificate_document : null}],
                       "field_cpr_course_certificate" : [{"target_id" : field_cpr_certificate ? field_cpr_certificate :null}],
                       "field_asthma_certificate": [{"target_id" : field_asthma_course_certificate ? field_asthma_course_certificate : null}],
                       "field_anaphylaxis_field_certific": [{"target_id" : field_anaphylaxis_certificate ? field_anaphylaxis_certificate : null}],
                       'field_blue_card_frequency':[{"value" : blueCardReminder ? blueCardReminder :blueCardReminder}]
     
                     }

                   let createProfileResponse = await dispatch(createProfile(authResponse.data,dataObject)) 
                   
                   if(createProfileResponse.status === 201)
                   {
                        setShowLoader(false)
                        let loginResponseData = await  dispatch(login(username, password))
                        if(loginResponseData == undefined){
                           toast.error("Login failed wrong user credentials.",{
                                position: 'top-center'
                           });
                        }else{
                            let userRole = window.localStorage.getItem('user_role');
                            if(userRole !== null || userRole !== ""){
                                 if(userRole === 'head_coordinator'){
                                    dispatch(setUserRole(2))
                                    history.push(`/co-ordination-unit-dashboard`);
                                }   
                            }else{
                                toast.error("User role not specified",{ position: 'top-center'});
                            }
                        }
                   }
                   else
                   {
                        setShowLoader(false)
                        toast.error("Profile Creation Issue: "+createProfileResponse.message,{ position: 'top-center'}); //signup  
                        await deleteRegisteredUser(registerResponseData.data.uid[0].value, authResponse.data)
                   }
                }
                else
                {
                    setShowLoader(false)
                    toast.error("Image Issue: "+imageRes.message,{ position: 'top-center'});  // auth api error message
                }
            }
            else
            {
                setShowLoader(false)
                toast.error("Auth token Issue: "+authResponse.message,{ position: 'top-center'});  // auth api error message
            }
        }
        else{
            setShowLoader(false)
            toast.error("Register Issue: "+registerResponseData.message,{ position: 'top-center'});
        }


    }

    return (
    <>
    <ToastContainer />
    <LoaderComponent displayLoader={showLoader} /> 
    <div className="main-wrapper">
        <div className="container"> 
            <div className="user-pages-wrap">
                <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="user-types-wrap course-detail-page text-center">
                                <h1 className="common-heaidng48">Blue Card</h1>
                                <h3 className="small-title">Please fill the required details</h3>

                                <form className="sign-up-form" onSubmit={(e)=>signup(e)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="text-left">Blue Card</label>
                                            <div className="row custom-row">
                                                <div className="col-lg-4 col-md-6 custom-col">
                                                    <label className="mdc-text-field mdc-text-field--outlined">
                                                        <input className={blueCardNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="blueCardNumber" 
                                                        aria-labelledby="my-label-id" value={blueCardNumber} onChange={(e)=>changeHandler(e, "blueCardNumber", e.target.value)} id="blueCardNumber" required onBlur={(e)=>e.target.setCustomValidity("")} maxlength="16"/>
                                                        <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Blue Card Number*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                                
                                                <div className="col-lg-4 col-md-6 custom-col">
                                                    <div className = "calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender('expiryDate')}>
                                                            <input value={expiryDate} onkeypress="return false;" autoComplete="off" type="text" className={expiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                                             id="expiryDate" required aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            <span className="material-icons">calendar_today</span>
                                                            </span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            displayExpiryDatePicker === true ? <Calendar onChange={onChangeDate}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 custom-col">
                                                     <div className = "calendar-wrap">
                                                        <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender('sightedDate')} >
                                                            <input value={sightedDate} onkeypress="return false;" autoComplete="off" type="text" className={sightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                            aria-labelledby="my-label-id" id="sightedDate" required onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
                                                            <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                                <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                            <span className="material-icons">calendar_today</span>
                                                            </span>
                                                        </label>
                                                        <div>
                                                        { 
                                                            displaySlightedDatePicker === true ? <Calendar onChange={onChangeDate}/> : null
                                                        }
                                                        </div>
                                                    </div>
                                                </div> 

                                            <div className="col-lg-4 col-md-4 custom-col">
                                                    <div className="select-field">
                                                        <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                        <span className="mdc-notched-outline">
                                                            <span className="mdc-notched-outline__leading"></span>
                                                            <span className="mdc-notched-outline__notch">
                                                            <span className="mdc-floating-label" id="my-label-id">{blueCardReminder ? blueCardReminder : "Set Reminder"}</span>
                                                            </span>
                                                            <span className="mdc-notched-outline__trailing"></span>
                                                        </span>
                                                        <span className="material-icons">arrow_drop_down</span>
                                                        </label>
                                                        <ul className="dropdown">
                                                        <>
                                                            {
                                                                reminder.map((item,index)=>
                                                                    <li onClick={()=>setBlueCardReminder(item)} >{item}</li>
                                                                )
                                                            }
                                                        </>
                                                        </ul>
                                                    </div>  
                                            </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="next-prev-btns">
                                        <button className="mdc-button mdc-button--raised" onClick = {goBack}>
                                            <span className="mdc-button__label">previous</span>
                                        </button>

                                        <button className="mdc-button mdc-button--raised green-btn" >
                                          <span className="mdc-button__label">FINISH</span>
                                        </button>
                                    </div>
                                </form>
                            </div>  
                        </div>  
                </div>
            </div>      
        </div>  
    </div> 
    </> 
    )
}

export default BlueCard;