/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import LoaderComponent from "../LoaderComponent/LoaderComponent";
import { PaginatedList } from "react-paginated-list";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { baseUrl } from "../../config";
import DummyProfileImage from "../../images/user2.png";
import { useHistory } from 'react-router-dom';

export default function CoordinatorsList(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [allCoOrdinators, setAllCoOrdinators] = useState([]);
  const [noDataLabel, setNoDataLabel] = useState(false);
  const [heading, setHeading] = useState("");
  const [isCaBTlist, setIsCaBTlist] = useState(false);
  const history = useHistory()

  useEffect(() => {
    window.localStorage.setItem("Coordinator", "true");
    console.log('props', props);
    if (props.isCaBT){
      setIsCaBTlist(true)
    }
    getInitialData();
  }, [props]);

  async function getInitialData() {
    let title = props.heading !== undefined ? props.heading : "";
    setHeading(title);
    if (props.data !== undefined && props.data.length > 0) {
      setNoDataLabel(false);
      setAllCoOrdinators(props.data);
    } else {
      if (props.data.length === 0) {
        setAllCoOrdinators(props.data);
        setNoDataLabel(true);
      }

      if (props.data === undefined) {
        setNoDataLabel(false);
      }
    }
  }

  const navigateUser = (cabtId) => {
    if(isCaBTlist){
      history.push(`/cabt-profile/${cabtId}`)
    }else{
      history.push(`/co-ordination-unit-edit-profile/${cabtId}`)
    }
  }

  const uids = ["2970","2967","2822","2968","2965"];
  return (
    <>
      <LoaderComponent displayLoader={showLoader} />
      {allCoOrdinators.length > 10 ?(
        <PaginatedList
          list={allCoOrdinators}
          itemsPerPage={10}
          renderList={(list) => (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profile</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Street Name</TableCell>
                    <TableCell>Suburb Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.length > 0 ? (
                    list.map((coordinator, index) => {
                      if (uids.includes(coordinator.uid)) {
                        return <></>
                      }

                      if (props.data) {
                        return (
                          <TableRow
                            key={index}
                            className="col-md-12"
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <img
                                height="100"
                                width="100"
                                src={
                                  coordinator.field_profile_picture
                                    ? baseUrl +
                                      "/" +
                                      coordinator.field_profile_picture
                                    : DummyProfileImage
                                }
                              />
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))} className="custom-title-link">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_last_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))} className="custom-title-link">
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_first_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              {coordinator.field_home_number || "__"}
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                  (isCaBTlist ? coordinator.field_street_address : coordinator.field_street_name) || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_suburb_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell>
                              <a
                                target={"_blank"}
                                href={`${baseUrl}/view/${isCaBTlist ? 'cabt' : 'user'}-data-csv?id=${coordinator.uid}`}
                                // https://wiseappbackend.thecorella.com/view/cabt-data-csv?id=2997
                                >
                                Export {isCaBTlist ? 'CaBT' : 'Office'} Report
                              </a>
                            </TableCell>
                            <TableCell>
                              <a
                                target={"_blank"}
                                href={`${baseUrl}/view${isCaBTlist ? '/cabt' : ''}/data-pdl-csv?id=${coordinator.uid}`}
                              >
                                Export PD Report
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  )   : (
                    <>
                      {noDataLabel === true ? (
                        <label>No Records Found!!</label>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
      ) : (
            <div>  
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profile</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Street Name</TableCell>
                    <TableCell>Suburb Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    allCoOrdinators.map((coordinator, index) => {
                      if (uids.includes(coordinator.uid)) {
                        return <></>
                      }

                      if (props.data) {
                        return (
                          <TableRow
                            key={index}
                            className="col-md-12"
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <img
                                height="100"
                                width="100"
                                src={
                                  coordinator.field_profile_picture
                                    ? baseUrl +
                                      "/" +
                                      coordinator.field_profile_picture
                                    : DummyProfileImage
                                }
                              />
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))} className="custom-title-link">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_last_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))} className="custom-title-link">
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_first_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              {coordinator.field_home_number || "__"}
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                  (isCaBTlist ? coordinator.field_street_address : coordinator.field_street_name) || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell onClick={() => (navigateUser(coordinator.uid))}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: coordinator.field_suburb_name || "__",
                                }}
                              ></div>
                            </TableCell>
                            <TableCell>
                              <a
                                target={"_blank"}
                                href={`${baseUrl}/view/${isCaBTlist ? 'cabt' : 'user'}-data-csv?id=${coordinator.uid}`}
                              >
                                Export {isCaBTlist ? 'CaBT' : 'Office'} Report
                              </a>
                            </TableCell>
                            <TableCell>
                              <a
                                target={"_blank"}
                                href={`${baseUrl}/view${isCaBTlist ? '/cabt' : ''}/data-pdl-csv?id=${coordinator.uid}`}
                              >
                                Export PD Report
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            </div>      
      )}
    </>
  );
}

