import React, { useEffect, useState } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { useHistory } from 'react-router-dom';
import { getSpecificMedia } from '../../../functions/api';
import { useSelector } from "react-redux"
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import moment from 'moment';

const NoticeDetailPage = (props) => {

  const history = useHistory();
  const store = useSelector(state => state.auth)
  const [userRole, setUserRole] = useState(1)
  const [noticeTitle, setNoticeTitle] = useState("")
  const [noticeDetail, setNoticeDetail] = useState("")
  const [url, setUrl] = useState("")
  const [noticeImage, setNoticeImage] = useState([])
  const [publishDate, setPublishDate] = useState('')
  const [archiveDate, setArchiveDate] = useState('')
  const [displayLoader, setDisplayLoader] = useState(true)
  const regex = /(<([^>]+)>)/ig;
  // async function getId(url) {
  //   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //   const match = url.match(regExp);
  //   return (match && match[2].length === 11) ? match[2] : null;
  // }

  // async function getVimeoId(url) {
  //   var url = url;
  //   var id = url.substring(url.lastIndexOf('/') + 1);
  //   return id;
  // }

  // const compareDate = (date) => {
  //   let fullDate = new Date()
  //   let currentDate = moment(fullDate).format('MMM DD YYYY')
  //   let currentMonth = moment(fullDate).format('MMM DD YYYY')
  //   let currentYear = moment(fullDate).format('MMM DD YYYY')

  //   let getDate = moment(date).format('MMM DD YYYY')
  //   let getMonth = moment(date).format('MMM DD YYYY')
  //   let getYear = moment(date).format('MMM DD YYYY')

  //   let status = false

  //   if (currentDate === getDate && currentMonth === getMonth && currentYear === getYear) {
  //     status = true
  //   }

  //   return status
  // }

  useEffect(() => {

    async function getInitialData() {

      setUserRole(store.userRole)
      let notice_id = props.match.params.id
      if (notice_id !== '') {
        let responseData = await NodeDetails(notice_id)
        if (responseData && responseData.status === 200) {
          if (responseData.data.data.title) {
            let noticeTitle = responseData.data.data.title.length > 0 ? responseData.data.data.title[0].value : ""
            setNoticeTitle(noticeTitle)
          }

          if (responseData.data.data.field_archived_date !== undefined && responseData.data.data.field_archived_date.length > 0) {
            let _newDate = moment(responseData.data.data.field_archived_date[0].value).format('DD-MM-YYYY')
            setArchiveDate(_newDate)
          }

          if (responseData.data.data.field_date !== undefined && responseData.data.data.field_date.length > 0) {
            let _newDate = moment(responseData.data.data.field_date[0].value).format('DD-MM-YYYY')
            setPublishDate(_newDate)
          }

          if (responseData.data.data.body) {
            let noticeDetail = responseData.data.data.body.length > 0 ? responseData.data.data.body[0].value.replace(regex, '') : ""
            setNoticeDetail(noticeDetail)
          }

          if (responseData.data.data.field_url) {
            setUrl(responseData.data.data.field_url)
          }

          // if (responseData.data.data.uid) {
          //   let userId = responseData.data.data.uid.length > 0 ? responseData.data.data.uid[0].target_id : ""

          //   let specificUserResponseData = await getSpecificUser(userId)
          //   if (specificUserResponseData.status === 200) {

          //     if (specificUserResponseData.data.data) {
          //       // if (specificUserResponseData.data.data.name) {
          //       //   let name = specificUserResponseData.data.data.name[0] ? specificUserResponseData.data.data.name[0].value : ""
          //       //   setUserName(name)
          //       // }

          //       if (specificUserResponseData.data.data.head_office_team_profiles !== undefined && specificUserResponseData.data.data.head_office_team_profiles !== '') {
          //         let profileId = specificUserResponseData.data.data.head_office_team_profiles[0] ? specificUserResponseData.data.data.head_office_team_profiles[0].target_id : '';
          //         if (profileId !== '') {
          //           let auth_token = window.localStorage.getItem('auth_token');
          //           let profile_response = await getUserProfileData(profileId, auth_token)
          //           if (profile_response.status === 200) {
          //             if (profile_response.data.data !== '' && profile_response.data.data !== undefined) {
          //               if (profile_response.data.data.field_profile_picture !== undefined && profile_response.data.data.field_profile_picture !== '') {
          //                 let user_picture = profile_response.data.data.field_profile_picture[0] ? profile_response.data.data.field_profile_picture[0].url : "";
          //                 setUserProfile(user_picture);
          //               }
          //             }
          //           }
          //         } else {
          //           setUserProfile(user2)
          //         }
          //       }
          //     }
          //   } else {

          //   }
          // }

          if (responseData.data.data.field_img !== undefined && responseData.data.data.field_img.length > 0) {
            let allImages = []
            responseData.data.data.field_img.map(async (data) => {
              let mediaResponse = await getSpecificMedia(data.target_id)
              if (mediaResponse.status === 200) {
                if (mediaResponse.data.field_media_image !== undefined && mediaResponse.data.field_media_image.length > 0) {
                  allImages.push(mediaResponse.data.field_media_image[0].url)
                  setNoticeImage([...allImages])
                }
              }
            })
          }
          setDisplayLoader(false)

        } else {
          setDisplayLoader(false)

        }
      }

    }

    getInitialData()

  }, [store.userRole, props.match.params.id])

  if (displayLoader) {
    return <LoaderComponent displayLoader={displayLoader} />
  }
  return (
    <div>
      {
        userRole === 1 || userRole === 3  ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <div className="main-wrapper mtb-70">
        <div className="container">
          {noticeTitle !== "" ?
            <div className="dashboard-wrap">
              <div className="common-header-row common-header-with-large-text">
                <h4>{noticeTitle}</h4>
                {/* <button onClick={() => history.goBack()} className="dropdown-list">
                  <span className="material-icons">arrow_back</span> Go Back
                </button> */}
                <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="content-block-right">
                    {/* <div class="task-listing task-list-with-media">	
									    <div class="mdc-form-field">
										    <span class="user-icon">
											    <img src={userProfile} />		
										    </span>	
										    <div class="user-info">
											    <label>{userName}</label>
											    <span class="date-field">{createdDate}</span>
										    </div>	  
									    </div>
								    </div> */}

                    {
                      noticeTitle ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">title</span>Title</h5>
                          <p>{noticeTitle}</p>
                        </div>
                        :
                        null
                    }
                    {
                      noticeDetail ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Description</h5>
                          <div className="material-textfield">
                            <textarea maxlength="200" placeholder="" rows="6" readOnly>{noticeDetail}</textarea>
                          </div>
                          {/* <p>{noticeDetail}</p> */}
                        </div>
                        :
                        null
                    }

                    {
                      publishDate ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span class="material-icons dp48">calendar_month</span>Publish Date</h5>
                          <p>{publishDate}</p>
                        </div>
                        :
                        null
                    }

                    {
                      archiveDate ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span class="material-icons dp48">calendar_month</span>Archive Date</h5>
                          <p>{archiveDate}</p>
                        </div>
                        :
                        null
                    }

                    <div className="meeting-notes">
                      <h5 className="title18"><span className="material-icons">link</span>Url</h5>
                      {
                        url.length > 0 ?
                          url.map(data => {
                            return (
                              <a href={data.uri} target="_blank" rel="noopener noreferrer"><p>{data.uri}</p></a>
                            )
                          })

                          :
                          null
                      }
                    </div>

                    <div className="meeting-notes signature-field">
                      <h5 className="title18"><span className="material-icons">image</span>Image</h5>
                      {
                        noticeImage.length > 0 ?
                          noticeImage.map(data => {
                            return (
                              <img onClick={() => { window.open(data, '_blank') }} src={data} alt='' />
                            )
                          })
                          :
                          null
                      }
                    </div>


                  </div>
                </div>
              </div>
            </div> :
            <div className="common-header-row common-header-with-large-text">
              <h4>Notice Not Found</h4>
              {/* <button onClick={() => history.goBack()} className="dropdown-list">
                <span className="material-icons">arrow_back</span> Go Back
              </button> */}
              <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default NoticeDetailPage;