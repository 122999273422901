import React,{ useState, useEffect } from 'react';
import BasicDetails from './BasicDetails';
import HeaderWithoutLogin from '../../../commonComponents/HeaderWithoutLogin/HeaderWithoutLogin';
import CourseDetails from './CourseDetails';
import BlueCard from './BlueCard';
import { useDispatch, useSelector } from 'react-redux';

const HeadOfficeSignup=(props)=> {

    const store = useSelector(state=>state.auth)
    const[next, setNext] = useState(1);

  useEffect(()=>
  {
    function getStateValues()
    {
      if(store.page !== undefined)
      {
        setNext(store.page)
      }
    }
    getStateValues()
    
  },[store.page])

    return( 
       <div>
        <HeaderWithoutLogin />
        {
            next==1? 
            <BasicDetails />
            :
            next==2?
            <CourseDetails />
            :
            <BlueCard />
        }
        </div>
               
    )
}

export default HeadOfficeSignup;