import React, {useEffect, useState} from 'react';
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import './styles.css';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { getAllEducators, getSpecificUser, getLeaves, convertTimeStampToTimeCustom, taskTitleList } from '../../../functions/api';
import { NodeDetails } from '../../../functions/CommonFunctions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { createTask, updateNode } from '../../../functions/api';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImage } from '../../../functions/api';
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import imageToBase64 from 'image-to-base64/browser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const EditTask = (props) => {


	const classes = useStyles();
    const regex = /(<([^>]+)>)/ig;

	const history = useHistory();
	const [title,setTitle] = useState(null);
	const [taskTitle,setTaskTitle] = useState(null);
	const [taskTitleOptions, setTaskTitleOptions] = useState([]);
	const [showCalendar, setCalendar] = useState(false);
	const [msDate, setMsDate] = useState('');
	const store = useSelector(state=>state.auth)
	const [timeValue, setTimeValue] = useState("");
	const [options, setOptions] = useState([]);
	const [showTimePicker, setTimePicker] = useState(false);
	const [educatorName,setEducatorName]= useState('')
	const [taskNotes,setTaskNotes] = useState('');
	const [educatorsList, setEducatorsList] = useState([])
	const [calendarValue, setCalendarValue] = useState();	
	const [video, setVideo] = useState();
	const [link, setLink] = useState();
	const [digitalSignatureName, setdigitalSignatureName] = useState('');
	const [digitalSignaturePath, setdigitalSignaturePath] = useState('');
	const [digitalSignatureTargetId, setDigitalSignatureTargetId] = useState('');
	const [ajaxLoader, setAjaxLoader] = useState(false);
	const [addImageName, setAddImageName] = useState("");
	const [addImagePath, setAddImagePath] = useState("");
	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
	const [leavesArray, setLeavesArray] = useState([])
	const [imageUpdated, setImageUpdated] = useState(false);
    const [status, setStatus] = useState({ value: 'To-Do', label: 'To-Do' });

	const [blockButton , setBlockButton] = useState(false)

    const taskOptions = [
		{ value: 'To-Do', label: 'To-Do' },
		{ value: 'In Progress', label: 'In Progress' },
		{ value: 'Completed', label: 'Completed' },
	  ];

	const location = useLocation();

	// 

	function calendar(){
		if(showCalendar === false)
		{
			setCalendar(true)
		}
		else
		{
			setCalendar(false)
		}
		
	}

	function timepicker(){
		setTimePicker(true)
	}

	const educatorsHandler=(event) => {
    	setEducatorName(event);
  	};

	const taskStatusHandler = (e) => {
		setStatus(e);
		// console.log("event",e.value);
	}

	const calendarHandler=(newDate) => {

		let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = year+"-"+month+"-"+date

		setMsDate(fullYear)
		setCalendar(false)
	}

	function _onDateFormat(date) {
		if (!date) {
			return ""
		}

		let newDate = date.split('-')
		let newValue = `${newDate[2]}-${newDate[1]}-${newDate[0]}`
		console.log("new--", newValue);

		return (newValue)
	}


	const timeHandler=(newTime)=>{    
      	setTimeValue(newTime)
		setTimePicker(false)
    }

    const taskTitleHandler=(event) => {
    	setTaskTitle(event.target.value);
    };

	const onChangeNotesHandler=(event) => {
		setTaskNotes(event.target.value);
	}

	const onChangeVideoHandler=(event) => {
		setVideo(event.target.value);
	}

	const onChangeLinksHandler=(event) => {
		setLink(event.target.value);
	}

	function get_url_extension( url ) {
    	return url.split(/[#?]/)[0].split('.').pop().trim();
	}	

	useEffect(() => {

		$('#chooseFile').bind('change', function () {
  			var filename = $("#chooseFile").val();
  			if (/^\s*$/.test(filename)) {
    			$(".file-upload").removeClass('active');
    			$("#noFile").text("No file chosen..."); 
  			}else{
    			$(".file-upload").addClass('active');
    			$("#noFile").text(filename.replace("C:\\fakepath\\", "")); 
  			}
		});

		$(function() { 
			$('.dropdown-toggle').click(function() { 
			$(this).next('.dropdown').slideToggle();
		});

		$(document).click(function(e) { 
			var target = e.target; 
			if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
				{ $('.dropdown').slideUp(); }
			});
		});

		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})

		async function getInitialData(){
			setAjaxLoader(true)
            let sortedEducatorsList = await getAllEducators();
            setEducatorsList(sortedEducatorsList.data.data);
			let opt_array = [];
			if(sortedEducatorsList.data.data.length > 0){
				sortedEducatorsList.data.data.map((edu, index) => {
					const obj = {};
					obj['value'] = edu.uid;
					obj['label'] = edu.name;
					opt_array.push(obj);
				})
				setOptions(opt_array);
			}

			let titleOptions = await taskTitleList()
			if(titleOptions.status === 200)
			{
				if(titleOptions.data !== undefined && titleOptions.data.data.length > 0)
				{
					let newArray = [];
					titleOptions.data.data.map((data, index) => {
						let obj = {};
						obj['value'] = <h3 dangerouslySetInnerHTML={{ __html: data.name }}></h3>
						obj['label'] = <h3 dangerouslySetInnerHTML={{ __html: data.name }}></h3>
						newArray.push(obj);
					})
					setTaskTitleOptions(newArray)
				}
			}

            // Task details
            let task_id = props.match.params.id
            if(task_id !==  ''){
                let responseData = await NodeDetails(task_id)
                if(responseData.status === 200){
					// console.log('--------->>>>>', responseData.data.data);
					if(responseData.data.data.field_status.length > 0){
						setStatus({value: responseData.data.data.field_status[0].value, label: responseData.data.data.field_status[0].value})
					}
					console.log('responseData ', responseData.data.data);
                    if(responseData.data.data.title){
                        let _taskTitle = responseData.data.data.title.length > 0 ? responseData.data.data.title[0].value : ""
                        setTitle(_taskTitle)
                        let obj = {};
						obj['value'] = _taskTitle;
						obj['label'] = _taskTitle;
						
                        setTaskTitle(_taskTitle)
                    }

                    if(responseData.data.data.field_assign_to !== undefined && responseData.data.data.field_assign_to !== ''){
                        let uid = responseData.data.data.field_assign_to[0] ? responseData.data.data.field_assign_to[0].target_id : ''
                        let res = await getSpecificUser(uid, window.localStorage.getItem('auth_token'));
                        if(res.status === 200){
                            if(res.data.data.name !== undefined && res.data.data.name !== ""){
                                let name = res.data.data.name[0] ? res.data.data.name[0].value : '';
								let obj = {};
								obj['value'] = responseData.data.data.field_assign_to[0].target_id;
								obj['label'] = name;
                                setEducatorName(obj);
                            }
                        }
                    }
                    if(responseData.data.data.field_task_notes){
                        let taskNotes = responseData.data.data.field_task_notes.length > 0 ? responseData.data.data.field_task_notes[0].value.replace(regex, '') : ""
                        setTaskNotes(taskNotes)
                    }
                    if(responseData.data.data.field_links !== undefined && responseData.data.data.field_links !== ''){
                        let taskLink = responseData.data.data.field_links[0] ? responseData.data.data.field_links[0].uri : ''
                        setLink(taskLink)
                    }
                    if(responseData.data.data.field_date !== undefined && responseData.data.data.field_date !== ''){
                        let newDate = responseData.data.data.field_date[0] ? responseData.data.data.field_date[0].value : ''
                        setMsDate(newDate)
                    }

                    if(responseData.data.data.field_time !== undefined && responseData.data.data.field_time !== ''){
                        let newTime = responseData.data.data.field_time[0] ? responseData.data.data.field_time[0].value : ''
                        let convertedTime = await convertTimeStampToTimeCustom(newTime)
                        setTimeValue(convertedTime)
                    }

                    if(responseData.data.data.field_embed_video !== undefined && responseData.data.data.field_embed_video !== ''){
                        if(responseData.data.data.field_embed_video[0] && responseData.data.data.field_embed_video[0].value){
                            setVideo(responseData.data.data.field_embed_video[0].value)
                        }
                    }

					if(responseData.data.data.field_image !== undefined && responseData.data.data.field_image !== ''){
                        let uploadedImage = responseData.data.data.field_image[0] ? responseData.data.data.field_image[0].url : null
                        setAddImageName(uploadedImage !== null ? uploadedImage.substring(uploadedImage.lastIndexOf('/')+1) : null)
						setAddImagePath(uploadedImage !== null ? (uploadedImage) : null)
                    }
					setAjaxLoader(false)
                }
            }
            // End 
        }
        getInitialData()
	},[]);


	async function taskSubmission(e){
		e.preventDefault();
		setAjaxLoader(true);
		let main_title = taskTitle;
		let educator_id = educatorName.value;
		let date = msDate.replace(/\//g, '-');
		let notes = taskNotes;

		if(taskTitle === undefined || taskTitle === ''){
			toast.error('Title is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		if(msDate === undefined || msDate === ''){
			toast.error('Date is required', {
				position: 'top-center'
			});
			setAjaxLoader(false);
			return false;
		}

		// if(taskNotes === undefined || taskNotes === ''){
		// 	toast.error('Task notes is required', {
		// 		position: 'top-center'
		// 	});
		// 	setAjaxLoader(false);
		// 	return false;
		// }
		setBlockButton(false);

		const getDatesBetweenDates = (startDate, endDate) => {
  			let dates = []
  			//to avoid modifying the original date
  			const theDate = new Date(startDate)
			while (theDate < endDate || theDate.valueOf() === endDate.valueOf()) {
				dates = [...dates, new Date(theDate)]
				theDate.setDate(theDate.getDate() + 1)
			}	
 			return dates
		}

		//getLeaves
		let checkLeaveResponse = {};
		if(educator_id !== '' && date !== ''){
			let selected_date = new Date(date).setHours(0,0,0,0);
			let leaves = await getLeaves(educator_id);
			if(leaves.status === 200){
				if(leaves.data.data.length > 0){
					setLeavesArray(leaves.data.data)
					for(let mydata of leaves.data.data){
						for(let rangeDate of getDatesBetweenDates(new Date(mydata.start_date), new Date(mydata.end_date))){
							if(selected_date.valueOf() === rangeDate.valueOf()){
								checkLeaveResponse['response'] = date
								checkLeaveResponse['success'] = false
							}
						}
					}

				}
			}
		}
		if(checkLeaveResponse['response'] !== undefined && store.userRole==2){
			setShowAvailabilityModal(true)
			setAjaxLoader(false);
			return false;
		}

		//end

		let data = { title: main_title, educator_id: educator_id, date: date, notes: notes, status: status };
		let res = await updateNode(props.match.params.id, data);
		if(res.status == 200){
			history.push('/tasks');
		}else{
			toast.error(res.message, {
				position: 'top-center'
			});
			setAjaxLoader(false);
		}
	}

    return (
    <>
		<ToastContainer />
		<HeadOfficeMainHeader />
		<LoaderComponent displayLoader={ajaxLoader} />  
	    <div className="main-wrapper">
		    <div className="container">	
			    <div className="user-pages-wrap">
				    <div className="row">
						<div className="col-lg-12">
							<div className="user-types-wrap course-detail-page text-center">
								<div className="common-header-row common-header-with-large-text">
									<h4>Edit Task : {title}</h4>	
									<div className="block-right">
										<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
									</div>
								</div>

								<form onSubmit= {taskSubmission} className="sign-up-form">
									<div className="row">
										<div className="col-md-12">
											<div className="row">
											<div className="col-lg-4 col-md-4">
                            <div className="outlined-textfield-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input maxlength="100" value={taskTitle} onChange={taskTitleHandler} type="text" className={taskTitle ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Task Name</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                          </div>
												{/* <div className="col-lg-3 col-md-3">
					
													<Select className="search-select-field" placeholder="Task Name" style={{ width: 100 }} value={taskTitle} options={taskTitleOptions} onChange={taskTitleHandler} />
													
													</div> */}

													{store.userRole ===1 ? null : (

												<div className="col-lg-3 col-md-3">
													<Select className="search-select-field" placeholder="Select Educator" style={{ width: 100 }} value={educatorName} options={options} onChange={educatorsHandler} />
												</div>		
													)}
													<div className="col-lg-3 col-md-3">
														<Select className="search-select-field" placeholder="Select Status" value={status} onChange={(event) => taskStatusHandler(event)} options={taskOptions} style={{ width: 100 }}>
															
														</Select>
													</div>

												<div className="col-lg-3 col-md-3">
													<div className="calendar-wrap">
														<label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar()}>
														<input readOnly type="text" className="mdc-text-field__input" aria-labelledby="my-label-id"style={{cursor:"pointer"}} />
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	<span className="mdc-floating-label" id="my-label-id">{msDate ? _onDateFormat(msDate) : "Due Date"}</span>
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															<span className="material-icons">calendar_today</span>
															</span>
														</label>
														<div>
														{ 
															showCalendar === true ? <Calendar value={calendarValue} onChange={calendarHandler}/> : null
														}
														</div>
													</div>
												</div>	
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<div className="outlined-textfield-wrap mt-10">
												<div className="material-textfield">
													<textarea maxlength="200" value={taskNotes} onChange={onChangeNotesHandler} id="message" className={taskNotes !== '' ? 'form__field active' : 'form__field'}  placeholder="" rows="6"></textarea>
													<label>Task Notes</label>
												</div>
											</div>
										</div>
									</div>
									<button type='submit' disabled={blockButton}  className="mdc-button mdc-button--raised mt-5">
                                    	<span className="mdc-button__label">Submit</span>
                                    </button>
								</form>
							</div>	
						</div>	
				    </div>
			    </div>		
		    </div>	
	    </div>
	    <Dialog open={showAvailabilityModal && store.userRole == 2} onClose={()=>setShowAvailabilityModal(false)} aria-labelledby="alert-dialog-title"
        	aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">User is not available on following dates-</DialogTitle>
        		<DialogContent>
	        		<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>S.No.</TableCell>
											<TableCell>Start Date</TableCell>
											<TableCell>End Date</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{
										leavesArray.length > 0 ? leavesArray.map((items, index) => (
											<TableRow key={index}>
												<TableCell>{index+1}</TableCell>
												<TableCell><Moment format="DD-MM-YYYY">{items.start_date}</Moment></TableCell>
												<TableCell><Moment format="DD-MM-YYYY">{items.end_date}</Moment></TableCell>
											</TableRow>
												
										)) : null
									}
									</TableBody>
								</Table>
							</TableContainer>
        		</DialogContent>
        		<DialogActions>
          			<Button onClick={()=>setShowAvailabilityModal(false)} color="primary" autoFocus>OK</Button>
				</DialogActions>
        </Dialog>
    </>
    )

}

export default EditTask;