import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';

const LoaderComponent = (props) => {

	const [open, setOpen] = useState(false)

	useEffect(()=>
	{
		if(props.displayLoader)
		{
			setOpen(props.displayLoader)
		}

	},[props,open])

    return (

	    	<Dialog open={props.displayLoader} onClose={()=>{}} aria-labelledby="alert-dialog-title"
	        	aria-describedby="alert-dialog-description" className="loader-wrap">
	        	<svg preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" height="200px" width="200px"  style={{'margin':'auto','display': 'block'}} xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
					<g transform="translate(20 50)">
					<circle cx="0" cy="0" r="6" fill="#0a50a1">
					  <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
					</circle>
					</g><g transform="translate(40 50)">
					<circle cx="0" cy="0" r="6" fill="#33a02c">
					  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
					</circle>
					</g><g transform="translate(60 50)">
					<circle cx="0" cy="0" r="6" fill="#801682">
					  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
					</circle>
					</g><g transform="translate(80 50)">
					<circle cx="0" cy="0" r="6" fill="#fb9900">
					  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
					</circle>
					</g>
				</svg>
		    </Dialog>
    )
}

export default LoaderComponent;