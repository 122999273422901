import React,{ useState, useEffect } from 'react';
import { getAllEducators, searchEducators, sortEducators, searchEducatorsBySuburb,getAllVenue,deleteVenue,getVenueList} from "../../../functions/api";
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import EducatorsList from '../../../commonComponents/CommonEducatorsListComponent/EducatorsList';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { baseUrl } from '../../../config';
import { Link, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import

//Import Images
import line from '../../../images/line.png';
import user1 from '../../../images/user1.png';



const VenueList=(props)=>{

    const [searchPlaceHolderValue,setSearchPlaceHolderValue] = useState('Type Educator name...');
    const [educatorsData,setEducatorsData] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [showLoader, setShowLoader] = useState(true);
	const [sortedValue, setSortedValue] = useState('ASC')
	const [venueList,setVenueList] = useState([]);
	const [userRole, setUserRole] = useState("");
	const history = useHistory();
	// const [data, setData] = useState([]);

	const StyledMenu = withStyles({
  	paper: {
    	border: '1px solid #d3d4d5',
  	},
	})((props) => (
  	<Menu elevation={0} getContentAnchorEl={null}
    	anchorOrigin={{
      		vertical: 'bottom',
      		horizontal: 'center',
    	}}
    	transformOrigin={{
      		vertical: 'top',
      		horizontal: 'center',
    	}}
    	{...props}
  	/>
	));

	const StyledMenuItem = withStyles((theme) => ({
  	root: {
    	'&:focus': {
      		backgroundColor: theme.palette.primary.main,
      		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        		color: theme.palette.common.white,
      		},
    	},
  	},
	}))(MenuItem);

    useEffect(()=>{
		let role = window.localStorage.getItem('user_role');
        setUserRole(role);
		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})
        getInitialData()

    },[props,searchPlaceHolderValue])

	//all venue list
	async function getInitialData(){
	let VenueList= await getAllVenue();
	

	if( VenueList.status == 200)
	{
		setShowLoader(false)
		let arr = [];
		if(VenueList.data.data.length > 0){
			VenueList.data.data.map((v)=>{
				arr.push({
					name: v.field_venue_name,
					location: v.field_location,
					risk_ass: v.field_risk_assessment_doc,
					venue_img: v.field_venue_images.split(','),
					covid_plan: v.field_covid_19_safe_plan,
					id:v.id
				})
			})
			setVenueList(arr);
		}
	}
	else{
		setShowLoader(false)
	}

	}

	// delete venue list with id
	async function deleteVenueList(id){
		
        let response = await deleteVenue(id);
		
        if(response.status == 204){
      		setShowLoader(false)
			
			toast.success('Venue has been deleted successfully!', {
                position: 'top-center'
            });
			getInitialData();
        }else{
            toast.error(response.message, {
                position: 'top-center'
            });
        }
    }

	async function search(inputValue) {
		let response = await searchEducators(inputValue)
		if(response.status === 200){
			if(response.data !== undefined && response.data !== ''){
				if(response.data.data.length > 0){
					setEducatorsData([...response.data.data]);
				}else{
					let _response = await searchEducatorsBySuburb(inputValue)
					if(_response.status === 200){
						if(_response.data !== undefined && _response.data !== ''){
							if(_response.data.data.length > 0){
								setEducatorsData([..._response.data.data]);
							}else{
								setEducatorsData([]);
							}
						}
					}else{
						toast.error(response.message,{
			                position: 'top-center'
			            });
					}
				}
			}
		}else{
			toast.error(response.message,{
                position: 'top-center'
            });
		}
	}

	const handleClick = (event) => {
   		setAnchorEl(event.currentTarget);
  	};

	 const handleClose = () => {
    	setAnchorEl(null);
  	};

	async function sorting(sort){
		if(sort === 'ASC'){
			setSortedValue('ASC')
		}
		if(sort === 'DESC'){
			setSortedValue('DESC')
		}
		setShowLoader(true)
		if(sort !== ''){
			let response = await sortEducators(sort)
			if(response.status === 200){
				setShowLoader(false)
				if(response.data.data.length > 0){
					setEducatorsData([...response.data.data]);
				}
			}
			else
			{
				setShowLoader(false)
			}
		}
	}

    return (
    	<>
		<ToastContainer />
		<LoaderComponent displayLoader={showLoader} />
    	<HeadOfficeMainHeader />
        <div className="main-wrapper mtb-70">
		    <div className="container">	
			    <div className="dashboard-wrap">
				    <div className="row">
						<div className="col-md-12">
							<div className="content-block-right">
									<div className="notice-listing-block pt-0">
										<div className="common-header-row mt-0">
											<h4 onClick={()=>console.log("hgh",venueList)}>Venue List</h4>	
											<div className="block-right">
										<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
											</div>
											
											{/* <div className="block-right">
												{/* Sorting Button  */}
												 {/* <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={sortedValue}>
          											<MenuItem value="ASC" onClick={() => sorting('ASC')}>A-Z</MenuItem>
          											<MenuItem value="DESC" onClick={() => sorting('DESC')}>Z-A</MenuItem>
												</Select>  */}
												{/* <div className="col-lg-12 col-md-12">
													<label className="mdc-text-field mdc-text-field--outlined search-educators">
													<input onChange={(e) => search(e.target.value)} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
														<span className="mdc-notched-outline">
													        <span className="mdc-notched-outline__leading"></span>
													        <span className="mdc-notched-outline__notch">
													            <span className="mdc-floating-label" id="my-label-id">Search By Name or Suburb name</span>
													        </span>
													        <span className="mdc-notched-outline__trailing"></span>
													    </span>
														<span className="material-icons">saved_search</span>
													</label>
												</div> */}
											{/* </div> */}
										</div>
										<div className="task-listing task-list-with-media">	
										<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												{/* <TableCell>S No</TableCell> */}
												<TableCell onClick={()=> console.log("test", venueList)}>Venue Name</TableCell>
												<TableCell>Location</TableCell>
												<TableCell>Venue Image</TableCell>
												<TableCell>venue document</TableCell>
												<TableCell>covid plan</TableCell>
												<TableCell>Action</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
								{
									venueList && venueList.length > 0 &&
									venueList.map((v)=>(
										<TableRow className="col-md-12">
											<TableCell className="custom-title-link">{v.name}</TableCell>
											<TableCell className="custom-title-link">{v.location}</TableCell>
											{/* {console.log("split", v.field_venue_images.split(','))} */}
											<TableCell>
												{v.venue_img.length > 0 && v.venue_img.map((v)=>(
													v == '' ? "No Image" :
													<img height="100" width="100" src={baseUrl+'/'+v.replace(" /","")} alt="no image" />
												))}
											</TableCell>
											<TableCell>{v.risk_ass ? v.risk_ass.slice(29,70).replace(/%/g,'_') : "N/A"}</TableCell>
											<TableCell>{v.covid_plan ? v.covid_plan.slice(29,70).replace(/%/g,'_') : "N/A"}</TableCell>
											<TableCell>
											{userRole == 'head_coordinator' ? 
												<Link className="bg-green action-btn-style" 
                                                to={`/add-venue/${v.id}`}
                                            >
                                                Edit
                                            </Link> : null} 

											{userRole == 'head_coordinator' ? 
												<button className="bg-red action-btn-style" 
													onClick={()=>deleteVenueList(v.id)}>Delete</button> : null 
											}
                                           </TableCell>
										</TableRow>
									))
								}
								</TableBody>
							</Table>
     								   </TableContainer> 
										</div>
									</div>	 
							    </div>
						    </div>
                        </div>
			        </div>		
		        </div>	
	        </div>
	    </>
        );
};

export default VenueList;
