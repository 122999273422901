import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleEducatorSignupForm, getAuthToken } from '../../../redux/actions/auth';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { $ } from "react-jquery-plugin";
const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const BusinessInfo = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch(); 
    const store = useSelector(state=>state.auth)

    const [dateValue, setDateValue] = useState(new Date());
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [dateField, setDateField] = useState('')

    const [timeValue, setTimeValue] = useState('');
    const [displayTimePicker, setDisplayTimePicker] = useState('')
    const [timeField, setTimeField] = useState('')

    const [commencementDate, setCommencementDate] = useState('')
    const [businessName,setBusinessName]=useState('')
    const [showCommencementDateModal, setShowCommencementDateModal] = useState(false)

    const [opertionalHours, setOpertionalHours] = useState('')
    const [closingHours, setClosingHours] = useState('')
    const [qualification, setQualification] = useState('')

    const [operationalDays, setOperationalDays] = useState(["Sun","Mon","Tue","Wed","Thu","Fri","Sat"])
    const [selectedDays, setSelectedDays]= useState([false,false,false,false,false,false,false])
    const [operationalDaysSelectedArrays, setOperationalDaysSelectedArrays] = useState(["Sun","Mon","Tue","Wed","Thu","Fri","Sat"])

    const [daysSelected, setDaysSelected] = useState(false)
    const [operationDaysData, setOperationDaysData] = useState([{status:false, open:'', close:'', day:'Sun'},{status:false, open:'', close:'', day:'Mon'},
                                                                {status:false, open:'', close:'', day:'Tue'},{status:false, open:'', close:'', day:'Wed'},
                                                                {status:false, open:'', close:'', day:'Thu'},{status:false, open:'', close:'', day:'Fri'},
                                                                {status:false, open:'', close:'', day:'Sat'}
                                                        ])

    const [glowIds , setGlowIds] = useState([])

    // New Fields 
        const [ceasedDate, setCeasedDate] = useState('')
        const [ceasedDateCalendarModel, setCeasedDateCalendarModel] = useState(false)
        const [annualAssessmentCompletionDate , setAnnualAssessmentCompletionDate] = useState('')
        const [annualAssessmentExpiryDate , setAnnualAssessmentExpiryDate] = useState('')
        const [annualAssessmentReminder , setAnnualAssessmentReminder] = useState('')

        const [annualAssessmentCompletionCalendar , setAnnualAssessmentCompletionCalendar] = useState(false);
        const [annualAssessmentExpiryCalendar , setAnnualAssessmentExpiryCalendar] = useState(false)


        const [ qualificationIssueDate , setQualificationIssueDate] = useState('');
        const [ qualificationCalendar , setQualificationCalendar] = useState(false); 



    useEffect(()=>{
        getInitialData()
        validateForm()
    },[])




    useEffect(() =>{
        $(function () {
          $('.dropdown-toggle').click(function () {
            $(this).next('.dropdown').slideToggle();
          });
    
          $(document).click(function (e) {
            var target = e.target;
            if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) { $('.dropdown').slideUp(); }
          });
        });
      },[])




    function validateForm()
        {
            var commencementValidate = document.getElementById("commencementDate");
            if(commencementValidate !== null){
              commencementValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Commencement date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }


            var qualificationValidate = document.getElementById("qualification");
            if(qualificationValidate !== null){
                qualificationValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Qualification date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var businessNameValidate = document.getElementById("businessName");
            if(businessNameValidate !== null){
                businessNameValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("Business Name can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }



        }

    function getInitialData()
        {
            if(window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined)
            { 
                let signupFormDetail = JSON.parse(window.localStorage.getItem('educatorSignupDetail'))
                console.log('signupFormDetail', signupFormDetail);
                if(signupFormDetail[2] !== null && signupFormDetail[2] !== undefined) {
                    setCommencementDate(signupFormDetail[2].businessInformation.commencementDate)
                    setOpertionalHours(signupFormDetail[2].businessInformation.opertionalHours)
                    setClosingHours(signupFormDetail[2].businessInformation.closingHours)
                    setQualification(signupFormDetail[2].businessInformation.qualification)
                    setDaysSelected(signupFormDetail[2].businessInformation.isDaysSelected)
                    
                    if(signupFormDetail[2].businessInformation.operationalDays.length > 0){
                        setOperationalDaysSelectedArrays(signupFormDetail[2].businessInformation.operationalDays)
                    }
                    if(signupFormDetail[2].businessInformation.setSelectedDays.length > 0) {
                        setOperationDaysData(signupFormDetail[2].businessInformation.setSelectedDays)
                    }
                }
            }
        }

    const showCalender=(field)=>
    {   
        setDateField(field)
        if(displayDatePicker === true)
        {
            setDisplayDatePicker(false)
        }
        else
        {
            setDisplayDatePicker(true)
        }
    }

    const onChangeDate=(newDate)=>
    {    
      let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
      let year = newDate.getFullYear()
      let date = newDate.getDate()
      let fullYear = date+"-"+month+"-"+year
      
      if(dateField === "commencementDate")
      {
          setCommencementDate(fullYear)
          setShowCommencementDateModal(false)
      }
      if(dateField === "ceasedDate")
      {
          setCeasedDate(fullYear)
          setCeasedDateCalendarModel(false)
      }
      if(dateField === "annualAssessmentCompletionDate")
      {
          setAnnualAssessmentCompletionDate(fullYear)
          setAnnualAssessmentCompletionCalendar(false)
      }
      if(dateField === "annualAssessmentExpiryDate")
      {
          setAnnualAssessmentExpiryDate(fullYear)
          setAnnualAssessmentExpiryCalendar(false)
      }
      if(dateField === "qualificationIssueDate")
      {
          setQualificationIssueDate(fullYear)
          setQualificationCalendar(false)
      }

      setDateField("")
    }

    const showTimePicker=(field)=>
    {   
        setTimeField(field)
    }

    const onChangeTime=(newTime)=>
    {    
      if(timeField === "opertionalHours")
      {
          setOpertionalHours(newTime)
      }
      if(timeField === "closingHours")
      {
          setClosingHours(newTime)
      }

      setTimeField("")
      setDisplayTimePicker(false)
    }

    const validateFields=()=>
    {
        if(operationalDaysSelectedArrays.length > 0)
        {
            if(opertionalHours !== "" && opertionalHours !== undefined) 
            {
                if(closingHours !== "" && closingHours !== undefined) 
                {
                    return true
                }
                else
                {
                    toast.error("Please select closing hours",{ position: 'top-center'});
                    return false
                }
            }
            else
            {
                toast.error("Please select opertional hours",{ position: 'top-center'});
                return false
            }
        }
        else
        {
            toast.error("Please select operational days",{ position: 'top-center'});
            return false
        }
    }

    const goNext=async(e)=>
    {  
      e.preventDefault()
      let res = validateFields()
      // if(res === true)
      // {
        let businessInformation = { "businessInformation": {   commencementDate:commencementDate, closedDate:null, 
                                                               opertionalHours:opertionalHours, closingHours:closingHours, 
                                                               qualification: qualification, operationalDays:operationalDaysSelectedArrays,
                                                               ceasedDate : ceasedDate,
                                                               setSelectedDays: operationDaysData, isDaysSelected: daysSelected ,
                                                               annualAssessmentCompletionDate : annualAssessmentCompletionDate ,
                                                               annualAssessmentExpiryDate : annualAssessmentExpiryDate ,
                                                               annualAssessmentReminder : annualAssessmentReminder,
                                                               qualificationIssueDate : qualificationIssueDate
                                                            }} 

        dispatch(handleEducatorSignupForm(4, businessInformation,2)) 
      // }
      
    }

    const goBack=()=>
    {
      dispatch(handleEducatorSignupForm(2))  
    }

    const selectOpertationalDays=(value, index)=>

 
    {

        if(glowIds.includes(value.day)){
            const filterResult  = glowIds.filter(item  => item != value.day)
            setGlowIds(filterResult)
         }else{
             setGlowIds([...glowIds , value.day])
         }
        let newArray = operationDaysData
        if(newArray[index].status === false){
          newArray[index].status = true
          setDaysSelected(true)
        }
        else {
            newArray[index].status = false
            newArray[index].open = ""
            newArray[index].close = ""
            setDaysSelected(false)
        }
        setOperationDaysData([...newArray])
    }

    const showTimer=(value,index,type, items)=>
    {
        let newArray = operationDaysData
        if(type === "open")
        {  
            setGlowIds([...glowIds , items.day])
            newArray[index].open = value
        }
        else
        {
            newArray[index].close = value
        }
        setOperationDaysData([...newArray])
    }

    const showHideCalender=(field)=>
    {
        if(field === 'commencementDate')
        {
            if(showCommencementDateModal === true)
            {
                setShowCommencementDateModal(false);
            }
            else
            {
                setShowCommencementDateModal(true);
            }

            setDateField("commencementDate")
        }

        if( field === 'ceasedDate'){
            if(ceasedDateCalendarModel === true){
                setCeasedDateCalendarModel(false);
            }else{
                setCeasedDateCalendarModel(true);
            }
            setDateField('ceasedDate')
        }
        if( field === 'annualAssessmentCompletionDate'){
            if(annualAssessmentCompletionCalendar === true){
                setAnnualAssessmentCompletionCalendar(false);
            }else{
                setAnnualAssessmentCompletionCalendar(true);
            }
            setDateField('annualAssessmentCompletionDate')
        }
        if( field === 'annualAssessmentExpiryDate'){
            if(annualAssessmentCompletionCalendar === true){
                setAnnualAssessmentExpiryCalendar(false);
            }else{
                setAnnualAssessmentExpiryCalendar(true);
            }
            setDateField('annualAssessmentExpiryDate')
        }

        if( field === 'qualificationIssueDate'){
            if(qualificationCalendar === true){
                setQualificationCalendar(false);
            }else{
                setQualificationCalendar(true);
            }
            setDateField('qualificationIssueDate')
        }


    }


    return  (
     <>
        <ToastContainer />
        <div className="main-wrapper">
            <div className="container">
                <div className="user-pages-wrap">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <div className="user-types-wrap text-center">
                            <h1 className="common-heaidng48">Business Information</h1>
                            <h3 className="small-title">Please fill the required details</h3>
                            <form className="sign-up-form" onSubmit={(e)=>goNext(e)}>
                                <div className="row">
                                    {/* <div className="col-md-6 col-lg-6">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="30" className={businessName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" onChange={(e) => {setBusinessName(e.target.value); e.target.setCustomValidity("")}} value = {businessName} name="businessName" 
                                            id="businessName" aria-labelledby="my-label-id" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Business Name*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span> 
                                            </span>
                                        </label>    
                                    </div> */}

                                        <div className="col-md-6">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("ceasedDate")}>
                                                <input value={ceasedDate} type="text" onkeypress="return false;" autoComplete="off" className={ceasedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id = "ceasedDate" required onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Ceased Date</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                        <div>
                                        { 
                                            ceasedDateCalendarModel === true ? <Calendar onChange={onChangeDate}/> : null
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("commencementDate")}>
                                                <input value={commencementDate} type="text" onkeypress="return false;" autoComplete="off" className={commencementDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id = "commencementDate" required onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Commencement Date</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                        <div>
                                        { 
                                            showCommencementDateModal === true ? <Calendar onChange={onChangeDate}/> : null
                                        }
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {
                                    operationDaysData.map((items,index)=>
                                       {
                                           return(
                                            <div className="row">
                                                <div className="operational-days-block col-md-2">
                                                    <ul className="operational-days-listing">
                                                        <li className={glowIds.includes(items.day)  ? "active" : ""} onClick={()=>selectOpertationalDays(items,index)} >{items.day}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4" onClick={()=>showTimePicker("opertionalHours")}>  
                                                    <TextField
                                                        id="time"
                                                        label="Opening Hours"
                                                        type="time"
                                                        className={classes.textField}
                                                        value={items.open}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        onChange={(e)=>showTimer(e.target.value,index,"open",items)}
                                                      />
                                                </div>
                                                <div className="col-md-4" onClick={()=>showTimePicker("closingHours")}>
                                                    <TextField
                                                        id="time"
                                                        label="Closing Hours"
                                                        type="time"
                                                        value = {items.close}
                                                        className={classes.textField}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        onChange={(e)=>showTimer(e.target.value,index,"close", items)}
                                                      />
                                                </div>
                                            </div>
                                            )
                                       }
                                    )
                                }

                                    <div>
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("qualificationIssueDate")}>
                                                <input value={qualificationIssueDate} type="text" onkeypress="return false;" autoComplete="off" className={qualificationIssueDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id = "qualificationIssueDate" required onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Qualification Issue Date</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                        <div>
                                        { 
                                            qualificationCalendar === true ? <Calendar onChange={onChangeDate}/> : null
                                        }
                                        </div>
                                        </div>
                                    </div>

                                <div className="row mt-20">
                                    <div className="col-md-12">
                                        <div className="outlined-textfield-wrap mt-20">
                                         <div className="material-textfield">
                                            <textarea maxlength="200" id="message" placeholder="" rows="6"
                                                className={qualification ? 'form__field active' : 'form__field'} type="text" name = "qualification" value = {qualification} 
                                                aria-labelledby="my-label-id" onChange={(e)=> {setQualification(e.target.value); e.target.setCustomValidity("")}}  
                                                required onBlur={(e)=>e.target.setCustomValidity("")}></textarea>
                                            <label>Qualification</label>
                                          </div>
                                       </div>
                                    </div>
                                </div>

                                <h2>Annual Assessment of Residence</h2>
                                <div className="row">
                                        <div className="col-md-6">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("annualAssessmentCompletionDate")}>
                                                <input value={annualAssessmentCompletionDate} type="text" onkeypress="return false;" autoComplete="off" className={annualAssessmentCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id = "annualAssessmentCompletionDate" required onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                        <div>
                                        { 
                                            annualAssessmentCompletionCalendar === true ? <Calendar onChange={onChangeDate}/> : null
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("annualAssessmentExpiryDate")}>
                                                <input value={annualAssessmentExpiryDate} type="text" onkeypress="return false;" autoComplete="off" className={annualAssessmentExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id = "annualAssessmentExpiryDate" required onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>
                                        <div>
                                        { 
                                            annualAssessmentExpiryCalendar === true ? <Calendar onChange={onChangeDate}/> : null
                                        }
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                      <div className="select-field">
                                        <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                          {/* <label className="reminder-field-style">Set Reminder*</label> */}
                                          <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                              <span className="mdc-floating-label" id="my-label-id">{annualAssessmentReminder ? annualAssessmentReminder : "Set Annual Assessment Reminder "}</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                          </span>
                                          <span className="material-icons">arrow_drop_down</span>
                                        </label>
                                        <ul className="dropdown">
                                          <>
                                            {
                                              reminder.map((item, index) =>
                                                <li key={index} onClick={() => setAnnualAssessmentReminder(item)}>{item}</li>
                                              )
                                            }
                                          </>
                                        </ul>
                                      </div>
                                    </div>


                                <div className="next-prev-btns">
                                    <button className="mdc-button mdc-button--raised " onClick = {goBack}>
                                      <span className="mdc-button__label">previous</span>
                                    </button>
                                    <button className="mdc-button mdc-button--raised green-btn b">
                                    <span className="mdc-button__label">next</span>
                                    </button>
                                </div>
                            </form>
                            {
                                displayTimePicker === true? <TimePicker onChange={onChangeTime} /> : null
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default BusinessInfo;