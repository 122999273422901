import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleEducatorSignupForm, getAuthToken } from '../../../redux/actions/auth';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { $ } from 'react-jquery-plugin';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CourseDetails = () => {

    const dispatch = useDispatch()

    const [value, setValue] = useState(new Date());
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [dateField, setDateField] = useState('')


    const [firstAidCourseCode, setFirstAidCourseCode] = useState('')
    const [firstAidCourseExpiryDate, setFirstAidCourseExpiryDate] = useState('')
    const [showFirstAidCourseDateModal, setShowFirstAidCourseDateModal] = useState(false)
    const [firstAidCourseReminder, setFirstAidCourseReminder] = useState('')

    const [crpCourseCode, setCrpCourseCode] = useState('')
    const [crpCourseExpiryDate, setCrpCourseExpiryDate] = useState('')
    const [showCrpCourseDateModal, setShowCrpCourseDateModal] = useState(false)
    const [crpCourseReminder, setCrpCourseReminder] = useState('')

    const [asthmaCourseCode, setAsthmaCourseCode] = useState('')
    const [showAsthmaCourseDateModal, setShowAsthmaCourseDateModal] = useState(false)
    const [asthmaCourseExpiryDate, setAsthmaCourseExpiryDate] = useState('')
    const [asthmaCourseReminder, setAsthmaCourseReminder] = useState('')

    const [anaphylaxisCourseCode, setAnaphylaxisCourseCode] = useState('')
    const [anaphylaxisCourseExpiryDate, setAnaphylaxisCourseExpiryDate] = useState('')
    const [showAnaphylaxisCourseDateModal, setShowAnaphylaxisCourseDateModal] = useState(false)
    const [anaphylaxisCourseReminder, setaAnaphylaxisCourseReminder] = useState('')

     const [certificates, setCertificates] = useState([])
     const [firstAidImg, setFirstAidImg] = useState([])
     const [crpCourseImg, setCrpCourseImg] = useState([])
     const [asthmaCourseImg, setAsthmaCourseImg] = useState([])
     const [anaphlaxisImg, setAnaphlaxisImg] = useState([])

     const  [ firstAidExpiryDate , setFirstAidExpiryDate] = useState('')
     const  [ firstAidCalendar , setFirstAidCalendar] = useState(false)

     const [ cprExpiryDate , setCprExpiryDate] = useState('')
     const [ cprCalendar , setCprCalendar] = useState(false)

     const [ asthmaExpiryDate , setAsthmaExpiryDate] = useState('')
     const [ asthmaCalendar , setAsthmaCalendar] = useState(false)

     const [anaphlaxisExpiryDate , setAnaphlaxisExpiryDate] = useState('')
     const [anaphlaxisCalender , setAnaphlaxisCalender] = useState(false);

    const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]  

    useEffect(()=>
    {

    // Dropdown toggle

        $(function() { 
            $('.dropdown-toggle').click(function() { 
                $(this).next('.dropdown').slideToggle();
            });

            $(document).click(function(e) { 
            var target = e.target; 
            if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
              { $('.dropdown').slideUp(); }
            });
        });


        $(".mobile-toggel-icon").click(function(){
          $(".header-bar .right-block .menu-list").slideToggle();
        });

        function getInitialData()
        {
            if(window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined)
            { 
                let signupFormDetail = JSON.parse(window.localStorage.getItem('educatorSignupDetail'))
                

                if(signupFormDetail[3] !== null && signupFormDetail[3] !== undefined)
                {
                    setFirstAidCourseCode(signupFormDetail[3].courseDetails.firstAidCourseCode)
                    setFirstAidCourseExpiryDate(signupFormDetail[3].courseDetails.firstAidCourseExpiryDate)
                    setFirstAidCourseReminder(signupFormDetail[3].courseDetails.firstAidCourseReminder)

                    setCrpCourseCode(signupFormDetail[3].courseDetails.crpCourseCode)
                    setCrpCourseExpiryDate(signupFormDetail[3].courseDetails.crpCourseExpiryDate)
                    setCrpCourseReminder(signupFormDetail[3].courseDetails.crpCourseReminder)

                    setAsthmaCourseCode(signupFormDetail[3].courseDetails.asthmaCourseCode)
                    setAsthmaCourseExpiryDate(signupFormDetail[3].courseDetails.asthmaCourseExpiryDate)
                    setAsthmaCourseReminder(signupFormDetail[3].courseDetails.asthmaCourseReminder)

                    setAnaphylaxisCourseCode(signupFormDetail[3].courseDetails.anaphylaxisCourseCode)  
                    setAnaphylaxisCourseExpiryDate(signupFormDetail[3].courseDetails.anaphylaxisCourseExpiryDate)
                    setaAnaphylaxisCourseReminder(signupFormDetail[3].courseDetails.anaphylaxisCourseReminder)
                    setCertificates(signupFormDetail[3].courseDetails.certificateDetails)
                }

            }
        }
        
        getInitialData()

        function validateForm()
        {
            var firstAidCourseCodeValidate = document.getElementById("firstAidCourseCode");
            if(firstAidCourseCodeValidate !== null){
              firstAidCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("First Aid course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var crpCourseCodeValidate = document.getElementById("crpCourseCode");
            if(crpCourseCodeValidate !== null){
              crpCourseCodeValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("CRP course code can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            // var asthmaCourseCodeValidate = document.getElementById("asthmaCourseCode");
            // if(asthmaCourseCodeValidate !== null){
            //   asthmaCourseCodeValidate.oninvalid = function(e) {
            //     if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            //     {
            //       e.target.setCustomValidity("Asthma course code can't be empty");
            //     }
            //     else
            //     {
            //       e.target.setCustomValidity("");
            //     }
            //   };
            // }

            // var anaphylaxisCourseCodeValidate = document.getElementById("anaphylaxisCourseCode");
            // if(anaphylaxisCourseCodeValidate !== null){
            //   anaphylaxisCourseCodeValidate.oninvalid = function(e) {
            //     if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            //     {
            //       e.target.setCustomValidity("Anaphylaxis course code can't be empty");
            //     }
            //     else
            //     {
            //       e.target.setCustomValidity("");
            //     }
            //   };
            // }

            var firstAidCourseExpiryDateValidate = document.getElementById("firstAidCourseExpiryDate");
            if(firstAidCourseExpiryDateValidate !== null){
              firstAidCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("FirstAid Course Expiry Date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            var crpCourseExpiryDateValidate = document.getElementById("crpCourseExpiryDate");
            if(crpCourseExpiryDateValidate !== null){
              crpCourseExpiryDateValidate.oninvalid = function(e) {
                if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
                {
                  e.target.setCustomValidity("CRP Course Expiry Date can't be empty");
                }
                else
                {
                  e.target.setCustomValidity("");
                }
              };
            }

            // var asthmaCourseExpiryDateValidate = document.getElementById("asthmaCourseExpiryDate");
            // if(asthmaCourseExpiryDateValidate !== null){
            //   asthmaCourseExpiryDateValidate.oninvalid = function(e) {
            //     if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            //     {
            //       e.target.setCustomValidity("Asthma Course Expiry Date can't be empty");
            //     }
            //     else
            //     {
            //       e.target.setCustomValidity("");
            //     }
            //   };
            // }

            // var anaphylaxisCourseExpiryDateValidate = document.getElementById("anaphylaxisCourseExpiryDate");
            // if(anaphylaxisCourseExpiryDateValidate !== null){
            //   anaphylaxisCourseExpiryDateValidate.oninvalid = function(e) {
            //     if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
            //     {
            //       e.target.setCustomValidity("Anaphylaxis Course Expiry Date can't be empty");
            //     }
            //     else
            //     {
            //       e.target.setCustomValidity("");
            //     }
            //   };
            // }

            


        }

        validateForm()

    
    },[])

    const changeHandler=(e,name, value) => {
        
        if(name=='firstAidCourseCode')
        {
            setFirstAidCourseCode(value);
            e.target.setCustomValidity("")
        }
        else if(name=='crpCourseCode')
        {
            setCrpCourseCode(value);
            e.target.setCustomValidity("")
        }
        else if(name=='asthmaCourseCode')
        {
            setAsthmaCourseCode(value);
            e.target.setCustomValidity("")
        }
        else if(name=='anaphylaxisCourseCode')
        {
            setAnaphylaxisCourseCode(value);
            e.target.setCustomValidity("")
        }

    }

    const showCalender=(field)=>
    {   
        setDateField(field)
        if(displayDatePicker === true)
        {
            setDisplayDatePicker(false)
        }
        else
        {
            setDisplayDatePicker(true)
        }
    }

    const onChangeDate=(newDate)=>
    {    
      let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
      let year = newDate.getFullYear()
      let date = newDate.getDate()
      let fullYear = date+"-"+month+"-"+year
      
      if(dateField === "firstAidCourseExpiryDate")
      {
          setFirstAidCourseExpiryDate(fullYear)
          setShowFirstAidCourseDateModal(false)
      }
      if(dateField === "crpCourseExpiryDate")
      {
          setCrpCourseExpiryDate(fullYear)
          setShowCrpCourseDateModal(false)
      }

      if(dateField === "asthmaCourseExpiryDate")
      {
          setAsthmaCourseExpiryDate(fullYear)
          setShowAsthmaCourseDateModal(false)
          
      }
      if(dateField === "anaphylaxisCourseExpiryDate")
      {
          setAnaphylaxisCourseExpiryDate(fullYear)
          setShowAnaphylaxisCourseDateModal(false)
          
      } 
      
      if(dateField === "firstAidExpiryDate"){ 
          setFirstAidExpiryDate(fullYear)
          setFirstAidCalendar(false)
        }
        
      if(dateField === "asthmaExpiryDate"){ 
            setAsthmaExpiryDate(fullYear)
            setAsthmaCalendar(false)
        }
       
      if(dateField === "cprExpiryDate"){ 
            setCprExpiryDate(fullYear)
            setCprCalendar(false)
        }
      
      if(dateField === "anaphlaxisExpiryDate"){ 
            setAnaphlaxisExpiryDate(fullYear)
            setAnaphlaxisCalender(false)
        }

      setDateField("")
      setDisplayDatePicker(false)
    }

    const validateValues=()=>
    {
        if(firstAidCourseReminder !== "")
        {
            if(crpCourseReminder !== "")
            {
                return true
                // if(asthmaCourseReminder !== "")
                // {
                //     if(anaphylaxisCourseReminder !== "")
                //     {
                //        return true 
                //     }
                //     else
                //     {
                //         toast.error("Please select reminder for Anaphylaxis Course",{ position: 'top-center' });
                //         return false
                //     }
                // }
                // else
                // {
                //     toast.error("Please select reminder for Asthma Course",{ position: 'top-center' });
                //     return false
                // }  
            }
            else
            {
                toast.error("Please select reminder for Crp Course",{ position: 'top-center' });
                return false
            }
        }
        else
        {
            toast.error("Please select reminder for First Aid Course",{ position: 'top-center' });
            return false
        }
    }

    const goNext=async(e)=>
    {  
      e.preventDefault()

      let res = validateValues()
      if(res === true)
      {
        let courseDetails = {"courseDetails":{  firstAidCourseCode:firstAidCourseCode, crpCourseCode:crpCourseCode, 
                                                asthmaCourseCode:asthmaCourseCode, anaphylaxisCourseCode:anaphylaxisCourseCode, 
                                                firstAidCourseExpiryDate:firstAidCourseExpiryDate, crpCourseExpiryDate:crpCourseExpiryDate, 
                                                asthmaCourseExpiryDate:asthmaCourseExpiryDate,
                                                anaphylaxisCourseExpiryDate:anaphylaxisCourseExpiryDate,firstAidCourseReminder: firstAidCourseReminder,
                                                crpCourseReminder: crpCourseReminder, asthmaCourseReminder: asthmaCourseReminder, 
                                                anaphylaxisCourseReminder: anaphylaxisCourseReminder,
                                                certificateDetails: certificates ,firstAidImg: firstAidImg,
                                                crpCourseImg: crpCourseImg,
                                                asthmaCourseImg: asthmaCourseImg,
                                                anaphlaxisImg: anaphlaxisImg,
                                                
                                                firstAidExpiryDate : firstAidCourseExpiryDate , cprExpiryDate : cprExpiryDate,
                                                asthmaExpiryDate : asthmaExpiryDate , anaphlaxisExpiryDate : anaphlaxisExpiryDate
                                            }
                            }  

        dispatch(handleEducatorSignupForm(5, courseDetails,3))
      }
  
    }

    const goBack=()=>
    {
      dispatch(handleEducatorSignupForm(3))  
    }

    const selectReminder=(field, selectedValue)=>
    {
        if(field === "firstAid")
        {
            setFirstAidCourseReminder(selectedValue)
        }
        if(field === "crp")
        {
            setCrpCourseReminder(selectedValue)
        }
        if(field === "asthma")
        {
            setAsthmaCourseReminder(selectedValue)
        }
        if(field === "anaphylaxis")
        {
            setaAnaphylaxisCourseReminder(selectedValue)
        }
    }

    const showHideCalender=(field)=>
    {
        if(field === "firstAidCourseCode")
        {
            if(showFirstAidCourseDateModal === true ) 
            {
                 setShowFirstAidCourseDateModal(false);
            }
            else
            {
                 setShowFirstAidCourseDateModal(true);
            }
           
            setDateField("firstAidCourseExpiryDate")
        }

        if(field === "crpCourseCode")
        {
            if(showCrpCourseDateModal === true ) 
            {
                 setShowCrpCourseDateModal(false);
            }
            else
            {
                 setShowCrpCourseDateModal(true);
            }
           
            setDateField("crpCourseExpiryDate")
        }

        if(field === "asthmaCourseCode")
        {
            if(showAsthmaCourseDateModal === true ) 
            {
                 setShowAsthmaCourseDateModal(false);
            }
            else
            {
                 setShowAsthmaCourseDateModal(true);
            }
           
            setDateField("asthmaCourseExpiryDate")
        }

        if(field === "anaphylaxisCourseCode")
        {
            if(showAnaphylaxisCourseDateModal === true ) 
            {
                 setShowAnaphylaxisCourseDateModal(false);
            }
            else
            {
                 setShowAnaphylaxisCourseDateModal(true);
            }
           
            setDateField("anaphylaxisCourseExpiryDate")
        }
        if(field === "firstAidExpiryDate"){
        if(firstAidCalendar === true ){
                setFirstAidCalendar(false);}
        else{   setFirstAidCalendar(true); }
            setDateField("firstAidExpiryDate")
        }
        
        
        if(field === "cprExpiryDate"){
            if(cprCalendar === true ){
                    setCprCalendar(false);}
            else{   setCprCalendar(true); }
                setDateField("cprExpiryDate")
        }
        
        if(field === "asthmaExpiryDate"){
            if(asthmaCalendar === true ){
                    setAsthmaCalendar(false);}
            else{   setAsthmaCalendar(true); }
                setDateField("asthmaExpiryDate")
        }
         
        if(field === "anaphlaxisExpiryDate"){
            if(anaphlaxisCalender === true ){
                    setAnaphlaxisCalender(false);}
            else{   setAnaphlaxisCalender(true); }
                setDateField("anaphlaxisExpiryDate")
        }
        
    }
    const uploadImages = (event , value) => {
        if (
          event.target.files !== undefined &&
          Object.keys(event.target.files).length > 0 &&
          Object.keys(event.target.files).length <= 5
        ) {
          if (
            event.target.files[0].type !== "image/jpg" &&
            event.target.files[0].type !== "image/jpeg" &&
            event.target.files[0].type !== "image/png" &&
            event.target.files[0].type !== "application/pdf"
          ) {
            toast.error(
              "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
              {
                position: "top-center",
              }
            );
            return false;
          } else {
            if (event.target.files[0].size > 2000000) {
              toast.error("Image size exceeds the limit of 2MB", { position: "top-center",});
              return false;
            } else {
              let imagesArray = [];
             
              Object.values(event.target.files).map(function (key, index) {
                let imageObject = {
                  name: "",
                  path: "",
                };
                if (key.name !== undefined) {
                  imageObject["name"] = key.name;
                }
                var reader = new FileReader();
                reader.onload = function (events) {
                  imageObject["path"] = events.target.result;
                };
                imagesArray.push(imageObject);
               if( value === 'FirstAidCourse'){
                   setFirstAidImg( [...imagesArray])
               }
               if(value === 'CRPCourse'){
                   setCrpCourseImg( [...imagesArray])
               }
               if(value === 'AsthmaCourse'){
                   setAsthmaCourseImg( [...imagesArray])
               }
               if(value === 'Anaphlixis'){
                   setAnaphlaxisImg( [...imagesArray])
               }
                setCertificates(
                    [...imagesArray]
                );
                reader.readAsDataURL(key);
              });
            }
          }
        } else {
          toast.error("You can't upload more than 5 images", {  position: "top-center", });
        }
      };
    // const uploadImages=(event)=>
    // {
    //     if(event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5)
    //     {
    //         if(event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png'&& event.target.files[0].type !== 'application/pdf'){
    //             toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png, pdf', {
    //                 position: 'top-center'
    //               });
    //             return false;
    //         }else{

    //             if(event.target.files[0].size > 2000000){
    //                 toast.error('Image size exceeds the limit of 2MB', {
    //                   position: 'top-center'
    //                 });
    //                 return false;
    //             }else{
    //                 let imagesArray = []
    //                 Object.values(event.target.files).map(function(key, index) {
    //                     let imageObject = {
    //                         name: '',
    //                         path: ''
    //                     }

    //                     if(key.name !== undefined)
    //                     {
    //                         imageObject['name'] = key.name
    //                     }

    //                     var reader = new FileReader();
    //                     reader.onload = function (events) {              
    //                         imageObject['path'] = events.target.result
    //                         imagesArray.push(imageObject)
    //                         setCertificates([...imagesArray])
    //                     };
                        
    //                     reader.readAsDataURL(key);
    //                 });
    //             }
    //         }
    //     }
    //     else
    //     {
    //         toast.error("You can't upload more than 5 images", { position: 'top-center' });
    //     }
    // }

    const removeSpecificImage=(index , value)=>
    {
        let imagesArray = certificates
        imagesArray.splice(index,1)
        setCertificates([...imagesArray])
        if( value === 'FirstAidCourse'){
            setFirstAidImg( [...imagesArray])
        }
        if(value === 'CRPCourse'){
            setCrpCourseImg( [...imagesArray])
        }
        if(value === 'AsthmaCourse'){
            setAsthmaCourseImg( [...imagesArray])
        }
        if(value === 'Anaphlixis'){
            setAnaphlaxisImg( [...imagesArray])
        }
    }

    return (
      <>  
        <ToastContainer />  
        <div className="main-wrapper">
            <div className="container">
                <div className="user-pages-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="user-types-wrap course-detail-page text-center">
                            <h1 className="common-heaidng48">First Aid Certification</h1>
                            <h3 className="small-title">Please fill the required details</h3>
                            <form className="sign-up-form" onSubmit={(e)=>goNext(e)}>
                                <div className="row">
                                    <div className="col-md-12">
                                    <label className="text-left">Provide First Aid in an Education & Care Setting(Expiry date is issue date + 1 year – Reminder day)</label>
                                    <div className="row custom-row">
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                <input type="text" name= "firstAidCourseCode" required className={firstAidCourseCode ? "mdc-text-field__input active" : "mdc-text-field__input"} id="firstAidCourseCode" value ={firstAidCourseCode} aria-labelledby="my-label-id"
                                                onChange={(e)=>changeHandler(e, "firstAidCourseCode", e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20"/>
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("firstAidCourseCode")}>
                                                    <input value={firstAidCourseExpiryDate} required onkeypress="return false;" autoComplete="off" type="text" className={firstAidCourseExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="firstAidCourseExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    showFirstAidCourseDateModal === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{firstAidCourseReminder ? firstAidCourseReminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                {

                                                }
                                                <ul className="dropdown" >
                                                <>
                                                    {
                                                        reminder.map((item,index)=>
                                                            <li onClick={()=>selectReminder("firstAid",item)} >{item}</li>
                                                        )
                                                    }
                                                </>
                                                </ul>
                                            </div>  
                                        </div>

                                        <div className="col-lg-6 col-md-6 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("firstAidExpiryDate")}>
                                                    <input value={firstAidExpiryDate} required onkeypress="return false;" autoComplete="off" type="text" className={firstAidExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="firstAidExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    firstAidCalendar === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                            <input onChange={(e)=>uploadImages(e ,'FirstAidCourse')} type="file" name="file"    accept="image/jpg,image/jpeg,image/png,.pdf"  className="mdc-text-field__input attach-file" aria-labelledby="my-label-id"/>
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Upload Certificate</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                                <span className="material-icons">image</span>
                                            </label>
                                            <small className="short-description mt-25">Image can only be of the following types : jpg, jpeg, png , pdf with limit upto 2MB</small>
                                            <>
                                            {
                                                firstAidImg.length > 0?
                                                firstAidImg.map((data,index)=>
                                                    <>
                                                        <img height="100" width="100" src = {data.path} /> 
                                                        <button type="button" onClick={(e)=>{removeSpecificImage(index, 'FirstAidCourse')}} ><span className="material-icons close">close</span></button>
                                                    </>
                                                ) 
                                                : null
                                            }
                                            </>
                                        </div>



                                    </div>
                                    </div>
                                    <div className="col-md-12">
                                    <label className="text-left">Provide CPR</label>
                                    <div className="row custom-row">
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                <input type="text" name= "crpCourseCode" required className={crpCourseCode ? "mdc-text-field__input active" : "mdc-text-field__input"} id= "crpCourseCode" value ={crpCourseCode}  
                                                onChange={(e)=>changeHandler(e,"crpCourseCode", e.target.value)}  aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" />
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("crpCourseCode")}>
                                                    <input value={crpCourseExpiryDate} onkeypress="return false;" required autoComplete="off" type="text" className={crpCourseExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                    id="crpCourseExpiryDate my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    showCrpCourseDateModal === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{crpCourseReminder ? crpCourseReminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                <ul className="dropdown">
                                                <>
                                                    {
                                                        reminder.map((item,index)=>
                                                            <li onClick={()=>selectReminder("crp",item)} >{item}</li>
                                                        )
                                                    }
                                                </>
                                                </ul>
                                            </div>  
                                        </div>

                                        <div className="col-lg-6 col-md-6 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("cprExpiryDate")}>
                                                    <input value={cprExpiryDate} required onkeypress="return false;" autoComplete="off" type="text" className={cprExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="cprExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    cprCalendar === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                            <input onChange={(e)=>uploadImages(e,'CRPCourse')} type="file" name="file"    accept="image/jpg,image/jpeg,image/png,.pdf"  className="mdc-text-field__input attach-file" aria-labelledby="my-label-id"/>
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Upload Certificate</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                                <span className="material-icons">image</span>
                                            </label>
                                            <small className="short-description mt-25">Image can only be of the following types : jpg, jpeg, png , pdf with limit upto 2MB</small>
                                            <>
                                            {
                                                crpCourseImg.length > 0?
                                                crpCourseImg.map((data,index)=>
                                                    <>
                                                        <img height="100" width="100" src = {data.path} /> 
                                                        <button type="button" onClick={(e)=>{removeSpecificImage(index ,'CRPCourse')}} ><span className="material-icons close">close</span></button>
                                                    </>
                                                ) 
                                                : null
                                            }
                                            </>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-12">
                                    <label className="text-left mt-25">Asthma Course</label>
                                    <label className="text-left">Only complete Asthma and Anaphylaxis course, if not covered under Provide First Aid in an Education & Care Setting</label>                                    
                                    <div className="row custom-row">
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                <input type="text" name= "asthmaCourseCode" className={asthmaCourseCode ? "mdc-text-field__input active" : "mdc-text-field__input"} value ={asthmaCourseCode} onChange={(e)=>changeHandler(e,"asthmaCourseCode", e.target.value)} 
                                                aria-labelledby="my-label-id" id= "asthmaCourseCode" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" />
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col" >
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("asthmaCourseCode")}>
                                                    <input value={asthmaCourseExpiryDate} onkeypress="return false;" autoComplete="off" type="text" className={asthmaCourseExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="asthmaCourseExpiryDate my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    showAsthmaCourseDateModal === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{asthmaCourseReminder ? asthmaCourseReminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                <ul className="dropdown">
                                                <>
                                                    {
                                                        reminder.map((item,index)=>
                                                            <li onClick={()=>selectReminder("asthma",item)} >{item}</li>
                                                        )
                                                    }
                                                </>
                                                </ul>
                                            </div>  
                                        </div>

                                        <div className="col-lg-6 col-md-6 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("asthmaExpiryDate")}>
                                                    <input value={asthmaExpiryDate} required onkeypress="return false;" autoComplete="off" type="text" className={asthmaExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="asthmaExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    asthmaCalendar === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                            <input onChange={(e)=>uploadImages(e,'AsthmaCourse')} type="file" name="file"    accept="image/jpg,image/jpeg,image/png,.pdf"  className="mdc-text-field__input attach-file" aria-labelledby="my-label-id"/>
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Upload Certificate</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                                <span className="material-icons">image</span>
                                            </label>
                                            <small className="short-description mt-25">Image can only be of the following types : jpg, jpeg, png , pdf with limit upto 2MB</small>
                                            <>
                                            {
                                                asthmaCourseImg.length > 0?
                                                asthmaCourseImg.map((data,index)=>
                                                    <>
                                                        <img height="100" width="100" src = {data.path} /> 
                                                        <button type="button" onClick={(e)=>{removeSpecificImage(index , 'AsthmaCourse')}} ><span className="material-icons close">close</span></button>
                                                    </>
                                                ) 
                                                : null
                                            }
                                            </>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-12">
                                    <label className="text-left">Anaphylaxis Course</label>
                                    <div className="row custom-row">
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                <input type="text" name= "anaphylaxisCourseCode" className={anaphylaxisCourseCode ? "mdc-text-field__input active" : "mdc-text-field__input"} value ={anaphylaxisCourseCode} onChange={(e)=>changeHandler(e,"anaphylaxisCourseCode", e.target.value)} 
                                                aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} maxlength="20" id= "anaphylaxisCourseCode" />
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col" >
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("anaphylaxisCourseCode")}>
                                                    <input value={anaphylaxisCourseExpiryDate} onkeypress="return false;"  autoComplete="off" type="text" className={anaphylaxisCourseExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="anaphylaxisCourseExpiryDat my-label-id" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    showAnaphylaxisCourseDateModal === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 custom-col">
                                            <div className="select-field">
                                                <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                                  <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                      <span className="mdc-floating-label" id="my-label-id">{anaphylaxisCourseReminder ? anaphylaxisCourseReminder : "Set Reminder"}</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                  </span>
                                                  <span className="material-icons">arrow_drop_down</span>
                                                </label>
                                                <ul className="dropdown">
                                                <>
                                                    {
                                                        reminder.map((item,index)=>
                                                            <li onClick={()=>selectReminder("anaphylaxis",item)} >{item}</li>
                                                        )
                                                    }
                                                </>
                                                </ul>
                                            </div>  
                                        </div> 

                                        <div className="col-lg-6 col-md-6 custom-col">
                                            <div className = "calendar-wrap">
                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showHideCalender("anaphlaxisExpiryDate")}>
                                                    <input value={anaphlaxisExpiryDate} required onkeypress="return false;" autoComplete="off" type="text" className={anaphlaxisExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                    aria-labelledby="my-label-id" id="anaphlaxisExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                    <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                    <span className="material-icons">calendar_today</span>
                                                    </span>
                                                </label>    
                                                {
                                                    anaphlaxisCalender === true ?
                                                    <Calendar
                                                      onChange={onChangeDate}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                            <input onChange={(e)=>uploadImages(e, 'Anaphlixis')} type="file" name="file"    accept="image/jpg,image/jpeg,image/png,.pdf"  className="mdc-text-field__input attach-file" aria-labelledby="my-label-id"/>
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Upload Certificate</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                                <span className="material-icons">image</span>
                                            </label>
                                            <small className="short-description mt-25">Image can only be of the following types : jpg, jpeg, png , pdf with limit upto 2MB</small>
                                            <>
                                            {
                                                anaphlaxisImg.length > 0?
                                                anaphlaxisImg.map((data,index)=>
                                                    <>
                                                        <img height="100" width="100" src = {data.path} /> 
                                                        <button type="button" onClick={(e)=>{removeSpecificImage(index, 'Anaphlixis')}} ><span className="material-icons close">close</span></button>
                                                    </>
                                                ) 
                                                : null
                                            }
                                            </>
                                        </div> 
                                    </div>
                                    </div>
                                </div>
                                <div className="next-prev-btns">
                                    <button className="mdc-button mdc-button--raised" onClick = {goBack}>
                                      <span className="mdc-button__label">previous</span>
                                    </button>
                                    <button className="mdc-button mdc-button--raised green-btn">
                                    <span className="mdc-button__label">next</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>  
    )
}

export default CourseDetails;