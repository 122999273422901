import React, { useEffect,useState } from 'react';
import { login, setUserRole } from '../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWithoutLogin from '../../../../src/commonComponents/HeaderWithoutLogin/HeaderWithoutLogin';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { $ } from 'react-jquery-plugin';
import { forgotPasswordReset } from '../../../functions/api';
import { Link } from 'react-router-dom';


//Import Images
import media1 from '../../../../src/images/media1.png';

const ResetPassword=(props) => {

    const [name, setName] = useState("")
    const [tempPass, setTempPass] = useState("")
    const [newPass, setNewPass] = useState("")

    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(()=>{

        setName(window.localStorage.getItem('forgotPasswordEmail'))

        $(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})

    },[])

    const navigateUser=(e)=>
    {
        e.preventDefault()
        history.push('/')
    }

    async function resetPasswordSubmission(e) {
        e.preventDefault();
        let data = {name: name, temp_pass: tempPass, new_pass : newPass}
        let response = await forgotPasswordReset(data);
        if(response.status === 200){
            toast.success(response.data.data.message, {
                position: 'top-center'
            });
            setName('')
            setTempPass('')
            setNewPass('')
            localStorage.removeItem('forgotPasswordEmail');

            setTimeout(function(){
                history.push('/');
            }, 3000);
        }else{
            toast.error(response.message, {
                position: 'top-center'
            });
        }
    }

    return (
        <>
            <ToastContainer />
            <HeaderWithoutLogin />
            <div className="main-wrapper">
                <div className="container"> 
                    <div className="user-pages-wrap">
                        <div className="row">
                                <div className="col-md-5">
                                    <div className="login-form">
                                        <h1 className="common-heaidng48">Reset Password</h1>

                                        <form onSubmit={resetPasswordSubmission}>

                                            <label className="mdc-text-field mdc-text-field--outlined">
                                              <input onChange={(e) => setTempPass(e.target.value)} value={tempPass} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                                              <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">Temporary Password</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                              </span>
                                            </label>

                                            <label className="mdc-text-field mdc-text-field--outlined">
                                              <input onChange={(e) => setNewPass(e.target.value)} value={newPass} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                                              <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">New Password</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                              </span>
                                            </label>

                                            <button className="mdc-button mdc-button--raised green-btn">
                                              <span className="mdc-button__label">Reset Password</span>
                                            </button>
                                        </form>

                                        <Link to={`/user-roles`} className="forget-pwd-link mt-25">Don’t have an account? Register</Link>
                                    </div>  
                                </div>  

                                <div className="col-md-7">
                                    <div className="media-block">
                                        <img src={media1} />
                                    </div>  
                                </div>  
                        </div>
                    </div>      
                </div>  
            </div>
        </>    
    )
}

export default ResetPassword;