import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

//Import Images
import logo from '../../images/logo.svg';

const HeaderWithoutLogin=() => {
    
    const location = useLocation();

    return (
        
        <div className="top-bar">
            <div className="container">	
                <Link to={`/`} className="main-logo"><img src={logo} /></Link>
                <span className="mdc-button__ripple"></span>
                {
                    location.pathname === '/user-roles' ||  location.pathname === '/forget-password' || location.pathname === '/reset-password' 
                    ?
                    <Link className="mdc-button mdc-button--outlined custom-outline-btn" to={`/`}><span className="mdc-button__label">Login</span></Link>
                    :
                    //   location.pathname === '/' ?
                    //     <Link className="mdc-button mdc-button--outlined custom-outline-btn" to={`/user-roles`}><span className="mdc-button__label">Create an account</span></Link>
                    //   :
                      null
                }
            </div>
        </div>
    )
}

export default HeaderWithoutLogin;