import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, handleEducatorSignupForm, getAuthToken, createProfile, login, setUserRole } from '../../../redux/actions/auth';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { uploadImage, deleteRegisteredUser, addOccupants, addBusinessDays, automaticFolderGeneratorAPI } from '../../../functions/api';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { $ } from 'react-jquery-plugin';
const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]
const   Vehicle = () => {

    const history = useHistory();
    const dispatch = useDispatch()
    const store = useSelector(state=>state.auth)

    let occupantsResponse = []
    let occupantsArray = []
    let adultOccupantsArray = []
    let adultOccupantsResponse = []

    const [formAllData, setFormAllData] = useState([])

    const [vehicleMake, setVehicleMake]= useState("") 
    const [vehicleModel, setVehicleModel]= useState("")
    const [vehicleYear, setVehicleYear]= useState("")
    const [registration, setRegistration]= useState("")
    const [licenceNumber, setLicenceNumber]= useState("")
    const [showLoader, setShowLoader] = useState(false)


    const [value, setValue] = useState(new Date());
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [dateField, setDateField] = useState('')

    const [registryExpiryDate, setRegistryExpiryDate] = useState('')
    const [maintenanceExpiryDate, setMaintenanceExpiryDate] = useState('')
    const [restraintExpiryDate, setRestraintExpiryDate] = useState('')

    const [showRegistryExpiryDateModal, setShowRegistryExpiryDateModal] = useState(false)
    const [showMaintenanceDateModal, setShowMaintenanceExpiryDateModal] = useState(false)
    const [showRestraintExpiryDateModal, setShowestraintExpiryDateModal] = useState(false)


    const [vehicleMaintenanceDate, setVehicleMaintenanceDate] = useState('')
    
    const [addVehicleAuditRemainderDate,setAddVehicleAuditRemainderDate]=useState('')
    const [showVehicleMaintenanceDateModal, setShowVehicleMaintenanceDateModal] = useState(false)
    const [vehicleAuditDate, setVehicleAuditDate] = useState('')
    const [showVehicleAuditDateModal, setShowVehicleAuditDateModal] = useState(false)
    const [licenceExpiryDate, setLicenceExpiryDate] = useState('')   
    const [showLicenceExpiryDateModal, setShowLicenceExpiryDateModal] = useState(false)
    const [addLicenceExpiryRemainderDate,setAddLicenceExpiryRemainderDate]=useState('')
    const [addVehicleMaintenanceRemainderDate,setAddVehicleMaintenanceRemainderDate]=useState('')
    const [selectedOperationalDays, setSelectedOperationalDays] = useState([])
    const [selectedOperationalTime, setSelectedOperationalTime] = useState([])
    const [opertionalHoursTimeStamp, setOpertionalHoursTimeStamp] = useState(null)
    const [closedTimeStamp, setClosedTimeStamp] = useState(null)
    const [addVehicleRegistrationReminderDate, setAddVehicleRegistrationReminderDate] = useState("")

    // New Fields 
    const [ vehicleMaintenanceExpiryDate , setVehicleMaintenanceExpiryDate] = useState('')
    const [vehicleRestraintAuditExpiryDate , setVehicleRestraintAuditExpiryDate] = useState('')

    const [vehicleMaintenanceCalendar , setVehicleMaintenanceCalendar] = useState(false)
    const [vehicleRestraintCalendar , setVehicleRestraintCalendar] = useState(false)

    useEffect(()=>{
         $(function() { 
            $('.dropdown-toggle').click(function() { 
                $(this).next('.dropdown').slideToggle();
            });

            $(document).click(function(e) { 
            var target = e.target; 
            if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
              { $('.dropdown').slideUp(); }
            });
        });

        $(".mobile-toggel-icon").click(function(){
          $(".header-bar .right-block .menu-list").slideToggle();
        });

        if(window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined){
          let storageValue = JSON.parse(window.localStorage.getItem('educatorSignupDetail'));
          console.log('storageValue',storageValue);
          setFormAllData(storageValue)
          let newArray = [],timeArray = []
          if(storageValue[2].businessInformation.setSelectedDays.length > 0){
            storageValue[2].businessInformation.setSelectedDays.map((data)=>{
                if(data.open !== "" || data.close !== "") {
                  let openingHours = data.open.split(':')        
                  let openingHoursTimeStamp = openingHours[0]* 3600 + openingHours[1]*60

                  let closingHours = data.close.split(':') 
                  let closingHoursTimeStamp = closingHours[0]* 3600 + closingHours[1]*60 

                  timeArray.push({"from": openingHoursTimeStamp ,"to": closingHoursTimeStamp})

                  if(data.day === "Sun")
                    newArray.push({ "target_id": 268, "target_type": "taxonomy_term", "url": "/taxonomy/term/268" })
                  
                  if(data.day === "Mon")
                    newArray.push({ "target_id": 269, "target_type": "taxonomy_term", "url": "/taxonomy/term/269" })
                  
                  if(data.day === "Tue")
                    newArray.push({ "target_id": 270, "target_type": "taxonomy_term", "url": "/taxonomy/term/270" })
                  
                  if(data.day === "Wed")
                    newArray.push({ "target_id": 274, "target_type": "taxonomy_term", "url": "/taxonomy/term/274" })
                  
                  if(data.day === "Thu")
                    newArray.push({ "target_id": 271, "target_type": "taxonomy_term", "url": "/taxonomy/term/271" })
                  
                  if(data.day === "Fri")
                    newArray.push({ "target_id": 272, "target_type": "taxonomy_term", "url": "/taxonomy/term/272" })
                  
                  if(data.day === "Sat")
                    newArray.push({ "target_id": 273, "target_type": "taxonomy_term", "url": "/taxonomy/term/273" })
                }
            })
          }

        setSelectedOperationalDays(newArray)
        setSelectedOperationalTime(timeArray) 

        if(storageValue[2].businessInformation.opertionalHours !== ""){
            let opertionalHoursTimeArray = storageValue[2].businessInformation.opertionalHours.split(':')        
            let hours = opertionalHoursTimeArray[0]
            let minutes = opertionalHoursTimeArray[1]
        }
        if(storageValue[2].businessInformation.closingHours !== "") {
            let closingHoursTimeArray = storageValue[2].businessInformation.closingHours.split(':')        
            let hours = closingHoursTimeArray[0]
            let minutes = closingHoursTimeArray[1]
        }         
          setSelectedOperationalDays(newArray)
        }
        validateForm();
    },[])

    function validateForm(){
      var vehicleMakeValidate = document.getElementById("vehicleMake");
      if(vehicleMakeValidate !== null){
        vehicleMakeValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null){
            e.target.setCustomValidity("Vehicle make can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var vehicleModelValidate = document.getElementById("vehicleModel");
      if(vehicleModelValidate !== null){
        vehicleModelValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Vehicle model can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var vehicleYearValidate = document.getElementById("vehicleYear");
      if(vehicleYearValidate !== null){
        vehicleYearValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Vehicle year date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var registrationValidate = document.getElementById("registration");
      if(registrationValidate !== null){
        registrationValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Registration can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var registryExpiryDateValidate = document.getElementById("registryExpiryDate");
      if(registryExpiryDateValidate !== null){
        registryExpiryDateValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Registration Expiry date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var registrationVehicleMaintenanceValidate = document.getElementById("vehicleMaintenanceDate");
      if(registrationVehicleMaintenanceValidate !== null){
        registrationVehicleMaintenanceValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Vehicle Maintenance Date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var vehicleMaintenanceDateValidate = document.getElementById("vehicleMaintenanceDate");
      if(vehicleMaintenanceDateValidate !== null){
        vehicleMaintenanceDateValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Vehicle maintenance date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var vehicleAuditDateValidate = document.getElementById("vehicleAuditDate");
      if(vehicleAuditDateValidate !== null){
        vehicleAuditDateValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Vehicle audit date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var licenceNumberValidate = document.getElementById("licenceNumber");
      if(licenceNumberValidate !== null){
        licenceNumberValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Licence number can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }

      var licenceExpiryDateValidate = document.getElementById("licenceExpiryDate");
      if(licenceExpiryDateValidate !== null){
        licenceExpiryDateValidate.oninvalid = function(e) {
          if(e.target.value === "" || e.target.value === undefined ||e.target.value === null)
          {
            e.target.setCustomValidity("Licence expiry date can't be empty");
          }
          else
          {
            e.target.setCustomValidity("");
          }
        };
      }
  }   

    //====================set Remainder=============================

    const selectReminder=(field, selectedValue)=>{
        if(field === "VehicleMaintenance") {
            setAddVehicleMaintenanceRemainderDate(selectedValue)
        }
        if(field === "AuditRemainder"){
            setAddVehicleAuditRemainderDate(selectedValue)

        }
        if(field==="ExpiryRemainderDate"){
            setAddLicenceExpiryRemainderDate(selectedValue)
        }

        if(field === "VehicleRegistrationReminderDate") {
            setAddVehicleRegistrationReminderDate(selectedValue)
        }   
    }

    //==============================================================

    const showCalender=(field)=>
    {   
        setDateField(field)
        if(displayDatePicker === true){
            setDisplayDatePicker(false)
        }
        else {
            setDisplayDatePicker(true)
        }
    }

    const onChangeDate=(newDate)=>{    
        let month = newDate.getMonth() < 12 ? newDate.getMonth()+1 : 1
        let year = newDate.getFullYear()
        let date = newDate.getDate()
        let fullYear = date+"-"+month+"-"+year

        if(dateField === "registryExpiryDate")
        {
            setRegistryExpiryDate(fullYear)
            setShowRegistryExpiryDateModal(false)
        }
        if(dateField === "vehicleMaintenanceDate")
        {
            setVehicleMaintenanceDate(fullYear)
            setShowVehicleMaintenanceDateModal(false)
        }
       
        if(dateField === "vehicleAuditDate")
        {
            setVehicleAuditDate(fullYear)
            setShowVehicleAuditDateModal(false)
            
        }
        if(dateField === "licenceExpiryDate")
        {
            setLicenceExpiryDate(fullYear)
            setShowLicenceExpiryDateModal(false)
        }
        if(dateField === "vehicleMaintenanceExpiryDate")
        {
          const fullYear = moment(newDate).format('YYYY-MM-DD')
            setVehicleMaintenanceExpiryDate(fullYear)
            setVehicleMaintenanceCalendar(false)
        }
        if(dateField === "vehicleRestraintAuditExpiryDate")
        {
            const fullYear = moment(newDate).format('YYYY-MM-DD')
            setVehicleRestraintAuditExpiryDate(fullYear)
            setVehicleRestraintCalendar(false)
        }

        setDateField("")
        setDisplayDatePicker(false)
    }

    const changeHandler=(e,name, value) => {
        if(name === "vehilceMake")
        {
           setVehicleMake(value) 
           e.target.setCustomValidity("")
        }
        if(name === "vehilceModel")
        {
           setVehicleModel(value) 
           e.target.setCustomValidity("")
        }
        if(name === "vehilceYear")
        {
          if(value.length <= 4)
          {
            setVehicleYear(value.replace(/\D/g, "")) 
          }
           
           e.target.setCustomValidity("")
        }
        if(name === "registration")
        {
           setRegistration(value) 
           e.target.setCustomValidity("")
        }
        if(name === "licenceNumber")
        {
           setLicenceNumber(value)
           e.target.setCustomValidity("") 
        }
    }

    const validatingFields=()=> {
        if(addVehicleRegistrationReminderDate !== "")
        {
            if(addVehicleMaintenanceRemainderDate !== "")
            {
                if(addVehicleAuditRemainderDate !== "")
                {
                    if(addLicenceExpiryRemainderDate !== "")
                    {
                        return true
                    }
                    else
                    {
                       toast.error("Please enter the Licence Expiry Reminder",{ position: 'top-center'}); 
                       return false
                    }  
                }
                else
                {
                   toast.error("Please enter the Vehicle Audit Reminder",{ position: 'top-center'}); 
                   return false
                }   
            }
            else
            {
               toast.error("Please enter the Vehicle Maintenance Reminder",{ position: 'top-center'}); 
               return false
            } 
        }
        else
        {
           toast.error("Please enter the Vehicle Registration Reminder",{ position: 'top-center'}); 
           return false
        }
    }

    const goNext = async(e) => { 
      e.preventDefault()
      setShowLoader(true)
      console.log('formAllData', formAllData);
      if(validatingFields()){
        let registerResponseData = await dispatch(registerUser(formAllData[0]));
        console.log('registerResponseData', registerResponseData);
        if(registerResponseData.status == 200){
          const response = await automaticFolderGeneratorAPI(registerResponseData.data.uid[0].value);
          const message = response.data.data.msg;
          // toast.success(message);
 
          let username = formAllData[0].basicDetail.name;
          let password = formAllData[0].basicDetail.password;
            
          let authResponse = await dispatch(getAuthToken(username, password)) 

          if(authResponse.status === 200)
          {
            let imageName = formAllData[0].basicDetail.imageName
            let imageData = formAllData[0].basicDetail.imageData

            let imageRes = await uploadImage(imageName, imageData)
            let field_certificate_document, field_cpr_certificate,field_asthma_course_certificate, field_anaphylaxis_certificate
            
            if(formAllData[3].courseDetails.firstAidImg.length > 0){
              let firstImg = await uploadImage(formAllData[3].courseDetails.firstAidImg[0].name, formAllData[3].courseDetails.firstAidImg[0].path)
              if(firstImg.status == 201){
                field_certificate_document = firstImg.data.data.fid[0].value
              }
            }
            if(formAllData[3].courseDetails.crpCourseImg.length > 0){
              let cprImg = await uploadImage(formAllData[3].courseDetails.crpCourseImg[0].name, formAllData[3].courseDetails.crpCourseImg[0].path)
              if(cprImg.status == 201){
                field_cpr_certificate = cprImg.data.data.fid[0].value
              }
            }
            if(formAllData[3].courseDetails.asthmaCourseImg.length > 0){
              let asthmaImg = await uploadImage(formAllData[3].courseDetails.asthmaCourseImg[0].name, formAllData[3].courseDetails.asthmaCourseImg[0].path)
              if(asthmaImg.status == 201){
                field_asthma_course_certificate = asthmaImg.data.data.fid[0].value
              }
            }
            if(formAllData[3].courseDetails.anaphlaxisImg.length > 0){
              let anaphylaxisImg = await uploadImage(formAllData[3].courseDetails.anaphlaxisImg[0].name, formAllData[3].courseDetails.anaphlaxisImg[0].path)
              if(anaphylaxisImg.status == 201){
                field_anaphylaxis_certificate = anaphylaxisImg.data.data.fid[0].value
              }
            }
           

            if(imageRes.status === 201){
              let _dob = formAllData[0].basicDetail.dob ? moment(formAllData[0].basicDetail.dob,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _insuranceExpiryDate = formAllData[4].insuranceDetailData.expiryDate ? moment(formAllData[4].insuranceDetailData.expiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _ceasedDate = formAllData[2].businessInformation.ceasedDate ? moment(formAllData[2].businessInformation.ceasedDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _commencementDate = formAllData[2].businessInformation.commencementDate ? moment(formAllData[2].businessInformation.commencementDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _educatorBlueCardExpiryDate = formAllData[1].residentialOccupant.educatorBlueCardExpiryDate ? moment(formAllData[1].residentialOccupant.educatorBlueCardExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _licenceExpiryDate = licenceExpiryDate ? moment(licenceExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _registryExpiryDate = registryExpiryDate ? moment(registryExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _vehicleAuditDate = vehicleAuditDate ? moment(vehicleAuditDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _vehicleMaintenanceDate = vehicleMaintenanceDate ? moment(vehicleMaintenanceDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _educatorBlueCardSlightedDate = formAllData[1].residentialOccupant.educatorBlueCardSlightedDate ? moment(formAllData[1].residentialOccupant.educatorBlueCardSlightedDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _anaphylaxisCourseExpiryDate = formAllData[3].courseDetails.anaphylaxisCourseExpiryDate ? moment(formAllData[3].courseDetails.anaphylaxisCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _asthmaCourseExpiryDate = formAllData[3].courseDetails.asthmaCourseExpiryDate ? moment(formAllData[3].courseDetails.asthmaCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _crpCourseExpiryDate = formAllData[3].courseDetails.crpCourseExpiryDate ? moment(formAllData[3].courseDetails.crpCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _firstAidCourseExpiryDate = formAllData[3].courseDetails.firstAidCourseExpiryDate ? moment(formAllData[3].courseDetails.firstAidCourseExpiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
              let _addVehicleMaintenanceRemainderDate=addVehicleMaintenanceRemainderDate
              let _addVehicleAuditRemainderDate=addVehicleAuditRemainderDate
              let _addLicenceExpiryRemainderDate=addLicenceExpiryRemainderDate
              let _addVehicleRegistrationReminderDate=addVehicleRegistrationReminderDate 
              let _maintenanceExpiryDate = vehicleMaintenanceExpiryDate ? moment(vehicleMaintenanceExpiryDate).format('YYYY-MM-DD') : null
              let _restraintAuditExpiryDate =  vehicleRestraintAuditExpiryDate ? moment(vehicleRestraintAuditExpiryDate).format('YYYY-MM-DD') : null


              let dataObject = {   
                "type": "educator",      
              
                  "uid": [
                  {
                  "target_id": registerResponseData.data.uid[0].value
                  }
                  ],
                  "field_profile_picture" : [{"target_id" : imageRes.data.data.fid[0].value}],
                  "field_asthma_course" : [{ "value" : formAllData[3].courseDetails.asthmaCourseCode}],    
                  "field_cpr_course" : [{ "value" : formAllData[3].courseDetails.crpCourseCode}],
                  "field_first_aid_course" : [{ "value" : formAllData[3].courseDetails.firstAidCourseCode}],
                  "field_insurance_provider" : [{ "value" : formAllData[4].insuranceDetailData.insuranceProvider}],       
                  "field_policy_number" : [{ "value" : formAllData[4].insuranceDetailData.policyNumber}],
                  "field_public_reminder_date": [{ "value": formAllData[4].insuranceDetailData.reminder }],
                  "field_d_o_b" : [{ "value" : _dob}],
                  "field_postcode": [{ "value": formAllData[0].basicDetail.postcode }],
                  "field_street_address": [{ "value": formAllData[0].basicDetail.address }],
                  "field_issue_date" : [{"value" :  formAllData[2].businessInformation.qualificationIssueDate ? moment(formAllData[2].businessInformation.qualificationIssueDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null}],
                  "field_insurance_expiry_date" : [{ "value" : _insuranceExpiryDate }],
                  "field_qualification" : [{ "value" : formAllData[2].businessInformation.qualification}],
                  "field_ceased_date" : [{ "value" : _ceasedDate}],
                  "field_commencement_date" : [{ "value" : _commencementDate}],
                  "field_blue_card_" : [{ "value" : formAllData[1].residentialOccupant.educatorBlueCard}],
                  "field_educator_reminder": [{ "value":  formAllData[1].residentialOccupant.educatorReminder }],
                  "field_annual_assessment_completi": [{ "value": (formAllData[2].businessInformation.annualAssessmentCompletionDate ? moment(formAllData[2].businessInformation.annualAssessmentCompletionDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null) }],
                  "field_business_expiry_date" : [{"value" : (formAllData[2].businessInformation.annualAssessmentExpiryDate ? moment(formAllData[2].businessInformation.annualAssessmentExpiryDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null)}],
                  "field_set_reminder_frequency": [{ "value": formAllData[2].businessInformation.annualAssessmentReminder }],
                  "field_blue_card_expiry_date" : [{ "value" : _educatorBlueCardExpiryDate}],
                  "field_certificate_document" : [{"target_id" : field_certificate_document}],
                  "field_cpr_certificate" : [{"target_id" : field_cpr_certificate}],
                  "field_asthma_course_certificate": [{"target_id" : field_asthma_course_certificate}],
                  "field_anaphylaxis_certificate": [{"target_id" : field_anaphylaxis_certificate}],
                  "field_public_reminder_date": [{"value" : formAllData[4].insuranceDetailData.reminder}],
                  "field_licence_number" : [{ "value" : licenceNumber}],
                  "field_registration" : [{ "value" : registration}],       
                  "field_licence_expiry_date" : [{ "value" : _licenceExpiryDate}],
                  "field_registration_expiry_date" : [{ "value" : _registryExpiryDate}],      
                  "field_vehicle_audit_date" : [{ "value" : _vehicleAuditDate}],
                  "field_vehicle_maintenance_date" : [{ "value" : _vehicleMaintenanceDate}],
                  "field_signed_date" : [{ "value" : _educatorBlueCardSlightedDate}],
                  "field_anaphylaxis_course" : [{ "value" : formAllData[3].courseDetails.anaphylaxisCourseCode}],
                  "field_anap_course_complete_date" :[{ "value" : _anaphylaxisCourseExpiryDate }],
                  "field_anaphylxis_course_reminder" :[{ "value" : formAllData[3].courseDetails.anaphylaxisCourseReminder}],
                  "field_asth_course_complete_date" : [{ "value" : _asthmaCourseExpiryDate }],
                  "field_asthma_course_reminder" : [{ "value" : formAllData[3].courseDetails.asthmaCourseReminder}],
                  "field_cpr_course_reminder" : [{ "value" : formAllData[3].courseDetails.crpCourseReminder}],
                  "field_cpr_course_completion_date" : [{ "value" : _crpCourseExpiryDate}],
                  "field_first_aid_course_reminder" : [{ "value" : formAllData[3].courseDetails.firstAidCourseReminder}],
                  "field_first_aid_completion_date" : [{ "value" : _firstAidCourseExpiryDate}],
                  "field_address" : [{"value" : formAllData[0].basicDetail.address}],
                  "field_vehicle_make" : [{ "value" : vehicleMake}],
                  "field_vehicle_model" : [{ "value" : vehicleModel }],
                  "field_vehicle_year" : [{ "value" : vehicleYear}],
                  'field_vehicle_expiry_date' : [{'value' : vehicleMaintenanceExpiryDate ?vehicleMaintenanceExpiryDate : null}],
                  'field_vehicle_restraint_expiry' :[{'value' : vehicleRestraintAuditExpiryDate ?vehicleRestraintAuditExpiryDate  : null}],
                  "field_suburb_name" : [{"value" : formAllData[0].basicDetail.suburbName}],
                  "field_home_number" : [{"value" : formAllData[0].basicDetail.homeNumber}],
                  "field_maintenance_reminder_date" : [{"value" : _addVehicleMaintenanceRemainderDate }],
                  "field_restraint_audit_reminder" : [{"value" : _addVehicleAuditRemainderDate }],
                  "field_license_exp_reminder_date" : [{"value" : _addLicenceExpiryRemainderDate}],
                  "field_reg_exp_reminder_date" : [{"value" : _addVehicleRegistrationReminderDate}] ,
                  "field_first_aid_expiry_date" : [{"value" :  formAllData[3].courseDetails.firstAidExpiryDate ? moment(formAllData[3].courseDetails.firstAidExpiryDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                  "field_cpr_expiry_date" : [{"value" : formAllData[3].courseDetails.cprExpiryDate ? moment(formAllData[3].courseDetails.cprExpiryDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                  "field_asthma_expiry_date" : [{"value" : formAllData[3].courseDetails.asthmaExpiryDate ? moment(formAllData[3].courseDetails.asthmaExpiryDate , 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],
                  "field_anaphylaxis_expiry_date" : [{"value" : formAllData[3].courseDetails.anaphlaxisExpiryDate ? moment(formAllData[3].courseDetails.anaphlaxisExpiryDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : null }],

                
              }


               console.log("dataObject-dataObject-dataObject",dataObject);
              if(formAllData[1].residentialOccupant.allOccupantDetails.length>0 && formAllData[1].residentialOccupant.allOccupantDetails[0].name !== "") {
                formAllData[1].residentialOccupant.allOccupantDetails.map(async(dataItems,index) => {
                  let _expiryDate = dataItems.expiryDate ? moment(dataItems.expiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                  let _occDob = dataItems.dob ? moment(dataItems.dob,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                  let _occSightedDate = dataItems.sightedDate ? moment(dataItems.sightedDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                  let _vehicleYear = vehicleYear ? moment(vehicleYear,'DD-MM-YYYY').format('YYYY-MM-DD') : null


                  let occupantObject = {
                    "type":[{
                      "target_id":"all_occupants",
                      "target_type": "paragraphs_type"
                    }],
                  
                    "field_occupant_date_of_birth":[{"value": _occDob}],
                    "field_occupant_first_name":[{"value": dataItems.name}], 
                    "field_occupant_last_name":[{"value": dataItems.lastName}], 
                  }


                  let occupantResponse = await addOccupants(occupantObject, authResponse.data)
                    
                  if(occupantResponse.status === 201)
                  {
                    if(formAllData[1].residentialOccupant.allAdultOccupant.length>0 && formAllData[1].residentialOccupant.allAdultOccupant[0].name !== "")
                    {
                      formAllData[1].residentialOccupant.allAdultOccupant.map(async(items,i)=>
                      {
                        let _adultOccupantExpiryDate = items.expiryDate ? moment(items.expiryDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                        let _adultOccupantDob = items.dob ? moment(items.dob,'DD-MM-YYYY').format('YYYY-MM-DD') : null
                        let _adultOccupantDobSightedDate = items.sightedDate ? moment(items.sightedDate,'DD-MM-YYYY').format('YYYY-MM-DD') : null  

                          let occupantObject = {
                            "type":[{
                              "target_id":"adult_occupants",
                              "target_type": "paragraphs_type"
                            }],
                            "field_occupant_blue_card":[{"value": items.blueCard}],
                            "field_occupant_card_expiry_date":[{"value":_adultOccupantExpiryDate}],
                            "field_occupant_date_of_birth":[{"value":_adultOccupantDob}],
                            "field_occupant_date_signed":[{"value":_adultOccupantDobSightedDate}],
                            "field_occupant_first_name":[{"value":items.name}],
                            "field_occupant_last_name":[{"value":items.lastName}],
                            "field_reminder_blue_card": [{ "value": items.adultOccupantReminder ?items.adultOccupantReminder : null }]
                          }  


                        let adultOccupantResponse = await addOccupants(occupantObject, authResponse.data)
                          
                        if(adultOccupantResponse.status === 201)
                        {
                            createProfileWithBothOccupants(authResponse.data,dataObject,username,password,registerResponseData.data.uid[0].value,occupantResponse.data.data,index+1,adultOccupantResponse.data.data,i+1 )
                        }
                        else
                        {
                          setShowLoader(false)
                          toast.error("Add Occupants Issue: "+adultOccupantResponse.message,{ position: 'top-center'}); //occupants
                          await deleteRegisteredUser(registerResponseData.data.uid[0].value, authResponse.data) 
                        }  
                      })
                      
                    }
                      
                  }
                  else
                  {
                    setShowLoader(false)
                    toast.error("Add Occupants Issue: "+occupantResponse.message,{ position: 'top-center'}); //occupants
                    await deleteRegisteredUser(registerResponseData.data.uid[0].value, authResponse.data) 
                  }

                })
                
              }
              else {
                await createNewUserProfile(authResponse.data, dataObject,username,password,registerResponseData.data.uid[0].value)
              } 
            }
            else {
                setShowLoader(false)
                toast.error("Image Issue: "+imageRes.message,{ position: 'top-center'});  // auth api error message
                await deleteRegisteredUser(registerResponseData.data.uid[0].value, authResponse.data) 
            }
          } else {
              setShowLoader(false)
              toast.error("Auth token Issue: "+authResponse.message,{ position: 'top-center'});  // auth api error message
          }
          if(registerResponseData.data.field_app_role[0].value == 'educator'){
            window.localStorage.setItem('uid', registerResponseData.data.uid[0].value)
            window.localStorage.setItem('name', registerResponseData.data.name[0].value)
            window.localStorage.setItem('user_role', registerResponseData.data.field_app_role[0].value)
            window.localStorage.setItem('password', formAllData[0].basicDetail.password)
            history.push(`/educators-dashboard`);
            window.localStorage.removeItem("educatorSignupDetail");
          }else{
            history.push('/co-ordination-unit-dashboard');
          }
        }
        else{
            toast.error("Register Issue: "+registerResponseData.message,{ position: 'top-center'});  // register api error message
        }
      }
      setShowLoader(false)
    }

    const createProfileWithBothOccupants=async(authToken,dataObject,username,password,deleteId,occupantObject,occupantObjectLength,adultOccupantObject,adultOccupantObjectLength)=>
    {     
      occupantsArray.push(occupantObject)
      adultOccupantsArray.push(adultOccupantObject)
      if(occupantObjectLength === formAllData[1].residentialOccupant.allOccupantDetails.length)
      {
        occupantsArray.map(data=>
        {
          if(data.id !== undefined && data.id.length >= 1)
          {
            occupantsResponse.push({"target_id" : data.id[0].value, "target_revision_id":data.revision_id[0].value})
          }
        })

        Object.assign(dataObject, {
          "field_all_occupants": occupantsResponse
        });

        if(adultOccupantObjectLength === formAllData[1].residentialOccupant.allAdultOccupant.length)
        {
          adultOccupantsArray.map(data=>
          {
            if(data.id !== undefined && data.id.length >= 1)
            {
              adultOccupantsResponse.push({"target_id" : data.id[0].value, "target_revision_id":data.revision_id[0].value})
            }
          })  

          Object.assign(dataObject, {
            "field_adult_occupants": adultOccupantsResponse
          });


          // if(selectedOperationalDays.length > 0 && formAllData[2].businessInformation.isDaysSelected === true)
          if(selectedOperationalDays.length > 0)
          {
            setShowLoader(false)
            let daysIds = []
            selectedOperationalDays.map(async(data,index)=>
            {
                let _days = []
                let _time = []
                _days.push(data)
                _time.push(selectedOperationalTime[index])
                let object= {
                              "type":[{
                                "target_id":"business_days",
                                "target_type": "paragraphs_type"
                              }],
                              "field_operational_days": _days,
                              "field_operational_hours": _time
                            }

                let _businessDays = await addBusinessDays(object, authToken)
                  
                if(_businessDays.status === 201)
                {
                    let _id = {"target_id" :_businessDays.data.data.id[0].value,"target_revision_id" :_businessDays.data.data.revision_id[0].value}
                    daysIds.push(_id)
                   
                    if(selectedOperationalDays.length === daysIds.length)
                    {
                        Object.assign(dataObject, {
                          "field_business_operational_days": daysIds
                        });

                      let createProfileResponse = await dispatch(createProfile(authToken,dataObject)) 

                      if(createProfileResponse.status === 201)
                      {
                          setShowLoader(false)
                          let loginResponseData = await  dispatch(login(username, password))
                          if(loginResponseData == undefined){
                             toast.error("Login failed wrong user credentials.",{
                                  position: 'top-center'
                             });
                          }else{
                              let userRole = window.localStorage.getItem('user_role');
                              if(userRole !== null || userRole !== ""){
                                  if(userRole === 'educator'){
                                      dispatch(setUserRole(1))
                                      history.push(`/educators-dashboard`);
                                  }  
                              }else{
                                  toast.error("User role not specified",{ position: 'top-center'});
                              }
                          }

                      }
                      else
                      {
                          setShowLoader(false)
                          toast.error("Profile Creation Issue: "+createProfileResponse.message,{ position: 'top-center'}); //signup  
                          await deleteRegisteredUser(deleteId, authToken) 
                      }
                    }
                }
                else
                {
                  toast.error(_businessDays.message,{
                      position: 'top-center'
                  });
                }
            })
          }
        
        } 
      }
    }

    const createNewUserProfile=async(authToken, dataObject, username, password, deleteId)=>
    {
        if(selectedOperationalDays.length > 0 && formAllData[2].businessInformation.isDaysSelected === true)
        {
            setShowLoader(false)
            let daysIds = []
            selectedOperationalDays.map(async(data,index)=>
            {
                let _days = []
                let _time = []
                _days.push(data)
                _time.push(selectedOperationalTime[index])
                let object= {
                              "type":[{
                                "target_id":"business_days",
                                "target_type": "paragraphs_type"
                              }],
                              "field_operational_days": _days,
                              "field_operational_hours": _time
                            }
                let _businessDays = await addBusinessDays(object, authToken)
                  
                if(_businessDays.status === 201)
                {
                    let _id = {"target_id" :_businessDays.data.data.id[0].value,"target_revision_id" :_businessDays.data.data.revision_id[0].value}
                    daysIds.push(_id)
                   
                    if(selectedOperationalDays.length === daysIds.length)
                    {
                        Object.assign(dataObject, {
                          "field_business_operational_days": daysIds
                        });

                        let createProfileResponse = await dispatch(createProfile(authToken,dataObject))                  
                        if(createProfileResponse.status === 201)
                        {
                            setShowLoader(false)
                            let loginResponseData = await  dispatch(login(username, password))
                            if(loginResponseData == undefined){
                               toast.error("Login failed wrong user credentials.",{
                                    position: 'top-center'
                               });
                            }else{
                                let userRole = window.localStorage.getItem('user_role');
                                if(userRole !== null || userRole !== ""){
                                    if(userRole === 'educator'){
                                        dispatch(setUserRole(1))
                                        history.push(`/educators-dashboard`);
                                    }  
                                }else{
                                    toast.error("User role not specified",{ position: 'top-center'});
                                }
                            }
                        }
                        else
                        {
                            setShowLoader(false)
                            toast.error("Profile Creation Issue: "+createProfileResponse.message,{ position: 'top-center'}); //signup  
                            await deleteRegisteredUser(deleteId, authToken) 
                        }
                    }
                }
                else
                {
                    toast.error(_businessDays.message,{
                        position: 'top-center'
                   });
                }
            })
            
        }
        else
        {
            let createProfileResponse = await dispatch(createProfile(authToken,dataObject))                  
            if(createProfileResponse.status === 201)
            {
                setShowLoader(false)
                let loginResponseData = await  dispatch(login(username, password))
                if(loginResponseData == undefined){
                   toast.error("Login failed wrong user credentials.",{
                        position: 'top-center'
                   });
                }else{
                    let userRole = window.localStorage.getItem('user_role');
                    if(userRole !== null || userRole !== ""){
                        if(userRole === 'educator'){
                            dispatch(setUserRole(1))
                            history.push(`/educators-dashboard`);
                        }  
                    }else{
                        toast.error("User role not specified",{ position: 'top-center'});
                    }
                }
            }
            else
            {
                setShowLoader(false)
                toast.error("Profile Creation Issue: "+createProfileResponse.message,{ position: 'top-center'}); //signup  
                await deleteRegisteredUser(deleteId, authToken) 
            }
        }
       
    }

    const goBack=()=>
    {
      dispatch(handleEducatorSignupForm(5))  
    }

    const showHideCalender=(field)=>
    {
        if(field === "registryExpiryDate")
        {
            if(showRegistryExpiryDateModal === true)
            {
               setShowRegistryExpiryDateModal(false) 
            }
            else
            {
               setShowRegistryExpiryDateModal(true)
            }
            setDateField("registryExpiryDate")
        }

        if(field === "vehicleMaintenanceDate")
        {
            if(showVehicleMaintenanceDateModal === true)
            {
               setShowVehicleMaintenanceDateModal(false) 
            }
            else
            {
               setShowVehicleMaintenanceDateModal(true)
            }
            setDateField("vehicleMaintenanceDate")
        }

       

        if(field === "vehicleAuditDate")
        {
            if(showVehicleAuditDateModal === true)
            {
               setShowVehicleAuditDateModal(false) 
            }
            else
            {
               setShowVehicleAuditDateModal(true)
            }
            setDateField("vehicleAuditDate")
        }
         
        if(field === "licenceExpiryDate")
        {
            if(showLicenceExpiryDateModal === true)
            {
               setShowLicenceExpiryDateModal(false) 
            }
            else
            {
               setShowLicenceExpiryDateModal(true)
            }
            setDateField("licenceExpiryDate")
        }
        if(field === "vehicleMaintenanceExpiryDate")
        {
            if(vehicleMaintenanceCalendar === true)
            {
               setVehicleMaintenanceCalendar(false) 
            }
            else
            {
               setVehicleMaintenanceCalendar(true)
            }
            setDateField("vehicleMaintenanceExpiryDate")
        }    

        if(field === "vehicleRestraintAuditExpiryDate")
        {
            if(vehicleRestraintCalendar === true)
            {
               setVehicleRestraintCalendar(false) 
            }
            else
            {
               setVehicleRestraintCalendar(true)
            }
            setDateField("vehicleRestraintAuditExpiryDate")
        }   
    }
   

    return (
    <>
        <ToastContainer />   
        <LoaderComponent displayLoader={showLoader} />  
        <div className="main-wrapper">
            <div className="container">
                <div className="user-pages-wrap">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                        <div className="user-types-wrap text-center">
                            <h1 className="common-heaidng48">Vehicle</h1>
                            <h3 className="small-title">Please fill the required details</h3>
                            <form className="sign-up-form" onSubmit={(e)=>goNext(e)} > 
                              
                                <div className="row custom-row">
                                  <div className="col-lg-4 col-md-6 custom-col">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                          <input maxlength="10" type="text" name="vehilceMake" className={vehicleMake ? "mdc-text-field__input active" : "mdc-text-field__input"} value={vehicleMake} id="vehicleMake" 
                                            aria-labelledby="my-label-id" required onChange={(e) => changeHandler(e,'vehilceMake', e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")}/>
                                          <span className="mdc-notched-outline">
                                              <span className="mdc-notched-outline__leading"></span>
                                              <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">Vehicle Make*</span>
                                              </span>
                                              <span className="mdc-notched-outline__trailing"></span>
                                          </span>
                                      </label>
                                  </div>

                                  <div className="col-lg-4 col-md-6 custom-col">
                                      <label className="mdc-text-field mdc-text-field--outlined">
                                          <input maxlength="10" type="text" required name="vehilceModel" className={vehicleModel ? "mdc-text-field__input active" : "mdc-text-field__input"} value={vehicleModel} id="vehicleModel" 
                                          aria-labelledby="my-label-id" onChange={(e) => changeHandler(e,'vehilceModel', e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")} />
                                          <span className="mdc-notched-outline">
                                              <span className="mdc-notched-outline__leading"></span>
                                              <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">Vehicle Model*</span>
                                              </span>
                                              <span className="mdc-notched-outline__trailing"></span>
                                          </span>
                                      </label>
                                  </div>

                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input maxlength="4" type="text" required name="vehilceYear" className={vehicleYear ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" value={vehicleYear} 
                                            id="vehicleYear"  onChange={(e) => changeHandler(e,'vehilceYear', e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")}/>
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Year*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>
                                    </div>
                                    </div>
                                    <div className="row custom-row">
                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input type="text" required maxlength="10" name="registration" className={registration ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" value={registration} 
                                            id="registration"  onChange={(e) => changeHandler(e,'registration', e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Registration*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={registryExpiryDate} required type="text" onClick={()=>showHideCalender("registryExpiryDate")} className={registryExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                onkeypress="return false;" autoComplete="off" id="registryExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Registration Expiry Date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                                <span className="material-icons">calendar_today</span>
                                            </label>    
                                            {
                                                showRegistryExpiryDateModal === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 custom-col">
                                        <div className="select-field">
                                            <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                              <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">{addVehicleRegistrationReminderDate ? addVehicleRegistrationReminderDate : "Reminder*"}</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                              </span>
                                              <span className="material-icons">arrow_drop_down</span>
                                            </label>
                                
                                            <ul className="dropdown">
                                            <>
                                                {
                                                    reminder.map((item,index)=>
                                                      <li onClick={()=>selectReminder("VehicleRegistrationReminderDate",item)}>{item}</li>
                                                    )
                                                }
                                            </>
                                            </ul>
                                        </div>  
                                    </div>
                                  </div>
                                  <div className="row custom-row">
                                    <div className="col-lg-4 col-md-4 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={vehicleMaintenanceDate} required  type="text" onClick={()=>showHideCalender("vehicleMaintenanceDate")} className={vehicleMaintenanceDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id="vehicleMaintenanceDate"  onkeypress="return false;" autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle maintenance date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>    
                                            {
                                                showVehicleMaintenanceDateModal === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    {/* Vehicle Maintenance Expiry Date Start's Here  */}
                                    <div className="col-lg-4 col-md-4 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={vehicleMaintenanceExpiryDate ? moment(vehicleMaintenanceExpiryDate).format('DD-MM-YYYY') : vehicleMaintenanceExpiryDate} required type="text" onClick={()=>showHideCalender("vehicleMaintenanceExpiryDate")} 
                                                className={vehicleMaintenanceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                onkeypress="return false;" autoComplete="off" id="vehicleMaintenanceExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Maintenance Expiry Date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>    
                                            {
                                                vehicleMaintenanceCalendar === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    {/* Vehicle Maintenance Expiry Date End's Here   */}
                                    
                                     <div className="col-lg-4 col-md-4 custom-col">
                                        <div className="select-field">
                                            <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                              <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">{addVehicleMaintenanceRemainderDate ? addVehicleMaintenanceRemainderDate : "Set Reminder*"}</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                              </span>
                                              <span className="material-icons">arrow_drop_down</span>
                                            </label>
                                
                                            <ul className="dropdown">
                                            <>
                                                {
                                                    reminder.map((item,index)=>
                                                      <li onClick={()=>selectReminder("VehicleMaintenance",item)}>{item}</li>
                                                    )
                                                }
                                            </>
                                            </ul>
                                        </div>  
                                    </div>
                                  </div>
                                  <div className="row custom-row">
                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={vehicleAuditDate} required type="text" onClick={()=>showHideCalender("vehicleAuditDate")} className={vehicleAuditDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id="vehicleAuditDate"  onkeypress="return false;" autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Restraint Audit date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span> 
                                            </label>    
                                            {
                                                showVehicleAuditDateModal === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                      {/* Vehicle Restraint Expiry Date Start's Here  */}
                                    <div className="col-lg-4 col-md-4 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={vehicleRestraintAuditExpiryDate ? moment(vehicleRestraintAuditExpiryDate).format('DD-MM-YYYY') : vehicleRestraintAuditExpiryDate} required type="text" onClick={()=>showHideCalender("vehicleRestraintAuditExpiryDate")} 
                                                className={vehicleRestraintAuditExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                onkeypress="return false;" autoComplete="off" id="vehicleRestraintAuditExpiryDate" onBlur={(e)=>e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Vehicle Restraint Expiry Date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>    
                                            {
                                                vehicleRestraintCalendar === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    {/* Vehicle Restraint Expiry Date End's Here   */}
                                     <div className="col-lg-4 col-md-4 custom-col">
                                        <div className="select-field">
                                            <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                              <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                  <span className="mdc-floating-label" id="my-label-id">{addVehicleAuditRemainderDate ? addVehicleAuditRemainderDate : "Set Reminder*"}</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                              </span>
                                              <span className="material-icons">arrow_drop_down</span>
                                            </label>
                                
                                            <ul className="dropdown">
                                            <>
                                                {
                                                    reminder.map((item,index)=>
                                                      <li onClick={()=>selectReminder("AuditRemainder",item)}>{item}</li>
                                                    )
                                                }
                                            </>
                                            </ul>
                                        </div>  
                                    </div>
                                    </div>
                                    <div className="row custom-row">
                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <label className="mdc-text-field mdc-text-field--outlined">
                                            <input type="text" required maxlength="18" name="licenceNumber" className={licenceNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" value={licenceNumber} 
                                            id="licenceNumber"  onChange={(e) => changeHandler(e,'licenceNumber', e.target.value)} onBlur={(e)=>e.target.setCustomValidity("")} />
                                            <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Drivers Licence Number*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                        </label>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-6 custom-col">
                                        <div className = "calendar-wrap">
                                            <label className="mdc-text-field mdc-text-field--outlined" >
                                                <input value={licenceExpiryDate} required type="text" onClick={()=>showHideCalender("licenceExpiryDate")} className={licenceExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                id="licenceExpiryDate"  onkeypress="return false;" autoComplete="off" onBlur={(e)=>e.target.setCustomValidity("")}  style={{cursor:"pointer"}} />
                                                <span className="mdc-notched-outline">
                                                <span className="mdc-notched-outline__leading"></span>
                                                <span className="mdc-notched-outline__notch">
                                                    <span className="mdc-floating-label" id="my-label-id">Drivers Licence Expiry Date*</span>
                                                </span>
                                                <span className="mdc-notched-outline__trailing"></span>
                                                <span className="material-icons">calendar_today</span>
                                                </span>
                                            </label>    
                                            {
                                                showLicenceExpiryDateModal === true ?
                                                <Calendar
                                                  dateFormat="ddd"
                                                  onChange={onChangeDate}
                                                  value={value}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                  <div className="col-lg-4 col-md-4 custom-col">
                                      <div className="select-field">
                                          <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                                            <span className="mdc-notched-outline">
                                              <span className="mdc-notched-outline__leading"></span>
                                              <span className="mdc-notched-outline__notch">
                                                <span className="mdc-floating-label" id="my-label-id">{addLicenceExpiryRemainderDate ? addLicenceExpiryRemainderDate : "Set Reminder*"}</span>
                                              </span>
                                              <span className="mdc-notched-outline__trailing"></span>
                                            </span>
                                            <span className="material-icons">arrow_drop_down</span>
                                          </label>
                              
                                          <ul className="dropdown">
                                          <>
                                              {
                                                  reminder.map((item,index)=>
                                                    <li onClick={()=>selectReminder("ExpiryRemainderDate",item)}>{item}</li>
                                                  )
                                              }
                                          </>
                                          </ul>
                                      </div>  
                                  </div>
                                </div>
                                        
                                <div className="next-prev-btns">
                                    <button className="mdc-button mdc-button--raised" onClick = {goBack}>
                                      <span className="mdc-button__label">previous</span>
                                    </button>
                                    
                                    <button className="mdc-button mdc-button--raised green-btn">
                                        <span className="mdc-button__label">FINISH</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>    
    )
}

export default Vehicle;