import React, { useEffect, useState } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { NodeDetails } from '../../../functions/CommonFunctions';
import { useHistory } from 'react-router-dom';
import { bookEvent, getLinkParticipatedUsers, convertTimeStampToTime, cancelBooking, getSpecificTaxonomy, getSpefificVenue } from '../../../functions/api';
import { useSelector } from "react-redux"
import './styles.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { baseUrl } from '../../../config'
import DummyImage from '../../../images/user2.png'

const LinkDetailPage = (props) => {
  const history = useHistory();
  const store = useSelector(state => state.auth)
  const [userRole, setUserRole] = useState(1)
  const [title, setTitle] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [eventTime, setEventTime] = useState('')
  const [eventEndTime, setEventEndTime] = useState('')
  const [noOfSeats, setNoOfSeats] = useState('')
  const [eventAdditionalInfo, setEventAdditionalInfo] = useState('')
  const [noOfBookedSeats, setNoOfBookedSeats] = useState('')
  const [noOfAlreadyBookedSeats, setNoOfAlreadyBookedSeats] = useState('')
  const [covidSafeFile, setCovidSafeFile] = useState('')
  const [covidSafeUrl, setCovidSafeUrl] = useState('')
  const [editBookingSeats, setEditBookingSeats] = useState(false);
  const [bookingPopup, setBookingPopup] = useState(false);
  const [linkId, setLinkId] = useState();
  const [linkParticipatedUsers, setLinkParticipated] = useState([])
  const [participantBookedSeats, setParticipantBookedSeats] = useState(0)
  const userId = window.localStorage.getItem("uid")
  const [showBookingButton, setShowBookingButton] = useState(true)
  const [displayLoader, setDisplayLoader] = useState(true)
  const [bookingButtonForDate, setBookingButtonForDate] = useState(false)
  const [businessName, setBusinessName] = useState('');
  const [facilitatorName, setFacilitatorName] = useState('');
  const [facilitatorBio, setFacilitatorBio] = useState('');
  const [sessionName, setSessionName] = useState('');
  const [sessionDescription, setSessionDescription] = useState('');
  const [sessionDocPath, setSessionDocPath] = useState('');
  const [sessionDocText, setSessionDocText] = useState('');
  const [venueName, setVenueName] = useState('');
  const [venueLocation, setVenueLocation] = useState("")
  const [venueDocPath, setVenueDocPath] = useState('');
  const [venueDocText, setVenueDocText] = useState('');
  const [venueImages, setVenueImages] = useState('');
  const [fieldAge, setFieldAge] = useState('');

  const showBookingPopup = async () => {
    setBookingPopup(true);
  }

  const bookMyEvent = async (e) => {
    e.preventDefault()
    setDisplayLoader(true)
    let availableSeats = noOfSeats - participantBookedSeats
    if (noOfBookedSeats !== "" && noOfBookedSeats > 0 && ((noOfBookedSeats <= (parseInt(noOfAlreadyBookedSeats) + availableSeats)) && editBookingSeats) || (!editBookingSeats && (noOfBookedSeats <= availableSeats)) && noOfBookedSeats <= 7) {
      let data = { linkName: linkId, seats: noOfBookedSeats }
      if (editBookingSeats) {
        let dataObject = { "lid": [{ "value": props.match.params.id }], "user_id": [{ "value": userId }] }
        let cancelBookingResponse = await cancelBooking(dataObject)
        if (cancelBookingResponse.status) {
          bookBoking(data)
        }
      } else {
        bookBoking(data)
      }
    } else {
      setDisplayLoader(false)
      if (noOfBookedSeats === "") {
        toast.error("Number of seats can't be empty", { position: 'top-center' });
      }
      else if (noOfBookedSeats > 7) {
        toast.error("You can't book more than 7 seats", { position: 'top-center' });
      }
      else if (noOfBookedSeats < availableSeats || noOfBookedSeats > availableSeats) {
        setShowBookingButton(false)
        toast.error(`Currenytly ${noOfBookedSeats} seats are not available !!!`, { position: 'top-center' });
      }
    }
  }

  async function bookBoking(data) {

    let bookingResponse = await bookEvent(data)
    if (bookingResponse.status === 201) {
      setBookingPopup(false);
      await getInitialData()
      await getAllParticipants()
      setShowBookingButton(false)
      let msg = 'Successfully Booked!!'
      if (editBookingSeats) {
        msg = 'Seats Update Successfully!'
      }
      toast.success(msg, {
        position: 'top-center'
      });
      setEditBookingSeats(false)
    } else {
      setDisplayLoader(false)
      toast.error(data.message, {
        position: 'top-center'
      });
      setBookingPopup(false);
    }
  }

  async function getInitialData() {
    let link_id = props.match.params.id
    setUserRole(store.userRole)
    if (link_id !== '') {
      try {
        setLinkId(link_id);
        let responseData = await NodeDetails(link_id)
        if (responseData && responseData.status === 200) {
          const regex = /(<([^>]+)>)/ig;
          if (responseData.data.data.title) {
            let _title = responseData.data.data.title.length > 0 ? responseData.data.data.title[0].value : ""
            setTitle(_title)
          }
          if (responseData.data.data.field_date) {
            let eventDate = responseData.data.data.field_date.length > 0 ? responseData.data.data.field_date[0].value : "";
            setEventDate(eventDate)

            // Compare Date and Time With Current Date and Time
            let timestamp = responseData.data.data.field_time.length > 0 ? responseData.data.data.field_time[0].value : ""
            let currentTimeStamp = new Date();
            var _time = currentTimeStamp.getHours() + ":" + currentTimeStamp.getMinutes();

            let timeArray = _time.split(':')
            let _hours = timeArray[0]
            let _minutes = timeArray[1]
            let totalTimeStamp = _hours * 3600 + _minutes * 60
            let current_date = new Date();
            if (current_date.getTime() > new Date(eventDate).getTime()) {
              if (totalTimeStamp > timestamp) {
                setBookingButtonForDate(true);  //not show
              } else {
                setBookingButtonForDate(true);
              }
            } else {
              setBookingButtonForDate(true);
            }
          }

          if (responseData.data.data.field_time) {
            let timestamp = responseData.data.data.field_time.length > 0 ? responseData.data.data.field_time[0].value : ""
            if (timestamp !== "" && timestamp !== undefined) {
              var newDateTime = await convertTimeStampToTime(timestamp)
              setEventTime(newDateTime)
            } else {
              setEventTime("N/A")
            }
          }

          if (responseData.data.data.field_session_end_time) {
            let timestamp = responseData.data.data.field_session_end_time.length > 0 ? responseData.data.data.field_session_end_time[0].value : ""
            if (timestamp !== "" && timestamp !== undefined) {
              var newDateTime1 = await convertTimeStampToTime(timestamp)
              setEventEndTime(newDateTime1)
            } else {
              setEventEndTime("N/A")
            }
          }

          if (responseData.data.data.field_number_of_seats) {
            let noOfSeats = responseData.data.data.field_number_of_seats.length > 0 ? responseData.data.data.field_number_of_seats[0].value : "N/A"
            setNoOfSeats(noOfSeats)
          }

          if (responseData.data.data.field_addition_info) {
            let eventAdditionalInfo = responseData.data.data.field_addition_info.length > 0 ? responseData.data.data.field_addition_info[0].value.replace(regex, '') : "N/A"
            setEventAdditionalInfo(eventAdditionalInfo)
          }

          if (responseData.data.data.field_age) {
            let eventAgeGroup = responseData.data.data.field_age.length > 0 ? responseData.data.data.field_age[0].value : ""
            setFieldAge(eventAgeGroup)
          }

          if (responseData.data.data.field_business_name) {
            let _businessName = responseData.data.data.field_business_name.length > 0 ? responseData.data.data.field_business_name[0].target_id : ""
            if (_businessName !== "") {
              let _taxonomyResponse = await getSpecificTaxonomy(responseData.data.data.field_business_name[0].target_id)
              if (_taxonomyResponse.status === 200) {
                if (_taxonomyResponse.data !== undefined && _taxonomyResponse.data.data !== undefined) {
                  let _name = _taxonomyResponse.data.data.name.length > 0 ? _taxonomyResponse.data.data.name[0].value : ""
                  setBusinessName(_name)
                }
              }
            }
          }

          if (responseData.data.data.field_facilitator) {
            let _facilator = responseData.data.data.field_facilitator.length > 0 ? responseData.data.data.field_facilitator[0].target_id : ""
            if (_facilator !== "") {
              let _facilatorResponse = await getSpecificTaxonomy(responseData.data.data.field_facilitator[0].target_id)
              if (_facilatorResponse.status === 200) {
                if (_facilatorResponse.data !== undefined && _facilatorResponse.data.data !== undefined) {
                  let resData = _facilatorResponse.data.data
                  let _name = resData.name.length > 0 ? resData.name[0].value : ""
                  let _bio = resData.field_add_bio.length > 0 ? resData.field_add_bio[0].value : ""
                  setFacilitatorName(_name)
                  setFacilitatorBio(_bio)
                }
              }
            }
          }

          if (responseData.data.data.field_session) {
            let _session = responseData.data.data.field_session.length > 0 ? responseData.data.data.field_session[0].target_id : ""
            if (_session !== "") {
              let _sessionResponse = await getSpecificTaxonomy(responseData.data.data.field_session[0].target_id)
              if (_sessionResponse.status === 200) {
                if (_sessionResponse.data !== undefined && _sessionResponse.data.data !== undefined) {
                  let resData = _sessionResponse.data.data
                  let _name = resData.name.length > 0 ? resData.name[0].value : ""
                  let _description = resData.description.length > 0 ? resData.description[0].value : ""
                  let _url = resData.field_attachment.length > 0 ? resData.field_attachment[0].url : ""
                  let filename = _url.substring(_url.lastIndexOf('/') + 1);
                  setSessionName(_name)
                  setSessionDescription(_description)
                  setSessionDocPath(_url)
                  setSessionDocText(filename)
                }
              }
            }
          }

          if (responseData.data.data.field_venue_link) {
            let _venue = responseData.data.data.field_venue_link.length > 0 ? responseData.data.data.field_venue_link[0].target_id : ""
            if (_venue !== "") {
              let _venueResponse = await getSpefificVenue(responseData.data.data.field_venue_link[0].target_id)
              if (_venueResponse.status === 200) {
                if (_venueResponse.data !== undefined && _venueResponse.data.data !== undefined) {
                  let resData = _venueResponse.data.data
                  let _name = resData.field_venue_name.length > 0 ? resData.field_venue_name[0].value : ""
                  let _location = resData.field_location.length > 0 ? resData.field_location[0].value : ""
                  let _doc = resData.field_risk_assessment_doc.length > 0 ? resData.field_risk_assessment_doc[0].url : ""
                  let _images = resData.field_venue_images.length > 0 ? resData.field_venue_images : []
                  let docName = _doc.substring(_doc.lastIndexOf('/') + 1);
                  let covidUrl = resData.field_covid_19_safe_plan.length > 0 ? resData.field_covid_19_safe_plan[0].url : ""
                  let covidName = covidUrl.substring(covidUrl.lastIndexOf('/') + 1);
                  setVenueName(_name)
                  setVenueLocation(_location)
                  setVenueDocPath(_doc)
                  setVenueDocText(docName)
                  setVenueImages(_images)
                  setCovidSafeFile(covidName)
                  setCovidSafeUrl(covidUrl)
                }
              }
            }
          }
          setDisplayLoader(false)
          getAllParticipants()
        } else {
          setDisplayLoader(false)
        }
      }
      catch (error) {
        setDisplayLoader(false)
      }
    }
  }


  async function getAllParticipants() {
    let link_id = props.match.params.id
    let allUsersResponse = await getLinkParticipatedUsers(link_id)
    if (allUsersResponse.status === 200 && allUsersResponse.data.data.length > 0) {
      let counts = 0
      allUsersResponse.data.data.map(async (data) => {
        counts = counts + JSON.parse(data.field_no_of_seats)
        if (data.uid === userId) {
          
          setNoOfBookedSeats(data.field_no_of_seats);
          setNoOfAlreadyBookedSeats(data.field_no_of_seats);
          setShowBookingButton(false)
        }
      })
      setLinkParticipated(allUsersResponse.data.data)
      setParticipantBookedSeats(counts)
    } else {
      setLinkParticipated([])
      setShowBookingButton(true)
    }
  }

  useEffect(() => {
    getInitialData()
  }, [props])

  const cancelMyBooking = async () => {
    let dataObject = { "lid": [{ "value": props.match.params.id }], "user_id": [{ "value": userId }] }
    let cancelBookingResponse = await cancelBooking(dataObject)
    if (cancelBookingResponse.status === 200) {
      toast.success("Booking cancelled successfully", { position: 'top-center' });
      setNoOfSeats(0)
      setParticipantBookedSeats(0)
      await getInitialData()
      await getAllParticipants()
      setShowBookingButton(true)
      setDisplayLoader(false)
    } else {
      toast.error(cancelBookingResponse.message, { position: 'top-center' });
    }
  }

  if (displayLoader) {
    return <LoaderComponent displayLoader={displayLoader} />
  }


  return (
    <div>
      <ToastContainer />
      {
        userRole === 1 || userRole === 3?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <>
        {
          bookingPopup === true ?
            <div className="main-wrapper mtb-70">
              <div className="container">
                <div className="dashboard-wrap">
                  <div className="row">
                    <div className="popup-layer">
                      <div className="calender-popup booking-popup">
                        <span onClick={() => setBookingPopup(false)} className="material-icons close-icon">close</span>
                        <h1 className="common-heading36">Booking</h1>
                        <div className="col-lg-12 col-md-4">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input onChange={(e) => setNoOfBookedSeats(e.target.value)} type="number"
                              className={noOfBookedSeats ? "mdc-text-field__input active" : "mdc-text-field__input"}
                              value={noOfBookedSeats} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Number of Seats</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <button onClick={bookMyEvent} className="mdc-button mdc-button--raised green-btn" >
                          <span className="mdc-button__label">{noOfBookedSeats ? 'Update' : 'Book'} Seats</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null
        }
      </>
      <div className="main-wrapper mtb-70">
        <div className="container">
          {title !== "" ?
            <div className="dashboard-wrap">
              <div className="common-header-row common-header-with-large-text">
                <h4>{title}</h4>
                <a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
                
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="sidebar">
                    <div className="time-date-row">
                      <div className='colm'>
                        <div className="date-block">
                          <text><span className="material-icons">date_range</span>Date</text>
                          <p>{moment(eventDate).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className="time-block">
                          <text><span className="material-icons">schedule</span> Start Time</text>
                          <p>{eventTime}</p>
                        </div>
                        <div className="time-block">
                          <text><span className="material-icons">schedule</span>End Time</text>
                          <p>{eventEndTime}</p>
                        </div>
                      </div>
                      <div>
                        {
                          venueName ?
                            <div className="meeting-notes">
                              <h2 className="title18"><span className="material-icons">business</span>Venue Name</h2>
                              <p>{venueName}</p>
                            </div>
                            :
                            null
                        }
                        {
                          venueLocation ?
                            <div className="location-block">
                              <text><span className="material-icons">location_on</span>Location</text>
                              <p>{venueLocation}</p>
                            </div>
                            :
                            null
                        }
                      </div>
                    </div>
                    <div className="list-block">
                      <h5 className="title18">Number Of Seats</h5>
                      <ul>
                        <li>{noOfSeats - participantBookedSeats}</li>
                      </ul>
                    </div>

                    {((userRole === 1 || userRole === 3) && bookingButtonForDate === true) ?
                      showBookingButton === true ?
                        <div className="list-block">
                          <button onClick={() => showBookingPopup()} className="mdc-button mdc-button--raised green-btn" >
                            <span className="mdc-button__label">Book</span>
                          </button>
                        </div>
                        :
                        <div className="list-block-container">
                          <div className="list-block">
                            <button onClick={() => [setEditBookingSeats(true), setBookingPopup(true)]} className="mdc-button mdc-button--raised green-btn">
                              <span className="mdc-button__label">Edit Booking</span>
                            </button>
                          </div>
                          <div className="list-block">
                            <button onClick={cancelMyBooking} className="mdc-button mdc-button--raised green-btn">
                              <span className="mdc-button__label">Cancel Booking</span>
                            </button>
                          </div>
                        </div>
                      : null
                    }
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content-block-right link-detail-page">
                    {
                      businessName ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Business Name</h5>
                          <p>{businessName}</p>
                        </div>
                        :
                        null
                    }
                    {
                      facilitatorName ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Facilitator Name</h5>
                          <p>{facilitatorName}</p>
                        </div>
                        :
                        null
                    }
                    {
                      facilitatorBio ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Facilitator Bio</h5>
                          <p>{facilitatorBio}</p>
                        </div>
                        :
                        null
                    }
                    {
                      sessionName ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Session Name</h5>
                          <p>{sessionName}</p>
                        </div>
                        :
                        null
                    }
                    {
                      sessionDescription ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Session Description</h5>
                          <p>{sessionDescription}</p>
                        </div>
                        :
                        null
                    }
                    {
                      sessionDocPath ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Session Document</h5>
                          <a href={sessionDocPath} target="_blank" rel="noopener noreferrer"><p>{sessionDocText.replace(/%20/g, '_')}</p></a>
                        </div>
                        :
                        null
                    }
                    {
                      venueImages.length > 0 ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Venue Images</h5>
                          <div className="detail-media-block">
                            {
                              venueImages.map(data =>
                                <a href={data.url} target="_blank" rel="noopener noreferrer">
                                  <img src={data.url} alt='' />
                                </a>
                              )
                            }
                          </div>
                        </div>
                        :
                        null
                    }
                    {
                      venueDocPath ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Venue Document</h5>
                          <h4 className="title18">Risk Assessment Document</h4>
                          <a href={venueDocPath} target="_blank" rel="noopener noreferrer"><p>{venueDocText.replace(/%2520/g, '_')}</p></a>
                          {
                            covidSafeFile ?
                              <>
                                <h4 className="title18">COVID Safe Plan</h4>
                                <a href={covidSafeUrl} target="_blank" rel="noopener noreferrer"><p>{covidSafeFile.replace(/%20/g, '_')}</p></a>
                              </>
                              :
                              null
                          }
                        </div>
                        :
                        null
                    }
                    {
                      eventAdditionalInfo ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Additional Info</h5>
                          <p>{eventAdditionalInfo}</p>
                        </div>
                        :
                        null
                    }
                    {
                      fieldAge ?
                        <div className="meeting-notes">
                          <h5 className="title18"><span className="material-icons">description</span>Age Group</h5>
                          <p>{fieldAge}</p>
                        </div>
                        :
                        null
                    }
                    {
                      linkParticipatedUsers.length > 0 ?
                        <div className="meeting-notes">
                          <h5 className="title18">Participants </h5>
                          <>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.No.</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Name</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    linkParticipatedUsers.length > 0 ?
                                      linkParticipatedUsers.map((items, index) => {
                                        return (
                                          <TableRow key={index} className="mdc-form-field">
                                            <TableCell><span>{index + 1}</span></TableCell>
                                            <TableCell> <figure className="user-media">
                                              <img src={items.field_profile_picture ? baseUrl + items.field_profile_picture : DummyImage} alt='' />
                                            </figure> </TableCell>
                                            <TableCell><span>{items.name} x {items.field_no_of_seats} seats</span></TableCell>
                                          </TableRow>
                                        )
                                      })
                                      :
                                      null
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        </div>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="common-header-row common-header-with-large-text">
              <h4>Link Not Found</h4>
              <a href="javascript:void()" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
              {/* <button onClick={() => history.goBack()} className="dropdown-list">
                <span className="material-icons">arrow_back</span> Go Back
              </button> */}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default LinkDetailPage;