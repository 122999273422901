import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleEducatorSignupForm, getAuthToken } from '../../../redux/actions/auth';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import { $ } from "react-jquery-plugin";


const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]

const ResidentialOccupants = (props) => {

  let education = {
    registerNumber: "",
    educationExpiry: "",
    showCalender: false
  }

  const dispatch = useDispatch();
  const store = useSelector(state => state.auth)
  const [value, setValue] = useState(new Date());
  const [displayDatePicker, setDisplayDatePicker] = useState(false);
  const [dateField, setDateField] = useState('')

  // residential occupants
  const [educatorDetails, setEducatorDetails] = useState(education);
  const [address, setAddress] = useState("")
  const [educatorBlueCard, setEducatorBlueCard] = useState("")
  const [educatorBlueCardNumber, setEducatorBlueCardNumber] = useState("")
  const [educatorBlueCardExpiryDate, setEducatorBlueCardExpiryDate] = useState('');
  const [displayEducatorBlueCardExpiryModal, setDisplayEducatorBlueCardExpiryModal] = useState(false);
  const [educatorBlueCardSlightedDate, setEducatorBlueCardSlightedDate] = useState('');
  const [displayEducatorBlueCardSlightedModal, setDisplayEducatorBlueCardSlightedModal] = useState(false);

  // all occupants
  //blueCard:"",expiryDate:'',sightedDate:'',showDobCalender:false,showExpiryCalender:false,showSightedDateCalender:false removed here in add more fields
  const [addMoreFileds, setAddMoreFields] = useState([{ name: "", lastName: "", dob: "" }])
  const [selectedCalenderIndex, setSelectedCalenderIndex] = useState(0)
  const [addMoreAdultOccupants, setAddMoreAdultOccupants] = useState([{ name: "", lastName: "", dob: "", 
    blueCard: "", expiryDate: '', sightedDate: '', showDobCalender: false, showExpiryCalender: false, 
    showSightedDateCalender: false, adultOccupantReminder: '', dropDown: false }])
  const [selectedAdultOccupantCalenderIndex, setSelectedAdultOccupantCalenderIndex] = useState(0)


  // Reminder Fields
  const [educatorReminder, setEducatorReminder] = useState('')
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    console.log('showDropDown', showDropDown);
  }, [showDropDown]);

  useEffect(() => {
    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) { $('.dropdown').slideUp(); }
      });
    });
  }, [])

  

  useEffect(() => {
    function getInitialData() {
      if (window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined) {
        let signupFormDetail = JSON.parse(window.localStorage.getItem('educatorSignupDetail'))
        if (signupFormDetail[1] !== null && signupFormDetail[1] !== undefined) {
          if (signupFormDetail[1].residentialOccupant.allOccupantDetails.length > 0) {
            setAddMoreFields(signupFormDetail[1].residentialOccupant.allOccupantDetails)
          }
          if (signupFormDetail[1].residentialOccupant.allAdultOccupant.length > 0) {
            setAddMoreAdultOccupants(signupFormDetail[1].residentialOccupant.allAdultOccupant)
          }
          setAddress(signupFormDetail[1].residentialOccupant.educatorAddress)
          setEducatorBlueCard(signupFormDetail[1].residentialOccupant.educatorBlueCard)
          setEducatorBlueCardExpiryDate(signupFormDetail[1].residentialOccupant.educatorBlueCardExpiryDate)
          setEducatorBlueCardSlightedDate(signupFormDetail[1].residentialOccupant.educatorBlueCardSlightedDate)
          setEducatorDetails(signupFormDetail[1].residentialOccupant.educatorDetails)
        }

      }
    }
    getInitialData()

  }, [])

  useEffect(() => {
    function validateForm() {
      var usernameValidate = document.getElementById("address");
      if (usernameValidate !== null) {
        usernameValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Address can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var educatorBlueCardExpiryDateValidate = document.getElementById("educatorBlueCardExpiryDate");
      if (educatorBlueCardExpiryDateValidate !== null) {
        educatorBlueCardExpiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Educator Blue Card expiry date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }


      var educatorBlueCardSlightedDateValidate = document.getElementById("educatorBlueCardSlightedDate");
      if (educatorBlueCardSlightedDateValidate !== null) {
        educatorBlueCardSlightedDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Educator Blue Card signed date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      //teacher education details
      var educatorDetailsValidate = document.getElementById("registerNumber");
      if (educatorDetailsValidate !== null) {
        educatorDetailsValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Teacher Registration number can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      //teacher expiry date 
      var educatorDetailsValidate = document.getElementById("educationExpiry");
      if (educatorDetailsValidate !== null) {
        educatorDetailsValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Teacher registration expiry date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }


      // All Occupants Validation
      var allOccupantFirstNameValidate = document.getElementById("allOccupantFirstName");
      if (allOccupantFirstNameValidate !== null) {
        allOccupantFirstNameValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter First Name");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var allOccupantLastNameValidate = document.getElementById("allOccupantLastName");
      if (allOccupantLastNameValidate !== null) {
        allOccupantLastNameValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter Last Name");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var allOccupantDobValidate = document.getElementById("allOccupantDob");
      if (allOccupantDobValidate !== null) {
        allOccupantDobValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter DOB");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var allOccupantBlueCardValidate = document.getElementById("allOccupantBlueCard");
      if (allOccupantBlueCardValidate !== null) {
        allOccupantBlueCardValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter Blue Card Number");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var allOccupantExpiryDateValidate = document.getElementById("allOccupantExpiryDate");
      if (allOccupantExpiryDateValidate !== null) {
        allOccupantExpiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter expiry date");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var allOccupantSightedDateValidate = document.getElementById("allOccupantSightedDate");
      if (allOccupantSightedDateValidate !== null) {
        allOccupantSightedDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter sighted date");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }


      // All Adult Occupants Validation

      var adultOccupantFirstNameValidate = document.getElementById("adultOccupantFirstName");
      if (adultOccupantFirstNameValidate !== null) {
        adultOccupantFirstNameValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter First Name");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var adultOccupantLastNameValidate = document.getElementById("adultOccupantLastName");
      if (adultOccupantLastNameValidate !== null) {
        adultOccupantLastNameValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter Last Name");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var adultOccupantDobValidate = document.getElementById("adultOccupantDob");
      if (adultOccupantDobValidate !== null) {
        adultOccupantDobValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter DOB");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var adultOccupantBlueCardValidate = document.getElementById("adultOccupantBlueCard");
      if (adultOccupantBlueCardValidate !== null) {
        adultOccupantBlueCardValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter Blue Card Number");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var adultOccupantExpiryDateValidate = document.getElementById("adultOccupantExpiryDate");
      if (adultOccupantExpiryDateValidate !== null) {
        adultOccupantExpiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter expiry date");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var adultOccupantSlightedDateValidate = document.getElementById("adultOccupantSlightedDate");
      if (adultOccupantSlightedDateValidate !== null) {
        adultOccupantSlightedDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Please enter sighted date");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

    }

    validateForm()

  }, [addMoreFileds.length])

  const changeHandler = (e, name, value) => {

    if (name === 'address') {
      setAddress(value);
      e.target.setCustomValidity("")
    }
    else if (name === 'educatorBlueCard') {
      setEducatorBlueCard(value);
      e.target.setCustomValidity("")
    }
  }

  const showCalender = (field, index, type) => {
    setDateField(field)
    let newArray = type === "occupant" ? addMoreFileds : addMoreAdultOccupants
    if (field === "allOccupantDob") {
      if (newArray[index].showDobCalender === true) {
        newArray[index].showDobCalender = false
      }
      else {
        newArray[index].showDobCalender = true
      }

    }
    if (field === "allOccupantBlueCardExpiry") {
      if (newArray[index].showExpiryCalender === true) {
        newArray[index].showExpiryCalender = false
      }
      else {
        newArray[index].showExpiryCalender = true
      }
    }
    if (field === "allOccupantSlightedDate") {
      if (newArray[index].showSightedDateCalender === true) {
        newArray[index].showSightedDateCalender = false
      }
      else {
        newArray[index].showSightedDateCalender = true
      }

    }

    if (type === "occupant") {
      if (index !== null && index !== undefined) {
        setSelectedCalenderIndex(index)
      }
      setAddMoreFields([...newArray])
    }
    else {
      if (index !== null && index !== undefined) {
        setSelectedAdultOccupantCalenderIndex(index)
      }
      setAddMoreAdultOccupants([...newArray])
    }


  }

  const onChangeDate = async (newDate) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = date + "-" + month + "-" + year
    // residential occupant

    if (dateField === "educatorBlueCardExpiryDate") {
      await setEducatorBlueCardExpiryDate(fullYear)
      setDisplayEducatorBlueCardExpiryModal(false)
    }
    if (dateField === "educatorBlueCardSlightedDate") {
      await setEducatorBlueCardSlightedDate(fullYear)
      setDisplayEducatorBlueCardSlightedModal(false)
    }

    if (dateField === "setEducatorDetails") {
      setEducatorDetails({ ...educatorDetails, educationExpiry: fullYear, showCalender: false })
      // setEducatorDetails(false)
    }

    // all occupant 

    let newArray = addMoreFileds

    if (dateField === "allOccupantDob") {
      newArray[selectedCalenderIndex].dob = fullYear
      newArray[selectedCalenderIndex].showDobCalender = false

    }
    if (dateField === "allOccupantSlightedDate") {
      newArray[selectedCalenderIndex].sightedDate = fullYear
      newArray[selectedCalenderIndex].showSightedDateCalender = false
    }
    if (dateField === "allOccupantBlueCardExpiry") {
      newArray[selectedCalenderIndex].expiryDate = fullYear
      newArray[selectedCalenderIndex].showExpiryCalender = false
    }

    setAddMoreFields(newArray)
    setSelectedCalenderIndex(0)
    setDateField("")
    setDisplayDatePicker(false)
  }

  const onChangeAdultOccupantDate = async (newDate) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = date + "-" + month + "-" + year
    // residential occupant

    if (dateField === "educatorBlueCardExpiryDate") {
      await setEducatorBlueCardExpiryDate(fullYear)
      setDisplayEducatorBlueCardExpiryModal(false)
    }
    if (dateField === "educatorBlueCardSlightedDate") {
      await setEducatorBlueCardSlightedDate(fullYear)
      setDisplayEducatorBlueCardSlightedModal(false)
    }

    // all occupant 

    let newArray = addMoreAdultOccupants

    if (dateField === "allOccupantDob") {
      newArray[selectedAdultOccupantCalenderIndex].dob = fullYear
      newArray[selectedAdultOccupantCalenderIndex].showDobCalender = false

    }
    if (dateField === "allOccupantSlightedDate") {
      newArray[selectedAdultOccupantCalenderIndex].sightedDate = fullYear
      newArray[selectedAdultOccupantCalenderIndex].showSightedDateCalender = false
    }
    if (dateField === "allOccupantBlueCardExpiry") {
      newArray[selectedAdultOccupantCalenderIndex].expiryDate = fullYear
      newArray[selectedAdultOccupantCalenderIndex].showExpiryCalender = false
    }

    if (dateField === "setEducatorDetails") {
      newArray[selectedAdultOccupantCalenderIndex].expiryDate = fullYear
      newArray[selectedAdultOccupantCalenderIndex].showExpiryCalender = false
    }

    setAddMoreAdultOccupants(newArray)
    setSelectedAdultOccupantCalenderIndex(0)
    setDateField("")
    setDisplayDatePicker(false)
  }

  const addMoreNewFields = (e) => {
    e.preventDefault()
    setAddMoreFields(() => addMoreFileds.concat({ name: "", dob: "", blueCard: "", expiryDate: '', sightedDate: '', showDobCalender: false, showExpiryCalender: false, showSightedDateCalender: false }))
  }

  const addAdultOccupants = (e) => {
    e.preventDefault()
    setAddMoreAdultOccupants(() => addMoreAdultOccupants.concat({ name: "", dob: "", blueCard: "", expiryDate: '', sightedDate: '', showDobCalender: false, showExpiryCalender: false, showSightedDateCalender: false }))
  }

  const removeMoreNewFields = (e, index) => {
    e.preventDefault()
    let addMoreFiledsRef = addMoreFileds
    addMoreFiledsRef.splice(index, 1)
    setAddMoreFields([...addMoreFiledsRef])
  }

  const removeMoreAdultOccupantsFields = (e, index) => {
    e.preventDefault()
    let addMoreFiledsRef = addMoreAdultOccupants
    addMoreFiledsRef.splice(index, 1)
    setAddMoreAdultOccupants([...addMoreFiledsRef])
  }

  const updatedOccupantDetail = (e, index, fieldName) => {
    e.preventDefault()
    let value = e.target.value
    let newArray = addMoreFileds
    if (fieldName === "name") {
      newArray[index].name = value
    }

    else if (fieldName === "lastName") {
      newArray[index].lastName = value
    }

    else if (fieldName === "blueCard") {
      newArray[index].blueCard = value
    }

    setAddMoreFields([...newArray])
  }

  const updatedAdultOccupantDetail = (e, index, fieldName) => {
    e.preventDefault()
    let value = e.target.value
    let newArray = addMoreAdultOccupants
    if (fieldName === "name") {
      newArray[index].name = value
    }

    else if (fieldName === "lastName") {
      newArray[index].lastName = value
    }

    else if (fieldName === "blueCard") {
      newArray[index].blueCard = value
    }


    setAddMoreAdultOccupants([...newArray])
  }


  const goNext = async (e) => {
    e.preventDefault()
    //Educator Address ,  educatorDetails: educatorDetails is removed
    let residentialOccupantData = {
      "residentialOccupant": {
        educatorBlueCard: e.target.elements.educatorBlueCard.value,
        educatorBlueCardExpiryDate: educatorBlueCardExpiryDate,
        educatorBlueCardSlightedDate: educatorBlueCardSlightedDate,
        allOccupantDetails: addMoreFileds, allAdultOccupant: addMoreAdultOccupants,
        educatorReminder: educatorReminder

      }
    }

    dispatch(handleEducatorSignupForm(3, residentialOccupantData, 1))

  }

  const goBack = () => {
    dispatch(handleEducatorSignupForm(1))
  }

  const showHideCalender = (fields) => {
    if (fields === "educatorBlueCardExpiryDate") {
      if (displayEducatorBlueCardExpiryModal === true) {
        setDisplayEducatorBlueCardExpiryModal(false);
      }
      else {
        setDisplayEducatorBlueCardExpiryModal(true);
      }
      setDateField("educatorBlueCardExpiryDate")
    }

    if (fields === "educatorBlueCardSlightedDate") {
      if (displayEducatorBlueCardSlightedModal === true) {
        setDisplayEducatorBlueCardSlightedModal(false);
      }
      else {
        setDisplayEducatorBlueCardSlightedModal(true);
      }
      setDateField("educatorBlueCardSlightedDate")
    }

    if (fields === "setEducatorDetails") {
      if (educatorDetails === true) {
        setEducatorDetails(educatorDetails => ({ ...educatorDetails, showCalender: false }));

      }
      else {
        setEducatorDetails(educatorDetails => ({ ...educatorDetails, showCalender: true }));

      }
      setDateField("setEducatorDetails")
    }

  }


  const updatedAdultOccupantReminder = (value, index, field) => {
    console.log('value', value);
    let marker = [...addMoreAdultOccupants]
    marker[index] = { ...marker[index], ['adultOccupantReminder']: value }
    marker[index] = { ...marker[index], ['dropDown']: false }
    console.log("marker", marker);
    setAddMoreAdultOccupants([...marker])

  }
  const initDropDown = (data, index) => {
    console.log('dataaa', data);
    let marker = [...addMoreAdultOccupants]
    marker[index] = { ...marker[index], ['dropDown']: true }
    console.log('here marker', marker);
    setAddMoreAdultOccupants([...marker])
  }

  return (
    <div className="main-wrapper">
      <div className="container">
        <div className="user-pages-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="user-types-wrap course-detail-page text-center">
                <h1 className="common-heaidng48">Residential Occupants</h1>
                <h3 className="small-title">Please fill the required details</h3>
                <form className="sign-up-form" onSubmit={(e) => goNext(e)}>
                  <div className="row">
                    <div className="col-md-12">

                      <div className="row custom-row">
                        {/* <div className="col-lg-6 col-md-6 custom-col">
                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                <input maxlength="50" className={address ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="address" value = {address} aria-labelledby="my-label-id" 
                                                onChange={(e) => changeHandler(e, 'address', e.target.value)} id = "address" required onBlur={(e)=>e.target.setCustomValidity("")} />
                                                <span className="mdc-notched-outline">
                                                    <span className="mdc-notched-outline__leading"></span>
                                                    <span className="mdc-notched-outline__notch">
                                                        <span className="mdc-floating-label" id="my-label-id">Address*</span>
                                                    </span>
                                                    <span className="mdc-notched-outline__trailing"></span>
                                                </span>
                                            </label>
                                        </div> */}
                        <div className="col-lg-6 col-md-6 custom-col">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="18" className={educatorBlueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="educatorBlueCard" value={educatorBlueCard} aria-labelledby="my-label-id" onChange={(e) => changeHandler(e, 'educatorBlueCard', e.target.value)}
                              id="educatorBlueCard" onBlur={(e) => e.target.setCustomValidity("")} />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Educator Blue Card</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 custom-col" >
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showHideCalender("educatorBlueCardExpiryDate")} >
                              <input value={educatorBlueCardExpiryDate} onkeypress="return false;" autoComplete="off" type="text" className={educatorBlueCardExpiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                aria-labelledby="my-label-id" id="educatorBlueCardExpiryDate" required onBlur={(e) => e.target.setCustomValidity("")} style={{cursor:"pointer"}} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            {
                              displayEducatorBlueCardExpiryModal === true ?
                                <Calendar onChange={onChangeDate} />
                                :
                                null
                            }
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 custom-col" >
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showHideCalender("educatorBlueCardSlightedDate")} >
                              <input value={educatorBlueCardSlightedDate} onkeypress="return false;" autoComplete="off" type="text" className={educatorBlueCardSlightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                aria-labelledby="my-label-id" id="educatorBlueCardSlightedDate" required onBlur={(e) => e.target.setCustomValidity("")}  style={{cursor:"pointer"}}/>
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Sighted Date</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            {
                              displayEducatorBlueCardSlightedModal === true ?
                                <Calendar onChange={onChangeDate} />
                                :
                                null
                            }
                          </div>
                        </div>
                        {/* Set Reminder Field Start's Here  */}
                        <div className="col-lg-6 col-md-6 custom-col">
                          <div className="select-field">
                            <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                              {/* <label className="reminder-field-style">Set Reminder*</label> */}
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{educatorReminder ? educatorReminder : "Set Educator Reminder "}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">arrow_drop_down</span>
                            </label>
                            <ul className="dropdown">
                              <>
                                {
                                  reminder.map((item, index) =>
                                    <li key={index} onClick={() => setEducatorReminder(item)}>{item}</li>
                                  )
                                }
                              </>
                            </ul>
                          </div>
                        </div>
                        {/* Set Reminder Field End's Here  */}
                      </div>
                    </div>

                    {/* <div className="col-md-12">
                                        <label className="text-left">Educator Details</label>
                                        {/*{
                                            addMoreFileds.map((items,index)=>*/}
                    {/* <div class="occupants-fields-wrap">
                                                    <div className="row custom-row">
                                                    {/*key={index}*/}

                    {/* <div className="col-lg-6 col-md-6 custom-col">
                                                            <label className="mdc-text-field mdc-text-field--outlined"> 
                                                                <input maxlength="18" required id="registerNumber" className={educatorDetails.registerNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="
                                                               registerNumber"  aria-labelledby="my-label-id" 
                                                                value={educatorDetails.registerNumber} onChange={(e)=>setEducatorDetails({...educatorDetails, registerNumber:e.target.value})} onBlur={(e)=>e.target.setCustomValidity("")} />
                                                                <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Registration number</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                            </label>
                                                        </div>   */}

                    {/* <div className="col-lg-6 col-md-6 custom-col" >
                                                            <div className = "calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" 
                                                                onClick={()=>showHideCalender("setEducatorDetails")} >
                                                                    <input value={educatorDetails.educationExpiry} required onkeypress="return false;" id="setEducatorDetails" type="text" className={educatorDetails.educationExpiry ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                                    aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")}  />      
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Expiry date</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                    </span> 
                                                                </label>
                                                                { */}
                    {/* educatorDetails.showCalender === true ?
                                                                        <Calendar onChange={onChangeDate} />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div> */}

                    {/* { 
                                                          index !== 0 ? <button onClick={(e)=>removeMoreNewFields(e, index)} ><span className="material-icons close">close</span></button> : null
                                                    //     }*/}
                    {/* // </div>
                                                </div> */}
                    {/*   )
                                        }*/}

                    {/* <div className="custom-dashed-button" onClick={addMoreNewFields}>
                                           <button className="mdc-button mdc-button--outlined btn-large">
                                            <span className="mdc-button__ripple"></span>
                                            <span className="mdc-button__label">Add More Occupants</span>
                                            </button>
                                        </div>*/}

                    {/* </div>  */}

                    <div className="col-md-12">
                      <label className="text-left">Child Occupants</label>
                      {
                        addMoreFileds.map((items, index) =>
                          <div class="occupants-fields-wrap">
                            <div className="row custom-row" key={index}>
                              <div className="col-lg-4 col-md-4 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" className={items.name ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantName" id="allOccupantFirstName"
                                    aria-labelledby="my-label-id" value={items.name} onChange={(e) => updatedOccupantDetail(e, index, "name")} onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">First Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-4 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" className={items.lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantLastName" id="allOccupantLastName" aria-labelledby="my-label-id"
                                    value={items.lastName} onChange={(e) => updatedOccupantDetail(e, index, "lastName")} onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Last Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-4 custom-col" >
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showCalender("allOccupantDob", index, "occupant")}>
                                    <input value={items.dob} onkeypress="return false;" type="text" id="allOccupantDob" className={items.dob ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                      aria-labelledby="my-label-id" onBlur={(e) => e.target.setCustomValidity("")} style={{cursor:"pointer"}}/>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">DOB</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {
                                    items.showDobCalender === true ?
                                      <Calendar onChange={onChangeDate} />
                                      :
                                      null
                                  }
                                </div>
                              </div>

                              {/* <div className="col-lg-4 col-md-4 custom-col">
                                                            <label className="mdc-text-field mdc-text-field--outlined">
                                                                <input maxlength="18"  id="allOccupantBlueCard" className={items.blueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantBlueCard"  aria-labelledby="my-label-id" 
                                                                value={items.blueCard} onChange={(e)=>updatedOccupantDetail(e,index,"blueCard")} onBlur={(e)=>e.target.setCustomValidity("")} />
                                                                <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                        <span className="mdc-floating-label" id="my-label-id">Educator Blue Card</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                </span>
                                                            </label>
                                                        </div>   */}

                              {/* <div className="col-lg-4 col-md-4 custom-col" >
                                                            <div className = "calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showCalender("allOccupantBlueCardExpiry",index,"occupant")}>
                                                                    <input value={items.expiryDate}  onkeypress="return false;" id="allOccupantExpiryDate" type="text" className={items.expiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} 
                                                                    aria-labelledby="my-label-id" onBlur={(e)=>e.target.setCustomValidity("")}  />      
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                    </span> 
                                                                </label>
                                                                {
                                                                    items.showExpiryCalender === true ?
                                                                        <Calendar onChange={onChangeDate} />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div> */}
                              {/* <div className="col-lg-4 col-md-4 custom-col" >
                                                            <div className = "calendar-wrap">
                                                                <label className="mdc-text-field mdc-text-field--outlined" onClick={()=>showCalender("allOccupantSlightedDate",index,"occupant")}>
                                                                    <input value={items.sightedDate}  onkeypress="return false;" type="text" id="allOccupantSightedDate" className={items.sightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                                                    onBlur={(e)=>e.target.setCustomValidity("")} /> 
                                                                    <span className="mdc-notched-outline">
                                                                    <span className="mdc-notched-outline__leading"></span>
                                                                    <span className="mdc-notched-outline__notch">
                                                                    <span className="mdc-floating-label" id="my-label-id">Sighted Date</span>
                                                                    </span>
                                                                    <span className="mdc-notched-outline__trailing"></span>
                                                                    <span className="material-icons">calendar_today</span>
                                                                    </span>
                                                                </label>
                                                                {
                                                                    items.showSightedDateCalender === true ?
                                                                        <Calendar onChange={onChangeDate} />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div> */}
                              {
                                index !== 0 ? <button onClick={(e) => removeMoreNewFields(e, index)} ><span className="material-icons close">close</span></button> : null
                              }
                            </div>
                          </div>
                        )
                      }

                      <div className="custom-dashed-button" onClick={addMoreNewFields}>
                        <button className="mdc-button mdc-button--outlined btn-large">
                          <span className="mdc-button__ripple"></span>
                          <span className="mdc-button__label">Add More Occupants</span>
                        </button>
                      </div>

                    </div>

                    <div className="col-md-12">
                      <label className="text-left">Adult Occupants</label>
                      {
                        addMoreAdultOccupants.map((items, index) =>
                          <div class="occupants-fields-wrap">
                            <div className="row custom-row" key={index}>
                              <div className="col-lg-4 col-md-4 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" required className={items.name ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantName" id="adultOccupantFirstName" aria-labelledby="my-label-id"
                                    value={items.name} onChange={(e) => updatedAdultOccupantDetail(e, index, "name")} onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">First Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                              <div className="col-lg-4 col-md-4 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="20" required className={items.lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantLastName" id="adultOccupantLastName" aria-labelledby="my-label-id"
                                    value={items.lastName} onChange={(e) => updatedAdultOccupantDetail(e, index, "lastName")} onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Last Name</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-4 custom-col" >
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showCalender("allOccupantDob", index, "adultOccupant")}>
                                    <input value={items.dob} required id="adultOccupantDob" onkeypress="return false;" type="text" className={items.dob ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                      onBlur={(e) => e.target.setCustomValidity("")} aria-labelledby="my-label-id" style={{cursor:"pointer"}}/>
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">DOB</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {
                                    items.showDobCalender === true ?
                                      <Calendar onChange={onChangeAdultOccupantDate} />
                                      :
                                      null
                                  }
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 custom-col">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input maxlength="18" id="adultOccupantBlueCard" className={items.blueCard ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" name="allOccupantBlueCard" aria-labelledby="my-label-id" value={items.blueCard}
                                    onChange={(e) => updatedAdultOccupantDetail(e, index, "blueCard")} onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Blue Card Number</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>

                              <div className="col-lg-4 col-md-4 custom-col" >
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showCalender("allOccupantBlueCardExpiry", index, "adultOccupant")}>
                                    <input value={items.expiryDate} id="adultOccupantExpiryDate" required onkeypress="return false;" type="text" className={items.expiryDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" onBlur={(e) => e.target.setCustomValidity("")} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {
                                    items.showExpiryCalender === true ?
                                      <Calendar onChange={onChangeAdultOccupantDate} />
                                      :
                                      null
                                  }
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 custom-col" >
                                <div className="calendar-wrap">
                                  <label className="mdc-text-field mdc-text-field--outlined" onClick={() => showCalender("allOccupantSlightedDate", index, "adultOccupant")}>
                                    <input value={items.sightedDate} required onkeypress="return false;" id="adultOccupantSlightedDate" type="text" className={items.sightedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" onBlur={(e) => e.target.setCustomValidity("")} />
                                    <span className="mdc-notched-outline">
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch">
                                        <span className="mdc-floating-label" id="my-label-id">Sighted Date</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                      <span className="material-icons">calendar_today</span>
                                    </span>
                                  </label>
                                  {
                                    items.showSightedDateCalender === true ?
                                      <Calendar onChange={onChangeAdultOccupantDate} />
                                      :
                                      null
                                  }
                                </div>
                              </div>

                              {/* Set Adult Occupant Reminder Field Start's Here  */}
                              <div className="col-lg-4 col-md-4 custom-col">
                                <div className="select-field" 
                                onMouseUp={() => initDropDown(items, index)}  
                                // onClick={() => initDropDown(items, index)}
                                >
                                  <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                    {/* <label className="reminder-field-style">Set Reminder*</label> */}
                                    <span className="mdc-notched-outline"
                                    >
                                      <span className="mdc-notched-outline__leading"></span>
                                      <span className="mdc-notched-outline__notch" >
                                        <span className="mdc-floating-label" id="my-label-id">{items.adultOccupantReminder ? items.adultOccupantReminder : "Set Educator Reminder "}</span>
                                      </span>
                                      <span className="mdc-notched-outline__trailing"></span>
                                    </span>
                                    <span className="material-icons">arrow_drop_down</span>
                                  </label>
                                  {items.dropDown && 
                                    <ul className="dropdown1">
                                      <>
                                        {
                                          reminder.map((item, i) =>
                                            <li key={i} onClick={() => updatedAdultOccupantReminder(item, index, "adultOccupantReminder")}>{item}</li>
                                          )
                                        }
                                      </>
                                    </ul>
                                  }
                                  
                                </div>
                              </div>
                              {/* Set Reminder Field End's Here  */}
                              {
                                index !== 0 ? <button onClick={(e) => removeMoreAdultOccupantsFields(e, index)} ><span className="material-icons close">close</span></button> : null
                              }
                            </div>
                          </div>
                        )
                      }
                      <div className="custom-dashed-button" onClick={addAdultOccupants}>
                        <button className="mdc-button mdc-button--outlined btn-large">
                          <span className="mdc-button__ripple"></span>
                          <span className="mdc-button__label">Add More Adult Occupants</span>
                        </button>
                      </div>
                    </div>



                  </div>

                  <div className="next-prev-btns">
                    <button className="mdc-button mdc-button--raised " onClick={goBack}>
                      <span className="mdc-button__label">previous</span>
                    </button>
                    <button className="mdc-button mdc-button--raised green-btn ">
                      <span className="mdc-button__label">next</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResidentialOccupants;