import React ,{useState, useEffect} from "react"
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import { getListOfParentFolder, getListOfParentFolderNew } from '../../../functions/api'
// import {  toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';

// import HeadOfficeFolderDetail from './HeadOfficeFolderDetail'
// import HeadOfficeFilesDetail from './HeadOfficeFilesDetail'

//Import Images
// import favourite from '../../../images/favorite.svg';
// import privateFolder from '../../../images/private.svg';
// import folder from '../../../images/folder.svg';
// import folderTwo from '../../../images/folder-two.svg';

//jquiery
// import { $ } from 'react-jquery-plugin';
import LoaderComponent from "../../../commonComponents/LoaderComponent/LoaderComponent";

var uid;

const FolderNavigation=(props)=>
{
	const { alias1 } = useParams();
	const history = useHistory()
	// const [allFolders, setAllFolders] = useState([])
	const[ mainFolderData , setMainFolderData] = useState([])
	const [ subFolderData , setSubFolderData] = useState([])
	const [ highligtedBox , setHighLightedBox] = useState([])
	const [ loader , setLoader] = useState(true)


	const location =  useLocation();

	useEffect(()=> {	
		uid = window.localStorage.getItem("uid");
		if (props.uid){
			uid = props.uid
		}
		 getMainFolder()
		// await getAllFolders()
	},[])


	// async function getMainFolder() {
	// 	const response = await getListOfParentFolder();
	// 	if(response.status === 200){
	// 		const get_required_data =[];
	// 		for (let iterator = 0; iterator < response.data.data.length; iterator++) {
				
	// 			if (response.data.data[iterator].field_user[0] == (uid)) {
	// 				console.log("response.data.data[iterator]", response.data.data[iterator]);
	// 				get_required_data.push(response.data.data[iterator])
	// 		        //  setMainFolderData(get_required_data)
					
	// 			}
	// 		}
	// 		setMainFolderData(get_required_data);
	// 		console.log('get_required_data--', get_required_data);
	// 	}
	// }
	// async function getMainFolder() {
	// 	const response = await getListOfParentFolder(uid);
	// 	if(response.status === 200){
	// 		const get_required_data =[];
	// 		const get_sub_folder_data =[];
	// 		for (let iterator = 0; iterator < response.data.data.length; iterator++) {
	// 			if(response.data.data[iterator].field_user[0] === uid){
	// 					get_required_data.push(response.data.data[iterator])
	// 					// const responseSub = await getListOfSubFolder(response.data.data[iterator].tid)
	// 					// get_sub_folder_data.push(responseSub.data.data)
	// 			}
	// 		}
	// 		setMainFolderData(get_required_data)
	// 		const flattenSubArray = get_sub_folder_data.flat(1)
	// 		if(flattenSubArray.length>0){
	// 			setSubFolderData(flattenSubArray)
	// 			setLoader(false);
	// 		}
	// 		setLoader(false)
	// 	}else{
	// 		setLoader(false);
	// 	}
	// }
  async function getMainFolder() {
		const response = await getListOfParentFolderNew(uid);
		if(response.status === 200){
			setMainFolderData(response.data.data.folders)
			setLoader(false)
		}else{
			setLoader(false);
		}
	}
	// const showSubFolder = async() =>{
	// 	const getFilteredData = await getFolderAllAPI();
	// 	if(getFilteredData.status === 200){
	// 		const resp = getFilteredData.data.data;
	// 		console.log('resp', resp);
	// 		setSubFolderData(resp);
	// 	}
	// }
// 	const showSubFolder = async() =>{
// 		const getFilteredData = await getFolderAllAPI();
// 		if(getFilteredData.status === 200){
// 			const resp = getFilteredData.data.data;
// 			// setSubFolderData(resp)
// ;
// 		}
// 	}
	const sendPreviousPageData=(data , tid)=>{
		window.scrollTo(0, 0);
		setHighLightedBox(tid)
		history.push({pathname:'/library' , state:{requiredData : data}})
		window.localStorage.setItem('folderParentId', tid)
		props.getParentId(tid);
	}

	useEffect(()=>
	{
		getMainFolder()
		// showSubFolder();
		// getInitialData()
    // eslint-disable-next-line
	},[props.data]);

	// async function getInitialData() {
	// 	let locationRequiredParentId = window.localStorage.getItem('folderParentId');
	// 	if(props.data !== undefined && props.data.length > 0)
	// 	{
	// 		setAllFolders(props.data)
	// 	}
	// 	else
	// 	{
	// 		let foldersResponse = await getFolderByParentIdAPI(locationRequiredParentId);
	// 		if(foldersResponse.status === 200)
	// 		{
	// 			if(foldersResponse.data.data.length > 0)
	// 			{
	// 				setAllFolders(foldersResponse.data.data)
	// 			}
	// 		}
	// 	}
	// }

	const showFiles=(taxonomyId , tid)=>
	{
		window.scrollTo(0, 0);
		setHighLightedBox(tid)
		// history.push(`/library/${taxonomyId}`)
    history.push(`/library/${taxonomyId}`, {uid:uid})
	}

	const goBack=()=>
	{
		window.scrollTo(0, 0);
		if(location.pathname ==='/library'){
			// window.location.reload()
		}
		history.push('/library')
		window.localStorage.removeItem('folderParentId');
		
	}

	return(
		<div className="col-md-4">
			<div className="sidebar sidebar-locker ">
				<div className="task-listing-block">
					<div className="common-header-row">
						<h4>Locker</h4>	
					</div>	

					<div className="folders-listing">
						{
							alias1 === undefined ?
							<span  onClick={() =>goBack()} className="material-icons arrow-upward">arrow_upward</span>
							:
							null
						}
						{loader === true ? <LoaderComponent displayLoader={loader}/> : 
						<ul>
							<li style={{cursor:'pointer'}} ><span className="material-icons">create_new_folder</span><text onClick={() => goBack()} >Locker</text>
								<ul>
									<li>
										<ul>
										    <>
											{   mainFolderData.length > 0 ? mainFolderData.map((items,index)=>
												{
													// let folerName = items.name.length > 12 ? items.name.substring(0, 12)+"...." : items.name.substring(0, 12)
													// let subDetailsOfFolder = subFolderData.filter(item => item.parent_target_id[0] === (items.tid).toString());
													
									
													return(
													<>
														<li key={`subFolder-index-key${index}`} className={ highligtedBox.includes(items.term_id) ? "active" : "" } style={{cursor:'pointer'}}>
															<div onClick={()=>sendPreviousPageData(items.sub , items.term_id)}>
															<span className="material-icons">folder</span>
															<text>{items.term_name}</text>
															</div>
															{/* {subDetailsOfFolder.length >0 ?(
															<span className="material-icons">subdirectory_arrow_right</span>
															):null} */}
															{items.sub.map((values) =>{ return(
															       <li  className="little-left" onClick={() => showFiles(values.term_id, items.term_id)}>
																	<span className="material-icons">folder_open</span>
																	<text >{values.term_name}</text>
					
																   </li>
																   )})}
															{
																items.term_id === alias1 ?
																<div onClick={goBack}>
																	<span className="material-icons arrow-upward" >arrow_upward</span>
																</div>
																:
																null
															}
														</li>
														
													</>	
													)
												})
												:
												null
											}
											</>
										</ul>
									</li>
								</ul>
							</li>
						</ul>	
}
					</div>	
				</div>	
			</div>	
		</div>

										
	)
}

export default FolderNavigation