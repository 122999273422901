import React, { useEffect, useState } from 'react';
import './styles.js';
import { login, setUserRole } from '../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWithoutLogin from '../../../../src/commonComponents/HeaderWithoutLogin/HeaderWithoutLogin';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { $ } from 'react-jquery-plugin';
import { getAuthToken } from '../../../redux/actions/auth';


//Import Images
import media1 from '../../../../src/images/media1.png';
import WFDC from '../../../../src/images/LOGO_WFDC.png'
import BayTerracelogo from '../../../../src/images/BayTerracelogo.png'
//
const LoginPage = (props) => {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [visibility, setVisibility] = useState('visibility_off')
  const [passwordVisibility, setPasswordVisibility] = useState('password')

  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    if (query.get('error') == 'token-expired') {
      // alert('testt')
      toast.success('Token Expired', {
        position: 'top-center'
      });
    }
    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

  }, [])

  const loginUser = async (e) => {
    e.preventDefault();
    setShowLoader(true)
    if (username !== '' && password !== '') {
      let responseData = await dispatch(login(username, password))
      if (responseData.status !== 200) {
        setShowLoader(false)
        toast.error(responseData.message, {
          position: 'top-center'
        });
      } else {
        setShowLoader(false)
        if (username.includes('@')) {
          let res = await dispatch(getAuthToken(responseData.data.current_user.name, password))
        } else {
          let res = await dispatch(getAuthToken(username, password))
        }
        let userRole = responseData.data.current_user.roles[1];
        if (userRole == 'educator') {
          history.push(`/educators-dashboard`);
          dispatch(setUserRole(1))
        } else if (userRole === 'head_coordinator') {
          dispatch(setUserRole(2))
          history.push('/co-ordination-unit-dashboard');
        } else if (userRole === 'cabt') {
          dispatch(setUserRole(3))
          history.push('/cabt-dashboard');
        }

      }
    } else {
      setShowLoader(false)
      if (username === "" || username === undefined) {
        toast.error("Please enter username or email.", {
          position: 'top-center'
        });
      } else if (password === "" || password === undefined) {
        toast.error("Please enter password.", {
          position: 'top-center'
        });
      }
    }
  }

  const moveForgetPassword = (e) => {
    e.preventDefault()
    history.push('/forget-password')
  }

  const showPassword = () => {
    if (visibility === 'visibility_off') {
      setVisibility('visibility')
      setPasswordVisibility('text')
    }

    if (visibility === 'visibility') {
      setVisibility('visibility_off')
      setPasswordVisibility('password')
    }
  }

  return (
    <>
      <LoaderComponent displayLoader={showLoader} />
      <ToastContainer />
      <HeaderWithoutLogin />
      <div className="main-wrapper">
        <div className="container">
          <div className="user-pages-wrap login-page">
            <div className="row">
              <div className="col-md-5">
                <div className="login-form">
                  <h1 className="common-heaidng48">Login in to WISE</h1>
                  <h3 className="small-title">Welcome back! Sign in using your email or username to continue</h3>
                  <form onSubmit={e => { loginUser(e) }}>
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input type="text" className={username ? "mdc-text-field__input active" : "mdc-text-field__input"} value={username} id="username" aria-labelledby="my-label-id" onChange={(e) => setUsername(e.target.value)} />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">Email/Username</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>

                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input className={password ? "mdc-text-field__input active" : "mdc-text-field__input"} type={passwordVisibility} value={password} id="password" aria-labelledby="my-label-id" onChange={(e) => setPassword(e.target.value)} />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">Password</span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span onClick={showPassword} class="material-icons">{visibility}</span>
                    </label>

                    <button className="mdc-button mdc-button--raised">
                      <span className="mdc-button__label">Log in</span>
                    </button>
                  </form>
                  <a href="#" className="forget-pwd-link" onClick={moveForgetPassword}>Forgot password?</a>
                </div>
              </div>
              <div className="col-md-7">
                <div className="media-block">
                  <img src={WFDC} className='home-logo-design-css' />
                </div>
                <div className="media-block">
                  <img src={BayTerracelogo} className='home-logo-design-css' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage;