/* eslint-disable no-unreachable */
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoaderComponent from "../../../commonComponents/LoaderComponent/LoaderComponent";
import HeadOfficeMainHeader from "../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader";
import CoordinatorsList from "../../../commonComponents/CommonCoordinatorsListComponent/CoordinatorsList";
import { getCaBTList, getCoordinators } from "../../../functions/api";
import { $ } from "react-jquery-plugin";

function CoordinatorsListPage() {
  const [showLoader, setShowLoader] = useState(true);
  const [coOrdinatorsData, setCoOrdinatorsData] = useState({});
  const [isArchiveOrNot, setIsArchiveOrNot] = useState(false);
  const [isCaBTlist, setIsCaBTlist] = useState(false);

  async function getInitialData() {
    setIsArchiveOrNot(false);
    let pathName = window.location.pathname
    if (pathName.includes('cabt-list')) {
      setIsCaBTlist(true)
    }
    const sortedEducatorsList = (pathName.includes('cabt-list')) ? await getCaBTList() : await getCoordinators()
    if (sortedEducatorsList.status === 200) {
      setShowLoader(false);
      if (sortedEducatorsList.data.data.length > 0) {
        let resData = sortedEducatorsList.data.data.filter(
          (item) => item.field_archive === "Off" || item.field_archive === ""
        );
        console.log(resData, "resData");
        setCoOrdinatorsData(resData);
      }
    } else {
      setShowLoader(false);
    }
  }

  useEffect(() => {
    $(function () {
      $(
        ".mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea"
      ).on("focusout", function () {
        if ($(this).val().length > 0) {
          $(this).addClass("active");
        } else {
          $(this).removeClass("active");
        }
      });
    });
    // console.log('pathname = ', window.location.pathname);
    getInitialData();
  }, []);
  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={showLoader} />
      <HeadOfficeMainHeader />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="content-block-right">
                  <div className="notice-listing-block educator-list-page-header">
                    <div className="common-header-row-new h4">
                      <h4>{isCaBTlist ? 'CaBT' : 'Coordinators'}</h4>
                      <div className="common-header-row col-md-7">
                        <div className="block-right">
                          <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' >
                            <span className="mdc-button__label"><a target={"_blank"} href={isCaBTlist ? 'https://wiseappbackend.thecorella.com/view/captuser-csv' : `https://wiseappbackend.thecorella.com/view/all/user-csv`} style={{ color: '#d9d9d9' }}>Export {isCaBTlist ? 'CaBT' : "Coordinator"} Report</a></span>
                          </button>
                          <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' >
                            <span className="mdc-button__label"><a target={"_blank"} href={isCaBTlist ? 'https://wiseappbackend.thecorella.com/csv/professional_files/cabt' : `https://wiseappbackend.thecorella.com/csv/professional_file/coordinator`} style={{ color: '#d9d9d9' }}>Export PD Report</a></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="task-listing task-list-with-media">
                      {coOrdinatorsData && coOrdinatorsData.length ? (
                       <CoordinatorsList
                          data={coOrdinatorsData}
                          isArchive={isArchiveOrNot}
                          isCaBT={isCaBTlist}
                        />
                      ) : (
                        <label> No Records Found!!</label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CoordinatorsListPage;
