import React, { useEffect, useState } from 'react';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import {
	getAllContent, getAllContentCustom, getParticularUserContentCustom, getArchivedTasks, archiveTask,
	uploadImage, getSpecificUser, completeTask, getCompletedTasks, getParticularUserContent, filterTasks, deleteNode,
	getGlobalAssignedTask, markGlobalTaskComplete, getAllTaskFlags, markGlobalTaskUnComplete, readNotification, taskActionComplete, getGlobalTasksList, getTaskAssign, getUpcomingMandS
} from '../../../functions/api';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux"
import './styles.css';
import { $ } from 'react-jquery-plugin';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import EmptyData from '../../../commonComponents/EmptyData/EmptyData';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { PaginatedList } from "react-paginated-list";
import { getGlobalTask } from '../../../functions/api';

const EducatorTasks = () => {

	let userId = window.localStorage.getItem('uid')
	let digitalSignaturePath = ''

	const [allTasks, setAllTasks] = useState([])
	const [allTasksStatus, setAllTasksStatus] = useState([])

	const store = useSelector(state => state.auth)
	const [userRole, setUserRole] = useState(1)
	const [completedTasks, setCompletedTasks] = useState([])
	const [checkedCompleteTask, setCheckedCompleteTask] = useState('checked');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [dropdownIndex, setDropdownIndex] = useState('')
	const [open, setOpen] = useState(false);
	const [taskId, setTaskId] = useState('');
	const [status, setStatus] = useState();
	const [reasonToExplain, setReasonToExplain] = useState(false)
	const [reason, setReason] = useState();
	const [noData, setNoData] = useState(false);
	const [sigPad, setSigPad] = useState()
	const [taskStatus, setTaskStatus] = useState('To-Do');
	const [taskType, setTaskType] = useState('')
	const [showLoader, setShowLoader] = useState(true)
	const [allPreviousSignatures, setAllPreviousSignatures] = useState([])
	const [userData, setUserData] = useState({})

	let userName = window.localStorage.getItem('name');
	let uid = window.localStorage.getItem('uid');

	const history = useHistory();

	const getGlobalTasks = async (status) => {
		setNoData(false)
		setShowLoader(true)
		let globalTaskResponse = await getGlobalAssignedTask(status)
		if (globalTaskResponse.status === 200) {
			setShowLoader(false)
			if (globalTaskResponse.data !== undefined && globalTaskResponse.data.data.length > 0) {
				let newTaskList = globalTaskResponse.data.data
				await setAllTasks([...newTaskList])
			} else {
				await setAllTasks([])
				setNoData(true)
			}
		}
		else {
			setShowLoader(false)
		}
	}

	const getAllTasksStatus = async () => {
		let getResponse = await getAllTaskFlags()
		if (getResponse.status === 200) {
			if (getResponse.data !== undefined && getResponse.data.data.length > 0) {
				setAllTasksStatus(getResponse.data.data)
			}
		}
	}

	useEffect(() => {
		window.localStorage.removeItem('folderParentId');
		$(function () {
			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
				if ($(this).val().length > 0) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			})
		})
		getAllInitalData(store.userRole);
		setUserRole(store.userRole)
	}, [store.userRole, taskStatus])

	async function markTaskCompleted() {

		setShowLoader(true)
		digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
		if (digitalSignaturePath.length !== 130) {
			let image_response = await uploadImage('digital_signature.png', digitalSignaturePath);
			if (image_response.status === 201) {
				if (image_response.data.data.fid !== undefined && image_response.data.data.fid !== '') {
					let fid = image_response.data.data.fid[0] ? image_response.data.data.fid[0].value : '';
					window.localStorage.setItem('digitalImageFid', fid);
				}
				if (status === true) {  // task complete 
					let data = { status: true, archived: 0, signature: window.localStorage.getItem('digitalImageFid') };
					let response = await completeTask(taskId, data)
					if (response.status === 200) {
						window.localStorage.removeItem('digitalImageFid');
						if (taskStatus === 'Archive') {
							let uid = window.localStorage.getItem('uid');
							let archived_tasks = await getArchivedTasks(uid);
							if (archived_tasks.status === 200) {
								setShowLoader(false)
								await setAllTasks(archived_tasks.data.data)
							}
							else {
								setShowLoader(false)
							}

						}

						if (taskStatus === 'Pending') {
							let completed_tasks = await filterTasks(0);
							if (completed_tasks.status === 200) {
								setShowLoader(false)
								await setAllTasks(completed_tasks.data.data)
							}
							else {
								setShowLoader(false)
							}

						}

						if (taskStatus === 'All') {
							let uid = window.localStorage.getItem('uid');
							let tasksArray = await getParticularUserContentCustom('task', uid)
							if (tasksArray.status === 200) {
								setShowLoader(false)
								if (tasksArray.data.data.length > 0) {
									let newTaskList = tasksArray.data.data
									await setAllTasks([...newTaskList])
								}
							}
							else {
								setShowLoader(false)
							}

						}
						complete_notification()
						toast.success('Task Completed Successfully!!', {
							position: 'top-center'
						});
						setOpen(false)
						setReasonToExplain(false)
						setReason('')
					} else {
						toast.error(response.message, {
							position: 'top-center'
						});
						setOpen(false)
						setReasonToExplain(false)
						setReason('')
					}
				} else {
					let data = { status: false, archived: false, reason: reason, signature: window.localStorage.getItem('digitalImageFid') };
					if (reason !== '') {
						let response = await completeTask(taskId, data)
						if (response.status === 200) {
							window.localStorage.removeItem('digitalImageFid');
							if (taskStatus === 'Completed') {
								let completed_tasks = await filterTasks(1);
								if (completed_tasks.status === 200) {
									setShowLoader(false)
									await setAllTasks(completed_tasks.data.data)
								}
								else {
									setShowLoader(false)
								}
							}

							if (taskStatus === 'Archive') {
								let uid = window.localStorage.getItem('uid');
								let archived_tasks = await getArchivedTasks(uid);
								if (archived_tasks.status === 200) {
									setShowLoader(false)
									await setAllTasks(archived_tasks.data.data)
								}
								else {
									setShowLoader(false)
								}

							}

							if (taskStatus === 'All') {
								let uid = window.localStorage.getItem('uid');
								let tasksArray = await getParticularUserContentCustom('task', uid)
								if (tasksArray.status === 200) {
									setShowLoader(false)
									if (tasksArray.data.data.length > 0) {
										let newTaskList = tasksArray.data.data
										await setAllTasks([...newTaskList])
									}
								}
								else {
									setShowLoader(false)
								}

							}
							toast.success('Task Incompleted!!', {
								position: 'top-center'
							});
							setOpen(false)
							setReasonToExplain(false)
							setReason('')
						} else {
							toast.error(response.message, {
								position: 'top-center'
							});
							setOpen(false)
							setReasonToExplain(false)
							setReason('')
						}
					} else {
						setShowLoader(false)
						toast.error('Please Explain Reason To Incomplete', {
							position: 'top-center'
						});
					}
				}
			} else {
				setShowLoader(false)
				toast.error(image_response.message, {
					position: 'top-center'
				});
			}
		} else {
			setShowLoader(false)
			toast.error('Your Signatures are required', {
				position: 'top-center'
			});
		}
	}

	async function completeGlobalTask() {
		setShowLoader(true)
		digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
		if (digitalSignaturePath.length !== 130) {
			let image_response = await uploadImage('digital_signature.png', digitalSignaturePath);
			if (image_response.status === 201) {
				if (image_response.data.data.fid !== undefined && image_response.data.data.fid !== '') {
					let fid = image_response.data.data.fid[0] ? image_response.data.data.fid[0].value : '';
					window.localStorage.setItem('digitalImageFid', fid);
				}
				if (status === true) {  // To complete the task  
					let _prevArray = allPreviousSignatures.length > 0 ?
						setAllPreviousSignatures(() => allPreviousSignatures.concat({ "target_id": window.localStorage.getItem('digitalImageFid') })) : [{ "target_id": window.localStorage.getItem('digitalImageFid') }]
					let data = { status: true, archived: 0, signature: _prevArray, taskCompleted: true };
					let response = await markGlobalTaskComplete(taskId, data)
					if (response.status === 200) {
						window.localStorage.removeItem('digitalImageFid');
						await getGlobalTasks(0)
						toast.success('Task Completed Successfully!!', {
							position: 'top-center'
						});
						setOpen(false)
						setReasonToExplain(false)
						setReason('')
					} else {
						toast.error(response.message, {
							position: 'top-center'
						});
						setOpen(false)
						setReasonToExplain(false)
						setReason('')
					}
				} else { // To uncomplete the completed task again
					let _prevArray = allPreviousSignatures.length > 0 ?
						setAllPreviousSignatures(() => allPreviousSignatures.concat({ "target_id": window.localStorage.getItem('digitalImageFid') })) : [{ "target_id": window.localStorage.getItem('digitalImageFid') }]
					let data = { status: false, archived: false, reason: reason, signature: _prevArray, taskCompleted: false };
					if (reason !== '') {
						let response = await markGlobalTaskUnComplete(taskId, data)
						if (response.status === 200) {
							window.localStorage.removeItem('digitalImageFid');
							await getGlobalTasks(1)
							toast.success('Task Incompleted!!', {
								position: 'top-center'
							});
							setOpen(false)
							setReasonToExplain(false)
							setReason('')
						} else {
							toast.error(response.message, {
								position: 'top-center'
							});
							setOpen(false)
							setReasonToExplain(false)
							setReason('')
						}
					} else {
						setShowLoader(false)
						toast.error('Please Explain Reason To Incomplete', {
							position: 'top-center'
						});
					}
				}
			} else {
				setShowLoader(false)
				toast.error(image_response.message, {
					position: 'top-center'
				});
			}
		} else {
			setShowLoader(false)
			toast.error('Your Signatures are required', {
				position: 'top-center'
			});
		}
	}

	async function getAllInitalData(role) {

		let loggedUserId = window.localStorage.getItem('uid');
		setShowLoader(true)
		setTaskType("Personal")

		if (role === 2) // for headOffice
		{
			let globalTaskArray = await getGlobalTasksList();
			let newList = [];
			let tasksData = await getAllContent('custom-task');

			if (tasksData.status === 200) {
				let arr = [...tasksData.data.data]
				newList = arr.filter((item) => {
					if ((item.field_assign_to.length > 0 && item.field_assign_to[0].target_id != ''  && item.field_assign_to[0].target_id != item.uid[0].target_id) || item.uid[0].target_id == loggedUserId) {
						return item
					}
				})
			}

			if (taskStatus === 'To-Do') {
				let arr = newList.filter((item) => {
					if (item.field_status[0]?.value == 'To-Do') {
						return item
					}
				})
				setAllTasks(arr)
			}
			else if (taskStatus === 'in-progress') {
				let arr = newList.filter((item) => {
					if (item.field_status[0]?.value == 'In Progress') {
						return item
					}
				})
				setAllTasks(arr)
			}
			else if (taskStatus === 'completed') {
				let arr = newList.filter((item) => {
					if (item.field_status[0]?.value == 'Completed') {
						return item
					}
				})
				setAllTasks(arr)
			}
			else {
				let tasksData = await getAllContent('task');
				if (tasksData.status === 200) {
					let arr = [...tasksData.data.data]
					let newArr = arr.filter((item) => {
						if ((item.field_assign_to.length > 0 && item.field_assign_to[0].target_id != '' && item.field_assign_to[0].target_id != item.uid[0].target_id) || item.uid[0].target_id == loggedUserId) {
							return item
						}
					})
					setShowLoader(false)
					setAllTasks(newArr)

				}
			}
		}
		else if (role === 1 || role === 3){  // for educators and CaBT
      let uid = window.localStorage.getItem('uid');
      let tasksData = await getParticularUserContentCustom('task', uid)
      if (tasksData.status === 200) {
        const filteredTasks = tasksData.data.data.filter(item => (item.field_assign_to.length > 0 && item.field_assign_to[0].target_id === parseInt(uid)))
			if (taskStatus === 'To-Do') {
					setShowLoader(false)
					const filteredToDoTasks = filteredTasks.filter(item => (item.field_status.length > 0 && item.field_status[0].value === 'To-Do'))
					setAllTasks(filteredToDoTasks)
				}else if (taskStatus === 'in-progress') {
					setShowLoader(false)
					const filteredProgressTasks = filteredTasks.filter(item => (item.field_status.length > 0 && item.field_status[0].value === 'In Progress'))
					setAllTasks(filteredProgressTasks)
				}else if (taskStatus === 'completed') {
					setShowLoader(false)
					const filteredStatusNew = filteredTasks.filter(item => (item.field_status.length > 0 && item.field_status[0].value === 'Completed'))
					setAllTasks(filteredStatusNew)
			}else {
					setShowLoader(false)
					setAllTasks(filteredTasks)
			}
    }
		}
		setShowLoader(false)
	}

	async function filterTasksStatus(value, e) {
		e.preventDefault()
		setShowLoader(true)
		setNoData(false)
		if (value === 'completed') {
			setTaskStatus('completed')
		} else if (value === 'in-progress') {
			setTaskStatus('in-progress')
		} else if (value === 'all') {
			setTaskStatus('all')
		}
		else if (value === 'To-Do') {
			setTaskStatus('To-Do')
		}
		setShowLoader(false)
	}

	async function deleteItem(item) {
		if (allTasks.length > 0) {
			const data = allTasks.filter(i => i.nid[0].value !== item)
			await setAllTasks([...data])
		}
	}

	async function deleteTask(taskId) {
		let response = await deleteNode(taskId);
		if (response.status === 204) {
			deleteItem(taskId)
			toast.success('Task has been deleted successfully!', {
				position: 'top-center'
			});
		} else {
			toast.error(response.message, {
				position: 'top-center'
			});
		}
	}


	function confirmDeleteTask(taskId) {
		setAnchorEl(null);
		confirmAlert({
			title: 'Are you sure you want to delete?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteTask(taskId)
				},
				{
					label: 'No',
				}
			]
		});
	}

	async function addToArchieved(taskId, taskStatus, message) {
		setShowLoader(true)
		if (taskId !== '') {
			let uid = window.localStorage.getItem('uid');
			let response = await archiveTask(taskId, uid, taskStatus);
			if (response.status === 200) {
				setShowLoader(false)
				toast.success(`Task ${message} Successfully!!`, {
					position: 'top-center'
				});
				// window.location.reload();
				if (taskType === "Personal") {
					if (taskStatus === 'Completed') {
						let completed_tasks = await filterTasks(1);
						await setAllTasks(completed_tasks.data.data)
					}
				}
				else if (taskType === "All") {
					setTaskStatus("completed")
					await getAllTasksStatus()
					await getGlobalTasks(1)
				}

				if (taskStatus === 'All') {
					if (userRole === 1 || userRole === 3) {
						let uid = window.localStorage.getItem('uid');
						let tasksArray = await getParticularUserContentCustom('task', uid)
						if (tasksArray.status === 200) {
							setShowLoader(false)
							if (tasksArray.data.data.length > 0) {
								let newTaskList = tasksArray.data.data
								await setAllTasks([...newTaskList])
							}
						}
					} else {
						let tasksArray = await getAllContentCustom('task')
						await setAllTasks(tasksArray.data.data)
						if (tasksArray.response === 200) {
							setShowLoader(false)
							if (tasksArray.data.data.length > 0) {
								let newTaskList = tasksArray.data.data
								await setAllTasks([...newTaskList])
							}
						}
					}
				}

			} else {
				setShowLoader(false)
				toast.error(response.message, {
					position: 'top-center'
				});
			}
		}
	}

	//COMPLETE TASK API
	async function complete_notification() {


		var obj = {
			"flag_id": [{ "target_id": "complete_flag" }],
			"entity_type": [{ "value": "node" }],
			"entity_id": [{ "value": taskId }],
			"uid": [{ "target_id": userId }],
			"field_complete_task": [{ "value": true }]
		}

		const res = await readNotification(obj);
		const resposne = await taskActionComplete(taskId)

		if (res && resposne) {
			setOpen(false)
			// window.location.reload()
		}

	}

	return (
		<>
			<ToastContainer />
			{
				userRole === 1 || userRole === 3 ?
					<EducatorsMainHeader />
					:
					<HeadOfficeMainHeader />
			}
			<LoaderComponent displayLoader={showLoader} />

			<div className="main-wrapper mtb-70">
				<div className="container">
					<div className="dashboard-wrap">
						<div className="row">
							<div className="col-md-12">
								<div className="content-block-right">
									<div className="task-listing-block tasks-page-block">
										<div className="common-header-row">
											<h4>Tasks
												<Link to={`/add-new-task`} class="mdc-button mdc-button--raised green-btn"><span class="mdc-button__label"> Add New Task </span> </Link>
											</h4>

											{
												taskType === "Personal" ?
													<Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
														value={taskStatus}>
														<MenuItem value="all" onClick={(e) => filterTasksStatus("all", e)}>All</MenuItem>
														<MenuItem value="To-Do" onClick={(e) => filterTasksStatus("To-Do", e)}>To-Do</MenuItem>
														<MenuItem value="in-progress" onClick={(e) => filterTasksStatus("in-progress", e)}>In-Progress</MenuItem>
														<MenuItem value="completed" onClick={(e) => filterTasksStatus("completed", e)}>Completed</MenuItem>
													</Select>
													:
													<Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
														value={taskStatus}>
													</Select>
											}


										</div>
										{noData === true ? <EmptyData title='Task List is Empty!' desc={userRole === 1 || userRole === 3 ? 'Tasks assigned by your Co ordination Unit will be displayed here!' : 'No Tasks to the Educators have been assigned by Co ordination Unit. Click Add New to assign One.'} /> :
											allTasks.length > 0 ?
											<PaginatedList
												list={allTasks}
												itemsPerPage={10}
												renderList={(list) => (
													<>
														<TableContainer>
															<Table>
																<TableHead>
																	<TableRow>
																		<TableCell>Title</TableCell>
																		{
																			userRole === 2 &&
																			<TableCell>Task Assigned To</TableCell>
																		}
																		<TableCell>Due Date</TableCell>
																		<TableCell>Status</TableCell>
																		<TableCell>Actions</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{list.length > 0 && list.map((data, index) => (
																		<>

																			{/* {userData[]} */}
																			<TableRow style={{ cursor: 'pointer' }} key={index} className="mdc-form-field">
																				<TableCell onClick={() => history.push(`/task-detail-page/${data.nid[0].value}`)} className="custom-title-link" dangerouslySetInnerHTML={{ __html: data.title[0].value }}></TableCell>
																				{
																					userRole === 2 &&
																					<TableCell onClick={() => history.push(`/task-detail-page/${data.nid[0].value}`)}>
																						{data.field_assign_to_name ?? '-'}
																					</TableCell>
																				}
																				<TableCell onClick={() => history.push(`/task-detail-page/${data.nid[0].value}`)}>{data.field_date !== undefined && data.field_date !== '' ? data.field_date[0] ? moment(data.field_date[0].value).format('DD-MM-YYYY') : null : null}</TableCell>
																				<TableCell onClick={() => history.push(`/task-detail-page/${data.nid[0].value}`)}>{data.field_status !== undefined && data.field_status !== '' ? data.field_status[0] ? (data.field_status[0].value) : null : null}</TableCell>
																				{/* 
																				{
																					taskType === "Personal" ? <TableCell>{data.field_completed !== '' && data.field_completed !== undefined ? data.field_completed[0] ? data.field_completed[0].value == true ? 'Completed' : 'In Progress' : null : null}</TableCell>
																						:
																						<TableCell onClick={() => history.push(`/task-detail-page/${data.nid[0].value}`)}>
																							{taskStatus === "All" ?
																								allTasksStatus[index].field_complete_task === "True" ?
																									'Completed' : 'In Progress' : taskStatus === "Pending" ?
																									"In Progress" : taskStatus === "Completed" ?
																										"Completed" : taskStatus === "Archive" ?
																											"Completed" : null}
																						</TableCell>
																				} */}
																				<TableCell>
																					{userRole === 2 ?
																						<Link className="bg-green action-btn-style" to={`/edit-task/${data.nid[0].value}`}>
																							Edit </Link> : null
																					}
																					{userRole === 2 ?
																						<button className="bg-red action-btn-style del" onClick={() => confirmDeleteTask(data.nid[0].value)}>
																							Delete</button> : null
																					}
																					{(data.uid[0].target_id) === parseInt(uid) ? (
																						userRole === 2 ? null : (
																							<>
																								<Link className="bg-green action-btn-style" to={`/edit-task/${data.nid[0].value}`}>
																									Edit
																								</Link>
																								<button className="bg-red action-btn-style del" onClick={() => confirmDeleteTask(data.nid[0].value)}>
																									Delete</button>
																							</>
																						)) : null}
																					{
																						taskType === "Personal" ?
																							data.field_completed !== undefined && data.field_completed.length >= 1 && data.field_completed[0].value === true
																								? data.field_archived !== undefined && data.field_archived !== '' && data.field_archived[0] && data.field_archived[0].value === false
																									|| data.field_archived.length === 0
																									? <button className="bg-yellow action-btn-style" onClick={() => addToArchieved(data.nid[0].value, true, "Archive")}>Archive</button>
																									: <button className="bg-yellow action-btn-style" onClick={() => addToArchieved(data.nid[0].value, false, "UnArchived")}>UnArchive</button> : null
																							:
																							taskStatus === "Completed" || taskStatus === "Archive" ?
																								data.field_archived !== undefined && data.field_archived !== '' && data.field_archived[0] && data.field_archived[0].value === false
																									|| data.field_archived.length === 0
																									? <button className="bg-yellow action-btn-style" onClick={() => addToArchieved(data.nid[0].value, true, "Archive")}>Archive</button>
																									: <button className="bg-yellow action-btn-style" onClick={() => addToArchieved(data.nid[0].value, false, "UnArchived")}>UnArchive</button>
																								:
																								null
																					}
																				</TableCell>
																			</TableRow>
																		</>
																	))}
																</TableBody>
															</Table>
														</TableContainer>
													</>
												)}
											/> : <EmptyData title='Task List is Empty!' desc={userRole === 1 || userRole === 3 ? 'Tasks assigned by your Co ordination Unit will be displayed here!' : 'No Tasks to the Educators have been assigned by Co ordination Unit. Click Add New to assign One.'} /> 
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{"Task Action"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">Let's perform the task action to make the task complete or incomplete.</DialogContentText>
					<>
						{
							reasonToExplain === true ?
								<div className="col-md-12">
									<div className="outlined-textfield-wrap mt-10">
										<div className="material-textfield">
											<textarea value={reason} onChange={(e) => setReason(e.target.value)} id="message" className={reason !== '' ? 'form__field active' : 'form__field'} placeholder="" rows="6"></textarea>
											<label>Incomplete Reason</label>
										</div>
									</div>
								</div>
								: null
						}
					</>
					<div className="signature-block">
						<label>Digital Signature</label>
						<SignaturePad canvasProps={{ className: styles.sigPad }} ref={(ref) => setSigPad(ref)} />
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} color="primary">Disagree</Button>
					{
						taskType === "Personal" ?
							<Button onClick={markTaskCompleted} color="primary" autoFocus>Agree</Button>
							:
							<Button onClick={completeGlobalTask} color="primary" autoFocus>Agree</Button>
					}
				</DialogActions>
			</Dialog>
		</>
	)
}

export default EducatorTasks;