import React, { useEffect, useState } from 'react';
import HeaderOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { $ } from 'react-jquery-plugin';
import Calendar from 'react-calendar';
// import TimePicker from 'react-time-picker';
import './styles.css';
// import styles from './styles.module.css'
import styles from '../../Educators/VisitDetail/styles.module.css'
import Select from 'react-select';
import { getAllEducators, updateUserImage, updateVisitDetails, CoordinatorList, addMeetingNotes, updateMeetingNotes } from '../../../functions/api';
import { uploadImage, convertTimeStampToTimeCustom, getSpecificUser, getLeaves } from '../../../functions/api';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";
import moment, { normalizeUnits } from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { NodeDetails } from '../../../functions/CommonFunctions';
import imageToBase64 from 'image-to-base64/browser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import SignaturePad from 'react-signature-canvas'
import './styles.css';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
// import blankImage from '../../../images/no_sign.png';
import blankImage from '../../../images/no_sign.png'
import { useSelector } from 'react-redux';
const taskMethods = ["To-Do", "Completed", "In Progress"]

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));



const EditVisit = (props) => {
  let editSigPad = {}
  let editCoordinatorPad = {}

  const classes = useStyles();

  const history = useHistory();
  const [title, setTitle] = useState('');
  const [educatorName, setEducatorName] = useState('')
  const [venue, setVenue] = useState('');
  const [resourcesPath, setResourcesPath] = useState([]);
  const [addImageName, setAddImageName] = useState('');
  const [visitNotes, setVisitNotes] = useState('');
  const [educatorsList, setEducatorsList] = useState([])
  const [visitMethodName, setVisitMethodName] = useState(null);
  const [visitMethod, setVisitMethod] = useState(null);
  const [showCalendar, setCalendar] = useState(false);
  const [showTimePicker, setTimePicker] = useState(false);
  const [timeValue, setTimeValue] = useState('');
  const [calendarValue, setCalendarValue] = useState();
  const [msDate, setMsDate] = useState('');
  const [addFile, setAddFile] = useState('');
  const [addVideo, setVideo] = useState('');
  const [options, setOptions] = useState([]);
  const [digitalSignatureName, setdigitalSignatureName] = useState('');
  const [digitalSignaturePath, setdigitalSignaturePath] = useState('');
  const [ajaxLoader, setAjaxLoader] = useState(false);
  const regex = /(<([^>]+)>)/ig;
  const [uploadImageName, setUploadImageName] = useState("");
  const [uploadImagePath, setUploadImagePath] = useState([]);
  const [updatedImagePath, setUpdatedImagePath] = useState([]);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
  const [leavesArray, setLeavesArray] = useState([])
  const [updatedImage, setUpdatedImage] = useState(false);
  const [updatedResources, setUpdatedResources] = useState(false);

  const [multipleVideoUrls, setMultipleVideoUrls] = useState([])
  const [cordinator, setCordinator] = useState('');
  const store = useSelector(state => state.auth)

  const [imagesId, setImagesId] = useState([])
  const [resourcesId, setResourcesId] = useState([])

  const [sigPad, setSigPad] = useState()

  const [sigCordPad, setSigCordPad] = useState()

  const [removeResourceIndex, setRemoveResourceIndex] = useState([])
  const [open, setOpen] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState('')

  const [blockSubmit, setBlockSubmit] = useState(false)
  const [taskTodo, setTaskTodo] = useState('To-Do')

  const [notes, setNotes] = useState('')
  const [educatorSignatureVisit, setEducatorSignatureVisit] = useState('')
  const [educatorSignatureId, setEducatorSignatureId] = useState('')
  const [coordinatorSignatureId, setCoordinatorSignatureId] = useState('')
  const [coordinatorSignatureVisit, setCoordinatorSignatureVisit] = useState('')
  const [imagesSignId, setImagesSignId] = useState(false)
  const [eduNotes, setEduNotes] = useState('')



  let signatureImage = '';
  let educatorSignature = ''


  useEffect(() => {
    //Dropdown toggle
    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
          $('.dropdown').slideUp();
        }
      });
    });


    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })
  }, [])

  const methods = [
    { value: 280, label: "Email" },
    { value: 281, label: "Home" },
    { value: 282, label: "Link" },
    { value: 279, label: "Phone" },
    { value: 4984, label: "Park" },
    { value: 4983, label: "WFDC Office" },
    { value: 283, label: "Other Location" }
  ]

  function calendar() {
    if (showCalendar === true) {
      setCalendar(false)
    }
    else {
      setCalendar(true)
    }
  }

  function timepicker() {
    setTimePicker(true)
  }

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  const educatorsHandler = (event) => {
    setEducatorName(event);
  };

  const getCoordinator = (event) => {
    // 
    setCordinator(event);
  }

  //get all cordinator list
  const [cordinator_option, set_cordinator_option] = useState([])
  async function get_cordinator() {
    setAjaxLoader(true)

    let list = await CoordinatorList();
    // 
    let opt_array = [
      {
        'label': "Assign Coordinator",
        'value': "",
      }
    ];
    if (list.data.data.length > 0) {
      list.data.data.map((v) => {
        const obj = {};
        obj['value'] = v.uid;
        obj['label'] = v.name;
        opt_array.push(obj);
      })
      set_cordinator_option(opt_array);
      setAjaxLoader(false)
      // 
    }
    // if(list.status == 200){
    // 	let arr=[];
    // 	
    // 	if(list.data.data.length > 0){
    // 		 list.data.data.map((v)=>{
    // 			arr.push(v.name)
    // 		 });
    // 		 setCordinator(arr);
    // 		 
    // 	}
    // }
    // else{
    // 	
    // }
  }

  const cancelModal = () => {
    setOpen(false)
    setSigPad('')
    setMeetingNotes('')
    setOpen(false)
  }

  // const submitMeetingNotes = async (e) => {
  // 	e.preventDefault()
  // 	let visit_id = props.match.params.id
  // 	let userId = await window.localStorage.getItem('uid');
  // 	setAjaxLoader(true)
  // 	let digitalSignaturePath = sigPad.getTrimmedCanvas().toDataURL('image/png')
  // 	const signatureUploadResponse = await uploadImage("signature.png", digitalSignaturePath)
  // 	if (signatureUploadResponse.status === 201) {
  // 		let signFid = signatureUploadResponse.data.data.fid[0].value;
  // 		if (signFid) {
  // 			let dataObj;
  // 			if (store.userRole === 2) {
  // 				dataObj = {
  // 					"type": [{
  // 						"target_id": "meeting_notes",
  // 						"target_type": "paragraphs_type"
  // 					}],
  // 					"field_meeting_notes": [{ "value": meetingNotes }],
  // 					"field_meeting_signature": [{ "target_id": signFid }],
  // 					"field_user": [{ "target_id": userId }],
  // 				}
  // 			}
  // 			else {
  // 				dataObj = {
  // 					"type": [{
  // 						"target_id": "meeting_notes",
  // 						"target_type": "paragraphs_type"
  // 					}],
  // 					"field_meeting_notes": [{ "value": meetingNotes }],
  // 					"field_educator_signature": [{ "target_id": signFid }],
  // 					"field_user": [{ "target_id": userId }],
  // 				}
  // 			}

  // 			let updateVisitMeetingResponse = await addMeetingNotes(dataObj);
  // 			if (updateVisitMeetingResponse.status === 201) {
  // 				if (updateVisitMeetingResponse.data.data !== undefined) {
  // 					let selectedObject = [{
  // 						"target_id": updateVisitMeetingResponse.data.data.id[0].value,
  // 						"target_revision_id": updateVisitMeetingResponse.data.data.revision_id[0].value
  // 					}]

  // 					let object = {
  // 						"type": [{ "target_id": "visits" }],
  // 						"field_meeting_notes": selectedObject,
  // 					}
  // 					let meetingRes = await updateMeetingNotes(visit_id, object)
  // 					if (meetingRes.status === 200) {
  // 						setAjaxLoader(false)
  // 						toast.success("Meeting Notes Added Successfully", { position: 'top-center' });
  // 						setSigPad('')
  // 						setMeetingNotes('')
  // 						setOpen(false)
  // 					}
  // 					else {
  // 						setAjaxLoader(false)
  // 						toast.error(meetingRes.message, { position: 'top-center' });
  // 					}
  // 				}
  // 			}

  // 		}
  // 	}
  // }



  const calendarHandler = (newDate) => {

    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date

    setMsDate(fullYear)
    setCalendar(false)
  }

  const timeHandler = (newTime) => {
    // 
    setTimeValue(newTime)
  }

  const selectMethod = (method) => {
    setVisitMethodName(method.label)
    if (method.label === "Email") {
      let obj = {};
      obj['value'] = 280;
      obj['label'] = "Email";
      setVisitMethod(obj)
    }
    else if (method.label === "Home") {
      let obj = {};
      obj['value'] = 281;
      obj['label'] = "Home";
      setVisitMethod(obj)
    }
    else if (method.label === "Link") {
      let obj = {};
      obj['value'] = 282;
      obj['label'] = "Link";
      setVisitMethod(obj)
    }
    else if (method.label === "Phone") {
      let obj = {};
      obj['value'] = 279;
      obj['label'] = "Phone";
      setVisitMethod(obj)
    } else if (method.label === "Park") {
      let obj = {};
      obj['value'] = 4984;
      obj['label'] = "Park";
      setVisitMethod(obj)
    } else if (method.label === "WFDC Office") {
      let obj = {};
      obj['value'] = 4983;
      obj['label'] = "WFDC Office";
      setVisitMethod(obj)
    } else if (method.label === "Other Location") {
      let obj = {};
      obj['value'] = 283;
      obj['label'] = "Other Location";
      setVisitMethod(obj)
    }
  }

  const addFileHandler = (event) => {
    setAddFile(event.target.files[0].name);
  }

  const addFileResourcesHandler = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let imagesArray = resourcesPath;
      if (event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && event.target.files[0].type !== 'application/msword') {
        toast.error('Invalid File Type. File can only be of the following types : pdf, doc, docx', {
          position: 'top-center'
        });
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error('File size exceeds the limit of 5MB', {
            position: 'top-center'
          });
          return false;
        }
        else {
          setUpdatedResources(true)
          setAddImageName(event.target.files[0].name)
          var reader = new FileReader();
          reader.onload = function (e) {
            imagesArray[index].name = event.target.files[0].name
            imagesArray[index].url = e.target.result
            uploadNewFile(imagesArray, index)
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    }
  }

  async function uploadNewFile(file, index) {

    try {
      let response = await uploadImage(file[index].name, file[index].url);
      if (response && response.data) {
        let fid = response.data.data.fid[0].value
        file[index].id = fid
        file[index].name = response.data.data.filename[0].value
        setResourcesPath([...file])
        resourcesId[index] = { "target_id": fid }
        setResourcesId(resourcesId)
      }
    } catch (error) {
      // console.log({ error });
    }

  }

  const onChangeTitleHandler = (event) => {
    setTitle(event.target.value);
  }
  const onChangeVisitNotesHandler = (event) => {
    setNotes(event.target.value);
  }

  const onChangeVenueHandler = (event) => {
    setVenue(event.target.value);
  }

  const onChangeNotesHandler = (event) => {
    setVisitNotes(event.target.value);
  }

  const onChangeVideoHandler = (event, index) => {
    let newArray = multipleVideoUrls
    newArray[index].value = event.target.value
    setMultipleVideoUrls([...newArray]);
  }

  useEffect(() => {

    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

    async function getInitialData() {
      let sortedEducatorsList = await getAllEducators();
      setEducatorsList(sortedEducatorsList.data.data);
      let opt_array = [];
      if (sortedEducatorsList.data.data.length > 0) {
        sortedEducatorsList.data.data.map((edu, index) => {
          const obj = {};
          obj['value'] = edu.uid;
          obj['label'] = edu.name;
          opt_array.push(obj);
        })
        setOptions(opt_array);
      }

      // Get visit Details
      let visit_id = props.match.params.id
      if (visit_id !== '') {
        let responseData = await NodeDetails(visit_id)

        // 
        if (responseData.status === 200) {
          if (responseData.data.data.title !== undefined && responseData.data.data.title !== '') {
            let taskTitle = responseData.data.data.title[0] ? responseData.data.data.title[0].value : ""
            setTitle(taskTitle)
          }
        }

        if (responseData.data.data.field_agenda !== undefined && responseData.data.data.field_agenda !== '') {
          let agenda = responseData.data.data.field_agenda[0] && responseData.data.data.field_agenda[0].value ? responseData.data.data.field_agenda[0].value.replace(regex, '') : ""
          setVisitNotes(agenda)
        }

        if (responseData.data.data.field_embed_video !== undefined) {

          if (responseData.data.data.field_embed_video.length > 0) {
            setMultipleVideoUrls(responseData.data.data.field_embed_video)
          }
          else {
            let newArray = []
            newArray.push({ value: "" })
            setMultipleVideoUrls(newArray)
          }

        }

        if (responseData.data.data.field_file !== undefined) {
          if (responseData.data.data.field_file.length > 0) {
            let filesArray = []
            let newArray = []
            responseData.data.data.field_file.map((data) => {
              newArray.push({ "target_id": data.target_id })
              setResourcesId(newArray)
              let fileName = data.url.substring(data.url.lastIndexOf('/') + 1)
              let obj = { name: fileName, id: data.target_id, url: data.url }
              filesArray.push(obj)
              setResourcesPath(filesArray)
            })
          }
          else {
            let newArray = []
            newArray.push({ name: "", id: "", url: "" })
            setResourcesPath(newArray)
          }

        }
        if (responseData.data.data.field_date !== undefined && responseData.data.data.field_date !== '') {
          let date = responseData.data.data.field_date[0] ? responseData.data.data.field_date[0].value : ""
          setMsDate(date)
        }
        if (responseData.data.data.field_status_visit !== undefined && responseData.data.data.field_status_visit !== '') {
          let data = responseData.data.data.field_status_visit[0] ? responseData.data.data.field_status_visit[0].value : ""
          setTaskTodo(data)
        }
        if (responseData.data.data.field_visit_method !== undefined && responseData.data.data.field_visit_method !== '') {
          let visits = responseData.data.data.field_visit_method[0] ? responseData.data.data.field_visit_method[0].target_id : ""
          if (visits !== "") {
            if (visits === 280) {
              let obj = {};
              obj['value'] = 280;
              obj['label'] = "Email";
              setVisitMethod(obj)
              setVisitMethodName("Email")
            }
            else if (visits === 281) {
              let obj = {};
              obj['value'] = 281;
              obj['label'] = "Home";
              setVisitMethod(obj)
              setVisitMethodName("Home")
            }
            else if (visits === 282) {
              let obj = {};
              obj['value'] = 282;
              obj['label'] = "Link";
              setVisitMethod(obj)
              setVisitMethodName("Link")
            }
            else if (visits === 279) {
              let obj = {};
              obj['value'] = 279;
              obj['label'] = "Phone";
              setVisitMethod(obj)
              setVisitMethodName("Phone")
            } else if (visits === 4984) {
              let obj = {};
              obj['value'] = 4984;
              obj['label'] = "Park";
              setVisitMethod(obj)
              setVisitMethodName("Park")
            } else if (visits === 4983) {
              let obj = {};
              obj['value'] = 4983;
              obj['label'] = "WFDC Office";
              setVisitMethod(obj)
              setVisitMethodName("WFDC Office")
            } else if (visits === 283) {
              let obj = {};
              obj['value'] = 283;
              obj['label'] = "Other Location";
              setVisitMethod(obj)
              setVisitMethodName("Other Location")
            }
          }
        }
        if (responseData.data.data.field_time !== undefined && responseData.data.data.field_time !== '') {
          let time = responseData.data.data.field_time[0] ? responseData.data.data.field_time[0].value : ""
          // 
          setTimeValue(convertTimeStampToTimeCustom(time))
        }

        if (responseData.data.data.field_visitor !== undefined && responseData.data.data.field_visitor !== '') {
          let target_id = responseData.data.data.field_visitor[0] ? responseData.data.data.field_visitor[0].target_id : 0;

          let res = await getSpecificUser(target_id, window.localStorage.getItem('auth_token'));
          if (res) {
            if (res.data !== undefined && res.data !== '') {
              if (res.data.data.name !== undefined && res.data.data.name !== "") {
                let name = res.data.data.name[0] ? res.data.data.name[0].value : '';
                let obj = {};
                obj['value'] = responseData.data.data.field_visitor[0].target_id;
                obj['label'] = name;
                setEducatorName(obj);
              }
            }
          }
        }

        if (responseData.data.data.field_headoffice !== undefined && responseData.data.data.field_headoffice !== '') {

          let target_id = responseData.data.data.field_headoffice[0] ? responseData.data.data.field_headoffice[0].target_id : null
          let res = await getSpecificUser(target_id, window.localStorage.getItem('auth_token'));
          if (res) {

            if (res.data !== undefined && res.data !== '') {
              if (res.data.data.name !== undefined && res.data.data.name !== "") {
                let name = res.data.data.name[0] ? res.data.data.name[0].value : '';
                let obj = {};
                obj['value'] = responseData.data.data.field_headoffice[0].target_id;
                obj['label'] = name;
                // 
                setCordinator(obj);
              }
            }
          }
        }

        if (responseData.data.data.field_venue !== undefined && responseData.data.data.field_venue !== '') {
          let location = responseData.data.data.field_venue[0] ? responseData.data.data.field_venue[0].value : ''
          // 
          setVenue(location)
        }

        if (responseData.data.data.field_notes !== undefined && responseData.data.data.field_notes !== '') {
          let notes = responseData.data.data.field_notes[0] && responseData.data.data.field_notes[0].value ? responseData.data.data.field_notes[0].value.replace(regex, '') : ""
          setNotes(notes)
        }

        if (responseData.data.data.field_user_notes !== undefined && responseData.data.data.field_user_notes !== '') {
          let notes = responseData.data.data.field_user_notes[0] && responseData.data.data.field_user_notes[0].value ? responseData.data.data.field_user_notes[0].value.replace(regex, '') : ""
          setEduNotes(notes)
        }
        
        if (responseData.data.data.field_educator_signature !== undefined && responseData.data.data.field_educator_signature !== '') {

          let signatureEdu = responseData.data.data.field_educator_signature[0] && responseData.data.data.field_educator_signature[0].url ? responseData.data.data.field_educator_signature[0].url : ""
          setEducatorSignatureVisit(responseData?.data?.data?.field_educator_signature[0])
          // console.log("eduuuu",responseData?.data?.data?.field_educator_signature[0]);
        }
        if (responseData.data.data.field_coordinator_signature !== undefined && responseData.data.data.field_coordinator_signature !== '') {
          let signatureCord = responseData.data.data.field_coordinator_signature[0] && responseData.data.data.field_coordinator_signature[0].url ? responseData.data.data.field_coordinator_signature[0].url : ""
          setCoordinatorSignatureVisit(responseData?.data?.data?.field_coordinator_signature[0])

        }

        if (responseData.data.data.field_image !== "" && responseData.data.data.field_image !== undefined) {
          let _allImagesArray = []
          if (responseData.data.data.field_image.length > 0) {
            let newArray = []
            responseData.data.data.field_image.map(async (imageData) => {
              newArray.push({ "target_id": imageData.target_id })
              setImagesId(newArray)
              let imageName = imageData.url.substring(imageData.url.lastIndexOf('/') + 1)
              await _allImagesArray.push({ "name": imageName, "path": imageData.url, 'id': imageData.target_id, updated: false })
              await setUploadImagePath(_allImagesArray)
            })
          }
        }
      }
    }
    getInitialData()
    get_cordinator();

  }, []);



  const uploadImageHandler = (event, field) => {
    if (event.target.files && event.target.files[0]) {
      if (Object.keys(event.target.files).length <= 7) {
        let imagesArray = []
        let updatedImagesArray = []
        imagesArray = uploadImagePath
        Object.values(event.target.files).map(function (filesData, index) {
          let name = filesData.name;
          if (field === "image") {

            if (filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png') {
              toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
                position: 'top-center'
              });
              return false;
            } else {
              if (filesData.size > 5000000) {
                toast.error('Image size exceeds the limit of 5MB', {
                  position: 'top-center'
                });
                return false;
              } else {
                setUpdatedImage(true)
                var reader = new FileReader();
                reader.onload = function (event) {
                  imagesArray.push({ 'name': name, 'path': event.target.result, 'id': '', updated: true })
                  updatedImagesArray.push({ 'name': name, 'path': event.target.result, 'id': '' })
                  setUploadImagePath([...imagesArray])
                  setUpdatedImagePath([...updatedImagesArray])
                };
                reader.readAsDataURL(filesData);
              }
            }
          }
        })
      }
      else {
        toast.error("You can't upload more than 7 images", { position: 'top-center' });
      }

    }
  }


  const mentoringSupportSubmission = async (event) => {
    event.preventDefault();
    setAjaxLoader(true);
    let name = title;
    let educator_id = educatorName.value ? educatorName.value : "";
    let method = visitMethod.value;
    let date = moment(msDate).format('YYYY-MM-DD')
    let location = venue;
    let agenda = visitNotes;
    let cordinator_id = cordinator.value ? cordinator.value : 0;
    let totalTimeStamp = null;

    let NewData = {
      notes: notes ? notes : null,
      educatorSignatureVisit: educatorSignatureId ? educatorSignatureId : null,
      coordinatorSignatureVisit: coordinatorSignatureId ? coordinatorSignatureId : null,
    }

    let urlsArray = []

    // console.log({ NewData });
    // return;
    //------------------------------------- VIDEO URL ----------------------------------------------
    if (multipleVideoUrls.length > 0) {
      multipleVideoUrls.map((data) => {
        if (data.value !== "") {
          urlsArray.push({ "value": data.value })
        }
      })
    }
    //-------------------------------------  FOR TITLE ----------------------------------------------
    if (title === undefined || title === '') {
      toast.error('Title is required', {
        position: 'top-center'
      });
      setAjaxLoader(false);
      return false;
    }
    //------------------------------------- VISIT METHOD (PHONE, STATUS) ----------------------------------------------
    if (visitMethod === null || visitMethod === '') {
      toast.error('Please choose visit method', {
        position: 'top-center'
      });
      setAjaxLoader(false);
      return false;
    }
    //------------------------------------- FOR DATE ----------------------------------------------
    if (msDate === undefined || msDate === '') {
      toast.error('Date is required', {
        position: 'top-center'
      });
      setAjaxLoader(false);
      return false;
    }

    setBlockSubmit(false)

    const getDatesBetweenDates = (startDate, endDate) => {
      let dates = []
      //to avoid modifying the original date
      const theDate = new Date(startDate)
      while (theDate < endDate || theDate.valueOf() === endDate.valueOf()) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
      }
      return dates
    }

    let checkLeaveResponse = {};
    if (educator_id !== '' && date !== '') {
      let selected_date = new Date(date).setHours(0, 0, 0, 0)
      let leaves = await getLeaves(educator_id);
      if (leaves.status === 200) {
        if (leaves.data.data.length > 0) {
          setLeavesArray(leaves.data.data)
          for (let mydata of leaves.data.data) {
            for (let rangeDate of getDatesBetweenDates(new Date(mydata.start_date), new Date(mydata.end_date))) {
              if (selected_date.valueOf() === rangeDate.valueOf()) {
                checkLeaveResponse['response'] = date
                checkLeaveResponse['success'] = false
              }
            }
          }

        }
      }
    }
    if (checkLeaveResponse['response'] !== undefined) {
      setShowAvailabilityModal(true)
      setAjaxLoader(false);
      setBlockSubmit(false)
      return false;
    }

    if (timeValue !== "") {
      let timeArray = timeValue.split(':')
      let hours = timeArray[0]
      let minutes = timeArray[1]
      totalTimeStamp = moment.duration(`${timeValue}:00`).asSeconds()
      // console.log("totalTimeStamp",totalTimeStamp);
    }

    if (updatedImage === false && updatedResources === false) {

      let data = { video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, educatorSignatureVisit: educatorSignatureId, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
      data = Object.assign({}, data, NewData)
      let res = await updateVisitDetails(props.match.params.id, data);
      if (res.status == 200) {
        localStorage.removeItem('fileFidMandS');
        history.push('/m&s');
      } else {
        toast.error(res.message, {
          position: 'top-center'
        });
        setAjaxLoader(false);
      }
    }
    else if (updatedImage === true && updatedResources === false) {
      let allImagesArray = []
      if (updatedImagePath.length > 0) {
        await Promise.all(uploadImagePath.map(async (data) => {
          if (data.updated) {
            let uploadImageResponse = await uploadImage(data.name, data.path)
            if (uploadImageResponse.status === 201) {
              let imageFid = uploadImageResponse.data.data.fid[0].value
              allImagesArray.push({ "target_id": imageFid })
            } else {
              setAjaxLoader(false);
              toast.error(uploadImageResponse.message, {
                position: 'top-center'
              });
            }
          }
        })).then(async () => {
          const filteredPaths = uploadImagePath.filter(path => path.updated === true);
          if (allImagesArray.length === filteredPaths.length) {
            await Promise.all(imagesId.map(async (newImagesData) =>
              allImagesArray.push(newImagesData)
            )).then(async () => {

              if (allImagesArray.length === uploadImagePath.length) {
                let data = { imageFid: allImagesArray, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
                data = Object.assign({}, data, NewData)
                let res = await updateVisitDetails(props.match.params.id, data);
                if (res.status == 200) {

                  history.push('/m&s');
                  window.localStorage.removeItem('fileFidMandS');
                } else {
                  setAjaxLoader(false);
                  toast.error(res.message, {
                    position: 'top-center'
                  });
                }
              }
            })
          }
        })
      }
      else {
        if (imagesId.length === uploadImagePath.length) {
          let data = { imageFid: imagesId, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
          // alert("3")
          data = Object.assign({}, data, NewData)
          let res = await updateVisitDetails(props.match.params.id, data);
          if (res.status == 200) {
            // setAjaxLoader(false);
            history.push('/m&s');
            window.localStorage.removeItem('fileFidMandS');
          } else {
            setAjaxLoader(false);
            toast.error(res.message, {
              position: 'top-center'
            });
          }
        }
      }
    }
    else if (updatedImage === false && updatedResources === true) {
      // alert(true)
      let allResourcesArray = []
      resourcesPath.map(async (data, index) => {
        let updatedIndex = index + 1
        // console.log("(data.id", data.id);
        if (data.id === "")   // adding new images with previous images
        {
          let response = await uploadImage(data.name, data.url);
          if (response.status === 201) {
            let fid = response.data.data.fid[0].value;
            allResourcesArray.push({ "target_id": fid })
            let allFilesArray = allResourcesArray.concat(resourcesId)
            if (resourcesPath.length === updatedIndex) {
              let data = { video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: allFilesArray, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
              // 
              data = Object.assign({}, data, NewData)
              // alert("4")
              let res = await updateVisitDetails(props.match.params.id, data);
              if (res.status == 200) {
                history.push('/m&s');
              } else {
                setAjaxLoader(false);
                toast.error(res.message, {
                  position: 'top-center'
                });
              }
            }

          } else {
            setAjaxLoader(false);
            toast.error(response.message, {
              position: 'top-center'
            });
          }
        }
        else {

          let data = { video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: resourcesId, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
          // console.log("data", data);
          // alert("5")
          data = Object.assign({}, data, NewData)
          let res = await updateVisitDetails(props.match.params.id, data);

          if (res.status == 200) {
            history.push('/m&s');
          } else {
            toast.error(res.message, {
              position: 'top-center'
            });
            setAjaxLoader(false);
          }
        }

      })
    }
    else {
      let allImagesArray = []
      if (updatedImagePath.length > 0) {
        await Promise.all(uploadImagePath.map(async (data) => {
          if (data.updated) {
            let uploadImageResponse = await uploadImage(data.name, data.path)
            if (uploadImageResponse.status === 201) {
              let imageFid = uploadImageResponse.data.data.fid[0].value
              allImagesArray.push({ "target_id": imageFid })
            } else {
              setAjaxLoader(false);
              toast.error(uploadImageResponse.message, {
                position: 'top-center'
              });
            }
          }
        })).then(async () => {
          const filteredPaths = uploadImagePath.filter(path => path.updated === true);
          if (allImagesArray.length === filteredPaths.length) {
            await Promise.all(imagesId.map(async (newImagesData) =>
              allImagesArray.push(newImagesData)
            )).then(async () => {
              if (allImagesArray.length === uploadImagePath.length) {
                let allResourcesArray = []
                const filteredPaths = resourcesPath.filter(path => path.id === '');
                if (filteredPaths.length != 0) {
                  await Promise.all(resourcesPath.map(async (data, index) => {
                    // let updatedIndex = index + 1
                    if (data.id === "")   // adding new images with previous images
                    {
                      let response = await uploadImage(data.name, data.url);
                      if (response.status === 201) {
                        let fid = response.data.data.fid[0].value;
                        allResourcesArray.push({ "target_id": fid })

                      } else {
                        toast.error(response.message, {
                          position: 'top-center'
                        });
                        setAjaxLoader(false);
                      }
                    }

                  }).then(async () => {
                    if (allResourcesArray.length === filteredPaths.length) {
                      await Promise.all(resourcesId.map(async (newImagesData) =>
                        allResourcesArray.push(newImagesData)
                      )).then(async () => {
                        if (resourcesPath.length === allResourcesArray.length) {
                          let data = { imageFid: allImagesArray, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: allResourcesArray, field_headoffice: cordinator_id, taskTodo: taskTodo, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
                          data = Object.assign({}, data, NewData)
                          let res = await updateVisitDetails(props.match.params.id, data);
                          if (res.status == 200) {
                            history.push('/m&s');
                          } else {
                            toast.error(res.message, {
                              position: 'top-center'
                            });
                            setAjaxLoader(false);
                          }
                        }
                      })
                    }
                  })
                  )
                } else {
                  let data = {
                    imageFid: allImagesArray,
                    video: urlsArray,
                    title: name,
                    educator_id: educator_id,
                    method: method,
                    date: date,
                    time: totalTimeStamp,
                    location: location,
                    agenda: agenda,
                    fid: resourcesId,
                    field_headoffice: cordinator_id,
                    taskTodo: taskTodo,
                    field_educator_signature: educatorSignatureVisit?.target_id,
                    field_coordinator_signature: coordinatorSignatureVisit?.target_id
                  };
                  data = Object.assign({}, data, NewData)
                  let res = await updateVisitDetails(props.match.params.id, data);
                  if (res.status == 200) {
                    history.push('/m&s');
                  } else {
                    toast.error(res.message, {
                      position: 'top-center'
                    });
                    setAjaxLoader(false);
                  }
                }
              }
              //   let data = { imageFid: allImagesArray, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
              //   data = Object.assign({}, data, NewData)
              //   let res = await updateVisitDetails(props.match.params.id, data);
              //   if (res.status == 200) {

              //     history.push('/m&s');
              //     window.localStorage.removeItem('fileFidMandS');
              //   } else {
              //     setAjaxLoader(false);
              //     toast.error(res.message, {
              //       position: 'top-center'
              //     });
              //   }
              // }
            })
          }
        })
      }
      // if (updatedImagePath.length > 0) {
      // 	updatedImagePath.map(async (data) => {
      // 		let uploadImageResponse = await uploadImage(data.name, data.path)
      // 		if (uploadImageResponse.status === 201) {
      // 			let imageFid = uploadImageResponse.data.data.fid[0].value
      // 			allImagesArray.push({ "target_id": imageFid })
      // 			if (allImagesArray.length === updatedImagePath.length) {
      // 				imagesId.map(async (newImagesData) =>
      // 					await allImagesArray.push(newImagesData)
      // 				)

      // 				if (allImagesArray.length === uploadImagePath.length) {
      // 					let data = { imageFid: allImagesArray, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, field_headoffice: cordinator_id, taskTodo: taskTodo, notes: notes, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
      // 					data = Object.assign({}, data, NewData)
      // 					let res = await updateVisitDetails(props.match.params.id, data);
      // 					if (res.status == 200) {
      // 						// setAjaxLoader(false);
      // 						// history.push('/m&s');
      // 						// window.localStorage.removeItem('fileFidMandS');
      // 					} else {
      // 						toast.error(res.message, {
      // 							position: 'top-center'
      // 						});
      // 						setAjaxLoader(false);
      // 					}
      // 					let allResourcesArray = []
      // 					resourcesPath.map(async (data, index) => {
      // 						let updatedIndex = index + 1
      // 						if (data.id === "")   // adding new images with previous images
      // 						{
      // 							let response = await uploadImage(data.name, data.url);
      // 							if (response.status === 201) {
      // 								let fid = response.data.data.fid[0].value;
      // 								allResourcesArray.push({ "target_id": fid })
      // 								let allFilesArray = allResourcesArray.concat(resourcesId)
      // 								if (resourcesPath.length === updatedIndex) {
      // 									let data = { imageFid: allImagesArray, video: urlsArray, title: name, educator_id: educator_id, method: method, date: date, time: totalTimeStamp, location: location, agenda: agenda, fid: allFilesArray, field_headoffice: cordinator_id, taskTodo: taskTodo, field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id };
      // 									//
      // 									data = Object.assign({}, data, NewData)

      // 									let res = await updateVisitDetails(props.match.params.id, data);
      // 									if (res.status == 200) {
      // 										history.push('/m&s');
      // 									} else {
      // 										toast.error(res.message, {
      // 											position: 'top-center'
      // 										});
      // 										setAjaxLoader(false);
      // 									}
      // 								}

      // 							} else {
      // 								toast.error(response.message, {
      // 									position: 'top-center'
      // 								});
      // 								setAjaxLoader(false);
      // 							}
      // 						}
      // 						else {
      // 							let data = {
      // 								video: urlsArray,
      // 								title: name,
      // 								educator_id: educator_id, method: method,
      // 								date: date, time: totalTimeStamp, location: location,
      // 								agenda: agenda, fid: resourcesId,
      // 								field_headoffice: cordinator_id,
      // 								taskTodo: taskTodo,
      // 								field_educator_signature: educatorSignatureVisit?.target_id, field_coordinator_signature: coordinatorSignatureVisit?.target_id
      // 							};
      // 							data = Object.assign({}, data, NewData)
      // 							// alert("7")
      // 							// 
      // 							let res = await updateVisitDetails(props.match.params.id, data);

      // 							// if(res.status == 200){
      // 							// 	history.push('/m&s');
      // 							// }else{
      // 							// 	toast.error(res.message,{
      // 							// 		position: 'top-center'
      // 							// 	});
      // 							// 	setAjaxLoader(false);
      // 							// }
      // 						}

      // 					})
      // 				}

      // 			}
      // 		}
      // 		else {
      // 			toast.error(uploadImageResponse.message, {
      // 				position: 'top-center'
      // 			});
      // 		}
      // 		setAjaxLoader(false);
      // 		history.goBack()
      // 	}
      // 	)
      // }
      setAjaxLoader(false);
    }
  }

  const removeSpecificImage = (index, selectedImageId) => {
    let imagesArray = uploadImagePath
    imagesArray.splice(index, 1)
    setUploadImagePath([...imagesArray])
    setUpdatedImage(true)

    let isExist = Object.values(imagesId).some(function (data) {
      return data.target_id === selectedImageId;
    });

    if (isExist === true) {
      let idsArray = imagesId
      idsArray.splice(index, 1)
      setImagesId([...idsArray])
    }

  }

  const addMoreUrls = () => {
    let newArray = multipleVideoUrls
    newArray.push({ value: "" })
    setMultipleVideoUrls([...newArray])
  }

  const addMoreResources = () => {
    let newArray = resourcesPath
    newArray.push({ name: '', url: '', id: '' })
    setResourcesPath([...newArray])
  }

  const removeSpecificResource = (index, specificResourceId) => {
    let newArray = resourcesPath
    newArray.splice(index, 1)
    setResourcesPath([...newArray])
    setUpdatedResources(true)

    // check whether remove id exist in the Resource Ids array
    let isExist = Object.values(resourcesId).some(function (data) {
      return data.target_id === specificResourceId;
    });

    if (isExist === true) {
      let idsArray = resourcesId
      idsArray.splice(index, 1)
      setResourcesId([...idsArray])
    }

  }
  let signPad = {}
  const removeSpecificCordSign = () => {
    setCoordinatorSignatureVisit('')
    // sigCordPad.clear()
    let checkSign = Object.getOwnPropertyNames(signPad)
    if (checkSign.length > 0) {
      signPad.clear()
    }
  }

  function removeEducatorSign() {
    setEducatorSignatureVisit('')
    try {
      editSigPad.clear()
    } catch (error) {

    }
  }

  function updateEducatorSign() {
    let trimmedDataURL = editSigPad.getTrimmedCanvas().toDataURL('image/png');
    if (trimmedDataURL) {
      signatureUploadOnEnd(trimmedDataURL, true)
    }
  }

  function removeCoordinatorSign() {
    setCoordinatorSignatureVisit('')
    try {
      editCoordinatorPad.clear()
    } catch (error) {

    }
  }

  function updateCoordinatorSign() {
    let trimmedDataURL = editCoordinatorPad.getTrimmedCanvas().toDataURL('image/png');
    if (trimmedDataURL) {
      signatureUploadOnEnd(trimmedDataURL)
    }
  }

  async function signatureUploadOnEnd(signImage, edu = false) {
    try {
      const signatureUploadResponse = await uploadImage("signature.png", signImage)
      if (signatureUploadResponse.status === 201) {
        let signFid = signatureUploadResponse.data.data.fid[0].value;
        console.log("fid", signFid);
        if (signFid) {
          if (edu) {
            setEducatorSignatureId(signFid)
          } else {
            setCoordinatorSignatureId(signFid)
          }
        }
      }
    } catch (error) {

    }
  }

  // useEffect(() => {
  // 	console.log('--==', coordinatorSignatureVisit);
  // }, [coordinatorSignatureVisit])

  const removeSpecificUrls = (index) => {
    let newArray = multipleVideoUrls
    newArray.splice(index, 1)
    setMultipleVideoUrls([...newArray])
  }

  return (
    <>
      <ToastContainer />
      <HeaderOfficeMainHeader />
      <LoaderComponent displayLoader={ajaxLoader} />
      <div className="main-wrapper">
        <div className="container">
          <div className="user-pages-wrap">
            <div className="row">
              <div className="col-lg-12">

                <div className="user-types-wrap course-detail-page text-center">
                  <div className="row">
                    <div className="common-header-row common-header-with-large-text col-md-8">
                      <h4>Edit Visit : {title}</h4>
                    </div>
                    {/* <div className="common-header-row common-header-with-large-text">
											<button className="mdc-button mdc-button--raised" onClick={() => setOpen(true)}>
												<span className="mdc-button__label">ADD NOTES</span>
											</button>
										</div> */}
                  </div>
                  <form onSubmit={mentoringSupportSubmission} className="sign-up-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <input maxlength="20" value={title} onChange={onChangeTitleHandler} type="text" className={title !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Title</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <Select className="search-select-field" placeholder="Select Educator" style={{ width: 100 }} value={educatorName} options={options} onChange={educatorsHandler} />
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <Select className="search-select-field"
                              placeholder="Assign Coordinator"
                              style={{ width: 100 }}
                              value={cordinator}
                              options={cordinator_option}
                              onChange={getCoordinator}
                            />
                          </div>


                          <div className="col-lg-2 col-md-6">
                            <div className="calendar-wrap">
                              <label className="mdc-text-field mdc-text-field--outlined" onClick={() => calendar()}>
                                <input type="text" readOnly className="mdc-text-field__input" aria-labelledby="my-label-id" style={{ cursor: "pointer" }} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{msDate ? moment(msDate).format('DD-MM-YYYY') : "Date"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                  <span className="material-icons">calendar_today</span>
                                </span>
                              </label>
                              <div>
                                {
                                  showCalendar === true ? <Calendar value={calendarValue} onChange={calendarHandler} /> : null
                                }
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-4 col-md-6">
														<TimePicker onChange={(timestamp,time)=>timeHandler(time)} placeholder={timeValue} defaultOpenValue={moment('00:00', 'HH:mm')} format='HH:mm' />
													</div> */}
                          <div className="col-lg-2 col-md-6 text-center justify-content-center">
                            <label className="mdc-text-field mdc-text-field--outlined">
                              <TimePicker

                                onChange={(timestamp, time) => timeHandler(time)}
                                defaultOpenValue={moment('00:00', 'HH:mm')}
                                placeholder={timeValue}
                                format='HH:mm'
                                bordered={false}
                                style={{ fontSize: 20, marginTop: 10, color: "#959595", width: "100%" }}
                              //placeholder=""
                              />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  {/* <span className="mdc-floating-label" id="my-label-id">Select Time</span> */}
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-6 custom-col">
                            <Select className="search-select-field" placeholder="Visit Method"
                              style={{ width: 100 }} value={visitMethod} options={methods}
                              onChange={selectMethod} />
                          </div>

                          {/* Task Todo List Starts  */}
                          <div className="select-field col-md-4">
                            <label className="mdc-text-field mdc-text-field--outlined  dropdown-toggle">
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{taskTodo ? taskTodo : "Task Status"}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">arrow_drop_down</span>
                            </label>
                            {

                            }
                            <ul className="dropdown">
                              <>
                                {

                                  taskMethods.map((item, index) =>
                                    <li onClick={() => setTaskTodo(item)} key={index}>{item}</li>

                                  )
                                }
                              </>
                            </ul>
                          </div>
                          {/* Task Todo List Ends  */}

                          {/* <div className="col-lg-4 col-md-6">
														<label className="mdc-text-field mdc-text-field--outlined">
															<input value={venue} onChange={onChangeVenueHandler} type="text" className={venue !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"/>
															<span className="mdc-notched-outline">
																<span className="mdc-notched-outline__leading"></span>
																<span className="mdc-notched-outline__notch">
																	<span className="mdc-floating-label" id="my-label-id">Address</span>
																</span>
																<span className="mdc-notched-outline__trailing"></span>
															</span>
														</label>
													</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="outlined-textfield-wrap mt-10">
                          <div className="material-textfield">
                            <textarea maxlength="200" value={visitNotes} onChange={onChangeNotesHandler} id="message" className={visitNotes !== '' ? 'form__field active' : 'form__field'} placeholder="" rows="6"></textarea>
                            <label>Agenda</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div className="col-md-12">
                        <div className="outlined-textfield-wrap mt-10">
                          <div className="material-textfield">
                            <textarea readOnly placeholder="" rows="6" value={eduNotes} className={eduNotes !== '' ? 'form__field active' : 'form__field'}></textarea>
                            <label>Educator notes</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div className="col-md-12">
                        <div className="outlined-textfield-wrap mt-10">
                          <div className="material-textfield">
                            <textarea placeholder="" rows="6" value={notes} onChange={(e) => { setNotes(e.target.value) }} className={notes !== '' ? 'form__field active' : 'form__field'}></textarea>
                            <label>Write notes here</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="meeting-notes" >
                      <h5 className="title18">Digital Signature (WFDC Representative)</h5>
                      <div className="signature-block">
                        {coordinatorSignatureVisit ?
                          <img src={coordinatorSignatureVisit?.url} width="200" height="200" />
                          :
                          <SignaturePad canvasProps={{ className: styles.sigPad }} onEnd={() => updateCoordinatorSign()} ref={(ref) => { editCoordinatorPad = ref }} />
                        }
                        {
                          coordinatorSignatureVisit || editSigPad ?
                            <button type="button" onClick={(e) => removeCoordinatorSign()}><span className="material-icons close">close</span></button>
                            : null
                        }
                      </div>
                    </div>

                    <div className="meeting-notes">
                      <h5 className="title18">Digital Signature (Educator)</h5>
                      <div className="signature-block">

                        {educatorSignatureVisit ?
                          <img src={educatorSignatureVisit?.url} width="200" height="200" />
                          :
                          <SignaturePad canvasProps={{ className: styles.sigPad }} onEnd={() => updateEducatorSign()} ref={(ref) => { editSigPad = ref }} />
                        }
                        {
                          educatorSignatureVisit || editSigPad ?
                            <button type="button" onClick={(e) => removeEducatorSign()}><span className="material-icons close">close</span></button>
                            : null
                        }

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-8 col-sm-8">
                        <label className="mdc-text-field mdc-text-field--outlined">
                          <input onChange={(e) => uploadImageHandler(e, "image")} multiple type="file" accept="image/jpg,image/jpeg,image/png" id="attach-file" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Upload Image</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                          </span>
                          <span className="material-icons">image</span>
                        </label>
                        <small className="short-description">Image can only be of the following types : jpg, jpeg, png with limit upto 5MB</small>
                        <>
                          {
                            uploadImagePath !== '' && uploadImagePath !== undefined ?
                              uploadImagePath.map((data, index) =>
                                <>
                                  <img height="100" width="100" src={data.path} />
                                  <button type="button" onClick={(e) => { removeSpecificImage(index, data.id) }} ><span className="material-icons close">close</span></button>
                                </>)
                              : null
                          }
                        </>




                      </div>
                    </div>
                    {
                      resourcesPath.length > 0 ?
                        resourcesPath.map((data, index) => {
                          let url = data.url
                          // console.log({data});
                          return (
                            <div className="row">
                              <div className="col-md-8 col-sm-8">
                                <label forhtml="add-video" className="mdc-text-field mdc-text-field--outlined">
                                  <input onChange={(e) => addFileResourcesHandler(e, index)} type="file" id="add-video" className={data.name ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" accept=".pdf,.doc,.docx" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{data.name ? data.name : "Add Resources"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">attach_file</span>
                                </label>
                                <small className="short-description">File can only be of the following types : pdf, doc, docx with limit upto 5MB</small>
                              </div>
                              {
                                index == 0 ?
                                  <div className="col-md-4 col-sm-4">
                                    <button type="button" className="mdc-button mdc-button--raised mt-5" onClick={addMoreResources} >
                                      <span className="mdc-button__label button">Add More Resources</span>
                                    </button></div>

                                  : null}
                              {
                                index > 0 ?
                                  <button className="close-btn resource-button cross-btn-create-visit" type="button" onClick={(e) => { removeSpecificResource(index, data.id) }}><span className="material-icons close">close</span></button>
                                  :
                                  null
                              }
                            </div>

                          )
                        })
                        :
                        null
                    }

                    {
                      multipleVideoUrls.length > 0 ?
                        multipleVideoUrls.map((data, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-8 col-sm-8">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={data.value} onChange={(e) => onChangeVideoHandler(e, index)} type="text" className={data.value !== '' ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Add Youtube/Vimeo Video Url</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">videocam</span>
                                </label>
                              </div>

                              {
                                index == 0 ?
                                  <div className="col-md-4 col-sm-4">
                                    <button type="button" className="mdc-button mdc-button--raised mt-5" onClick={addMoreUrls}>
                                      <span className="mdc-button__label button-style-visit">Add More Urls</span>
                                    </button></div>

                                  : null}
                              {
                                index > 0 ?
                                  <button className="close-btn resource-button cross-btn-create-visit" type="button" onClick={(e) => { removeSpecificUrls(index) }}><span className="material-icons close">close</span></button>
                                  :
                                  null
                              }
                            </div>
                          )
                        })
                        :
                        null
                    }



                    <div className="row">
                      <button type='submit' disabled={blockSubmit} className="mdc-button mdc-button--raised mt-5">
                        <span className="mdc-button__label">Submit</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showAvailabilityModal} onClose={() => setShowAvailabilityModal(false)} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">User is not available on following dates-</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No.</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  leavesArray.length > 0 ? leavesArray.map((items, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell><Moment format="DD-MM-YYYY">{items.start_date}</Moment></TableCell>
                      <TableCell><Moment format="DD-MM-YYYY">{items.end_date}</Moment></TableCell>
                    </TableRow>

                  )) : null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAvailabilityModal(false)} color="primary" autoFocus>OK</Button>
        </DialogActions>
      </Dialog>



      {/*  Meeting Notes Diaog */}

      {/* <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Meeting Notes</DialogTitle>
				<DialogContent>
					<div className="col-md-12">
						<div className="outlined-textfield-wrap mt-10">
							<form className="form-meeting-notes">
								<div className="row">
									<div className="col-md-12">
										<div className="outlined-textfield-wrap mt-10">
											<div className="material-textfield">
												<textarea onChange={(e) => { setMeetingNotes(e.target.value) }} className={meetingNotes ? 'form__field active' : 'form__field'} placeholder="" rows="6"
												></textarea>
												<label>Write notes here</label>
											</div>
										</div>
									</div>
								</div>
								<div className="meeting-notes">
								</div>

								{signatureImage ? (
									<div className="meeting-notes">
										<h5 className="title18">WFDC Representative</h5>
										<div className="signature-block">
											<img src={signatureImage} width="200" height="200" />
										</div>
									</div>
								) : (
									store.userRole === 2 ? (
										<div className="meeting-notes">
											<h5 className="title18">WFDC Representative</h5>
											<div className="signature-block">
												<SignaturePad canvasProps={{ className: styles.sigPad }} ref={(ref) => setSigPad(ref)} />
											</div>
										</div>
									) : (
										< div className="meeting-notes">
											<h5 className="title18">WFDC Representative</h5>
											<div className="signature-block">
												<img src={blankImage} width="200" height="100" />
											</div>
										</div>
									)
								)
								}
								{
									educatorSignature && educatorSignature ? (
										<div className="meeting-notes">
											<h5 className="title18">Digital Signature (Educator)</h5>
											<div className="signature-block">
												<img src={educatorSignature} width="200" height="200" />
											</div>
										</div>
									) : (
										store.userRole === 1 ? (
											<div className="meeting-notes">
												<h5 className="title18">Digital Signature (Educator)</h5>
												<div className="signature-block">
													<SignaturePad readOnly canvasProps={{ className: styles.sigPad }} ref={(ref) => setSigPad(ref)} />
												</div>
											</div>
										) : (
											< div className="meeting-notes">
												<h5 className="title18">Digital Signature (Educator)</h5>
												<div className="signature-block">
													<img src={blankImage} width="200" height="100" />
												</div>
											</div>
										)
									)
								}


							</form>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancelModal} color="primary">Cancel</Button>
					<Button onClick={submitMeetingNotes} color="primary" autoFocus>Submit</Button>
				</DialogActions>
			</Dialog> */}
    </>
  )
}

export default EditVisit;