import React, {useEffect} from 'react';
import './App.css';
import Routes from './router/routes';
import "../src/main.css";
import { setUserRole } from './redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { $ } from 'react-jquery-plugin';

const App=()=>{

  const dispatch = useDispatch()

  useEffect(()=>
  {
     $(document).ready(function() {
       let role = window.localStorage.getItem("user_role")
        if(role === "head_coordinator")
        {
          dispatch(setUserRole(2))
        }
        if(role === "educator")
        {
          dispatch(setUserRole(1))
        }
    });

  },[])


  return (
    <div>
      <Routes />
    </div>
  );
}
export default App;
