const initialState = {
    userRole: 1,
    authData: [],
    page: 1,
    authToken: "",
    loggedInUserDetail: []
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case "login":
        return {
          ...state, authData: action.payload
        };
      case "user_role":
        return {
          ...state, userRole:action.payload
        };
      case "logged_in_user_detail":
        return {
          ...state, loggedInUserDetail:action.payload
      };
      case "auth_token":
        return {
          ...state, authToken: action.payload
        };
      case "educator_signup_next":
      return {
        ...state, page:action.pageNumber
      };
      case "educator_signup_previous":
      return {
        ...state, page:action.pageNumber
      };
      case "USER_CREATED":
      return {
        ...state
      };
      default:
        return state;
    }
};

export default authReducer;