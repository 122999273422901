import { baseUrl } from '../../../config';
import { getXcsrfToken } from "../../../functions/api";
import moment from 'moment';

const axios = require('axios').default;

export function login(username, password) {

    let userCredentialObject = { mail: username, pass: password }

    return function(dispatch) {

        return axios.post(`${baseUrl}/ish/user/login?_format=json`, {
                mail: userCredentialObject.mail,
                pass: userCredentialObject.pass,
            })
            .then(async function(response) {
                if (response.status === 200) {
                    
                  window.localStorage.setItem('name', response.data.current_user.name);
                     window.localStorage.setItem('username', username);
                     window.localStorage.setItem('password', password);
                    window.localStorage.setItem('uid', await response.data.current_user.uid);
                    
                    window.localStorage.setItem('user_role', response.data.current_user.roles[1]);
                    window.localStorage.setItem('login_csrf_token', response.data.csrf_token);
                    window.localStorage.setItem('logout_token', response.data.logout_token);
                    dispatch({
                        type: 'login',
                        payload: response.data
                    })

                    return ({ status: response.status, data: response.data })
                } else {
                    return ({ status: response.status })
                }
            })
            .catch(function(error) {
                return ({ message: error.response.data.message })
            });
    }
}

export function getAuthToken(username, password) {

    let formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', '4098b7cf-e436-4216-8be5-e7fc4316344e');
    formData.append('client_secret', 'ish');
    formData.append('username', username);
    formData.append('password', password);

    return function(dispatch) {

        return axios.post(`${baseUrl}/oauth/token?_format=json`, formData, {

        }).then(async(response) => {
            if (response.status === 200) {
                let token = response.data.access_token
                await window.localStorage.setItem('auth_token', token)

                dispatch({
                    type: 'auth_token',
                    payload: token
                })
                return ({ status: response.status, data: token })
            } else {
                return ({ status: response.status })
            }
        }).catch((error) => { return ({ message: error.response.data.message }) })
    }
}


export function setUserRole(value) {
    return {
        type: 'user_role',
        payload: value
    }
}


export function registerUser(registerationData) {

    let basicDetails = registerationData.basicDetail
    let _dob = basicDetails.dob ? moment(basicDetails.dob,'DD-MM-YYYY').format('YYYY-MM-DD') : null

    let userRegisterDetailObject = {
        "field_first_name": [{ "value": basicDetails.firstName }],
        "field_last_name": [{ "value": basicDetails.lastName }],
        "name": [{ "value": basicDetails.name }],
        "mail": [{ "value": basicDetails.email }],
        "pass": [{ "value": basicDetails.password }],
        "field_d_o_b": [{ "value": _dob }],
        "field_contact_number": [{ "value": basicDetails.contact }],
        "field_app_role": [{ "value": basicDetails.role }],
    }

    return async function(dispatch) {
        let xcsrfToken = await getXcsrfToken()

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRF-Token': xcsrfToken
        }

        return axios.post(`${baseUrl}/user/register?_format=json`, userRegisterDetailObject, {
                headers: headers,
            })
            .then(async(response) => {
                if (response.status == 200) {
                    let data = { registerUserId: response.data.uid[0].value, registerUsername: basicDetails.name, registerUserPassword: basicDetails.password, registerUserEmail: basicDetails.email }
                    dispatch({
                        type: "register_detail",
                        payload: response.data,
                        status: true
                    })
                    return ({ status: response.status, data: response.data })
                } else {
                    return ({ status: response.status, message: response.message })
                }
            })
            .catch((error) => { return ({ message: error.response.data.message }) })
    };
}

// get current user data using uid
export function getLoggedInUserDetail(uid, authToken) {

    return function(dispatch) {

        const headers = {
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        }
        return axios.get(`${baseUrl}/user/${uid}?_format=json`, {
                headers: headers
            })
            .then(async(response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "logged_in_user_detail",
                        payload: response
                    })
                    return ({ status: response.status })
                } else {
                    return ({ status: response.status, message: response.message })
                }
            })
            .catch((error) => { return ({ message: error.response.data.message }) })
    };
}


export function createProfile(authToken, data) {

    return async function(dispatch) {

        let xcsrfToken = await getXcsrfToken()

        const headers = {
            'Authorization': 'Bearer ' + authToken,
            'X-CSRF-Token': xcsrfToken,
            'Content-Type': 'application/json',
        }

        return axios.post(`${baseUrl}/entity/profile?_format=json`, data, {
                headers: headers
            })
            .then(async(response) => {
                if (response.status === 201) {
                    dispatch({
                        type: "USER_CREATED"
                    })
                    return ({ status: response.status })
                } else {
                    return ({ status: response.status, message: response.message })
                }
            })
            .catch((error) => {
                return ({ message: error.response.data.message })
            })
    };
}

export function handleEducatorSignupForm(page, data, index) {
    if (data !== undefined) {
        if (window.localStorage.getItem('educatorSignupDetail') !== null && window.localStorage.getItem('educatorSignupDetail') !== undefined) {
            let getStorageValue = JSON.parse(window.localStorage.getItem('educatorSignupDetail'));
            if (getStorageValue[index] !== null && getStorageValue[index] !== undefined) {
                getStorageValue[index] = data
            } else {
                getStorageValue.push(data)
            }

            window.localStorage.setItem('educatorSignupDetail', JSON.stringify(getStorageValue));
        } else {
            let _signupArray = []
            _signupArray.push(data)
            window.localStorage.setItem('educatorSignupDetail', JSON.stringify(_signupArray));
        }

        return {
            type: 'educator_signup_next',
            pageNumber: page
        }

    } else {
        return {
            type: 'educator_signup_previous',
            pageNumber: page
        }
    }

}

export function handleHeadOfficeSignupForm(page, data, index) {
    if (data !== undefined) {
        if (window.localStorage.getItem('headOfficeSignupDetail') !== null && window.localStorage.getItem('headOfficeSignupDetail') !== undefined) {
            let getStorageValue = JSON.parse(window.localStorage.getItem('headOfficeSignupDetail'));
            if (getStorageValue[index] !== null && getStorageValue[index] !== undefined) {
                getStorageValue[index] = data
            } else {
                getStorageValue.push(data)
            }

            window.localStorage.setItem('headOfficeSignupDetail', JSON.stringify(getStorageValue));
        } else {
            let _signupArray = []
            _signupArray.push(data)
            window.localStorage.setItem('headOfficeSignupDetail', JSON.stringify(_signupArray));
        }

        return {
            type: 'educator_signup_next',
            pageNumber: page
        }

    } else {
        return {
            type: 'educator_signup_previous',
            pageNumber: page
        }
    }

}