import React,{ useState, useEffect } from 'react';
import { getAllEducators, searchEducators, sortEducators, searchEducatorsBySuburb,getSessionList ,getAllVenue,deleteFacilitorList} from "../../../functions/api";
import { $ } from 'react-jquery-plugin';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import EducatorsList from '../../../commonComponents/CommonEducatorsListComponent/EducatorsList';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ToastContainer, toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { baseUrl } from '../../../config';
import { Link, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import

//Import Images
import line from '../../../images/line.png';
import user1 from '../../../images/user1.png';



const FacilitorList=(props)=>{

    const [searchPlaceHolderValue,setSearchPlaceHolderValue] = useState('Type Educator name...');
    const [educatorsData,setEducatorsData] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [showLoader, setShowLoader] = useState(true)
	const [sortedValue, setSortedValue] = useState('ASC')
	const [venueList,setVenueList] = useState([]);
	const [sessionList,setSessionList] = useState([]);
	const [userRole, setUserRole] = useState("");
	const history = useHistory();

	const StyledMenu = withStyles({
  	paper: {
    	border: '1px solid #d3d4d5',
  	},
	})((props) => (
  	<Menu elevation={0} getContentAnchorEl={null}
    	anchorOrigin={{
      		vertical: 'bottom',
      		horizontal: 'center',
    	}}
    	transformOrigin={{
      		vertical: 'top',
      		horizontal: 'center',
    	}}
    	{...props}
  	/>
	));

	const StyledMenuItem = withStyles((theme) => ({
  	root: {
    	'&:focus': {
      		backgroundColor: theme.palette.primary.main,
      		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        		color: theme.palette.common.white,
      		},
    	},
  	},
	}))(MenuItem);

    useEffect(()=>{
		let role = window.localStorage.getItem('user_role');
        setUserRole(role);
		$(function(){
  			$('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function(){
    			if($(this).val().length > 0) {
      				$(this).addClass('active');
    			}else{
      				$(this).removeClass('active');
    			}
  			})
		})
        getFacilitorList()

    },[props,searchPlaceHolderValue])

	//all session list
	async function getFacilitorList(){
	let SessionLists= await getSessionList();
	

		if( SessionLists.status == 200)
		{
			setShowLoader(false)
			setSessionList(SessionLists.data.data);
		}
		else{
			setShowLoader(false)
		}

	}

	// delete sessiom list with id
	async function deleteSession(id){
        let response = await deleteFacilitorList(id);
        if(response.status === 204){
            // deleteItem(id)
            toast.success('Facilitator has been deleted successfully!', {
                position: 'top-center'
            });
			getFacilitorList();
        }else{
            toast.error(response.message, {
                position: 'top-center'
            });
        }
    }

	
    // function confirmDeleteVenue(id){
    //     setAnchorEl(null);
    //     confirmAlert({
    //         title: 'Are you sure you want to delete?',
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: () => deleteVenue(id)
    //             },
    //             {
    //                 label: 'No',
    //             }
    //         ]
    //     });
    // }

	async function search(inputValue) {
		let response = await searchEducators(inputValue)
		if(response.status === 200){
			if(response.data !== undefined && response.data !== ''){
				if(response.data.data.length > 0){
					setEducatorsData([...response.data.data]);
				}else{
					let _response = await searchEducatorsBySuburb(inputValue)
					if(_response.status === 200){
						if(_response.data !== undefined && _response.data !== ''){
							if(_response.data.data.length > 0){
								setEducatorsData([..._response.data.data]);
							}else{
								setEducatorsData([]);
							}
						}
					}else{
						toast.error(response.message,{
			                position: 'top-center'
			            });
					}
				}
			}
		}else{
			toast.error(response.message,{
                position: 'top-center'
            });
		}
	}

	const handleClick = (event) => {
   		setAnchorEl(event.currentTarget);
  	};

	 const handleClose = () => {
    	setAnchorEl(null);
  	};

	async function sorting(sort){
		if(sort === 'ASC'){
			setSortedValue('ASC')
		}
		if(sort === 'DESC'){
			setSortedValue('DESC')
		}
		setShowLoader(true)
		if(sort !== ''){
			let response = await sortEducators(sort)
			if(response.status === 200){
				setShowLoader(false)
				if(response.data.data.length > 0){
					setEducatorsData([...response.data.data]);
				}
			}
			else
			{
				setShowLoader(false)
			}
		}
	}

    return (
    	<>
		<ToastContainer />
		<LoaderComponent displayLoader={showLoader} />
    	<HeadOfficeMainHeader />
        <div className="main-wrapper mtb-70">
		    <div className="container">	
			    <div className="dashboard-wrap">
				    <div className="row">
						<div className="col-md-12">
							<div className="content-block-right">
									<div className="notice-listing-block">
										<div className="common-header-row">
											<h4 onClick={()=>console.log("hgh",sessionList)}>Facilitator List</h4>	
											<div className="block-right">
										<a href="javascript:void(0)" onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</a>
											</div>
											
										</div>
										<div className="task-listing task-list-with-media">	
										<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>facilitator Name</TableCell>
															<TableCell>facilitator document </TableCell>
															<TableCell>Action</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
											{
												sessionList && sessionList.length > 0 &&
												sessionList.map((v)=>(
													<TableRow className="col-md-12">
														<TableCell className="custom-title-link">{v.name}</TableCell>
														<TableCell>{v.field_attachment ? v.field_attachment.slice(29,70).replace(/%/g,'_') : "N/A"}</TableCell>
														<TableCell>
														{userRole == 'head_coordinator' ? 
															<Link className="bg-green action-btn-style" 
																to={`/add-session/${v.tid}`}
															>
																Edit
															</Link> : null}  
														{userRole == 'head_coordinator' ? 
															<button className="bg-red action-btn-style" 
																onClick={()=>deleteSession(v.tid)}>Delete</button> : null 
														}
													</TableCell>
													</TableRow>
												))
											}
											</TableBody>
											</Table>
										</TableContainer> 
										
										</div>
									</div>	 
							    </div>
						    </div>
                        </div>
			        </div>		
		        </div>	
	        </div>
	    </>
        );
};

export default FacilitorList;
