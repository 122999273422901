import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { toast } from 'react-toastify';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { changeEducatorStatus, educatorListArchive } from "../../functions/api";
import DummyProfileImage from '../../images/user2.png'
//Import Images
import { PaginatedList } from 'react-paginated-list';
import LoaderComponent from '../../commonComponents/LoaderComponent/LoaderComponent'

const EducatorsList = (props) => {
  const history = useHistory()
  const [allEducators, setAllEducators] = useState([])
  const [noDataLabel, setNoDataLabel] = useState(false)
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    window.localStorage.setItem("educatorInCoordinator", "true");
    getInitialData()
  }, [props])

  async function getInitialData() {
    if (props.data !== undefined && props.data.length > 0) {
      setNoDataLabel(false)
      setAllEducators(props.data)
    }else {
      if (props.data.length === 0) {
        setAllEducators([])
        setNoDataLabel(true)
      }

      if (props.data === undefined) {
        setNoDataLabel(false)
      }
    }
  }

  const navigateUser = (educatorUid) => {
    history.push(`/educators-profile/${educatorUid}`)
  }

  async function archiveEducator(profileID, uid) {
    setShowLoader(true)
    const data = {
      uid: uid,
      field_archive: true
    };

    const response = await educatorListArchive(profileID, data);
    await changeUserStatus(uid, 0)
    if (response.status === 200) {
      toast.success('Educator is archived successfully!', {
        position: 'top-center'
      });
      getInitialData()

      let newData = [...allEducators]
      let index = newData.findIndex(o => o.uid === uid);
      newData.splice(index, 1);

      setAllEducators(newData)

    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
    setShowLoader(false)
  }

  async function UnArchiveEducator(profileID, uid) {
    setShowLoader(true)
    const data = {
      uid: uid,
      field_archive: false
    };

    const response = await educatorListArchive(profileID, data);
    await changeUserStatus(uid, 1)
    if (response.status === 200) {
      toast.success('Educator is Unarchived successfully!', {
        position: 'top-center'
      });
      getInitialData()
      let newData = [...allEducators]
      let index = newData.findIndex(o => o.uid === uid);
      newData.splice(index, 1);
      setAllEducators(newData)
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
    setShowLoader(false)
  }

  const changeUserStatus = async (uid, status) => {
    try {

      await changeEducatorStatus(uid,status)
    } catch (error) {
      // console.log({ error });
    }
  }

  return (
    <>
      <LoaderComponent displayLoader={showLoader} />
      {allEducators.length > 0 ?
        <PaginatedList
          list={allEducators}
          itemsPerPage={10}
          renderList={(list) => (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profile</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Street Name</TableCell>
                    <TableCell>Suburb Name</TableCell>
                    {props.isArchive === false ? <TableCell>Actions</TableCell> : <TableCell>UnArchive</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    list.length > 0 ? list.map((educator, index) => {
                      if (props.data) {
                        let allDays = educator.field_operational_days.replace(/ /g, '').split(',')
                        let days = allDays.includes("All") || allDays.length === 7 ? "M/Tu/W/Th/F/Sa/Su" : ""
                        if (days === "") {
                          if (allDays.includes("Monday")) {
                            days = days.concat("M/")
                          }
                          if (allDays.includes("Tuesday")) {
                            days = days.concat("Tu/")
                          }
                          if (allDays.includes("Wednesday")) {
                            days = days.concat("W/")
                          }
                          if (allDays.includes("Thursday")) {
                            days = days.concat("Th/")
                          }
                          if (allDays.includes("Friday")) {
                            days = days.concat("F/")
                          }
                          if (allDays.includes("Saturday")) {
                            days = days.concat("Sa/")
                          }
                          if (allDays.includes("Sunday")) {
                            days = days.concat("Su/")
                          }
                          days = days.substring(0, days.length - 1);
                        }
                        return (
                          <TableRow key={index} className="col-md-12" style={{ cursor: 'pointer' }}>
                            <TableCell onClick={() => navigateUser(educator.uid)}><img height="100" width="100" src={educator.field_profile_picture ? (baseUrl + '/' + educator.field_profile_picture) : DummyProfileImage} alt='' /></TableCell>
                            <TableCell onClick={() => navigateUser(educator.uid)} className="custom-title-link"><div dangerouslySetInnerHTML={{ __html: educator.field_last_name }} ></div></TableCell>
                            <TableCell onClick={() => navigateUser(educator.uid)} className="custom-title-link"> <div dangerouslySetInnerHTML={{ __html: educator.field_first_name }} ></div></TableCell>
                            <TableCell onClick={() => navigateUser(educator.uid)}>{educator.field_home_number}</TableCell>
                            <TableCell onClick={() => navigateUser(educator.uid)}><div dangerouslySetInnerHTML={{ __html: educator.field_street_address }} ></div></TableCell>
                            <TableCell onClick={() => navigateUser(educator.uid)}><div dangerouslySetInnerHTML={{ __html: educator.field_suburb_name }} ></div></TableCell>
                            <TableCell>
                              {props.isArchive === false ?
                                <button className="mdc-button mdc-button--raised" style={{ backgroundColor: "red" }} type="submit" onClick={() => archiveEducator(educator.profile_id, educator.uid)}>Archive</button>
                                :
                                <button className="mdc-button mdc-button--raised mt-2" style={{ backgroundColor: "green" }} type="submit" onClick={() => UnArchiveEducator(educator.profile_id, educator.uid)}>UnArchive</button>
                              }
                            </TableCell>
                            <TableCell>
                              <a target={"_blank"} href={`${baseUrl}/view/all_user_csv?id=${educator.uid}`} rel="noopener noreferrer">Export Data</a>
                            </TableCell>
                          </TableRow>
                        )
                      }else{
                        return null
                      }
                    }
                    ) :
                      <>
                        {
                          noDataLabel === true ?
                            <label>No Records Found!!</label>
                            :
                            null
                        }
                      </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )} />
        : null
      }
    </>
  )
};

export default EducatorsList;
