import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';


import { useDispatch, useSelector } from "react-redux"

// Educator Screens
import EducatorSignup from '../pages/Educators/SignupPage/EducatorSignup';
import EducatorsDashboard from '../pages/Educators/Dashboard/EducatorsDashboard';
import NoticeDetailPage from '../pages/commonPages/NoticeDetail/NoticeDetailPage';
import EducatorTaskDetail from '../pages/Educators/TaskDetail/EducatorTaskDetail';
import EducatorTasks from '../pages/Educators/Tasks/EducatorTasks';
import EducatorVisitDetail from '../pages/Educators/VisitDetail/EducatorVisitDetail';
import EducatorFolders from '../pages/Educators/Folders/EducatorFolders'

//common pages
import LoginPage from '../pages/commonPages/Login/LoginPage';
import ForgetPassword from '../pages/commonPages/ForgetPassword/ForgetPassword';
import UserRolesPage from '../pages/commonPages/UserRoles/UserRolesPage';
import NoticesPage from '../pages/commonPages/Notices/NoticesPage';
import LinksPage from '../pages/commonPages/Links/LinksPage';
import VisitsPage from '../pages/commonPages/Visits/VisitsPage';
import EducatorViewProfilePage from '../pages/commonPages/EducatorCommonViewProfile/EducatorViewProfilePage';
import LinkDetailPage from '../pages/commonPages/LinkDetail/LinkDetailPage';

//Head Office Screens
import HeadOfficeSignup from '../pages/HeadOffice/SignupPage/HeadOfficeSignup';
import HeadOfficeDashboard from '../pages/HeadOffice/Dashboard/HeadOfficeDashboard'
import HeadOfficeFolders from '../pages/HeadOffice/Folders/HeadOfficeFolders'
import NewMentoringSupportPage from '../pages/HeadOffice/NewMentoringSupportPage/NewMentoringSupportPage';
import AddNewTaskPage from '../pages/HeadOffice/NewTaskPage/AddNewTaskPage';
import EducatorsListPage from '../pages/HeadOffice/EducatorsList/EducatorsListPage';
import CoordinatorsListPage from "../pages/HeadOffice/CoordinatorsList/CoordinatorsListPage";
import CreateNewNoticePage from '../pages/HeadOffice/CreateNewNotice/CreateNewNoticePage';
import CreateNewLinkPage from '../pages/HeadOffice/CreateNewLink/CreateNewLinkPage';
import EditEducatorProfilePage from '../pages/Educators/EditProfile/EditEducatorProfilePage';
import EditHeadOfficeProfilePage from '../pages/HeadOffice/EditProfile/EditHeadOfficeProfilePage';
import EditTask from '../pages/HeadOffice/EditTask/EditTask';
import EditLink from '../pages/HeadOffice/EditLink/EditLink';
import EditNotice from '../pages/HeadOffice/EditNotice/EditNotice';
import EditVisit from '../pages/HeadOffice/EditVisit/EditVisit';
import ResetPassword from '../pages/commonPages/ResetPassword/ResetPassword';
import ViewProfile from '../pages/HeadOffice/ViewProfile/ViewProfile';
import AddFacilator from '../pages/HeadOffice/AddFacilator/AddFacilator'
import AddSession from '../pages/HeadOffice/AddSession/AddSession';
import AddBusiness from '../pages/HeadOffice/AddBusiness/AddBusiness';
import AddVenue from '../pages/HeadOffice/AddVenue/AddVenue';
import VenueList from '../pages/HeadOffice/VenueList/AllVenueList';
import FacilitatorList from '../pages/HeadOffice/SessionList/FacilitatorList';
import { getAuthToken, login, setUserRole } from '../redux/actions/auth';
import { useHistory } from "react-router-dom";
import LoaderComponent from '../commonComponents/LoaderComponent/LoaderComponent';
import { logoutUser } from '../functions/api';
import CaBTViewProfilePage from '../pages/HeadOffice/CaBTViewProfile/CaBTViewProfilePage';
import CaBTDashboard from '../pages/Educators/CabtDashboard/CaBTDashboard';



const educatorRoutes = [{
  path: '/login',
  component: LoginPage
},
{
  path: '/forget-password',
  component: ForgetPassword
},
{
  path: '/user-roles',
  component: UserRolesPage
},
{
  path: '/signup-educator',
  component: EducatorSignup
},
{
  path: '/signup-co-ordination-unit',
  component: HeadOfficeSignup
},
{
  path: '/educators-dashboard',
  component: EducatorsDashboard
},
{
  path: '/educators-profile/:id',
  component: EducatorViewProfilePage
},
{
  path: '/link-detail-page/:id',
  component: LinkDetailPage
},
{
  path: '/notice-detail-page/:id',
  component: NoticeDetailPage
},
{
  path: '/task-detail-page/:id',
  component: EducatorTaskDetail
},
{
  path: '/tasks',
  component: EducatorTasks
},
{
  path: '/notices',
  component: NoticesPage
},
{
  path: '/link',
  component: LinksPage
},
{
  path: '/library',
  component: EducatorFolders
},
{
  path: '/library/:alias1',
  component: EducatorFolders
},
{
  path: '/m&s-detail-page/:id',
  component: EducatorVisitDetail
},
{
  path: '/m&s',
  component: VisitsPage
},
{
  path: '/educator-edit-profile',
  component: EditEducatorProfilePage
},
{
  path: '/reset-password',
  component: ResetPassword
},
{
  path: '/add-new-task',
  component: AddNewTaskPage
},
{
  path: '/edit-task/:id',
  component: EditTask
},

];

const headOfficeRoutes = [{
  path: '/login',
  component: LoginPage
},
{
  path: '/forget-password',
  component: ForgetPassword
},
{
  path: '/user-roles',
  component: UserRolesPage
},
{
  path: '/signup-educator',
  component: EducatorSignup
},
{
  path: '/signup-co-ordination-unit',
  component: HeadOfficeSignup
},
{
  path: '/co-ordination-unit-dashboard',
  component: HeadOfficeDashboard
},
{
  path: '/link-detail-page/:id',
  component: LinkDetailPage
},
{
  path: '/notice-detail-page/:id',
  component: NoticeDetailPage
},
{
  path: '/task-detail-page/:id',
  component: EducatorTaskDetail
},
{
  path: '/tasks',
  component: EducatorTasks
},
{
  path: '/notices',
  component: NoticesPage
},
{
  path: '/link',
  component: LinksPage
},
{
  path: '/m&s-detail-page/:id',
  component: EducatorVisitDetail
},
{
  path: '/m&s',
  component: VisitsPage
},
{
  path: '/add-new-m&s',
  component: NewMentoringSupportPage
},
{
  path: '/add-new-task',
  component: AddNewTaskPage
},
{
  path: '/educators-list',
  component: EducatorsListPage
},
{
  path: "/coordinators-list",
  component: CoordinatorsListPage,
},
{
  path: "/cabt-list",
  component: CoordinatorsListPage,
},
{
  path: '/library',
  component: HeadOfficeFolders
},
{
  path: '/library/:alias1',
  component: HeadOfficeFolders
},
{
  path: '/add-new-link',
  component: CreateNewLinkPage
},
{
  path: '/educators-profile/:id',
  component: EducatorViewProfilePage
},
{
  path: '/add-new-notice',
  component: CreateNewNoticePage
},
{
  path: '/co-ordination-unit-edit-profile/:id',
  component: EditHeadOfficeProfilePage
},
{
  path: '/co-ordination-unit-profile/:id',
  component: ViewProfile
},
{
  path: '/edit-task/:id',
  component: EditTask
},

{
  path: '/edit-link/:id',
  component: EditLink
},
{
  path: '/edit-notice/:id',
  component: EditNotice
},
{
  path: '/edit-m&s/:id',
  component: EditVisit
},
{
  path: '/reset-password',
  component: ResetPassword
},
{
  path: '/add-facilator',
  component: AddFacilator
},
{
  path: '/add-business',
  component: AddBusiness
},
{
  path: '/add-venue',
  component: AddVenue
},
{
  path: '/add-venue/:id',
  component: AddVenue
},
{
  path: '/add-session',
  component: AddSession
},
{
  path: '/add-session/:tid',
  component: AddSession
},
{
  path: '/all-venues',
  component: VenueList
},
{
  path: '/facilitator-list',
  component: FacilitatorList
},
{
  path: '/cabt-profile/:id',
  component: CaBTViewProfilePage
},
];

const unAuthRoutes = [
  {
    path: '/',
    component: LoginPage
  },
  {
    path: '/forget-password',
    component: ForgetPassword
  },
  {
    path: '/user-roles',
    component: UserRolesPage
  },
  {
    path: '/signup-educator',
    component: EducatorSignup
  },
  {
    path: '/signup-co-ordination-unit',
    component: HeadOfficeSignup
  },
]

const cabTRoutes = [{
  path: '/login',
  component: LoginPage
},
{
  path: '/forget-password',
  component: ForgetPassword
},
{
  path: '/cabt-dashboard',
  component: CaBTDashboard
},
{
  path: '/cabt-profile/:id',
  component: CaBTViewProfilePage
},
{
  path: '/notice-detail-page/:id',
  component: NoticeDetailPage
},
{
  path: '/notices',
  component: NoticesPage
},
{
  path: '/reset-password',
  component: ResetPassword
},
];


const Routes = ({ route, props }) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false)

  let store = useSelector(state => state.auth)

  useEffect(() => {
    loginUser()
  }, [store.userRole])

  const loginUser = async () => {
    let username = window.localStorage.getItem("username")
    let password = window.localStorage.getItem("password")
    if (username && password) {
      setShowLoader(true)
      try {
        let responseData = await dispatch(login(username, password))
        if (username.includes('@')) {
          await dispatch(getAuthToken(responseData.data.current_user.name, password))
        } else {
          await dispatch(getAuthToken(username, password))
        }
        let userRole = responseData.data.current_user.roles[1];
        if (userRole === 'educator') {
          history.push(`/educators-dashboard`);
          dispatch(setUserRole(1))
        } else if (userRole === 'head_coordinator') {
          dispatch(setUserRole(2))
          history.push('/co-ordination-unit-dashboard');
        } else if (userRole === 'cabt') {
          dispatch(setUserRole(3))
          history.push('/cabt-dashboard');
        }
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    } else {
      let { pathname } = window.location
      if (pathname !== '/login' || pathname !== '/') {
        logout()
      }
      setShowLoader(false)
    }
  }


  const logout = async () => {
    try {
      await logoutUser()
      window.localStorage.clear();
      history.push("/")
    } catch (error) {
      
    }
  }

  // let userRole = JSON.stringify(window.localStorage.getItem("user_role"))
  // let userRoutes = userRole.includes("educator") ? educatorRoutes : headOfficeRoutes
  let isAuthenicated = JSON.stringify(window.localStorage.getItem("user_role")) !== "null" ? true : false
  let pathName = window.location.pathname

  if (showLoader) {
    return (<LoaderComponent displayLoader={true} />)
  }

  return (
    <Router>
      <div>
        <Switch >
          <Route exact path='/' component={LoginPage} />
          {
            isAuthenicated === true ?
              store.userRole === 1 ?
                educatorRoutes.map((route) => <Route key={route.path} exact path={route.path} component={route.component} /> )
              : 
              store.userRole === 3 ?
              cabTRoutes.map((route) => <Route key={route.path} exact path={route.path} component={route.component} /> ) :
                headOfficeRoutes.map((route) => <Route key={route.path} exact path={route.path} component={route.component} /> )
            :
              unAuthRoutes.map((route) => {
                if (unAuthRoutes.filter(e => e.path === pathName).length > 0) {
                  return <Route key={route.path} exact path={route.path} component={route.component} />
                } else {
                  return <Redirect to="/" />
                }
              }
            )
          }
        </Switch>
      </div>
    </Router>
  )
}

export default Routes;