import React, { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import { getListOfParentFolder, getListOfParentFolderNew } from '../../../functions/api'
// import { toast, ToastsStore, ToastsContainerPosition } from 'react-toastify';
// import { ReactDOM } from "react";
//Import Images
// import favourite from '../../../images/favorite.svg';
// import privateFolder from '../../../images/private.svg';
// import folder from '../../../images/folder.svg';
// import folderTwo from '../../../images/folder-two.svg';


//jquiery
// import { $ } from 'react-jquery-plugin';
import LoaderComponent from "../../../commonComponents/LoaderComponent/LoaderComponent";
// var role;



const FolderNavigation = (props) => {
  const { alias1 } = useParams();
  const history = useHistory()
  // const [allFolders, setAllFolders] = useState([])
  const [mainFolderData, setMainFolderData] = useState([])
  const [subFolderData, setSubFolderData] = useState([])
  const [highligtedBox, setHighLightedBox] = useState([])
  const [loader, setLoader] = useState(true)

  const location = useLocation();

  useEffect(() => {
    getMainFolder()
    // getAllFolders()
    // eslint-disable-next-line
  }, [])

  // const uidFunction = async()=>{
  // 	console.log({role});
  // 	if(role === 'head_coordinator'){
  // 	  uid = await props.educatorId;
  // 	}else{
  // 		uid = await window.localStorage.getItem("uid");
  // 	}
  // }

  // async function getMainFolder() {
  //   let uid = window.localStorage.getItem("uid");
  //   if (props.educatorId) {
  //     uid = props.educatorId
  //   }

  //   const response = await getListOfParentFolder(uid);

  //   if (response.status === 200) {
  //     const get_required_data = [];

  //     const get_sub_folder_data = [];
  //     for (let iterator = 0; iterator < response.data.data.length; iterator++) {
  //       if (response.data.data[iterator].field_user[0] === uid) {
  //         get_required_data.push(response.data.data[iterator])
  //         // const responseSub = await getListOfSubFolder(response.data.data[iterator].tid)
  //         // get_sub_folder_data.push(responseSub.data.data)

  //       }
  //     }
  //     // console.log('get_sub_folder_data', get_sub_folder_data);
  //     setMainFolderData(get_required_data)
  //     const flattenSubArray = get_sub_folder_data.flat(1)

  //     // console.log('flatten_array',flattenSubArray);
  //     if (flattenSubArray.length > 0) {
  //       setSubFolderData(flattenSubArray)
  //       setLoader(false);
  //     }
  //     setLoader(false);

  //   } else {
  //     setLoader(false);
  //   }
  // }
  async function getMainFolder() {
    let uid = window.localStorage.getItem("uid");
    if (props.educatorId) {
      uid = props.educatorId
    }else if(props.uid){
      uid = props.uid
    }

    const response = await getListOfParentFolderNew(uid);
    // console.log();
    if (response.status === 200) {
      setMainFolderData(response.data?.data.folders)
      setLoader(false);

    } else {
      setLoader(false);
    }
  }

  useEffect(() => {
    // role = window.localStorage.getItem('user_role');
    getMainFolder()
    //  showSubFolder();
    // await uidFunction();
    // await getInitialData()
    // async function getInitialData() {
    // 	if(props.data !== undefined && props.data.length > 0)
    // 	{
    // 		setAllFolders([]);

    // 		const get_required_data =[];

    // 		for (let iterator = 0; iterator < props.data.length; iterator++) {
    //             	if(props.data[iterator].field_user_id === uid){
    // 				get_required_data.push(props.data[iterator])
    // 				// setAllFolders( prevSelected => [...prevSelected, props.data[iterator]])
    // 		}
    // 	    }

    // 		const unique_element =[];
    // 		const unique_ids =[];

    // 	   for( let i =0 ; i< get_required_data.length ; i++){

    // 		if(!(unique_ids.includes(get_required_data[i].parent_target_id[0]))){
    // 			unique_element.push(get_required_data[i]);
    // 			unique_ids.push(get_required_data[i].parent_target_id[0])
    // 		}
    // 	   }


    // 		setAllFolders(unique_element)


    // 		// setAllFolders(props.data)
    // 	}
    // 	else
    // 	{
    // 		let foldersResponse = await getAllFolders();
    // 		if(foldersResponse.status === 200)
    // 		{
    // 			if(foldersResponse.data.data.length > 0)
    // 			setAllFolders([]);
    // 			{
    // 				for (let iterator = 0; iterator < foldersResponse.data.data.length; iterator++) {



    // 					if(foldersResponse.data.data[iterator].field_user_id === uid){
    // 						setAllFolders( prevSelected => [...prevSelected, foldersResponse.data.data[iterator]])
    // 				 }
    // 				 }

    // 			}
    // 		}
    // 	}
    // }
    // eslint-disable-next-line
  }, [props.data, props.glowId])

  // async function getInitialData() {
  // 	if(props.data !== undefined && props.data.length > 0)
  // 	{
  // 		setAllFolders(props.data)
  // 	}
  // 	else
  // 	{
  // 		let foldersResponse = await getAllFolders();
  // 		if(foldersResponse.status === 200)
  // 		{
  // 			if(foldersResponse.data.data.length > 0)
  // 			{
  // 				setAllFolders(foldersResponse.data.data)
  // 			}
  // 		}
  // 	}
  // }


  const showFiles = (taxonomyId, tid) => {
    let uid = window.localStorage.getItem("uid");
    if (props.educatorId) {
      uid = props.educatorId
    }else if(props.uid){
      uid = props.uid
    }
    window.scrollTo(0, 0);
    setHighLightedBox(tid)
    history.push(`/library/${taxonomyId}`, {uid:uid})
  }

  const goBack = () => {
    // console.log('location.pathname =', location.pathname);/educators-profile
    if ((location.pathname === '/library') || (location.pathname.includes('/educators-profile'))) {
      // window.location.reload()
    } else {
      window.scrollTo(0, 0);
      history.push('/library')
      window.localStorage.removeItem('folderParentId');
    }
  }

  // const showSubFolder = async () => {
  //   const getFilteredData = await getFolderAllAPI();

  //   if (getFilteredData.status === 200) {
  //     const resp = getFilteredData.data.data;

  //     ;
  //   }
  // }

  const sendPreviousPageData = (data, tid) => {
    window.scrollTo(0, 0);
    setHighLightedBox(tid)
    history.push({ pathname: '/library', state: { requiredData: data } })
    window.localStorage.setItem('folderParentId', tid)
    props.getParentId(tid);
  }
  // const reloader =()=>{
  // 	// window.location.reload()
  // }

  return (
    <div className="col-md-4">
      <div className="sidebar sidebar-locker ">
        <div className="task-listing-block">
          <div className="common-header-row" >
            <h4>Locker</h4>
          </div>

          <div className="folders-listing">

            {
              alias1 === undefined ?
                <span onClick={() => goBack()} className="material-icons arrow-upward">arrow_upward</span>
                :
                null
            }
            {loader === true ? <LoaderComponent displayLoader={loader} /> :
              <ul>
                <li style={{ cursor: 'pointer' }} ><span className="material-icons">create_new_folder</span><text onClick={() => goBack()} >Locker</text>
                  <ul>
                    <li>
                      <ul>
                        <>
                          {
                            mainFolderData.length > 0 ?
                              mainFolderData.map((items, index) => {
                                // let folerName = items.name.length > 12 ? items.name.substring(0, 12)+"...." : items.name.substring(0, 12)
                                // let subDetailsOfFolder = subFolderData.filter(item => item.parent_target_id[0] === (items.tid).toString());
                                return (
                                  <li key={index} className={highligtedBox.includes(items.term_id) ? "active" : ""} style={{ cursor: 'pointer' }}>
                                    <div onClick={() => sendPreviousPageData(items.sub, items.term_id)} >
                                      <span className="material-icons">folder</span>
                                      <text>{items.term_name}</text>
                                    </div>
                                    {items.sub.map((values) => {
                                      return (
                                        <li className={alias1 === items.term_id ? "active little-left" : "little-left"} onClick={() => showFiles(values.term_id, items.term_id)}>
                                          <span className="material-icons">folder_open</span>
                                          <text >{values.term_name}</text>
                                        </li>
                                      )
                                    })}
                                    {
                                      items.term_id === alias1 ?
                                        <div onClick={goBack}>
                                          <span className="material-icons arrow-upward" >arrow_upward</span>
                                        </div>
                                        :
                                        null
                                    }
                                  </li>
                                )
                              })
                              :
                              null
                          }
                        </>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export function resetScrollInsideTable(indexTable) {
  let tableBody = document.getElementsByClassName('sidebar')[indexTable];
  tableBody.scrollTop = 0;
}

export default FolderNavigation