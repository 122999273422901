import React, { useState, useEffect } from "react"
// import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { getFolderFiles, createNewFolder, addFavouriteFolder, updateFolder, unfavouriteFolder, deleteSpecificFolder, deleteSpecificFile, getListOfParentFolder, getFolderByParentIdAPI, uploadMultiFilesInFolder, updateTargetIdsOfImage, deleteSpecificFolderFile, updateSpecificFolderFile } from '../../../functions/api'
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { getAllFolders, getFolderFiles, createNewFolder, addFavouriteFolder, updateFolder, deleteSpecificFolder, deleteSpecificFile,unfavouriteFolder } from '../../../functions/api'

//Import Images
import favourite from '../../../images/favorite.svg';
import privateFolder from '../../../images/private.svg';
// import folder from '../../../images/folder.svg';
import folderTwo from '../../../images/folder-two.svg';
import both from '../../../images/both.svg';
import ReactTooltip from "react-tooltip";
import pdfIcons from '../../../images/pdfImage.png';
import excelIcons from '../../../images/excelImage.png';
import docIcons from '../../../images/docImage.png';
//jquery
import { $ } from 'react-jquery-plugin';
import { baseUrl } from "../../../config";
import { PaginatedList } from "react-paginated-list";
import { confirmAlert } from "react-confirm-alert";

var uid;
const HeadOfficeFolderDetail = (props) => {
  const history = useHistory()
  // const [allFolders, setAllFolders] = useState([])
  const [showOptions, setShowOptions] = useState([])
  // const [showFileOptions, setShowFileOptions] = useState([])
  const [showLoader, setShowLoader] = useState(true)
  const [libraryFolderName, setLibraryFolderName] = useState("")
  const [isPrivateFolder, setPrivateFolder] = useState(false)
  const [displayUpdateFolder, setDisplayUpdateFolder] = useState(false)
  const [displayCreateFolder, setDisplayCreateFolder] = useState(false)
  const [updationTaxonomyId, setUpdationTaxonomyId] = useState('')
  const [previousIndex, setPreviosIndex] = useState(null);
  const [dummyFolder, setDummyFolder] = useState(true);
  const [mainFolder, setMainFolder] = useState([]);
  const [subFolder, setSubFolderData] = useState([]);
  const [passChildId, setPassChildId] = useState('')
  const [requiredParentID, setRequiredParentID] = useState('');

  const [allFiles, setAllFiles] = useState([]);
  // const [folderName, setFolderName] = useState('')
  const [ind, setIndex] = useState()
  const [dropdownIndex, setDropdownIndex] = useState(false)
  // const [displayUpdateFile, setDisplayUpdateFile] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // const [indFile, setIndexFile] = useState()
  const [fileName, setFileName] = useState('')
  const [fid, setFid] = useState()
  const location = useLocation();
  let locationRequiredParentId = window.localStorage.getItem('folderParentId');
  // console.log('handleImageDelete', locationRequiredParentId);
  useEffect(() => {

    let parentId = requiredParentID
    if (locationRequiredParentId) {
      parentId = locationRequiredParentId
    }
    setAllFiles([]);
    if (parentId) {
      getAllFilesData(parentId);
      setDummyFolder(false);
      setRequiredParentID(parentId)
      showSubFolder(parentId)
      // if(location.state.requiredData.length >0){
      // 	setSubFolderData(location.state.requiredData)
      // }else{
      // 	setDummyFolder(false);
      // 	setPassChildId(props.parentId)
      // 	setRequiredParentID(props.parentId)
      // 	setSubFolderData([])
      // 	getAllFilesData(props.parentId);
      // }
    }
    else if (parentId == null && parentId) {
      getAllFilesData(parentId);
      setDummyFolder(false);
      setRequiredParentID(parentId)
      showSubFolder(parentId)
    }
    // eslint-disable-next-line
  }, [props.parentId, location.state, requiredParentID])

  async function getAllFoldersData() {
    let foldersResponse = await getListOfParentFolder(uid);
    if (foldersResponse.status === 200) {
      setShowLoader(false)
      if (foldersResponse.data.data.length > 0) {
        setMainFolder([]);
        // setAllFolders([])
        const get_required_data = [];
        for (let iterator = 0; iterator < foldersResponse.data.data.length; iterator++) {
          if (foldersResponse.data.data[iterator].field_user[0] === (uid)) {
            get_required_data.push(foldersResponse.data.data[iterator])
            setMainFolder(get_required_data)
          }

        }



        // 	const unique_element =[];
        // 	const unique_ids =[];

        //    for( let i =0 ; i< get_required_data.length ; i++){

        // 	// if(!(unique_ids.includes(get_required_data[i].parent_target_id[0]))){
        // 	// 	unique_element.push(get_required_data[i]);
        // 	// 	unique_ids.push(get_required_data[i].parent_target_id[0])
        // 	// }
        //    }

        // setAllFolders(get_required_data)


        props.getFolders(foldersResponse.data.data)
        // let newArray = []
        // foldersResponse.data.data.map((data) => {
        // 	 newArray.push(false)
        // })
        // setShowOptions(newArray)
        const newArray = Array.from({ length: foldersResponse.data.data.length }, () => false);
        setShowOptions(newArray);
      }
    }
    else {
      setShowLoader(false)
      toast.error(foldersResponse.message, { position: 'top-center' });
    }
  }

  useEffect(() => {
    uid = window.localStorage.getItem("uid");
    if (props.uid) {
      uid = props.uid
    }
    getMainFolder()
    getAllFoldersData()
    // eslint-disable-next-line 
  }, [setMainFolder])

  const deleteSelectOptions = async (data, index) => {
    setShowLoader(true)
    let unfavRes = await unfavouriteFolder(data.id)
    if (unfavRes.status === 204) {
      let newArray = showOptions
      newArray[index] = false
      setShowOptions([...newArray])
      setShowLoader(false)
      toast.success("Folder removed from favourite list", { position: 'top-center' });
      // getAllFoldersData()
      getMainFolder()
      showSubFolder(locationRequiredParentId, true)
    }
    else {
      setShowLoader(false)
      let newArray = showOptions
      newArray[index] = false
      setShowOptions([...newArray])
      toast.error(unfavRes.message, { position: 'top-center' });
    }

  }

  const showHideModal = (modalType, folderType) => {
    if (modalType === "create") {
      setLibraryFolderName("")
      setPrivateFolder(false)
      setDisplayUpdateFolder(false)

      if (displayCreateFolder === true) {
        setDisplayCreateFolder(false)
      }
      else {
        setDisplayCreateFolder(true)
      }
    }
    else {
      if (displayUpdateFolder === true) {
        setDisplayUpdateFolder(false)
      }
      else {
        setDisplayUpdateFolder(true)
      }
    }

  }

  const createFolder = async () => {
    setShowLoader(true)
    let userId = await window.localStorage.getItem('uid')

    showHideModal("create")
    let bodyObject;
    if (dummyFolder) {
      bodyObject = {
        "vid": [
          {
            "target_id": "folders_reference"
          }
        ],
        "name": [
          {
            "value": libraryFolderName
          }
        ],
        "field_user": [
          {
            "target_id": userId
          }

        ],
        "field_private": [
          {
            "value": isPrivateFolder
          }
        ],
        "field_main_folder": [
          {
            "value": dummyFolder
          }
        ]


      }
    } else {
      bodyObject = {
        "vid": [
          {
            "target_id": "folders_reference"
          }
        ],
        "name": [
          {
            "value": libraryFolderName
          }
        ],
        "field_user": [
          {
            "target_id": userId
          }

        ],
        "field_private": [
          {
            "value": isPrivateFolder
          }
        ],
        "field_main_folder": [
          {
            "value": dummyFolder
          }
        ],
        "field_user_id": [
          {
            "target_id": userId
          },

        ],
        "parent": [
          {
            "target_id": passChildId
          },

        ],


      }
    }

    let createResponse = await createNewFolder(bodyObject)
    if (createResponse.status === 201) {
      setShowLoader(false)
      // await getAllFoldersData()
      getMainFolder()
      setLibraryFolderName("")
      setPrivateFolder(false)
      if (passChildId) {

        await showSubFolder(passChildId)
      }
      toast.success("Folder created successfully", { position: 'top-center' });
    }
    else {
      setShowLoader(false)
      toast.error(createResponse.message, { position: 'top-center' });
    }

  }

  const showFiles = (taxonomyId, field, folderData) => {
    if (field === 'moveNext') {
      setDummyFolder(false);
    }
    else {
      history.push({ pathname: `/library/${taxonomyId}`, state: { folderName: field , uid : uid} })
    }
  }

  // const showFiles = (taxonomyId, field, folderData) => {
  // 	history.push({ pathname: `/library/${taxonomyId}`, state: { folderName: field } })

  // }

  const showHideOptions = (index) => {
    let newArray = showOptions;

    if (previousIndex === index) {
      setPreviosIndex(null)
      newArray[index] = false
    }
    else {
      if (newArray[index] === true) {
        newArray[previousIndex] = false
        setPreviosIndex(null)
        newArray[index] = false
      }
      else {
        newArray[previousIndex] = false
        setPreviosIndex(index)
        newArray[index] = true
      }
    }
  }

  const showHideFileOptions = (index) => {
    if (dropdownIndex) {
      setIndex()
      setDropdownIndex(false)
    } else {
      setIndex(index)
      setDropdownIndex(true)
    }
    // let newArray = showFileOptions;

    // if (previousIndex === index) {
    // 	setPreviosIndex(null)
    // 	newArray[index] = false
    // 	setIndex()
    // }
    // else {
    // 	if (newArray[index] === true) {
    // 		newArray[previousIndex] = false
    // 		setPreviosIndex(null)
    // 		newArray[index] = false
    // 		setIndex()
    // 	}
    // 	else {
    // 		newArray[previousIndex] = false
    // 		setPreviosIndex(index)
    // 		newArray[index] = true
    // 		setIndex(index)
    // 	}
    // }
  }


  const showSubFolder = async (parentID, type) => {
    if (type) {
      if (parentID === null) {
        return
      }
      else {
        window.localStorage.setItem('folderParentId', parentID)
        setRequiredParentID(parentID)
        getAllFilesData(parentID)
        const getFilteredData = await getFolderByParentIdAPI(parentID);
        // console.log("getFilteredData===", getFilteredData);
        if (getFilteredData.status === 200) {
          const resp = getFilteredData.data.data;
          setSubFolderData(resp)
          setPassChildId(parentID)
          setDummyFolder(false);
          setShowLoader(false)
        }
      }
      return
    }

    if (parentID === null) {
      return
    } else {
      window.localStorage.setItem('folderParentId', parentID)
      setRequiredParentID(parentID)
      getAllFilesData(parentID)
      const getFilteredData = await getFolderByParentIdAPI(parentID);
      // console.log("getFilteredData===", getFilteredData);
      if (getFilteredData.status === 200) {
        const resp = getFilteredData.data.data;
        // console.log("rsp", resp);
        setSubFolderData(resp)
        setPassChildId(parentID)
        setDummyFolder(false);
      }
    }
    setShowLoader(false)
  }

  const selectFileOptions = (id, index, name) => {
    setFid(id)
    setFileName(name)
    if (ind === index) {
      setShowModal(true)
      setDropdownIndex(false)
    }

  }
  const updateFileName = async () => {
    setShowLoader(true)
    let fileChangeName = await updateSpecificFolderFile(fid, fileName)
    if (fileChangeName?.status === 200) {
      setAllFiles([])
      setShowLoader(false)
      toast.success("File Name Update Successfully", { position: 'top-center' });
      getAllFilesData(locationRequiredParentId)
      setIndex()
      setShowModal(false)
    }
    else {

      setShowLoader(false)
      toast.error(fileChangeName.message, { position: 'top-center' });

    }
  }

  const selectOptions = async (data, option, index) => {
    // console.log('edit folder', index);
    setShowLoader(true)
    let uid = await window.localStorage.getItem('uid')
    let newArray = showOptions
    newArray[index] = false
    setShowOptions([...newArray])

    if (option === "favorite") {
      let obj = {
        "flag_id": [{ "target_id": "favourite" }],
        "entity_type": [{ "value": "taxonomy_term" }],
        "entity_id": [{ "value": data.tid }],
        "uid": [{ "target_id": uid }]
      }

      let favouriteFolderResponse = await addFavouriteFolder(obj)
      if (favouriteFolderResponse.status === 201) {
        setShowLoader(false)
        if (favouriteFolderResponse.data.data !== undefined) {
          let responseData = favouriteFolderResponse.data.data
          let favId = responseData.id ? responseData.id[0].value : ""
          let folderFlag = data.field_private === "On" ? true : false
          updateFolderDetail("favoutite", data.name, data.tid, folderFlag, favId)
        }
        // toast.success("Folder added to the favourite successfully",{ position: 'top-center'});
      }
      else {
        setShowLoader(false)
        toast.error(favouriteFolderResponse.message, { position: 'top-center' });
      }
    }

    if (option === "rename") {
      setShowLoader(false)
      showHideModal("update")
      setLibraryFolderName(data.name)
      let folderFlag = data.field_private === "On" ? true : false
      $('#private').prop('checked', folderFlag);
      setPrivateFolder(folderFlag)
      setUpdationTaxonomyId(data.tid)
    }

    if (option === "delete") {
      // console.log("delete");
      setShowLoader(true)
      let folderFilesResponse = await getFolderFiles(data.tid)
      // console.log("folderFilesResponse", folderFilesResponse);
      let deleteFolderResponse = await deleteSpecificFolder(data.tid)
      if (deleteFolderResponse.status === 204) {
        if (folderFilesResponse.data.data.length > 0) {
          let foldersData = folderFilesResponse.data.data
          foldersData.map(async (items, i) => {
            let index = i + 1
            // console.log("index", index);
            let delRes = await deleteSpecificFile(items.mid)
            if (delRes.status === 204) {
              if (index === foldersData.length) {
                toast.success("Folder Deleted Successfully", { position: 'top-center' });
              }

            }
          })
          setShowLoader(false)
          getMainFolder()
        }
        else {

          setShowLoader(false)
          toast.success("Folder Deleted Successfully", { position: 'top-center' });
          // await getAllFoldersData()
          getMainFolder()
        }
      }
      else {
        setShowLoader(false)
        toast.error(deleteFolderResponse.message, { position: 'top-center' });
      }
      showSubFolder(locationRequiredParentId, true)
      getAllFilesData(locationRequiredParentId)
      // if (window.localStorage.getItem('folderParentId')) {
      // 	getAllFilesData(window.localStorage.getItem('folderParentId'))
      // }
    }

  }
  function confirmDeleteLocker(data, index) {

    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => selectOptions(data, "delete", index)
        },
        {
          label: 'No',
          onClick: () => showHideOptions(index)
        }
      ]
    });
  }
  const updateFolderDetail = async (field, selectedFolderName, tid, folderStatus, favId) => {

    setShowLoader(true)
    let uid = await window.localStorage.getItem('uid')
    let name = field === "favoutite" ? selectedFolderName : libraryFolderName
    let privateFolderStatus = field === "favoutite" ? folderStatus : isPrivateFolder

    let renameObj = {
      "vid": [{ "target_id": "folders_reference" }],
      "name": [{ "value": name }],
      "field_user": [{ "target_id": uid }],
      "field_private": [{ "value": privateFolderStatus }]
    }

    if (field === "favoutite") {
      Object.assign(renameObj, { "field_favourite_folder": [{ "target_id": favId }] })
    }

    let taxonomyId = field === "favoutite" ? tid : updationTaxonomyId

    let updateFolderResponse = await updateFolder(taxonomyId, renameObj)
    if (updateFolderResponse.status === 200) {
      setShowLoader(false)
      await getAllFoldersData()
      getMainFolder()
      setLibraryFolderName("")
      setPrivateFolder(false)
      setDisplayUpdateFolder(false)
      if (field === "favoutite") {
        toast.success("Folder added to favourite", { position: 'top-center' });
      }
      else {
        toast.success("Folder updated successfully", { position: 'top-center' });
      }
    }
    else {
      setShowLoader(false)
      toast.error(updateFolderResponse.message, { position: 'top-center' });
    }
    if (window.localStorage.getItem('folderParentId')) {
      showSubFolder(window.localStorage.getItem('folderParentId'))
    }

  }

  // New Functionality Here Don't Bother Previous One as it's not Understandable
  async function getMainFolder() {
    setShowLoader(true)
    const response = await getListOfParentFolder(uid);
    if (response.status === 200) {
      const get_required_data = [];
      for (let iterator = 0; iterator < response.data.data.length; iterator++) {

        if (response.data.data[iterator].field_user[0] === (uid)) {
          // console.log("response.data.data[iterator]", response.data.data[iterator]);
          get_required_data.push(response.data.data[iterator])
          setMainFolder(get_required_data)

        }
      }

      setShowLoader(false);
    } else {
      setShowLoader(false)
    }
  }

  // working on showing anf uploading files 
  const uploadImageHandler = (event, field) => {
    let imagesArray = [];
    let fidOfImage = [];
    if (event.target.files && event.target.files[0]) {
      let lengthOfFiles = Object.keys(event.target.files).length;
      if (Object.keys(event.target.files).length <= 7) {
        Object.values(event.target.files).map(function (filesData, index) {
          let name = filesData.name;
          if (field === "image") {
            // if(filesData.type !== 'image/jpg' && filesData.type !== 'image/jpeg' && filesData.type !== 'image/png'){
            // 	toast.error('Invalid Image Type. Image can only be of the following types : jpg, jpeg, png', {
            //         position: 'top-center'
            //     });
            //     return false;
            // }else{
            if (filesData.size > 5000000) {
              toast.error('Image size exceeds the limit of 5MB', {
                position: 'top-center'
              });
              return false;
            } else {
              setShowLoader(true)
              var reader = new FileReader();
              reader.onload = async function (event) {
                imagesArray.push({ 'name': name, 'path': event.target.result })
                const response = await uploadMultiFilesInFolder(name, event.target.result)
                fidOfImage.push({ "target_id": response });
                // console.log('fidOfImage', fidOfImage);
                if (lengthOfFiles === fidOfImage.length) {
                  let localRequiredParentId = window.localStorage.getItem('requiredParentID')
                  // console.log("localRequiredParentId", localRequiredParentId);
                  // console.log("requiredParentID", requiredParentID);
                  let parentId = requiredParentID ? requiredParentID : localRequiredParentId ? localRequiredParentId : ''
                  // console.log("parentIdnew", parentId);
                  const uploadDocResponse = await updateTargetIdsOfImage("Data_Media", fidOfImage, parentId);
                  // console.log('uploadDocResponse', uploadDocResponse);
                  if (uploadDocResponse.status === 201) {
                    setShowLoader(false)
                    await getAllFilesData(requiredParentID)
                    toast.success("Uploaded Successfully!!!", { position: 'top-center' });
                  }
                  else {
                    setShowLoader(false)
                    toast.error(uploadDocResponse.message, { position: 'top-center' });
                  }
                  window.localStorage.removeItem("requiredParentID");
                }
              };
              return reader.readAsDataURL(filesData);
            }
          } else {
            return false;
          }
        });
      }
      else {
        toast.error("You can't upload more than 7 images", { position: 'top-center' });
      }
    }
  }



  async function getAllFilesData(data) {
    // setAllFiles([])
    let foldersResponse;
    if (data !== '') {
      foldersResponse = await getFolderFiles(data);
    }
    else {
      foldersResponse = await getFolderFiles(requiredParentID);
    }

    if (foldersResponse.status === 200) {
      if (foldersResponse.data.data.length > 0) {
        setAllFiles(foldersResponse.data.data)
        // setFolderName(foldersResponse.data.data[0].field_folder);
        setShowLoader(false)
        setDropdownIndex(false)
        setIndex()
      }
    }
    else {
      setShowLoader(false)
      setAllFiles([])
    }
  }
  async function handleImageDelete(data, index) {
    setShowLoader(true)
    if (ind === index) {
      setDropdownIndex(false)
      setIndex()
      const res = await deleteSpecificFolderFile(data?.fid)

      if (res?.status === 200) {
        setAllFiles([])
        setShowLoader(false)
        toast.success("File Deleted Successfully", { position: 'top-center' });
        await getAllFilesData(locationRequiredParentId)
      }
      else {
        setShowLoader(false)
        toast.error(res.message, { position: 'top-center' });
      }
    }
  }

  function confirmDeleteLockerFile(data, index) {

    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleImageDelete(data, index)
        },
        {
          label: 'No',
          onClick: () => showHideFileOptions(index)
        }
      ]
    });
  }
  return (
    <>
      <LoaderComponent displayLoader={showLoader} />

      <div className="col-md-8">
        <div className="content-block-right">
          <div className="common-header-row library-page-block">
            {/* <h4>Locker</h4> */}
            <div className='breadcrun-of-folder-path'>
              {subFolder && subFolder.length > 0 ? (
                <div className="row">
                  <h4 dangerouslySetInnerHTML={{ __html: subFolder[0].name_1 }}></h4>
                </div>
              ) : null}
            </div>
            <div className="btn-right">
              {!dummyFolder ? (
                <div style={{ marginBottom: 5 }}>
                  <div className="common-header-row">
                    <div className="btn-right upload-btn" >
                      <ReactTooltip place="top" type="dark" effect="float" id="upload">
                        <p>Accept jpg, jpeg, png, pdf, doc, docx formats only</p>
                      </ReactTooltip>
                      <div className="mdc-button mdc-button--raised green-btn" data-tip data-for='upload'>
                        <input type="file" style={{ "opacity": "0", "position": "absolute", "height": "40px", cursor: 'pointer' }} multiple accept="image/jpg,image/png,.pdf,.doc,.docx"
                          onChange={(e) => uploadImageHandler(e, "image")}
                        />
                        <span className="material-icons">add</span> <span>UPLOAD FILE</span>
                      </div>
                    </div>
                  </div>
                  <div className="mdc-button mdc-button--raised green-btn" onClick={() => showHideModal("create")}>
                    <span className="material-icons">add</span> <span className="mdc-button__label">add folder</span>
                  </div>
                </div>
              ) :
                (
                  <div className="mdc-button mdc-button--raised green-btn" onClick={() => showHideModal("create")}>
                    <span className="material-icons">add</span> <span className="mdc-button__label">add folder</span>
                  </div>
                )}
            </div>
          </div>

          {/* {console.log('--', dummyFolder)}
					{console.log('--mainFolder', mainFolder)}
					{console.log('--subFolder', subFolder)} */}
          {dummyFolder && mainFolder.length > 0 ?
            <PaginatedList
              list={mainFolder}
              itemsPerPage={20}
              renderList={(list) => (
                <ul className="big-folders-list">
                  {list && list.map((data, index) => {
                    return (
                      <li style={{ cursor: 'pointer' }}>
                        <figure onClick={() => showSubFolder(data.tid, true)}>
                          <img src={data.field_private === "On" && data.flagged === "True" ? both : data.field_private === "On" ? privateFolder : data.flagged === "True" ? favourite : folderTwo} alt="" />
                        </figure>
                        <p>{data.name.length > 12 ? data.name.substring(0, 12) + "...." : data.name.substring(0, 12)}
                          <span className="material-icons" onClick={() => showHideOptions(index)} >more_vert</span>
                        </p>
                        {showOptions[index] === true ?
                          <ul className="dropdown-2">{
                            data.flagged === "False" ?
                              <li onClick={() => selectOptions(data, "favorite", index)}> Favorite </li>
                              :
                              <li onClick={() => deleteSelectOptions(data, index)}> Unfavorite </li>
                          }

                            <li onClick={() => selectOptions(data, "rename", index)}> Edit </li>
                            <li onClick={() => confirmDeleteLocker(data, index)}> Delete </li>
                          </ul> : null


                        }
                      </li>
                    )
                  })}
                </ul>
              )}
            /> : subFolder.length > 0 &&
            <PaginatedList
              list={subFolder}
              itemsPerPage={20}
              renderList={(list) => (
                <ul className="big-folders-list">
                  {list && list.map((data, index) => {
                    return (
                      <li style={{ cursor: 'pointer' }}>
                        <figure onClick={() => showFiles(data.tid, data.name, data)}>
                          <img src={data.field_private === "On" && data.flagged === "True" ? both : data.field_private === "On" ? privateFolder : data.flagged === "True" ? favourite : folderTwo} alt="" />
                        </figure>
                        <p>{data.name.length > 12 ? data.name.substring(0, 12) + "...." : data.name.substring(0, 12)}
                          <span className="material-icons" onClick={() => showHideOptions(index)} >more_vert</span>
                        </p>
                        {showOptions[index] === true ?
                          <ul className="dropdown-2">{
                            data.flagged === "False" ?
                              <li onClick={() => selectOptions(data, "favorite", index)}> Favorite </li>
                              :
                              <li onClick={() => deleteSelectOptions(data, index)}> Unfavorite </li>
                          }

                            <li onClick={() => selectOptions(data, "rename", index)}> Edit </li>
                            <li onClick={() => confirmDeleteLocker(data, index)}> Delete </li>
                          </ul> : null
                        }
                      </li>
                    )
                  })}

                </ul>
              )}
            />
          }
          {!dummyFolder ? (

            <ul className="big-folders-list">
              <>
                {/* {console.log("allFiles",allFiles)} */}
                {
                  allFiles.length && !showLoader ?
                    allFiles.map((items, index) => {
                      let imageName = items?.filename
                      let imageUrl = baseUrl + items.field_media_file_1
                      let splittedArray = items.field_media_file_1.split(".")
                      let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                        splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                          : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                            : imageUrl
                      // let fileName = items.name.length > 12 ? items.name.substring(0, 12) + "...." : items.name.substring(0, 12)
                      let splitFile = imageName.split(".");
                      if (items?.fid) {
                        // console.log("items", items.fid);
                        // console.log("items===",items.field_media_file_1);

                        return (
                          <li style={{ cursor: 'pointer', marginBottom: 40 }}  >
                            <figure>
                              <a href={baseUrl + items?.field_media_file_1} target="_blank" rel="noreferrer" ><img src={uploadedImageIcon} alt="" /></a>
                            </figure>
                            {/* <div dangerouslySetInnerHTML={{__html:items.filename}} onClick={() => showHideFileOptions(index)}></div> */}
                            <p>
                              {/* {console.log('items.name',items.filename)} */}

                              {imageName}
                              <span className="material-icons" onClick={() => showHideFileOptions(index)} >more_vert</span>
                            </p>
                            {ind === index && dropdownIndex === true ?
                              <ul className="dropdown-2">
                                <li onClick={() => confirmDeleteLockerFile(items, index)}> Delete </li>
                                <li onClick={() => selectFileOptions(items.fid, index, splitFile[0])}> Edit </li>
                              </ul>
                              : null
                            }
                          </li>
                        )
                      } else {
                        return null
                      }
                    })
                    : (
                      null
                    )
                }
              </>
            </ul>
          ) : null}
          {
            displayCreateFolder === true ?
              <div class="container">
                <div class="dashboard-wrap">
                  <div class="row">
                    <div class="col-lg-8 offset-lg-2 offset">
                      <div class="add-folders-popup new-folder">
                        <h3>New Folder</h3>
                        <label className="mdc-text-field mdc-text-field--outlined">
                          <input className={libraryFolderName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" value={libraryFolderName} onChange={(e) => setLibraryFolderName(e.target.value)} name="libraryFolderName" aria-labelledby="my-label-id" />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Folder Name*</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                          </span>
                        </label>
                        <span className="mdc-notched-outline__trailing"></span>

                        <div class="mdc-form-field">
                          <div class="mdc-checkbox">
                            <input id="checkbox-1" type="checkbox" class="mdc-checkbox__native-control" defaultChecked={isPrivateFolder} onChange={(e) => setPrivateFolder(e.target.checked)} />
                            <div class="mdc-checkbox__background">
                              <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                                <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                              </svg>
                              <div class="mdc-checkbox__mixedmark"></div>
                            </div>
                            <div class="mdc-checkbox__ripple"></div>
                          </div>
                          <label for="checkbox-1">Make Private(Only for Co-ordination Unit)</label>
                        </div>



                        <div class="cancel-btn-wrap">
                          <button class="mdc-button" onClick={() => showHideModal("create")}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">cancel</span>
                          </button>

                          <button class="mdc-button" onClick={createFolder}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">create</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              null
          }
          {
            displayUpdateFolder === true ?
              <div class="container">
                <div class="dashboard-wrap">
                  <div class="row">
                    <div class="col-lg-8 offset-lg-2 offset">
                      <div class="add-folders-popup new-folder">
                        <h3>New Folder</h3>
                        <label className="mdc-text-field mdc-text-field--outlined">
                          <input className={libraryFolderName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" value={libraryFolderName} onChange={(e) => setLibraryFolderName(e.target.value)} name="folderName" aria-labelledby="my-label-id" />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Edit Folder</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                          </span>
                        </label>

                        <div class="mdc-form-field">
                          <div class="mdc-checkbox">
                            <input type="checkbox" class="mdc-checkbox__native-control" id="private" defaultChecked={isPrivateFolder} onChange={(e) => setPrivateFolder(e.target.checked)} />
                            <div class="mdc-checkbox__background">
                              <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                                <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                              </svg>
                              <div class="mdc-checkbox__mixedmark"></div>
                            </div>
                            <div class="mdc-checkbox__ripple"></div>
                          </div>
                          <label for="checkbox-1">Make Private</label>
                        </div>
                        <div class="cancel-btn-wrap">
                          <button class="mdc-button" onClick={() => showHideModal("update")}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">cancel</span>
                          </button>

                          <button type="button" class="mdc-button" onClick={() => updateFolderDetail("edit")}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">Update</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              null
          }
          {
            showModal ?
              <div class="container">
                <div class="dashboard-wrap">
                  <div class="row">
                    <div class="col-lg-8 offset-lg-2 offset">
                      <div class="add-folders-popup new-folder">
                        <h3>Edit File Name</h3>
                        <label className="mdc-text-field mdc-text-field--outlined">
                          <input className={fileName ? "mdc-text-field__input active" : "mdc-text-field__input"} type="text" value={fileName} onChange={(e) => setFileName(e.target.value)} name="folderName" aria-labelledby="my-label-id" />
                          <span className="mdc-notched-outline">
                            <span className="mdc-notched-outline__leading"></span>
                            <span className="mdc-notched-outline__notch">
                              <span className="mdc-floating-label" id="my-label-id">Edit File Name</span>
                            </span>
                            <span className="mdc-notched-outline__trailing"></span>
                          </span>
                        </label>


                        <div class="cancel-btn-wrap">
                          <button class="mdc-button" onClick={() => setShowModal(false)}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">cancel</span>
                          </button>

                          <button type="button" class="mdc-button" onClick={updateFileName}>
                            <span class="mdc-button__ripple"></span>
                            <span class="mdc-button__label">Update</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              null
          }


          {/* {!dummyFolder ? (

						<ul className="big-folders-list">
							<>
								{
									allFiles.length && !showLoader ?
										allFiles.map((items, index) => {
											let imageUrl = baseUrl + items.field_media_file_1
											let splittedArray = items.field_media_file_1.split(".")
											let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
												splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
													: splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
														: imageUrl
											let fileName = items.name.length > 12 ? items.name.substring(0, 12) + "...." : items.name.substring(0, 12)

											return (
												<li style={{ cursor: 'pointer', marginBottom: 40 }}  >
													<figure>
														<a href={baseUrl + items.field_media_file_1} target="_blank"><img src={uploadedImageIcon} /></a>
													</figure>
												</li>
											)
										})
										: (
											null
										)
								}
							</>
						</ul>
					) : null} */}

        </div>
      </div>
    </>
  )
}

export default HeadOfficeFolderDetail;