import React, { useEffect, useState } from 'react';
import { getCompletedVisitsNew, getUpcomingVisits } from '../../functions/api';
import { Link, useHistory } from 'react-router-dom';
import { deleteNode } from '../../functions/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { baseUrl } from '../../config';
import moment from 'moment';
// import { PaginatedList } from 'react-paginated-list';
import Loader from 'react-loader-spinner';

const VisitsList = (props) => {

  const history = useHistory();
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState("")
  // const [noDataLabel, setNoDataLabel] = useState(false)
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    setData([])
    let role = window.localStorage.getItem('user_role');
    setUserRole(role)
    async function getInitialData() {
      // setNoDataLabel(false)
      setData([])
      if (props.data !== undefined && props.data.length > 0) {
        // setNoDataLabel(false)
        setShowLoader(true)
        let newData = props.data.map(async (mapData) => {
          let singleVisit = {
            title: '',
            date: '',
            time: '',
            signatureImage: '',
            meetingNotes: '',
            meetingType: '',
            meetingMethod: '',
            comments: [],
            videoLink: '',
            venue: '',
            visitId: '',
            agenda: "",
            file: "",
            createdBy: "",
            assignedTo: "",
            uploadedImage: "",
            previous_date: "",
            name: '',
            status: '',
            coordinator_name: '',
            cord_name: ''
          }

          if (mapData) {
            if (mapData.title !== "" && mapData.title !== undefined) {
              let title = mapData.title ? mapData.title : ""
              singleVisit['title'] = title
            }

            if (mapData.uid !== undefined && mapData.uid !== '') {
              let uid = mapData.uid ? mapData.uid : ''
              singleVisit['createdBy'] = uid;
            }

            if (mapData.field_visitor !== undefined && mapData.field_visitor !== '') {
              let visitor = mapData.field_visitor ? mapData.field_visitor : ""
              singleVisit['assignedTo'] = visitor;
            }
            if (mapData.field_first_name !== "" && mapData.field_first_name !== undefined) {
              let first_name = mapData.field_first_name ? mapData.field_first_name : ""
              let last_name = mapData.field_last_name ? mapData.field_last_name : 'NA'
              let name = first_name + " " + last_name
              let html_edu = <div dangerouslySetInnerHTML={{ __html: name }}></div>
              singleVisit['name'] = html_edu

            }

            if (mapData.field_date !== "" && mapData.field_date !== undefined) {
              let date = mapData.field_date ? mapData.field_date : ""
              singleVisit['date'] = date
            }

            if (mapData.nid !== "" && mapData.nid !== undefined) {
              let visitId = mapData.nid ? mapData.nid : ""
              singleVisit['visitId'] = visitId
            }

            if (mapData.field_visit_method !== "" && mapData.field_visit_method !== undefined) {
              let visitMethod = mapData.field_visit_method ? mapData.field_visit_method : ""
              singleVisit['meetingMethod'] = visitMethod
            }
            if (mapData.field_time !== "" && mapData.field_time !== undefined) {
              let visitMethod = mapData.field_time ? mapData.field_time : ""
              singleVisit['time'] = visitMethod
            }

            if (mapData.field_image !== "" && mapData.field_image !== undefined) {
              let uploadedImage = mapData.field_image ? mapData.field_image : ""
              singleVisit['uploadedImage'] = baseUrl + uploadedImage
            }

            if (mapData.nid !== "" && mapData.nid !== undefined) {
              let nid = mapData.nid ? mapData.nid : ""
              singleVisit['nid'] = nid
            }

            if (mapData.previous_date !== "" && mapData.previous_date !== undefined) {
              if (mapData.previous_date.length > 3) {
                let previous_date = mapData.previous_date ? mapData.previous_date.slice(11, mapData.previous_date.length) : ""
                singleVisit['previous_date'] = previous_date
              } else {
                let previous_date = "N/A"
                singleVisit['previous_date'] = previous_date
              }
            }

            if (mapData.field_status_visit !== undefined && mapData.field_status_visit !== '') {
              let field_status_visit = mapData.field_status_visit ? mapData.field_status_visit : ''
              singleVisit['status'] = field_status_visit;
            }
            if (mapData.field_headoffice !== undefined && mapData.field_headoffice !== '') {
              let head_office = mapData.field_headoffice ? mapData.field_headoffice : ""
              singleVisit['coordinator_name'] = head_office;

            }
            if (mapData.field_first_name_1 !== "" && mapData.field_first_name_1 !== undefined) {
              let cord_first_name = mapData.field_first_name_1 !== "" ? mapData.field_first_name_1 : ""
              let cord_last_name = mapData.field_last_name_1 ? mapData.field_last_name_1 : ''
              let cordName = cord_first_name + " " + cord_last_name
              let html_cord = <div dangerouslySetInnerHTML={{ __html: cordName }}></div>
              singleVisit['cord_name'] = html_cord
            }

            try {
              let _userVisits = await getCompletedVisitsNew(mapData.field_visitor_1);
              if (_userVisits.data.data.rows.length > 0) {
                let currentStatus = _userVisits.data.data.rows.filter((item => item.field_status_visit === 'Completed'))
                singleVisit['previous_date'] = currentStatus[0].field_date
              }
            } catch (error) { }
          }
          return singleVisit
          // setData(previousData => [...previousData, singleVisit])
        })
        setShowLoader(true)
        const allData1 = await Promise.all(newData);
        setData(allData1)
        setShowLoader(false)
      }
      else {
        if (props.data.length === 0) {
          // setNoDataLabel(true)
          setShowLoader(false)
        }

        if (props.data === undefined) {
          // setNoDataLabel(false)
          setShowLoader(false)
        }

      }
    }

    getInitialData()

  }, [props.data]);

  async function deleteItem(item) {
    if (data.length > 0) {
      const visitsData = data.filter(i => i.nid !== item)
      await setData([...visitsData])
    }
  }

  async function deleteVisit(visitId) {
    let response = await deleteNode(visitId);
    if (response.status === 204) {
      deleteItem(visitId)
      toast.success('Visit has been deleted successfully!', {
        position: 'top-center'
      });
    } else {
      toast.error(response.message, {
        position: 'top-center'
      });
    }
  }

  function confirmDeleteVisit(visitId) {
    confirmAlert({
      title: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteVisit(visitId)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  return (
    <>
      <ToastContainer />
      {/* <PaginatedList
        list={data}
        itemsPerPage={20}
        renderList={(list) => ( */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {userRole === 'head_coordinator' ? <TableCell>Assigned To</TableCell> : ''}
              <TableCell>Title</TableCell>
              {userRole === 'head_coordinator' ? (
                <TableCell>Previous Date</TableCell>
              ) : null}
              <TableCell>{userRole === 'head_coordinator' ? "Visit Date" : "Date"}</TableCell>
              {userRole === 'head_coordinator' ? (
                <TableCell>Visit Method</TableCell>
              ) : null}
              {userRole !== 'head_coordinator' ? (
                <TableCell>Time</TableCell>
              ) : null}
              <TableCell>{userRole === 'head_coordinator' ? 'Coordinator Assigned' : 'Coordinator Assigned'}</TableCell>
              <TableCell>Status</TableCell>
              {
                userRole === 'head_coordinator' ?
                  <TableCell>Actions</TableCell>
                  : null
              }
            </TableRow>
          </TableHead>
          <TableBody className='form-list-container'>
            {
              data.length > 0 ? data.map((visit, index) => (
                <>
                  <TableRow key={index} className="mdc-form-field form-list-ms" style={{ cursor: "pointer" }}>
                    {userRole === 'head_coordinator' ? <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{visit.name ? visit.name : 'N/A'}</TableCell> : ''}
                    <TableCell className="custom-title-link" ><Link to={`/m&s-detail-page/${visit.visitId}`} dangerouslySetInnerHTML={{ __html: visit.title }}></Link></TableCell>
                    {userRole === 'head_coordinator' ? (
                      <TableCell>{visit.previous_date && visit.previous_date !== 'N/A' ? moment(visit.previous_date).format('DD-MM-YYYY') : 'N/A'}</TableCell>
                    ) : null}
                    <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{moment(visit.date).format('DD-MM-YYYY')}</TableCell>
                    {userRole === 'head_coordinator' ? (
                      <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{visit.meetingMethod}</TableCell>
                    ) : null}
                    {userRole !== 'head_coordinator' ?
                      <TableCell>{visit.time}</TableCell> :
                      ''
                    }
                    {userRole === 'head_coordinator' ? (
                      <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{visit.cord_name !== "" ? visit.cord_name : "Assign Coordinator"}</TableCell>) :
                      <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{visit.cord_name !== "" ? visit.cord_name : "Assign Coordinator"}</TableCell>
                    }
                    <TableCell onClick={() => history.push(`/m&s-detail-page/${visit.visitId}`)}>{visit.status}</TableCell>
                    <TableCell className='ms-action-buttons'>
                      {userRole === 'head_coordinator' && (
                        <>
                          <Link className="bg-green action-btn-style" to={`/edit-m&s/${visit.visitId}`}> Edit</Link>
                          <button className="bg-red action-btn-style del" onClick={() => confirmDeleteVisit(visit.visitId)}>Delete</button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ))
                : null
                // <div className="mdc-form-field">
                //   {noDataLabel === true ?
                //     <label>You don't have any upcoming M&S</label>
                //     :
                //     null
                //   }
                // </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
            showLoader ?
              <div className="loaderBox">
                <Loader
                  visible={showLoader}
                  type="Bars"
                  color="#0A50A1"
                  height={40}
                  width={100}
                  timeout={10000} //10 secs
                />
              </div>: null
          }
      {/* <div>

        <Pagination pageCount={3} activeIndex={1} onPageClick={handlePageClick}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick} />
      </div>
       )} /> */}
    </>
  )
}

export default VisitsList;